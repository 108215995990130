import {
  Container,
  Box,
  Typography,
  Grid,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputBase,
  IconButton,
} from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import { withParam } from "../../utils/Router.Helper";
// import { fetchPmsData, pmsgetAllList } from "../../api/Api";
// import { useQuery } from "react-query";
// import AddProduct from "./AIF/AddAIFProduct";
import FilterPMS from "../Filters/FilterPMS";
import Loading from "../../Constants/Loading";
import { Link } from "react-router-dom";
import { PMS_GET_ALL } from "../../utils/API_Names";
import axios from "axios";
import ComparePMSFund from "./ComparePMSFund";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";

const Pms = () => {
  const dispatch = useDispatch();
  const myState = useSelector((state: any) => state.ChangeComparePmsFund);
  const ComparePMSFundData: any = useSelector(
    (state: any) => state.ChangeComparePmsFund
  );
  console.log("myState", myState);

  const [filterDataURL, setFilterDataURL] = React.useState([]);
  const [Return, setReturn] = React.useState("sortByReturn=1M");
  const [AUM, setAUM] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [aifList, setAifList] = React.useState<any>([]);
  const [searchTerm, setSearchTerm] = React.useState("");

  // async function fetchAIFFilterData(){
  //   const data = await axios.get(`${PMS_GET_ALL}?${filterDataURL.join("&")}&${Return}`)
  //   return data;
  // }

  // const { data:aifList,isLoading, isFetching, isError} = useQuery(["PmsList",filterDataURL,Return,AUM],fetchAIFFilterData,{
  //   refetchOnWindowFocus:false
  // })

  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${PMS_GET_ALL}?${filterDataURL.join("&")}&${Return}`)
      .then((res: any) => {
        // console.log("Res", res.data.pmsDTOList);
        // setAifList(res);
        setAifList(res.data.pmsDTOList);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error("Something went Wrong!");
      });
  }, [filterDataURL, Return, AUM]);

  const xs = 3;
  const sm = 3;
  const md = 1;
  const lg = 1;

  const changeHandleComparePMS = (event: any, pmsData: any) => {
    const checked = event.target.checked;
    if (checked) {
      dispatch({ type: "AddComparePmsFund", payload: pmsData });
    } else {
      dispatch({ type: "RemoveComparePmsFund", payload: pmsData });
    }
  };

  // if(isLoading){
  //   return <Loading/>
  // }

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredPms =
    aifList &&
    aifList.filter((pmsItem: any) => {
      return pmsItem.schemeName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  // console.log("PMS_LIST: ", aifList?.data?.pmsDTOList);

  return (
    <>
      {isLoading && <Loading />}
      {
        <Container maxWidth={"xl"}>
          <Box sx={{ minHeight: "calc(100vh - 50px)", marginTop: "1%" }}>
            {/* <AddAIFProduct /> */}

            <Grid container spacing={2}>
              <Grid item md={3}>
                <FilterPMS
                  setFilterDataURL={setFilterDataURL}
                  setReturn={setReturn}
                  setAUM={setAUM}
                />
                <ComparePMSFund />
              </Grid>
              <Grid item md={9}>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 400,
                    mb: "1rem",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search PMS"
                    onChange={handleSearch}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
                {filteredPms &&
                  filteredPms?.map((row: any) => (
                    <Paper elevation={8} sx={{ mb: 3, p: 1 }}>
                      <Grid
                        container
                        spacing={2}
                        textAlign={"left"}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          textDecoration: "none",
                        }}
                      >
                        <Grid item xs>
                          <Box>
                            <img
                              src={row?.amcInfoDTO?.amcLogo}
                              width={100}
                              alt="AIF logo"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Link to={"/pmsScreener/" + row.schemeCode}>
                            <Typography fontWeight={"500"} variant="h5">
                              {row.schemeName}
                            </Typography>
                          </Link>
                          <Typography>
                            <Chip
                              label={
                                !!row?.schemeBenchmarkName
                                  ? row?.schemeBenchmarkName
                                  : "-"
                              }
                              sx={{ mr: 1 }}
                            />
                            <Chip
                              label={
                                !!row?.fundPerformance?.aum
                                  ? row?.fundPerformance?.aum + "  Cr"
                                  : "-"
                              }
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography align="right">
                            {row?.schemePlanType}
                          </Typography>
                          <Typography
                            align="right"
                            sx={{ display: "flex", justifyContent: "end" }}
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={ComparePMSFundData.includes(row)}
                                    onClick={(event: any) =>
                                      changeHandleComparePMS(event, row)
                                    }
                                    disabled={
                                      ComparePMSFundData.includes(row)
                                        ? false
                                        : ComparePMSFundData?.length === 4
                                    }
                                  />
                                }
                                label="Compare"
                              />
                            </FormGroup>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container columnGap={"6%"} sx={{ m: 1 }}>
                        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                          <Typography variant="subtitle2">1M</Typography>
                          <Typography variant="subtitle2">
                            {row?.fundPerformance?.schemeRet1m || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                          <Typography variant="subtitle2">3M</Typography>
                          <Typography variant="subtitle2">
                            {row?.fundPerformance?.schemeRet3m || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                          <Typography variant="subtitle2">6M</Typography>
                          <Typography variant="subtitle2">
                            {row?.fundPerformance?.schemeRet6m || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                          <Typography variant="subtitle2">1Y</Typography>
                          <Typography variant="subtitle2">
                            {row?.fundPerformance?.schemeRet1y || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                          <Typography variant="subtitle2">3Y</Typography>
                          <Typography variant="subtitle2">
                            {row?.fundPerformance?.schemeRet3y || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                          <Typography variant="subtitle2">5Y</Typography>
                          <Typography variant="subtitle2">
                            {row?.fundPerformance?.schemeRet5y || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                          <Typography variant="subtitle2">SL</Typography>
                          <Typography variant="subtitle2">
                            {row?.fundPerformance?.schemeRetInce || "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
              </Grid>
            </Grid>
          </Box>
        </Container>
      }
    </>
  );
};
export default withParam(Pms);
