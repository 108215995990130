import { Box, CircularProgress, Switch } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';

const CompanyCircularProgress = (props:any) => {
	// console.log("IS company active",props.isActive)
	const companyMasterCode = props.companyMasterCode
	const isActive = props.isActive

	const [activeCircularProgress, SetActiveCircularProgress] = useState(false);
	const [checked, setChecked] = React.useState(isActive);

	React.useEffect(() => {
		setChecked(isActive)
	}, [props])
	

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		SetActiveCircularProgress(true)
		axios.put("company/deleteCompany/" + companyMasterCode).then(()=>{
			SetActiveCircularProgress(false);
			setChecked(!event.target.checked);
			props.setRefresh(!props.refresh)
		}).catch((err:any)=>{
			SetActiveCircularProgress(false);
			toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title)	
		})
	  };
  return (
	<>
				<Switch
                        checked={checked}
						disabled={activeCircularProgress}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                     />
                     <Box sx={{ pt:1 }}>
                      { activeCircularProgress ? 
                        <CircularProgress size={20} />
                        : <></>
                      }
                     </Box>
	</>
  )
}

export default CompanyCircularProgress