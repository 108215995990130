import { Paper, Box, List, ListItem, ListItemAvatar, Avatar, Typography, ListItemText } from '@mui/material'
import React from 'react'
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

const TotalIncomeDarkCard = (props:any) => {

    const unpaidValue = props.unpaid; 
    console.log("Unpaid props", unpaidValue);

  return (
    <Paper elevation={3} sx={{
    backgroundColor: "#1e88e5",
    color: "#fff",
    overflow: 'hidden',
    position: 'relative',
    borderRadius:"10px",
    height:"225px",
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background: `linear-gradient(210.04deg, ${theme.palette.primary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background: `linear-gradient(140.9deg, ${theme.palette.primary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
    }}>
    <Box sx={{ p: 2 }}>
        <List sx={{ py: 0 }}>
            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                    <Avatar
                        variant="rounded"
                        sx={{
                            // ...theme.typography.commonAvatar,
                            // ...theme.typography.largeAvatar,
                            backgroundColor: "#1565c0",
                            color: '#fff'
                        }}
                    >
                        <TableChartOutlinedIcon fontSize="inherit" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        py: 0,
                        mt: 0.45,
                        mb: 0.45
                    }}
                    primary={
                        unpaidValue && unpaidValue.map((item:any)=>(
                        <Typography variant="h4" sx={{ fontSize: '2.125rem', fontWeight: 500, }}>
                            ₹{item.totalPayoutRemain}
                        </Typography>
                        ))
                    }
                    secondary={
                        <Typography variant="subtitle2" sx={{ mt: 0.25 }}>
                            { sessionStorage.getItem("Profile")==="E" ? "Pending Revenue Credit" : "" }
                            { sessionStorage.getItem("Profile")==="D" ? "Pending Payout" : "" }
                            { sessionStorage.getItem("Profile")==="B" ? "Pending Payout" : "" }
                        </Typography>
                    }
                />
            </ListItem>
        </List>
    </Box>
</Paper>
  )
}

export default TotalIncomeDarkCard