const initialState = ""
export const ChangeCompanyReducer = (state=initialState,action) => {
	switch(action.type){
		case "ChangeCompany": return action.payload;
		default: return state;
	}
}

export const ChangeCompareAifFund = (state=[],action) => {
	switch(action.type){
		case "AddCompareAifFund": return [...state,action.payload];
		case "RemoveCompareAifFund": return state.filter((item)=>item!==action.payload); 
		default: return state;
	}
}

export const ChangeComparePmsFund = (state=[],action) => {
	switch(action.type){
		case "AddComparePmsFund": return [...state,action.payload];
		case "RemoveComparePmsFund": return state.filter((item)=>item!==action.payload); 
		default: return state;
	}
}

export const ChangeCompareMfFund = (state=[],action) => {
	switch(action.type){
		case "AddCompareMfFund": return [...state,action.payload];
		case "RemoveCompareMfFund": return state.filter((item)=>item!==action.payload); 
		default: return state;
	}
}

export const ClientCount = (state=[],action) => {
	switch(action.type){
		case "AddClientCount": return action.payload;
		default: return state;
	}
}

export const TotalCountOfClient = (state=0,action) => {
	switch(action.type){
		case "TotalClientCount": return action.payload;
		default: return state;
	}
}

export const MonthlyCountOfClient = (state=0,action) => {
	switch(action.type){
		case "MonthlyClientCount": return action.payload;
		default: return state;
	}
}
export const currMonthWiseData = (state=0,action) => {
	switch(action.type){
		case "MonthlywiseCount": return action.payload;
		default: return state;
	}
}

export const quarterWiseClientData = (state=0,action) => {
	switch(action.type){
		case "quarterWiseData": return action.payload;
		default: return state;
	}
}

export const yearlyDatacount = (state=0,action) => {
	switch(action.type){
		case "yearlyDatacount": return action.payload;
		default: return state;
	}
}


export const UnlistedTotalAmount = (state=0,action) => {
	switch(action.type){
		case "UnlistedTotal": return action.payload;
		default: return state;
	}
}

export const prevQuarterData = (state=0,action) => {
	switch(action.type){
		case "prevQuarterData": return action.payload;
		default: return state;
	}
}
export const ytdDataClient = (state=0,action) => {
	switch(action.type){
		case "ytdDataClient": return action.payload;
		default: return state;
	}
}