import { ThemeProvider } from '@emotion/react'
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, createTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { CommaFormater2 } from '../../converter'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CropSquareIcon from '@mui/icons-material/CropSquare'
import { getQuality, getUserPortfolioProfile } from '../../../api/Api'
import LinearProgress from '@mui/material/LinearProgress';
const defaultTheme = createTheme()

const PortFolioProfile = () => {
    
  const [dataPortfolioReport, PortfolioReport] = useState<any>([])
  const [statementportfolioReport, portfolioreportStatement] = useState<any>([])
  const [datamoderateGrowth, moderateGrowthScheme] = useState<any>([])
  const [dataAggressiveSchemes, AggressiveSchemes] = useState<any>([])
  const [dataconservativeSchemes, conservativeSchemes] = useState<any>([])
  const [datasectoralSchemes, sectoralSchemes] = useState<any>([])
  const [dataqualitydscore, qualitydscore] = useState<any>()
  const [datagreyZone, greyZone] = useState<any>()
  const [datadistressZone, distressZonePer] = useState<any>()
  const [dataZoneDataList, ZoneDataList] = useState<any>([])
  const [distressZonelist, distressZone] = useState<any>([])
  const [datasafeZoneList, safeZoneList] = useState<any>([])
  const [datagreyZoneList, greyZoneList] = useState<any>([])
  const [expanded, setExpanded] = useState<string | false>(false); 
  const chartRef = useRef(null)
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [progress, setProgress] = useState(0);

  const task1Progress = 50; // Replace with the actual progress of the first task
  const task2Progress = 80; // Replace with the actual progress of the second task
  const task3Progress = 25; 

  

  useEffect(() => {
    setshowCircularProgress(true);
    Promise.all([getUserPortfolioProfile(), getQuality()])
      .then(([profileResponse, qualityResponse]) => {
        PortfolioReport(profileResponse.data)
        portfolioreportStatement(profileResponse.data.growthTypeDescription)
        moderateGrowthScheme(profileResponse.data.moderateGrowthSchemes)
        AggressiveSchemes(profileResponse.data.aggressiveGrowthSchemes)
        conservativeSchemes(profileResponse.data.conservativeGrowthSchemes)
        sectoralSchemes(profileResponse.data.sectoralGrowthSchemes)
        qualitydscore(qualityResponse.data.safeZonePercentage)
        greyZone(qualityResponse.data.greyZonePercentage)
        distressZonePer(qualityResponse.data.distressZonePercentage)
        ZoneDataList(qualityResponse.data)
        distressZone(qualityResponse.data.distressZone)
        safeZoneList(qualityResponse.data.safeZone)
        greyZoneList(qualityResponse.data.greyZone)
      }).then(()=>{
        setshowCircularProgress(false);
      })
      .catch((error) => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
      const averageProgress = (task1Progress + task2Progress + task3Progress) / 3;
      setProgress(averageProgress);
  }, [])

 


  const handleChange = (panel:any) => (event:any, isExpanded:any) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <div>
       <ThemeProvider theme={defaultTheme}>
       {showCircularProgress == true ? (
                    <CircularProgress
                      color="secondary"
                      size={50}
                      thickness={5}
                    />
                  ) : (
                    <Box>
    <Typography className='descriptionportfolio-nd-quality'>{statementportfolioReport}</Typography>
                   <div style={{marginBottom:"20px"}}>
                   {/* <ProgressBar style={{height:"20px"}}>
                   <ProgressBar style={{background:"#fbcc34"}} now={dataPortfolioReport.aggressiveGrowthValuePercentage} label={`${dataPortfolioReport.aggressiveGrowthValuePercentage}%`} title={`${dataPortfolioReport.aggressiveGrowthValuePercentage}%`} key={1} />
                   <ProgressBar style={{background:"#16678e"}}  now={dataPortfolioReport.moderateGrowthValuePercentage}  label={`${dataPortfolioReport.moderateGrowthValuePercentage}%`} title={`${dataPortfolioReport.moderateGrowthValuePercentage}%`}key={2} />
                   <ProgressBar style={{background:"#16846d"}} now={dataPortfolioReport.conservativeGrowthValuePercentage} label={`${dataPortfolioReport.conservativeGrowthValuePercentage}%`} title={`${dataPortfolioReport.conservativeGrowthValuePercentage}%`} key={3} />
                   <ProgressBar style={{background:"#7a1348"}} now={dataPortfolioReport.sectoralGrowthValuePercentage} label={`${dataPortfolioReport.sectoralGrowthValuePercentage}%`} title={`${dataPortfolioReport.sectoralGrowthValuePercentage}%`}key={3} />
                 </ProgressBar> */}
                  <div>
                  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                  <LinearProgress variant="determinate" value={dataPortfolioReport.aggressiveGrowthValuePercentage}  
sx={{
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#fbcc34', // Change the background color of the value side to yellow
        },
        height: 15,
      }} />
                  <LinearProgress variant="determinate" value={dataPortfolioReport.moderateGrowthValuePercentage}
                  sx={{
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#16678e', // Change the background color of the value side to yellow
                    },
                    height: 15,
                  }}
                  />
                  <LinearProgress variant="determinate" value={dataPortfolioReport.conservativeGrowthValuePercentage}
                  sx={{
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#16846d', // Change the background color of the value side to yellow
                    },
                    height: 15,
                  }}
                  />
                  <LinearProgress variant="determinate" value={dataPortfolioReport.sectoralGrowthValuePercentage} 
                  sx={{
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#7a1348', // Change the background color of the value side to yellow
                    },
                    height: 15,
                  }}
                  />
    </Stack>
    </div>
                   </div>
    <Box>
        <TableContainer>
            <Table className='' style={{ width: '100%', marginBottom: '0px' }} >
                <TableHead sx={{ background: '#032b5b' }} >
                    <TableRow className='main-tr-top-post-investment-holding-imp main-tr-top-portfolio-report-main-headder' >
                        <TableCell className='Investment-Type-header-portfolio-dtls' sx={{ width: '35%', color: '#fff'}}>Investment Type</TableCell>
                        <TableCell className='investment-header-th-value-porfolio' sx={{ width: '10%', color: '#fff', textAlign: 'center' }}>Value</TableCell>
                        <TableCell className='investment-header-th-value-porfolio' sx={{ width: '30%', color: '#fff', textAlign: 'center' }}>Portfolio %</TableCell>
                    </TableRow>
                </TableHead>
            </Table>
        </TableContainer>

        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  backgroundColor: '#f0f0f0',
                  margin: 0
                }}
            >
                <TableContainer>
                    <Table aria-label='simple table' >
                        <TableBody >
                            <TableRow className='portfolioprofile-table-tr' style={{ borderBottom: 'hidden' }}>
                                <TableCell className='' style={{ padding: 0, width: '25%' }}>
                                    <span className='portfolioprofile-invest-type-icone'>
                                        <CropSquareIcon className='' style={{ color: '#fbcc34', backgroundColor: '#fbcc34' }}></CropSquareIcon>
                                    </span>
                                    Aggressive Growth
                                </TableCell>
                                <TableCell className='' style={{ padding: 0, width: '10%', textAlign: 'center' }}>{CommaFormater2(Math.floor(dataPortfolioReport.aggressiveGrowthValue))}</TableCell>
                                <TableCell className='' style={{ padding: 0, width: '20%', textAlign: 'center' }}>{dataPortfolioReport.aggressiveGrowthValuePercentage}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: '1.5em' }}>
                <TableContainer>
                    <Table aria-label='simple table' >
                        <TableBody sx={{ background: '#e7f1ff' }}>

                            {dataPortfolioReport.aggressiveGrowthValuePercentage === 0
                              ? <TableRow>
                                    <TableCell className=''><p>Not Available</p></TableCell>
                                </TableRow>
                              : dataAggressiveSchemes?.filter((auto:any) => auto.aggressiveGrowthSchemesName != null).map((item:any, index:any) => (
                                    <TableRow className='portfolioprofile-table-tr' key={index}>
                                        <TableCell className='' style={{ width: '60%', textAlign: 'left' }}>{item.aggressiveGrowthSchemesName}</TableCell>
                                        <TableCell className='' style={{ width: '20%' }}>{CommaFormater2(Math.floor(item.aggressiveGrowthSchemesValue))}</TableCell>
                                        <TableCell className='' style={{ width: '20%' }}>{item.aggressiveGrowthSchemesPercentage}</TableCell>
                                    </TableRow>
                              ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  backgroundColor: '#f0f0f0',
                  margin: 0
                }}
            >
                <TableContainer>
                    <Table aria-label='simple table' >
                        <TableBody >
                            <TableRow className='portfolioprofile-table-tr' style={{ borderBottom: 'hidden' }}>
                                <TableCell className='' style={{ padding: 0, width: '25%' }}>
                                    <span className='portfolioprofile-invest-type-icone'>
                                        <CropSquareIcon className='' style={{ color: '#16678e', backgroundColor: '#16678e' }}></CropSquareIcon>
                                    </span>
                                    Moderate Growth
                                </TableCell>
                                <TableCell className='' style={{ padding: 0, width: '10%', textAlign: 'center' }}>{CommaFormater2(Math.floor(dataPortfolioReport.moderateGrowthValue))}</TableCell>
                                <TableCell className='' style={{ padding: 0, width: '20%', textAlign: 'center' }}>{dataPortfolioReport.moderateGrowthValuePercentage}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: '1.5em' }}>
                <TableContainer>
                    <Table aria-label='simple table' >
                        <TableBody sx={{ background: '#e7f1ff' }}>

                            {dataPortfolioReport.moderateGrowthValuePercentage === 0
                              ? <TableRow>
                                    <TableCell className=''><p>Not Available</p></TableCell>
                                </TableRow>
                              : datamoderateGrowth?.filter((auto:any) => auto.moderateGrowthSchemesName != null).map((item:any, index:any) => (
                                    <TableRow className='portfolioprofile-table-tr' key={index}>
                                        <TableCell className='' style={{ width: '60%', textAlign: 'left' }}>{item.moderateGrowthSchemesName}</TableCell>
                                        <TableCell className='' style={{ width: '20%' }}>{CommaFormater2(Math.floor(item.moderateGrowthSchemesValue))}</TableCell>
                                        <TableCell className='' style={{ width: '20%' }}>{item.moderateGrowthSchemesPercentage}</TableCell>
                                    </TableRow>
                              ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  backgroundColor: '#f0f0f0',
                  margin: 0
                }}
            >
                <TableContainer>
                    <Table aria-label='simple table' >
                        <TableBody >
                            <TableRow className='portfolioprofile-table-tr' style={{ borderBottom: 'hidden' }}>
                                <TableCell className='' style={{ padding: 0, width: '25%'}}>
                                    <span className='portfolioprofile-invest-type-icone'>
                                        <CropSquareIcon className='' style={{ color: '#16846d', backgroundColor: '#16846d' }}></CropSquareIcon>
                                    </span>
                                    Conservative Growth
                                </TableCell>
                                <TableCell className='' style={{ padding: 0, width: '10%', textAlign: 'center' }}>{CommaFormater2(Math.floor(dataPortfolioReport.conservativeGrowthValue))}</TableCell>
                                <TableCell className='' style={{ padding: 0, width: '20%', textAlign: 'center' }}>{dataPortfolioReport.conservativeGrowthValuePercentage}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: '1.5em' }}>
                <TableContainer>
                    <Table aria-label='simple table' >
                        <TableBody sx={{ background: '#e7f1ff' }}>

                            {dataPortfolioReport.conservativeGrowthValuePercentage === 0
                              ? <TableRow>
                                    <TableCell className=''><p>Not Available</p></TableCell>
                                </TableRow>
                              : dataconservativeSchemes?.filter((auto:any) => auto.conservativeGrowthSchemesName != null).map((item:any, index:any) => (
                                    <TableRow className='portfolioprofile-table-tr' key={index}>
                                        <TableCell className='' style={{ width: '60%', textAlign: 'left' }}>{item.conservativeGrowthSchemesName}</TableCell>
                                        <TableCell className='' style={{ width: '20%' }}>{CommaFormater2(Math.floor(item.conservativeGrowthSchemesValue))}</TableCell>
                                        <TableCell className='' style={{ width: '20%' }}>{item.conservativeGrowthSchemesPercentage}</TableCell>
                                    </TableRow>
                              ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  backgroundColor: '#f0f0f0',
                  margin: 0
                }}
            >
                <TableContainer>
                    <Table aria-label='simple table' >
                        <TableBody >
                            <TableRow className='portfolioprofile-table-tr' style={{ borderBottom: 'hidden' }}>
                                <TableCell className='' style={{ padding: 0, width: '25%'}}>
                                    <span className='portfolioprofile-invest-type-icone'>
                                        <CropSquareIcon className='' style={{ color: '#7a1348', backgroundColor: '#7a1348' }}></CropSquareIcon>
                                    </span>
                                    Sectoral Growth
                                </TableCell>
                                <TableCell className='' style={{ padding: 0, width: '10%', textAlign: 'center' }}>{CommaFormater2(Math.floor(dataPortfolioReport.sectoralGrowthValue))}</TableCell>
                                <TableCell className='' style={{ padding: 0, width: '20%', textAlign: 'center' }}>{dataPortfolioReport.sectoralGrowthValuePercentage}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: '1.5em' }}>
                <TableContainer>
                    <Table aria-label='simple table' >
                        <TableBody sx={{ background: '#e7f1ff' }}>

                            {dataPortfolioReport.sectoralGrowthValuePercentage === 0
                              ? <TableRow>
                                    <TableCell className=''><p>Not Available</p></TableCell>
                                </TableRow>
                              : datasectoralSchemes?.filter((auto:any) => auto.sectoralGrowthSchemesName != null).map((item:any, index:any) => (
                                    <TableRow className='portfolioprofile-table-tr' key={index}>
                                        <TableCell className='' style={{ width: '60%', textAlign: 'left' }}>{item.sectoralGrowthSchemesName}</TableCell>
                                        <TableCell className='' style={{ width: '20%' }}>{CommaFormater2(Math.floor(item.sectoralGrowthSchemesValue))}</TableCell>
                                        <TableCell className='' style={{ width: '20%' }}>{item.sectoralGrowthSchemesPercentage}</TableCell>
                                    </TableRow>
                              ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    </Box>
</Box>
                  )}




</ThemeProvider>
    </div>
  )
}

export default PortFolioProfile