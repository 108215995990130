import React, { useState } from 'react'
import { TextField,Modal,Typography,Button,Box } from '@mui/material';
import { useForm, Resolver } from "react-hook-form";
import axios from 'axios';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  height: '70%',
  overflow:'scroll'
};

const resolver = async (values:any) => {
    
    
    return {
      values: !values.schemeName ? {} : values,
      errors: !values.schemeName
        ? {
            schemeName: {
              type: "required",
              message: "This is required.",
            },
          }
        : {},
    };
  };

  

const AddAIFProduct = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [file, setFile] = useState<any>([]);

    const handleChange = (event:any, name:any) => {
        console.log(event.target.files[0]);
        console.log(name);
        setFile([...file,[name,event.target.files[0]]]);

    }

    const onSubmit = (data:any) => {
        console.log("Data",data);
        const formData = new FormData();
        file.map((item:any) => {
            formData.append(item[0],item[1]);
        })

        const dto:any = {   
          "schemeName": "Invest SMALL CAP FUND",   
          "category": "cat-I",   
          "aum": "500000",   
          "minInvestment": "1000",   
          "risk": "HIGH",   
          "managementFees": "500",   
          "profitSharing": "500",   
          "oneYearReturn": "100",   
          "secondYearReturn": "200",   
          "thirdYearReturn": "300",   
          "sinceInceptionDate": "2022-07-26",   
          "benchmark": "XXXX",   
          "sector": "BANKING",   
          "note": "XXXX",   
          "faq": "XXXX",   
          "productTypeCode": "4",   
          "productName": "ALTERNATIVE INVESTMENT FUND",   
          "description": "About MF",   
          "brochureUrl": "http://hdfc.com",   
          "videoUrl": "http://hdfc.com",   
          "formUrl": "http://hdfc.com",   
          "minAmount": "500",   
          "maxAmount": "100000",   
          "minTenure": "1",   
          "maxTenure": "10",   
          "openDate": "2022-10-26",   
          "closeDate": "2023-10-26",   
          "exitLoad": "1",   
          "companyMasterCode": "4"
        };

        formData.append("aifDTOStr",JSON.stringify(data))
        // console.log("Form Data",formData)
        // formData.append("aifDTOStr",JSON.stringify(dto))
        axios.post("aif/uploadFile", formData);
      }

    const { register, handleSubmit } = useForm({
        resolver: resolver,
      });

      console.log("Files--", file )
  
    return (
      <div>
        <Button onClick={handleOpen} sx={{marginBottom:'1%'}} variant="contained">Add Product</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box 
            sx={style} 
            component='form' 
            noValidate
            autoComplete='off' 
            onSubmit={handleSubmit(onSubmit)}
            >
                <Typography variant='h4'>
                    Add Product
                </Typography>
          <TextField 
            id="outlined-basic" 
            label="Scheme Name" 
            variant="outlined"
            {...register("schemeName")} 
          />
          <TextField 
            id="outlined-basic" 
            label="Category" 
            variant="outlined" 
            {...register("category")}
          />
          <TextField 
            id="outlined-basic" 
            label="aum" 
            variant="outlined" 
            {...register("aum")}
          />
          <TextField 
            id="outlined-basic" 
            label="minInvestment" 
            variant="outlined"
            {...register("minInvestment")} 
          />
          <TextField 
            id="outlined-basic" 
            label="risk" 
            variant="outlined"
            {...register("risk")} 
          />
          <TextField 
            id="outlined-basic" 
            label="managementFees" 
            variant="outlined"
            {...register("managementFees")} 
          />
          <TextField 
            id="outlined-basic" 
            label="profitSharing" 
            variant="outlined"
            {...register("profitSharing")} 
          />
          <TextField 
            id="outlined-basic" 
            label="oneYearReturn" 
            variant="outlined"
            {...register("oneYearReturn")} 
          />
          <TextField 
            id="outlined-basic" 
            label="secondYearReturn" 
            variant="outlined"
            {...register("secondYearReturn")} 
          />
          <TextField 
            id="outlined-basic" 
            label="thirdYearReturn" 
            variant="outlined"
            {...register("thirdYearReturn")} 
          />
          <TextField 
            id="outlined-basic" 
            label="sinceInceptionDate" 
            variant="outlined"
            {...register("sinceInceptionDate")} 
          />
          <TextField 
            id="outlined-basic" 
            label="benchmark" 
            variant="outlined"
            {...register("benchmark")} 
          />
          <TextField 
            id="outlined-basic" 
            label="sector" 
            variant="outlined"
            {...register("sector")} 
          />
          <TextField 
            id="outlined-basic" 
            label="note" 
            variant="outlined"
            {...register("note")} 
          />
          <TextField 
            id="outlined-basic" 
            label="faq" 
            variant="outlined"
            {...register("faq")} 
          />
          <TextField 
            id="outlined-basic" 
            label="productTypeCode" 
            variant="outlined"
            {...register("productTypeCode")} 
          />
          <TextField 
            id="outlined-basic" 
            label="productName" 
            variant="outlined"
            {...register("productName")} 
          />
          <TextField 
            id="outlined-basic" 
            label="description" 
            variant="outlined"
            {...register("description")} 
          />
          <TextField 
            id="outlined-basic" 
            label="brochureUrl" 
            variant="outlined"
            {...register("brochureUrl")} 
          />
          <TextField 
            id="outlined-basic" 
            label="videoUrl" 
            variant="outlined"
            {...register("videoUrl")} 
          />
          <TextField 
            id="outlined-basic" 
            label="formUrl" 
            variant="outlined"
            {...register("formUrl")} 
          />
          <TextField 
            id="outlined-basic" 
            label="minAmount" 
            variant="outlined"
            {...register("minAmount")} 
          />
          <TextField 
            id="outlined-basic" 
            label="maxAmount" 
            variant="outlined"
            {...register("maxAmount")} 
          />
          <TextField 
            id="outlined-basic" 
            label="minTenure" 
            variant="outlined"
            {...register("minTenure")} 
          />
          <TextField 
            id="outlined-basic" 
            label="maxTenure" 
            variant="outlined"
            {...register("maxTenure")} 
          />
          <TextField 
            id="outlined-basic" 
            label="openDate" 
            variant="outlined"
            {...register("openDate")} 
          />
          <TextField 
            id="outlined-basic" 
            label="closeDate" 
            variant="outlined"
            {...register("closeDate")} 
          />
          <TextField 
            id="outlined-basic" 
            label="exitLoad" 
            variant="outlined"
            {...register("exitLoad")} 
          />
          {
            sessionStorage.getItem("CompanyMasterCode")?<></>:
            <TextField 
              id="outlined-basic" 
              label="companyMasterCode" 
              variant="outlined"
              defaultValue={sessionStorage.getItem("CompanyMasterCode")}
              {...register("companyMasterCode")} 
            />
          }
          <Typography variant="subtitle1">Form</Typography>
                <input type="file" name='form' onChange={(event)=>handleChange(event,"form")} style={{ marginBottom: "10px" }} />
                <Typography variant="subtitle1">Presentation</Typography>
                <input type="file" name='presentation' onChange={(event)=>handleChange(event,"presentation")} style={{ marginBottom: "10px" }} />
                <Typography variant="subtitle1">Fact Sheet</Typography>
                <input type="file" name='factSheet' onChange={(event)=>handleChange(event,"factSheet")} style={{ marginBottom: "10px" }} />
                <Typography variant="subtitle1">Fund Manager Video</Typography>
                <input type="file" name='fundManagerVideo' onChange={(event)=>handleChange(event,"fundManagerVideo")} style={{ marginBottom: "10px" }} />
                <Typography variant="subtitle1">Product Video</Typography>
                <input type="file" name='productVideo' onChange={(event)=>handleChange(event,"productVideo")} style={{ marginBottom: "10px" }} />
                <Typography variant="subtitle1">Knowledge Series</Typography>
                <input type="file" name='knowledgeSeries' onChange={(event)=>handleChange(event,"knowledgeSeries")} style={{ marginBottom: "10px" }} />
                <Typography variant="subtitle1">Contest</Typography>
                <input type="file" name='contest' onChange={(event)=>handleChange(event,"contest")} style={{ marginBottom: "10px" }} />
          <Button type='submit' variant="contained">
            Submit
          </Button>
          </Box>
        </Modal>
      </div>
    );
}

export default AddAIFProduct