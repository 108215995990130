import {
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { ResearchServiceMonthCounts } from "../../../Constants/ResearchServiceMonthCounts";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ResearchServicesOrderModel from "./ResearchServicesOrderModel";
import axios from "axios";
import { RESEARCH_SERVICES_GET_ALL } from "../../../utils/API_Names";
import EditIcon from "@mui/icons-material/Edit";
import AddNewResearchServicesModel from "./AddNewResearchServicesModel";

const ResearchPlans = () => {
  const [monthValue, setMonthValue] = React.useState<any>(
    ResearchServiceMonthCounts[0].value
  );
  const [month, setMonth] = React.useState<any>(
    ResearchServiceMonthCounts[0].month
  );
  const [researchServicesOrderModel, setResearchServicesOrderModel] =
    useState(false);
  const [planData, setPlanData] = React.useState<any>([]);
  const [planDetails, setPlanDetails] = React.useState<any>("");
  const [researchServices, setResearchServices] = useState(false);
  const [tenureDetails, setTenureDetails] = useState([]);

  React.useEffect(() => {
    axios
      .get(`${RESEARCH_SERVICES_GET_ALL}`)
      .then((data: any) =>
        setTenureDetails(data.data.researchServiceSubscriptionDTOList)
      );

    axios
      .get(`${RESEARCH_SERVICES_GET_ALL}?tenure=${monthValue}`)
      .then((data: any) =>
        setPlanDetails(data.data.researchServiceSubscriptionDTOList)
      );
  }, [monthValue]);

  function addResearchServicesOrderModel(status: any) {
    status === "open" ? (
      setResearchServicesOrderModel(true)
    ) : status === "close" ? (
      setResearchServicesOrderModel(false)
    ) : (
      <></>
    );
  }

  function editResearchServices(status: any) {
    status === "open" ? (
      setResearchServices(true)
    ) : status === "close" ? (
      setResearchServices(false)
    ) : (
      <></>
    );
  }

  const optionClicked = (e: any) => {
    setPlanData(e);
  };

  const updateData = {
    researchServicesPlanId: planData?.researchServicesPlanId,
    productMasterCode: planData?.productMasterCode,
    category: planData?.category,
    month: month,
    planAmount: planData?.planAmount,
    subscriptionPlan: planData?.productName,
    segment: planData?.segment,
    tenure: monthValue,
    isActive: planData?.isActive,
  };
  let getFilterMonth: any = [];
  let getFilterTenure = tenureDetails.map((tenure: any) => {
    getFilterMonth = tenure.tenure;
    return getFilterMonth;
  });

  console.log("getFilterTenure", getFilterMonth);

  return (
    <Box>
      <Grid container sx={{ mb: 5 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h4" gutterBottom>
            Choose Your Plan
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ padding: "0 20% 0 20%", mt: 4 }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={ResearchServiceMonthCounts.map((option: any) => option)}
            getOptionLabel={(options: any) => options.month}
            defaultValue={ResearchServiceMonthCounts[0]}
            onChange={(options: any, newValue) => {
              setMonthValue(newValue.value);
              setMonth(newValue.month);
            }}
            size="small"
            disableClearable
            renderInput={(params) => (
              <TextField {...params} label="Select Month" />
            )}
          />
        </Grid>
      </Grid>

      <Grid container rowSpacing={2} columnSpacing={2}>
        {planDetails &&
          planDetails.map((plan: any) => (
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Paper
                elevation={2}
                sx={{ p: 2, borderRadius: "10px", bgcolor: "#e7e7e7" }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mb: 4 }}>
                    <Typography color={"#fc1e17"} variant="h4" gutterBottom>
                      {" "}
                      {plan.productName}{" "}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h4">
                      ₹
                      {plan.planAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      <span style={{ fontSize: 15 }}>+ GST </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h6">{month}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ padding: "0 20% 0 20%" }}
                  >
                    <Divider
                      sx={{
                        mt: 0.5,
                        mb: 1,
                        bgcolor: "#fc1e17",
                        p: 0.1,
                        borderRadius: "20px",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ padding: "0 20% 0 20%" }}
                  >
                    <Typography gutterBottom>
                      {" "}
                      {plan.segment.includes("Cash") ? (
                        <DoneIcon color={"success"} />
                      ) : (
                        <CloseIcon color={"error"} />
                      )}{" "}
                      Cash
                    </Typography>
                    <Typography gutterBottom>
                      {" "}
                      {plan.segment.includes("F&O") ? (
                        <DoneIcon color={"success"} />
                      ) : (
                        <CloseIcon color={"error"} />
                      )}{" "}
                      F&O
                    </Typography>
                    <Typography gutterBottom>
                      {" "}
                      {plan.segment.includes("Cash + F&O") ? (
                        <DoneIcon color={"success"} />
                      ) : (
                        <CloseIcon color={"error"} />
                      )}
                      Cash + F&O
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      sx={{
                        ":hover": {
                          bgcolor: "black",
                          color: "white",
                        },
                      }}
                      disabled={!plan.isActive}
                      onClick={() => {
                        addResearchServicesOrderModel("open");
                        optionClicked(plan);
                      }}
                    >
                      Order Now{" "}
                    </Button>
                    {sessionStorage.getItem("Profile") === "B" ? (
                      <Button
                        variant="outlined"
                        sx={{ ml: 1 }}
                        onClick={() => {
                          optionClicked(plan);
                          editResearchServices("open");
                        }}
                      >
                        <EditIcon fontSize="small" /> Edit
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        <ResearchServicesOrderModel
          open={researchServicesOrderModel}
          onClose={() => addResearchServicesOrderModel("close")}
          data={planData && planData}
          month={month}
        />
      </Grid>

      {researchServices && (
        <AddNewResearchServicesModel
          open={researchServices}
          onClose={() => editResearchServices("close")}
          data={researchServices && planData && updateData}
          month={month}
        />
      )}
    </Box>
  );
};

export default ResearchPlans;
