import {
  Grid,
  Container,
  Modal,
  Backdrop,
  Box,
  Typography,
  Zoom,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../images/rozy250px.png";
import "../css/aboutus.css";
import Tushar from "../images/Team_Profile_PIC/01_Tushar_v1.png";
import Peeyush from "../images/Team_Profile_PIC/07_Peeyush_v1.png";
import Sunny from "../images/Team_Profile_PIC/05_Sunny_v1.png";
import Manoj from "../images/Team_Profile_PIC/02_Manoj_v1.png";
import Adi from "../images/Team_Profile_PIC/04_Adi_v1.png";
import Rozy from "../images/Team_Profile_PIC/03_Rozy_v1.png";
import Hemika from "../images/Team_Profile_PIC/06_Hemika_v1.png";
import Dhawal from "../images/Team_Profile_PIC/08_Dhawal_v1.png";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import CloseIcon from "@mui/icons-material/Close";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const style = {
  position: "absolute" as "absolute",
  top: "4%",
  left: "32%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "0.5px solid gray",
  borderRadius: "10px",
  boxShadow: 24,
  padding: "10px 32px ",
  backgroundColor: "#e7eeff",
};

const AboutUs = () => {
  const [tushar, setTushar] = useState<any>(false);
  const handleTusharOpen = () => setTushar(true);
  const handleTusharClose = () => setTushar(false);

  const [dhawal, setDhawal] = useState<any>(false);
  const handleDhawalOpen = () => setDhawal(true);
  const handleDhawalClose = () => setDhawal(false);

  const [hemika, setHemika] = useState<any>(false);
  const handleHemikaOpen = () => setHemika(true);
  const handleHemikaClose = () => setHemika(false);

  const [rozy, setRozy] = useState<any>(false);
  const handleRozyOpen = () => setRozy(true);
  const handleRozyClose = () => setRozy(false);

  const [aditya, setAditya] = useState<any>(false);
  const handleAdityaOpen = () => setAditya(true);
  const handleAdityaClose = () => setAditya(false);

  const [manoj, setManoj] = useState<any>(false);
  const handleManojOpen = () => setManoj(true);
  const handleManojClose = () => setManoj(false);

  const [sunny, setSunny] = useState<any>(false);
  const handleSunnyOpen = () => setSunny(true);
  const handleSunnyClose = () => setSunny(false);

  const [peeyush, setPeeyush] = useState<any>(false);
  const handlePeeyushOpen = () => setPeeyush(true);
  const handlePeeyushClose = () => setPeeyush(false);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <section id="Teams">
      <Container maxWidth="xl">
        <Grid item md={12} className="my-5">
          <h1 className="orteam-title">Meet Our Team </h1>
          <p className="ortm-desc">
            We are a growing team of experts and thought leaders ready to
            harness the Force for good. Filled with dynamism and enthusiasm our
            people are wearer of many hats and dedicated to business's success.
          </p>
        </Grid>
        <Grid container className="row" style={{ justifyContent: "center" }}>
          <Grid item md={6} lg={3} className="col-md-6 col-lg-3">
            <div
              className="img-block mb-5"
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#tusharBopche"
            >
              <img
                src={Tushar}
                onClick={handleTusharOpen}
                className="img-fluid "
                alt="Tushar Bopche"
                style={{ maxWidth: "80%" }}
              />
              <div className="content">
                <h4 className="otm-title">Tushar Bopche</h4>
                <p className="text-muted otm-designation">MD & CEO</p>
              </div>
            </div>
          </Grid>
          <Grid item md={6} lg={3} className="col-md-6 col-lg-3">
            <div
              className="img-block mb-5"
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#rozyEfzal"
            >
              <img
                src={Rozy}
                onClick={handleRozyOpen}
                className="img-fluid "
                alt="Rozy Efzal"
                style={{ maxWidth: "80%" }}
              />
              <div className="content">
                <h4 className="otm-title">Rozy Efzal</h4>
                <p className="text-muted otm-designation">
                  Head Business Strategy
                </p>
              </div>
            </div>
          </Grid>
          <Grid item md={6} lg={3} className="col-md-6 col-lg-3">
            <div
              className="img-block mb-5"
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#adityaAgarwala"
            >
              <img
                src={Adi}
                onClick={handleAdityaOpen}
                className="img-fluid "
                alt="Aditya Agarwala"
                style={{ maxWidth: "80%" }}
              />
              <div className="content">
                <h4 className="otm-title">Aditya Agarwala</h4>
                <p className="text-muted otm-designation">
                  Head of Research & Investment
                </p>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container className="row" style={{ justifyContent: "center" }}>
          <Grid item md={6} lg={3} className="col-md-6 col-lg-3 ">
            <div
              className="img-block mb-5"
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#manojSharma"
            >
              <img
                src={Manoj}
                onClick={handleManojOpen}
                className="img-fluid "
                alt="Manoj Sharma"
                style={{ maxWidth: "80%" }}
              />
              <div className="content">
                <h4 className="otm-title">Manoj Sharma</h4>
                <p className="text-muted otm-designation">Head - Alliances</p>
              </div>
            </div>
          </Grid>
          <Grid item md={6} lg={3} className="col-md-6 col-lg-3">
            <div
              className="img-block mb-5"
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#sunnyTrisal"
            >
              <img
                src={Sunny}
                onClick={handleSunnyOpen}
                className="img-fluid "
                alt="Sunny Trisal"
                style={{ maxWidth: "80%" }}
              />
              <div className="content">
                <h4 className="otm-title">Sunny Trisal</h4>
                <p className="text-muted otm-designation">Head Fixed Income</p>
              </div>
            </div>
          </Grid>
          <Grid item md={6} lg={3} className="col-md-6 col-lg-3">
            <div
              className="img-block mb-5"
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#peeyushAgarwal"
            >
              <img
                src={Peeyush}
                onClick={handlePeeyushOpen}
                className="img-fluid"
                style={{ maxWidth: "80%" }}
                alt="Peeyush Agarwal"
              />
              <div className="content">
                <h4 className="otm-title">Peeyush Agrawal</h4>
                <p className="text-muted otm-designation">
                  Lead Advisor,Brainstation
                </p>
              </div>
            </div>
          </Grid>
        </Grid>

        {/*-------- Modals --------*/}

        {/* Tushar */}
        <Modal
          open={tushar}
          onClose={handleTusharClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
        >
          <Zoom in={tushar}>
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handleTusharClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="team-member-Icon">
                  <img
                    src={Tushar}
                    className="img-responsive team-member_Img"
                    alt=""
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <h5
                    style={{ boxSizing: "initial", margin: "0px" }}
                    className="modal-title tm-title"
                    id="exampleModalCenterTitle"
                  >
                    Tushar Bopche
                  </h5>
                  <span
                    className="modal-title tm-designation"
                    id="exampleModalCenterTitle"
                  >
                    MD & CEO
                  </span>
                  <Stack direction={"row"} spacing={2} justifyContent="center">
                    <a
                      href="https://twitter.com/tusharbopche"
                      className="icon-hover"
                      target="_blank"
                    >
                      <TwitterIcon />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/tusharvikrambopche/"
                      className="icon-hover"
                      target="_blank"
                    >
                      <LinkedInIcon />
                    </a>
                  </Stack>
                </div>
              </div>
              <div className="modal-body">
                <p className="modal-title tm-desc" id="exampleModalCenterTitle">
                  An Engineering Graduate with Master's degree in Marketing &
                  Finance, having an impressive command & prodigious expertise
                  in the Finance industry. He has a colossal experience of 16+
                  years of working as a Product Head in the leading Finance &
                  Investment service companies. His proven skills in management
                  strategies and being a thought leader made him co-found a
                  non-profit NGO "BrainStation India Foundation “with the vision
                  to support talent and aspirations of budding entrepreneurs
                  spear heading InvestValue Fintech Pvt Ltd.
                </p>
              </div>
            </Box>
          </Zoom>
        </Modal>

        {/* Dhawal */}
        <Modal
          open={dhawal}
          onClose={handleDhawalClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
        >
          <Zoom in={dhawal}>
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handleDhawalClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="team-member-Icon">
                  <img
                    src={Dhawal}
                    className="img-responsive team-member_Img"
                    alt=""
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <h5
                    style={{ boxSizing: "initial", margin: "0px" }}
                    className="modal-title tm-title"
                    id="exampleModalCenterTitle"
                  >
                    Dhawal Shah
                  </h5>
                  <span
                    className="modal-title tm-designation"
                    id="exampleModalCenterTitle"
                  >
                    CTO
                  </span>
                  <Stack direction={"row"} spacing={2} justifyContent="center">
                    <a className="icon-hover" target="_blank">
                      <TwitterIcon />
                    </a>
                    <a className="icon-hover" target="_blank">
                      <LinkedInIcon />
                    </a>
                  </Stack>
                </div>
              </div>
              <div className="modal-body">
                <p className="modal-title tm-desc" id="exampleModalCenterTitle">
                  An engineering graduate having 15 years of leadership and
                  mentoring experience, accomplished and qualified IT leader in
                  the industry with extensive program, project and support
                  management.
                </p>
              </div>
            </Box>
          </Zoom>
        </Modal>

        {/* Hemika */}
        <Modal
          open={hemika}
          onClose={handleHemikaClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
        >
          <Zoom in={hemika}>
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handleHemikaClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="team-member-Icon">
                  <img
                    src={Hemika}
                    className="img-responsive team-member_Img"
                    alt=""
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <h5
                    style={{ boxSizing: "initial", margin: "0px" }}
                    className="modal-title tm-title"
                    id="exampleModalCenterTitle"
                  >
                    Hemika Tanwar
                  </h5>
                  <span
                    className="modal-title tm-designation"
                    id="exampleModalCenterTitle"
                  >
                    Chief People Officer
                  </span>
                  <Stack direction={"row"} spacing={2} justifyContent="center">
                    <a className="icon-hover" target="_blank">
                      <TwitterIcon />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/hemika-tanwar-30a0131a/"
                      className="icon-hover"
                      target="_blank"
                    >
                      <LinkedInIcon />
                    </a>
                  </Stack>
                </div>
              </div>
              <div className="modal-body">
                <p className="modal-title tm-desc" id="exampleModalCenterTitle">
                  Highly accomplished, proactive, HR professional dedicated to
                  providing impressive human resource operational leadership for
                  small and large corporations with over 15 years of experience
                  specializing in financial, technology and banking sector.
                  Proven success in building high-performing teams, developing
                  and re-engineering HR functions with a background in large
                  corporates ,start ups and mandated environments. Possessing a
                  management degree in HR decorated with special awards in the
                  business school, she is a key member in strategy and
                  developmental plans.
                </p>
              </div>
            </Box>
          </Zoom>
        </Modal>

        {/* Rozy */}
        <Modal
          open={rozy}
          onClose={handleRozyClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
        >
          <Zoom in={rozy}>
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handleRozyClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="team-member-Icon">
                  <img
                    src={Rozy}
                    className="img-responsive team-member_Img"
                    alt=""
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <h5
                    style={{ boxSizing: "initial", margin: "0px" }}
                    className="modal-title tm-title"
                    id="exampleModalCenterTitle"
                  >
                    Rozy Efzal
                  </h5>
                  <span
                    className="modal-title tm-designation"
                    id="exampleModalCenterTitle"
                  >
                    Head Business Strategy
                  </span>
                  <Stack direction={"row"} spacing={2} justifyContent="center">
                    <a className="icon-hover" target="_blank">
                      <TwitterIcon />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/rozy-efzal-8012a54/"
                      className="icon-hover"
                      target="_blank"
                    >
                      <LinkedInIcon />
                    </a>
                  </Stack>
                </div>
              </div>
              <div className="modal-body">
                <p className="modal-title tm-desc" id="exampleModalCenterTitle">
                  A Computer Science graduate and postgraduate in Marketing &
                  Human Resource, is a dynamic and enterprising business leader
                  with multifaceted previous career roles in Digital Marketing,
                  Real estate, Business development, Team Building and Media &
                  E-commerce fields. An experienced leader and entrepreneur with
                  her innovative customer-centric & problem-solving approach,
                  she is all set and committed to make investvalue Fintech Pvt
                  Ltd, a platform which is determined to create an impact with
                  our competence in building technology powered innovation in
                  financial investment space.
                </p>
              </div>
            </Box>
          </Zoom>
        </Modal>

        {/* Aditya */}
        <Modal
          open={aditya}
          onClose={handleAdityaClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
        >
          <Zoom in={aditya}>
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handleAdityaClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="team-member-Icon">
                  <img
                    src={Adi}
                    className="img-responsive team-member_Img"
                    alt=""
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <h5
                    style={{ boxSizing: "initial", margin: "0px" }}
                    className="modal-title tm-title"
                    id="exampleModalCenterTitle"
                  >
                    Aditya Agarwala
                  </h5>
                  <span
                    className="modal-title tm-designation"
                    id="exampleModalCenterTitle"
                  >
                    Head of Research & Investment
                  </span>
                  <Stack direction={"row"} spacing={2} justifyContent="center">
                    <a
                      href="https://twitter.com/agarwala_adi"
                      className="icon-hover"
                      target="_blank"
                    >
                      <TwitterIcon />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/aditya-agarwala-cmt-28ba0629/"
                      className="icon-hover"
                      target="_blank"
                    >
                      <LinkedInIcon />
                    </a>
                  </Stack>
                </div>
              </div>
              <div className="modal-body">
                <p className="modal-title tm-desc" id="exampleModalCenterTitle">
                  Aditya Agarwala, is a CMT (Market Technician Association –
                  USA) certified Technical Research analyst with over 13 years
                  of extensive experience in the field of research and money
                  management. His expertise lies in stock picking with the use
                  of his in-depth knowledge in technical analysis. Aditya is
                  sought after by popular business media like CNBC-TV18, ET Now,
                  Bloomberg Quint, ET Markets, Moneycontrol.com to name a few.
                </p>
              </div>
            </Box>
          </Zoom>
        </Modal>

        {/* Manoj */}
        <Modal
          open={manoj}
          onClose={handleManojClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
        >
          <Zoom in={manoj}>
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handleManojClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="team-member-Icon">
                  <img
                    src={Manoj}
                    className="img-responsive team-member_Img"
                    alt=""
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <h5
                    style={{ boxSizing: "initial", margin: "0px" }}
                    className="modal-title tm-title"
                    id="exampleModalCenterTitle"
                  >
                    Manoj Sharma
                  </h5>
                  <span
                    className="modal-title tm-designation"
                    id="exampleModalCenterTitle"
                  >
                    Head - Alliances
                  </span>
                  <Stack direction={"row"} spacing={2} justifyContent="center">
                    <a
                      href="https://twitter.com/Manoj911"
                      className="icon-hover"
                      target="_blank"
                    >
                      <TwitterIcon />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/manoj-sharma-21a5a224/"
                      className="icon-hover"
                      target="_blank"
                    >
                      <LinkedInIcon />
                    </a>
                  </Stack>
                </div>
              </div>
              <div className="modal-body">
                <p className="modal-title tm-desc" id="exampleModalCenterTitle">
                  A market veteran with an MBA degree in Finance, is a Product
                  and Sales enthusiast with an extensive experience of more than
                  15 years. His expertise includes Private Equity, Commodity,
                  Capital Market, Mutual funds, Portfolio Management, Business
                  Development, Team Management and Financial Analysis. A well
                  versed personality of trade and financial products brings the
                  proficiency of wealth management to the tech platform.
                </p>
              </div>
            </Box>
          </Zoom>
        </Modal>

        {/* Sunny */}
        <Modal
          open={sunny}
          onClose={handleSunnyClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
        >
          <Zoom in={sunny}>
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handleSunnyClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="team-member-Icon">
                  <img
                    src={Sunny}
                    className="img-responsive team-member_Img"
                    alt=""
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <h5
                    style={{ boxSizing: "initial", margin: "0px" }}
                    className="modal-title tm-title"
                    id="exampleModalCenterTitle"
                  >
                    Sunny Trishal
                  </h5>
                  <span
                    className="modal-title tm-designation"
                    id="exampleModalCenterTitle"
                  >
                    Head Fixed Income
                  </span>
                  <Stack direction={"row"} spacing={2} justifyContent="center">
                    <a className="icon-hover" target="_blank">
                      <TwitterIcon />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/sunny-trisal-87a104146/"
                      className="icon-hover"
                      target="_blank"
                    >
                      <LinkedInIcon />
                    </a>
                  </Stack>
                </div>
              </div>
              <div className="modal-body">
                <p className="modal-title tm-desc" id="exampleModalCenterTitle">
                  A seasoned professional with over 16 years of experience in
                  varied financial services like stock exchange, banking and
                  broking. His expertise lies in product development & business
                  development in fixed income markets. As part of his earlier
                  stint at BSE, where he led development and launch of reporting
                  and settlement platform (NDS RST), tri party repo, new debt
                  segment (BSE NDS), Interest rate futures & options,
                  Non-competitive bidding (NCB GSEC), BSE Direct platform &
                  mobile application and Request for Quote platform (BSE RFQ).
                  Sunny has earned an Engineering degree in Electronics and
                  Telecom from K.J.Somaya College of Engineering further adding
                  MMS degree in marketing from Mumbai University.
                </p>
              </div>
            </Box>
          </Zoom>
        </Modal>

        {/* Peeyush */}
        <Modal
          open={peeyush}
          onClose={handlePeeyushClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
        >
          <Zoom in={peeyush}>
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handlePeeyushClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="team-member-Icon">
                  <img
                    src={Peeyush}
                    className="img-responsive team-member_Img"
                    alt=""
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <h5
                    style={{ boxSizing: "initial", margin: "0px" }}
                    className="modal-title tm-title"
                    id="exampleModalCenterTitle"
                  >
                    Peeyush Agrawal
                  </h5>
                  <span
                    className="modal-title tm-designation"
                    id="exampleModalCenterTitle"
                  >
                    Lead Advisor,Brainstation
                  </span>
                  <Stack direction={"row"} spacing={2} justifyContent="center">
                    <a className="icon-hover" target="_blank">
                      <TwitterIcon />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/peeyush-agrawal-7843634/"
                      className="icon-hover"
                      target="_blank"
                    >
                      <LinkedInIcon />
                    </a>
                  </Stack>
                </div>
              </div>
              <div className="modal-body">
                <p className="modal-title tm-desc" id="exampleModalCenterTitle">
                  A technology leader with business acumen to build enterprise
                  level software across industries. He is a University gold
                  medallist and a possessing combination of new age technology
                  and management skills. Worked extensively in the area of
                  conceptualizing SAAS based cloud platform, agile technology
                  and software delivery. He is sharing his rich industry
                  experience by being associated with Niti Ayog's flagship
                  initiative to promote innovation and entrepreneurship Atal
                  Innovation Mission and co-founded a non-profit NGO named
                  BrainStation India Foundation to provide platform to students
                  and innovators to start their entrepreneurial journey.
                </p>
              </div>
            </Box>
          </Zoom>
        </Modal>
      </Container>
    </section>
  );
};

export default AboutUs;