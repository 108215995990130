/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { InformationGrid } from "./InformationGrid";
import { TableGridForAllData } from "./TableGridForAllData";
import { HorizontalTabs } from "./HorizontalTabs";
import { Box, Grid, Paper } from "@mui/material";
import DropDownFiles from "../EmployeeDashBoard/DropDownFiles";
import Carousel from "react-material-ui-carousel";
import ProductConnect from "./ProductConnect";
import { useQuery } from "react-query";
import { listOfAllEmployeeFiles } from "../../api/Api";

export const DistributorDashboard = () => {

  const { data } = useQuery("getFile", () =>
  listOfAllEmployeeFiles("FOCUS_PRODUCT")
);

  function Item(props: any) {
    return (
      <Paper>
        <img src={props.item.filePath} alt="Neo fin desk" width={500} height={250}/> {props.i}
      </Paper>
    );
  }
  return (
    <Box sx={{ backgroundColor: "#dedede" }}>
      <Box sx={{ margin: "-1% 1% 0% 2%" }}>
        <InformationGrid />
        <Box sx={{ marginTop: 2 }}>
          <Grid container justifyContent={"space-between"} rowSpacing={3}>
            <Grid item xs={12} sm={3.5} md={3} lg={3}>
              <DropDownFiles />
            </Grid>
            <Grid item xs={12} sm={8} md={8.6} lg={5}>
              <Carousel sx={{ width: "100%" }} navButtonsAlwaysVisible>
              {data && data.data.reportResponseDTO.map((item:any, i:any) => (
                  <Item key={i} item={item} />
                ))}
              </Carousel>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <ProductConnect/>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <TableGridForAllData />
    </Box>
  );
};
