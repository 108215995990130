import { Autocomplete, Box, Button, CircularProgress, Divider, Fab, Grid, Modal, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { CREATE_NEW_AIF_SCHEME, CREATE_NEW_PMS_SCHEME, PRODUCT_INSURANCE_API, PRODUCT_lOAN_API, SAVE_AMC } from '../../utils/API_Names';
import { toast } from 'react-toastify';
import { useQuery } from "react-query";
import { AifGetById, ManagerGetById, PmsGetById } from '../../api/Api';
import { productTypeEnum } from "../../Constants/ProductTypeEnum";
import RemoveIcon from '@mui/icons-material/Remove';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { IncomeType } from '../../Constants/IncomeType';
import { changeDate } from '../../utils/Helper';


const AddScheme = (props: any) => {

    const { amcId } = props;
    const { amcCode } = props;
    const { productCode } = props;
    const {amcName} = props;
    const [schemeModelOpen, setSchemeModelOpen] = useState(false);
    const [showCircularProgress, setshowCircularProgress] = useState(false);
    const [Color, SetColor] = React.useState<any>("");
    const [Color2, SetColor2] = React.useState<any>("");
    const [schemeCode, setSchemeCode] = React.useState<any>("");
    const [managerCode, setManagerCode] = React.useState<any>("");
    const [openDate,setOpenDate] = useState(new Date())
    const [closeDate,setCloseDate] = useState(new Date())
    const [incomeType, setIncomeType] = React.useState<any>(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [hybridPercentage, setHybridPercentage] = React.useState();
    const [selectedProductType, setSelectedProductType] = useState(productCode);
    const [loanType, setLoanType] = useState('');
    const [companyLogo, setCompanyLogo] = useState<any>();
    const [CompanyLogoInsu, setCompanyLogoInsu] = useState<any>();
    const [edit, setedit] = useState(true);

    const [schemeManager, setSchemeManager] = useState([
        {
            "fundManagerAmc": '',
            "fundManagerAmcId": amcId,
            "fundManagerAum": '',
            "fundManagerCode": '',
            "fundManagerDescription": '',
            "fundManagerFundCount": '',
            "fundManagerImage": '',
            "fundManagerName": '',
            "productId": productCode === "AIF" ? productTypeEnum.ALTERNATIVE_INVESTMENT_FUND : productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM,
            "productName": productCode
        },
    ]);
    console.log('schememanager',schemeManager)

    const handleAddRow = () => {
        setSchemeManager([...schemeManager, { 
            "fundManagerAmc": '',
            "fundManagerAmcId": amcId,
            "fundManagerAum": '',
            "fundManagerCode": '',
            "fundManagerDescription": '',
            "fundManagerFundCount": '',
            "fundManagerImage": '',
            "fundManagerName": '',
            "productId": productCode === "AIF" ? productTypeEnum.ALTERNATIVE_INVESTMENT_FUND : productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM,
            "productName": productCode
      }]);
      };

      const handleInputChange = (index:any, field:any, value:any) => {
        const updatedDetails:any = [...schemeManager];
        updatedDetails[index][field] = value;
        setSchemeManager(updatedDetails);
      };

    const handleRemoveRow = (index: any) => {
        const updatedDetails = schemeManager.filter((_, i) => i !== index);
        setSchemeManager(updatedDetails);
    };

    const { data: aifData } = useQuery(["AIFGetById", schemeCode], () => AifGetById(schemeCode), {
        enabled: !!schemeCode && productCode === "AIF",
        onSuccess: (data: any) => { SetColor("warning") },
        onError: (data: any) => SetColor("success"),
        retry: false,
        refetchOnWindowFocus: false,
    },)

    const { data: pmsData } = useQuery(["PMSGetById", schemeCode], () => PmsGetById(schemeCode), {
        enabled: !!schemeCode && productCode === "PMS",
        onSuccess: (data: any) => { SetColor("warning") },
        onError: (data: any) => SetColor("success"),
        retry: false,
        refetchOnWindowFocus: false,
    },)

    const { data: managerData } = useQuery(["ManagerGetById", managerCode], () => ManagerGetById(managerCode), {
        enabled: !!managerCode,
        onSuccess: (data: any) => { SetColor2("warning") },
        onError: (data: any) => SetColor2("success"),
        retry: false,
        refetchOnWindowFocus: false,
    },)
    console.log('managerdata:',managerData)

    function schemeModel(status: any) {
        return status === "open" ?
            setSchemeModelOpen(true)
            : status === "close" ? setSchemeModelOpen(false) : null
    }

    const {
        register,
        handleSubmit,
        formState: { errors } } = useForm({ mode: "onChange" });

    const registerOptions = {
        productName:{required:"Enter Scheme Name"},
        schemeCode: {
            required: "Please Enter Scheme Code",
            onBlur: (e: any) => setSchemeCode(!!e.target.value ? e.target.value : ""),
            pattern: {
                value: /^[A-Z0-9]+$/,
                message: "Invalid Scheme Code"
            }
        },
        schemeClassification: { required: "Please Enter Scheme Classification" },
        schemeInceptionDate: { required: "Please Enter Scheme Inception Date" },
        openDate: { required: "Please Enter Open Date" },
        closeDate: { required: "Please Enter Close Date" },
        schemeBenchmarkName: { required: "Please Enter Scheme Benchmark Name" },
        schemeFeeStructure: { required: "Please Enter Fee Structure" },
        schemePlanType: { required: "Please Enter Scheme Plan Type" },
        description: { required: "Please Enter Description" },
        managementFees: { required: "Please Enter Management Fees" },
        fixedFee: { required: "Please Enter Fixed Fee" },
        incomeRate: { required: "Please Enter Income Rate" },
        revenueRate: { required: "Please Enter Revenue Rate" },
        revenueCreditFee: { required: "Please Enter Revenue Credit Fee" },
        benchmarkName: { required: "Please Enter Benchmark Name" },
        date: { required: "Please Enter Date" },
        planName: { required: "Please Enter Plan Name" },
        schemeExitLoad: { required: "Please Enter Scheme Exit Load" },
        schemeObjective: { required: "Please Enter Scheme Objective" },
        fundManagerCode: {
            required: "Please Enter Fund Manager Code",
            onBlur: (e: any) => setManagerCode(!!e.target.value ? e.target.value : "")
        },
        fundManagerName: { required: "Please Enter Fund Manager Name" },
        fundManagerAum: { required: "Please Enter Fund Manager Aum" },
        fundManagerFundCount: { required: "Please Enter Fund Manager Fund Count" },
        loanType:{required: "Please Enter Loan Type"},
        segment: {required: "please Enter Segment "},
        maxTenure: {required: "Please Enter MaxTenure"},
        firstYearReturn: {required: "Please Enter first Year Return"},
        secondYearReturn: {required: "Please Enter Second Year Return"},
        thirdYearReturn: {required: "Please Enter Third Year Return"},



    }



    const onsubmit = (data: any) => {
        var schemeDto = {
            "incomeType": data.incomeType,
            "isFocusedFund": data.isFocusedFund,
            "schemeId": data.schemeId,
            "schemeCode": data.schemeCode,
            "amcId": amcId,
            "amcCode": amcCode,
            //"amcName": amcName,
            "schemeClassification": data.schemeClassification,
            "schemeInceptionDate": data.schemeInceptionDate,
            "schemeBenchmarkName": data.schemeBenchmarkName,
            "schemeMinInvestment": data.schemeMinInvestment,
            "schemeExitLoad": data.schemeExitLoad,
            "schemeFeeStructure": data.schemeFeeStructure,
            "schemeFundManager": data.schemeFundManager,
            "schemeObjective": data.schemeObjective,
            "schemeRiskGrade": data.schemeRiskGrade,
            "schemeExpenseRatio": data.schemeExpenseRatio,
            "schemeIsin": data.schemeIsin,
            "schemeAmfiCode": data.schemeAmfiCode,
            "schemePlanType": data.schemePlanType,
            "schemeRtaName": data.schemeRtaName,
            "schemeComments": data.schemeComments,
            "schemeMinLockingPeriod": data.schemeMinLockingPeriod,
            "fundManagers": [
                {
                  "fundManagerAmc": data.fundManagerAmc,
                  "fundManagerAmcId": data.fundManagerAmcId,
                  "fundManagerAum": data.fundManagerAum,
                  "fundManagerCode": data.fundManagerCode,
                  "fundManagerDescription": data.fundManagerDescription,
                  "fundManagerFundCount": data.fundManagerFundCount,
                  "fundManagerImage": data.fundManagerImage,
                  "fundManagerName": data.fundManagerName
                }
              ],
            "fundPerformance": {
                "activeReturns1month": data.activeReturns1month,
                "activeReturns1year": data.activeReturns1year,
                "activeReturns3month": data.activeReturns3month,
                "activeReturns3year": data.activeReturns3year,
                "activeReturns5year": data.activeReturns5year,
                "activeReturns6month": data.activeReturns6month,
                "activeReturnsSi": data.activeReturnsSi,
                "aum": data.aum,
                "benchmarkName": data.benchmarkName,
                "bmRet1m": data.bmRet1m,
                "bmRet1y": data.bmRet1y,
                "bmRet2y": data.bmRet2y,
                "bmRet3m": data.bmRet3m,
                "bmRet3y": data.bmRet3y,
                "bmRet5y": data.bmRet5y,
                "bmRet6m": data.bmRet6m,
                "bmRetInce": data.bmRetInce,
                "catRet1m": data.catRet1m,
                "catRet1y": data.catRet1y,
                "catRet3m": data.catRet3m,
                "catRet3y": data.catRet3y,
                "catRet5y": data.catRet5y,
                "catRet6m": data.catRet6m,
                "count1month": data.count1month,
                "count1year": data.count1year,
                "count3month": data.count3month,
                "count3year": data.count3year,
                "count5year": data.count5year,
                "count6month": data.count6month,
                "date": data.date,
                "nav": data.nav,
                "planName": data.planName,
                "rankingRank1month": data.rankingRank1month,
                "rankingRank1year": data.rankingRank1year,
                "rankingRank3month": data.rankingRank3month,
                "rankingRank3year": data.rankingRank3year,
                "rankingRank5year": data.rankingRank5year,
                "rankingRank6month": data.rankingRank6month,
                "schemeCode": data.schemeCode,
                "schemeRet1m": data.schemeRet1m,
                "schemeRet1y": data.schemeRet1y,
                "schemeRet2y": data.schemeRet2y,
                "schemeRet3m": data.schemeRet3m,
                "schemeRet3y": data.schemeRet3y,
                "schemeRet5y": data.schemeRet5y,
                "schemeRet6m": data.schemeRet6m,
                "schemeRetInce": data.schemeRetInce
            },             
            "portfolioCharacteristics": {
                "avgCreditRating": data.avgCreditRating,
                "avgMaturityYrs": data.avgMaturityYrs,
                "avgMktCap": data.avgMktCap,
                "date": data.date,
                "dividendYield": data.dividendYield,
                "macaulayDurationYrs": data.macaulayDurationYrs,
                "modifiedDurationYrs": data.modifiedDurationYrs,
                "pb": data.pb,
                "pe": data.pe,
                "schemeAssetClassName": data.schemeAssetClassName,
                //"schemeCode": data.schemeCode,
                //"schemeId": data.schemeId,
                "totalStocks": data.totalStocks,
                "yieldToMaturity": data.yieldToMaturity
            },
            "fundComposition": {
            "date": data.date,
            "cash": data.cash,
            "debt": {},
            "equity": data.equity,
            },
            "revenueCreditFee": data.revenueCreditFee,
            "variableFee": data.variableFee,
            "hurdleFee": data.hurdleFee,
            "performanceFee": data.performanceFee,
            "managementFees": data.managementFees,
            "fixedFee": data.fixedFee,
            "productTypeCode": productCode === "AIF" ? productTypeEnum.ALTERNATIVE_INVESTMENT_FUND : productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM,
            "productName": data.productName,
            "description": data.description,
            "brochureUrl": data.brochureUrl,
            "videoUrl": data.videoUrl,
            "formUrl": data.formUrl,
            "minAmount": data.minAmount,
            "maxAmount": data.maxAmount,
            "minTenure": data.minTenure,
            "maxTenure": data.maxTenure,
            "openDate": changeDate(data.openDate),
            "closeDate": changeDate(data.closeDate),
            "exitLoad": data.exitLoad,
            "companyMasterCode": data.companyMasterCode,
            "isActive": data.isActive,
        }
        if(productCode==="AIF")
        {
            axios.post(CREATE_NEW_AIF_SCHEME,schemeDto).then((res:any)=>res && toast.success(res?.data?.status?.message?.title)).then(()=>{window.location.reload()}).catch((err:any)=>{
                toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title);
              })
        }
        else if (productCode==="PMS")
        {
            axios.post(CREATE_NEW_PMS_SCHEME,schemeDto).then((res:any)=>res && toast.success(res?.data?.status?.message?.title)).then(()=>{window.location.reload()}).catch((err:any)=>{
                toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title);
        })
        }
    }
    const handleChange = (e:any) => {
        const productType = e.target.value;
        setSelectedProductType(productType);
        console.log("Selected Product Type:", productType);
      };


const onsubmitOne = async (data: any) => {
    try {
      // Prepare the financial DTO
      const financialDTo = {
        amcId: amcId,
        loanType: data.loanType,
        segment: data.segment,
        productName: data.productName,
        maxTenure: data.maxTenure,
        firstYearReturn: data.firstYearReturn,
        secondYearReturn: data.secondYearReturn,
        thirdYearReturn: data.thirdYearReturn,
        // formData: formData.get("LoanLogo"),
      };
    //   console.log("myformdatatat",financialDTo)
  
      const formData = new FormData();
      formData.append("loanLogo", companyLogo);
      formData.append("loanMasterDTO",JSON.stringify(financialDTo));
      // Make the POST request with FormData
      const response = await axios.post(PRODUCT_lOAN_API, formData);

      // Display success message and reload page
      toast.success(response?.data?.status?.message?.title);
      window.location.reload();
    } catch (error:any) {
      // Display error message
      toast.error(
        error?.response?.data?.status?.message?.title ||
          error?.response?.data?.title ||
          "An error occurred"
      );
    }
  };
  
const onsubmittWO = (data: any) => {
    // Financial DTO
    var financialDTo = {
      "amcId": amcId,
      "productName": data.productName,
      "insuranceType": data.insuranceType,
      "paymentType": data.paymentType,
      "category": data.category,
      "firstYearRevenue": data.firstYearRevenue,
      "secondYearRevenue": data.secondYearRevenue,
      "thirdYearRevenue": data.thirdYearRevenue
    };
  
    // Create FormData and append data
    const formData = new FormData();
    formData.append("insLogo", CompanyLogoInsu);
    formData.append("insuranceDTO", JSON.stringify(financialDTo));
  
    // Post data using axios
    axios.post(PRODUCT_INSURANCE_API, formData)
      .then((res: any) => {
        toast.success(res?.data?.status?.message?.title);
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.status?.message?.title ? err?.response?.data?.status?.message?.title : err?.response?.data?.title);
      });
  
  };
  

   
      const LoanTypes = [
        { loanType: 'Education Loan' },
        { loanType: 'Home Loan' },
        { loanType: 'LAP' },
        { loanType: 'LAS' },
        { loanType: 'Personal Loan' },
        { loanType: 'Vehicle Loan' },
        { loanType: 'Business Loan' },
 
      ];
      const LoanSegment = [
        { segment: 'Retail Loan' },
        { segment: 'Business Loan' },
        { segment: 'LAP' },
        { segment: 'LAS' },
      ];

      const InsuranceType = [
        { insuranceType: 'Life Insurance Product Category' },
        { insuranceType: 'Heath Insurance' },
     
 
      ];

      const InsuranceCategory = [
        { category: 'Traditional' },
        { category: 'Term ' },
        { category: 'ULIP' },
        { category: 'Health' },
        { category: 'Pension ' },
 
      ];
      


console.log("myyytasfd",productCode)
// console.log("myyytasfd",selectedProductType)
    return (
        <>
            <Button variant='contained' onClick={() => schemeModel("open")} > <AddIcon /> Add Scheme </Button>

            <Modal
                keepMounted
                open={schemeModelOpen}
                onClose={() => schemeModel("close")}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography variant='h4' textAlign={"center"} gutterBottom> Add New Scheme </Typography>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant='h6' textAlign={"left"}>Scheme Details</Typography>
                            <Divider />
                        </Grid>
                        <Grid container spacing={2} mb={2}>
                         <Grid item xs={12} sm={12} md={4} lg={3}>
      <form onSubmit={handleSubmit(onsubmit)}>
        {/* <TextField
          id="outlined-select-product-type"
          label="Product Type"
          select
          variant="outlined"
          fullWidth
          {...register("productType")}
          onChange={handleChange}
          defaultValue={selectedProductType}
        >
        <MenuItem value="PMS">PMS</MenuItem>
        <MenuItem value="AIF">AIF</MenuItem>
          <MenuItem value="Loan">Loan</MenuItem>
          <MenuItem value="Insurance">Insurance</MenuItem>
        </TextField> */}
        <TextField
                                    id="outlined-select-product-type"
                                    label="Product Type"
                                    variant="outlined"
                                    className={edit ? "textDisable" : ""}
                                    value={productCode}
                                    InputLabelProps={{ shrink: true }}
                                    color={!!Color ? Color : ""}
                                    error={errors.productType ? true : false}
                                    helperText={!errors.productType ? "" : errors.productType.message + ""}
                                    fullWidth
                                    {...register("productType")}
                                />

        {/* Other form fields and submit button go here */}
      </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                  style={{}}
                                    id="outlined-basic"
                                    label="AMC Name"
                                    variant="outlined"
                                    className={edit ? "textDisable" : ""}
                                    value={amcName}
                                    InputLabelProps={{ shrink: true }}
                                    color={!!Color ? Color : ""}
                                    error={errors.amcName ? true : false}
                                    helperText={!errors.amcName ? "" : errors.amcName.message + ""}
                                    fullWidth
                                    {...register("amcName")}
                                />
                            </form>
                        </Grid>
                        </Grid>
 
                       
                      { selectedProductType==='Insurance' || selectedProductType === 'Loan'?(
                          selectedProductType === 'Loan' ? (
                            <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
          <form onSubmit={handleSubmit(onsubmitOne)} id="form2">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={LoanTypes}
              getOptionLabel={(option) => option.loanType}
              fullWidth
              onChange={(event: any, newValue: any) => {
                console.log("Selected Loan Type:", newValue?.loanType);
                setLoanType(newValue?.loanType);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Loan Type"
                  fullWidth
                  // Use the register function with proper TypeScript typings
          {...(register("loanType", registerOptions.loanType) as any)}
          error={Boolean(errors.loanType)}
          helperText={errors.loanType?.message}
                />
              )}
            />
          </form>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <form onSubmit={handleSubmit(onsubmitOne)}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Product"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.productName ? true : false}
                                        helperText={!errors.productName ? "" : errors.productName.message + ""}
                                        fullWidth
                                        {...register("productName",registerOptions.productName)}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
          <form onSubmit={handleSubmit(onsubmitOne)} id="form2">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={LoanSegment}
              getOptionLabel={(option) => option.segment}
              fullWidth
              onChange={(event: any, newValue: any) => {
                console.log("Selected Loan Type:", newValue?.segment);
                setLoanType(newValue?.segment);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Loan Segment"
                  fullWidth
                  {...register("segment",registerOptions.segment)}
                />
              )}
            />
          </form>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <form onSubmit={handleSubmit(onsubmitOne)}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Tenur"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.maxTenure ? true : false}
                                        helperText={!errors.maxTenure ? "" : errors.maxTenure.message + ""}
                                        fullWidth
                                        {...register("maxTenure",registerOptions.maxTenure)}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <form onSubmit={handleSubmit(onsubmitOne)}>
                                    <TextField
                                        id="outlined-basic"
                                        label="First Year"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.firstYearReturn ? true : false}
                                        helperText={!errors.firstYearReturn ? "" : errors.firstYearReturn.message + ""}
                                        fullWidth
                                        {...register("firstYearReturn",registerOptions.firstYearReturn)}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <form onSubmit={handleSubmit(onsubmitOne)}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Second Year"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.secondYearReturn ? true : false}
                                        helperText={!errors.secondYearReturn ? "" : errors.secondYearReturn.message + ""}
                                        fullWidth
                                        {...register("secondYearReturn",registerOptions.secondYearReturn)}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <form onSubmit={handleSubmit(onsubmitOne)}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Third Year"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.thirdYearReturn ? true : false}
                                        helperText={!errors.thirdYearReturn ? "" : errors.thirdYearReturn.message + ""}
                                        fullWidth
                                        {...register("thirdYearReturn",registerOptions.thirdYearReturn)}
                                    />
                                </form>
                            </Grid>
                        

                            <Grid item xs={12} sm={12} md={4} lg={3} ml={1}>
                            <form onSubmit={handleSubmit(onsubmitOne)}>
					<Box>
					<Button variant="contained" component="label">
						Upload Company Logo 
						<input
						hidden
						type="file"
						accept=".jpg,.jpeg,.png,.pdf"
						name="companyLogo"
						onChange={(event: any) =>
							setCompanyLogo(event.target.files[0])
						}
						/>
					</Button>
					<Typography variant="caption" sx={{ marginLeft: "0.5rem" }}>
						{companyLogo == undefined
						? "No file choosen"
						: companyLogo.name}
					</Typography>
					</Box>
                    </form>
					</Grid>


                            <Grid item xs={12} sm={12} md={12} lg={12} textAlign={"center"}>
                                <form onSubmit={handleSubmit(onsubmitOne)}>
                                    <Button type='submit' variant='contained' disabled={showCircularProgress}>
                                        {showCircularProgress === true ? (
                                            <CircularProgress
                                                color="secondary"
                                                size={20}
                                                thickness={5}
                                            />
                                        ) : (
                                            ""
                                        )}{"  "}
                                        Submit</Button>
                                </form>
                            </Grid>
                           
                           </Grid>
                          ):(
                            // *****************************START INSURANCE*******************************88
                          <Grid container spacing={2}>
                         <Grid item xs={12} sm={6} md={3} lg={3}>
          <form onSubmit={handleSubmit(onsubmittWO)} id="form3">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={InsuranceType}
              getOptionLabel={(option) => option.insuranceType}
              fullWidth
              onChange={(event: any, newValue: any) => {
                console.log("Selected Loan Type:", newValue?.insuranceType);
                setLoanType(newValue?.insuranceType);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Insurance Type"
                  fullWidth
                  {...register("insuranceType")}
                />
              )}
            />
          </form>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <form onSubmit={handleSubmit(onsubmittWO)}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Product"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.productName ? true : false}
                                        helperText={!errors.productName ? "" : errors.productName.message + ""}
                                        fullWidth
                                        {...register("productName")}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
          <form onSubmit={handleSubmit(onsubmittWO)} id="form2">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={InsuranceCategory}
              getOptionLabel={(option) => option.category}
              fullWidth
              onChange={(event: any, newValue: any) => {
                console.log("Selected Loan Type:", newValue?.Category);
                setLoanType(newValue?.category);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  fullWidth
                  {...register("category")}
                />
              )}
            />
          </form>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <form onSubmit={handleSubmit(onsubmittWO)}>
                                    <TextField
                                        id="outlined-basic"
                                        label="PPT"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.paymentType ? true : false}
                                        helperText={!errors.paymentType ? "" : errors.paymentType.message + ""}
                                        fullWidth
                                        {...register("paymentType")}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <form onSubmit={handleSubmit(onsubmittWO)}>
                                    <TextField
                                        id="outlined-basic"
                                        label="First Year"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.firstYearRevenue ? true : false}
                                        helperText={!errors.firstYearRevenue ? "" : errors.firstYearRevenue.message + ""}
                                        fullWidth
                                        {...register("firstYearRevenue")}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <form onSubmit={handleSubmit(onsubmittWO)}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Second Year"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.secondYearRevenue ? true : false}
                                        helperText={!errors.secondYearRevenue ? "" : errors.secondYearRevenue.message + ""}
                                        fullWidth
                                        {...register("secondYearRevenue")}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <form onSubmit={handleSubmit(onsubmittWO)}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Third Year"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.thirdYearRevenue ? true : false}
                                        helperText={!errors.thirdYearRevenue ? "" : errors.thirdYearRevenue.message + ""}
                                        fullWidth
                                        {...register("thirdYearRevenue")}
                                    />
                                </form>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={3} ml={1}>
                            <form onSubmit={handleSubmit(onsubmittWO)}>
					<Box>
					<Button variant="contained" component="label">
						Upload Company Logo 
						<input
						hidden
						type="file"
						accept=".jpg,.jpeg,.png,.pdf"
						name="companyLogo"
						onChange={(event: any) =>
							setCompanyLogoInsu(event.target.files[0])
						}
						/>
					</Button>
					<Typography variant="caption" sx={{ marginLeft: "0.5rem" }}>
						{companyLogo == undefined
						? "No file choosen"
						: companyLogo.name}
					</Typography>
					</Box>
                    </form>
					</Grid>





                            <Grid item xs={12} sm={12} md={12} lg={12} textAlign={"center"}>
                                <form onSubmit={handleSubmit(onsubmittWO)}>
                                    <Button type='submit' variant='contained' disabled={showCircularProgress}>
                                        {showCircularProgress === true ? (
                                            <CircularProgress
                                                color="secondary"
                                                size={20}
                                                thickness={5}
                                            />
                                        ) : (
                                            ""
                                        )}{"  "}
                                        Submit</Button>
                                </form>
                            </Grid>
                          </Grid>



                        
                          )


                    
                      ):(





                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Name"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.productName ? true : false}
                                    helperText={!errors.productName ? "" : errors.productName.message + ""}
                                    fullWidth
                                    {...register("productName", registerOptions.productName)}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Code"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    color={!!Color ? Color : ""}
                                    sx={{ marginBottom: Color === "warning" ? '0px' : '0px' }}
                                    error={errors.schemeCode ? true : false}
                                    helperText={!errors.schemeCode ? "" : errors.schemeCode.message + ""}
                                    fullWidth
                                    {...register("schemeCode", registerOptions.schemeCode)}
                                />
                                {Color === "warning" ? <Typography color={"red"}> This Scheme Already exist ! </Typography> : ""}
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Classification"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.schemeClassification ? true : false}
                                    helperText={!errors.schemeClassification ? "" : errors.schemeClassification.message + ""}
                                    fullWidth
                                    {...register("schemeClassification")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Inception Date"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.schemeInceptionDate ? true : false}
                                    helperText={!errors.schemeInceptionDate ? "" : errors.schemeInceptionDate.message + ""}
                                    fullWidth
                                    {...register("schemeInceptionDate")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Benchmark Name"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.schemeBenchmarkName ? true : false}
                                    helperText={!errors.schemeBenchmarkName ? "" : errors.schemeBenchmarkName.message + ""}
                                    fullWidth
                                    {...register("schemeBenchmarkName")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Fee Structure"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeFeeStructure")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Objective"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeObjective")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Risk Grade"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeRiskGrade")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Expense Ratio"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeExpenseRatio")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Isin"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeIsin")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Amfi Code"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeAmfiCode")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Plan Type"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.schemePlanType ? true : false}
                                    helperText={!errors.schemePlanType ? "" : errors.schemePlanType.message + ""}
                                    fullWidth
                                    {...register("schemePlanType", registerOptions.schemePlanType)}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Rta Name"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeRtaName")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Exit Load"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeExitLoad")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Comments"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeComments")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Min Investment"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeMinInvestment")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Min Locking Period"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeMinLockingPeriod")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Variable Fee"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("variableFee")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Description"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.description ? true : false}
                                    helperText={!errors.description ? "" : errors.description.message + ""}
                                    fullWidth
                                    {...register("description")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Brochure URL"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("brochureUrl")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Video URL"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("videoUrl")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Form URL"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("formUrl")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Min Amount"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("minAmount")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Max Amount"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("maxAmount")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Min Tenure"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("minTenure")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Max Tenure"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("maxTenure")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                    <form onSubmit={handleSubmit(onsubmit)}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label="Open Date"
                      //inputFormat="DD/MM/YYYY"
                      value={openDate}
                      onChange={(newValue:any) => {
                        setOpenDate(newValue.toJSON());
                      }}
                      renderInput={(params) => 
                      <TextField 
                        {...params} 
                        sx={{width:"100%"}}
                        error={errors.openDate ? true : false}
                        helperText={
                        errors.openDate === undefined
                          ? ""
                          : errors.openDate.message + ""
                      }
                      {...register("openDate")}
                      />
                    }
                    />
                  </LocalizationProvider> 
                    </form>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <form onSubmit={handleSubmit(onsubmit)}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label="Close Date"
                      //inputFormat="DD/MM/YYYY"
                      value={closeDate}
                      onChange={(newValue:any) => {
                        setCloseDate(newValue.toJSON());
                      }}
                      renderInput={(params) => 
                      <TextField 
                        {...params} 
                        sx={{width:"100%"}}
                        error={errors.closeDate ? true : false}
                        helperText={
                        errors.closeDate === undefined
                          ? ""
                          : errors.closeDate.message + ""
                      }
                      {...register("closeDate")}
                      />
                    }
                    />
                  </LocalizationProvider> 
                    </form>
                </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Hurdle Fee"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("hurdleFee")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Management Fee"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.managementFees ? true : false}
                                    helperText={!errors.managementFees ? "" : errors.managementFees.message + ""}
                                    fullWidth
                                    {...register("managementFees")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Performance Fee"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("performanceFee")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Fixed Fee"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.fixedFee ? true : false}
                                    helperText={!errors.fixedFee ? "" : errors.fixedFee.message + ""}
                                    fullWidth
                                    {...register("fixedFee")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Income Rate"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.incomeRate ? true : false}
                                    helperText={!errors.incomeRate ? "" : errors.incomeRate.message + ""}
                                    fullWidth
                                    {...register("incomeRate")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Revenue Rate"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.revenueRate ? true : false}
                                    helperText={!errors.revenueRate ? "" : errors.revenueRate.message + ""}
                                    fullWidth
                                    {...register("revenueRate")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Revenue Credit Fee"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.revenueCreditFee ? true : false}
                                    helperText={!errors.revenueCreditFee ? "" : errors.revenueCreditFee.message + ""}
                                    fullWidth
                                    {...register("revenueCreditFee", registerOptions.revenueCreditFee)}
                                />
                            </form>
                        </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <form onSubmit={handleSubmit(onsubmit)} id="form1">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={IncomeType.map((options: any) => options)}
                          getOptionLabel={(options: any) => options.incomeType}
                          //defaultValue={IncomeType.find((option:any) => option.incomeTypes === IncomeType.data.aifDTOList[0].incomeType) || null}
                          fullWidth
                          onChange={(options: any, newValue) => {
                            setIncomeType(newValue?.incomeTypes);
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label="Select Income Type"
                              fullWidth
                            />
                          )}
                        />
                      </form>
                    </Grid>
                    {incomeType === "trail" && (
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            label="Trail Date"
                            inputFormat="DD/MM/YYYY"
                            value={selectedDate}
                            onChange={(newValue: any) => {
                              setSelectedDate(newValue.toJSON());
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ width: "100%" }}
                                error={errors.selectedDate ? true : false}
                                helperText={
                                  errors.selectedDate === undefined
                                    ? ""
                                    : errors.selectedDate.message + ""
                                }
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    )}
                    {incomeType === "hybrid" && (
                      <>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                              label="Trail Date"
                              inputFormat="DD/MM/YYYY"
                              value={selectedDate}
                              onChange={(newValue: any) => {
                                setSelectedDate(newValue.toJSON());
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{ width: "100%", mb: 2 }}
                                  error={errors.selectedDate ? true : false}
                                  helperText={
                                    errors.selectedDate === undefined
                                      ? ""
                                      : errors.selectedDate.message + ""
                                  }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            label="Up front (%)"
                            fullWidth
                            value={hybridPercentage}
                            onChange={(event: any) =>
                              setHybridPercentage(event.target.value)
                            }
                          />
                        </Grid>
                      </>
                    )}


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant='h6' textAlign={"left"}>Scheme Performance</Typography>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Active Returns 1 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("activeReturns1month")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Active Returns 1 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("activeReturns1year")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Active Returns 3 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("activeReturns3month")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Active Returns 3 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("activeReturns3year")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Active Returns 5 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("activeReturns5year")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Active Returns 6 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("activeReturns6month")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Active Returns SI"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("activeReturnsSi")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="AUM"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("aum")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Benchmark Name"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.benchmarkName ? true : false}
                                    helperText={!errors.benchmarkName ? "" : errors.benchmarkName.message + ""}
                                    fullWidth
                                    {...register("benchmarkName")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Benchmark Return 1 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("bmRet1m")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Benchmark Return 1 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("bmRet1y")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Benchmark Return 2 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("bmRet2y")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Benchmark Return 3 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("bmRet3m")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Benchmark Return 3 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("bmRet3y")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Benchmark Return 5 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("bmRet5y")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Benchmark Return 6 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("bmRet6m")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Benchmark Return Ince"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("bmRetInce")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Category Return 1 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("catRet1m")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Category Return 1 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("catRet1y")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Category Return 3 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("catRet3m")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Category Return 3 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("catRet3y")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Category Return 5 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("catRet5y")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Category Return 6 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("catRet6m")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Count 1 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("count1month")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Count 1 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("count1year")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Count 3 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("count3month")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Count 3 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("count3year")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Count 5 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("count5year")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Count 6 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("count6month")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Date"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.Date ? true : false}
                                    helperText={!errors.Date ? "" : errors.Date.message + ""}
                                    fullWidth
                                    {...register("Date")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="NAV"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("nav")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Plan Name"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.planName ? true : false}
                                    helperText={!errors.planName ? "" : errors.planName.message + ""}
                                    fullWidth
                                    {...register("planName")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Ranking Rank 1 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("rankingRank1month")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Ranking Rank 1 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("rankingRank1year")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Ranking Rank 3 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("rankingRank3month")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Ranking Rank 3 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("rankingRank3year")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Ranking Rank 5 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("rankingRank5year")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Ranking Rank 6 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("rankingRank6month")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Return 1 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeRet1m")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Return 1 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeRet1y")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Return 2 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeRet2y")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Return 3 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeRet3m")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Return 3 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeRet3y")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Return 5 Year"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeRet5y")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Return 6 Month"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeRet6m")}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <TextField
                                    id="outlined-basic"
                                    label="Scheme Return Ince"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    {...register("schemeRetInce")}
                                />
                            </form>
                        </Grid>



                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant='h6' textAlign={"left"}>Scheme Managers</Typography>
                            <Divider />
                        </Grid>

                        {schemeManager.map((item: any, index: any) => (
                            <Paper elevation={8} sx={{ p: 2, mb: 2 }}>
                                <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                    <Typography variant='h6' mb={2} gutterBottom>Scheme Managers {index + 1} :</Typography>
                                    <Grid alignItems={"center"} sx={{ mb: 2 }}>
                                        <Fab color="primary" aria-label="add" variant="extended" sx={{ mr: 1 }} onClick={() => handleRemoveRow(index)}>
                                            <RemoveIcon /> Remove
                                        </Fab>
                                    </Grid>                            </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <form onSubmit={handleSubmit(onsubmit)}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Fund Manager Code"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                color={!!Color2 ? Color2 : ""}
                                                sx={{ marginBottom: Color2 === "warning" ? '0px' : '0px' }}
                                                onChange={(e)=>handleInputChange(index,"fundManagerCode",e.target.value)}
                                                error={errors.fundManagerCode ? true : false}
                                                helperText={!errors.fundManagerCode ? "" : errors.fundManagerCode.message + ""}
                                                fullWidth
                                                // {...register("fundManagerCode", registerOptions.fundManagerCode)}
                                            />
                                        </form>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <form onSubmit={handleSubmit(onsubmit)}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Fund Manager Name"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                value={managerData && managerData?.data?.fundManagerDTOS[0]?.fundManagerName}
                                                onChange={(e)=>handleInputChange(index,"fundManagerName",e.target.value)}
                                                error={errors.fundManagerName ? true : false}
                                                helperText={!errors.fundManagerName ? "" : errors.fundManagerName.message + ""}
                                                fullWidth
                                                // {...register("fundManagerName", registerOptions.fundManagerName)}
                                            />
                                        </form>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <form onSubmit={handleSubmit(onsubmit)}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Fund Manager AUM"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                value={managerData && managerData?.data?.fundManagerDTOS[0]?.fundManagerAum}
                                                onChange={(e)=>handleInputChange(index,"fundManagerFundCount",e.target.value)}
                                                error={errors.fundManagerAum ? true : false}
                                                helperText={!errors.fundManagerAum ? "" : errors.fundManagerAum.message + ""}
                                                fullWidth
                                                // {...register("fundManagerAum", registerOptions.fundManagerAum)}
                                            />
                                        </form>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <form onSubmit={handleSubmit(onsubmit)}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Fund Manager Description"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                value={managerData && managerData?.data?.fundManagerDTOS[0]?.fundManagerDescription}
                                                onChange={(e)=>handleInputChange(index,"fundManagerFundCount",e.target.value)}
                                                fullWidth
                                                // {...register("fundManagerDescription")}
                                            />
                                        </form>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <form onSubmit={handleSubmit(onsubmit)}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Fund Manager Fund Count"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                value={managerData && managerData?.data?.fundManagerDTOS[0]?.fundManagerFundCount}
                                                onChange={(e)=>handleInputChange(index,"fundManagerFundCount",e.target.value)}
                                                error={errors.fundManagerFundCount ? true : false}
                                                helperText={!errors.fundManagerFundCount ? "" : errors.fundManagerFundCount.message + ""}
                                                fullWidth
                                                // {...register("fundManagerFundCount", registerOptions.fundManagerFundCount)}
                                            />
                                        </form>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <form onSubmit={handleSubmit(onsubmit)}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Fund Manager Image URL"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                value={managerData && managerData?.data?.fundManagerDTOS[0]?.fundManagerImage}
                                                onChange={(e)=>handleInputChange(index,"fundManagerImage",e.target.value)}
                                                fullWidth
                                                // {...register("fundManagerImage")}
                                            />
                                        </form>
                                    </Grid>


                                </Grid>

                            </Paper>
                        ))}

                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "end" }}>
                            <Fab color="primary" aria-label="add" variant="extended" sx={{ mr: 1 }} onClick={handleAddRow}>
                                <AddIcon />
                                Add Manager
                            </Fab>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} textAlign={"center"}>
                            <form onSubmit={handleSubmit(onsubmit)}>
                                <Button type='submit' variant='contained' disabled={showCircularProgress}>
                                    {showCircularProgress === true ? (
                                        <CircularProgress
                                            color="secondary"
                                            size={20}
                                            thickness={5}
                                        />
                                    ) : (
                                        ""
                                    )}{"  "}
                                    Submit</Button>
                            </form>
                        </Grid>
                        </Grid>
                      ) }
                      
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

const style = {
    position: "absolute" as 'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: "10px",
    maxHeight: "80%",
    overflowY: "auto",
};

export default AddScheme