import { Autocomplete, Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { GetAllBondQuote } from '../../api/Api'
import AddQuote from './AddQuote'
import QuoteTableData from './QuoteTableData'

interface QuoteDetail {
  id: number;
  quote: string;
  productMasterCode:number;
}
interface BondQuote {
  id: number;
  quote: string;
  data:{
    quoteDetails: QuoteDetail[];
  }
}

const AdminQuote = () => {

  const { data, refetch} = useQuery<BondQuote>("BondQuote",GetAllBondQuote)

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = useState(0);

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


  return (
    <Box>
      <Typography variant="h5" mb={2} gutterBottom>
        {" "}
        Quote Book{" "}
      </Typography>
      <AddQuote />
      <Box>
        <TableContainer component={Paper} elevation={3} sx={{ mt: 3 }}>
          <Table sx={{ width: 750 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ISIN</TableCell>
                <TableCell>Min Value</TableCell>
                <TableCell>Multiple</TableCell>
                <TableCell>Max Value</TableCell>
                <TableCell>M.YTM</TableCell>
                <TableCell>M.YTC</TableCell>
                <TableCell>M.Price</TableCell>
                <TableCell>S.YTM</TableCell>
                <TableCell>S.YTC</TableCell>
                <TableCell>S.Price</TableCell>
                <TableCell>E.YTM</TableCell>
                <TableCell>E.YTC</TableCell>
                <TableCell>E.Price</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.data.quoteDetails
                  .sort(function (a: any, b: any) {
                    return b.productMasterCode - a.productMasterCode;
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => (
                    <QuoteTableData row={row} refatch={refetch} />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 30, 50]}
          component="div"
          count={data ? data.data.quoteDetails.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}

export default AdminQuote