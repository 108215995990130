import { Box, Button, Modal, Typography, CircularProgress, IconButton, Grid } from '@mui/material'
import React from 'react'
import { style } from './Clients'
import axios from 'axios'
import { toast } from 'react-toastify'
import CloseIcon from '@mui/icons-material/Close';

const ImportClient = (props:any) => {
	const [ importFile, setImportFile ] = React.useState("")
	const [ failedClient, setFailedClient] = React.useState([])
	const [showCircularProgress, setshowCircularProgress] = React.useState(false);
	const open = props.open
	const onClose = props.onClose

	const submitData = () => {
		setshowCircularProgress(true);
		if(!!importFile){
			const formData = new FormData();
			formData.append("clientList",importFile);
			axios.post("client/importClient",formData)
			.then((res:any)=>toast.success("Client Added successfully!"))
			.then(() => {
				setshowCircularProgress(false);
			  })
			.catch((err:any)=>{
				setshowCircularProgress(false);
				toast.error( !!err?.response?.data?.status?.message?.title ? err?.response?.data?.status?.message?.title : err?.response?.data?.messageType);
				err.response ? setFailedClient(err.response.data.errorResDTOList) : setFailedClient([]);
			}

			)
		}
	}
  return (
	<>
	    <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleImportClient} className="modal-scroll"  >
		  <IconButton style={{ position: 'absolute',fontSize:"18px" ,top: "8px", right: "5px"}} onClick={() => onClose()}>
      <CloseIcon style={{fontSize:"18px"}} />
       </IconButton>
		  	<Typography
                variant="h5"
                component="div"
                textAlign="center"
                mb={2}
              >
                Add Clients
              </Typography>
			  <Grid style={{textAlign:"center"}}>
			<input type='file' onChange={(e:any)=>setImportFile(e.target.files[0])}/>
			<br />
			<br />
			<Button variant='contained'  onClick={submitData}>
				{showCircularProgress === true ? (
                  <CircularProgress color="secondary" size={20} thickness={5} />
                ) : (
                  ""
                )}
                Submit
			</Button>
			</Grid>
				<Box sx={{ width: "100%", marginTop: "20px" }}>
			{failedClient.map((item:any)=>(
				<Typography variant="caption" display={"block"} marginBottom={1} > Client Code : {item.clientCode} - {item.reason}</Typography>
				))}
			</Box>
		  </Box>
        </Modal>
	</>
  )
}

export default ImportClient

export const styleImportClient = {
	position: 'absolute' as 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
	  width: '50%',
	  bgcolor: 'background.paper',
	  boxShadow: 24,
	  p: 3,
	  borderRadius:"10px",
	  maxHeight: "80%",
	  overflowY: "auto",
  };