import {
  Box,
  Button,
  Link,
  FormControlLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
  Container,
  Autocomplete,
  CircularProgress,
  TablePagination,
  IconButton,
  InputBase,
} from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  GetAllClient,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  listOfManufactureNDistributorCompanies,
} from "../../api/Api";
import States from "../../Constants/States";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ImportClient from "../ImportClient";
import { useDispatch } from "react-redux";
import { getQuarterDates } from "../../utils/Helper";
import CloseIcon from "@mui/icons-material/Close";

const EmployeeClientData = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [Clients, setClients] = useState<any>([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [nomineePancard, setNomineePancard] = useState<any>();
  const [cancelCheque, setCancelCheque] = useState<any>();
  const [profileImage, setProfileImage] = useState<any>();
  const [aadhaarCard, setAadhaarCard] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [CMLdocument, setCMLdocument] = useState<any>();
  const [value, setValueDate] = React.useState<Dayjs | null | any>(
    dayjs("2022-04-07")
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"

  const { data: ListOfCompany } = useQuery(
    "ListOfManuAndDistList",
    () => listOfManufactureNDistributorCompanies("D"),
    {
      enabled: sessionStorage.getItem("Profile") === "B",
      staleTime: Infinity,
    }
  );

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => setOpen(false);

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    // If the search term is empty, reset the page to 0, otherwise, keep the current page
    if (event.target.value === "") {
      setPage(0);
    }
  };

  const handleSort = () => {
    // Toggle between "asc" and "desc" when clicking on the sorting button
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm();

  const onSubmit = (data: any) => {
    setshowCircularProgress(true);
    const formData: any = new FormData();
    formData.append("panCardDocument", nomineePancard);
    formData.append("cancelCheque", cancelCheque);
    formData.append("profileImage", profileImage);
    formData.append("aadhaarCardDocument", aadhaarCard);
    formData.append("clientMasterList", CMLdocument);
    formData.append("clientDto", JSON.stringify(data));

    axios
      .post("client/saveClient", formData)
      .then(() => toast.success("Client Added Successfully"))
      .then(() => {
        setshowCircularProgress(false);
        setOpen(false);
        window.location.reload();
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        toast.error(
          err.response.data ? err.response.data.status.message.title : "Error"
        );
      });
  };

  const { data } = useQuery("getAllClients", () => GetAllClient("", ""), {
    onSuccess: (data: any) => {
      const newData =
        data &&
        data.data.clients.map((item: any, index: any) => {
          return { ...item, SrNo: index + 1 };
        });
      setClients(newData);
    },
    staleTime: Infinity,
  });

  const createdAtCount: any = {};
  Clients.forEach((item: any) => {
    const createdAt = item?.createdAt?.split("T")[0];
    if (createdAt) {
      if (createdAtCount[createdAt]) {
        createdAtCount[createdAt]++;
      } else {
        createdAtCount[createdAt] = 1;
      }
    }
  });

  const startYear = new Date().getFullYear();
  const endYear = new Date().getFullYear();
  const startMonth = 1;
  const endMonth = 12;

  const monthData: any = {};
  for (let year = startYear; year <= endYear; year++) {
    for (let month = startMonth; month <= endMonth; month++) {
      const monthKey = `${year}-${String(month).padStart(2, "0")}`;
      monthData[monthKey] = 0;
    }
  }

  // Fill in the month data from your JSON
  Clients.forEach((entry: any) => {
    if (entry.createdAt) {
      const date = new Date(entry.createdAt);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
      const monthKey = `${year}-${String(month).padStart(2, "0")}`;
      if (monthData[monthKey] !== undefined) {
        monthData[monthKey]++;
      }
    }
  });

  function getPreviousQuarterDates() {
    const currentDate = new Date();
    const currentQuarter = Math.ceil((currentDate.getMonth() + 1) / 3);
    const startMonth = (currentQuarter - 2) * 3;
    const startDate = new Date(currentDate.getFullYear(), startMonth, 1);
    const endMonth = (currentQuarter - 1) * 3 - 1;
    const lastDay = new Date(
      currentDate.getFullYear(),
      endMonth + 1,
      0
    ).getDate();
    const endDate = new Date(currentDate.getFullYear(), endMonth, lastDay);
    return {
      quarterStartDate: startDate.toISOString().split("T")[0],
      quarterEndDate: endDate.toISOString().split("T")[0],
    };
  }

  let quarterWiseData: any = {};
  let currMonthWiseData: any = {};
  let yearlyData: any = {};
  let prevQuarterData: any = {};
  let ytdDataClient: any = {};

  Clients &&
    Clients.forEach((item: any, index: any) => {
      const tradeDate = item?.createdAt?.split("T")[0];
      if (tradeDate) {
        const startDateStr = new Date(getQuarterDates()?.quarterStartDate);
        const endDateStr = new Date(getQuarterDates()?.quarterEndDate);
        const prevQuarterStartDate = new Date(
          getPreviousQuarterDates()?.quarterStartDate
        );
        const prevQuarterEndDate = new Date(
          getPreviousQuarterDates()?.quarterEndDate
        );

        const dateToCheck = new Date(tradeDate);
        // Check if the date is within the current quarter
        if (dateToCheck >= startDateStr && dateToCheck <= endDateStr) {
          quarterWiseData[index] = item;
          // console.log('dateToCheck is between startDate and endDate (Quarter)');
        } else {
          // console.log('dateToCheck is not between startDate and endDate (Quarter)');
        }
        // Check if the date is in the current month
        const currMonth = new Date().getMonth() + 1;
        if (currMonth === dateToCheck.getMonth() + 1) {
          currMonthWiseData[index] = item;
          // console.log('dateToCheck is in the current month (Monthly)');
        } else {
          currMonthWiseData[index] = 0; // Set to 0 if no response is obtained
        }
        // Check if the date is within the current year
        const currentYear = new Date().getFullYear();
        if (dateToCheck.getFullYear() === currentYear) {
          yearlyData[index] = item;
          // console.log('dateToCheck is in the current year (Yearly)');
        } else {
          yearlyData[index] = 0; // Set to 0 if no response is obtained
        }

        // Check if the date is within the previous quarter
        if (
          dateToCheck >= prevQuarterStartDate &&
          dateToCheck <= prevQuarterEndDate
        ) {
          prevQuarterData[index] = item;
          // console.log('dateToCheck is between prevQuarterStartDate and prevQuarterEndDate (Previous Quarter)');
        }
        // else {
        //   // prevQuarterData[index] = 0;
        //   // console.log('dateToCheck is not between prevQuarterStartDate and prevQuarterEndDate (Previous Quarter)');
        // }

        // Calculate YTD start and end dates dynamically
        const currentYearStart = new Date(currentYear, 3 - 1, 1); // April 1
        const currentYearEnd = new Date(currentYear + 1, 3 - 1, 31); // March 31

        if (dateToCheck >= currentYearStart && dateToCheck <= currentYearEnd) {
          ytdDataClient[index] = item;
          // console.log('dateToCheck is in the financial year (YTD)');
        } else {
          ytdDataClient[index] = 0; // Set to 0 if no response is obtained
          // console.log('dateToCheck is not in the financial year (YTD)');
        }
      }
    });

  // console.log("quart: ", Object.entries(quarterWiseData).length);
  // console.log("prev-quat-data: ", Object.entries(prevQuarterData).length);
  // console.log("ytd-data-client: ", Object.entries(ytdDataClient).length);

  dispatch({ type: "quarterWiseData", payload: quarterWiseData });
  dispatch({ type: "currMonthWiseData", payload: currMonthWiseData });
  dispatch({ type: "yearlyDatacount", payload: yearlyData });
  dispatch({ type: "prevQuarterData", payload: prevQuarterData });
  dispatch({ type: "ytdDataClient", payload: ytdDataClient });

  dispatch({ type: "MonthlyClientCount", payload: monthData });
  dispatch({ type: "AddClientCount", payload: createdAtCount });
  dispatch({ type: "TotalClientCount", payload: Clients.length });

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", watch("companyMasterCode")],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        "D",
        getValues("companyMasterCode")
      ),
    {
      enabled: !!getValues("companyMasterCode"),
      staleTime: Infinity,
    }
  );

  const filteredClients = Clients.filter((item: any) => {
    return (
      item.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.panCardNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.clientCode.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Sorting function based on Clients Name
  const sortedClients = [...filteredClients].sort((a, b) => {
    const nameA = a.fullName.toLowerCase();
    const nameB = b.fullName.toLowerCase();

    if (sortOrder === "asc") {
      return nameA.localeCompare(nameB);
    } else {
      return nameB.localeCompare(nameA);
    }
  });

  return (
    <Container maxWidth="xl">
      <Box sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}>
        <Grid container gap={2}>
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Client by name or Pan Card or Client Code"
                onChange={handleSearch}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              ></IconButton>
            </Paper>
            {/* <Button variant="contained" onClick={() => setOpenImportModal(true)}>
		  Import Client
		</Button> */}
            <Button variant="contained" onClick={() => setOpen(true)}>
              Add Client
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: "2%" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr No.</TableCell>
                <TableCell onClick={handleSort} style={{ cursor: "pointer" }}>
                  Client Name {sortOrder === "asc" ? "▲" : "▼"}
                </TableCell>
                <TableCell align="right">Company Name</TableCell>
                <TableCell align="right">Company Email Id</TableCell>
                <TableCell align="right">Mobile No</TableCell>
                <TableCell align="right">Client Code</TableCell>
                {/* <TableCell align="right">Status</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedClients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item: any, index: any) => {
                  return (
                    <Link
                      key={item.clientId}
                      display={"table-row"}
                      sx={{
                        textDecoration: "none",
                        "&:hover": { backgroundColor: "lightgray" },
                      }}
                      href={"/client/" + item.clientId}
                    >
                      <TableCell align="left">
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.fullName}
                      </TableCell>
                      <TableCell align="right">{item.companyName}</TableCell>
                      <TableCell align="right">{item.emailId}</TableCell>
                      <TableCell align="right">{item.mobileNo}</TableCell>
                      <TableCell align="right">{item.clientCode}</TableCell>
                    </Link>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 30, 50]}
          component="div"
          // count={Clients ? Clients.length : 0}
          count={sortedClients ? sortedClients.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="modal-scroll">
            <IconButton
              style={{
                position: "absolute",
                fontSize: "18px",
                top: "8px",
                right: "5px",
              }}
              onClick={() => handleClose()}
            >
              <CloseIcon style={{ fontSize: "18px" }} />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "500" }}
              >
                Client Details
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="PUBLIC"
                >
                  <Stack direction={"row"} spacing={3}>
                    <FormControlLabel
                      value="PRIVATE"
                      {...register("accessType")}
                      control={<Radio />}
                      label="Private"
                    />
                    <FormControlLabel
                      value="PUBLIC"
                      {...register("accessType")}
                      control={<Radio />}
                      label="Public"
                    />
                  </Stack>
                </RadioGroup>

                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="INDIVIDUAL"
                >
                  <Stack direction={"row"} spacing={3}>
                    <FormControlLabel
                      value="INDIVIDUAL"
                      {...register("clientType")}
                      control={<Radio />}
                      label="Individual"
                    />
                    <FormControlLabel
                      value="CORPORATE"
                      {...register("clientType")}
                      control={<Radio />}
                      label="Corporate"
                    />
                  </Stack>
                </RadioGroup>
              </Box>

              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                {sessionStorage.getItem("Profile") === "B" ? (
                  <Grid item md={3}>
                    <Autocomplete
                      options={
                        ListOfCompany ? ListOfCompany.data.companies : []
                      }
                      loading={
                        ListOfCompany ? !ListOfCompany.data.companies : true
                      }
                      getOptionLabel={(option: any) => option.companyName}
                      onChange={(event: any, value: any) => {
                        setValue(
                          "companyMasterCode",
                          !!value ? value.companyMasterCode : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Distributor Company"
                          variant="outlined"
                          // error={errors.isinNumber ? true : false}
                          // helperText={
                          //   errors.isinNumber == undefined
                          //     ? ""
                          //     : errors.isinNumber.message + ""
                          // }
                          // {...register("companyMasterCode")}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    fullWidth
                    error={errors.fullName ? true : false}
                    helperText={
                      errors.fullName === undefined
                        ? ""
                        : errors.fullName.message + ""
                    }
                    {...register("fullName")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Mobile No"
                    variant="outlined"
                    fullWidth
                    error={errors.mobileNo ? true : false}
                    helperText={
                      errors.mobileNo === undefined
                        ? ""
                        : errors.mobileNo.message + ""
                    }
                    {...register("mobileNo")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Email Id"
                    type="email"
                    variant="outlined"
                    fullWidth
                    error={errors.emailId ? true : false}
                    helperText={
                      errors.emailId === undefined
                        ? ""
                        : errors.emailId.message + ""
                    }
                    {...register("emailId")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="PAN Card No"
                    variant="outlined"
                    fullWidth
                    error={errors.panCardNo ? true : false}
                    helperText={
                      errors.panCardNo === undefined
                        ? ""
                        : errors.panCardNo.message + ""
                    }
                    {...register("panCardNo")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Address Line 1"
                    variant="outlined"
                    fullWidth
                    error={errors.addressLine1 ? true : false}
                    helperText={
                      errors.addressLine1 === undefined
                        ? ""
                        : errors.addressLine1.message + ""
                    }
                    {...register("addressLine1")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Address Line 2"
                    variant="outlined"
                    fullWidth
                    error={errors.addressLine2 ? true : false}
                    helperText={
                      errors.addressLine2 === undefined
                        ? ""
                        : errors.addressLine2.message + ""
                    }
                    {...register("addressLine2")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Address Line 3"
                    variant="outlined"
                    fullWidth
                    error={errors.addressLine3 ? true : false}
                    helperText={
                      errors.addressLine3 === undefined
                        ? ""
                        : errors.addressLine3.message + ""
                    }
                    {...register("addressLine3")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    fullWidth
                    error={errors.city ? true : false}
                    helperText={
                      errors.city === undefined ? "" : errors.city.message + ""
                    }
                    {...register("city")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Pin Code"
                    variant="outlined"
                    fullWidth
                    error={errors.pinCode ? true : false}
                    helperText={
                      errors.pinCode === undefined
                        ? ""
                        : errors.pinCode.message + ""
                    }
                    {...register("pinCode")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Client Code"
                    variant="outlined"
                    fullWidth
                    error={errors.clientCode ? true : false}
                    helperText={
                      errors.clientCode === undefined
                        ? ""
                        : errors.clientCode.message + ""
                    }
                    {...register("clientCode")}
                  />
                </Grid>
                <Grid item md={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={[
                      {
                        label: "RESIDENT INDIVIDUAL",
                        value: "RESIDENT_INDIVIDUAL",
                      },
                      {
                        label: "NON RESIDENT INDIVIDUAL",
                        value: "NON_RESIDENT_INDIVIDUAL",
                      },
                    ].map((option: any) => option)}
                    getOptionLabel={(options: any) => options.label}
                    onChange={(options: any, newValue) => {
                      setValue("residentialStatus", newValue?.value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="User Role" />
                    )}
                  />
                </Grid>

                <Grid item md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label="Date of Birth"
                      inputFormat="DD/MM/YYYY"
                      value={value}
                      onChange={(newValue) => {
                        setValueDate(newValue.toJSON());
                        setValue("dateOfBirth", newValue.toJSON());
                      }}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item md={3}>
                  <Autocomplete
                    options={States.countries}
                    getOptionLabel={(option: any) => option.country}
                    onChange={(event: any, value: any) =>
                      setSelectedCountry(value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        variant="outlined"
                        error={errors.country ? true : false}
                        helperText={
                          errors.country === undefined
                            ? ""
                            : errors.country.message + ""
                        }
                        {...register("country")}
                      />
                    )}
                  />
                </Grid>

                <Grid item md={3}>
                  {selectedCountry && (
                    <Autocomplete
                      options={selectedCountry.states}
                      onChange={(event: any, value: any) =>
                        setSelectedState(value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          variant="outlined"
                          error={errors.state ? true : false}
                          helperText={
                            errors.state === undefined
                              ? ""
                              : errors.state.message + ""
                          }
                          {...register("state")}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>

              <Typography
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "500" }}
              >
                Bank Details
              </Typography>

              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Bank Name"
                    variant="outlined"
                    fullWidth
                    error={errors.accountNo ? true : false}
                    helperText={
                      errors.accountNo === undefined
                        ? ""
                        : errors.accountNo.message + ""
                    }
                    {...register("bankName")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Branch Name"
                    variant="outlined"
                    fullWidth
                    error={errors.accountNo ? true : false}
                    helperText={
                      errors.accountNo === undefined
                        ? ""
                        : errors.accountNo.message + ""
                    }
                    {...register("branchName")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Account No"
                    variant="outlined"
                    fullWidth
                    error={errors.accountNo ? true : false}
                    helperText={
                      errors.accountNo === undefined
                        ? ""
                        : errors.accountNo.message + ""
                    }
                    {...register("accountNo")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="IFSC Code"
                    variant="outlined"
                    fullWidth
                    error={errors.ifscCode ? true : false}
                    helperText={
                      errors.ifscCode === undefined
                        ? ""
                        : errors.ifscCode.message + ""
                    }
                    {...register("ifscCode")}
                  />
                </Grid>

                <Grid item md={3}></Grid>
                <Grid item md={3}></Grid>
              </Grid>

              <Typography
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "500" }}
              >
                DP Details
              </Typography>

              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="DP Name"
                    variant="outlined"
                    fullWidth
                    error={errors.dpName ? true : false}
                    helperText={
                      errors.dpName === undefined
                        ? ""
                        : errors.dpName.message + ""
                    }
                    {...register("dpName")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="DP ID"
                    variant="outlined"
                    fullWidth
                    error={errors.dpId ? true : false}
                    helperText={
                      errors.dpId === undefined ? "" : errors.dpId.message + ""
                    }
                    {...register("dpId")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="RM Name"
                    variant="outlined"
                    fullWidth
                    error={errors.rmName ? true : false}
                    helperText={
                      errors.rmName === undefined
                        ? ""
                        : errors.rmName.message + ""
                    }
                    {...register("rmName")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="RM Branch"
                    variant="outlined"
                    fullWidth
                    error={errors.rmBranch ? true : false}
                    helperText={
                      errors.rmBranch === undefined
                        ? ""
                        : errors.rmBranch.message + ""
                    }
                    {...register("rmBranch")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="RM Branch Code"
                    variant="outlined"
                    fullWidth
                    error={errors.rmBranch ? true : false}
                    helperText={
                      errors.rmBranch === undefined
                        ? ""
                        : errors.rmBranch.message + ""
                    }
                    {...register("rmBranchCode")}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="RM Employee Code"
                    variant="outlined"
                    fullWidth
                    error={errors.rmEmpCode ? true : false}
                    helperText={
                      errors.rmEmpCode === undefined
                        ? ""
                        : errors.rmEmpCode.message + ""
                    }
                    {...register("rmEmpCode")}
                  />
                </Grid>

                {sessionStorage.getItem("Profile") === "B" ? (
                  <Grid item md={3}>
                    <Autocomplete
                      options={
                        UserProfilesByCompanyMasterCode
                          ? UserProfilesByCompanyMasterCode.data.userProfile
                          : []
                      }
                      getOptionLabel={(option: any) => option.employeeCode}
                      onChange={(event: any, value: any) => {
                        setValue(
                          "distributorEmployeeCode",
                          !!value ? value.employeeCode : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Distributor"
                          variant="outlined"
                          error={errors.distributorEmployeeCode ? true : false}
                          helperText={
                            !!errors.distributorEmployeeCode &&
                            errors.distributorEmployeeCode.message + ""
                          }
                          {...register("distributorEmployeeCode")}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
              <Typography
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "500" }}
              >
                Document Upload
              </Typography>

              <Stack spacing={3} direction="column" mb={3}>
                <Box>
                  <Button variant="contained" component="label">
                    Upload Pan Card
                    <input
                      hidden
                      type="file"
                      accept=".pdf"
                      name="panCardDocument"
                      onChange={(event: any) =>
                        setNomineePancard(event.target.files[0])
                      }
                    />
                  </Button>
                  <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                    {nomineePancard == undefined
                      ? "No file choosen"
                      : nomineePancard.name}
                  </Typography>
                </Box>
                <Box>
                  <Button variant="contained" component="label">
                    Upload Client Master List
                    <input
                      hidden
                      type="file"
                      accept=".pdf"
                      name="cmlDocument"
                      onChange={(event: any) =>
                        setCMLdocument(event.target.files[0])
                      }
                    />
                  </Button>
                  <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                    {CMLdocument == undefined
                      ? "No file choosen"
                      : CMLdocument.name}
                  </Typography>
                </Box>
                <Box>
                  <Button variant="contained" component="label">
                    Upload Cancel Cheque
                    <input
                      hidden
                      type="file"
                      accept=".pdf"
                      name="cancelCheque"
                      onChange={(event: any) =>
                        setCancelCheque(event.target.files[0])
                      }
                    />
                  </Button>
                  <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                    {cancelCheque == undefined
                      ? "No file choosen"
                      : cancelCheque.name}
                  </Typography>
                </Box>
                <Box>
                  <Button variant="contained" component="label">
                    Upload Profile Image
                    <input
                      hidden
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      name="profileImage"
                      onChange={(event: any) =>
                        setProfileImage(event.target.files[0])
                      }
                    />
                  </Button>
                  <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                    {profileImage == undefined
                      ? "No file choosen"
                      : profileImage.name}
                  </Typography>
                </Box>
                <Box>
                  <Button variant="contained" component="label">
                    Upload Aadhaar Card
                    <input
                      hidden
                      type="file"
                      accept=".jpg,.jpeg,.png,.pdf"
                      name="aadhaarCard"
                      onChange={(event: any) =>
                        setAadhaarCard(event.target.files[0])
                      }
                    />
                  </Button>
                  <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                    {aadhaarCard == undefined
                      ? "No file choosen"
                      : aadhaarCard.name}
                  </Typography>
                </Box>
              </Stack>

              <Button
                type="submit"
                variant="contained"
                disabled={showCircularProgress}
              >
                {!!showCircularProgress && (
                  <CircularProgress
                    color="secondary"
                    size={20}
                    thickness={5}
                    sx={{ mr: 1 }}
                  />
                )}
                Submit
              </Button>
            </form>
          </Box>
        </Modal>
      </Box>
      <ImportClient
        open={openImportModal}
        onClose={() => setOpenImportModal(false)}
      />
    </Container>
  );
};

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "80%",
  overflowY: "auto",
};

export default EmployeeClientData;
