import { Container, Link, Box,  Paper, Stack, Typography, Button, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Autocomplete, CircularProgress, TablePagination, Grid, InputBase, IconButton, FormControl } from '@mui/material'
// import { Link } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useQuery } from 'react-query';
import { getAllUnlistedShare, getAllUnlistedShareMaster } from '../../api/Api';
import { Navigate } from "react-router-dom";
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import Loading from '../../Constants/Loading';

const prods = [
    {
        title:'CSK'
    },
    {
        title:'Barbeque Nation'
    },
    {
        title:'Bira'
    },
    {
        title:'Boat'
    },
]

const style = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "1px solid gray",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    "& .MuiTextField-root": { m: 1 },
  };


const AdminUnlisted = () => {

    const [unlistedModel, setUnlistedModel] = useState(false)
    const [file, setFile] = useState<any>([]);
    const [brokerList, setBrokerList] = useState<any>();
    const [brokerCode, setBrokerCode] = useState<any>("");
    const [scriptLogoFile, setScriptLogoFile] = useState<any>();
    const [reportDocumentFile, setReportDocumentFile] = useState<any>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [showCircularProgress, setshowCircularProgress] = useState(false);

    const {data, isLoading, refetch } = useQuery("UnlistedMaster",getAllUnlistedShareMaster);
    console.log("Data", data);

    console.log("Script logo file", scriptLogoFile);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };

      const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      const handleSearch = (event:any) => {
        setSearchTerm(event.target.value);
      };

      const filteredUnlisted = data && data.data.unlistedList.filter((item:any) => {
        return (
          item.scriptName.toLowerCase().includes(searchTerm.toLowerCase()) 
          // || item.userName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    
    function updateUnlistedModel(status:any){
        status==="open"?
        setUnlistedModel(true)
        : status==="close" ? setUnlistedModel(false) : <></>
    }

    const resolver: any = async (values: any) => {
        return {
            values: !values.scriptName ? {} : values,
            errors: !values.scriptName ? {
                scriptName: {
                  type: "required",
                  message: "This is required.",
                },
              }:
              // values.scriptName?{
              //   scriptName: {
              //     value: /^[a-zA-Z0-9]+$/,
              //     message: "Please Enter a valid Script Name",
              //  }
              // }
              // :
              !values.isinNumber 
              ? {
                isinNumber : {
                  type: "required",
                  message: "This is required.",
                }
              }
              :values.faceValue.length < 1
              ?{
                faceValue : {
                  type: "required",
                  message: "This is required.",
                }
              } 
              : isNaN(values.faceValue)?{
                faceValue : {
                  type: "required",
                  message: "This is required.",
                }
              }:{} 
        }
    }


    const onSubmit = (data: any) => {
      if(scriptLogoFile){
        setshowCircularProgress(true)
        console.log("data--",data)
        const formData = new FormData();
        file.map((item:any) => {
          formData.append(item[0],item[1]);
        })
        formData.append("scriptLogo", scriptLogoFile);
        formData.append("reportDocument",reportDocumentFile);
        formData.append("unlistedDto",JSON.stringify({...data}))
        axios.post("unlistedMaster/saveUnlistedMaster",formData).then(()=>toast.success("Unlisted Added Successfully!"))
        .then(() => {window.location.reload();})
        .then(()=>{setshowCircularProgress(false); updateUnlistedModel("close") } )
        .catch((err:any)=>{ setshowCircularProgress(false); toast.error(err.response.data?err.response.data.status.message.title:"Error")});
      }
      else
      {
        toast.error("Please Upload Script Logo")
      }
    }

    const { register, handleSubmit, formState:{errors}} = useForm({ mode: "onBlur" });

    const handleChange = (event:any, name:any) => {
        console.log(event.target.files[0]);
        console.log(name);
        setFile([...file,[name,event.target.files[0]]]);
    }

    const registerOptions = {
      scriptName: {
        required: "Script is required",
        pattern: {
          value : /[a-zA-Z\\s]*/,
          message : "Invalid Script name"
        } 
      },
      isinNumber : { 
        required: "ISIN is required",
        pattern: {
          value : /^[A-Za-z0-9]+$/,
          message : "Invalid ISIN number"
        }
      },
      faceValue:{
        validate: {
        positive: (v:any) => parseInt(v) > 0 || 'should be greater than 0',
        },
        required: "Face value is required",
        valueAsNumber: true
      }
    } 

    console.log("errors",errors)
    console.log("reportDocumentFile",reportDocumentFile)

  return (
     <Container maxWidth='xl' sx={{marginBottom:'5%'}}>
      {isLoading ? (<Loading/>) : ( 
      <Box sx={{ marginTop: "3%" }}>
        <Typography variant='h5' sx={{fontWeight:'500'}} gutterBottom>Unlisted Master</Typography>

            <Grid container spacing={2}>
                <Grid item md={12} sx={{display:'flex', width:'100%'}}>
                <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Unlisted by Name"
                onChange={handleSearch}
              />
            </Paper>
            <Button variant="contained" onClick={()=>updateUnlistedModel("open")} sx={{marginLeft:'auto'}}>Add Unlisted</Button>
                </Grid>
                <Grid item md={12}>
                <TableContainer component={Paper} elevation={3}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                    <TableCell sx={{fontSize:'1rem', fontWeight:'600'}} align="left"></TableCell>
                    <TableCell sx={{fontSize:'1rem', fontWeight:'600'}} align="left">ISIN</TableCell>
                    <TableCell sx={{fontSize:'1rem', fontWeight:'600'}} align="left">Script Name</TableCell>
                    <TableCell sx={{fontSize:'1rem', fontWeight:'600'}} align="center">Face Value</TableCell>
                    <TableCell sx={{fontSize:'1rem', fontWeight:'600'}} align="left">Status</TableCell>
                    <TableCell sx={{fontSize:'1rem', fontWeight:'600'}} align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {filteredUnlisted && filteredUnlisted
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item:any)=>{
                        return(   
                          <Link display={"table-row"} sx={{textDecoration:'none', '&:hover':{backgroundColor:'lightgray'}}} href={"/unlistedMasterDetails/"+item.unlistedMasterCode} style={{textDecoration:'none'}}>
                            <TableCell sx={{padding:'10px'}} align="left"><img src={item.scriptLogo} width={30}/></TableCell>
                            <TableCell sx={{padding:'10px'}} align="left">{item.isinNumber}</TableCell>
                            <TableCell sx={{padding:'10px'}} align="left">{item.scriptName}</TableCell>
                            <TableCell sx={{padding:'10px'}} align="center">{item.faceValue}</TableCell>
                            <TableCell sx={{padding:'10px'}} align="left">{item.isActive?<>Active</>:<>In Active</> }</TableCell>
                            <TableCell sx={{padding:'10px'}} align="left">
                            <Button 
                                    variant='contained'
                                    onClick={(e) => {e.preventDefault(); axios.put('unlistedMaster/softDeleteUnlistedMaster/'+item.unlistedMasterCode).then(()=>refetch());}}
                                    color={'primary'}
                                >
                                    {item.isActive?<>In Active</>:<>Active</>}
                                </Button>
                            </TableCell>
                        </Link>
                        )
                    })}
                
                </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 30, 50]}
                    component="div"
                    count={data.data.unlistedList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Grid>
                
            </Grid>
        </Box> ) } 

        <Modal
         keepMounted
         open={unlistedModel}
         onClose={()=>updateUnlistedModel("close")}
         aria-labelledby="keep-mounted-modal-title"
         aria-describedby="keep-mounted-modal-description"
         >
          <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Add Unlisted
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField 
            id="outlined-basic" 
            label="Script Name" 
            variant="outlined" 
            error={errors.scriptName?true:false}
            helperText={ errors.scriptName === undefined? "" : errors.scriptName.message + "" }
            {...register("scriptName",registerOptions.scriptName)}
            />

            <TextField 
            id="outlined-basic" 
            label="ISIN Number" 
            variant="outlined"
            error={errors.isinNumber?true:false}
            helperText={ errors.isinNumber === undefined? "" : errors.isinNumber.message + "" } 
            {...register("isinNumber",registerOptions.isinNumber)}
            />

            <TextField 
            id="outlined-basic" 
            label="Face Value" 
            variant="outlined"
            type={'number'}
            error={errors.faceValue?true:false}
            helperText={ errors.faceValue === undefined? "" : errors.faceValue.message + "" }  
            {...register("faceValue",registerOptions.faceValue)}
            />
            
            <Stack spacing={2} mt={2}>
            <Box>
            {/* <FormControl
              error={errors.scriptLogo?true:false}
              helperText={ errors.scriptLogo === undefined? "" : errors.scriptLogo.message + "" } 
            > */}
            <Button variant="outlined" component='label' >
             Upload Logo
                <input hidden type="file" name='form' onChange={(event:any)=>setScriptLogoFile(event.target.files[0])} style={{ marginBottom: "10px" }} />
              
            </Button> 
            <Typography variant='caption' sx={{marginLeft:'1rem'}}>{scriptLogoFile === undefined ? "No file choosen only accept (.png, .jpeg, .jpg)" : scriptLogoFile.name } </Typography>
            {/* </FormControl> */}
              </Box>

              <Box>
            <Button variant='outlined' component="label">
              Upload Report File
                <input hidden type="file" name='form' onChange={(event:any)=>setReportDocumentFile(event.target.files[0])} style={{ marginBottom: "10px" }} />
            </Button>
            <Typography variant='caption'  sx={{marginLeft:'1rem'}}>{ reportDocumentFile === undefined ? "No file choosen only accept (.png, .jpeg, .jpg)" : reportDocumentFile.name }</Typography>
              </Box>
            </Stack>
                <Button type="submit" disabled={showCircularProgress} variant="contained" sx={{marginTop:'6%'}}> 
                {showCircularProgress === true ? (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      thickness={5}
                    />
                  ) : (
                    ""
                  )}{"  "} Submit</Button>
          </form>
            </Box>
        </Modal>
    </Container> 
                  
  )
}

export default AdminUnlisted