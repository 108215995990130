import { Container, Grid, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PMSIMG from "../../images/InvestmentProductIMG/PMSIMG.png"
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import StartWithUs from '../StartWithUs/StartWithUs';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';



const ProductPMS = () => {
  window.scrollTo(0, 0);
  return (
//     <section>
//         <Container>
//         <Grid md={12} className={sessionStorage.getItem("JWT") ? 'your-dynamic-css-class-product' : 'default-class-product'}>
//        <Typography style={{ textAlign: "left", fontSize: "30px", fontWeight: "bold",borderBottom: "4px solid black"}}>Portfolio Management Services (PMS)</Typography>
//        <Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold", textDecoration: "underline",paddingTop:"10px" }}>Industry Synopsis</Typography>
//        <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
//        Over the last year, the discretionary PMS in India has witnessed a 14% absolute growth year-on-year (YOY) in assets under management
//        (AUM), reaching a huge Rs 23.49 lakh crores as of April 2023 as per SEBI data. The increase in AUM of the PMS industry is in line with
//        the recovery in broad markets and heightened interest from HNIs (High Networth Individuals) and family offices in PMS structures. PMS
//        performance in India is estimated to exceed 50 lakh crore by 2031. It would take a 20% CAGR increase in near future.
//       </Typography>
//       </Grid>
//       <br />
//       <Typography style={{ textAlign: "left", fontSize: "25px", fontWeight: "bold"}}>Why to invest in PMS?</Typography>
//     <Grid container>
//   <Grid xs={1}>
//   <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
//   </Grid>
//   <Grid xs={11}>
//     <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
//     PMS offers individualized investment solutions managed by expert. Investors can choose from a variety of portfolios offered by
// service providers, including large-cap, mid-cap, multi-cap depending on their level of risk tolerance
//     </Typography>
  
//   </Grid>
//   <Grid xs={1}>
//   <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
//   </Grid>
//   <Grid xs={11}>
//     <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
//     Delivering strong alpha in comparison to benchmark indices is the goal of a PMS.
//     </Typography>
  
//   </Grid>
//   <Grid xs={1}>
//   <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
//   </Grid>
//   <Grid xs={11}>
//     <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
//     PMS offers transparency in terms of transaction, fees structure, The asset management company also provide monthly portfolio
// reports to the clients
//     </Typography>
  
//   </Grid>
//   <Grid xs={1}>
//   <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
//   </Grid>
//   <Grid xs={11}>
//     <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
//     Although PMS is a flexible investment choice, investors are protected by the fact that the investor-fund management interaction
// is governed by the law
//     </Typography>
  
//   </Grid>
//   <Grid xs={1}>
//   <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
//   </Grid>
//   <Grid xs={11}>
//     <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
//     As on March’23 PMS AUM increased to 23.30 Lacs crores from 13.03 lacs crores in Mar’19.
//     </Typography>
  
//   </Grid>
//   <Grid xs={1}>
//   <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
//   </Grid>
//   <Grid xs={11}>
//     <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
//     PMS AUM increase by more than 175% in March’23 compared to March’19
//     </Typography>
  
//   </Grid>

//     </Grid>
//     <br />
//     <Grid xs={12}>
//       <Typography style={{fontSize: "25px", fontWeight: "bold"}}>PMS AUM GROWTH (IN CR)</Typography>
//             <Typography style={{display:"flex",justifyContent:"center"}}><CropLandscapeIcon style={{color:"#4472c4"}}/>Clien <CropLandscapeIcon style={{color:"#ed7d31"}}/>AUM (in cr)</Typography>
//            <img style={{maxWidth:"100%",height:"auto"}} src={PMSIMG} alt="" />
//     </Grid>
//     <Grid md={12} >
// <Typography style={{fontSize:"25px",background:"aqua",fontWeight:"bold",padding:"8px",borderRadius:"10px"}}>Portfolio Management Services (PMS)</Typography>
// <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 650 }} aria-label="simple table">
//         <TableHead>
//           <TableRow>
//             <TableCell align="center"  style={{fontSize:"16px"}}>AMC</TableCell>
//             <TableCell align="center" style={{fontSize:"16px"}}>STRATEGY</TableCell>
//             <TableCell align="center" style={{fontSize:"16px"}}>CATEGORY</TableCell>
//             <TableCell align='center' style={{fontSize:"16px"}}>PERIODIC RETURNS (%)
//             <TableCell align="right" style={{fontSize:"16px"}}></TableCell>
//             <TableCell align="right" style={{fontSize:"16px"}}>3M</TableCell>
//             <TableCell align="right" style={{fontSize:"16px"}}>6M</TableCell>
//             <TableCell align="right" style={{fontSize:"16px"}}>1YR</TableCell>
//             <TableCell align="right" style={{fontSize:"16px"}}>3YR</TableCell>
//             <TableCell align="center" style={{fontSize:"16px"}}>SINCE INCEPTION</TableCell>
//             </TableCell>

//           </TableRow>
//         </TableHead>
//         <TableBody>
//             <TableRow sx={{border: "0"}}>
//               <TableCell component="th" scope="row" align="center"  style={{fontSize:"15px"}}>Portfolio Management Services (PMS)</TableCell>
//               <TableCell align="center" style={{fontSize:"15px"}}>Phoenix PMS</TableCell>
//               <TableCell align="center" style={{fontSize:"15px"}}>Multicap</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>
//               <TableCell align="right" style={{fontSize:"15px"}}></TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>16.21%</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>21.13%</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>25.95%</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>NA</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>21.57%</TableCell>
//               </TableCell>
              
//             </TableRow>
//             <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
//               <TableCell component="th" scope="row" align="center"  style={{fontSize:"15px"}}>Abakkus Asset Manager</TableCell>
//               <TableCell align="center" style={{fontSize:"15px"}}>Emerging Opportunities</TableCell>
//               <TableCell align="center" style={{fontSize:"15px"}}>Small & Micap</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>
//               <TableCell align="right" style={{fontSize:"15px"}}></TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>28.31%</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>33.25%</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>43.22%</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>NA</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>41.19%</TableCell>
//               </TableCell>
              
//             </TableRow>
        
//         </TableBody>
//       </Table>
//     </TableContainer>
   
// </Grid>
// {sessionStorage.getItem("JWT")?"":
// <Grid mt={2} mb={2}>
// <StartWithUs/>
// </Grid>
// }
//         </Container>
//     </section>
<section>
        <Container>
        <Grid md={12} className={sessionStorage.getItem("JWT") ? 'your-dynamic-css-class-product' : 'default-class-product'}>
       <Typography style={{ textAlign: "left", fontSize: "30px", fontWeight: "bold",borderBottom: "4px solid black"}}>Portfolio Management Services (PMS) </Typography>
       <Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold", textDecoration: "underline",paddingTop:"10px" }}>Industry Update </Typography>
       <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
       Over the last year, the discretionary PMS in India has witnessed a 14% absolute growth year-on-year (YOY) in assets under management (AUM),
        reaching a huge Rs 23.49 lakh crores as of April 2023 as per SEBI data. 
        The increase in AUM of the PMS industry is in line with  the recovery in broad markets and heightened interest from HNIs (High Networth Individuals) 
        and family offices in PMS structures. PMS  performance in India is estimated to exceed 50 lakh crore by 2031. It would take a 20% CAGR increase in near future.   
      </Typography>
      </Grid>
      <br />
      
      <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "bold"}}>Why to invest in PMS?</Typography>
      <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingTop:"10px"}}>
      •	 PMS offers individualized investment solutions managed by expert. Investors can choose from a variety of portfolios offered by  service providers,
         including large-cap, mid-cap, multi-cap depending on their level of risk tolerance.
      </Typography>
      <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingTop:"10px"}}>
      •	 Delivering strong alpha in comparison to benchmark indices is the goal of a PMS.
      </Typography>
      <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingTop:"10px"}}>
      •	 PMS offers transparency in terms of transaction, fees structure, The asset management company also provide monthly portfolio reports to the clients.
      </Typography>
      <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingTop:"10px"}}>
      •	 PMS follows a robust risk management approach, in which portfolio managers closely monitor diversification and assess market,  interest-rate, and inflation risks.
      </Typography>
      <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingTop:"10px"}}>
      •	 Although PMS is a flexible investment choice, invetors are protected by the fact that the investor-fund management interaction  is governed by the law
      </Typography>

     
    <br />

   
   
  




{sessionStorage.getItem("JWT")?"":
<Grid mt={2} mb={2}>
<StartWithUs/>
</Grid>
}
        </Container>
    </section>
  )
}

export default ProductPMS