import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import Paper from '@mui/material/Paper';
import StartWithUs from '../StartWithUs/StartWithUs';

const ProductFixedIncome = () => {
  window.scrollTo(0, 0);

  
  return (
    <section>
      <Container>
        <Grid md={12} className={sessionStorage.getItem("JWT") ? 'your-dynamic-css-class-product' : 'default-class-product'}>
 <Typography style={{ textAlign: "left", fontSize: "30px", fontWeight: "bold",borderBottom: "4px solid black"}}>Fixed Income</Typography>
 <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify",paddingTop:"10px"}}>
 RBI continued the pause in the latest monetary policy statement on Aug 10, 2023 and also maintained the policy
stance as “Withdrawal of Accommodation”. The full impact of 250 basis points increase in repo rate undertaken
by RBI, since May 2022 is still panning out in relation to the growth versus inflation matrix, and any charge in the
policy rate may require some further examination of the emerging economic parameters, before taking a final
view on either increasing or decreasing the repo rate
</Typography>
<br />
<Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
US Federal Reserve raised its target federal funds rate another 0.25% at its July 2023 Federal Open Market
Committee (FOMC) meeting. This latest interest rate hike represents the 11th increase since March 2022. This
brings the target fed funds rate to a range of 5.25% to 5.50%, its highest level since early 2001
</Typography>
<br />
<Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
Our in-house view is that further rate hikes by RBI is very unlikely. There is a good chance of rate cuts by the end
of the calendar year owing to the national elections in the first half next calendar year.
</Typography>
<br/>
{/* <Grid>
<TableContainer component={Paper}>
<Table aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell  sx={{background:"#00d5d5",fontSize:"20px",padding:"8px"}} colSpan={9} align="center">Fixed Income</TableCell>
      </TableRow>
      <TableRow >
        <TableCell style={{fontSize:"20px"}} >Instrument</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Maturity</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Product</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Interest Frequency</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Indicative Offer</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Quantum</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Rating</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Face Value</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Type</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} component="th" scope="row">8.25% INDORE MUNICIPAL CORPORATION 2032</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">20-Feb-32</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">Bond</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">Half Yearly</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">8.10%</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">2032 20-Feb-32 Bond Half Yearly 8.10% 5 Lac & Multiple</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">AA+ By IND,AA By CARE</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">250</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">Secured</TableCell>
      
      </TableRow>
    </TableBody>
  </Table>
  </TableContainer>
</Grid> */}
<br />
<Grid mb={2}>
<TableContainer component={Paper}>
<Table aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell  sx={{background:"#00d5d5",fontSize:"20px",padding:"8px"}} colSpan={9} align="center">Corporate Fixed Deposits</TableCell>
      </TableRow>
      <TableRow >
        <TableCell style={{fontSize:"20px"}} >TYPE</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Product</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Min Investment Amt</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Tenure</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Interest</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Interest Payment Frequency</TableCell>
        <TableCell style={{fontSize:"20px"}}  align="center">Rating</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} component="th" scope="row">Corporate Fixed Deposits</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">Shriram Finance </TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">5000</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">12-60 months</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">7.34%- 9.10%</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">Monthly, Quartely,Half Yearly, Yearly</TableCell>
        <TableCell style={{fontSize:"14px",fontWeight:"bold"}} align="center">[ICRA]AA+ (Stable)" by ICRA and "IND AA+/Stable</TableCell>
      </TableRow>
    </TableBody>
  </Table>
  </TableContainer>
  </Grid>
</Grid>
{/* {sessionStorage.getItem("JWT")?"":
<Grid mt={2} mb={2}>
<StartWithUs/>
</Grid>
} */}

</Container>
    </section>
  )
}

export default ProductFixedIncome