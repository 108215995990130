/* eslint-disable no-useless-concat */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Container,
  Box,
  Paper,
  Stack,
  Typography,
  Button,
  TextField,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  TableBody,
  InputBase,
  IconButton,
  Grid,
  Autocomplete,
  TablePagination,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import React, { useDebugValue, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useQuery } from "react-query";
import {
  GetAllClient,
  GetAllUnlistedWithQuantity,
  listOfManufactureNDistributorCompanies,
} from "../../api/Api";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { useSelector, useDispatch } from "react-redux/es/exports";
import { changeCompanyAction } from "../../actions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { changeDate, convertDate } from "../../utils/Helper";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { Viewer } from "@react-pdf-viewer/core";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  "& .MuiTextField-root": { m: 1 },
};

const Unlisted = (props: any) => {
  const navigate = useNavigate();
  // Redux Implementation
  // const myState = useSelector((state:any)=> state.ChangeCompanyReducer)
  // const dispatch = useDispatch();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const clientDetails = props?.clientDetails;

  const [unlistedModelOpen, setUnlistedModelOpen] = useState(false);
  const [viewDocumentOpen, setViewDocumentOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyMasterCode, setCompanyMasterCode] = useState();
  const [companyData, setCompanyData] = useState<any>();

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);

  const [showCircularProgress1, setshowCircularProgress1] = useState(false);
  const [showCircularProgress2, setshowCircularProgress2] = useState(false);
  const [clientFile, setClientFile] = useState<any>("");
  const [clientFileDocId, setClientFileDocId] = useState<any>("");
  // clients data
  const [Clients, setClients] = useState([]);
  const [filteredClientsData, setFilteredClientsData] = useState([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // useEffect(()=>{
  //   sessionStorage.setItem(
  //     "CompanyData",
  //     companyData && companyData[0]?.companyMasterCode
  //   );
  //   companyData && sessionStorage.setItem(
  //     "CompanyTypeCode",
  //     companyData[0]?.companyTypeCode
  //   );
  // },[companyData])

  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompanie",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled:
        sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E",
      onSuccess(data) {
        setCompanyData(data?.data?.companies);
        setCompanyMasterCode(data?.data?.companies[0].companyMasterCode);
        sessionStorage.setItem(
          "CompanyData",
          data?.data?.companies[0]?.companyMasterCode
        );
        sessionStorage.setItem(
          "CompanyTypeCode",
          data?.data?.companies[0]?.companyTypeCode
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  let lastModifyDate: any = {};
  // const { data } = useQuery(
  //   ["GetAllUnlistedWithQuantity", companyMasterCode],
  //   () =>
  //     GetAllUnlistedWithQuantity(
  //       (!!companyMasterCode && sessionStorage.getItem("Profile") === "B") ||
  //         (!!companyMasterCode && sessionStorage.getItem("Profile") === "E")
  //         ? "?companyMasterCode=" + companyMasterCode
  //         : ""
  //     ),
  //   {
  //     enabled:
  //       (sessionStorage.getItem("Profile") === "B" && !!companyMasterCode) ||
  //       (sessionStorage.getItem("Profile") === "E" && !!companyMasterCode) ||
  //       sessionStorage.getItem("Profile") === "D",
  //     staleTime: Infinity,
  //   }
  // );
  const { data } = useQuery(
    ["GetAllUnlistedWithQuantity", "NFD000009"],
    () =>
      GetAllUnlistedWithQuantity(
        (!!companyMasterCode && sessionStorage.getItem("Profile") === "B") ||
          (!!companyMasterCode && sessionStorage.getItem("Profile") === "E")
          ? "?companyMasterCode=" + "NFD000009"
          : ""
      ),
    {
      enabled:
        (sessionStorage.getItem("Profile") === "B" && !!companyMasterCode) ||
        (sessionStorage.getItem("Profile") === "E" && !!companyMasterCode) ||
        sessionStorage.getItem("Profile") === "D",
      staleTime: Infinity,
    }
  );

  data &&
    data?.data?.unlistedList
      .sort(
        (a: any, b: any) =>
          new Date(a?.lastModifyDate)?.getTime() -
          new Date(b?.lastModifyDate)?.getTime()
      )
      ?.map(
        (item: any, index: any) =>
          (lastModifyDate[index] = item?.lastModifyDate)
      );

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredUnlisted =
    data &&
    data.data.unlistedList.filter((item: any) => {
      return (
        item.scriptName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.isinNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

  const { data: fetchClientData } = useQuery(
    "getAllClients",
    () => GetAllClient(),
    {
      onSuccess: (data: any) => {
        const newData = data.data.clients.map((item: any, index: any) => {
          return { ...item, SrNo: index + 1 };
        });
        setClients(newData);
      },
      refetchOnWindowFocus: false,
    }
  );

  function unlistedModel(status: any) {
    return status === "open"
      ? setUnlistedModelOpen(true)
      : status === "close"
      ? setUnlistedModelOpen(false)
      : null;
  }
  const viewDocument = (fileType: any) => {
    axios.get("documentupload?documentId=" + fileType).then((res: any) => {
      setClientFile(res?.data?.documentFilePath);
      setClientFileDocId(fileType);
    });
  };

  const downloadRateCard = (typeOfRateCard: any) => {
    if (typeOfRateCard === "BROKER") {
      setshowCircularProgress1(true);
    }

    if (typeOfRateCard === "CLIENT") {
      setshowCircularProgress2(true);
    }
    axios({
      url:
        "unlistedMaster/rateChart?companyMasterCode=" +
        (!!companyMasterCode ? companyMasterCode : "") +
        "&rateChartType=" +
        typeOfRateCard,
      method: "GET",
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link: any = document.createElement("a");
        link.href = url;
        var date1 = new Date();
        if (typeOfRateCard === "BROKER") {
          link.setAttribute(
            "download",
            "Rate_Cart_For_Distributor_" +
              ("0" + date1.getDate()).slice(-2) +
              "_" +
              ("0" + (date1.getMonth() + 1)).slice(-2) +
              "_" +
              date1.getFullYear() +
              ".pdf"
          );
        } else {
          link.setAttribute(
            "download",
            "Rate_Cart_For_Client_" +
              ("0" + date1.getDate()).slice(-2) +
              "_" +
              ("0" + (date1.getMonth() + 1)).slice(-2) +
              "_" +
              date1.getFullYear() +
              ".pdf"
          );
        }
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setshowCircularProgress1(false);
        setshowCircularProgress2(false);
      })
      .catch((err: any) => {
        setshowCircularProgress1(false);
        setshowCircularProgress2(false);
        toast.error("Something Went Wrong!");
      });
  };

  const downloadUnlistedReport = (reportDocument: any) => {
    if (reportDocument) {
      axios
        .get("documentupload?documentId=" + reportDocument)
        .then((res: any) => {
          axios({
            url: "documentupload/download?documentId=" + reportDocument,
            method: "GET",
            responseType: "blob", // important
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link: any = document.createElement("a");
            link.href = url;
            var date1 = new Date();
            link.setAttribute("download", res.data.documentFileName);
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
        });
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("CompanyData");
  }, []);

  let sorted = Object?.entries(lastModifyDate)?.map((it: any) => it[1]);

  const handleSearchClientFromPAN = (e: any) => {
    const val = e.target.value;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (val.length === 10 && panRegex.test(val)) {
      const filteredData = Clients.filter(
        (client: any) => client.panCardNo.toLowerCase() === val.toLowerCase()
      );
      setFilteredClientsData(filteredData);
    } else {
      setFilteredClientsData([]);
    }
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "1%" }}>
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid
            item
            lg={3}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Unlisted by Name"
                onChange={handleSearch}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>

          <Grid
            item
            lg={3}
            sx={{
              width: "100%",
            }}
          >
            {/* // Search Client from PAN Number */}
            <Paper
              component="form"
              sx={{
                p: "9px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Clients from PAN Number"
                onChange={handleSearchClientFromPAN}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              ></IconButton>
            </Paper>
          </Grid>

          {sessionStorage.getItem("Profile") === "B" ||
          sessionStorage.getItem("Profile") === "E" ? (
            distributorCompanyList &&
            companyData && (
              <Grid item lg={3} sx={{ width: "100%" }}>
                {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  disableClearable
                  options={
                    distributorCompanyList
                      ? distributorCompanyList.data.companies.map(
                          (options: any) => options
                        )
                      : []
                  }
                  getOptionLabel={(options: any) =>
                    options.companyName + " - " + options.companyTypeCode
                  }
                  onChange={(options: any, newValue) => {
                    setCompanyMasterCode(
                      newValue ? newValue.companyMasterCode : ""
                    );
                    sessionStorage.setItem(
                      "CompanyData",
                      newValue?.companyMasterCode
                    );
                    sessionStorage.setItem(
                      "CompanyTypeCode",
                      newValue?.companyTypeCode
                    );
                    setCompanyData(newValue);
                  }}
                  defaultValue={companyData[0]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Select Company"
                      size="small"
                    />
                  )}
                  sx={{ width: "100%" }}
                /> */}
                <Autocomplete
                  options={filteredClientsData}
                  getOptionLabel={(option: any) =>
                    option.companyName
                      ? `${option.fullName} - ${option.companyName} - ${option.companyTypeCode}`
                      : `${option.fullName} - ${option.contactPersonName} - ${option.companyTypeCode}`
                  }
                  // onChange={handleSelectClientDetails}
                  onChange={(options: any, newValue) => {
                    setCompanyMasterCode(
                      newValue ? newValue.companyMasterCode : ""
                    );
                    sessionStorage.setItem(
                      "CompanyData",
                      newValue?.companyMasterCode
                    );
                    sessionStorage.setItem(
                      "CompanyTypeCode",
                      newValue?.companyTypeCode
                    );
                    setCompanyData(newValue);
                    // set client details in session storage
                    // sessionStorage.setItem(
                    //   "selectedClientDetails",
                    //   JSON.stringify(newValue)
                    // );
                    setSelectedClient(newValue);
                  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Client"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )
          ) : (
            <></>
          )}
          {filteredUnlisted?.length > 0 &&
            ((sessionStorage.getItem("Profile") === "B" && companyMasterCode) ||
            (sessionStorage.getItem("Profile") === "E" && companyMasterCode) ? (
              <Grid item lg={3} sx={{ width: "100%" }}>
                <Button
                  onClick={() => unlistedModel("open")}
                  endIcon={<FileDownloadOutlinedIcon />}
                  variant="contained"
                >
                  Download Rate Card
                </Button>
              </Grid>
            ) : null)}
          {sessionStorage.getItem("Profile") === "D" ? (
            <Grid item lg={3} sx={{ width: "100%" }}>
              <Button
                onClick={() => unlistedModel("open")}
                endIcon={<FileDownloadOutlinedIcon />}
                variant="contained"
              >
                Download Rate Card
              </Button>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>

        {filteredUnlisted?.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Typography variant="subtitle2" textAlign={"end"}>
              {" "}
              Note: This Rates Are Updated on{" "}
              {convertDate(sorted[sorted?.length - 1])}{" "}
            </Typography>
          </Box>
        ) : null}

        <Grid item md={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    align="center"
                  >
                    Logo
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    align="center"
                  >
                    Unlisted Name
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    align="center"
                  >
                    ISIN
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    align="center"
                  >
                    Face Value
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    align="center"
                  >
                    Price
                  </TableCell>
                  {/* <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Available Quantity
                        </TableCell> */}
                  {/* <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Last Modify Date
                        </TableCell> */}
                  {/* <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Download 
                        </TableCell> */}
                  <TableCell
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    align="center"
                  >
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUnlisted &&
                  filteredUnlisted
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .sort((a: any, b: any) =>
                      a.scriptName.toLowerCase() < b.scriptName.toLowerCase()
                        ? 1
                        : -1
                    )
                    .map((item: any) => {
                      return (
                        <Box
                          display={"table-row"}
                          sx={{
                            textDecoration: "none",
                            "&:hover": { backgroundColor: "lightgray" },
                          }}
                          key={item.unlistedMasterCode}
                        >
                          <TableCell sx={{ padding: "10px" }} align="center">
                            <img
                              style={{ height: "30px" }}
                              src={item.scriptLogo}
                              alt=""
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "10px",
                              textDecoration: selectedClient
                                ? "underLine"
                                : "none",
                              cursor: selectedClient ? "pointer" : "default",
                            }}
                            align="center"
                            onClick={() => {
                              if (selectedClient) {
                                // pass selected client id to the next page using query params
                                navigate(
                                  `/DistUnlistedDetails/${item.unlistedMasterCode}?clientId=${selectedClient.clientId}`
                                );
                              }
                            }}
                          >
                            {item.scriptName}
                          </TableCell>
                          <TableCell sx={{ padding: "10px" }} align="center">
                            {item.isinNumber}
                          </TableCell>
                          <TableCell sx={{ padding: "10px" }} align="center">
                            {item.faceValue}
                          </TableCell>
                          <TableCell sx={{ padding: "10px" }} align="center">
                            {item.maxManufactureSaleRate.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </TableCell>
                          {/* <TableCell
                                  sx={{ padding: "10px" }}
                                  align="center"
                                >
                                  { convertDate(item.lastModifyDate)}
                                </TableCell> */}
                          {/* <TableCell
                                  sx={{ padding: "10px" }}
                                  align="center"
                                >
                                  {item.quantity}
                                </TableCell> */}
                          {/* <TableCell
                                  sx={{ padding: "10px" }}
                                  align="center"
                                >
                                  {item.reportDocument ?
                                  <Button variant='contained' onClick={()=>downloadUnlistedReport(item.reportDocument)}> Download Report </Button>:
                                  <Button variant="contained" disabled>Download Report</Button>
                                  }
                                </TableCell> */}
                          <TableCell sx={{ padding: "10px" }} align="center">
                            {item.reportDocument ? (
                              <Button
                                variant="contained"
                                // onClick={()=>viewDocument(data.data.unlistedList[0].reportDocument)}
                                onClick={() => {
                                  setViewDocumentOpen(true);
                                  viewDocument(item.reportDocument);
                                }}
                              >
                                {" "}
                                View
                              </Button>
                            ) : (
                              <Button variant="contained" disabled>
                                View
                              </Button>
                            )}
                          </TableCell>
                        </Box>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 30, 50]}
            component="div"
            count={filteredUnlisted ? filteredUnlisted.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Box>
      <Modal
        keepMounted
        open={unlistedModelOpen}
        onClose={() => unlistedModel("close")}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <IconButton
            style={{
              position: "absolute",
              fontSize: "18px",
              top: "8px",
              right: "5px",
            }}
            onClick={() => unlistedModel("close")}
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </IconButton>
          <Typography
            id="keep-mounted-modal-title"
            textAlign={"center"}
            variant="h6"
            component="h2"
          >
            Download Rate Card
          </Typography>
          <Stack direction={"column"} sx={{ marginTop: "3%" }} spacing={3}>
            <Button
              endIcon={<FileDownloadOutlinedIcon />}
              variant="contained"
              disabled={showCircularProgress1}
              onClick={() => downloadRateCard("BROKER")}
            >
              {showCircularProgress1 === true ? (
                <CircularProgress color="secondary" size={20} thickness={5} />
              ) : (
                ""
              )}{" "}
              Download for Self
            </Button>
            <Button
              endIcon={<FileDownloadOutlinedIcon />}
              variant="contained"
              disabled={showCircularProgress2}
              onClick={() => downloadRateCard("CLIENT")}
            >
              {showCircularProgress2 === true ? (
                <CircularProgress color="secondary" size={20} thickness={5} />
              ) : (
                ""
              )}{" "}
              Download for Client
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={viewDocumentOpen}
        onClose={() => setViewDocumentOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <IconButton
            style={{
              position: "absolute",
              fontSize: "18px",
              top: "8px",
              right: "5px",
            }}
            onClick={() => setViewDocumentOpen(false)}
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </IconButton>
          {
            !!clientFile ? (
              clientFile.includes(".pdf") ? (
                <div
                  className="rpv-core__viewer"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    display: "flex",
                    flexDirection: "column",
                    height: "600px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      backgroundColor: "#eeeeee",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      justifyContent: "center",
                      padding: "4px",
                    }}
                  >
                    <ZoomOutButton />
                    <ZoomPopover />
                    <ZoomInButton />
                    <Tooltip title="Download">
                      <IconButton
                        onClick={() => downloadUnlistedReport(clientFileDocId)}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      overflow: "hidden",
                    }}
                  >
                    {!!clientFile ? (
                      <Viewer
                        fileUrl={clientFile}
                        plugins={[zoomPluginInstance]}
                      />
                    ) : (
                      " No Data Found"
                    )}
                  </div>
                </div>
              ) : (
                <img
                  src={clientFile}
                  alt="image"
                  height={"50%"}
                  width={"auto"}
                />
              )
            ) : (
              ""
            )
            // <Typography variant="h6" textAlign={"center"} sx={{mt:"15%"}}>No Data Found</Typography>
          }
        </Box>
      </Modal>
    </Container>
  );
};

export default Unlisted;
