import React from 'react'
import { CartGetAllList } from './CartGetAllList'

const Cart = () => {
  return (
	<>
		<CartGetAllList/>
	</>
  )
}

export default Cart