import { Grid } from '@mui/material'
import React from 'react'
import demo from "../images/insurance.jpeg";
import "../css/whyus.css";
import Product from "../images/Product.jpg";
import Platform from "../images/Platform.jpg";
import Intellectualcapability from "../images/Intellectualcapability.jpg";

const WhyNfd = () => {
  return (
    <section>
        <div className="whyUs-main">
          <div className="whyus-cont">
            <div className="whyus-text">
              <div className="whyus-span">
                <span>Why NeoFinDesk?</span>
              </div>

              <Grid container className="index-card-why-nfd">
                <Grid item md={4}>
                  <div className="grid-why-nfd">
                    <div className="item-why-nfd">
                      <a href="#0" aria-labelledby="person1"></a>
                      <img src={Product} alt="demo" className="fin-image" />
                      <div className="item__overlay">
                        <div
                          style={{ backgroundColor: "black", color: "#00afaf" }}
                        >
                          <h3 id="person11" aria-hidden="true">
                            Product
                          </h3>
                        </div>
                        <div className="item__body why-nfd-spn1">
                            <span>
                            All financial products at one place.
                            </span>
                            <br />
                            <br />
                            <span>
                            One of its kind multi-issuer platform which   
                            connects the issuers & distributors, leading to an efficient digital  marketplace.
                            </span>
                            <br />
                            <br />
                            <span>
                            Virtual Product Desk.
                            </span>
                            <br/>
                          {/* <span>
                            Are you ready to be a part of the India’s journey to
                            5 trillion economy.{" "}
                          </span>
                          <span>
                            Come be a part of this journey and grow your
                            business by offering your clients our hand picked
                            bouquet of products available on our platform.
                          </span>
                          <span>
                            Don’t miss out on this opportunity as the AUM of PMS
                            & AIF which currently stands at 8.84cr is expected
                            to grow 6 fold in next 10 years.
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item md={4}>
                  <div className="grid-why-nfd">
                    <div className="item-why-nfd">
                      <a href="#0" aria-labelledby="person1"></a>
                      <img src={Platform} alt="demo" className="fin-image" />
                      <div className="item__overlay">
                        <h3 id="person11" aria-hidden="true">
                          Platform
                        </h3>
                        <div className="item__body why-nfd-spn2 ">
                          <span>
                            NeoFinDesk is a one of its kind multi-issuer
                            platform which connects the issuers and
                            distributors, leading to a more efficient digital
                            marketplace.
                          </span>
                          <span>
                            Our platform optimizes the investment performance of
                            clients, enables services providers to compare the
                            prices of products and also monitors risks.
                          </span>
                          <span>
                            Now distributors can leverage technology to offer
                            complex structured products to their clients using
                            our platform.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item md={4}>
                  <div className="grid-why-nfd">
                    <div className="item-why-nfd">
                      <a href="#0" aria-labelledby="person1"></a>
                      <img
                        src={Intellectualcapability}
                        alt="demo"
                        className="fin-image"
                      />
                      <div className="item__overlay">
                        <h3 id="person11" aria-hidden="true">
                          Intellectual Capability
                        </h3>
                        <div className="item__body why-nfd-spn3">
                          <span>
                            With the right approach, market intelligence and
                            guidance by your side choosing the right product
                            becomes a cakewalk.
                          </span>
                          <span>
                            We at Neo are a bunch of energetic, experienced yet
                            young team of financial enthusiasts with a combined
                            experience of 100 years who will help you grow your
                            business and your clients wealth.
                          </span>
                          <br />
                          <span>
                          Product Design, Product Sales & Technology Experts.
                          </span>
                          <span>
                          Inhouse Algo & AI based decision making tools.
                          </span>
                          <span>
                          Research Support.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>
  )
}

export default WhyNfd