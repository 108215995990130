import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { toast } from "react-toastify";
import { changeDate } from "../../../utils/Helper";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { bondEnum } from "../../../Constants/bondEnum";
import CloseIcon from "@mui/icons-material/Close";
import { addMonths, subMonths } from "date-fns";
// import { Viewer } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

const AddUpdateNCDModel = (props: any) => {
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [openDate, setOpenDate] = useState(new Date());
  const [closeDate, setCloseDate] = useState(new Date());

  const [ncdCompanyLogo, setNcdCompanyLogo] = useState<Blob | string | any>("");

  const [ncdSeriesDTO, setNcdSeriesDTO] = useState([
    {
      couponRate: 0,
      interestFrequency: "",
      productName: "",
      tenure: "",
      amtOnMaturity: "",
      effectiveYield: "",
      retailIndividual: "",
      hniIndividual: "",
      nonInstitutionalInvestors: "",
      institutionalInvestors: "",
    },
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  React.useEffect(() => {
    if (props?.data.length != 0) {
      setValue("subscriptionPlan", props.data.companyName);
      setValue("ncdName", props.data.ncdName);
      setValue("issuePrice", props.data.issuePrice);
      setValue("issueSize", props.data.issueSize);
      setValue("bondRating", props.data.bondRating);
      setValue("minimumInvestment", props.data.minimumInvestment);
      setValue("minimumLotSize", props.data.minimumLotSize);
      setValue("revenueSharing", props.data.revenueSharing);
      setValue("bondRating", props.data.bondRating);
      setOpenDate(new Date(props.data.openDate));
      setCloseDate(new Date(props.data.closeDate));
    }
  }, [props]);

  const onSubmit = (data: any) => {
    if (props?.data.length != 0) {
      const NcdDTO = {
        ...data,
        ncdId: props.data.ncdId,
        ncdSeriesDTO: null,
        openDate: changeDate(openDate),
        closeDate: changeDate(closeDate),
      };

      delete NcdDTO["closeDates"];
      delete NcdDTO["openDates"];

      const formData = new FormData();
      formData.append("ncdCompanyLogo", ncdCompanyLogo);
      formData.append("NcdDTO", JSON.stringify(NcdDTO));

      axios
        .put("ncd/updateNcd", formData)
        .then((res: any) => {
          res && toast.success(res?.data?.status?.message?.title);
          window.location.reload();
        })
        .catch((err: any) => {
          toast.error(
            err?.response?.data?.status?.message?.title
              ? err?.response?.data?.status?.message?.title
              : err?.response?.data?.title
          );
        });
    } else {
      const NcdDTO = {
        ...data,
        ncdSeriesDTO,
        openDate: changeDate(openDate),
        closeDate: changeDate(closeDate),
      };

      delete NcdDTO["closeDates"];
      delete NcdDTO["openDates"];

      const formData = new FormData();
      formData.append("ncdCompanyLogo", ncdCompanyLogo);
      formData.append("NcdDTO", JSON.stringify(NcdDTO));

      axios
        .post("ncd/saveNcd", formData)
        .then((res: any) => {
          res && toast.success(res?.data?.status?.message?.title);
          window.location.reload();
        })
        .catch((err: any) => {
          toast.error(err?.response?.data?.code);
        });
    }
  };

  const registerOptions: any = {
    ncdName: {
      required: "Please Enter NCD Name",
      maxLength: {
        value: 200,
        message: "NCD Name should not exceed 200 characters",
      },
      pattern: {
        value: /^[a-zA-Z0-9-_&\s]+$/,
        message: "Invalid NCD Name",
      },
    },
    issuePrice: {
      required: "Please Enter Issue Price",
      pattern: {
        value: /^[0-9]{1,10}$/,
        message: "Invalid Issue Price (up to 10 digits allowed)",
      },
    },
    openDate: { required: "Please Enter Open Date" },
    closeDate: { required: "Please Enter Close Date" },
    issueSize: {
      required: "Please Enter Issue Size",
      pattern: {
        value: /^[0-9]{1,6}$/,
        message: "Invalid Issue Size (up to 6 digits allowed)",
      },
    },
    effectiveYield: {
      required: "Please Enter Effective Yield",
      pattern: {
        value: /^[A-Za-z0-9\\s.&()/,+-]+$/,
        message: "Invalid Yield",
      },
    },
    bondRating: { required: "Please Enter Bond Rating" },
    revenueSharing: {
      required: "Please Enter Revenue Sharing",
      // pattern: {
      //   value: /^[0-9]{1,3}(\.\d{1,2})?$/,
      //   message:
      //     "Invalid Revenue Sharing (Up to 3 digits and optional decimals allowed)",
      // },
    },
    minimumLotSize: {
      required: "Please Enter Lot Size",
      pattern: {
        value: /^[0-9]{1,6}$/,
        message: "Invalid Issue Size (up to 8 digits allowed)",
      },
    },
    minimumInvestment: {
      required: "Please Enter Minimum Investment",
      pattern: {
        value: /^[0-9]{1,10}$/,
        message: "Invalid Minimum Investment (up to 10 digits allowed)",
      },
    },
    couponRate: {
      required: "Please Enter Coupon Rate",
      pattern: {
        value: /^[0-9]+$/,
        message: "Invalid Coupon Rate",
      },
    },
    amtOnMaturity: {
      required: "Please Enter Maturity Amount",
      pattern: {
        value: /^[0-9]+(\.[0-9]+)?$/,
        message: "Invalid Maturity Amount",
      },
      retailIndividual: {
        required: "Enter Effective Yield",
        validate: {
          validRange: (v: any) =>
            (parseFloat(v) >= 0 && parseFloat(v) <= 100) ||
            "Effective Yield to be between 0-100",
        },
        valueAsNumber: true,
      },
      hniIndividual: {
        required: "Enter Effective Yield",
        validate: {
          validRange: (v: any) =>
            (parseFloat(v) >= 0 && parseFloat(v) <= 100) ||
            "Effective Yield to be between 0-100",
        },
        valueAsNumber: true,
      },
      nonInstitutionalInvestors: {
        required: "Enter Effective Yield",
        validate: {
          validRange: (v: any) =>
            (parseFloat(v) >= 0 && parseFloat(v) <= 100) ||
            "Effective Yield to be between 0-100",
        },
        valueAsNumber: true,
      },
      institutionalInvestors: {
        required: "Enter Effective Yield",
        validate: {
          validRange: (v: any) =>
            (parseFloat(v) >= 0 && parseFloat(v) <= 100) ||
            "Effective Yield to be between 0-100",
        },
        valueAsNumber: true,
      },
    },
    //   interestFrequency: { required: "Please Enter Interest Frequency",
    //   pattern: {
    //     value: /^[A-Za-z]+$/,
    //     message: "Invalid Interest Frequency",
    //   },
    // },
    //   seriesName: { required: "Please Enter series Name" },
    //   tenure: { required: "Please Enter Tenure" },
  };

  const handleAddRow = () => {
    setNcdSeriesDTO([
      ...ncdSeriesDTO,
      {
        couponRate: 0,
        interestFrequency: "",
        productName: "",
        tenure: "",
        amtOnMaturity: "",
        effectiveYield: "",
        retailIndividual: "",
        hniIndividual: "",
        nonInstitutionalInvestors: "",
        institutionalInvestors: "",
      },
    ]);
  };
  //   const defaultLayoutPluginInstance = defaultLayoutPlugin({
  //     toolbarPlugin: {
  //         fullScreenPlugin: {
  //             // Zoom to fit the screen after entering and exiting the full screen mode
  //             onEnterFullScreen: (zoom:any) => {
  //                 zoom(SpecialZoomLevel.PageFit);
  //             },
  //             onExitFullScreen: (zoom) => {
  //                 zoom(SpecialZoomLevel.PageFit);
  //             },
  //         },
  //     },
  // });

  const handleRemoveRow = (index: any) => {
    const updatedDetails = ncdSeriesDTO.filter((_, i) => i !== index);
    setNcdSeriesDTO(updatedDetails);
  };

  const handleInputChange = (index: any, field: any, value: any) => {
    const updatedDetails: any = [...ncdSeriesDTO];
    updatedDetails[index][field] = value;
    setNcdSeriesDTO(updatedDetails);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.onClose()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <IconButton
            style={{
              position: "absolute",
              fontSize: "18px",
              top: "8px",
              right: "5px",
            }}
            onClick={() => props.onClose()}
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </IconButton>
          {props.data.length === 0 ? (
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Add New NCD
            </Typography>
          ) : (
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Add NCD Details
            </Typography>
          )}
          <br />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="NCD Name"
                  variant="outlined"
                  fullWidth
                  error={errors.ncdName ? true : false}
                  helperText={
                    !errors.ncdName ? "" : errors.ncdName.message + ""
                  }
                  {...register("ncdName", registerOptions.ncdName)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Issue Price"
                  variant="outlined"
                  fullWidth
                  error={errors.issuePrice ? true : false}
                  helperText={
                    !errors.issuePrice ? "" : errors.issuePrice.message + ""
                  }
                  {...register("issuePrice", registerOptions.issuePrice)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="Open Date"
                    inputFormat="DD/MM/YYYY"
                    value={openDate}
                    onChange={(newValue: any) => {
                      setOpenDate(newValue.toJSON());
                      setValue("openDate", newValue.toJSON());
                      // Update the minimum date for closeDate when openDate changes
                      setCloseDate((prevCloseDate) => {
                        // Make sure the closeDate is not less than the selected openDate
                        return prevCloseDate < newValue
                          ? newValue
                          : prevCloseDate;
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        error={errors.openDate ? true : false}
                        helperText={
                          errors.openDate === undefined
                            ? ""
                            : errors.openDate.message + ""
                        }
                        {...register("openDates", registerOptions.openDate)}
                      />
                    )}
                    minDate={subMonths(new Date(), 1)} // Set minimum date as 1 month before today
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={closeDate}
                    label="Close Date"
                    onChange={(newValue: any) => {
                      setCloseDate(newValue.toJSON());
                      setValue("closeDate", newValue.toJSON());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="outlined-basic"
                        variant="outlined"
                        fullWidth
                        error={errors.closeDate ? true : false}
                        helperText={
                          !errors.closeDate ? "" : errors.closeDate.message + ""
                        }
                        {...register("closeDates", registerOptions.closeDate)}
                      />
                    )}
                    minDate={openDate} // Set minimum date as the selected openDate
                    maxDate={addMonths(new Date(), 12)} // Set maximum date as 12 months from today
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Issue Size (in cr)"
                  variant="outlined"
                  fullWidth
                  error={errors.issueSize ? true : false}
                  helperText={
                    !errors.issueSize ? "" : errors.issueSize.message + ""
                  }
                  {...register("issueSize", registerOptions.issueSize)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Minimum Investment Amount"
                  variant="outlined"
                  fullWidth
                  error={errors.minimumInvestment ? true : false}
                  helperText={
                    !errors.minimumInvestment
                      ? ""
                      : errors.minimumInvestment.message + ""
                  }
                  {...register(
                    "minimumInvestment",
                    registerOptions.minimumInvestment
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Lot Size"
                  variant="outlined"
                  fullWidth
                  error={errors.minimumLotSize ? true : false}
                  helperText={
                    !errors.minimumLotSize
                      ? ""
                      : errors.minimumLotSize.message + ""
                  }
                  {...register(
                    "minimumLotSize",
                    registerOptions.minimumLotSize
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Revenue Percent"
                  variant="outlined"
                  fullWidth
                  error={errors.revenueSharing ? true : false}
                  helperText={
                    !errors.revenueSharing
                      ? ""
                      : errors.revenueSharing.message + ""
                  }
                  {...register(
                    "revenueSharing",
                    registerOptions.revenueSharing
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Bond Rating"
                  variant="outlined"
                  fullWidth
                  error={errors.bondRating ? true : false}
                  helperText={
                    !errors.bondRating ? "" : errors.bondRating.message + ""
                  }
                  {...register("bondRating", registerOptions.bondRating)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                textAlign={"center"}
                sx={{ p: 2 }}
              >
                <Button variant="contained" component="label">
                  <Typography variant="subtitle1">NCD Company Logo</Typography>
                  <input
                    hidden
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    name="profileImage"
                    onChange={(event: any) =>
                      setNcdCompanyLogo(event.target.files[0])
                    }
                  />
                </Button>

                <Typography variant="subtitle1">
                  {!!!ncdCompanyLogo
                    ? " No file choosen"
                    : ncdCompanyLogo?.name}
                </Typography>
              </Grid>

              {props.data.length === 0 && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {ncdSeriesDTO.map((item: any, index: any) => (
                      <Paper elevation={8} sx={{ p: 2, mb: 2 }}>
                        <Typography gutterBottom>
                          {" "}
                          Add Series {index + 1}{" "}
                        </Typography>
                        <Grid
                          container
                          rowSpacing={2}
                          columnSpacing={2}
                          sx={{ mb: 2 }}
                        >
                          <Grid item xs={12} sm={12} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Series Name"
                              variant="outlined"
                              fullWidth
                              error={errors.productName ? true : false}
                              helperText={
                                !errors.productName
                                  ? ""
                                  : errors.productName.message + ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "productName",
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={3}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={bondEnum.CouponFrequencyEnum.map(
                                (option: any) => option
                              )}
                              getOptionLabel={(options: any) => options.label}
                              onChange={(options: any, newValue) => {
                                handleInputChange(
                                  index,
                                  "interestFrequency",
                                  newValue.value
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Interest Payout Frequency"
                                  variant="outlined"
                                  fullWidth
                                  error={
                                    errors.interestFrequency ? true : false
                                  }
                                  helperText={
                                    !errors.interestFrequency
                                      ? ""
                                      : errors.interestFrequency.message + ""
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Tenure (Months)"
                              variant="outlined"
                              fullWidth
                              error={errors.tenure ? true : false}
                              helperText={
                                !errors.tenure ? "" : errors.tenure.message + ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "tenure",
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Coupon Rate (%)"
                              variant="outlined"
                              fullWidth
                              error={errors.couponRate ? true : false}
                              helperText={
                                !errors.couponRate
                                  ? ""
                                  : errors.couponRate.message + ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "couponRate",
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Effective Yield (%)"
                              variant="outlined"
                              fullWidth
                              error={errors.effectiveYield ? true : false}
                              helperText={
                                !errors.effectiveYield
                                  ? ""
                                  : errors.effectiveYield.message + ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "effectiveYield",
                                  e.target.value
                                )
                              }
                              // {...register("effectiveYield", registerOptions.effectiveYield)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Amount On Maturity (Rs)"
                              variant="outlined"
                              fullWidth
                              error={errors.amtOnMaturity ? true : false}
                              helperText={
                                !errors.amtOnMaturity
                                  ? ""
                                  : errors.amtOnMaturity.message + ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "amtOnMaturity",
                                  e.target.value
                                )
                              }
                              // {...register("amtOnMaturity", registerOptions.amtOnMaturity)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
                          <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
                          <Grid item xs={12} sm={12} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Retail Individual (%)"
                              variant="outlined"
                              fullWidth
                              error={errors.retailIndividual ? true : false}
                              helperText={
                                !errors.retailIndividual
                                  ? ""
                                  : errors.retailIndividual.message + ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "retailIndividual",
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="HNI Individual (%)"
                              variant="outlined"
                              fullWidth
                              error={errors.hniIndividual ? true : false}
                              helperText={
                                !errors.hniIndividual
                                  ? ""
                                  : errors.hniIndividual.message + ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "hniIndividual",
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Non-Institutional Investors (%)"
                              variant="outlined"
                              fullWidth
                              error={
                                errors.nonInstitutionalInvestors ? true : false
                              }
                              helperText={
                                !errors.nonInstitutionalInvestors
                                  ? ""
                                  : errors.nonInstitutionalInvestors.message +
                                    ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "nonInstitutionalInvestors",
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Institutional Investors (%)"
                              variant="outlined"
                              fullWidth
                              error={
                                errors.institutionalInvestors ? true : false
                              }
                              helperText={
                                !errors.institutionalInvestors
                                  ? ""
                                  : errors.institutionalInvestors.message + ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "institutionalInvestors",
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid textAlign={"end"} sx={{ mb: 2 }}>
                          <Fab
                            color="primary"
                            aria-label="add"
                            variant="extended"
                            sx={{ mr: 1 }}
                            onClick={() => handleRemoveRow(index)}
                          >
                            <RemoveIcon /> Remove
                          </Fab>
                        </Grid>
                      </Paper>
                    ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ textAlign: "end" }}
                  >
                    <Fab
                      color="primary"
                      aria-label="add"
                      variant="extended"
                      sx={{ mr: 1 }}
                      onClick={handleAddRow}
                    >
                      <AddIcon />
                      Add Series
                    </Fab>
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={12} md={12} lg={12} textAlign={"center"}>
                <Button type="submit" variant="contained">
                  {" "}
                  {showCircularProgress ? (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      thickness={5}
                    />
                  ) : (
                    ""
                  )}
                  {"  "} Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "80%",
  overflowY: "auto",
};

export default AddUpdateNCDModel;
