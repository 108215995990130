import { Grid,Box, Typography, IconButton, Stack, Button, Paper, Chip, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useQuery } from 'react-query';
import { GetMaturityCashFlow, GetQuoteDetailsByIsin } from '../api/Api';
import axios from 'axios';
import { convertDateDMY } from '../utils/Helper';

const Calculator = (props:any) => {
  const [quoteDetail , setQuoteDetail] = useState<any>();
  const [quantityCount, setQuantityCount] = useState<any>(1);
  const [num , setNum] = useState(0);
  const [spread, setSpread] = useState(0.00);
  const [ipDates, setipDates] = useState<any>();

  const [minValue,setMinValue] = useState<any>(0);
  const [maxValue,setMaxValue] = useState<any>(0);
  const [multiple,setMultiple] = useState<any>(0);

  const [updatedPrice , setUpdatedPrice] = useState<any>();
  const [updatedYield , setUpdatedYield] = useState<any>();

  const handleAdd = () => {
    if(num == maxValue){

    }else{
      setNum(num + multiple);
    }
  }

  const handleSubs = () => {
    if(num == minValue){

    }else{
      setNum(num - multiple);
    }
  }

  const handleSpreadAdd = () => {
    if(spread >= 0)
    {
      setSpread(spread + 0.10);
    }
    else{}
  }

  const handleSpreadSubs = () => {
    if(spread > 0)
    {
      setSpread(spread - 0.10);
    }
    else{}
  }


  const AddQuantity = () => {
    if(num == maxValue){

    }else{
      setQuantityCount(quantityCount + 1);
    }
  }

  const DecQuantity = () => {
    if(num == minValue){

    }else{
      setQuantityCount(quantityCount - 1);
    }
  }

  
  //Quote Details
  const { data:getquoteDetais, refetch } = useQuery(["getquoteDetailByIsin",props?.item?.isinNumber],()=>GetQuoteDetailsByIsin(props.item.isinNumber),
    {enabled:!!props?.item?.isinNumber , 
      onSuccess:(data:any)=>{
        setQuoteDetail(data.data.quoteDetails[0]);
        setNum(data.data.quoteDetails[0] && data.data.quoteDetails[0].minValue);
        setMinValue(data.data.quoteDetails[0] && data.data.quoteDetails[0].minValue);
        setMaxValue(data.data.quoteDetails[0] && data.data.quoteDetails[0].maxValue);
        setMultiple(data.data.quoteDetails[0] && data.data.quoteDetails[0].multiple);
      }
    })

    console.log("quoteDetail", quoteDetail)

  
  //CashFlow
    const { data:cashflowMaturity, refetch:cashFlowrefetch } = useQuery(["cashflowMaturity",props?.item?.isinNumber],()=>GetMaturityCashFlow(props.item.isinNumber),
        {enabled:!!props?.item?.isinNumber , 
        onSuccess:(data:any)=>{
        // console.log("Maturity Cashflow Dates" , data.data.cashFlowMaturityCallList);
        setipDates(data.data.cashFlowMaturityCallList);
      }
    })

    //Xirr with spread
    const getSpreadYield = () => {
      var sellPrice = quoteDetail.salePrice + spread;
      axios.get('quoteDetails/xirrCalculation?isinNumber='+ props.item.isinNumber + '&salePrice=' + sellPrice?.toFixed(2))
      .then((res:any) =>{
        setUpdatedPrice(res.data.xirrNXnpvDTO.salePrice) 
        setUpdatedYield(res.data.xirrNXnpvDTO.saleYtm)
      } 
      )
    }

    console.log("updated price", updatedPrice)
    console.log("updated yield", updatedYield)

    //Accrued Interest calculation
    var daysDiff;
    if (ipDates && ipDates.length > 0) {
      // Generate a list of all IP dates
      const dates: any[] = ipDates.map((it: any) => new Date(it.interestDate));
    
      // Get today's date
      const today = new Date();
    
      // Find where today's date lies within the list
      let lowerIndex = -1;
      let upperIndex = -1;
    
      for (let i = 0; i < dates.length; i++) {
        if (dates[i] <= today) {
          lowerIndex = i;
        } else {
          upperIndex = i;
          break;
        }
      }
    
      // Output the result
      console.log("Today's date lies between:");
      console.log("Lower Index:", dates[lowerIndex]);
      console.log("Upper Index:", dates[upperIndex]);
    
      // Set the time portion of the dates to midnight
      dates[lowerIndex]?.setHours(0, 0, 0, 0);
      dates[upperIndex]?.setHours(0, 0, 0, 0);
    
      // Calculate the time difference in milliseconds
      const timeDiff = Math.abs(dates[lowerIndex]?.getTime() - dates[upperIndex]?.getTime());
    
      // Convert the time difference to days
      daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      
      // Output the result
      console.log("Days between the two dates:", daysDiff);
    } else {
      console.log("ipDates array is undefined or empty.");
    }

    const faceValue = props && props?.item?.faceValue;
    const coupon = props && props?.item?.coupon;
    const days = daysDiff && daysDiff/365;

    console.log("accrured values" , faceValue , coupon , days);

    const accruedInterest:any = faceValue && days && coupon ? faceValue*coupon*days : "--"

    console.log("Accrued interest --" , accruedInterest);

    //Principle Consideration Calculation

    const principleConsideration = num * quoteDetail?.salePrice / 100;

    //Total Consideration Calculation

    const totalConsideration = accruedInterest + principleConsideration;


  return (
    
    <Grid container spacing={3} sx={{display:'flex', justifyContent:'center'}}>
      <Grid item md={12}>
        <Box sx={{display:'flex'}}>
        <Box sx={{
          backgroundColor:'#F8EEEE', 
          width:'100%', 
          height:'28px', 
          borderRadius:'10px',
          margin:'0 auto',
          marginTop:'19.5px',
          display:'flex',
          justifyContent:'center'
          }}>
         <Typography variant={'subtitle1'} >
           {sessionStorage.getItem('value')}
          </Typography> 
        </Box>
        </Box>
      </Grid>


      <Grid item md={6} sx={{display:'flex', justifyContent:'center'}}>
        <Typography sx={{fontWeight:'500', fontSize:'0.8rem'}}>Spread :</Typography> 
        <Typography sx={{fontSize:'0.8rem'}}>{sessionStorage.getItem('spread')}</Typography>
      </Grid>

      {/* <Grid item md={6} sx={{display:'flex', justifyContent:'center'}}>
        <Button variant='contained' size='small' onClick={getSpreadYield} sx={{marginBottom:'9px'}}>Add Spread</Button>
      </Grid> */}

      <Grid item md={6} sx={{display:'flex', justifyContent:'center'}}>
        <Typography sx={{fontWeight:'500', fontSize:'0.8rem'}}>Price :</Typography> 
        <Typography sx={{fontSize:'0.8rem'}}>{sessionStorage.getItem('price')}</Typography>
      </Grid>

      <Grid item md={6} sx={{display:'flex', justifyContent:'center'}}>
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem'}}>Yield :</Typography> 
        <Typography sx={{fontSize:'0.8rem'}}>{sessionStorage.getItem('yield')}%</Typography>
      </Grid>
      
      <Grid item md={6} sx={{display:'flex', justifyContent:'center'}}>
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem'}}>Quantity :</Typography> 
        <Typography sx={{fontSize:'0.8rem'}}>{sessionStorage.getItem('quantity')}</Typography>
      </Grid>

      <Grid item md={6} sx={{textAlign:'center'}}>
        <Typography sx={{fontWeight:'500', fontSize:'0.8rem'}}>Deal Date :</Typography> 
        <Typography sx={{fontSize:'0.8rem'}}>{ convertDateDMY(sessionStorage.getItem('dealDate'))}</Typography>
      </Grid>

      <Grid item md={6} sx={{textAlign:'center'}}>
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem'}}>Value Date :</Typography> 
        <Typography sx={{fontSize:'0.8rem'}}>{convertDateDMY(sessionStorage.getItem('valueDate'))}</Typography>
      </Grid>

<Grid container spacing={3} sx={{margin:'0 0.5rem'}}>
      <Grid item md={12} sx={{display:'flex', justifyContent:'space-between'}}>
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem', margin:'auto 0'}}>Principle Consideration :</Typography>
        <Chip label={sessionStorage.getItem('Principle')} /> 
      </Grid>
      <Grid item md={12} sx={{display:'flex', justifyContent:'space-between'}}>
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem', margin:'auto 0'}}>Accrued Interest :</Typography>
        <Chip label={sessionStorage.getItem('Accrued')}/> 
      </Grid>
      <Grid item md={12} sx={{display:'flex', justifyContent:'space-between'}}>
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem', margin:'auto 0'}}>Total Consideration :</Typography>
        <Chip label={sessionStorage.getItem('TotalConsideration')} /> 
      </Grid>
</Grid>
    </Grid>
  )
}

export default Calculator