import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  GetAllClient,
  GetBrokeragePayoutByCompanyMasterCode,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  MfGetById,
  listOfManufactureNDistributorCompanies,
  MfGetAllList,
} from "../../api/Api";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm } from "react-hook-form";
import { productTypeEnum } from "../../Constants/ProductTypeEnum";
import { changeDate } from "../../utils/Helper";
import axios from "axios";
import { toast } from "react-toastify";
import { CREATE_NEW_ORDER } from "../../utils/API_Names";

const OrderWindowForMF = () => {
  const [mfData, setMfData] = useState<any>();

  const [companyMasterCode, setCompanyMasterCode] = React.useState();
  const [distributorCode, setDistributorCode] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [orderDate, seOrderDate] = React.useState(new Date());

  let mfSchemeCode = mfData?.schemeCode;

  const { data: mfList, isLoading } = useQuery("MfOrder", () =>
    MfGetAllList("Y")
  );

  const { data: mfDetils, isLoading: mfDetilsLoading } = useQuery(
    ["MFGetById", mfSchemeCode],
    () => MfGetById(mfSchemeCode),
    {
      enabled: !!mfSchemeCode,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
    }
  );

  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompanie",
    () => listOfManufactureNDistributorCompanies("D"),
    {
      enabled: sessionStorage.getItem("Profile") === "B",
      staleTime: Infinity,
    }
  );

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", companyMasterCode],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode("D", companyMasterCode),
    {
      enabled: !!companyMasterCode,
    }
  );

  const { data: clientData } = useQuery(
    ["getAllClients", companyMasterCode, distributorCode],
    () => GetAllClient(companyMasterCode, distributorCode),
    {
      enabled:
        !!companyMasterCode ||
        sessionStorage.getItem("Profile") === "D" ||
        sessionStorage.getItem("Profile") === "E",
    }
  );

  const { data: brokerageData } = useQuery(
    ["GetBrokeragePayoutByCompanyMasterCode", companyMasterCode],
    () =>
      GetBrokeragePayoutByCompanyMasterCode(
        !!sessionStorage.getItem("CompanyMasterCode") &&
          sessionStorage.getItem("CompanyMasterCode") !== "null"
          ? sessionStorage.getItem("CompanyMasterCode")
          : companyMasterCode
      ),
    {
      enabled:
        (!!sessionStorage.getItem("CompanyMasterCode") &&
          sessionStorage.getItem("CompanyMasterCode") !== "null") ||
        !!companyMasterCode,
    }
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const registerOptions = {
    buyRate: {
      required: "Please Enter Valid Amount",
      pattern: {
        value: /^[0-9]+$/,
        message: "Invalid Amount",
      },
      validate: {
        positive: (v: any) =>
          parseInt(v) >=
            mfDetils.data.mutualFundResponse[0]?.schemeMinInvestment ||
          `Please Enter A Amount More then ${mfDetils.data.mutualFundResponse[0]?.schemeMinInvestment}`,
      },
    },
  };

  const onSubmitInvestNow = (dataa: any) => {
    let MfInvestNowdto = {
      productMasterCode: mfDetils.data.mutualFundResponse[0].productMasterCode,
      clientId: clientId,
      version: "1",
      productTypeCode: productTypeEnum.ALTERNATIVE_INVESTMENT_FUND,
      orderDate: changeDate(orderDate),
      distCompanyMasterCode: companyMasterCode,
      distEmployeeCode: distributorCode,
      schemeCode: mfDetils.data.mutualFundResponse[0].schemeCode,
      buyRate: !!dataa.buyRate
        ? dataa.buyRate
        : mfDetils.data.mutualFundResponse[0].schemeMinInvestment,
    };

    axios
      .post(CREATE_NEW_ORDER, MfInvestNowdto)
      .then((res: any) => {
        res && toast.success(res?.data?.status?.message?.title);
        window.location.reload();
      })
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };

  const mfBrokerage =
    brokerageData &&
    brokerageData.data?.companyBrokerage?.filter(
      (item: any) =>
        item?.productTypeCode === productTypeEnum?.ALTERNATIVE_INVESTMENT_FUND
    )[0];

  const profit: any =
    !!watch("buyRate") && mfDetils
      ? // here it will check for is NAN
        isNaN(
          parseFloat(
            (
              watch("buyRate") *
              (parseFloat(mfDetils.data.mutualFundResponse[0].managementFees) /
                100) *
              (parseFloat(mfDetils.data.mutualFundResponse[0].fixedFee) / 100)
            ).toFixed(2)
          ) -
            parseFloat(
              (
                watch("buyRate") *
                (parseFloat(
                  mfDetils.data.mutualFundResponse[0].managementFees
                ) /
                  100) *
                (parseFloat(mfDetils.data.mutualFundResponse[0].fixedFee) /
                  100) *
                (parseFloat(mfBrokerage?.brokerageValueForIV) / 100)
              ).toFixed(2)
            )
        )
        ? "0"
        : // this will print on display
          parseFloat(
            (
              watch("buyRate") *
              (parseFloat(mfDetils.data.mutualFundResponse[0].managementFees) /
                100) *
              (parseFloat(mfDetils.data.mutualFundResponse[0].fixedFee) / 100)
            ).toFixed(2)
          ) -
          parseFloat(
            (
              watch("buyRate") *
              (parseFloat(mfDetils.data.mutualFundResponse[0].managementFees) /
                100) *
              (parseFloat(mfDetils.data.mutualFundResponse[0].fixedFee) / 100) *
              (parseFloat(mfBrokerage?.brokerageValueForIV) / 100)
            ).toFixed(2)
          )
      : // this is else condition for nan
        "0";

  return (
    <>
      {mfList ? (
        <Autocomplete
          sx={{ width: 500 }}
          disablePortal
          id="combo-box-demo"
          options={
            mfList &&
            mfList.data.mutualFundResponse.map((option: any) => option)
          }
          getOptionLabel={(options: any) => options?.schemeName}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={option?.amcInfoDTO?.amcLogo}
                srcSet={option?.amcInfoDTO?.amcLogo}
                alt=""
              />
              {option?.schemeName}
            </Box>
          )}
          onChange={(options: any, newValue) => {
            setMfData(newValue || "");
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Scheme" size="small" />
          )}
        />
      ) : (
        <Typography variant="subtitle1" textAlign={"center"} sx={{ mt: 2 }}>
          <CircularProgress size={15} sx={{ mr: 1 }} />
          Please Wait We're Fetching MF Schemes For You...{" "}
        </Typography>
      )}

      {mfList && mfDetils
        ? mfDetils.data.mutualFundResponse.map((item: any) => (
            <Box>
              <Typography variant="h6" textAlign={"center"} gutterBottom>
                Order Details
              </Typography>
              <Divider sx={{ mb: 1 }} />
              <Typography variant="h5" textAlign={"center"} gutterBottom>
                {item.schemeName || "-"}
              </Typography>
              <Grid container textAlign={"center"}></Grid>

              <Grid container spacing={2} textAlign={"center"} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Typography variant="subtitle1">
                    Exit Load / Lock-in
                  </Typography>
                  <Typography variant="subtitle2">
                    {item.schemeExitLoad || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">Fees Structure</Typography>
                  <Typography variant="subtitle2">
                    {item.schemeFeeStructure || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">NAV</Typography>
                  <Typography variant="subtitle2">
                    {item.fundPerformance?.nav}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">Min Investment</Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {item.schemeMinInvestment?.toLocaleString("en-IN")
                      ? "₹ " + item.schemeMinInvestment?.toLocaleString("en-IN")
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container rowGap={2} columnGap={2}>
                {sessionStorage.getItem("Profile") === "B" ? (
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={
                        distributorCompanyList &&
                        distributorCompanyList.data.companies.map(
                          (options: any) => options
                        )
                      }
                      getOptionLabel={(options: any) => options.companyName}
                      fullWidth
                      onChange={(options: any, newValue) => {
                        setCompanyMasterCode(
                          newValue ? newValue.companyMasterCode : ""
                        );
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label="Select Distributor Company"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                {sessionStorage.getItem("Profile") === "B" ? (
                  <>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={
                          UserProfilesByCompanyMasterCode
                            ? UserProfilesByCompanyMasterCode.data.userProfile.map(
                                (options: any) => options
                              )
                            : []
                        }
                        getOptionLabel={(options: any) => options?.userCode}
                        fullWidth
                        onChange={(options: any, newValue) => {
                          setDistributorCode(
                            newValue ? newValue.employeeCode : ""
                          );
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Select Distributor User"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {clientData && (
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={clientData.data.clients.map(
                        (options: any) => options
                      )}
                      getOptionLabel={(options: any) =>
                        options.fullName + " - " + options.panCardNo
                      }
                      fullWidth
                      onChange={(options: any, newValue) => {
                        setClientId(newValue ? newValue.clientId : "");
                      }}
                      renderInput={(params: any) => (
                        <form
                          onSubmit={handleSubmit(onSubmitInvestNow)}
                          id="form1"
                        >
                          <TextField
                            {...params}
                            label="Select Clients"
                            size="small"
                          />
                        </form>
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label="Order Date"
                      inputFormat="DD/MM/YYYY"
                      value={orderDate}
                      onChange={(newValue: any) => {
                        seOrderDate(newValue.toJSON());
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <form onSubmit={handleSubmit(onSubmitInvestNow)} id="form1">
                    <TextField
                      label="Investment Amount"
                      size="small"
                      fullWidth
                      defaultValue={item.schemeMinInvestment}
                      error={errors.buyRate ? true : false}
                      helperText={
                        !errors.buyRate ? "" : errors.buyRate.message + ""
                      }
                      {...register("buyRate", registerOptions.buyRate)}
                    />
                  </form>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography>
                    {" "}
                    Your Profit will be : {parseFloat(profit)?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              <Box
                sx={{
                  textAlign: "center",
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <form onSubmit={handleSubmit(onSubmitInvestNow)} id="form1">
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitInvestNow)}
                    variant="contained"
                  >
                    Order Now
                  </Button>
                </form>
              </Box>
            </Box>
          ))
        : mfSchemeCode && (
            <Typography variant="subtitle1" textAlign={"center"} sx={{ mt: 2 }}>
              {" "}
              <CircularProgress size={15} sx={{ mr: 1 }} /> Please Wait We're
              Fetching MF Schemes Details For You...{" "}
            </Typography>
          )}
    </>
  );
};

export default OrderWindowForMF;
