import axios from "axios";
import React, { useState } from "react";
import { productTypeEnum } from "../../Constants/ProductTypeEnum";
import { Dayjs } from "dayjs";
import { useQuery } from "react-query";
import { Stack, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AifPayoutSortTable from "./AifPayoutSortTable";

const AifPayout = () => {
  const [aifPayout, setaifPayout] = useState<any>([]);
  const [fromDate, setFromDate] = React.useState<Dayjs | null | any>(
    new Date(new Date().setMonth(new Date().getMonth() - 3)).toJSON()
  );
  const [toDate, setToDate] = React.useState<Dayjs | null | any>(
    new Date().toJSON()
  );

  const productListAPI = async () => {
    const data: any = await axios.get(
      `payout/payoutFilter?fromDate=${fromDate}&productTypeCode=${productTypeEnum.ALTERNATIVE_INVESTMENT_FUND}&toDate=${toDate}`
    );
    return data;
  };

  const { data: getUserTypeDetails, refetch } = useQuery(
    "getUserTypeDetail",
    productListAPI,
    {
      onSuccess: (data: any) => {
        setaifPayout(data.data.payoutDTOList);
      },
      staleTime: Infinity,
    }
  );

  return (
    <>
      <Stack direction={"row"} spacing={3} width={"30%"} marginLeft={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            label="From Date"
            inputFormat="DD/MM/YYYY"
            value={fromDate}
            onChange={(newValue) => {
              setFromDate(newValue.toJSON());
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                defaultValue={fromDate}
                sx={{ width: "100%", marginBottom: "20px" }}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            label="To Date"
            inputFormat="DD/MM/YYYY"
            minDate={fromDate}
            value={toDate}
            onChange={(newValue) => {
              setToDate(newValue.toJSON());
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                defaultValue={toDate}
                sx={{ width: "100%", marginBottom: "20px" }}
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
      {!!aifPayout && (
        <AifPayoutSortTable
          data={aifPayout}
          toDate={toDate}
          fromDate={fromDate}
        />
      )}
    </>
  );
};

export default AifPayout;
