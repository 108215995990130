import { Box, Container, Grid, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withParam } from '../utils/Router.Helper';
import UpdateMaturityCashflow from './UpdateMaturityCashflow';
import UpdateCallCashflow from './UpdateCallCashflow';
import axios from 'axios';
import { convertDateDMY } from '../utils/Helper';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const UpdateCashflow = (props:any) => {
  console.log("Update Props", props);
  const [value, setValue] = React.useState(0);
  const [bondData, setBondData]= useState<any>();

  useEffect(() => {
    axios.get('bondwork/listOfBondDetails/' + props.params.isin).then((res:any)=>{
      setBondData(res.data.bondWork[0])
    })
  },[])

  console.log("BondData", bondData);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box sx={{marginTop:'2%'}}>
      <Container maxWidth='xl'>
        <Grid md={12}>
          <Typography variant='h4'><b> {bondData && bondData.issuerName} , {bondData && convertDateDMY(bondData.issuanceDate && bondData.issuanceDate.slice(0,10))}</b></Typography>
        </Grid>
      <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        { bondData && bondData.maturityDate && <Tab label="Maturity" {...a11yProps(0)} /> }
          {bondData && bondData.callDate && <Tab label="Call" {...a11yProps(1)} /> }
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <UpdateMaturityCashflow isin = {props.params.isin} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UpdateCallCashflow isin = {props.params.isin} />
      </TabPanel>
      </Box>
      </Container>
    </Box>
  )
}

export default withParam(UpdateCashflow)