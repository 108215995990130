export const ProductFileType = [
	{ label:"REVENUE SHEET",value:"REVENUE_SHEET" },
	{ label:"PRODUCT OFFERING",value:"PRODUCT_OFFERING" },
	{ label:"PRODUCT DESK",value:"PRODUCT_DESK" },
	{ label:"PRODUCT COLLATERALS",value:"PRODUCT_COLLATERALS" },
	{ label:"PRODUCT HIGHLIGHTS",value:"PRODUCT_HIGHLIGHTS" },
	{ label:"FOCUS PRODUCT",value:"FOCUS_PRODUCT" },
	{ label:"CONTEST",value:"CONTEST" },
]

export const ProductTypeForPerOrder = [
	{ productTypeCode:"11",productName:"UNLISTED" },
	{ productTypeCode:"13",productName:"RESEARCH SERVICES" },
	{ productTypeCode:"12",productName:"BOND WORKS" },
]

export const ProductTypeForPerOrderForD = [
	{ productTypeCode:"11",productName:"UNLISTED" },
	{ productTypeCode:"13",productName:"RESEARCH SERVICES" },
]