import dayjs from "dayjs";

export function convertDate(str) {
  var date = new Date(str);
  var formattedDate = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;
  return formattedDate;
}

export function convertDateIntoTime(str) {
  console.log("daysjs", dayjs(str));
  var formattedDate = `${dayjs(str).$H} : ${dayjs(str).$m}`;
  return formattedDate;
}

export function changeDate(e) {
  var date = new Date(e),
    month = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), month, day].join("-");
}

export function convertDateYMD(dateString) {
  if (!!dateString) {
    var parts = dateString?.split("-");
    if (
      parts?.length === 3 &&
      parts[0]?.length <= 2 &&
      parts[1]?.length <= 2 &&
      parts[2]?.length === 4
    ) {
      // Date is in DD-MM-YYYY format, convert to YYYY-MM-DD
      // var parts = dateString.split('-');
      var day = parts[0].padStart(2, "0");
      var month = parts[1].padStart(2, "0");
      var year = parts[2];

      return year + "-" + month + "-" + day;
    } else {
      // Date is already in YYYY-MM-DD format, no need to change
      return dateString;
    }
  }
}

export function convertDateDMY(dateString) {
  if (!!dateString) {
    var parts = dateString.split("-");
    var year = parts[0];
    var month = parts[1]?.padStart(2, "0");
    var day = parts[2]?.padStart(2, "0");

    return day + "-" + month + "-" + year;
  }
}

export function convertDatexample(dateString) {
  var parts = dateString.split("-");

  if (
    parts.length === 3 &&
    parts[0].length === 2 &&
    parts[1].length === 2 &&
    parts[2].length === 4
  ) {
    // Date is in DD-MM-YYYY format, convert to YYYY-MM-DD
    var day = parts[0].padStart(2, "0");
    var month = parts[1].padStart(2, "0");
    var year = parts[2];

    return year + "-" + month + "-" + day;
  } else {
    // Date is already in YYYY-MM-DD format, or invalid format, no need to change
    return dateString;
  }
}

export function getFiscalDate() {
  var today = new Date();
  var curMonth = today.getMonth();
  var fiscalYr = "";
  if (curMonth >= 3) {
    fiscalYr = today.getFullYear().toString() + "-04-01";
  } else {
    fiscalYr = (today.getFullYear() - 1).toString() + "-04-01";
  }
  return fiscalYr;
}

export function getLastFinancialDate() {
  var today = new Date();
  var curMonth = today.getMonth();
  var fiscalYr = "";
  if (curMonth >= 3) {
    fiscalYr = (today.getFullYear() + 1).toString() + "-03-31";
  } else {
    fiscalYr = today.getFullYear().toString() + "-03-31";
  }
  return fiscalYr;
}

export function getQuarterDates() {
  var today = new Date();
  var curMonth = today.getMonth();
  var fiscalYear = today.getFullYear();
  var quarterStart;
  var quarterEnd;

  if (curMonth >= 0 && curMonth < 3) {
    // Q1 (January - March)
    quarterStart = new Date(fiscalYear, 0, 1);
    quarterEnd = new Date(fiscalYear, 2, 31);
  } else if (curMonth >= 3 && curMonth < 6) {
    // Q2 (April - June)
    quarterStart = new Date(fiscalYear, 3, 1);
    quarterEnd = new Date(fiscalYear, 5, 30); // Assuming 30 days in June
  } else if (curMonth >= 6 && curMonth < 9) {
    // Q3 (July - September)
    quarterStart = new Date(fiscalYear, 6, 1);
    quarterEnd = new Date(fiscalYear, 8, 30); // Assuming 30 days in September
  } else {
    // Q4 (October - December)
    quarterStart = new Date(fiscalYear, 9, 1);
    quarterEnd = new Date(fiscalYear, 11, 31);
  }

  return {
    quarter: "Q" + (Math.floor(curMonth / 3) + 1),
    quarterStartDate: changeDate(quarterStart),
    quarterEndDate: changeDate(quarterEnd),
  };
}

export function numDifferentiation(value) {
  let val = Math.abs(value);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2);
    let FormatedValue = CommaFormater2(val);
    val = FormatedValue + "Cr";
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2);
    let FormatedValue = CommaFormater2(val);
    val = FormatedValue + "Lakh";
  } else if (val >= 1000) {
    let FormatedValue = CommaFormater2(val);
    val = FormatedValue;
  }
  return val;
}

export function CommaFormater2(number) {
  return new Intl.NumberFormat("en-IN").format(number);
}

export function CurrencyFormate(number) {
  number = !isNaN(number) ? number : 0;
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  return formatter.format(number);
}

export function isValidUsername(input) {
  var specialCharCount = (input.match(/[!@#$%^&*(),.?":{}|<>]/g) || []).length;
  return (
    /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<> ]*$/.test(input) && specialCharCount <= 3
  );
}
