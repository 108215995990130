import React from "react";
import "../css/footer.css";

const FooterCopyright = () => {
  return (
    <div className="copyright">
      <footer>
        <div className="text-center">
          <small className="text copyright-neof-indesk-c">
            Copyright © 2022. neofindesk.
          </small>
        </div>
      </footer>
    </div>
  );
};

export default FooterCopyright;
