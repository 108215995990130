import React, { useEffect, useState } from 'react'
import { Box, Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, styled, tableCellClasses } from '@mui/material'
import axios from 'axios';
import CallCfRows from './CallCfRows';
import { changeDate, convertDateYMD } from '../utils/Helper';
import { toast } from 'react-toastify';

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.common.black,
      fontWeight: "600",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  interface Column {
    id: "isin" | "interestDate" | "interestPayment" | "totalPayout" | "update" | "nonHolidays" | "days" | 'redemption' ;
    label: string;
    minWidth?: number;
    align?: "right" | "left" | "center";
    format?: (value: number) => string;
  }
  
  const columns: Column[] = [
    { id: "isin", label: "ISIN", minWidth: 170, align: "center", },
    { id: "interestDate", label: "Interest Date", align: "center", minWidth: 100 },
    { id: "nonHolidays", 
      label: "Non Holiday Date", 
      align: "center",
      minWidth: 100 
    },
    { id: "days", 
      label: "Days", 
      minWidth: 100,
      align: "center",
    },
    {
      id: "interestPayment",
      label: "Interest Payout",
      minWidth: 100,
      align: "center",
      //   format: (value: number) => value.toLocaleString('en-US'),
    },
    {
      id: "redemption",
      label: "Redemption Payout",
      minWidth: 100,
      align: "center",
      //   format: (value: number) => value.toLocaleString('en-US'),
    },
    {
      id: "totalPayout",
      label: "Total Payout",
      minWidth: 100,
      align: "center",
      //   format: (value: number) => value.toLocaleString('en-US'),
    },
    {
      id: "update",
      label: "Update",
      minWidth: 100,
      align: "center",
      //   format: (value: number) => value.toLocaleString('en-US'),
    },
  ];

const UpdateCallCashflow = (props:any) => {
    const [cashflow, setCashflow] = useState<any>();
    const [edit, setEdit] = useState<any>(true);

    const [interestPayment, setInterestPayment] = useState<any>();
    const [redemptionDate, setRedemptionDate] = useState<any>();
    const [days, setDays] = useState<any>();
    const [nonHolidays, setNonHolidays] = useState<any>();
    const [redemption, setRedemption] = useState<any>();
    const [totalPayout, setTotalPayout] = useState<any>();
  
      useEffect(() => {
        axios.get('cashflow/listOfCashFlowCallDateByIsinNumber/{isinNumber}?isinNumber=' + props.isin)
        .then((res:any)=> setCashflow(res.data.cashFlowMaturityCallList))
      }, [])
  
      console.log("cashflow", cashflow)

      const addCashFlow = () => {
        let cfData = {
          "cashFlowId": cashflow && cashflow[0].cashFlowId,
          "isinNumber": props.isin,
          "interestPayment": interestPayment,
          "interestDate": convertDateYMD(redemptionDate),
          "days": days,
          "nonHolidays": convertDateYMD(nonHolidays),
          "redemption": redemption,
          "totalPayout": totalPayout
        }
        axios.post("cashflow/createCashFlowCallDate", cfData).then((res:any)=>res && toast.success("Added Successfully!"))
      }

      function updateCashFlowJson(array:any, idToUpdate:any, newinterestDate:any, issuanceDate:any) {
        const cashFlow:any = array.map((obj:any) => {
          if (obj.cfCallDateId === idToUpdate) {
            return { 
              ...obj, 
              interestDate: convertDateYMD(newinterestDate),
              Updatable:true,
            }; // Update the 'Days' value
          }
          return obj;
        });

        let CashFlowUpdatedData:any = []
      
        cashFlow.forEach((item:any,index:any)=>{
              // let indexValue:any = !!cashFlow[index]?index+1:cashFlow.length-1;
              let indexValue:any = index+1;
              console.log("diffDays0",indexValue)
              console.log("diffDays1",cashFlow[indexValue]?.interestDate)
              let Date1:any = index===0 ? new Date(issuanceDate) : new Date(cashFlow[index-1]?.interestDate);
              let Date2:any = index===0 ? new Date(cashFlow[index]?.interestDate) : new Date(cashFlow[index]?.interestDate);
              console.log("diffDays3",Date2)
              const diffTime:any = Math.abs(Date2)-Math.abs(Date1);
              console.log("diffTime",diffTime)
              const diffDays:any = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              console.log("diffDays4",diffDays)
              console.log("cashflow1",{ 
                ...cashFlow[index], 
                days: diffDays
              })
              CashFlowUpdatedData = [...CashFlowUpdatedData,{ 
                ...cashFlow[index], 
                days: diffDays
              }]
              console.log("CashFlowUpdatedData",CashFlowUpdatedData)
              console.log("cashflow2",cashFlow)
              return { 
                ...cashFlow[index], 
                days: diffDays
              }
        })
        setCashflow(CashFlowUpdatedData)
      }  

  return (
    <Box>
        <TableContainer component={Paper} >
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <>
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{ top: 57, Width: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableCell>
                        </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cashflow && cashflow
                    .map((row: any) => {
                      return (
                        <>
                        <CallCfRows item={row} updateCashFlowJson={updateCashFlowJson} CashFlowData={cashflow}/>
                        </>
                        );
                    })}
                </TableBody>
                <TableBody>
                  <TableRow>
                    <StyledTableCell>
                      {props.isin}
                    </StyledTableCell>
                    <StyledTableCell align={"left"}>
                    <TextField label="Redemption Date" variant="outlined" onChange={(e:any)=>setRedemptionDate(e.target.value)} />
                    </StyledTableCell>
                    <StyledTableCell align={"left"}>
                    <TextField label="Non Holidays date" variant="outlined" onChange={(e:any)=>setNonHolidays(e.target.value)} />
                    </StyledTableCell>
                    <StyledTableCell align={"left"}>
                    <TextField label="Days" variant="outlined" onChange={(e:any) => setDays(e.target.value)} />
                    </StyledTableCell>
                    <StyledTableCell align={"right"}>
                    <TextField label="Interest Payout" variant="outlined" onChange={(e:any) => setInterestPayment(e.target.value)} />
                    </StyledTableCell>
                    <StyledTableCell align={"right"}>
                    <TextField label="Redemption Payout" variant="outlined" onChange={(e:any) => setRedemption(e.target.value)} />
                    </StyledTableCell>
                    <StyledTableCell align={"right"}>
                    <TextField label="Total Payout" variant="outlined" onChange={(e:any) => setTotalPayout(e.target.value)} />
                    </StyledTableCell>
                    <StyledTableCell align={"right"}>
                    <Button variant='contained' onClick={addCashFlow}>
                      Add +
                    </Button>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
      </TableContainer>
      <Box sx={{margin:'5% 0', width:'100%' , textAlign:'center'}}>
        {/* <Button variant='contained'>
          Submit
        </Button> */}
      </Box>
    </Box>
  )
}

export default UpdateCallCashflow