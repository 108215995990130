import { Box, CircularProgress, Container, Stack } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withParam } from "../utils/Router.Helper";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {convertDate} from "../utils/Helper.js"

const ProductScrener = (props: any) => {
  console.log(props.params.productId);
  const [productId, setProductId] = useState<any>([]);
  const [loading, setLoading] = React.useState(true);
  const [edit, setedit] = useState(true);

  const [value, setValue] = React.useState<Dayjs | null>(
    dayjs('2014-08-18T21:11:54'),
  );

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
  };

  useEffect(() => {
    axios
      .get("aif/" + props.params.productId)
      .then((res: any) => {
        setProductId(res.data.aif);
        setValue(res.data.aif[0].closeDate.split("T")[0])
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  const resolver = async (values: any) => {
    return {
      values: !values.productName ? {} : values,
      errors: !values.productName ? {} : {},
    };
  };

  const { register, handleSubmit } = useForm({
    resolver: resolver,
  });

  const onSubmit = (data: any) => {
    console.log("DATA",{...data,productMasterCode:parseInt(props.params.productId)})
    const formData = new FormData();
    formData.append("aifDTOStr",JSON.stringify({...data,productMasterCode:parseInt(props.params.productId),companyMasterCode:productId[0].companyMasterCode,isActive:productId[0].isActive,productTypeCode:productId[0].productTypeCode}))
    axios.put("aif/uploadFile",formData)
  }
  const editDetails = () => {
    setedit(false);
  };

  console.log(convertDate(value))
  return (
    <Container maxWidth={"xl"}>
       <LocalizationProvider dateAdapter={AdapterDayjs}>

      
      <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "10%" }}>
        {loading ? (
          <CircularProgress
            size={68}
            sx={{
              color: "primary",
              position: "absolute",
              top: 300,
              left: 650,
              zIndex: 1,
            }}
          />
        ) : (
          <></>
        )}
        {productId.length !== 0 ? (
          <>
            <Stack direction="column" spacing={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                variant={"outlined"}
                label="Product Name"
                defaultValue={productId[0].productName}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("productName")}
              />
              <TextField
                variant={"outlined"}
                label="Scheme Name"
                defaultValue={productId[0].schemeName}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("schemeName")}
              />
              <DesktopDatePicker
                label="Close Date"
                inputFormat="DD-MM-YYYY"
                value={value}
                onChange={handleChange}
                renderInput={(params:any) => <TextField {...params} />}
              />
              <TextField
                variant={"outlined"}
                label="Sector"
                defaultValue={productId[0].sector}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("sector")}
              />
              <TextField
                variant={"outlined"}
                label="aum"
                defaultValue={productId[0].aum}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("aum")}
              />
              <TextField
                variant={"outlined"}
                label="benchmark"
                defaultValue={productId[0].benchmark}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("benchmark")}
              />
              <TextField
                variant={"outlined"}
                label="brochureUrl"
                defaultValue={productId[0].brochureUrl}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("brochureUrl")}
              />
              <TextField
                variant={"outlined"}
                label="category"
                defaultValue={productId[0].category}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("category")}
              />
              <TextField
                variant={"outlined"}
                label="closeDate"
                defaultValue={productId[0].closeDate}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("closeDate")}
              />
              <TextField
                variant={"outlined"}
                label="description"
                defaultValue={productId[0].description}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("description")}
              />
              <TextField
                variant={"outlined"}
                label="exitLoad"
                defaultValue={productId[0].exitLoad}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("exitLoad")}
              />
              <TextField
                variant={"outlined"}
                label="faq"
                defaultValue={productId[0].faq}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("faq")}
              />
              <TextField
                variant={"outlined"}
                label="formUrl"
                defaultValue={productId[0].formUrl}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("formUrl")}
              />
              <TextField
                variant={"outlined"}
                label="managementFees"
                defaultValue={productId[0].managementFees}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("managementFees")}
              />
              <TextField
                variant={"outlined"}
                label="minInvestment"
                defaultValue={productId[0].minInvestment}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("minInvestment")}
              />
              <TextField
                variant={"outlined"}
                label="note"
                defaultValue={productId[0].note}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("note")}
              />
              <TextField
                variant={"outlined"}
                label="openDate"
                defaultValue={productId[0].openDate}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("openDate")}
              />
              <TextField
                variant={"outlined"}
                label="profitSharing"
                defaultValue={productId[0].profitSharing}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("profitSharing")}
              />
              <TextField
                variant={"outlined"}
                label="risk"
                defaultValue={productId[0].risk}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("risk")}
              />
              <TextField
                variant={"outlined"}
                label="sinceInceptionDate"
                defaultValue={productId[0].sinceInceptionDate}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("sinceInceptionDate")}
              />
              <TextField
                variant={"outlined"}
                label="videoUrl"
                defaultValue={productId[0].videoUrl}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("videoUrl")}
              />
              <TextField
                variant={"outlined"}
                label="One Yr Return"
                defaultValue={productId[0].oneYearReturn}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("oneYearReturn")}
              />
              <TextField
                variant={"outlined"}
                label="Two Yr Return"
                defaultValue={productId[0].secondYearReturn}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("secondYearReturn")}
              />
              <TextField
                variant={"outlined"}
                label="Three Yr Return"
                defaultValue={productId[0].thirdYearReturn}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("thirdYearReturn")}
              />
              <TextField
                variant={"outlined"}
                label="Max Amount"
                defaultValue={productId[0].maxAmount}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("maxAmount")}
              />
              <TextField
                variant={"outlined"}
                label="Min Amount"
                defaultValue={productId[0].minAmount}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("minAmount")}
              />
              <TextField
                variant={"outlined"}
                label="Max Tenure"
                defaultValue={productId[0].maxTenure}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("maxTenure")}
              />
              <TextField
                variant={"outlined"}
                label="Min Tenure"
                defaultValue={productId[0].minTenure}
                InputProps={{ readOnly: edit }}
                sx={{ width: "300px" }}
                {...register("minTenure")}
              />
               <Button type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
               <Button color="primary" variant="contained" onClick={editDetails}>
                      Edit Details
                    </Button>
              </form>
            </Stack>
          </>
        ) : (
          ""
        )}
      </Box>
      </LocalizationProvider>
    </Container>
  );
};

export default withParam(ProductScrener);
