import { Box, Link, Container, Paper, Table, TableContainer,TableHead,TableCell,TableRow,TableBody,Button, Modal, Autocomplete, TextField, CircularProgress, TablePagination, Grid, IconButton } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { Navigate, Route, Router, Routes, useNavigate } from 'react-router-dom'
import { withParam } from '../../utils/Router.Helper'
import { GetFixedRateUnlisted } from '../../api/Api'
import { useForm } from 'react-hook-form'
import { UNLISTED_FIXED_RATE } from '../../utils/API_Names'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import CloseIcon from '@mui/icons-material/Close';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 8,
    borderRadius:5
  };


const UnlistedFixedRate = () => {
  const navigate = useNavigate();
    
    const [open, setOpen] = useState(false);
    const [distNameList , setDistNameList] = useState<any>([]);
    const [distName, setDistName] = useState<any>();
    const [unlistedList, setUnlistedList] = useState<any>([]);
    const [unlistedCode, setUnlistedCode] = useState<any>();
    const [fixedRate, setFixedRate] = useState<any>();
    const [companyList, setcompanyList] = useState([]);
    const [companyCode, setCompanyCode] = useState("");
    const [CompanyWiseProduct, setCompanyWiseProduct] = useState([]);
    const [ProductList, setProductList] = useState([]);
    const [productlist, setproductlist] = useState("");
    const [showCircularProgress, setshowCircularProgress] = useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = useState(0);

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const {data, refetch} = useQuery("UnlistedFixedRate",GetFixedRateUnlisted);

    useEffect(()=>{
        axios.get("userProfile/listOfUserTypeUserProfile/D").then((res:any)=>{
            setDistNameList(res.data.userProfile);
        });
        axios.get("unlistedMaster/listOfAllUnlistedMasterDetails").then((res:any)=>{
            setUnlistedList(res.data.unlistedList);
        });
        axios
        .get("company/listOfCompanies")
        .then((res: any) => setcompanyList(res.data.companies));
        axios
        .get("product/listOfProductType")
        .then((res: any) => setProductList(res.data.productType));
    },[])

    useEffect(() => {
        companyCode !== "" &&
          axios
            .get("sourceDestination/sourceValue?sourceValue=" + companyCode)
            .then((res: any) => setCompanyWiseProduct(res.data.sourceDes)).catch((err:any)=>
            toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title))
      }, [companyCode]);
    
    console.log("distNameList",distNameList);
    console.log("unlisted list", unlistedList);

    distName ? console.log('dist name', distName):<></>
    fixedRate ? console.log('fixedRate', fixedRate):<></>
    unlistedCode ? console.log('unlisted', unlistedCode):<></>

    const resolver = async (values : any) => {
      return {
        values: !values.fixedValue ? {} : values,
        errors: 
        !values.companyMasterCode
        ?{
          companyMasterCode:{
            type:"required",
            message:"This is required."
          }
        }
        :
        !values.fixedValue
        ?{
          fixedValue:{
            type: "required",
            message: "This is required.",
          }
        }
        :
        !values.unlistedMasterCode
        ?{
          unlistedMasterCode:{
            type: "required",
            message: "This is required.",
          }
        }
        :{}
      }
    }


    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
      resolver: resolver,
    });

    const onSubmit = (data:any) =>{
      console.log("Data-",data)
      axios.post("unlistedFixedRate/saveUnlistedFixedRate",data)
      .then(()=>{ handleClose();setshowCircularProgress(false);refetch()})
      .then(()=>toast.success("Fixed Rate Added Successfully!"))
      .catch((err:any)=>{ setshowCircularProgress(false);toast.error(err.response.data?err.response.data.status.message.title:"Error")})
    }

    const obj = {
        "companyMasterCode": companyCode,
        "unlistedMasterCode": unlistedCode,
        "fixedValue": fixedRate
    }

    const postFixedRate = () =>{
      setshowCircularProgress(true)
        axios.post("unlistedFixedRate/saveUnlistedFixedRate",obj)
        .then(()=>{ handleClose();setshowCircularProgress(false)})
        .then(()=>toast.success("Fixed Rate Added Successfully!"))
        .catch((err:any)=>{ setshowCircularProgress(false);toast.error(err.response.data?err.response.data.status.message.title:"Error")})
    }
     const unlistedFixedRateCode = 1
    console.log("unlisted",`${UNLISTED_FIXED_RATE}${unlistedFixedRateCode}`)

    const deleteFixedRate = (unlistedFixedRateCode:any) => {
      axios.delete(`${UNLISTED_FIXED_RATE}${unlistedFixedRateCode}`).then((res:any)=>{ 
        console.log("res",res)
      toast.success(res?.data?.status?.message?.title?res?.data?.status?.message?.title:res?.data?.title);
      refetch();
    }).catch((err:any)=>
      toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title)
      )

    }

  return (
    <Container maxWidth='xl'>
        <Box sx={{ marginTop: "4%" }}>
            <Button onClick={handleOpen} variant='contained' sx={{mb:3}}>Add Fixed Rate</Button>
        <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                    <TableCell sx={{fontSize:'1rem', fontWeight:'600'}} align="center">Company Name</TableCell>
                    <TableCell sx={{fontSize:'1rem', fontWeight:'600'}} align="center">Fixed Value</TableCell>
                    <TableCell sx={{fontSize:'1rem', fontWeight:'600'}} align="center">Unlisted Name</TableCell>
                    <TableCell sx={{fontSize:'1rem', fontWeight:'600'}} align="center">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.data.unlistedFixedDetails
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item:any)=>(
                      <Link key={item.companyMasterCode} 
                      display={"table-row"} 
                      sx={{textDecoration:'none', '&:hover':{backgroundColor:'lightgray'}}} 
                      >
                            <TableCell sx={{padding:'10px', cursor:"pointer", textDecoration:"underline"}} align="center" onClick={()=>navigate(`/fixedRateDetail/${item.unlistedFixedRateCode}`)}>{item.companyName}</TableCell>
                            <TableCell sx={{padding:'10px'}} align="center">{item.fixedValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                            <TableCell sx={{padding:'10px'}} align="center">{item.scriptName}</TableCell>
                            <TableCell sx={{padding:'10px', zIndex:10}} align="center">
                             <Button onClick={()=>deleteFixedRate(item.unlistedFixedRateCode)} > 
                              <DeleteSweepIcon/>
                             </Button> 
                            </TableCell>
                        </Link>
                        
                        
                        
                    ))}
                </TableBody>
                    </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 30, 50]}
          component="div"
          count={data ? data.data.unlistedFixedDetails.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Box>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <IconButton style={{ position: 'absolute',fontSize:"18px" ,top: "8px", right: "5px"}} onClick={() => handleClose()}>
      <CloseIcon style={{fontSize:"18px"}} />
       </IconButton>
          <form>
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            getOptionLabel={(options: any) => options?.companyName}
            options={companyList ? companyList.filter((it:any)=>(it.companyTypeCode==="D")).map((option: any) => option) : []}
            onChange={(options: any, newValue) => {
              setCompanyCode(newValue?.companyMasterCode);
              setValue("companyMasterCode",newValue?.companyMasterCode)     
            }}
            sx={{ width: 300,mb:2 }}
            renderInput={(params) => (
              <TextField {...params} 
                label="User Company"
                error={errors.companyMasterCode?true:false}
                        helperText={
                        errors.companyMasterCode === undefined
                          ? ""
                          : errors.companyMasterCode.message + ""
                        }
                 />
            )}
        />

        

        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={unlistedList ? unlistedList.map((option:any)=> option) : []}
            getOptionLabel={(options:any)=>options.scriptName}
            onChange={(options: any, newValue) => {
                setUnlistedCode(newValue?.unlistedMasterCode);
                setValue("unlistedMasterCode", newValue?.unlistedMasterCode);
              }}
            sx={{ width: 300, mb:2 }}
            renderInput={(params) => 
            <TextField {...params} 
              label="Select Unlisted"
              error={errors.unlistedMasterCode?true:false}
                        helperText={
                        errors.unlistedMasterCode === undefined
                          ? ""
                          : errors.unlistedMasterCode.message + ""
                        }
            />}
        />
        <TextField 
        sx={{ width: 300, mb:2 }}
        onChangeCapture={(newValue:any)=>{
            setFixedRate(newValue.target.value)
        }}  
        id="outlined-basic" 
        label="Fixed Rate" 
        variant="outlined"
        error={errors.fixedValue?true:false}
                        helperText={
                        errors.fixedValue === undefined
                          ? ""
                          : errors.fixedValue.message + ""
                        } 
        {...register("fixedValue")}
        />

        <Grid item xs={12} sm={12} md={4} lg={3} ml={1}>
          <Button variant='contained' type='submit' onClick={handleSubmit(onSubmit)} disabled={showCircularProgress} > {showCircularProgress === true ? (
            <CircularProgress
            color="secondary"
            size={20}
            thickness={5}
            />
            ) : (
              ""
              )}{" "} Submit</Button>
          </Grid>
          </form>
        </Box>
      </Modal>
    </Container>
  )
}

export default withParam(UnlistedFixedRate)