import { ThemeProvider } from '@emotion/react'
import { Box, Container, Drawer, Hidden, Icon, IconButton, List, ListItem, ListItemButton, ListItemText, createTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import PortfolioAllocation from './AnalyzerAnalysis/PortfolioAllocation'
import DetailedPortfolio from './AnalyzerAnalysis/DetailedPortfolio'
import PortfolioDiversify from './AnalyzerAnalysis/PortfolioDiversify'
import PortFolioProfile from './AnalyzerAnalysis/PortFolioProfile'
const defaultTheme = createTheme()



const AnalyzerAnalysis = () => {

  
  const drawerItemsByUserType = [
    {
      title: 'Allocation'
    },
    {
      title: 'Detailed Portfolio'
    },
    {
      title: 'Equity Sector Diversity'
    },
    {
      title: 'PortfolioProfile'
    }
  ]
  const [selectedDrawerItem, setSelectedDrawerItem] = useState(drawerItemsByUserType[0])
  const [mobileOpen, setMobileOpen] = useState(false)
 

  const drawerWidth = 250
  useEffect(() => {
    console.log('Selected item:', selectedDrawerItem)
  }, [selectedDrawerItem])

  const handleDrawerItemClick = (item:any) => {
    console.log('Clicked item:', item)
    setSelectedDrawerItem(item)
    if (mobileOpen) {
      setMobileOpen(false)
    }
  }
 
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const renderSelectedContent = () => {
    switch (selectedDrawerItem.title) {
      case 'Allocation':
        return <PortfolioAllocation></PortfolioAllocation>
      case 'Detailed Portfolio':
        return <DetailedPortfolio></DetailedPortfolio>
      case 'Equity Sector Diversity':
        return <PortfolioDiversify></PortfolioDiversify>
      case 'PortfolioProfile':
        return <PortFolioProfile></PortFolioProfile>
      default:
        return null
    }
  }
  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
            <Container maxWidth='lg' sx={{ display: 'flex', height: '100vh'}}>
                <Hidden mdDown>
                    <Drawer
                        anchor='left'
                        variant='permanent'
                        open
                        sx={{

                          width: drawerWidth,
                          '& .MuiDrawer-paper': { width: drawerWidth, position: 'relative', overflowY: 'auto', border: 'none',background:"none" }
                        }}
                    >
                        <div  style={{position:"sticky",top:"116px"}}>
                            <List sx={{ backgroundColor: '#d7ebfd', borderRadius: '12px', border: '1px solid black'}} >
                                {drawerItemsByUserType.map((data, index) => (
                                    <ListItem key={data.title} disablePadding
                                        sx={{
                                          backgroundColor: data.title === selectedDrawerItem?.title ? '#032b5b' : 'transparent',
                                          color: data.title === selectedDrawerItem?.title ? '#fff' : '#000'

                                        }}
                                    >
                                        <ListItemButton
                                            onClick={() => handleDrawerItemClick(data)}
                                            selected={data.title === selectedDrawerItem?.title}
                                            sx={{
                                              backgroundColor: data.title === selectedDrawerItem?.title ? '#032b5b' : 'transparent',
                                              color: data.title === selectedDrawerItem?.title ? '#fff' : '#000',
                                              display: 'flex',
                                              justifyContent: 'space-between', // Align the title and icon to the center
                                              alignItems: 'center' // Center the icon vertically
                                            }}
                                        >
                                            <ListItemText primary={data.title} />
                                            {data.title === selectedDrawerItem?.title && (
                                                <Icon>
                                                    <ChevronRightIcon />
                                                </Icon>
                                            )}
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Drawer>
                </Hidden>

                <Hidden xsDown mdUp implementation="css">

                    {/* Show menu icon button in mobile mode */}
                    <IconButton
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        onClick={handleDrawerToggle}
                        sx={{ zIndex: 1 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        anchor='left'
                        variant='temporary'
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                          keepMounted: true
                        }}
                        sx={{
                          width: drawerWidth,
                          '& .MuiDrawer-paper': { width: drawerWidth, position: 'relative', overflowY: 'auto', border: 'none', height: '13.08em', borderRadius: '15px' }
                        }}
                    >
                        <div>
                            <List sx={{ backgroundColor: '#d7ebfd', borderRadius: '12px', border: '1px solid black' }} >
                                {drawerItemsByUserType.map((data, index) => (
                                    <ListItem key={data.title} disablePadding
                                        sx={{
                                          backgroundColor: data.title === selectedDrawerItem?.title ? '#032b5b' : 'transparent',
                                          color: data.title === selectedDrawerItem?.title ? '#fff' : '#000'

                                        }}
                                    >
                                        <ListItemButton
                                            onClick={() => handleDrawerItemClick(data)}
                                            selected={data.title === selectedDrawerItem?.title}
                                            sx={{
                                              backgroundColor: data.title === selectedDrawerItem?.title ? '#032b5b' : 'transparent',
                                              color: data.title === selectedDrawerItem?.title ? '#fff' : '#000',
                                              display: 'flex',
                                              justifyContent: 'space-between', // Align the title and icon to the center
                                              alignItems: 'center' // Center the icon vertically
                                            }}
                                        >
                                            <ListItemText primary={data.title} />
                                            {data.title === selectedDrawerItem?.title && (
                                                <Icon>
                                                    <ChevronRightIcon />
                                                </Icon>
                                            )}
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Drawer>
                </Hidden>

                <Box sx={{ flex: 1, p: 2, pt: 0, overflowY: 'auto' }}>{renderSelectedContent()}</Box>
            </Container>
        </ThemeProvider>
    </div>
  )
}

export default AnalyzerAnalysis