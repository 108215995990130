import { ThemeProvider } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { getModelPortFolio } from '../../api/Api'
import { Box, CircularProgress, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography, createTheme } from '@mui/material'
import { StyledTableCell } from '../../Constants/TableStyle'
const defaultTheme = createTheme()

const AnalyzerSuggested = () => {
  const [modalData, setModalData] = useState<any>([])
  const [showCircularProgress, setshowCircularProgress] = useState(false);

    
  useEffect(() => {
    setshowCircularProgress(true)
    getModelPortFolio().then((resp) => setModalData(resp.data)).then(()=>{
        setshowCircularProgress(false)  
    })
  }, [])
  return (
    <div>
 {showCircularProgress==true?(
                <CircularProgress
                      color="secondary"
                      size={50}
                      thickness={5}
                    />
 ):
 (
<ThemeProvider theme={defaultTheme}>
<Box sx={{ borderBottom: '2px solid #a1a1a1', padding: '1em' }}>
    <Box sx={{ textAlign: 'start', mb: 1 }} className='Aggressive-modal-main-div'>
        <span className='Aggressive-modal-p'>Aggressive</span>
    </Box>
    <Box className='modal-portfolio-table-added' id="">
        <Paper elevation={4}>
            <TableContainer>
                <Table sx={{ minWidth: 500, border: '2px solid #000' }} aria-label='simple table' id="">
                    <TableHead className="" style={{ background: '#bde7ff', textAlign: 'center' }}>
                        <TableRow className="main-tr-table-portfolio-report">
                            <StyledTableCell ><Typography sx={{ fontSize: '1.3em' }}>Scheme Name</Typography></StyledTableCell>
                            <StyledTableCell><Typography sx={{ fontSize: '1.3em' }}>1YR</Typography></StyledTableCell>
                            <StyledTableCell><Typography sx={{ fontSize: '1.3em' }}>3YR</Typography></StyledTableCell>
                            <StyledTableCell><Typography sx={{ fontSize: '1.3em' }}>5YR</Typography></StyledTableCell>
                            <StyledTableCell><Typography sx={{ fontSize: '1.3em' }}>10YR</Typography></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className='transaction-report-bg-podt-mf-dashboar'>
                        {modalData.aggressiveFolioList?.map((item:any) => (
                            <TableRow key={item.bseSchemeCode} className="main-tr-table-portfolio-report-data">
                                <StyledTableCell >{(item.schemeName).toUpperCase()}</StyledTableCell>
                                <StyledTableCell >{item.oneYrRetrun == null ? '-' : item.oneYrRetrun}</StyledTableCell>
                                    <StyledTableCell >{item.threeYrRetrun == null ? '-' : item.threeYrRetrun}</StyledTableCell>
                                    <StyledTableCell >{item.fiveYrRetrun == null ? '-' : item.fiveYrRetrun}</StyledTableCell>
                                    <StyledTableCell >{item.tenYrRetrun == null ? '-' : item.tenYrRetrun.toFixed(2)}</StyledTableCell>
                            </TableRow>

                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    </Box>
</Box>
<Box>

    <Box sx={{ borderBottom: '2px solid #a1a1a1', padding: '1em' }}>
        <Box sx={{ textAlign: 'start', mb: 1 }} className='Aggressive-modal-main-div'>
            <span className='Aggressive-modal-p'>Moderate</span>
        </Box>
        <Box className='modal-portfolio-table-added' id="">
            <Paper elevation={4}>
                <TableContainer>
                    <Table sx={{ minWidth: 500, border: '2px solid #000' }} aria-label='simple table' id="">
                        <TableHead className="" style={{ background: '#bde7ff', textAlign: 'center' }}>
                            <TableRow className="main-tr-table-portfolio-report">
                                <StyledTableCell ><Typography sx={{ fontSize: '1.3em' }}>Scheme Name</Typography></StyledTableCell>
                                <StyledTableCell><Typography sx={{ fontSize: '1.3em' }}>1YR</Typography></StyledTableCell>
                                <StyledTableCell><Typography sx={{ fontSize: '1.3em' }}>3YR</Typography></StyledTableCell>
                                <StyledTableCell><Typography sx={{ fontSize: '1.3em' }}>5YR</Typography></StyledTableCell>
                                <StyledTableCell><Typography sx={{ fontSize: '1.3em' }}>10YR</Typography></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='transaction-report-bg-podt-mf-dashboar'>
                            {modalData.moderateFolioList?.map((item:any) => (
                                <TableRow key={item.bseSchemeCode} className="main-tr-table-portfolio-report-data">
                                    <StyledTableCell >{(item.schemeName).toUpperCase()}</StyledTableCell>
                                    <StyledTableCell >{item.oneYrRetrun == null ? '-' : item.oneYrRetrun}</StyledTableCell>
                                    <StyledTableCell >{item.threeYrRetrun == null ? '-' : item.threeYrRetrun}</StyledTableCell>
                                    <StyledTableCell >{item.fiveYrRetrun == null ? '-' : item.fiveYrRetrun}</StyledTableCell>
                                    <StyledTableCell >{item.tenYrRetrun == null ? '-' : item.tenYrRetrun.toFixed(2)}</StyledTableCell>
                                </TableRow>

                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    </Box>
</Box>
<Box sx={{ padding: '1em' }}>
    <Box>
        <Box sx={{ textAlign: 'start', mb: 1 }} className='Aggressive-modal-main-div'>
            <span className='Aggressive-modal-p'>Conservative</span>
        </Box>
        <Box className='modal-portfolio-table-added' id="">
            <Paper elevation={4}>
                <TableContainer>
                    <Table sx={{ minWidth: 500, border: '2px solid #000' }} aria-label='simple table' id="">
                        <TableHead className="" style={{ background: '#bde7ff', textAlign: 'center' }}>
                            <TableRow className="main-tr-table-portfolio-report">
                                <StyledTableCell ><Typography sx={{ fontSize: '1.3em' }}>Scheme Name</Typography></StyledTableCell>
                                <StyledTableCell><Typography sx={{ fontSize: '1.3em' }}>1YR</Typography></StyledTableCell>
                                <StyledTableCell><Typography sx={{ fontSize: '1.3em' }}>3YR</Typography></StyledTableCell>
                                <StyledTableCell><Typography sx={{ fontSize: '1.3em' }}>5YR</Typography></StyledTableCell>
                                <StyledTableCell><Typography sx={{ fontSize: '1.3em' }}>10YR</Typography></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='transaction-report-bg-podt-mf-dashboar'>
                            {modalData.conservativeFolioList?.map((item:any) => (
                                <TableRow key={item.bseSchemeCode} className="main-tr-table-portfolio-report-data">
                                    <StyledTableCell >{(item.schemeName).toUpperCase()}</StyledTableCell>
                                    <StyledTableCell >{item.oneYrRetrun == null ? '-' : item.oneYrRetrun}</StyledTableCell>
                                    <StyledTableCell >{item.threeYrRetrun == null ? '-' : item.threeYrRetrun}</StyledTableCell>
                                    <StyledTableCell >{item.fiveYrRetrun == null ? '-' : item.fiveYrRetrun}</StyledTableCell>
                                    <StyledTableCell >{item.tenYrRetrun == null ? '-' : item.tenYrRetrun.toFixed(2)}</StyledTableCell>
                                </TableRow>

                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    </Box>
</Box>

</ThemeProvider>
 )}
        
       
    </div>
  )
}

export default AnalyzerSuggested