import { Box, CircularProgress, Switch, TableCell } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { ACTIVE_INACTIVE_AIF, ACTIVE_INACTIVE_PMS, AMC_ACTIVE_INACTIVE } from '../../utils/API_Names';

export const ManageSchemeTableSwitch = (props:any) => {
	const { productMasterCode, isActive, productType } = props

	console.log("productType",productType, props)

	const [activeCircularProgress, SetActiveCircularProgress] = useState(false);
	const [checked, setChecked] = React.useState(isActive);

	React.useEffect(() => {
		setChecked(isActive)
	}, [props,isActive])
	

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		SetActiveCircularProgress(true)

		productType==="AIF"?
		axios.put(ACTIVE_INACTIVE_AIF + productMasterCode).then(()=>{
			SetActiveCircularProgress(false);
			setChecked(!event.target.checked);
			props.setRefresh(!props.refresh)
		}).catch((err:any)=>{
			SetActiveCircularProgress(false);
			toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title)	
		}) 
		: productType==="PMS" ? 
		axios.put(ACTIVE_INACTIVE_PMS + productMasterCode).then(()=>{
			SetActiveCircularProgress(false);
			setChecked(!event.target.checked);
			props.setRefresh(!props.refresh)
		}).catch((err:any)=>{
			SetActiveCircularProgress(false);
			toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title)	
		}) 
		: <></> 
	  };	
  return (
	<>
		<Switch
			checked={checked}
			disabled={activeCircularProgress}
			onChange={handleChange}
			inputProps={{ 'aria-label': 'controlled' }} />
                <Box sx={{ pt:1 }}> {activeCircularProgress ? <CircularProgress size={20} /> : <></>}</Box>
	</>
  )
}

