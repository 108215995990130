import React, { useEffect } from 'react'
import { Box, Button, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
import { withState } from '../../../utils/Router.Helper';

const CompareAIFFund = () => {
	const CompareAIFFundData = useSelector((state:any)=> state.ChangeCompareAifFund)
	const dispatch = useDispatch();
	const navigate = useNavigate();

  return (
	<Box sx={{mt:2,
		// position:'sticky',top:'465px'
		}}>
		<Paper elevation={2} sx={{p:2}}>
			<Typography variant='h4' gutterBottom>Compare Fund</Typography>
			{CompareAIFFundData.map((aifSchemeList:any)=>(
				<>
					<Typography variant='subtitle2' gutterBottom sx={{display:"flex", justifyContent:"space-between"}} >
						{aifSchemeList.schemeName} 
						<Tooltip title="Remove">
						  <IconButton>
							<RemoveIcon onClick={()=>dispatch({ type: 'RemoveCompareAifFund',payload: aifSchemeList })}/>
						  </IconButton>
						</Tooltip>
					</Typography>
				</>
			))}
			<Button variant='contained' onClick={()=>navigate("/CompareFund",{state:CompareAIFFundData})} disabled={CompareAIFFundData.length >= 2 ? false : true}> Compare Fund</Button>
		</Paper>
	</Box>
  )
}

export default withState(CompareAIFFund)