/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import { useQuery } from "react-query";
import {
  getProductTotalAmo,
  listOfManufactureNDistributorCompanies,
  selfProfile,
} from "../../api/Api";
import { useDispatch, useSelector } from "react-redux";
import { Dayjs } from "dayjs";
import axios from "axios";
import TotalClientsForEmployee from "./TotalClientsForEmployee";
import TransactionValueForEmployee from "./TransactionValueForEmployee";
import RevenueForEmployee from "./RevenueForEmployee";
import BusinessPartnerForEmployee from "./BusinessPartnerForEmployee";
import {
  getFiscalDate,
  getLastFinancialDate,
  getQuarterDates,
} from "../../utils/Helper";
import ReactApexChart from "react-apexcharts";
import { Pie } from "react-chartjs-2";
import { Link } from "react-router-dom";

export const InformationGridForEmployee = () => {
  const state = useSelector((state: any) => state);
  const ClientData = useSelector((state: any) => state.ClientCount);
  const ClientCounts = useSelector((state: any) => state.TotalCountOfClient);
  const MonthlyCountOfClient = useSelector(
    (state: any) => state.MonthlyCountOfClient
  );
  const quarterWiseData = useSelector(
    (state: any) => state.quarterWiseClientData
  );
  const MonthlywiseCount = useSelector((state: any) => state.currMonthWiseData);
  const yearlyDatacount = useSelector((state: any) => state.yearlyDatacount);
  const prevQuarterDataa = useSelector((state: any) => state.prevQuarterData);
  const ytdDataClients = useSelector((state: any) => state.ytdDataClient);

  const [revenueCount, setRevenueCount] = React.useState<any>([]);
  const [transactionValue, setTransactionValue] = React.useState<any>();
  const [quarterTranValue, setQuarterTranValue] = React.useState<any>();
  const [monthWiseData, setMonthWiseData] = React.useState<any>();
  const [prevQuarterTranValue, setPrevQuarterTranValue] = useState(0);
  const [revenueValue, setRevenueValue] = React.useState<any>();
  const [quarterRevenueValue, setQuarterRevenueValue] = React.useState<any>();
  const [monthRevenue, setMonthRevenue] = React.useState<any>();
  const [prevQuarterRevenueValue, setPrevQuarterRevenueValue] = useState(0);
  const [ChartTotalAmount, productTotalAmount] = React.useState<any>();

  // const { data } = useQuery("UserProfile", selfProfile);
  const productListAPI = async () => {
    const data: any = await axios.get(
      `payout/payoutFilter?fromDate=${getFiscalDate()}`
    );
    return data;
  };

  const { data: getUserTypeDetails, refetch } = useQuery(
    "getUserTypeDetail",
    productListAPI,
    {
      onSuccess(data) {
        const createdAtCount: any = {};

        data.data.payoutDTOList.forEach((item: any) => {
          const createdAt = item?.totalTranValue;
          if (createdAt) {
            if (createdAtCount[createdAt]) {
              createdAtCount[createdAt]++;
            } else {
              createdAtCount[createdAt] = 1;
            }
          }
        });
        setRevenueCount(
          Object.entries(createdAtCount).map(([createdAt, count]) => ({
            createdAt,
            count,
          }))
        );
      },
      staleTime: Infinity,
    }
  );

  const { data: ListOfCompany } = useQuery(
    "ListOfManuAndDistList",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      staleTime: Infinity,
    }
  );

  React.useEffect(() => {
    getProductTotalAmo()
      .then((response: any) => {
        productTotalAmount(response.data);
      })
      .catch((error) => {});
  }, []);

  function getPreviousQuarterDates() {
    const currentDate = new Date();
    const currentQuarter = Math.ceil((currentDate.getMonth() + 1) / 3);

    const startMonth = (currentQuarter - 2) * 3;
    const startDate = new Date(currentDate.getFullYear(), startMonth, 1);

    const endMonth = (currentQuarter - 1) * 3 - 1;
    const lastDay = new Date(
      currentDate.getFullYear(),
      endMonth + 1,
      0
    ).getDate();
    const endDate = new Date(currentDate.getFullYear(), endMonth, lastDay);

    return {
      quarterStartDate: startDate.toISOString().split("T")[0],
      quarterEndDate: endDate.toISOString().split("T")[0],
    };
  }
  React.useEffect(() => {
    axios
      .get(
        `trade/getDateFilterTrade?fromDate=${getFiscalDate()}&toDate=${getLastFinancialDate()}`
      )
      .then((res: any) => {
        setTransactionValue(
          res.data.tradeList
            .map((item: any) => item.totalTransactionValue)
            .reduce((total: any, amount: any) => total + amount, 0)
        );
        let quarterWiseData: any = {};
        let currMonthWiseData: any = {};
        let prevQuarterWiseData: any = {};

        res.data.tradeList.forEach((item: any, index: any) => {
          const transactionDate = item?.transactionDate; // Use 'transactionDate' instead of 'updatedAt'

          if (transactionDate) {
            const startDateStr = new Date(getQuarterDates()?.quarterStartDate);
            const endDateStr = new Date(getQuarterDates()?.quarterEndDate);
            const dateToCheck = new Date(transactionDate);

            if (dateToCheck >= startDateStr && dateToCheck <= endDateStr) {
              quarterWiseData[index] = item;
              // console.log('transactionDate is between startDate and endDate (Current Quarter)');
            } else {
              // console.log('transactionDate is not between startDate and endDate (Current Quarter)');
            }

            const currentMonth = new Date().getMonth() + 1;
            const transactionMonth = dateToCheck.getMonth() + 1;

            if (currentMonth === transactionMonth) {
              currMonthWiseData[index] = item;
            }

            // Get the previous quarter's data
            const prevQuarterStartDate = new Date(
              getPreviousQuarterDates()?.quarterStartDate
            );
            const prevQuarterEndDate = new Date(
              getPreviousQuarterDates()?.quarterEndDate
            );

            if (
              dateToCheck >= prevQuarterStartDate &&
              dateToCheck <= prevQuarterEndDate
            ) {
              prevQuarterWiseData[index] = item;
            }
          }
        });

        setQuarterTranValue(
          Object.values(quarterWiseData)
            .map((item: any) => item.totalTransactionValue)
            .reduce((total: any, amount: any) => total + amount, 0)
        );

        // Check if there is data for the current month before setting the state
        if (Object.keys(currMonthWiseData).length > 0) {
          setMonthWiseData(
            Object.values(currMonthWiseData)
              .map((item: any) => item.totalTransactionValue)
              .reduce((total: any, amount: any) => total + amount, 0)
          );
        } else {
          setMonthWiseData(0); // Set to 0 if there is no data for the current month
        }

        // Set the state for the previous quarter
        setPrevQuarterTranValue(
          Object.values(prevQuarterWiseData)
            .map((item: any) => item.totalTransactionValue)
            .reduce((total: any, amount: any) => total + amount, 0)
        );
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `payout/payoutFilter?fromDate=${getFiscalDate()}&toDate=${getLastFinancialDate()}`
      )
      .then((res: any) => {
        let quarterData: any = {};
        let currMonthData: any = {};
        let prevQuarterData: any = {};

        res.data.payoutDTOList.forEach((item: any, index: any) => {
          const createdAt = item?.createdAt?.split("T")[0];
          if (createdAt) {
            const startDateStr = new Date(getQuarterDates()?.quarterStartDate);
            const endDateStr = new Date(getQuarterDates()?.quarterEndDate);
            const dateToCheck = new Date(createdAt);
            if (dateToCheck >= startDateStr && dateToCheck <= endDateStr) {
              quarterData[index] = item;
            }
            const currentMonth = new Date().getMonth() + 1;
            if (currentMonth === dateToCheck.getMonth() + 1) {
              currMonthData[index] = item;
            }
            const prevQuarterStartDate = new Date(
              getPreviousQuarterDates()?.quarterStartDate
            );
            const prevQuarterEndDate = new Date(
              getPreviousQuarterDates()?.quarterEndDate
            );
            if (
              dateToCheck >= prevQuarterStartDate &&
              dateToCheck <= prevQuarterEndDate
            ) {
              prevQuarterData[index] = item;
            }
          }
        });
        // setRevenueValue(
        //   res.data.payoutDTOList
        //     .map((item: any) => item.totalPayout)
        //     .reduce((total: any, amount: any) => total + amount, 0)
        // );
        // instead of taking item.totalPayout, take summation of item.brokerPayout and item.revenueCredit (for REVENUE WIDGET)
        setRevenueValue(
          res.data.payoutDTOList
            .map((item: any) => item.brokerPayout + item.revenueCredit)
            .reduce((total: any, amount: any) => total + amount, 0)
        );
        setQuarterRevenueValue(
          Object.entries(quarterData)
            .map((item: any) => item[1])
            .map((item: any) => item.brokerPayout + item.revenueCredit)
            .reduce((total: any, amount: any) => total + amount, 0)
        );
        setMonthRevenue(
          Object.entries(currMonthData)
            .map((item: any) => item[1])
            .map((item: any) => item.brokerPayout + item.revenueCredit)
            .reduce((total: any, amount: any) => total + amount, 0)
        );
        setPrevQuarterRevenueValue(
          Object.entries(prevQuarterData)
            .map((item: any) => item[1])
            .map((item: any) => item.brokerPayout + item.revenueCredit)
            .reduce((total: any, amount: any) => total + amount, 0)
        );
      });
  }, []);

  let quarterWiseDataBuss: any = {};
  let currMonthWiseDataBuss: any = [];
  let yearlyDataBuss: any = [];
  let prevQuarterDataBuss: any = [];
  let ytdDataBuss: any = {};

  ListOfCompany &&
    ListOfCompany.data.companies.forEach((item: any, index: any) => {
      const tradeDate = item?.createdAt?.split("T")[0];

      if (tradeDate) {
        const startDateStr = new Date(getQuarterDates()?.quarterStartDate);
        const endDateStr = new Date(getQuarterDates()?.quarterEndDate);
        const prevQuarterStartDate = new Date(
          getPreviousQuarterDates()?.quarterStartDate
        );
        const prevQuarterEndDate = new Date(
          getPreviousQuarterDates()?.quarterEndDate
        );

        // Define the start and end dates for the financial year dynamically
        const currentYear = new Date().getFullYear();
        const financialYearStartDate = new Date(currentYear, 3 - 1, 1); // April 1
        const financialYearEndDate = new Date(currentYear + 1, 3 - 1, 31); // March 31

        const dateToCheck = new Date(tradeDate);

        // Check if the date is within the current quarter
        if (dateToCheck >= startDateStr && dateToCheck <= endDateStr) {
          quarterWiseDataBuss[index] = item;
          // console.log('dateToCheck is between startDate and endDate (Quarter)');
        } else {
          // console.log('dateToCheck is not between startDate and endDate (Quarter)');
        }

        // Check if the date is in the current month
        const currMonth = new Date().getMonth() + 1;
        if (currMonth === dateToCheck.getMonth() + 1) {
          currMonthWiseDataBuss.push(item);
        }

        // Check if the date is within the current year
        if (dateToCheck.getFullYear() === currentYear) {
          yearlyDataBuss[index] = item;
        }

        // Check if the date is within the previous quarter
        if (
          dateToCheck >= prevQuarterStartDate &&
          dateToCheck <= prevQuarterEndDate
        ) {
          prevQuarterDataBuss[index] = item;
          // console.log('dateToCheck is between prevQuarterStartDate and prevQuarterEndDate (Previous Quarter)');
        } else {
          // console.log('dateToCheck is not between prevQuarterStartDate and prevQuarterEndDate (Previous Quarter)');
        }

        // Check if the date is within the financial year (YTD)
        if (
          dateToCheck >= financialYearStartDate &&
          dateToCheck <= financialYearEndDate
        ) {
          ytdDataBuss[index] = item;
          // console.log('dateToCheck is in the financial year (YTD)');
        } else {
          ytdDataBuss[index] = 0; // Set to 0 if no response is obtained
          // console.log('dateToCheck is not in the financial year (YTD)');
        }
      }
    });

  //START OF CLIENT COUNT
  const arrayClientData = Object.entries(MonthlyCountOfClient).map(
    ([createdAt, count]) => ({ createdAt, count })
  );
  // Filter the items with count greater than 0
  const filteredArray = arrayClientData.filter((item: any) => item.count > 0);
  // Get the length of filteredArray
  const MonthlyCountOfClientNo = filteredArray.length;

  const arrayClientmonthly = Object.entries(quarterWiseData).map(
    ([createdAt, count]) => ({ createdAt, count })
  );
  const filteredArrayy = arrayClientmonthly.filter(
    (item: any) => item.count > 0
  );
  const quarterlyCountClientNo = filteredArrayy.length;

  //END OF CLIENT COUNT
  function getCurrentDates() {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const formattedDate = `${monthNames[currentMonth]}'${currentDay}`;
    const currentQuarterStartMonth = Math.floor(currentMonth / 3) * 3;
    const currentQuarterEndMonth = currentQuarterStartMonth + 2;
    // Calculate the previous year if the current month is in the first quarter (Jan-Mar)
    const previousYear =
      currentQuarterStartMonth === 0 ? currentYear - 1 : currentYear;
    const formattedYear = previousYear.toString().slice(-2);
    const formattedQuarter = `${monthNames[currentQuarterStartMonth]}-${monthNames[currentQuarterEndMonth]}'${formattedYear}`;
    const formattedYearStartDate = `${monthNames[0]}'1`;
    const formattedYearEndDate = `${monthNames[11]}'31`;

    // Calculate previous quarter start and end months
    let prevQuarterStartMonth = currentQuarterStartMonth - 3;
    let prevQuarterEndMonth = currentQuarterEndMonth - 3;

    if (prevQuarterStartMonth < 0) {
      prevQuarterStartMonth += 12;
      prevQuarterEndMonth += 12;
    }

    const formattedPrevQuarterStart = `${monthNames[prevQuarterStartMonth]}'1`;
    const formattedPrevQuarterEnd = `${monthNames[prevQuarterEndMonth]}'31`;

    const formattedYearStartDateYTD = `${currentYear}, 4 - 1, 1`; // April 1
    const formattedYearEndDateYTD = `${currentYear + 1}, 3 - 1, 31`; // March 31
    const ytdDATE = formattedYearStartDateYTD + formattedYearEndDateYTD;

    return {
      currentDate: formattedDate,
      currentMonth: formattedDate,
      currentQuarter: formattedQuarter,
      currentYearStartDate: formattedYearStartDate,
      currentYearEndDate: formattedYearEndDate,
      prevQuarterStart: formattedPrevQuarterStart,
      prevQuarterEnd: formattedPrevQuarterEnd,
    };
  }
  const dates = getCurrentDates();
  return (
    <Grid container spacing={2} mt={"5px"}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Link to="/clients">
          <TotalClientsForEmployee
            title="Total Clients"
            Count={ClientCounts ? ClientCounts : 0}
            monthoscunt={Object.entries(MonthlywiseCount).length || 0}
            quarterlyCount={Object.entries(quarterWiseData)?.length || 0}
            TotalYearCount={Object.entries(yearlyDatacount).length || 0}
            prevQuarterValue={Object.entries(prevQuarterDataa).length || 0}
            YtdTotalClient={Object.entries(ytdDataClients).length || 0}
            prevQuarterStartDate={dates.prevQuarterStart}
            prevQuarterEndDate={dates.prevQuarterEnd}
            currentDate={dates.currentDate}
            monthly={dates.currentMonth}
            quarterly={dates.currentQuarter}
            yearly={dates.currentYearStartDate}
          />
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Link
          to={
            sessionStorage.getItem("Profile") === "B"
              ? "/CompanyManagement"
              : "/PartnerManagement"
          }
        >
          <BusinessPartnerForEmployee
            title="Business Partners"
            Count={
              ListOfCompany && ListOfCompany.data.companies?.length
                ? ListOfCompany.data.companies?.length
                : 0
            }
            monthlyCount={currMonthWiseDataBuss.length}
            quartlycount={Object.entries(quarterWiseDataBuss).length || 0}
            yearlyCount={Object.entries(yearlyDataBuss).length || 0}
            prevQuarterValue={Object.entries(prevQuarterDataBuss).length || 0}
            prevQuarterStartDate={dates.prevQuarterStart}
            prevQuarterEndDate={dates.prevQuarterEnd}
            ytdCount={Object.entries(ytdDataBuss).length || 0}
            currentDate={dates.currentDate}
            monthly={dates.currentMonth}
            quarterly={dates.currentQuarter}
            yearly={dates.currentYearStartDate}
          />
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TransactionValueForEmployee
          title="Transaction Value"
          yearlyCount={transactionValue ? transactionValue : 0}
          quarterlyCount={quarterTranValue ? quarterTranValue : 0}
          monthlyCount={monthWiseData ? monthWiseData : 0}
          prevQuarterValue={prevQuarterTranValue ? prevQuarterTranValue : 0}
          prevQuarterStartDate={dates.prevQuarterStart}
          prevQuarterEndDate={dates.prevQuarterEnd}
          currentDate={dates.currentDate}
          monthly={dates.currentMonth}
          quarterly={dates.currentQuarter}
          yearly={dates.currentYearStartDate}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Link
          to={
            sessionStorage.getItem("Profile") === "B"
              ? "/backOffpayoutDashboard"
              : "/revenueCreditDashboard"
          }
        >
          <RevenueForEmployee
            title="Revenue"
            revenueYearlyCount={revenueValue ? revenueValue : 0}
            revenueQuarterlyCount={
              quarterRevenueValue ? quarterRevenueValue : 0
            }
            revenueMonthlyCount={monthRevenue ? monthRevenue : 0}
            prevQuarterValueRevenue={prevQuarterRevenueValue}
            prevQuarterStartDate={dates.prevQuarterStart}
            prevQuarterEndDate={dates.prevQuarterEnd}
            currentDate={dates.currentDate}
            monthly={dates.currentMonth}
            quarterly={dates.currentQuarter}
            yearly={dates.currentYearStartDate}
          />
        </Link>
      </Grid>
    </Grid>
  );
};
