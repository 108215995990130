import React from "react";
import Slider from "react-slick";
import "../css/partners.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PartnersSlider = () => {
  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    slidesPerRow: 1,
  };
  return (
    <>
      <div className="partner-main">
        <div className="partner-header">
          <span>Our Manufacturing Partners</span>
        </div>

        <div className="slick-cont2">
          <Slider {...settings} className="slider_container">
            <div>
              <img
                className="silder-img"
                src={require("../images/AbakkusAsset.png")}
                alt="Credit to Joshua Earle on Unsplash"
              />
            </div>
            <div>
              <img
                className="silder-img"
                src={require("../images/ambit.jpg")}
                alt="Credit to Igor Ovsyannykov on Unsplash"
              />
            </div>
            <div>
              <img
                className="silder-img"
                src={require("../images/iciciPrudential.png")}
                alt="Credit to Pierre Châtel-Innocenti on Unsplash"
              />
            </div>
            <div>
              <img
                className="silder-img"
                src={require("../images/IIFLWealth.png")}
                alt="Credit to Pierre Châtel-Innocenti on Unsplash"
              />
            </div>
            <div>
              <img
                className="silder-img"
                src={require("../images/InvescoLogo.png")}
                alt="Credit to Pierre Châtel-Innocenti on Unsplash"
              />
            </div>
            <div>
              <img
                className="silder-img"
                src={require("../images/motilal-oswal.png")}
                alt="Credit to Pierre Châtel-Innocenti on Unsplash"
              />
            </div>
            <div>
              <img
                className="silder-img"
                src={require("../images/questLogo.png")}
                alt="Credit to Pierre Châtel-Innocenti on Unsplash"
              />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default PartnersSlider;
