/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableBody,
  Link,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  GetAllUnlistedWithQuantity,
  GetBrokeragePayoutByCompanyMasterCode,
  GetByIdProductList,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  listOfManufactureNDistributorCompanies,
  product_listOfAllProductType,
} from "../../api/Api";
import TableHeadData from "../TableHeadData";

const OrderWindowForUnlisted = () => {
  const [companyMasterCode, setCompanyMasterCode] = useState();
  const [companyTypeCode, setCompanyTypeCode] = React.useState();
  const [distributorCode, setDistributorCode] = React.useState("");
  const [distributorUserCode, setDistributorUserCode] = React.useState("");
  const [unlistedID, setUnlistedID] = React.useState("");

  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompanie",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled:
        sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E",
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: UnlistedByID } = useQuery(
    ["UnlistedProductListByID", unlistedID, companyMasterCode],
    () =>
      GetByIdProductList(unlistedID, "?companyMasterCode=" + companyMasterCode),
    {
      enabled: !!unlistedID,
      keepPreviousData: false,
    }
  );

  const { data: unlistedList } = useQuery(
    ["GetAllUnlistedWithQuantity", companyMasterCode],
    () =>
      GetAllUnlistedWithQuantity(
        (!!companyMasterCode && sessionStorage.getItem("Profile") === "B") ||
          (!!companyMasterCode && sessionStorage.getItem("Profile") === "E")
          ? "?companyMasterCode=" + companyMasterCode
          : ""
      ),
    {
      enabled:
        (sessionStorage.getItem("Profile") === "B" && !!companyMasterCode) ||
        (sessionStorage.getItem("Profile") === "E" && !!companyMasterCode) ||
        sessionStorage.getItem("Profile") === "D",
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", companyMasterCode, companyTypeCode],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        companyTypeCode === "S" ? "E" : companyTypeCode,
        companyMasterCode
      ),
    {
      enabled: !!companyMasterCode,
      keepPreviousData: false,
    }
  );

  // LIST OF ALL PRODUCT TYPES
  const listOfAllProductType =
    sessionStorage.getItem("listOfAllProducts") ?? "";
  const listOfAllProductTypeParsed = JSON.parse(listOfAllProductType);

  // fetch UNLISTED product type code
  let productTypeCodeFromList = listOfAllProductTypeParsed?.filter(
    (productObj: any) => productObj.productName === "UNLISTED"
  )[0].productTypeCode;

  // FETCH BROKERAGE PAYOUT
  const { data: fetchBrokeragePayout } = useQuery(
    "GetBrokeragePayoutByCompanyMasterCode",
    () => GetBrokeragePayoutByCompanyMasterCode(companyMasterCode),
    {
      enabled: !!companyMasterCode,
      refetchOnWindowFocus: false,
    }
  );
  const filteredUnlistedBrokeragePayout =
    fetchBrokeragePayout?.data?.companyBrokerage?.filter(
      (brokerageObj: any) =>
        brokerageObj.productTypeCode === productTypeCodeFromList
    );
  // destructuring brokerage payout from the filtered array and assign null if the array is empty
  let brokeragePayout = filteredUnlistedBrokeragePayout?.length
    ? filteredUnlistedBrokeragePayout[0]
    : null;

  return (
    <>
      <Typography variant="h5" textAlign={"center"}>
        {" "}
        Unlisted Order{" "}
      </Typography>
      <Grid container rowSpacing={1} columnSpacing={1}>
        {sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E" ? (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={
                distributorCompanyList &&
                distributorCompanyList.data.companies.map(
                  (options: any) => options
                )
              }
              getOptionLabel={(options: any) =>
                options.companyName + " - " + options.companyTypeCode
              }
              onChange={(options: any, newValue) => {
                setCompanyMasterCode(
                  newValue ? newValue.companyMasterCode : ""
                );
                setCompanyTypeCode(newValue ? newValue.companyTypeCode : "");
                sessionStorage.setItem(
                  "CompanyData",
                  newValue?.companyMasterCode
                );
                sessionStorage.setItem(
                  "CompanyTypeCode",
                  newValue?.companyTypeCode
                );
                setUnlistedID("");
              }}
              renderInput={(params: any) => (
                <TextField {...params} label="Select Company" size={"small"} />
              )}
            />
          </Grid>
        ) : (
          <></>
        )}

        {unlistedList &&
          (sessionStorage.getItem("Profile") === "B" ||
          sessionStorage.getItem("Profile") === "E" ? (
            <>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    UserProfilesByCompanyMasterCode
                      ? UserProfilesByCompanyMasterCode.data.userProfile.map(
                          (options: any) => options
                        )
                      : []
                  }
                  getOptionLabel={(options: any) =>
                    options?.userCode +
                    "- " +
                    options?.firstName +
                    " " +
                    options?.lastName
                  }
                  onChange={(options: any, newValue) => {
                    setDistributorCode(newValue ? newValue.employeeCode : "");
                    setDistributorUserCode(newValue ? newValue.userCode : "");
                  }}
                  renderInput={(params: any) => (
                    <TextField {...params} label="Select User" size={"small"} />
                  )}
                />
              </Grid>
            </>
          ) : (
            <></>
          ))}

        {unlistedList && (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={unlistedList.data.unlistedList.map(
                (options: any) => options
              )}
              getOptionLabel={(options: any) => options.scriptName}
              onChange={(options: any, newValue) => {
                setUnlistedID(newValue?.unlistedMasterCode || "");
              }}
              renderInput={(params: any) => (
                <TextField {...params} label="Select Unlisted" size={"small"} />
              )}
            />
          </Grid>
        )}
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1470 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ width: 150 }}>
                Order Date
              </TableCell>
              <TableCell align="center" sx={{ width: 150 }}>
                Buy Price
              </TableCell>
              <TableCell align="center" sx={{ width: 150 }}>
                Sell Price
              </TableCell>
              {/* <TableCell align='center' sx={{width:50}}>TCS (0.1%)</TableCell> */}
              <TableCell align="center">Total</TableCell>
              <TableCell align="center" sx={{ width: 150 }}>
                Bid Quantity
              </TableCell>
              <TableCell align="center" sx={{ width: 350 }}>
                Client
              </TableCell>
              {sessionStorage.getItem("Profile") === "E" &&
              sessionStorage.getItem("CompanyTypeCode") === "S" ? null : (
                <>
                  <TableCell align="center">RM Revenue</TableCell>
                  <TableCell align="center">Distributor Payout</TableCell>
                </>
              )}
              <TableCell align="center"></TableCell>
              <TableCell align="center" sx={{ width: 50 }}>
                Order
              </TableCell>
              {/* <TableCell align="center" sx={{ width: 50 }}>
                Add To Proposal
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {UnlistedByID &&
              UnlistedByID.data.unlistedList.map((item: any) => (
                <TableHeadData
                  item={item}
                  distributorCode={distributorCode}
                  JV={""}
                  companyMasterCode={companyMasterCode}
                  distributorUserCode={distributorUserCode}
                  brokeragePayout={brokeragePayout ? brokeragePayout : null}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OrderWindowForUnlisted;
