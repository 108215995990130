import { Autocomplete, Box, Button, Chip, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { GetAllBondQuote, GetBondsMasterActiveInactiveList, GetPriceByYtm, GetYtmYtcByPrice } from '../../api/Api'
import { TextFieldSizeSmall, mb } from '../../Constants/Color'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { toast } from 'react-toastify'
import { convertDate } from '../../utils/Helper'

interface isinType {
  issuerName: String;
  coupon: number;
  maturityDate:Date;
  faceValue:string;
}

const AddQuote = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({ mode: "onBlur"});

    const [isinNumber, setIsinNumber] = useState("")
    const [isin, setIsin] = useState<isinType | any>()


    const [refatchYtmYtc, setrefatchYtmYtc] = useState(false)
    const [refatchPrice, setrefatchPrice] = useState(false)

    const [showCircularProgress, setshowCircularProgress] = useState(false);

    // const { data,refetch } = useQuery("BondQuote",GetAllBondQuote)
    const { data:bondData } = useQuery("BondActiveInactiveDeatils",()=>GetBondsMasterActiveInactiveList("?isActive=true"))

   

    const registerOptions = {
        isinNumber: { required: "Isin is required" },
        // minValue: { required: "Min Value is required",onChange: (v: any) => console.log(parseInt(v).toLocaleString('en-IN')) },
        minValue: { required: "Min Value is required",valueAsNumber: true,
        validate:{
          checkFaceValue : (minValue:any) => (minValue >= parseInt(isin.faceValue)) || "Min value should Greater then face value",
          checkValue : (minValue:any) => minValue % parseInt(isin.faceValue)===0 || "Min value should Multiple of face value",
        },
        // onChange: (v: any) => setValue("minValue",parseInt(v.target.value).toLocaleString('en-IN'))
        },
        salePrice:{ required: "Sale Price is required" },
        manufacturerPrice:{ required: "Manufacturer Price is required" },
        multiple:{ required: "Min Value is required", validate:{
          checkFaceValue : (minValue:any) => (minValue >= parseInt(isin.faceValue)) || "Multiple value should Greater then face value",
          checkValue : (minValue:any) => minValue % parseInt(isin.faceValue)===0 || "Multiple value should Multiple of face value",
        }},
        maxValue:{ required: "Max value is required"},
        // , validate:{
        //   checkFaceValue : (minValue:any) => (minValue >= parseInt(isin.faceValue)) || "Max value should Greater then face value",
        //   checkMaxValue : (minValue:any) => minValue % parseInt(isin.faceValue)===0 || "Max value should Multiple of face value",
        // } }
    };

      const { data:YtmYtcvalue } = useQuery("YtmYtcDetail",()=>GetYtmYtcByPrice(isinNumber,getValues("manufacturerPrice"),getValues("salePrice"),getValues("employeePrice")),{
        enabled: !!isinNumber && (!!getValues("salePrice") || !!getValues("manufacturerPrice") || !!getValues("employeePrice")) && refatchYtmYtc,
        refetchOnWindowFocus: false,
        onSuccess:(data:any)=>{
          setValue("saleYtm",data.data.xirrNXnpvDTO.saleYtm);
          setValue("saleYtc",data.data.xirrNXnpvDTO.saleYtc);
          setValue("ytm",data.data.xirrNXnpvDTO.ytm);
          setValue("ytc",data.data.xirrNXnpvDTO.ytc);
          setValue("employeeYtc",data.data.xirrNXnpvDTO.employeeYtc);
          setValue("employeeYtm",data.data.xirrNXnpvDTO.employeeYtm);
          setrefatchYtmYtc(false)
      }})

      const { data:getPrice } = useQuery("PriceDetail",()=>GetPriceByYtm(isinNumber,getValues("ytm"),getValues("saleYtm"),getValues("employeeYtm")),{
          enabled: !!isinNumber && (!!getValues("ytm") || !!getValues("saleYtm")) && refatchPrice,
          refetchOnWindowFocus: false,
          onSuccess:(data:any)=>{
            console.log("Menu Price",data.data.xirrNXnpvDTO)
            setValue("manufacturerPrice",data.data.xirrNXnpvDTO.manufacturerPrice);
            setValue("salePrice",data.data.xirrNXnpvDTO.salePrice);
            setValue("employeePrice",data.data.xirrNXnpvDTO.employeePrice);
            setrefatchPrice(false)
        }})

      const onSubmit = (data: any) => {
        setshowCircularProgress(true)
        const DataDto = { 
            ...data,
            isinNumber:isinNumber,
            productName: isin.issuerName,
            description: isin.issuerName,
        }
        console.log("DataDto",DataDto)
          axios.post("quoteDetails/saveQuoteDetails",DataDto)
              .then(()=>toast.success("Quote added Successfully"))
              .then(()=>{setshowCircularProgress(false);window.location.reload()})
              .catch((res)=>{setshowCircularProgress(false); toast.error(res.response.data.status.message.title)})
      }

      function get_Ytm_Ytc(event:any){
        setrefatchYtmYtc(true)
      }
      
      function get_Price(event:any){
        setrefatchPrice(true)
      }


      // console.log(getValues("isinNumber"))
      // console.log(getValues("manufacturerPrice"))
      // console.log(getValues("salePrice")) 
      // console.log("isin",isin) 
      // console.log("Price Enable -- ",!!isinNumber && (!!getValues("salePrice") || !!getValues("manufacturerPrice")) && refatchYtmYtc) 
      // console.log("YTM Enable -- ",!!isinNumber && (!!getValues("ytm") || !!getValues("saleYtm")) && refatchPrice) 

  return (
    <>
        <Box>
                { !!isin ? <Typography textAlign={'center'} fontSize={15} gutterBottom>
                  <Stack spacing={1} direction={'row'} mb={2}>
                  <Chip variant="outlined" label={ isin.issuerName + " / " + isin.coupon + "% / " + isin.faceValue + " / " + convertDate(isin.maturityDate) } />  
                  </Stack>
                </Typography> :<></> }
                
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                <Grid item xs={12} sm={3} md={2} lg={1.5}>
              <Autocomplete
                options={bondData ? bondData.data.bondWork : []}
                getOptionLabel={(option:any) => !!bondData ? option.abbreviation + " - " + option.isinNumber : ""}
                onChange={(event:any, value:any) => { setIsin(!!value?value:""); setIsinNumber(!!value?value.isinNumber:"")}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Bond"
                    variant="outlined"
                    error={errors.isinNumber ? true : false}
                    helperText={
                      errors.isinNumber === undefined
                        ? ""
                        : errors.isinNumber.message + ""
                    }
                    {...register("isinNumber",registerOptions.isinNumber)}
                    size={TextFieldSizeSmall}
                  />
                )}
              />
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                  <TextField
                    id="outlined-basic"
                    label="Min Value"
                    variant="outlined"
                    
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={errors.minValue ? true : false}
                    helperText={
                      errors.minValue === undefined
                        ? ""
                        : errors.minValue.message + ""
                    }
                    {...register("minValue",registerOptions.minValue)}
                    size={TextFieldSizeSmall}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                  <TextField
                    id="outlined-basic"
                    label="Multiple Value"
                    variant="outlined"
                    
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={errors.multiple ? true : false}
                    helperText={
                      errors.multiple === undefined
                        ? ""
                        : errors.multiple.message + ""
                    }
                    {...register("multiple",registerOptions.multiple)}
                    sx={{ mb: mb }}
                    size={TextFieldSizeSmall}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                  <TextField
                    id="outlined-basic"
                    label="Max Value"
                    variant="outlined"
                    
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={errors.maxValue ? true : false}
                    helperText={
                      errors.maxValue === undefined
                        ? ""
                        : errors.maxValue.message + ""
                    }
                    {...register("maxValue",registerOptions.maxValue)}
                    sx={{ mb: mb }}
                    size={TextFieldSizeSmall}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                  <TextField
                    id="outlined-basic"
                    label="M. YTM"
                    variant="outlined"
                    
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={errors.manufacturerPrice ? true : false}
                    helperText={
                      errors.manufacturerPrice === undefined
                        ? ""
                        : errors.manufacturerPrice.message + ""
                    }
                    {...register("ytm"
                    // ,registerOptions.manufacturerPrice
                    )}
                    sx={{ mb: mb }}
                    size={TextFieldSizeSmall}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                  <TextField
                    id="outlined-basic"
                    label="M. YTC"
                    variant="outlined"
                    
                    fullWidth
                    error={errors.manufacturerPrice ? true : false}
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      errors.manufacturerPrice === undefined
                        ? ""
                        : errors.manufacturerPrice.message + ""
                    }
                    {...register("ytc"
                    // ,registerOptions.manufacturerPrice
                    )}
                    sx={{ mb: mb }}
                    size={TextFieldSizeSmall}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                  <TextField
                    id="outlined-basic"
                    label="M. Price"
                    variant="outlined"
                    
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={errors.manufacturerPrice ? true : false}
                    helperText={
                      errors.manufacturerPrice === undefined
                        ? ""
                        : errors.manufacturerPrice.message + ""
                    }
                    // onChangeCapture={(e:any)=>setValue("manufacturerPrice",e.target.value)}
                    {...register("manufacturerPrice"
                    // ,registerOptions.manufacturerPrice
                    )}
                    sx={{ mb: mb }}
                    size={TextFieldSizeSmall}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                  <TextField
                    id="outlined-basic"
                    label="S. YTM"
                    variant="outlined"
                    
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={errors.saleYtm ? true : false}
                    helperText={
                      errors.saleYtm === undefined
                        ? ""
                        : errors.saleYtm.message + ""
                    }
                    {...register("saleYtm")}
                    sx={{ mb: mb }}
                    size={TextFieldSizeSmall}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                
                  <TextField
                    id="outlined-basic"
                    label="S. YTC"
                    variant="outlined"
                    
                    fullWidth
                    error={errors.salePrice ? true : false}
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      errors.salePrice === undefined
                        ? ""
                        : errors.salePrice.message + ""
                    }
                    // onChangeCapture={(e:any)=>setValue("saleYtc",e.target.value)}
                    {...register("saleYtc"
                    // ,registerOptions.salePrice
                    )}
                    sx={{ mb: mb }}
                    size={TextFieldSizeSmall}
                  />

                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                  <TextField
                    id="outlined-basic"
                    label="S. Price"
                    variant="outlined"
                    
                    fullWidth
                    error={errors.salePrice ? true : false}
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      errors.salePrice === undefined
                        ? ""
                        : errors.salePrice.message + ""
                    }
                    {...register("salePrice"
                    // ,registerOptions.salePrice
                    )}
                    sx={{ mb: mb }}
                    size={TextFieldSizeSmall}
                  />
                </Grid>

                <Grid item xs={12} sm={3} md={2} lg={1}>
                  <TextField
                    id="outlined-basic"
                    label="E. YTM"
                    variant="outlined"
                    
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={errors.saleYtm ? true : false}
                    helperText={
                      errors.saleYtm === undefined
                        ? ""
                        : errors.saleYtm.message + ""
                    }
                    {...register("employeeYtm")}
                    sx={{ mb: mb }}
                    size={TextFieldSizeSmall}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                
                  <TextField
                    id="outlined-basic"
                    label="E. YTC"
                    variant="outlined"
                    
                    fullWidth
                    error={errors.salePrice ? true : false}
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      errors.salePrice === undefined
                        ? ""
                        : errors.salePrice.message + ""
                    }
                    // onChangeCapture={(e:any)=>setValue("saleYtc",e.target.value)}
                    {...register("employeeYtc"
                    // ,registerOptions.salePrice
                    )}
                    sx={{ mb: mb }}
                    size={TextFieldSizeSmall}
                  />

                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                  <TextField
                    id="outlined-basic"
                    label="E. Price"
                    variant="outlined"
                    
                    fullWidth
                    error={errors.salePrice ? true : false}
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      errors.salePrice === undefined
                        ? ""
                        : errors.salePrice.message + ""
                    }
                    {...register("employeePrice"
                    // ,registerOptions.salePrice
                    )}
                    sx={{ mb: mb }}
                    size={TextFieldSizeSmall}
                  />
                </Grid>
              </Grid>

                    <Stack spacing={3} direction={'row'}>
                  <Button variant="contained" onClick={get_Ytm_Ytc} size='small'> Get YTM/YTC</Button>
                  <Button variant="contained" onClick={get_Price} size='small'> Get Price</Button>
                    <Button type="submit" variant="contained" size='small' disabled={showCircularProgress}>
                  {!!showCircularProgress ? (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      thickness={5}
                    />
                  ) : (
                    ""
                  )}{" "}
                     Submit
                  </Button>
                    </Stack>
                  </form>

            </Box>
    </>
  )
}

export default AddQuote