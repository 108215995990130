import React from 'react'
import { useQuery } from 'react-query'
import { getAllBookACall } from '../../api/Api'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { convertDate, convertDateIntoTime } from '../../utils/Helper'
import { withParam } from '../../utils/Router.Helper'

const ListOfBookACall = () => {
	const { data } = useQuery("GetAllBookACallList",getAllBookACall)
  return (
	<>
	<Typography variant='h4' gutterBottom> Book A Call </Typography>
	<TableContainer component={Paper} elevation={3}>
		<Table sx={{ minWidth: 930 }} aria-label="simple table">
		  <TableHead>
		    <TableRow>
				<TableCell>ID</TableCell>
				<TableCell align="left">Company Name</TableCell>
				<TableCell align="left">Full Name</TableCell>
				<TableCell align="left">Broker Master Code</TableCell>
				<TableCell align="left">Product Type Code</TableCell>
				<TableCell align="left">Mobile No</TableCell>
				<TableCell align="left">Email Id</TableCell>
				<TableCell align="left">Address</TableCell>
				<TableCell align="left">Availability Date</TableCell>
				<TableCell align="left">From Time</TableCell>
				<TableCell align="left">To Time</TableCell>
				<TableCell align="left">Status</TableCell>
				<TableCell align="left">Remarks</TableCell>
				<TableCell align="left">Employee Code</TableCell>
				<TableCell align="left">Meeting Type</TableCell>
				<TableCell align="left">Meeting Link</TableCell>
				<TableCell align="left">Meeting Time</TableCell>
				<TableCell align="left">Is Existing</TableCell>
				<TableCell align="left">Assigned By</TableCell>
				<TableCell align="left">Action</TableCell>
		    </TableRow>
		  </TableHead>
		  <TableBody>
		    {data && data.data.bookACallList?.sort((a: any, b: any) => b.bookCallDetailsId - a.bookCallDetailsId).map((row: any) => (
		      <TableRow
		        key={row.bookCallDetailsId}
		        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
		      >
		        <TableCell component="th" scope="row">{row.bookCallDetailsId || "-"}</TableCell>
		        <TableCell align="left">{row.companyName || "-"}</TableCell>
		        <TableCell align="left">{row.contactPersonFullName || "-"}</TableCell>
		        <TableCell align="left">{row.brokerMasterCode || "-"}</TableCell>
		        <TableCell align="left">{row.productTypeCode || "-"}</TableCell>
		        <TableCell align="left">{row.mobileNo || "-"}</TableCell>
		        <TableCell align="left">{row.emailId || "-"}</TableCell>
		        <TableCell align="left">{row.address || "-"}</TableCell>
		        <TableCell align="left">{row.availabilityDate ? convertDate(row.availabilityDate) : "-"}</TableCell>
		        <TableCell align="left">{convertDateIntoTime(row.availabilityFromTime) ? convertDateIntoTime(row.availabilityFromTime ) : "-"}</TableCell>
		        <TableCell align="left">{convertDateIntoTime(row.availabilityToTime) ? convertDateIntoTime(row.availabilityToTime) : "-"}</TableCell>
		        <TableCell align="left">{row.status?.replaceAll("_"," ") || "-"}</TableCell>
		        <TableCell align="left">{row.remarks || "-"}</TableCell>
		        <TableCell align="left">{row.mappedEmployeeCode || "-"}</TableCell>
		        <TableCell align="left">{row.meetingType || "-"}</TableCell>
		        <TableCell align="left">{row.meetingLink || "-"}</TableCell>
		        <TableCell align="left">{row.meetingTime || "-"}</TableCell>
		        <TableCell align="left">{row.isExisting || "-"}</TableCell>
		        <TableCell align="left">{row.assignedBy || "-"}</TableCell>
		        <TableCell align="center">
		          <Link to={"/BookACallByID/" + row.bookCallDetailsId}>
		            <Button
			            size="small"
			            type="submit"
			            color="primary"
			            variant="contained"
		            >
		              View
		            </Button>
		          </Link>
		        </TableCell>
		      </TableRow>
		    ))}
		  </TableBody>
		</Table>
	</TableContainer>
	</>
  )
}

export default withParam(ListOfBookACall) 