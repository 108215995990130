import React from "react";
import Slider from "react-slick";
import "../css/distSlider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const DistributerSlider = () => {
  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    slidesPerRow: 1,
  };
  return (
    <>
      <div className="dist-main">
        <div className="dist-header">
          <span>Our Distributor Partners</span>
        </div>

        <div className="slick-cont3">
          <Slider {...settings} className="slider_container">
            <div>
              <img
                className="silder-img"
                src={require("../images/hdfcsecurity.png")}
                alt="Credit to Joshua Earle on Unsplash"
              />
            </div>
            <div>
              <img
                className="silder-img"
                src={require("../images/motilal-oswal.png")}
                alt="Credit to Igor Ovsyannykov on Unsplash"
              />
            </div>
            <div>
              <img
                className="silder-img"
                src={require("../images/wealthvault.png")}
                alt="Credit to Pierre Châtel-Innocenti on Unsplash"
              />
            </div>
            <div>
              <img
                className="silder-img"
                src={require("../images/yessecurities.png")}
                alt="Credit to Richard Nolan on Unsplash"
              />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default DistributerSlider;
