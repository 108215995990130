import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  GetAllClient,
  GetBrokeragePayoutByCompanyMasterCode,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  PmsGetById,
  listOfManufactureNDistributorCompanies,
  pmsgetAllList,
} from "../../api/Api";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm } from "react-hook-form";
import { productTypeEnum } from "../../Constants/ProductTypeEnum";
import { CurrencyFormate, changeDate } from "../../utils/Helper";
import axios from "axios";
import { toast } from "react-toastify";
import { IncomeType } from "../../Constants/IncomeType";
import ClientModalForOrder from "../ClientModalForOrder";
import { CREATE_NEW_ORDER, SAVE_PROPOSAL } from "../../utils/API_Names";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const OrderWindowForPMS = (props: any) => {
  const [pmsData, setPmsData] = useState<any>();

  const [companyMasterCode, setCompanyMasterCode] = React.useState();
  const [companyTypeCode, setCompanyTypeCode] = React.useState();
  const [distributorCode, setDistributorCode] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [orderDate, seOrderDate] = React.useState(new Date());
  const [dataincomeval, selectValueincome] = useState<any>();
  const [datasetincomevalue, setincomevalue] = useState<any>();
  const [Clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [clientDetails, setClientDetails] = React.useState<any>("");
  const [incomeType, setIncomeType] = React.useState<any>(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [uploadForm, setUploadForm] = React.useState<any>();
  const [uploadFormRefNo, setUploadFormRefNo] = React.useState("");
  const [OrderNowBtn, setOrderNowBtn] = React.useState(false);
  const [investmentAmount, setInvestmentAmount] = React.useState("");
  const [distributorUserCode, setDistributorUserCode] = React.useState("");
  const [selectedUser, setSelectedUser] = React.useState(null);

  let pmsSchemeCode = pmsData?.schemeCode;

  console.log(pmsSchemeCode);

  const { data: pmsList, isLoading } = useQuery("PmsOrder", () =>
    pmsgetAllList("Y")
  );

  const { data: pmsDetils, isLoading: pmsDetilsLoading } = useQuery(
    ["PMSGetById", pmsSchemeCode],
    () => PmsGetById(pmsSchemeCode),
    {
      enabled: !!pmsSchemeCode,
      refetchOnWindowFocus: false,
      retry: false,
      // keepPreviousData: false,
      onSuccess(data) {
        setValue(
          "buyRate",
          pmsDetils?.data?.pmsDTOList[0]?.schemeMinInvestment
        );
      },
    }
  );

  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompanie",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled:
        sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E",
    }
  );

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", companyMasterCode, companyTypeCode],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        companyTypeCode === "S" ? "E" : companyTypeCode,
        companyMasterCode
      ),
    {
      enabled: !!companyMasterCode,
    }
  );

  const { data: clientData } = useQuery(
    ["getAllClients", companyMasterCode, distributorCode],
    () => GetAllClient(companyMasterCode, distributorCode),
    {
      enabled: !!companyMasterCode || sessionStorage.getItem("Profile") === "D",
    }
  );

  const { data: brokerageData } = useQuery(
    ["GetBrokeragePayoutByCompanyMasterCode", companyMasterCode],
    () =>
      GetBrokeragePayoutByCompanyMasterCode(
        sessionStorage.getItem("Profile") === "D"
          ? sessionStorage.getItem("CompanyMasterCode")
          : companyMasterCode
      ),
    {
      enabled: !!companyMasterCode || sessionStorage.getItem("Profile") === "D",
    }
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,

    formState: { errors },
  } = useForm({ mode: "onChange" });

  const addToProposal = () => {
    let proposalDTO = {
      clientId: clientId,
      productMasterCode: pmsDetils.data.pmsDTOList[0].productMasterCode,
      productTypeCode: productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM,
      clientPrice: !!investmentAmount
        ? investmentAmount
        : pmsDetils.data.pmsDTOList[0].schemeMinInvestment,
      disCompanyMasterCode: !!companyMasterCode ? companyMasterCode : "",
      disUserCode: !!distributorUserCode ? distributorUserCode : "",
    };
    console.log("proposal-DTO: ", proposalDTO);
    axios
      .post(SAVE_PROPOSAL, proposalDTO)
      .then(
        (res: any) => res && toast.success(res?.data?.status?.message?.title)
      )
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };
  const registerOptions = {
    commitmentAmount: {
      required: "Please Enter Valid Amount",
      pattern: {
        value: /^[0-9]+$/,
        message: "Invalid Amount",
      },
      validate: {
        positive: (v: any) =>
          parseInt(v) >= pmsDetils.data.pmsDTOList[0]?.schemeMinInvestment ||
          `Please Enter A Amount More then ${pmsDetils.data.pmsDTOList[0]?.schemeMinInvestment}`,
      },
    },
    selectedDate: {
      required: "Select a Date",
    },
    clients: { required: "Select a Client" },
  };

  const { data } = useQuery("getAllClients", () => GetAllClient(), {
    onSuccess: (data: any) => {
      const newData = data.data.clients.map((item: any, index: any) => {
        return { ...item, SrNo: index + 1 };
      });
      setClients(newData);
    },
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);

  const pmsBrokerage =
    brokerageData &&
    brokerageData.data?.companyBrokerage?.filter(
      (item: any) =>
        item?.productTypeCode === productTypeEnum?.PORTFOLIO_MANAGEMENT_SYSTEM
    )[0];

  const profit: any =
    !!watch("buyRate") && pmsDetils
      ? // here it will check for is NAN
        isNaN(
          parseFloat(
            (
              watch("buyRate") *
              (parseFloat(pmsDetils.data.pmsDTOList[0].revenueCreditFee) /
                100) *
              (parseFloat(pmsDetils.data.pmsDTOList[0].managementFees) / 100)
            ).toFixed(2)
          ) -
            parseFloat(
              (
                watch("buyRate") *
                (parseFloat(pmsDetils.data.pmsDTOList[0].revenueCreditFee) /
                  100) *
                (parseFloat(pmsDetils.data.pmsDTOList[0].managementFees) /
                  100) *
                (parseFloat(pmsBrokerage?.brokerageValueForIV) / 100)
              ).toFixed(2)
            )
        )
        ? "0"
        : // this will print on display
          parseFloat(
            (
              watch("buyRate") *
              (parseFloat(pmsDetils.data.pmsDTOList[0].revenueCreditFee) /
                100) *
              (parseFloat(pmsDetils.data.pmsDTOList[0].managementFees) / 100)
            ).toFixed(2)
          ) -
          parseFloat(
            (
              watch("buyRate") *
              (parseFloat(pmsDetils.data.pmsDTOList[0].revenueCreditFee) /
                100) *
              (parseFloat(pmsDetils.data.pmsDTOList[0].managementFees) / 100) *
              (parseFloat(pmsBrokerage?.brokerageValueForIV) / 100)
            ).toFixed(2)
          )
      : // this is else condition for nan
        "0";

  React.useEffect(() => {
    if (uploadForm) {
      setOrderNowBtn(true);
      const formData = new FormData();
      formData.append("document", uploadForm);
      axios.post("documentupload", formData).then((res: any) => {
        setUploadFormRefNo(res.data.documentId);
        setOrderNowBtn(false);
      });
    }
  }, [uploadForm]);

  let amt: any = null;
  const onSubmitInvestNow = (dataa: any) => {
    let PmsInvestNowdto = {
      productMasterCode: pmsDetils.data.pmsDTOList[0].productMasterCode,
      clientId: clientId,
      version: "1",
      productTypeCode: productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM,
      orderDate: changeDate(orderDate),
      distCompanyMasterCode: companyMasterCode,
      distEmployeeCode: distributorCode,
      schemeCode: pmsDetils.data.pmsDTOList[0].schemeCode,
      buyRate: !!dataa.buyRate
        ? dataa.buyRate
        : pmsDetils.data.pmsDTOList[0].schemeMinInvestment,
      commitmentAmount: dataa?.commitmentAmount,
      acknowledgementReferenceNo:
        dataa?.acknowledgementNo === "" ? null : dataa?.acknowledgementNo,
      fromRefNo: uploadFormRefNo === "" ? null : uploadFormRefNo,
      trailAmount: amt,
    };
    axios
      .post(CREATE_NEW_ORDER, PmsInvestNowdto)
      .then((res: any) => {
        res && toast.success(res?.data?.status?.message?.title);
        window.location.reload();
      })
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };

  let revenueRate=(pmsDetils && (pmsDetils.data.pmsDTOList[0].managementFees * pmsDetils.data.pmsDTOList[0].revenueCreditFee) / 100);
  let disRevenueRate:any;
  let brokerRevenueRate:any;
  if(companyTypeCode === "D" || sessionStorage.getItem("Profile")==="D"){
    disRevenueRate=revenueRate * pmsBrokerage?.brokerageValueForCustomer/100;
    brokerRevenueRate=revenueRate-disRevenueRate;
  }else{
    brokerRevenueRate=revenueRate;
  }

  let userInvestmentAmount = watch("buyRate");
  let ManagementFeesAmount =
    pmsDetils &&
    (userInvestmentAmount * pmsDetils.data.pmsDTOList[0].managementFees) / 100;
  let RevenueCreditAmount =
    pmsDetils &&
    (ManagementFeesAmount * pmsDetils.data.pmsDTOList[0].revenueCreditFee) /
      100;
  let income_Type = pmsDetils?.data?.pmsDTOList[0]?.incomeType || null;

  let trailAmount: any = null;
  let empRevenueAmount:any;
  if (income_Type === "trail") {
    const revenuePerDay: any = RevenueCreditAmount / 365;
    let now = new Date(orderDate);
    let monthap = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    let daysRemaining = monthap.getDate();
    let amount = revenuePerDay * daysRemaining;
    if (companyTypeCode === "D" || sessionStorage.getItem("Profile") === "D") {
      amt = (amount * pmsBrokerage?.brokerageValueForCustomer) / 100;
      trailAmount = CurrencyFormate(amt);
      amt = amt.toFixed(2);
      empRevenueAmount=CurrencyFormate(amount-amt);
    } else {
      amt = amount;
      trailAmount = CurrencyFormate(amt);
      amt = amt.toFixed(2);
    }
  }

  return (
    <>
      {pmsList ? (
        <Autocomplete
          sx={{ width: 500 }}
          disablePortal
          id="combo-box-demo"
          options={
            pmsList && pmsList.data.pmsDTOList.map((option: any) => option)
          }
          getOptionLabel={(options: any) => options?.schemeName}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option?.schemeName}
            </Box>
          )}
          onChange={(options: any, newValue) => {
            setPmsData(newValue || "");
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Scheme" size="small" />
          )}
        />
      ) : (
        <Typography variant="subtitle1" textAlign={"center"} sx={{ mt: 2 }}>
          <CircularProgress size={15} sx={{ mr: 1 }} />
          Please Wait We're Fetching PMS Schemes For You...{" "}
        </Typography>
      )}

      {pmsList && pmsDetils
        ? pmsDetils.data.pmsDTOList.map((item: any) => (
            <Box>
              <Typography variant="h6" textAlign={"center"} gutterBottom>
                Order Details
              </Typography>
              <Divider sx={{ mb: 1 }} />
              <Grid
                container
                rowGap={4}
                sx={{
                  margin: "0 auto",
                }}
              >
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <img
                    src={item?.amcInfoDTO?.amcLogo}
                    width={200}
                    alt="AMC logo"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}></Grid>
                <Typography variant="h5" textAlign={"center"} gutterBottom>
                  {item.schemeName || "-"}
                </Typography>
              </Grid>
              <Grid container textAlign={"center"}></Grid>

              <Grid container spacing={2} textAlign={"center"} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">Min Investment</Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {item.schemeMinInvestment?.toLocaleString("en-IN")
                      ? "₹ " + item.schemeMinInvestment?.toLocaleString("en-IN")
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">Benchmark</Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {item.schemeBenchmarkName ? item.schemeBenchmarkName : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">AUM</Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {item.fundPerformance.aum ? item.fundPerformance.aum : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">NAV</Typography>
                  <Typography variant="subtitle2">
                    {item.fundPerformance?.nav}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 2 }}>
                <Grid item xs={9} sm={12} md={12} lg={12}>
                  <Typography variant="subtitle1" align="center">
                    Fund Objective
                  </Typography>
                  <Typography variant="subtitle2" align="center">
                    {item.schemeObjective || "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} textAlign={"center"} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">Inception Date</Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {item.schemeInceptionDate ? item.schemeInceptionDate : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Typography variant="subtitle1">
                    Exit Load / Lock-in
                  </Typography>
                  <Typography variant="subtitle2">
                    {item.schemeExitLoad || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">Fees Structure</Typography>
                  <Typography variant="subtitle2">
                    {item.schemeFeeStructure || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">Fund Manager</Typography>
                  <Typography variant="subtitle2">
                    {item.fundManagerName || "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container rowGap={2} columnGap={3}>
                {sessionStorage.getItem("Profile") === "B" ||
                sessionStorage.getItem("Profile") === "E" ? (
                  <Grid item xs={12} sm={3} md={3} lg={2}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={
                        distributorCompanyList &&
                        distributorCompanyList.data.companies.map(
                          (options: any) => options
                        )
                      }
                      getOptionLabel={(options: any) =>
                        options.companyName + " - " + options.companyTypeCode
                      }
                      fullWidth
                      onChange={(options: any, newValue) => {
                        setCompanyMasterCode(
                          newValue ? newValue.companyMasterCode : ""
                        );
                        setCompanyTypeCode(
                          newValue ? newValue.companyTypeCode : ""
                        );
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label="Select Company"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                {sessionStorage.getItem("Profile") === "B" ||
                sessionStorage.getItem("Profile") === "E" ? (
                  <>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={
                          UserProfilesByCompanyMasterCode
                            ? UserProfilesByCompanyMasterCode.data.userProfile.map(
                                (options: any) => options
                              )
                            : []
                        }
                        getOptionLabel={(options: any) =>
                          options?.userCode +
                          "- " +
                          options?.firstName +
                          " " +
                          options?.lastName
                        }
                        fullWidth
                        onChange={(options: any, newValue) => {
                          setDistributorCode(
                            newValue ? newValue.employeeCode : ""
                          );
                          setDistributorUserCode(
                            newValue ? newValue.userCode : ""
                          );
                          setSelectedUser(newValue ? newValue : null);
                        }}
                        value={selectedUser}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Select User"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={
                        clientData
                          ? clientData?.data?.clients?.map(
                              (options: any) => options
                            )
                          : []
                      }
                      getOptionLabel={(options: any) =>
                        options.fullName + " - " + options.panCardNo
                      }
                      fullWidth
                      onChange={(options: any, newValue) => {
                        setClientId(newValue ? newValue.clientId : "");
                        setClientDetails(newValue ? newValue : "");
                      }}
                      renderInput={(params: any) => (
                        <form
                          onSubmit={handleSubmit(onSubmitInvestNow)}
                          id="form1"
                        >
                          <TextField
                            {...params}
                            label="Select Clients"
                            size="small"
                            error={errors.clients ? true : false}
                            helperText={
                              !errors.clients ? "" : errors.clients.message + ""
                            }
                            {...register("clients", registerOptions.clients)}
                          />
                        </form>
                      )}
                    />
                  </Grid>

                <Grid sx={{ p: 1 }}>
                  {clientId && (
                    <ClientModalForOrder clientDetails={clientDetails} />
                  )}
                </Grid>
                <TableContainer component={Paper} elevation={3}>
                  <Table sx={{ minWidth: 1650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Order Date
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Client Name
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          PAN Card
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Commitment Amount
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Investment Amount
                        </TableCell>
                        { sessionStorage.getItem("Profile")==="D" &&
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Revenue Rate (PA)
                            </TableCell>
                          }
                          { (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile")==="E") && companyTypeCode==="D" &&
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Revenue Rate (PA)
                            </TableCell>
                          }
                          { (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile")==="E") && companyTypeCode==="D" &&
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Payout Rate (PA)
                            </TableCell>
                          }
                          { (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile")==="E") && companyTypeCode==="S" &&
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Revenue Rate (PA)
                            </TableCell>
                          }
                          { sessionStorage.getItem("Profile")==="D" &&
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Monthly Revenue Trail Amount (Tentative)
                            </TableCell>
                          }
                          { (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile")==="E") && companyTypeCode==="D" &&
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Monthly Revenue Trail Amount (Tentative)
                            </TableCell>
                          }
                          { (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile")==="E") && companyTypeCode==="D" &&
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Monthly Payout Trail Amount (Tentative)
                            </TableCell>
                          }
                          { (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile")==="E") && companyTypeCode==="S" &&
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Monthly Revenue Trail Amount (Tentative)
                            </TableCell>
                          }
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Acknowledgement Reference No *
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Form Upload{" "}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableCell>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            label="Order Date"
                            inputFormat="DD/MM/YYYY"
                            value={orderDate}
                            onChange={(newValue: any) => {
                              seOrderDate(newValue.toJSON());
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                error={errors.orderDate ? true : false}
                                helperText={
                                  errors.orderDate === undefined
                                    ? ""
                                    : errors.orderDate.message + ""
                                }
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Typography>{clientDetails.fullName}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {clientDetails.panCardNo}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <form
                          onSubmit={handleSubmit(onSubmitInvestNow)}
                          id="form1"
                        >
                          <TextField
                            size="small"
                            fullWidth
                            label="Commitment Amount"
                            error={errors.commitmentAmount ? true : false}
                            helperText={
                              !errors.commitmentAmount
                                ? ""
                                : errors.commitmentAmount.message + ""
                            }
                            {...register(
                              "commitmentAmount",
                              registerOptions.commitmentAmount
                            )}
                          />
                        </form>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <form
                          onSubmit={handleSubmit(onSubmitInvestNow)}
                          id="form1"
                        >
                          <TextField
                            label="Investment Amount"
                            size="small"
                            fullWidth
                            defaultValue={item.schemeMinInvestment}
                            error={errors.buyRate ? true : false}
                            helperText={
                              !errors.buyRate ? "" : errors.buyRate.message + ""
                            }
                            {...register("buyRate")}
                          />
                        </form>
                      </TableCell>
                      { sessionStorage.getItem("Profile")==="D" && 
                          <TableCell component="th" scope="row">
                            <Typography textAlign={"center"}>{!isNaN(disRevenueRate) ? disRevenueRate : 0 }%</Typography>
                          </TableCell>
                        }
                        { (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile")==="E") && companyTypeCode==="D" &&
                          <TableCell component="th" scope="row">
                            <Typography textAlign={"center"}>{!isNaN(brokerRevenueRate) ? brokerRevenueRate : 0 }%</Typography>
                          </TableCell>
                        }
                        { (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile")==="E") && companyTypeCode==="D" &&
                          <TableCell component="th" scope="row">
                            <Typography textAlign={"center"}>{!isNaN(disRevenueRate) ? disRevenueRate : 0 }%</Typography>
                          </TableCell>
                        }
                        { (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile")==="E") && companyTypeCode==="S" &&
                          <TableCell component="th" scope="row">
                            <Typography textAlign={"center"}>{!isNaN(revenueRate) ? revenueRate : 0 }%</Typography>
                          </TableCell>
                        }
                        { sessionStorage.getItem("Profile")==="D" && 
                          <TableCell component="th" scope="row" align="center">
                            {" "}
                            {trailAmount || 0}{" "}
                          </TableCell>
                        }
                        { (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile")==="E") && companyTypeCode==="D" &&
                          <TableCell component="th" scope="row" align="center">
                            {" "}
                            {empRevenueAmount || 0}{" "}
                          </TableCell>
                        }
                        { (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile")==="E") && companyTypeCode==="D" &&
                          <TableCell component="th" scope="row" align="center">
                            {" "}
                            {trailAmount || 0}{" "}
                          </TableCell>
                        }
                        { (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile")==="E") && companyTypeCode==="S" &&
                          <TableCell component="th" scope="row" align="center">
                            {" "}
                            {trailAmount || 0}{" "}
                          </TableCell>
                        }
                      <TableCell component="th" scope="row">
                        <form
                          onSubmit={handleSubmit(onSubmitInvestNow)}
                          id="form1"
                        >
                          <TextField
                            size="small"
                            {...register("acknowledgementNo")}
                          />
                        </form>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          component="label"
                          size="small"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          sx={{ mb: 1 }}
                        >
                          Form Upload
                          <input
                            hidden
                            type="file"
                            accept=".pdf"
                            onChange={(event: any) =>
                              setUploadForm(event.target.files[0])
                            }
                          />
                        </Button>
                        <Typography sx={{ fontSize: 12 }}>
                          {uploadForm?.name}
                        </Typography>
                      </TableCell>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Box
                sx={{
                  textAlign: "center",
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <form onSubmit={handleSubmit(onSubmitInvestNow)} id="form1">
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitInvestNow)}
                    variant="contained"
                    disabled={OrderNowBtn}
                  >
                    Order Now
                  </Button>
                </form>
                <Button
                    disabled={!!!clientId}
                    onClick={() => addToProposal()}
                    variant="contained"
                  >
                    Proposal
                  </Button>
              </Box>
            </Box>
          ))
        : pmsSchemeCode && (
            <Typography variant="subtitle1" textAlign={"center"} sx={{ mt: 2 }}>
              {" "}
              <CircularProgress size={15} sx={{ mr: 1 }} /> Please Wait We're
              Fetching PMS Schemes Details For You...{" "}
            </Typography>
          )}
    </>
  );
};

export default OrderWindowForPMS;
