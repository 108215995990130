import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { bondEnum } from "../../Constants/bondEnum";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useQuery } from "react-query";
import { Get_Bond_Details_By_Isin } from "../../api/Api";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  "& .MuiTextField-root": { m: 1 },
  overflowY: "scroll",
  maxHeight: "80%",
};

const AddBond = () => {
  const [open, setOpen] = React.useState(false);
  const [bondType, setBondType] = useState<any>();
  const [callPut, setCallPut] = useState<any>();
  const [couponBasis, setCouponBasis] = useState<any>();
  const [couponFreq, setCouponFreq] = useState<any>();
  const [dayCount, setDayCount] = useState<any>();
  const [government, setGovernment] = useState<any>();
  const [issuerType, setIssuerType] = useState<any>();
  const [listed, setListed] = useState<any>();
  const [modeIssuance, setModeIssuance] = useState<any>();
  const [psu, setPsu] = useState<any>();
  const [secured, setSecured] = useState<any>();
  const [seniority, setSeniority] = useState<any>();
  const [taxability, setTaxability] = useState<any>();
  const [tier, setTier] = useState<any>();
  const [callDate, setCallDate] = React.useState<Dayjs | null | any>(dayjs('2022-04-07'));
  const [putDate, setPutDate] = React.useState<Dayjs | null | any>(dayjs('2022-04-07'));
  const [issuanceDate, setIssuanceDate] = React.useState<Dayjs | null | any>(dayjs('2022-04-07'));
  const [maturityDate, setMaturityDate] = React.useState<Dayjs | null | any>(dayjs('2022-04-07'));

  const [bondLogo , setBondLogo] = useState<any>();
  const [ImDoc , setImDoc] = useState<any>();
  
  const [isin, setIsin] = React.useState<any>("");
  const [checkIsin, setcheckIsin] = React.useState<any>("");
  const [Color, SetColor] = React.useState<any>("");

  const [showCircularProgress, setshowCircularProgress] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data } = useQuery(["GetBondDeatilsByIsin",isin],()=>Get_Bond_Details_By_Isin(isin),{
    enabled:!!isin,
    onSuccess:(data:any)=> { SetColor("warning")},
    onError:(data:any)=> SetColor("success"),
    retry : false,
    refetchOnWindowFocus: false,
  },)
  console.log("isin",isin)

  const resolver = async (values: any) => {
    return {
      values: !values.abbreviation ? {} : values,
      errors:
      !values.isinNumber
        ? {
            isinNumber: {
              type: "required",
              message: "This is required.",
            },
          }
          : 
        !values.bondType
        ? {
            bondType: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.dayCountConvention
        ? {
            dayCountConvention: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.issuerName
        ? {
            issuerName: {
              type: "required",
              message: "This is required.",
            },
          } 
          : !values.issuerType
        ? {
            issuerType: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.couponBasis
        ? {
            couponBasis: {
              type: "required",
              message: "This is required.",
            },
          }
          : values.coupon < 0 
        ? {
       
            coupon: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.couponFrequency
        ? {
            couponFrequency: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.callPutDate
        ? {
            callPutDate: {
              type: "required",
              message: "This is required.",
            },
          } 
          :callPut === "YES" ?
          !values.callDate 
        ? {
            callDate: {
              type: "required",
              message: "This is required.",
            }
          } : {}
        : !values.issuanceDate
        ? {
            issuanceDate: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.maturityDate
        ? {
            maturityDate: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.faceValue
        ? {
            faceValue: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.securedUnsecured
        ? {
            securedUnsecured: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.governmentGauranteed
        ? {
            governmentGauranteed: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.psuNonPsu
        ? {
            psuNonPsu: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.taxability
        ? {
            taxability: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.modeOfIssuance
        ? {
            modeOfIssuance: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.listedUnlisted
        ? {
            listedUnlisted: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.rtaDetails
        ? {
          rtaDetails: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.abbreviation
        ? {
            abbreviation: {
              type: "required",
              message: "This is required.",
            },
          } : {} 
  };
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver,
  });

  console.log("Errors", errors);

  const onSubmit = (data: any) => {
    setshowCircularProgress(true)
    console.log("Data", {
      ...data,
      issuerType: issuerType,
      callPutDate: callPut,
      governmentGauranteed: government,
    });
    const formData = new FormData();
    formData.append("bondWorkDTO",JSON.stringify({
      ...data,
      issuerType: issuerType,
      callPutDate: callPut,
      governmentGauranteed: government
    })) 
    formData.append("imPDF", ImDoc)
    axios
      .post("bondwork/saveBondWork",formData)
      .then((res: any) => toast.success("Bond added Successfully"))
      .then(()=> {setshowCircularProgress(false); setOpen(false)})
      .then(()=>window.location.reload())
      .catch((err: any) =>
        {
          console.log("Err-",err)
          setshowCircularProgress(false);
          toast.error(
          !!err.response?.data?.status?.message?.title ? err.response.data.status.message.title : err.response.data.messageType
        )
      }
      );
  };

    const registerOptions = {
      isin:{required: "Isin is required", onBlur:(e:any)=>setIsin(!!e.target.value?e.target.value:"")}
    }

  return (
    <>

    <Container maxWidth='xl'>
      <Box sx={{ marginTop: "5%" }} className="modal-scroll">
        <Typography variant="h6" component="h2" textAlign={"center"} mb={3}>
          Add Bond
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} sx={{display: "flex", flexDirection: "row",}}>

<Grid item md={3}>
<TextField
  id="outlined-basic"
  label="ISIN"
  variant="outlined"
  fullWidth
  size='small'
  color={!!Color?Color:""}
  sx={{marginBottom:Color==="warning"?'0px':'0px'}}
  error={errors.isinNumber ? true : false}
  helperText={
    errors.isinNumber === undefined ? "" : errors.isinNumber.message+""
  }
  {...register("isinNumber",registerOptions.isin)}
/>
{ Color==="warning"?<Typography color={"red"}> This ISIN Already exist ! </Typography>:""}
</Grid>

<Grid item md={3}>
    {/* bondType */}
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.BondTypeEnum.map((option: any) => option)}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setBondType(newValue?.value);
    setValue("bondType", newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Bond Type"
      size='small'
      error={errors.bondType ? true : false}
      helperText={
        errors.bondType === undefined
          ? ""
          : errors.bondType.message + ""
      }
      // {...register("bondType")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
      {/* DayCount */}
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.DayCountEnum.map((option: any) => option)}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setDayCount(newValue?.value);
    setValue("dayCountConvention",newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Day Count Convention"
      size='small'
      error={errors.dayCountConvention ? true : false}
      helperText={
        errors.dayCountConvention === undefined
          ? ""
          : errors.dayCountConvention.message + ""
      }
      // {...register("dayCountConvention")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
      {/* issuerName */}
<TextField
  id="outlined-basic"
  label="Issuer Name"
  variant="outlined"
  fullWidth
  size='small'
  error={errors.issuerName ? true : false}
  helperText={
    errors.issuerName === undefined
      ? ""
      : errors.issuerName.message + ""
  }
  {...register("issuerName")}
/>
</Grid>

<Grid item md={3}>
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.IssuerTypeEnum.map((option: any) => option)}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setIssuerType(newValue?.value);
    setValue("issuerType", newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Issuer Type"
      size='small'
      
      error={errors.issuerType ? true : false}
      helperText={
        errors.issuerType === undefined
          ? ""
          : errors.issuerType.message + ""
      }
      // {...register("issuerType")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
    {/* couponBasis */}
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.CouponBasisEnum.map((option: any) => option)}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setCouponBasis(newValue?.value);
    setValue("couponBasis",newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Coupon Basis"
      size='small'
      
      error={errors.couponBasis ? true : false}
      helperText={
        errors.couponBasis === undefined
          ? ""
          : errors.couponBasis.message + ""
      }
      // {...register("couponBasis")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
    {/* coupon */}
<TextField
  id="outlined-basic"
  label="Coupon"
  variant="outlined"
  fullWidth
  size='small'
  
  error={errors.coupon ? true : false}
  helperText={
    errors.coupon === undefined ? "" : errors.coupon.message + ""
  }
  {...register("coupon", { valueAsNumber: true })}
/>
</Grid>

<Grid item md={3}>
    {/* couponFreq */}
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.CouponFrequencyEnum.map(
    (option: any) => option
  )}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setCouponFreq(newValue?.value);
    setValue("couponFrequency",newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Coupon Frequency"
      size='small'
      
      error={errors.couponFrequency ? true : false}
      helperText={
        errors.couponFrequency === undefined
          ? ""
          : errors.couponFrequency.message + ""
      }
      // {...register("couponFrequency")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
    {/* call/put */}
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.CallPutDateEnum.map((option: any) => option)}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setCallPut(newValue?.value);
    setValue("callPutDate", newValue?.value)
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Call Put Date"
      size='small'
  
      error={errors.callPutDate ? true : false}
      helperText={
        errors.callPutDate === undefined
          ? ""
          : errors.callPutDate.message + ""
      }
      // {...register("callPutDate")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
    {/* callDate */}
 { callPut==="YES" ?
 (
 <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MobileDatePicker
      label="Call Date"
      inputFormat="DD/MM/YYYY"
      value={callDate}
      disabled = {callPut === "NO" || callPut === undefined}
      onChange={(newValue) => {
        setCallDate(newValue.toJSON());
        setValue("callDate",newValue.toJSON())
      }}
      renderInput={(params) => 
      <TextField 
        size="small" 
        {...params} 
        sx={{width:"100%"}}
        error={errors.callDate ? true : false}
        helperText={
        errors.callDate === undefined
          ? ""
          : errors.callDate.message + ""
      }
      />
    }
    />
  </LocalizationProvider> 
 )
 :
 (
  <TextField
        id="outlined-basic"
        label="Call Date"
        variant="outlined"
        disabled
        fullWidth
        size='small'
        
      />
 ) 
  } 
</Grid>

<Grid item md={3}>
    {/* PutDate */}
  { callPut==="YES" ?
  (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MobileDatePicker
      label="Put Date"
      inputFormat="DD/MM/YYYY"
      value={putDate}
      disabled = {callPut === "NO" || callPut === undefined}
      onChange={(newValue) => {
        setPutDate(newValue.toJSON());
        setValue("putDate",newValue.toJSON())
      }}
      renderInput={(params) => <TextField size="small" {...params} sx={{width:"100%", marginBottom:'20px'}}/>}
    />
  </LocalizationProvider> 
  ) 
  :
  (
    <TextField
        id="outlined-basic"
        label="Put Date"
        variant="outlined"
        disabled
        fullWidth
        size='small'
        
      />
  )
    }
</Grid>

<Grid item md={3}>
    {/* issuanceDate */}
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MobileDatePicker
      label="Issuance Date"
      inputFormat="DD/MM/YYYY"
      value={issuanceDate}
      onChange={(newValue) => {
        setIssuanceDate(newValue.toJSON());
        setValue("issuanceDate",newValue.toJSON())
      }}
      renderInput={(params) => 
      <TextField 
        size="small" 
        {...params} 
        sx={{width:"100%"}}
        error={errors.issuanceDate ? true : false}
        helperText={
        errors.issuanceDate === undefined
          ? ""
          : errors.issuanceDate.message + ""
      }
      />
    }
    />
  </LocalizationProvider>
</Grid>

<Grid item md={3}>
    {/* Maturity Date */}
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MobileDatePicker
      label="Maturity Date"
      inputFormat="DD/MM/YYYY"
      value={maturityDate}
      onChange={(newValue) => {
        setMaturityDate(newValue.toJSON());
        setValue("maturityDate",newValue.toJSON())
      }}
      renderInput={(params) => 
      <TextField 
        size="small" {...params} 
        sx={{width:"100%"}}
        error={errors.maturityDate ? true : false}
      helperText={
        errors.maturityDate === undefined
          ? ""
          : errors.maturityDate.message + ""
      }
      />
    }
    />
  </LocalizationProvider>
</Grid>

<Grid item md={3}>
    {/* faceValue */}
  <TextField
  id="outlined-basic"
  label="Face Value"
  variant="outlined"
  fullWidth
  size='small'
  
  error={errors.faceValue ? true : false}
  helperText={
    errors.faceValue === undefined
      ? ""
      : errors.faceValue.message + ""
  }
  {...register("faceValue", { valueAsNumber: true })}
/>
</Grid>

<Grid item md={3}>
    {/* SeniorityRepay */}
  <Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.RepaymentEnum.map((option: any) => option)}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setSeniority(newValue?.value);
    setValue("seniorityOfRepayment",newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Seniority Of Repayment"
      size='small'
      
      error={errors.seniorityOfRepayment ? true : false}
      helperText={
        errors.seniorityOfRepayment === undefined
          ? ""
          : errors.seniorityOfRepayment.message + ""
      }
      // {...register("seniorityOfRepayment")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
     {/* securedUnsecured */}
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.SecuredUnsecuredEnum.map(
    (option: any) => option
  )}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setSecured(newValue?.value);
    setValue("securedUnsecured",newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Secured Unsecured"
      size='small'
  
      error={errors.securedUnsecured ? true : false}
      helperText={
        errors.securedUnsecured === undefined
          ? ""
          : errors.securedUnsecured.message + ""
      }
      // {...register("securedUnsecured")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
    <Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.GaurentedEnum.map((option: any) => option)}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setGovernment(newValue?.value);
    setValue("governmentGauranteed",newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Government Gauranteed"
      size='small'
      
      error={errors.governmentGauranteed ? true : false}
      helperText={
        errors.governmentGauranteed === undefined
          ? ""
          : errors.governmentGauranteed.message + ""
      }
      // {...register("governmentGauranteed")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
    {/* Tier */}
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.TierEnum.map((option: any) => option)}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setTier(newValue?.value);
    setValue("tier",newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Tier"
      size='small'
      
      error={errors.tier ? true : false}
      helperText={
        errors.tier === undefined ? "" : errors.tier.message + ""
      }
      // {...register("tier")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
      {/* Psu/Nompsu */}
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.PsuNonPsuEnum.map((option: any) => option)}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setPsu(newValue?.value);
    setValue("psuNonPsu",newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="PSU Non-PSU"
      size='small'
      
      error={errors.psuNonPsu ? true : false}
      helperText={
        errors.psuNonPsu === undefined
          ? ""
          : errors.psuNonPsu.message + ""
      }
      // {...register("psuNonPsu")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
    <Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.TaxabilityEnum.map((option: any) => option)}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setTaxability(newValue?.value);
    setValue("taxability",newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Taxability"
      size='small'
      
      error={errors.taxability ? true : false}
      helperText={
        errors.taxability === undefined
          ? ""
          : errors.taxability.message + ""
      }
      // {...register("taxability")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
      {/* recordDays */}
<TextField
  id="outlined-basic"
  label="Record Days"
  variant="outlined"
  // defaultValue={"2023-02-22"}
  fullWidth
  size='small'
  
  error={errors.recordDate ? true : false}
  helperText={
    errors.recordDate === undefined
      ? ""
      : errors.recordDate.message + ""
  }
  {...register("recordDays", { valueAsNumber: true })}
/>
</Grid>

<Grid item md={3}>
      {/* modeOfIssuance */}
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.ModeOfIssuanceEnum.map((option: any) => option)}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setModeIssuance(newValue?.value);
    setValue("modeOfIssuance",newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Mode Of Issuance"
      size='small'
  
      error={errors.modeOfIssuance ? true : false}
      helperText={
        errors.modeOfIssuance === undefined
          ? ""
          : errors.modeOfIssuance.message + ""
      }
      // {...register("modeOfIssuance")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
    {/* Listed/Unlisted */}
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={bondEnum.ListedUnlistedEnum.map((option: any) => option)}
  getOptionLabel={(options: any) => options.label}
  onChange={(options: any, newValue) => {
    setListed(newValue?.value);
    setValue("listedUnlisted",newValue?.value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Listed Unlisted"
      size='small'
      
      error={errors.listedUnlisted ? true : false}
      helperText={
        errors.listedUnlisted === undefined
          ? ""
          : errors.listedUnlisted.message + ""
      }
      // {...register("listedUnlisted")}
    />
  )}
/>
</Grid>

<Grid item md={3}>
      {/* stepupStepDown */}
<TextField
  id="outlined-basic"
  label="Stepup Stepdown"
  variant="outlined"
  fullWidth
  size='small'
  
  error={errors.stepupStepDown ? true : false}
  helperText={
    errors.stepupStepDown === undefined
      ? ""
      : errors.stepupStepDown.message + ""
  }
  {...register("stepUpStepDown")}
/>
</Grid>

<Grid item md={3}>
    {/* remarks */}
<TextField
  id="outlined-basic"
  label="Remarks"
  variant="outlined"
  fullWidth
  size='small'
  
  error={errors.remarks ? true : false}
  helperText={
    errors.remarks === undefined ? "" : errors.remarks.message + ""
  }
  {...register("remarks")}
/>
</Grid>

<Grid item md={3}>
      {/* RtA */}
<TextField
  id="outlined-basic"
  label="RTA Details"
  variant="outlined"
  fullWidth
  size='small'
  
  error={errors.rtaDetails ? true : false}
  helperText={
    errors.rtaDetails === undefined
      ? ""
      : errors.rtaDetails.message + ""
  }
  {...register("rtaDetails")}
/>
</Grid>

<Grid item md={3}>
    {/* debentureTrustee */}
<TextField
  id="outlined-basic"
  label="Debenture Trustee"
  variant="outlined"
  fullWidth
  size='small'
  
  error={errors.debentureTrustee ? true : false}
  helperText={
    errors.debentureTrustee === undefined
      ? ""
      : errors.debentureTrustee.message + ""
  }
  {...register("debentureTrustee")}
/>
</Grid>

<Grid item md={3}>
      {/* interestPaymentDates */}
<TextField
  id="outlined-basic"
  label="Interest Payment Dates"
  variant="outlined"
  // defaultValue={"2023-02-22"}
  fullWidth
  size='small'
  
  error={errors.interestPaymentDates ? true : false}
  helperText={
    errors.interestPaymentDates === undefined
      ? ""
      : errors.interestPaymentDates.message + ""
  }
  {...register("interestPaymentDates")}
/>
</Grid>

<Grid item md={3}>
   {/* rating1 */}
<TextField
  id="outlined-basic"
  label="Rating1"
  variant="outlined"
  fullWidth
  size='small'
  
  error={errors.rating1 ? true : false}
  helperText={
    errors.rating1 === undefined ? "" : errors.rating1.message + ""
  }
  {...register("rating1")}
/> 
</Grid>

<Grid item md={3}>
   {/* rating2 */}
<TextField
  id="outlined-basic"
  label="Rating 2"
  variant="outlined"
  fullWidth
  size='small'
  
  error={errors.rating2 ? true : false}
  helperText={
    errors.rating2 === undefined ? "" : errors.rating2.message + ""
  }
  {...register("rating2")}
/> 
</Grid>

<Grid item md={3}>
    {/* rating3 */}
<TextField
  id="outlined-basic"
  label="Rating 3"
  variant="outlined"
  fullWidth
  size='small'
  
  error={errors.rating3 ? true : false}
  helperText={
    errors.rating3 === undefined ? "" : errors.rating3.message + ""
  }
  {...register("rating3")}
/>
</Grid>

<Grid item md={3}>
     {/* Abbrevation */}
<TextField
  id="outlined-basic"
  label="Abbrevation"
  variant="outlined"
  fullWidth
  size='small'
  
  error={errors.abbreviation ? true : false}
  helperText={
    errors.abbreviation === undefined
      ? ""
      : errors.abbreviation.message + ""
  }
  {...register("abbreviation")}
/>
</Grid>

<Grid item md={3}>
<Box marginBottom={3} textAlign={'initial'}>
<Button variant="contained" component='label' >
 Upload Logo
    <input hidden type="file" name='form' onChange={(event:any)=>setBondLogo(event.target.files[0])} style={{ marginBottom: "10px" }} />
</Button>
<Typography variant='caption' sx={{marginLeft:1}}>{bondLogo === undefined ? "No file choosen" : bondLogo.name }</Typography>
</Box>

<Box textAlign={'initial'}>
<Button variant='contained' component="label">
  Upload IM Document
    <input hidden type="file" name='form' onChange={(event:any)=>setImDoc(event.target.files[0])} style={{ marginBottom: "10px" }} />
</Button>
<Typography variant='caption'  sx={{marginLeft:1}}>{ ImDoc === undefined ? "No file choosen" : ImDoc.name }</Typography>
</Box>
</Grid>

<div style={{ textAlign: "center", width: "100%" , marginBottom:"4%" }}>
    <Button
      type="submit"
      color="primary"
      variant="contained"
      disabled = {showCircularProgress}
      sx={{ marginTop: "10px" }}
    >
      {showCircularProgress === true ? (
                <CircularProgress
                  color="secondary"
                  size={20}
                  thickness={5}
                />
              ) : (
                ""
              )}
      Submit
    </Button>
  </div>
</Grid>
        </form>
      </Box>
    </Container>
    </>
  );
};

export default AddBond;


// : !values.bondType
        // ? {
        //     bondType: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.callDate
        // ? {
        //     callDate: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.callPutDate
        // ? {
        //     callPutDate: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.coupon
        // ? {
        //     coupon: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.couponBasis
        // ? {
        //     couponBasis: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.couponFrequency
        // ? {
        //     couponFrequency: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.creditRating
        // ? {
        //     creditRating: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.dayCountConvention
        // ? {
        //     dayCountConvention: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.debentureTrustee
        // ? {
        //     debentureTrustee: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.faceValue
        // ? {
        //     faceValue: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.governmentGauranteed
        // ? {
        //     governmentGauranteed: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.interestPaymentDates
        // ? {
        //     interestPaymentDates: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.isApproved
        // ? {
        //     isApproved: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }s
        // : !values.isinNumber
        // ? {
        //     isinNumber: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.issuanceDate
        // ? {
        //     issuanceDate: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.issueSize
        // ? {
        //     issueSize: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.issuerName
        // ? {
        //     issuerName: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.issuerType
        // ? {
        //     issuerType: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.listedUnlisted
        // ? {
        //     listedUnlisted: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.maturityDate
        // ? {
        //     maturityDate: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.modeOfIssuance
        // ? {
        //     modeOfIssuance: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.psuNonPsu
        // ? {
        //     psuNonPsu: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.putDate
        // ? {
        //     putDate: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.rating1
        // ? {
        //     rating1: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.rating2
        // ? {
        //     rating2: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.rating3
        // ? {
        //     rating3: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.recordDate
        // ? {
        //     recordDate: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.remarks
        // ? {
        //     remarks: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.rtaDetails
        // ? {
        //     rtaDetails: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.securedUnsecured
        // ? {
        //     securedUnsecured: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.seniorityOfRepayment
        // ? {
        //     seniorityOfRepayment: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.stepupStepDown
        // ? {
        //     stepupStepDown: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.taxability
        // ? {
        //     taxability: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        // : !values.tier
        // ? {
        //     tier: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }