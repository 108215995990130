import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import "../css/disclaimer.css"

const Disclaimer = () => {
  return (
    <Container maxWidth="lg">
        <Box sx={{marginTop:'10%'}}>
        <div className="disc-content-wrapper">

                        <a className="" href="/">       
                        <div className="disc-backHome"><ArrowBackIosIcon/> Go Back </div></a>

                        <h5 style={{textAlign:'left'}}> DISCLAIMER</h5>
                        <p>Please read this information carefully. Access to this website and/or App or any of its associate group sites ("investvalue.ai"), is a confirmation that you have read, understood, and agree to be legally bound by the terms of the following disclaimer and user agreement.</p>
                        

                        <p><strong>INVEST VALUE FINTECH PRIVATE LIMITED ("investvalue")</strong> is engaged in providing financial Intermediation and related services in India. Intermediation and related services in India. <strong>investvalue </strong>is a mutual fund distributor registered with AMFI with ARN-239464.  In accordance with the applicable laws, <strong>investvalue</strong> is permitted to only render incidental advice with respect to mutual fund products only to its mutual fund distribution client. For every other purpose, including distribution of non-mutual fund products, all material displayed on the Website is for informational purposes only. </p>

                        <p>No material /information contained on the Website constitutes or shall be interpreted as investment advice, offer, solicitation or advertisement with respect to the purchase or sale of or to deal with any security and no part of it shall form the basis of or be relied upon in connection with any contract or commitment whatsoever.</p>
                        <p><strong>investvalue </strong> has taken due care and caution in the compilation of the data and the contents for its Website and Online Desks. However, investvalue does not guarantee the suitability, accuracy, adequacy, or completeness of any information and is not responsible for any errors or omissions for the results obtained from the use of such information. <strong>investvalue</strong> specifically states that it cannot be held liable for any loss or damages (monetary, legal, or otherwise) caused by any error, omission, interruption, deletion, defect, failure, technical reasons, system downtime network failure, or other delays or rejection by other parties like Bank, Mutual Fund or AMC and that it has no financial liability whatsoever to any user on account of the use of the information provided on its Website. <strong>investvalue</strong> does not provide any warranties (whether expressed or implied), as to the quality, accuracy, efficacy, completeness, performance, fitness, or any of the contents of the Website, including (but not limited) to any comments, feedback, and advertisements contained within the Website</p>

                        <p><strong>investvalue </strong>strongly recommends its users to verify the veracity /appropriateness of the information before making any decisions. The information on this Website is updated from time to time. <strong>investvalue </strong>is also not responsible for any errors, omissions, or representations on any of its pages or on any links on any of its pages. <strong>investvalue </strong>does not endorse in any way any advertisers on its web pages. This Website contains articles contributed by individuals. The views are exclusively their own and do not necessarily represent the views of the Website owners or its management. The linked sites are not under <strong>investvalue’s</strong> control and <strong>investvalue</strong> is not responsible for the contents of any linked site or any link contained in a linked site, or any changes or updates to such sites. <strong>investvalue</strong> is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by <strong>investvalue</strong> or the Website.</p>
                        
                        <p><strong>investvalue</strong> hereby expressly disclaims any implied warranties imputed by the laws of any jurisdiction. <strong>investvalue</strong> considers itself and intends to be governed by the exclusive jurisdiction of the courts of Surat, Mumbai City in India. If you don't agree with any of the disclaimers above, please do not read the material on any of the pages and exit the Website immediately. This Website is specifically for users in the territory of India. Although access to users outside India is not denied, <strong>investvalue</strong> shall have no legal liabilities whatsoever in any laws of any jurisdiction other than India. <strong>investvalue</strong> reserves the right to make changes to its Website, including but not limited to the Terms of Use, Disclaimers, and Privacy Policy contained herein.</p>

                        <p>Past Performance Is Not Indicative of Future Returns. Mutual Funds and securities investments are subject to market risks and there is no assurance or guarantee that the objective of the Scheme will be achieved. Past performance of the Sponsor/AMC/Fund or that of any scheme of the Fund does not indicate the future performance of the Schemes of the Fund. Please read the Key Information Memorandum and the Offer Document carefully before investing.</p>
                    </div>

        </Box>
    </Container>
  )
}

export default Disclaimer