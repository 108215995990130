import { Autocomplete, Box, Button, Chip, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { useQuery } from 'react-query'
import { GetAllClient, GetUnlistedDetails, GetUnlistedOrderByClientID, product_listOfAllProductType } from '../../api/Api'
import axios from 'axios'
import TermSheetTable from './TermSheetTable'
import UploadTermSheet from './UploadTermSheet/UploadTermSheet'
import { Controller, useForm } from "react-hook-form";
import MultiSelect from '../../utils/MultiSelect'

const TermSheetGeneration = () => {


  const [setTermSheetData, setSetTermSheetData] = React.useState([])
  const [clientData, setClientData] = React.useState(null)
  const [productlist, setproductlist] = React.useState<any>([]);

  const { data: getOrderData } = useQuery(["UnlistedOrderByClientID", clientData, productlist.productTypeCode], () => GetUnlistedOrderByClientID(clientData, productlist.productTypeCode), { enabled: !!clientData })
  const { data: getClientData } = useQuery("getAllClients", () => GetAllClient());
  const { control } = useForm({ mode: "onChange" });
  const [ProductList, setProductList] = React.useState([]);
  const { data: listOfAllProductType } = useQuery(["ListOfAllProductTypes"], product_listOfAllProductType, { onSuccess: (data: any) => { setProductList(data.data.productType) } })
 
  return (
    <>
      <Box sx={{ minHeight: "calc(100vh - 150px)", margin: "3%" }}>
        <Typography gutterBottom variant='h4'>Term Sheet</Typography>
        {getClientData && (
          <>
            <Grid container rowSpacing={2} columnSpacing={2} sx={{mb:2}}>
              <Grid item xs={12} sm={12} md={4} lg={3} >
                <Autocomplete
                  id="tags-outlined"
                  options={getClientData.data.clients.map((option: any) => option)}
                  getOptionLabel={(options: any) => options.fullName + " - " + options.panCardNo}
                  onChange={(options: any, newValue: any) => {
                    setClientData(newValue?.clientId)
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Client"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3} >
                <Autocomplete
                  disableClearable
                  id="combo-box-demo1"
                  getOptionLabel={(options: any) =>
                    options.productName
                  }
                  // options={ProductList.map((options: any) => options)}
                  options={ProductList.filter((option:any) => option.productTypeCode !== 3  && option.productTypeCode !==7 && option.productTypeCode !==8 && option.productTypeCode !==9 && option.productTypeCode !==10 && option.productTypeCode !==12).map((options: any) => options)}
                  onChange={(options: any, newValue: any) => {
                    setproductlist(
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Products"
                    // error={ProductList?true:false}
                    />
                  )}
                />
              </Grid>
            </Grid>


            <div style={{ height: 500, width: '100%' }}>

              {getOrderData ? <TermSheetTable getOrderData={getOrderData} /> : <></>}

            </div>
          </>
        )}
      </Box>
    </>
  )
}

export default TermSheetGeneration