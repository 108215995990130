import React from "react";
import { withParam } from "../../utils/Router.Helper";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useId } from "react";
import { styled } from "@mui/material/styles";
import { useQuery } from "react-query";
import {
  AifGetById,
  GetAllClient,
  GetBrokeragePayoutByCompanyMasterCode,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  PmsGetById,
  listOfManufactureNDistributorCompanies,
} from "../../api/Api";
import PersonIcon from "@mui/icons-material/Person";
import ReactApexChart from "react-apexcharts";
import Loading from "../../Constants/Loading";
import ErrorPage from "../../Constants/ErrorPage";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CurrencyFormate, changeDate, convertDate } from "../../utils/Helper";
import { productTypeEnum } from "../../Constants/ProductTypeEnum";
import { CREATE_NEW_ORDER, SAVE_PROPOSAL } from "../../utils/API_Names";
import ClientModalForOrder from "../ClientModalForOrder";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { IncomeTypeForPMS } from "../../Constants/IncomeTypeForPMS";

export const TestComponet = (Props: any) => {
  console.log("props of test", Props);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return <Item>{Props.children}</Item>;
};

const PMSScreener = (props: any) => {
  const schemeCode = props.params.schemeCode;

  const [open, setOpen] = React.useState(false);
  const [investNowModalOpen, setInvestNowModalOpen] = React.useState(false);
  const [companyMasterCode, setCompanyMasterCode] = React.useState();
  const [companyTypeCode, setCompanyTypeCode] = React.useState();
  const [distributorCode, setDistributorCode] = React.useState("");
  const [distributorUserCode, setDistributorUserCode] = React.useState("");
  const [orderDate, seOrderDate] = React.useState(new Date());
  const [clientId, setClientId] = React.useState("");
  const [investmentAmount, setInvestmentAmount] = React.useState("");
  const [focusedFund, setFocusedFund] = React.useState<any>(false);
  const [isActive, setIsActive] = React.useState<any>(false);
  const [incomeType, setIncomeType] = React.useState<any>(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const [clientDetails, setClientDetails] = React.useState<any>("");
  const [uploadForm, setUploadForm] = React.useState<any>();
  const [uploadFormRefNo, setUploadFormRefNo] = React.useState("");
  const [OrderNowBtn, setOrderNowBtn] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["PMSGetById", schemeCode],
    () => PmsGetById(schemeCode),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: false,
      onSuccess(data) {
        setFocusedFund(data?.data?.pmsDTOList[0]?.isFocusedFund);
        setIsActive(data?.data?.pmsDTOList[0]?.isActive);
        setValue("buyRate", data?.data?.pmsDTOList[0]?.schemeMinInvestment);
        setIncomeType(data?.data?.pmsDTOList[0]?.incomeType);
        setValue2("managementFees", data?.data?.pmsDTOList[0]?.managementFees);
        setValue2(
          "revenueCreditFee",
          data?.data?.pmsDTOList[0]?.revenueCreditFee
        );
      },
      // staleTime: Infinity,
    }
  );

  const { data: clientData } = useQuery(
    ["getAllClients", companyMasterCode, distributorCode],
    () => GetAllClient(companyMasterCode, distributorCode),
    {
      enabled: !!companyMasterCode || sessionStorage.getItem("Profile") === "D",
    }
  );

  const { data: brokerageData } = useQuery(
    ["GetBrokeragePayoutByCompanyMasterCode", companyMasterCode],
    () =>
      GetBrokeragePayoutByCompanyMasterCode(
        sessionStorage.getItem("Profile") === "D"
          ? sessionStorage.getItem("CompanyMasterCode")
          : companyMasterCode
      ),
    {
      enabled: !!companyMasterCode || sessionStorage.getItem("Profile") === "D",
    }
  );

  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompanie",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled:
        sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E",
      staleTime: Infinity,
    }
  );

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", companyMasterCode, companyTypeCode],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        companyTypeCode === "S" ? "E" : companyTypeCode,
        companyMasterCode
      ),
    {
      enabled: !!companyMasterCode,
    }
  );

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    formState: { errors: error2 },
    setValue: setValue2,
  } = useForm({ mode: "onChange" });

  console.log("watch2 :", watch2());

  const registerOptions = {
    commitmentAmount: {
      required: "Please Enter Valid Amount",
      pattern: {
        value: /^[0-9]+$/,
        message: "Invalid Amount",
      },
      validate: {
        positive: (v: any) =>
          parseInt(v) >= data.data.pmsDTOList[0]?.schemeMinInvestment ||
          `Please Enter A Amount More then ${data.data.pmsDTOList[0]?.schemeMinInvestment}`,
      },
    },
    selectedDate: {
      required: "Select a Date",
    },
    clients: { required: "Select a Client" },
  };

  const chartData: any = data && {
    options: {
      chart: {
        type: "bar",
        height: 450,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["1M", "3M", "6M", "1Y", "3Y", "5Y", "Since Inception"],
      },
      yaxis: {
        title: {
          text: "% (Percentage)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val + " %";
          },
        },
      },
    },
    legend: {
      // position: '',
      width: 400,
      // position: 'top',
    },
    series: [
      {
        name: "Fund",
        data: [
          data.data.pmsDTOList[0].fundPerformance?.schemeRet1m,
          data.data.pmsDTOList[0].fundPerformance?.schemeRet3m,
          data.data.pmsDTOList[0].fundPerformance?.schemeRet6m,
          data.data.pmsDTOList[0].fundPerformance?.schemeRet1y,
          data.data.pmsDTOList[0].fundPerformance?.schemeRet3y,
          data.data.pmsDTOList[0].fundPerformance?.schemeRet5y,
          data.data.pmsDTOList[0].fundPerformance?.schemeRetInce,
        ],
      },
      {
        name: "BenchMark",
        data: [
          data.data.pmsDTOList[0].fundPerformance?.bmRet1m,
          data.data.pmsDTOList[0].fundPerformance?.bmRet3m,
          data.data.pmsDTOList[0].fundPerformance?.bmRet6m,
          data.data.pmsDTOList[0].fundPerformance?.bmRet1y,
          data.data.pmsDTOList[0].fundPerformance?.bmRet3y,
          data.data.pmsDTOList[0].fundPerformance?.bmRet5y,
          data.data.pmsDTOList[0].fundPerformance?.bmRetInce,
        ],
      },
    ],
  };

  const donutData: any = data && {
    series: [
      data.data.pmsDTOList[0].fundComposition?.cash || 0,
      data.data.pmsDTOList[0].fundComposition?.debt || 0,
      data.data.pmsDTOList[0].fundComposition?.equity || 0,
    ],
    options: {
      // colors: ['#F44336', '#E91E63', '#9C27B0'],
      chart: {
        type: "donut",
      },
      labels: ["Cash", "Debt", "Equity"],
      legend: {
        formatter: function (val: any, opts: any) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
        },
        position: "bottom",
      },
      dataLabels: {
        enabled: true,
        // formatter: function(val:any, opts:any) {
        //     return val.toFixed(2) + "% - " + opts.w.globals.series[opts.seriesIndex]
        //   },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const barData: any = data && {
    series: [
      {
        data: !!data.data.pmsDTOList[0].fundSectors
          ? data.data.pmsDTOList[0]?.fundSectors.map((sector: any) => {
              return sector?.sectorWeight;
            })
          : [],
      },
    ],
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: !!data.data.pmsDTOList[0].fundSectors
        ? data.data.pmsDTOList[0].fundSectors.map((sector: any) => {
            return sector?.sectorName;
          })
        : [],
    },
  };

  const riskBarData: any = data && {
    series: [
      {
        data: [
          data.data.pmsDTOList[0].fundRiskRatios?.standardDeviation1Y,
          data.data.pmsDTOList[0].fundRiskRatios?.sharpeRatio1Y,
          data.data.pmsDTOList[0].fundRiskRatios?.beta1Y,
          data.data.pmsDTOList[0].fundRiskRatios?.rsquare1Y,
        ],
      },
      {
        data: [
          data.data.pmsDTOList[0].fundRiskRatios?.standardDeviation3Y,
          data.data.pmsDTOList[0].fundRiskRatios?.sharpeRatio3Y,
          data.data.pmsDTOList[0].fundRiskRatios?.beta3Y,
          data.data.pmsDTOList[0].fundRiskRatios?.rsquare1Y,
        ],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -3,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: ["Standard Deviation", "Sharp ratio", "Beta", "R-Square"],
      },
    },
  };

  const onsubmit = (updateData: any) => {
    var UpdateAIfDto = {
      ...updateData,
      productMasterCode: data.data.pmsDTOList[0].productMasterCode,
      isFocusedFund: focusedFund,
      isActive: isActive,
      incomeType: incomeType,
      trailDate: incomeType === "upfront" ? null : changeDate(selectedDate),
    };
    axios
      .put("pms/pmsFundFeeUpdate", UpdateAIfDto)
      .then((res: any) => toast.success("Update successfully"))
      .then(() => window.location.reload())
      .catch((err) =>
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        )
      );
  };

  // const changeStatus = () => {
  //   axios.put("pms/activeInActive?productMasterCode=" + data.data.pmsDTOList[0].productMasterCode).then((res: any) => { refetch(); toast.success("Success") }).catch((err: any) => toast.error(err?.response?.data?.status?.message?.title ? err?.response?.data?.status?.message?.title : err?.response?.data?.title))
  // }

  React.useEffect(() => {
    if (uploadForm) {
      setOrderNowBtn(true);
      const formData = new FormData();
      formData.append("document", uploadForm);
      axios.post("documentupload", formData).then((res: any) => {
        setUploadFormRefNo(res.data.documentId);
        setOrderNowBtn(false);
      });
    }
  }, [uploadForm]);

  let amt: any = null;
  const onSubmitInvestNow = (dataa: any) => {
    let PmsInvestNowdto = {
      productMasterCode: data.data.pmsDTOList[0].productMasterCode,
      clientId: clientId,
      version: "1",
      productTypeCode: productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM,
      orderDate: changeDate(orderDate),
      distCompanyMasterCode: companyMasterCode,
      distEmployeeCode: distributorCode,
      schemeCode: data.data.pmsDTOList[0].schemeCode,
      buyRate: !!dataa.buyRate
        ? dataa.buyRate
        : data.data.pmsDTOList[0].schemeMinInvestment,
      commitmentAmount: dataa?.commitmentAmount,
      acknowledgementReferenceNo:
        dataa?.acknowledgementNo === "" ? null : dataa?.acknowledgementNo,
      fromRefNo: uploadFormRefNo === "" ? null : uploadFormRefNo,
      trailAmount: amt,
    };
    axios
      .post(CREATE_NEW_ORDER, PmsInvestNowdto)
      .then((res: any) => {
        res && toast.success(res?.data?.status?.message?.title);
        window.location.reload();
      })
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };

  const addToProposal = () => {
    let proposalDTO = {
      clientId: clientId,
      productMasterCode: data.data.pmsDTOList[0].productMasterCode,
      productTypeCode: productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM,
      clientPrice: !!investmentAmount
        ? investmentAmount
        : data.data.pmsDTOList[0].schemeMinInvestment,
      disCompanyMasterCode: !!companyMasterCode ? companyMasterCode : "",
      disUserCode: !!distributorUserCode ? distributorUserCode : "",
    };
    axios
      .post(SAVE_PROPOSAL, proposalDTO)
      .then(
        (res: any) => res && toast.success(res?.data?.status?.message?.title)
      )
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };

  const handleFocusedFund = () => {
    setFocusedFund(!focusedFund);
  };

  const handleIsActive = () => {
    setIsActive(!isActive);
  };

  const pmsBrokerage =
    brokerageData &&
    brokerageData.data?.companyBrokerage?.filter(
      (item: any) =>
        item?.productTypeCode === productTypeEnum?.PORTFOLIO_MANAGEMENT_SYSTEM
    )[0];

  let revenueRate =
    data &&
    (data.data.pmsDTOList[0].managementFees *
      data.data.pmsDTOList[0].revenueCreditFee) /
      100;
  let disRevenueRate: any;
  let brokerRevenueRate: any;
  if (companyTypeCode === "D" || sessionStorage.getItem("Profile") === "D") {
    disRevenueRate =
      (revenueRate * pmsBrokerage?.brokerageValueForCustomer) / 100;
    brokerRevenueRate = revenueRate - disRevenueRate;
  } else {
    brokerRevenueRate = revenueRate;
  }

  let userInvestmentAmount = watch("buyRate");
  let ManagementFeesAmount =
    data &&
    (userInvestmentAmount * data.data.pmsDTOList[0].managementFees) / 100;
  let RevenueCreditAmount =
    data &&
    (ManagementFeesAmount * data.data.pmsDTOList[0].revenueCreditFee) / 100;
  let income_Type = data?.data?.pmsDTOList[0]?.incomeType || null;

  let trailAmount: any = null;
  let empRevenueAmount: any;
  if (income_Type === "trail") {
    const revenuePerDay: any = RevenueCreditAmount / 365;
    let now = new Date(orderDate);
    let monthap = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    let daysRemaining = monthap.getDate();
    let amount = revenuePerDay * daysRemaining;
    if (companyTypeCode === "D" || sessionStorage.getItem("Profile") === "D") {
      amt = (amount * pmsBrokerage?.brokerageValueForCustomer) / 100;
      trailAmount = CurrencyFormate(amt);
      amt = amt.toFixed(2);
      empRevenueAmount = CurrencyFormate(amount - amt);
    } else {
      amt = amount;
      trailAmount = CurrencyFormate(amt);
      amt = amt.toFixed(2);
    }
  } else {
    trailAmount = RevenueCreditAmount / 12;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorPage width={"100%"} height={"auto"} />;
  }

  return (
    <Box sx={{ marginTop: "20px" }}>
      {/* <ErrorPage width={"100%"} height={"auto"}/> */}
      {data &&
        data.data.pmsDTOList.map((item: any) => (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Typography variant="h5">{item?.schemeName || "-"}</Typography>
              <Box>
                <Button
                  onClick={() => setInvestNowModalOpen(true)}
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  Order Now
                </Button>
                {sessionStorage.getItem("Profile") === "B" ? (
                  <Button onClick={handleOpen} variant="outlined">
                    <EditIcon fontSize="small" /> Edit Fees
                  </Button>
                ) : (
                  <></>
                )}
              </Box>
            </Box>

            <Grid
              container
              rowGap={4}
              sx={{
                margin: "0 auto",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  justifyContent: "space-evenly",
                  margin: "0 auto",
                }}
              >
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <img
                    src={item?.amcInfoDTO?.amcLogo}
                    width={200}
                    alt="AMC logo"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <Typography variant="h5">Benchmark</Typography>
                  <Typography variant="h6" color="primary">
                    {item.schemeBenchmarkName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <Typography variant="h5">Min Investment</Typography>
                  <Typography variant="h6" color="primary">
                    {item.schemeMinInvestment?.toLocaleString("en-IN")
                      ? "₹ " + item.schemeMinInvestment?.toLocaleString("en-IN")
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <Typography variant="h5">Inception Date</Typography>
                  <Typography variant="h6" color="primary">
                    {item.schemeInceptionDate || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <Typography variant="h5">As On</Typography>
                  <Typography variant="h6" color="primary">
                    {item.fundPerformance?.date || "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  justifyContent: "space-evenly",
                  margin: "0 auto",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  sx={{ justifyContent: "center" }}
                >
                  <Button
                    variant="contained"
                    sx={{ width: "80%" }}
                    color="secondary"
                  >
                    {item.schemeClassification || "-"} | {item.schemePlanType}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={9} textAlign={"left"}>
                  <Typography variant="subtitle2">
                    {item.schemeObjective || "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ margin: "0 auto" }}>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Typography variant="subtitle1">
                    Exit Load / Lock-in
                  </Typography>
                  <Typography variant="subtitle2">
                    {item.schemeExitLoad || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={9} textAlign={"left"}>
                  <Typography variant="subtitle1">Fees Structure</Typography>
                  <Typography variant="subtitle2">
                    {item.schemeFeeStructure || "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ margin: "0 auto" }}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Paper
                    elevation={8}
                    sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
                  >
                    <Grid container sx={{ justifyContent: "space-evenly" }}>
                      <Grid item xs={12} sm={2} md={2} lg={2}>
                        <Typography variant="h6">NAV</Typography>
                        <Typography>
                          {!!item.fundPerformance?.nav
                            ? "₹" + item.fundPerformance?.nav
                            : "-"}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs={12} sm={2} md={2} lg={2}>
                        <Typography variant="h6">1 Month Change</Typography>
                        <Typography
                          color={
                            item.fundPerformance?.activeReturns1month > 0
                              ? "Green"
                              : "Red"
                          }
                        >
                          {item.fundPerformance?.activeReturns1month > 0 ? (
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <ArrowDropUpIcon />{" "}
                              {item.fundPerformance?.activeReturns1month || "-"}
                              %
                            </Box>
                          ) : (
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <ArrowDropDownIcon />{" "}
                              {item.fundPerformance?.activeReturns1month}
                            </Box>
                          )}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs={12} sm={2} md={2} lg={2}>
                        <Typography variant="h6">Since Inception</Typography>
                        <Typography
                          color={
                            item.fundPerformance?.schemeRetInce > 0
                              ? "Green"
                              : "Red"
                          }
                        >
                          {item.fundPerformance?.schemeRetInce > 0 ? (
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <ArrowDropUpIcon />{" "}
                              {item.fundPerformance?.schemeRetInce || "-"}%
                            </Box>
                          ) : (
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <ArrowDropDownIcon />{" "}
                              {item.fundPerformance?.schemeRetInce}
                            </Box>
                          )}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs={12} sm={2} md={2} lg={2}>
                        <Typography variant="h6">Fund AUM(Cr.)</Typography>
                        <Typography>
                          {!!item.fundPerformance.aum
                            ? "₹" + item.fundPerformance.aum
                            : "-"}
                        </Typography>
                        <Typography>
                          Fund AUM as on:
                          <br />
                          {!!item.fundPerformance.date
                            ? item.fundPerformance.date
                            : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Paper
                    elevation={8}
                    sx={{
                      borderRadius: "20px",
                      aifscpadding: "10px",
                      height: 410,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Portfolio Characteristic
                    </Typography>
                    <Grid container sx={{ padding: "0 16px" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Total Stocks :{" "}
                        </Typography>
                        <Typography variant="subtitle1">
                          {item.portfolioCharacteristics?.totalStocks || "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Avg Market cap :{" "}
                        </Typography>
                        <Typography variant="subtitle1">
                          {item.portfolioCharacteristics?.avgMktCap
                            ? (item.portfolioCharacteristics.avgMktCap >= 1000
                                ? item.portfolioCharacteristics.avgMktCap.toLocaleString()
                                : item.portfolioCharacteristics.avgMktCap) +
                              " (Cr.)"
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Dividend Yield :{" "}
                        </Typography>
                        <Typography variant="subtitle1">
                          {item.portfolioCharacteristics?.dividendYield || "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Portfolio P/E ratio:{" "}
                        </Typography>
                        <Typography variant="subtitle1">
                          {item.portfolioCharacteristics?.pe || "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">Age:</Typography>
                        <Typography variant="subtitle1">
                          {item.portfolioCharacteristics?.avg_mkt_cap || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Typography variant="h6">Fund Manager</Typography>
                    <Grid container sx={{ justifyContent: "center" }}>
                      {item?.fundManagers?.map((fundManager: any) => (
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <PersonIcon />
                          <Typography>
                            {fundManager?.fundManagerName}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ margin: "0 auto" }}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Paper
                    elevation={8}
                    sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
                  >
                    <Typography variant="h6">Over View</Typography>
                    {!!item.fundPerformance ? (
                      <ReactApexChart
                        options={chartData.options}
                        series={chartData.series}
                        type="bar"
                        height={350}
                      />
                    ) : (
                      <img
                        src={
                          "https://neofindesklocal.s3.us-east-2.amazonaws.com/NO_DATA_FOUND.svg"
                        }
                        alt="No data Found"
                        width={350}
                        height={350}
                      />
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Paper
                    elevation={8}
                    sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
                  >
                    <Typography variant="h6">Performance</Typography>
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Returns</TableCell>
                            <TableCell align="right">Fund</TableCell>
                            <TableCell align="right">Benchmark</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              1 Month
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.schemeRet1m || "-"}
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.bmRet1m || "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              3 Month
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.schemeRet3m || "-"}
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.bmRet3m || "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              6 Month
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.schemeRet6m || "-"}
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.bmRet6m || "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              1 Year
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.schemeRet1y || "-"}
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.bmRet1y || "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              3 Year
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.schemeRet3y || "-"}
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.bmRet3y || "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              5 Year
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.schemeRet5y || "-"}
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.bmRet5y || "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              Since Inception
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.schemeRetInce || "-"}
                            </TableCell>
                            <TableCell align="right">
                              {item.fundPerformance?.bmRetInce || "-"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ margin: "0 auto" }}>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Paper
                    elevation={8}
                    sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
                  >
                    <Typography variant="h6">Composition ( % )</Typography>
                    {!!item.fundComposition ? (
                      <ReactApexChart
                        options={donutData.options}
                        series={donutData.series}
                        type="donut"
                        height={350}
                      />
                    ) : (
                      <img
                        src={
                          "https://neofindesklocal.s3.us-east-2.amazonaws.com/NO_DATA_FOUND.svg"
                        }
                        alt="No data Found"
                        width={350}
                        height={350}
                      />
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={8}>
                  <Paper
                    elevation={8}
                    sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
                  >
                    <Typography variant="h6">Risk Analysis</Typography>
                    {!!data.data.pmsDTOList[0].fundRiskRatios ? (
                      <ReactApexChart
                        options={riskBarData.options}
                        series={riskBarData.series}
                        type="bar"
                        height={350}
                      />
                    ) : (
                      <>
                        <img
                          src={
                            "https://neofindesklocal.s3.us-east-2.amazonaws.com/NO_DATA_FOUND.svg"
                          }
                          alt="No data Found"
                          width={350}
                          height={350}
                        />
                      </>
                    )}
                  </Paper>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ margin: "0 auto" }}>
                <Grid item xs={12} sm={12} md={6} lg={8}>
                  <Paper
                    elevation={8}
                    sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
                  >
                    <Typography variant="h6">Sector weights</Typography>
                    {!!data.data.pmsDTOList[0].fundSectors ? (
                      <ReactApexChart
                        options={barData}
                        series={barData.series}
                        type="bar"
                        height={350}
                      />
                    ) : (
                      <>
                        <img
                          src={
                            "https://neofindesklocal.s3.us-east-2.amazonaws.com/NO_DATA_FOUND.svg"
                          }
                          alt="No data Found"
                          width={350}
                          height={350}
                        />
                      </>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Paper
                    elevation={8}
                    sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
                  >
                    <Typography variant="h6">
                      Holdings as on : {item.fundPerformance?.date || "-"}
                    </Typography>
                    {/* <TableCell>{ window.screen.height+ "-" +window.screen.width }</TableCell> */}
                    {!!item.fundHolding ? (
                      <TableContainer>
                        <Table
                          sx={{ minWidth: 50 }}
                          aria-label="simple table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Holdings Company</TableCell>
                              <TableCell align="right">Wts%</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.fundHolding.map((holdingData: any) => (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  padding: "8px",
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {holdingData?.securityName}
                                </TableCell>
                                <TableCell align="right">
                                  {holdingData.securityWeight}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <img
                        src={
                          "https://neofindesklocal.s3.us-east-2.amazonaws.com/NO_DATA_FOUND.svg"
                        }
                        alt="No data Found"
                        width={"fit-content"}
                        height={400}
                      />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {" "}
                      Income Type :{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <form onSubmit={handleSubmit(onsubmit)} id="form1">
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={IncomeTypeForPMS.map(
                          (options: any) => options
                        )}
                        getOptionLabel={(options: any) => options.incomeType}
                        defaultValue={
                          IncomeTypeForPMS.find(
                            (option: any) =>
                              option.incomeTypes ===
                              data.data.pmsDTOList[0].incomeType
                          ) || null
                        }
                        fullWidth
                        onChange={(options: any, newValue) => {
                          setIncomeType(newValue?.incomeTypes);
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Select Income Type"
                            fullWidth
                          />
                        )}
                      />
                    </form>
                  </Grid>
                </Grid>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Fees Details
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <form onSubmit={handleSubmit2(onsubmit)}>
                        <TextField
                          id="outlined-basic"
                          label="Management Fee (%)"
                          fullWidth
                          variant="outlined"
                          defaultValue={
                            data && data.data.pmsDTOList[0].managementFees
                          }
                          error={error2.managementFees ? true : false}
                          helperText={
                            !error2.managementFees
                              ? ""
                              : error2.managementFees.message + ""
                          }
                          {...register2("managementFees", {
                            valueAsNumber: true,
                            validate: {
                              positive: (v: any) =>
                                parseFloat(v) > 0 ||
                                "Fixed Fee Should be Greater Than 0",
                            },
                          })}
                        />
                      </form>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <form onSubmit={handleSubmit2(onsubmit)}>
                        <TextField
                          id="outlined-basic"
                          label="Income (%)"
                          variant="outlined"
                          fullWidth
                          defaultValue={
                            data && data.data.pmsDTOList[0].revenueCreditFee
                          }
                          error={error2.revenueCreditFee ? true : false}
                          helperText={
                            !error2.revenueCreditFee
                              ? ""
                              : error2.revenueCreditFee.message + ""
                          }
                          {...register2("revenueCreditFee", {
                            valueAsNumber: true,
                            validate: {
                              positive: (v: any) =>
                                parseFloat(v) > 0 ||
                                "Management Fee Should be Greater Than 0",
                            },
                          })}
                        />
                      </form>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography>
                        {" "}
                        Revenue (%):{" "}
                        {!isNaN(
                          (watch2("managementFees") *
                            watch2("revenueCreditFee")) /
                            100
                        )
                          ? (
                              (watch2("managementFees") *
                                watch2("revenueCreditFee")) /
                              100
                            ).toFixed(2)
                          : 0}{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography>Status</Typography>
                      <Switch
                        checked={isActive}
                        onChange={handleIsActive}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <Typography>Is Focused Fund</Typography>
                      <Switch
                        checked={focusedFund}
                        onChange={handleFocusedFund}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                        
                      </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <form onSubmit={handleSubmit2(onsubmit)}>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ mb: "1" }}
                          onClick={handleSubmit2(onsubmit)}
                        >
                          {" "}
                          Submit{" "}
                        </Button>
                      </form>
                    </Grid>
                  </Grid>
                </Typography>
              </Box>
            </Modal>

            <Modal
              open={investNowModalOpen}
              onClose={() => setInvestNowModalOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography variant="h6" textAlign={"center"} gutterBottom>
                  Order Details
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <Typography variant="h5" textAlign={"center"} gutterBottom>
                  {item.schemeName || "-"}
                </Typography>
                <Grid container textAlign={"center"}>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <img
                      src={item?.amcInfoDTO?.amcLogo}
                      width={100}
                      alt="AMC logo"
                    />
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Typography variant="subtitle1">Min Investment</Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {item.schemeMinInvestment?.toLocaleString("en-IN") ? "₹ " + item.schemeMinInvestment?.toLocaleString("en-IN") :
                        "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Typography variant="subtitle1">As On</Typography>
                    <Typography variant="subtitle2" >
                      {item.fundPerformance?.date || "-"}
                    </Typography>
                  </Grid>
                </Grid> */}

                <Grid container spacing={2} textAlign={"center"} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                    <Typography variant="subtitle1">Min Investment</Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {item.schemeMinInvestment?.toLocaleString("en-IN")
                        ? "₹ " +
                          item.schemeMinInvestment?.toLocaleString("en-IN")
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                    <Typography variant="subtitle1">Benchmark</Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {item.schemeBenchmarkName
                        ? item.schemeBenchmarkName
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                    <Typography variant="subtitle1">AUM</Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {item.fundPerformance.aum
                        ? item.fundPerformance.aum
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                    <Typography variant="subtitle1">NAV</Typography>
                    <Typography variant="subtitle2">
                      {item.fundPerformance?.nav}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2, ml: 12 }}>
                  <Grid item xs={12} sm={3} md={3} lg={10}>
                    <Typography variant="subtitle1" align="center">
                      Fund Objective
                    </Typography>
                    <Typography variant="subtitle2" align="center">
                      {item.schemeObjective || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} textAlign={"center"} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                    <Typography variant="subtitle1">Inception Date</Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {item.schemeInceptionDate
                        ? item.schemeInceptionDate
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Typography variant="subtitle1">
                      Exit Load / Lock-in
                    </Typography>
                    <Typography variant="subtitle2">
                      {item.schemeExitLoad || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                    <Typography variant="subtitle1">Fees Structure</Typography>
                    <Typography variant="subtitle2">
                      {item.schemeFeeStructure || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                    <Typography variant="subtitle1">Fund Manager</Typography>
                    <Typography variant="subtitle2">
                      {item.fundManagerName || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container rowGap={2} columnGap={2}>
                  {sessionStorage.getItem("Profile") === "B" ||
                  sessionStorage.getItem("Profile") === "E" ? (
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={
                          distributorCompanyList &&
                          distributorCompanyList?.data?.companies?.map(
                            (options: any) => options
                          )
                        }
                        getOptionLabel={(options: any) =>
                          options.companyName + " - " + options.companyTypeCode
                        }
                        fullWidth
                        onChange={(options: any, newValue: any) => {
                          setCompanyMasterCode(
                            newValue ? newValue.companyMasterCode : ""
                          );
                          setCompanyTypeCode(
                            newValue ? newValue.companyTypeCode : ""
                          );
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Select Company"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {sessionStorage.getItem("Profile") === "B" ||
                  sessionStorage.getItem("Profile") === "E" ? (
                    // && UserProfilesByCompanyMasterCode
                    <>
                      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={
                            UserProfilesByCompanyMasterCode
                              ? UserProfilesByCompanyMasterCode?.data?.userProfile?.map(
                                  (options: any) => options
                                )
                              : []
                          }
                          getOptionLabel={(options: any) =>
                            options?.userCode +
                            "- " +
                            options?.firstName +
                            " " +
                            options?.lastName
                          }
                          fullWidth
                          onChange={(options: any, newValue: any) => {
                            setDistributorCode(
                              newValue ? newValue.employeeCode : ""
                            );
                            setDistributorUserCode(
                              newValue ? newValue.userCode : ""
                            );
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label="Select User"
                              size="small"
                            />
                          )}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}

                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={
                        clientData
                          ? clientData?.data?.clients?.map(
                              (options: any) => options
                            )
                          : []
                      }
                      getOptionLabel={(options: any) =>
                        options.fullName + " - " + options.panCardNo
                      }
                      fullWidth
                      onChange={(options: any, newValue: any) => {
                        setClientId(newValue ? newValue.clientId : "");
                        setClientDetails(newValue ? newValue : "");
                      }}
                      renderInput={(params: any) => (
                        <form
                          onSubmit={handleSubmit(onSubmitInvestNow)}
                          id="form1"
                        >
                          <TextField
                            {...params}
                            label="Select Clients"
                            size="small"
                            error={errors.clients ? true : false}
                            helperText={
                              !errors.clients ? "" : errors.clients.message + ""
                            }
                            {...register("clients", registerOptions.clients)}
                          />
                        </form>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label="Order Date"
                        inputFormat="DD/MM/YYYY"
                        value={orderDate}
                        onChange={(newValue: any) => {
                          seOrderDate(newValue.toJSON());
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {clientId && (
                    <ClientModalForOrder clientDetails={clientDetails} />
                  )}

                  <TableContainer
                    component={Paper}
                    elevation={2}
                    sx={{ mt: 2 }}
                  >
                    <Table sx={{ minWidth: 1650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ fontSize: "1rem", fontWeight: "600" }}
                            align="center"
                          >
                            Order Date
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "1rem", fontWeight: "600" }}
                            align="center"
                          >
                            Client Name
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "1rem", fontWeight: "600" }}
                            align="center"
                          >
                            Pan Card
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "1rem", fontWeight: "600" }}
                            align="center"
                          >
                            Commitment Amount
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "1rem", fontWeight: "600" }}
                            align="center"
                          >
                            Investment Amount
                          </TableCell>
                          {sessionStorage.getItem("Profile") === "D" && (
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Revenue Rate (PA)
                            </TableCell>
                          )}
                          {(sessionStorage.getItem("Profile") === "B" ||
                            sessionStorage.getItem("Profile") === "E") &&
                            companyTypeCode === "D" && (
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Revenue Rate (PA)
                              </TableCell>
                            )}
                          {(sessionStorage.getItem("Profile") === "B" ||
                            sessionStorage.getItem("Profile") === "E") &&
                            companyTypeCode === "D" && (
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Payout Rate (PA)
                              </TableCell>
                            )}
                          {(sessionStorage.getItem("Profile") === "B" ||
                            sessionStorage.getItem("Profile") === "E") &&
                            companyTypeCode === "S" && (
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Revenue Rate (PA)
                              </TableCell>
                            )}
                          {sessionStorage.getItem("Profile") === "D" && (
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Monthly Revenue Trail Amount (Tentative)
                            </TableCell>
                          )}
                          {(sessionStorage.getItem("Profile") === "B" ||
                            sessionStorage.getItem("Profile") === "E") &&
                            companyTypeCode === "D" && (
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Monthly Revenue Trail Amount (Tentative)
                              </TableCell>
                            )}
                          {(sessionStorage.getItem("Profile") === "B" ||
                            sessionStorage.getItem("Profile") === "E") &&
                            companyTypeCode === "D" && (
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Monthly Payout Trail Amount (Tentative)
                              </TableCell>
                            )}
                          {(sessionStorage.getItem("Profile") === "B" ||
                            sessionStorage.getItem("Profile") === "E") &&
                            companyTypeCode === "S" && (
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Monthly Revenue Trail Amount (Tentative)
                              </TableCell>
                            )}
                          <TableCell
                            sx={{ fontSize: "1rem", fontWeight: "600" }}
                            align="center"
                          >
                            Acknowledgement Reference No{" "}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "1rem", fontWeight: "600" }}
                            align="left"
                          >
                            Form Upload
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {" "}
                            {convertDate(orderDate)}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Typography>{clientDetails.fullName}</Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {clientDetails.panCardNo}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <form
                            onSubmit={handleSubmit(onSubmitInvestNow)}
                            id="form1"
                          >
                            <TextField
                              size="small"
                              fullWidth
                              label="Commitment Amount"
                              error={errors.commitmentAmount ? true : false}
                              helperText={
                                !errors.commitmentAmount
                                  ? ""
                                  : errors.commitmentAmount.message + ""
                              }
                              {...register(
                                "commitmentAmount",
                                registerOptions.commitmentAmount
                              )}
                            />
                          </form>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <form
                            onSubmit={handleSubmit(onSubmitInvestNow)}
                            id="form1"
                          >
                            <TextField
                              label="Investment Amount"
                              size="small"
                              fullWidth
                              defaultValue={item.schemeMinInvestment}
                              error={errors.buyRate ? true : false}
                              helperText={
                                !errors.buyRate
                                  ? ""
                                  : errors.buyRate.message + ""
                              }
                              {...register("buyRate")}
                            />
                          </form>
                        </TableCell>
                        {sessionStorage.getItem("Profile") === "D" && (
                          <TableCell component="th" scope="row">
                            <Typography textAlign={"center"}>
                              {!isNaN(disRevenueRate) ? disRevenueRate : 0}%
                            </Typography>
                          </TableCell>
                        )}
                        {(sessionStorage.getItem("Profile") === "B" ||
                          sessionStorage.getItem("Profile") === "E") &&
                          companyTypeCode === "D" && (
                            <TableCell component="th" scope="row">
                              <Typography textAlign={"center"}>
                                {!isNaN(brokerRevenueRate)
                                  ? brokerRevenueRate
                                  : 0}
                                %
                              </Typography>
                            </TableCell>
                          )}
                        {(sessionStorage.getItem("Profile") === "B" ||
                          sessionStorage.getItem("Profile") === "E") &&
                          companyTypeCode === "D" && (
                            <TableCell component="th" scope="row">
                              <Typography textAlign={"center"}>
                                {!isNaN(disRevenueRate) ? disRevenueRate : 0}%
                              </Typography>
                            </TableCell>
                          )}
                        {(sessionStorage.getItem("Profile") === "B" ||
                          sessionStorage.getItem("Profile") === "E") &&
                          companyTypeCode === "S" && (
                            <TableCell component="th" scope="row">
                              <Typography textAlign={"center"}>
                                {!isNaN(revenueRate) ? revenueRate : 0}%
                              </Typography>
                            </TableCell>
                          )}
                        {sessionStorage.getItem("Profile") === "D" && (
                          <TableCell component="th" scope="row" align="center">
                            {" "}
                            {trailAmount || 0}{" "}
                          </TableCell>
                        )}
                        {(sessionStorage.getItem("Profile") === "B" ||
                          sessionStorage.getItem("Profile") === "E") &&
                          companyTypeCode === "D" && (
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {" "}
                              {empRevenueAmount || 0}{" "}
                            </TableCell>
                          )}
                        {(sessionStorage.getItem("Profile") === "B" ||
                          sessionStorage.getItem("Profile") === "E") &&
                          companyTypeCode === "D" && (
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {" "}
                              {trailAmount || 0}{" "}
                            </TableCell>
                          )}
                        {(sessionStorage.getItem("Profile") === "B" ||
                          sessionStorage.getItem("Profile") === "E") &&
                          companyTypeCode === "S" && (
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {" "}
                              {trailAmount || 0}{" "}
                            </TableCell>
                          )}
                        <TableCell component="th" scope="row" align="center">
                          <form
                            onSubmit={handleSubmit(onSubmitInvestNow)}
                            id="form1"
                          >
                            <TextField
                              size="small"
                              {...register("acknowledgementNo")}
                            />
                          </form>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Button
                            component="label"
                            size="small"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            sx={{ mb: 1 }}
                          >
                            Upload file
                            <input
                              hidden
                              type="file"
                              accept=".pdf"
                              onChange={(event: any) =>
                                setUploadForm(event.target.files[0])
                              }
                            />
                          </Button>
                          <Typography sx={{ fontSize: 12 }}>
                            {uploadForm?.name}
                          </Typography>
                        </TableCell>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Box
                  sx={{
                    textAlign: "center",
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmitInvestNow)} id="form1">
                    <Button
                      type="submit"
                      onClick={() => handleSubmit(onSubmitInvestNow)}
                      variant="contained"
                      disabled={OrderNowBtn}
                    >
                      Order Now
                    </Button>
                  </form>
                  <Button
                    disabled={!!!clientId}
                    onClick={() => addToProposal()}
                    variant="contained"
                  >
                    Proposal
                  </Button>
                </Box>
              </Box>
            </Modal>
          </>
        ))}
    </Box>
  );
};

export default withParam(PMSScreener);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  //minHeight: "90%",
  overflowY: "auto",
};
