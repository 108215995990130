import { Grid,Box, Typography, IconButton, Stack, Button, Paper, Chip, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useQuery } from 'react-query';
import { GetMaturityCashFlow, GetQuoteDetailsByIsin } from '../api/Api';
import axios from 'axios';
import { format, addDays, isWeekend } from "date-fns";
import Holidays from "date-holidays";
import { CREATE_NEW_ORDER } from '../utils/API_Names';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router';

const FixedIncomeOrderCalc = (props:any) => {
  // const {Productmastercode}= props
  const { Bdata, productMasterCode,productTypeCode,clientID,BondDetails,filteredID,companyMasterCode,distributorCode } = props.product;
  const brokerageValue = props.item && props.item.brokerageValueForCustomer;
  const [quoteDetail , setQuoteDetail] = useState<any>();
  const [quantityCount, setQuantityCount] = useState<any>(1);
  const [num , setNum] = useState<any>(0);
  const [spread, setSpread] = useState<any>(0.00);
  const [ipDates, setipDates] = useState<any>();

  const [minValue,setMinValue] = useState<any>(0);
  const [maxValue,setMaxValue] = useState<any>(0);
  const [multiple,setMultiple] = useState<any>(0);

  const [updatedPrice , setUpdatedPrice] = useState<any>();
  const [updatedYield , setUpdatedYield] = useState<any>();

  const [selectedDate, setSelectedDate] = useState("");
  const [disableT1, setDisableT1] = useState(false);
 

  const handleAdd = () => {
    if(num == maxValue){

    }else{
      setNum(num + multiple);
    }
  }

  const handleSubs = () => {
    if(num == minValue){

    }else{
      setNum(num - multiple);
    }
  }

  const handleSpreadAdd = () => {
    if (spread >= 0) {
      setSpread(spread + 0.10);
    }
  }

  const handleSpreadSubs = () => {
    if (spread <= 0.10) {
      setSpread(0);
    } else {
      setSpread(spread - 0.10);
    }
  }


  const AddQuantity = () => {
    if(num == maxValue){

    }else{
      setQuantityCount(quantityCount + 1);
    }
  }

  const DecQuantity = () => {
    if(num == minValue){

    }else{
      setQuantityCount(quantityCount - 1);
    }
  }

  
  //Quote Details
  const { data:getquoteDetais, refetch } = useQuery(["getquoteDetailByIsin",props?.item?.isinNumber],()=>GetQuoteDetailsByIsin(props.item.isinNumber),
    {enabled:!!props?.item?.isinNumber , 
      onSuccess:(data:any)=>{
        setQuoteDetail(data.data.quoteDetails[0]);
        setNum(data.data.quoteDetails[0] && data.data.quoteDetails[0].minValue);
        setMinValue(data.data.quoteDetails[0] && data.data.quoteDetails[0].minValue);
        setMaxValue(data.data.quoteDetails[0] && data.data.quoteDetails[0].maxValue);
        setMultiple(data.data.quoteDetails[0] && data.data.quoteDetails[0].multiple);
        setUpdatedPrice(data.data.quoteDetails[0] && data.data.quoteDetails[0].salePrice);
        setUpdatedYield(data.data.quoteDetails[0] && data.data.quoteDetails[0].saleYtm);
      },
      refetchOnWindowFocus: false,
    })


  
  //CashFlow
  const { data:cashflowMaturity, refetch:cashFlowrefetch } = useQuery(["cashflowMaturity",props?.item?.isinNumber],()=>GetMaturityCashFlow(props.item.isinNumber),
    {enabled:!!props?.item?.isinNumber , 
      onSuccess:(data:any)=>{
        setipDates(data.data.cashFlowMaturityCallList);
      }
    })

    //Xirr with spread
    const getSpreadYield = () => {
      var sellPrice = quoteDetail.salePrice + spread;
    
      axios.get('quoteDetails/xirrCalculation?isinNumber='+ props.item.isinNumber + '&salePrice=' + sellPrice?.toFixed(2))
        .then((res:any) => {
          setUpdatedPrice(res.data.xirrNXnpvDTO.salePrice);
          setUpdatedYield(res.data.xirrNXnpvDTO.saleYtm);
    
          // Call setCalcValues after setting the state to use the updated values
        })
        .catch((error) => {
          // Handle error if the request fails
          console.error("Error fetching data:", error);
        });
    };


    //Accrured Interest calculation
    var daysDiff;
    if (ipDates && ipDates.length > 0) {
      // Generate a list of all IP dates
      const dates: any[] = ipDates.map((it: any) => new Date(it.interestDate));
    
      // Get today's date
      const today = new Date();
    
      // Find where today's date lies within the list
      let lowerIndex = -1;
      let upperIndex = -1;
    
      for (let i = 0; i < dates.length; i++) {
        if (dates[i] <= today) {
          lowerIndex = i;
        } else {
          upperIndex = i;
          break;
        }
      }
    

    
      // Set the time portion of the dates to midnight
      dates[lowerIndex]?.setHours(0, 0, 0, 0);
      dates[upperIndex]?.setHours(0, 0, 0, 0);
    
      // Calculate the time difference in milliseconds
      const timeDiff = Math.abs(dates[lowerIndex]?.getTime() - today?.getTime());
    
      // Convert the time difference to days
      daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      
      // Output the result
      console.log("Days between the two dates:", daysDiff);
    } else {
      console.log("ipDates array is undefined or empty.");
    }

    const faceValue = props && props?.item?.faceValue;
    const coupon = props && props?.item?.coupon/100;
    const days = daysDiff && daysDiff/365;

    const accruedInterest:any = faceValue && days && coupon ? faceValue*coupon*days*quantityCount : "--"
    //Principle Consideration Calculation

    const principleConsideration:any = num * updatedPrice / 100;

    //Total Consideration Calculation

    const totalConsideration : any = accruedInterest === '--' ? "--" : accruedInterest + principleConsideration;

    const distMargin: any = spread * num / 100;

    

    const setCalcValues = () => {
      sessionStorage.setItem('Accrued' , accruedInterest.toFixed(2));
      sessionStorage.setItem('TotalConsideration', totalConsideration.toFixed(2));
      sessionStorage.setItem('Principle', principleConsideration);
      sessionStorage.setItem('price',updatedPrice);
      sessionStorage.setItem('yield', updatedYield);
      sessionStorage.setItem('spread', spread?.toFixed(2));
      sessionStorage.setItem('value', num);
      sessionStorage.setItem('quantity', quantityCount);
      sessionStorage.setItem('dealDate', getToday(new Date()));
      sessionStorage.setItem('valueDate', selectedDate);
    }

   

    useEffect(() => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
  
      if (currentHour >= 12 || currentHour < 1) {
        setSelectedDate(getNextValidDate(currentTime));
        setDisableT1(true);
      } else {
        setSelectedDate(getToday(currentTime));
        setDisableT1(false);
      }
  
      const checkTime = () => {
        const now = new Date();
        const currentHour = now.getHours();
  
        if (currentHour >= 12 || currentHour < 1) {
          setDisableT1(true);
        } else {
          setDisableT1(false);
        }
      };
  
      const interval = setInterval(checkTime, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, []);
  
    const handleRadioChange = (e:any) => {
      const currentTime = new Date();
  
      if (e.target.value === "t0") {
        setSelectedDate(getToday(currentTime));
      } else {
        setSelectedDate(getNextValidDate(currentTime));
      }
    };
  
    const getToday = (date:any) => {
      return format(date, "yyyy-MM-dd");
    };
  
    const getNextValidDate = (date:any) => {
      const hd = new Holidays("IN");
      let nextDay = addDays(date, 1);
  
      while (isWeekend(nextDay) || hd.isHoliday(nextDay)) {
        nextDay = addDays(nextDay, 1);
      }
  
      return format(nextDay, "yyyy-MM-dd");
    };

    const placeOrder = () => {
      setCalcValues()
      const obj = {
        "orderDate": new Date(),
        "productMasterCode": productMasterCode,
        "clientId": clientID,
        "version": 1,
        "productTypeCode": productTypeCode,
        "quantity": parseFloat(sessionStorage.getItem('quantity')+""),
        "userRate": parseFloat(sessionStorage.getItem('price')+""), //sell price
        "tcs": 0.5 * parseFloat(sessionStorage.getItem('price')+"") , 
        "totalValue": 0,
        "bondType": BondDetails?.bondType,
        "isinNumber": BondDetails?.isinNumber,
        "accountStatus": "PENDING",
        "coupon": BondDetails?.coupon,
        "issuerName": BondDetails?.issuerName,
        "maturityDate": BondDetails?.maturityDate,
        "transactionType": "string",
        "optionSelected": "string",
        "clientName": filteredID?.fullName,
        "distributorId": 0,
        "distributorName": "string",
        "settlementType": "Bilateral",
        "settlementMode": "OFF_MARKET",
        "dealDate": sessionStorage.getItem('dealDate'),
        "settlementDate": sessionStorage.getItem('valueDate'),
        "orderValue": 0,
        "price": parseFloat(sessionStorage.getItem('price')+""),
        "yield": parseFloat(sessionStorage.getItem('yield')+""),
        "accruedInterest": parseFloat(sessionStorage.getItem('Accrued')+""),
        "totalConsideration": parseFloat(sessionStorage.getItem('TotalConsideration')+""),
        "status": "string",
        "prinicipalAmount": parseFloat(sessionStorage.getItem('Principle')+""),
        "bondPrice": parseFloat(sessionStorage.getItem("value")+""),
        "spreadValue": parseFloat(sessionStorage.getItem("spread")+""),
        "distCompanyMasterCode" : companyMasterCode,
        "distEmployeeCode":distributorCode
        
      }
      axios.post(CREATE_NEW_ORDER, obj)
      // .then((res:any) => toast.success(res?.data?.status?.message?.title))
      .then((res:any) => toast.success("You Can View Your Orders In Pre Order Window."))
      .then(()=>
      {
        sessionStorage.removeItem('Accrued');
        sessionStorage.removeItem('TotalConsideration');
        sessionStorage.removeItem('Principle');
        sessionStorage.removeItem('price');
        sessionStorage.removeItem('yield');
        sessionStorage.removeItem('spread');
        sessionStorage.removeItem('value');
        sessionStorage.removeItem('quantity');
        sessionStorage.removeItem('dealDate');
        sessionStorage.removeItem('valueDate');
      }
      
      )
      .catch((err: any) => {
        toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title);
     });
    }


  return (
    <>
    <Grid className='fixed-oedercalc-main-grid' container spacing={3} sx={{display:'flex', justifyContent: "flex-start"}}>
      <Grid container spacing={0} sx={{display:'flex', justifyContent:'center'}} >
    <Typography variant='h6'>Calculator</Typography>
      </Grid>
      <Grid item md={5}>
        <Box sx={{display:'flex' ,justifyContent: "flex-start",paddingBottom:"20px"}} >
          <IconButton sx={{
            "&:hover":{
              cursor:'pointer',
              backgroundColor:'transparent'
            },
            marginTop:'13px'
          }}
          onClick={()=>{handleSubs(); DecQuantity();}}
          >
        <RemoveIcon sx={{margin:'auto', color:'#000'}}/>
          </IconButton>
        <Box sx={{
          backgroundColor:'#F8EEEE', 
          width:'100%', 
          height:'28px', 
          borderRadius:'10px',
          margin:'0 auto',
          marginTop:'19.5px',
          display:'flex',
          justifyContent:'center'
          }}>
         <Typography variant={'subtitle1'} >
           {num ? num.toLocaleString('en-IN') : "--"}
          </Typography> 
        </Box>
        <IconButton sx={{
            "&:hover":{
              cursor:'pointer',
              backgroundColor:'transparent'
            },
            marginTop:'13px'
          }}
          onClick={()=>{handleAdd(); AddQuantity()}}
          >
        <AddIcon sx={{margin:'auto',color:'#000'}}/>
        </IconButton>
        </Box>
      </Grid>
     <Grid container spacing={1} sx={{display:'flex', justifyContent: "flex-start",alignItems: "center",marginLeft:"50px"}}  >
      <Grid item md={3}>
        <Box sx={{display:'flex'}}>
          <IconButton sx={{
            "&:hover":{
              cursor:'pointer',
              backgroundColor:'transparent'
            },
          }}
          onClick={()=>{handleSpreadSubs();}}
          >
        <RemoveIcon sx={{ color:'#000'}}/>
          </IconButton>
        <Box sx={{
          backgroundColor:'#F8EEEE', 
          width:'100%', 
          height:'28px', 
          borderRadius:'10px',
          margin:'0 auto',
          display:'flex',
          justifyContent:'center'
          }}>
         <Typography variant={'subtitle1'} >
           {spread?.toFixed(2)}
          </Typography> 
        </Box>
        <IconButton sx={{
            "&:hover":{
              cursor:'pointer',
              backgroundColor:'transparent'
            },
          }}
          onClick={()=>{handleSpreadAdd();}}
          >
        <AddIcon sx={{margin:'auto',color:'#000'}}/>
        </IconButton>
        </Box>
      </Grid>
      <Grid >
        <Button variant='contained' size='small' onClick={getSpreadYield} >Add Spread</Button>
      </Grid>
      </Grid>

     



     



      <Grid item md={4} sx={{display:'flex', justifyContent:'center'}}>
        <Typography sx={{fontWeight:'500', fontSize:'0.8rem'}}>Price :</Typography> 
        <Typography sx={{fontSize:'0.8rem'}}>{ updatedPrice && updatedPrice?.toFixed(2)}</Typography>
      </Grid>

      <Grid item md={4} >
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem'}}>Yield :</Typography> 
        <Typography sx={{fontSize:'0.8rem'}}>{updatedYield && updatedYield}%</Typography>
      </Grid>
      
      <Grid item md={4} sx={{display:'flex', justifyContent:'center'}}>
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem'}}>Quantity :</Typography> 
        <Typography sx={{fontSize:'0.8rem'}}>{quantityCount}</Typography>
      </Grid>

      <Grid item md={4} sx={{display:'flex', justifyContent:'center'}}>
        <Typography sx={{fontWeight:'500', fontSize:'0.8rem'}}>Deal Date :</Typography> 
        <Typography sx={{fontSize:'0.8rem'}}>{getToday(new Date())}</Typography>
      </Grid>

      <Grid item md={4} sx={{textAlign:'center'}}>
      <label>
        <input
          type="radio"
          name="time"
          value="t0"
          checked={selectedDate === getToday(new Date())}
          onChange={handleRadioChange}
          disabled={disableT1}
        />
        T+0
      </label>
      <label style={{marginLeft:'10px'}}>
        <input
          type="radio"
          name="time"
          value="t1"
          checked={selectedDate === getNextValidDate(new Date())}
          onChange={handleRadioChange}
        />
        T+1
      </label>
      </Grid>

      <Grid item md={4} sx={{display:'flex', justifyContent:'center'}}>
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem'}}>Value Date :</Typography> 
        <Typography sx={{fontSize:'0.8rem'}}>{selectedDate}</Typography>
      </Grid>

<Grid container spacing={3} sx={{margin:'0 0.5rem'}}>
      <Grid item md={3} sx={{display:'flex', justifyContent:'space-evenly'}}>
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem', margin:'auto 0'}}>Principle Consideration :</Typography>
        <Chip label={principleConsideration ? principleConsideration?.toFixed(2) : "--"} /> 
      </Grid>
      <Grid item md={3} sx={{display:'flex', justifyContent:'space-evenly'}}>
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem', margin:'auto 0'}}>Accrued Interest :</Typography>
        <Chip label={typeof accruedInterest === 'number' ? accruedInterest?.toFixed(2) : '--'}/> 
      </Grid>
      <Grid item md={3} sx={{display:'flex', justifyContent:'space-evenly'}}>
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem', margin:'auto 0'}}>Total Consideration :</Typography>
        <Chip label={typeof totalConsideration === 'number' ? totalConsideration?.toFixed(2) : "--"} /> 
      </Grid>
      
      {/* To Do : Actual Calculation Pending */}
      {sessionStorage.getItem('Profile')!=='D'?(
      <Grid item md={3} sx={{display:'flex', justifyContent:'space-evenly'}}>
        <Typography sx={{fontWeight:'500',fontSize:'0.8rem', margin:'auto 0'}}>Gross Margin :</Typography>
        <Chip label={distMargin?.toFixed(2)} /> 
      </Grid>
      ):("")
      }

  </Grid>
    </Grid>
    <Grid item  lg={12} textAlign="center"  >  
            <Grid container style={{display:"flex",justifyContent:"space-around"}}>
              <Button sx={{marginBottom: "10px"}}
                variant="contained"
                onClick={placeOrder}
                disabled={!!!distributorCode}
              >
                Order
              </Button>

              
            </Grid>
          </Grid>
    </>
  )
}

export default FixedIncomeOrderCalc