import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Bounce, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Grade } from "@mui/icons-material";
import { productTypeEnum } from "../../../Constants/ProductTypeEnum";
import { useQuery } from "react-query";
import {
  GetAllClient,
  GetBrokeragePayoutByCompanyMasterCode,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  listOfManufactureNDistributorCompanies,
} from "../../../api/Api";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { CurrencyFormate, changeDate } from "../../../utils/Helper";
import ClientModalForOrder from "../../ClientModalForOrder";
import { CREATE_NEW_ORDER } from "../../../utils/API_Names";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  "& .MuiTextField-root": { m: 1 },
};

const ResearchServicesOrderModel = (props: any) => {
  const data = props.data;
  console.log("hagshgdgdhf", data);
  const month = props.month;
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const {
    handleSubmit,
    formState: {},
    reset,
  } = useForm();

  const [companyMasterCode, setCompanyMasterCode] = React.useState("");
  const [distributorCode, setDistributorCode] = React.useState("");
  const [distributorUserCode, setDistributorUserCode] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [orderDate, seOrderDate] = useState(new Date());
  const [companyTypeCode, setCompanyTypeCode] = React.useState();
  const [selectedCompany, setSelectedCompany] = React.useState(null);

  const [value, setValue] = React.useState("D");

  const [clientDetails, setClientDetails] = React.useState<any>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const onSubmit = (submitData: any) => {
    if (!selectedCompany) {
      toast.error("Please select the company!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Bounce,
      });
      return;
    }

    const orderObj = {
      ...submitData,
      productTypeCode: productTypeEnum.RESEARCH_SERVICES,
      productMasterCode: data.productMasterCode,
      clientId: clientId,
      version: "1",
      distCompanyMasterCode: companyMasterCode,
      distEmployeeCode: distributorCode,
      subscriptionPlan: data?.productName,
      orderDate: changeDate(orderDate),
    };
    axios
      .post(CREATE_NEW_ORDER, orderObj)
      .then(() => {
        toast.success("You Can View Your Orders In Pre Order Window.");
        setSelectedCompany(null);
        setCompanyMasterCode("");
        setDistributorCode("");
        setClientId("");
      })
      .then(() => {
        setShowCircularProgress(false);
        props.onClose();
      })
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };

  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompanie",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled:
        sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E",
    }
  );

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", companyMasterCode, companyTypeCode],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        companyTypeCode === "S" ? "E" : companyTypeCode,
        companyMasterCode
      ),
    {
      enabled: !!companyMasterCode,
    }
  );

  const { data: clientData } = useQuery(
    ["getAllClients", companyMasterCode, distributorCode],
    () => GetAllClient(companyMasterCode, distributorCode),
    {
      enabled: !!companyMasterCode || sessionStorage.getItem("Profile") === "D",
    }
  );

  const { data: brokerageData } = useQuery(
    ["GetBrokeragePayoutByCompanyMasterCode", companyMasterCode],
    () =>
      GetBrokeragePayoutByCompanyMasterCode(
        sessionStorage.getItem("Profile") === "D"
          ? sessionStorage.getItem("CompanyMasterCode")
          : companyMasterCode
      ),
    {
      enabled: !!companyMasterCode || sessionStorage.getItem("Profile") === "D",
    }
  );

  const researchBrokerage =
    brokerageData &&
    brokerageData.data?.companyBrokerage?.filter(
      (item: any) =>
        item?.productTypeCode === productTypeEnum?.RESEARCH_SERVICES
    )[0];

  console.log("hfdghfhgfghfhgfghfhg", researchBrokerage);

  let trailAmount: any = null;
  let trailAmountOne: any = null;
  let amt: any = null;
  let amtOne: any = null;
  amt = (data?.planAmount * researchBrokerage?.brokerageValueForCustomer) / 100;
  amtOne = (data?.planAmount * researchBrokerage?.brokerageValueForIV) / 100;
  trailAmount = CurrencyFormate(amt);
  trailAmountOne = CurrencyFormate(amtOne);

  console.log("mycalculation", trailAmount);

  console.log("myswlwlwcode", companyTypeCode);
  return (
    <>
      <Modal
        keepMounted
        open={props.open}
        onClose={() => props.onClose()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <IconButton
            style={{
              position: "absolute",
              fontSize: "18px",
              top: "8px",
              right: "5px",
            }}
            onClick={() => props.onClose()}
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </IconButton>
          <Typography
            id="keep-mounted-modal-title"
            variant="h5"
            component="h2"
            gutterBottom
          >
            Investkul Research Service
          </Typography>
          <Typography
            id="keep-mounted-modal-title"
            component="h2"
            style={{ fontWeight: 500 }}
            gutterBottom
          >
            You are about to subscribe to Investkul Research Service. Please
            verify and click on Order Now to finalize.
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography>
              Product Name: <b>{data?.productName}</b>
            </Typography>
            <Typography>
              Plan Amount: <b>{data?.planAmount}</b>
            </Typography>
            <Typography>
              Segment: <b>{data?.segment}</b>
            </Typography>
            <Typography>
              Category: <b>{data?.category}</b>
            </Typography>
            <Typography>
              Tenure: <b>{month}</b>
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Order Date"
                inputFormat="DD/MM/YYYY"
                value={orderDate}
                onChange={(newValue: any) => {
                  seOrderDate(newValue.toJSON());
                }}
                renderInput={(params) => (
                  <TextField size="small" {...params} sx={{ width: "100%" }} />
                )}
              />
            </LocalizationProvider>

            {sessionStorage.getItem("Profile") === "B" ||
            sessionStorage.getItem("Profile") === "E" ? (
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    distributorCompanyList &&
                    distributorCompanyList.data.companies.map(
                      (options: any) => options
                    )
                  }
                  getOptionLabel={(options: any) =>
                    options.companyName + " - " + options.companyTypeCode
                  }
                  fullWidth
                  onChange={(options: any, newValue) => {
                    setSelectedCompany(newValue);
                    setCompanyMasterCode(
                      newValue ? newValue.companyMasterCode : ""
                    );
                    setCompanyTypeCode(
                      newValue ? newValue.companyTypeCode : ""
                    );
                  }}
                  value={selectedCompany}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Select Company"
                      size="small"
                    />
                  )}
                />
              </Grid>
            ) : (
              <></>
            )}

            {(UserProfilesByCompanyMasterCode &&
              sessionStorage.getItem("Profile") === "B") ||
            (UserProfilesByCompanyMasterCode &&
              sessionStorage.getItem("Profile") === "E") ? (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={
                      UserProfilesByCompanyMasterCode
                        ? UserProfilesByCompanyMasterCode.data.userProfile.map(
                            (options: any) => options
                          )
                        : []
                    }
                    getOptionLabel={(options: any) =>
                      options?.userCode +
                      "- " +
                      options?.firstName +
                      " " +
                      options?.lastName
                    }
                    fullWidth
                    onChange={(options: any, newValue) => {
                      setDistributorCode(newValue ? newValue.employeeCode : "");
                      setDistributorUserCode(newValue ? newValue.userCode : "");
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Select Distributor User"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}

            {clientData && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={clientData.data.clients.map(
                    (options: any) => options
                  )}
                  getOptionLabel={(options: any) =>
                    options.fullName + " - " + options.panCardNo
                  }
                  fullWidth
                  onChange={(options: any, newValue) => {
                    setClientId(newValue ? newValue.clientId : "");
                    setClientDetails(newValue ? newValue : "");
                  }}
                  renderInput={(params: any) => (
                    <form id="form1">
                      {/* onSubmit={handleSubmit(onSubmitInvestNow)} */}
                      <TextField
                        {...params}
                        label="Select Clients"
                        size="small"
                      />
                    </form>
                  )}
                />
              </Grid>
            )}
            {/* {sessionStorage.getItem("Profile") === "E" && (
                            <Typography>Revenue Credit: <b>{data?.planAmount}</b></Typography>
                            )}  */}
            {sessionStorage.getItem("Profile") === "E" &&
              (companyTypeCode === "D" ? (
                <Typography>
                  Revenue Credit: <b>{trailAmountOne}</b>
                </Typography>
              ) : companyTypeCode === "S" ? (
                <Typography>
                  Revenue Credit : <b>{data?.planAmount}</b>
                </Typography>
              ) : null)}

            {sessionStorage.getItem("Profile") === "D" && (
              <Typography>
                Payout Amount: <b>{trailAmount}</b>
              </Typography>
            )}
            {sessionStorage.getItem("Profile") === "B" &&
              (companyTypeCode === "D" ? (
                <Typography>
                  Payout Amount: <b>{trailAmount}</b>
                </Typography>
              ) : companyTypeCode === "S" ? (
                <Typography>
                  Revenue Credit : <b>{data?.planAmount}</b>
                </Typography>
              ) : null)}

            <Grid item xs={12} sm={12} md={12} lg={12}>
              {clientId && (
                <ClientModalForOrder clientDetails={clientDetails} />
              )}
            </Grid>
            <Button type="submit" variant="contained" sx={{ marginTop: "6%" }}>
              {" "}
              {showCircularProgress ? (
                <CircularProgress color="secondary" size={20} thickness={5} />
              ) : (
                ""
              )}
              {"  "} Order Now
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ResearchServicesOrderModel;
