import React from "react";
import { useQuery } from "react-query";
import {
  GetAllClient,
  listOfManufactureNDistributorCompanies,
  proposalListByUser,
} from "../../api/Api";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import AIFProposal from "./Proposal/AIFProposal";
import PMSProposal from "./Proposal/PMSProposal";
import BondProposal from "./Proposal/BondProposal";
import UnlistedProposal from "./Proposal/UnlistedProposal";
import axios from "axios";
import { DELETE_PROPOSAL } from "../../utils/API_Names";
import { toast } from "react-toastify";
import GetAppIcon from "@mui/icons-material/GetApp";

export const CartGetAllList = () => {
  const [clientID, setClientID] = React.useState<any>(null);
  const [showCircularProgress, setshowCircularProgress] = React.useState(false);
  // const [clientsData, setClientsData] = React.useState<any>([]);
  const [companyMasterCode, setCompanyMasterCode] = React.useState("");
  const [companyTypeCode, setCompanyTypeCode] = React.useState("");
  const [distributorCode, setDistributorCode] = React.useState("");
  const [selectedClient, setSelectedClient] = React.useState<any>(null);
  // const [ proposalID, setProposalID] = React.useState("")

  // For fetching the list of companies
  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompanie",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled:
        sessionStorage.getItem("Profile") === "D" ||
        sessionStorage.getItem("Profile") === "E",
      staleTime: Infinity,
    }
  );

  // For fetching the list of clients
  // const { data: clients } = useQuery("getAllClients", () => GetAllClient(), {
  //   onSuccess: (res: any) => {
  //     const clientDetData = res.data.clients;
  //     setClientsData(clientDetData);
  //   },
  //   staleTime: Infinity,
  // });

  const { data: clientData } = useQuery(
    ["getAllClients", companyMasterCode, distributorCode],
    () => GetAllClient(companyMasterCode, distributorCode),
    {
      enabled: !!companyMasterCode || sessionStorage.getItem("Profile") === "D",
      staleTime: Infinity,
    }
  );

  const { data, refetch } = useQuery(
    ["proposalList", clientID],
    () => proposalListByUser(clientID),
    {
      enabled: !!clientID,
      staleTime: Infinity,
    }
  );

  const deleteProposal = (proposalID: number) => {
    axios
      .delete(`${DELETE_PROPOSAL}${proposalID}`)
      .then((res: any) => toast.success(res?.data?.status?.message?.title))
      .then(() => refetch())
      .catch((err: any) =>
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        )
      );
  };

  const downloadProposal = () => {
    setshowCircularProgress(true);
    axios({
      url: "proposal/proposalDocument?clientId=" + clientID,
      method: "GET",
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link: any = document.createElement("a");
        link.href = url;
        var date1 = new Date();
        link.setAttribute(
          "download",
          "Proposal_For_" +
            ("0" + date1.getDate()).slice(-2) +
            "_" +
            ("0" + (date1.getMonth() + 1)).slice(-2) +
            "_" +
            date1.getFullYear() +
            ".pdf"
        );
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setshowCircularProgress(false);
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        toast.error("Something Went Wrong!");
      });
  };
  return (
    <>
      <Typography variant="h4" sx={{ mt: 1, fontWeight: "500" }}>
        Proposal
      </Typography>
      {/* {clientsData && ( */}
      <Grid container sx={{ zIndex: 1 }} columnGap={3} alignItems={"baseline"}>
        {/* SELECT COMPANIES */}
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Autocomplete
            // disablePortal
            id="combo-box-demo"
            options={
              distributorCompanyList
                ? distributorCompanyList.data.companies
                : []
            }
            loading={
              distributorCompanyList
                ? !distributorCompanyList.data.companies
                : true
            }
            getOptionLabel={(company: any) => {
              const { companyName, companyTypeCode } = company;
              return `${companyName} - ${companyTypeCode}`;
            }}
            sx={{ mt: 2, mb: 2 }}
            onChange={(options: any, newValue) => {
              console.log("comp-val: ", newValue);
              setCompanyMasterCode(newValue ? newValue.companyMasterCode : "");
              setCompanyTypeCode(newValue ? newValue.companyTypeCode : "");
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Company" />
            )}
          />
        </Grid>
        {/* SELECT CLIENTS */}
        {companyMasterCode && (
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Autocomplete
              // disablePortal
              id="combo-box-demo"
              options={clientData ? clientData.data.clients : []}
              // loading={clientData ? !clientData.data.clients : true}
              getOptionLabel={(client: any) => {
                const { clientId, fullName, panCardNo } = client;
                return `${fullName} - ${panCardNo} - ${clientId}`;
              }}
              sx={{ mt: 2, mb: 2 }}
              onChange={(options: any, newValue) => {
                setClientID(newValue ? newValue?.clientId : null);
                setSelectedClient(newValue ? newValue : null);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Client" />
              )}
            />
          </Grid>
        )}

        <Grid item md={2} lg={3}></Grid>

        {/* DOWNLOAD PROPOSAL */}
        {!!clientID &&
          (data?.data?.cartDTOS[0]?.unlistedCartResDTOS.length > 0 ||
            data?.data?.cartDTOS[0]?.bondCartResDTOS.length > 0 ||
            data?.data?.cartDTOS[0]?.aifCartResDTOS.length > 0 ||
            data?.data?.cartDTOS[0]?.pmsCartResDTOS.length > 0) && (
            <Grid item md={3} lg={2}>
              <Button
                onClick={downloadProposal}
                disabled={showCircularProgress}
                sx={{ mt: 2 }}
              >
                <GetAppIcon />{" "}
                {showCircularProgress ? (
                  <CircularProgress color="secondary" size={20} thickness={5} />
                ) : null}{" "}
                Download Proposal{" "}
              </Button>
            </Grid>
          )}
      </Grid>
      {/* )} */}

      <Divider />
      {data && data?.data?.cartDTOS[0]?.aifCartResDTOS.length !== 0 && (
        <Typography variant="h5" gutterBottom>
          AIF
        </Typography>
      )}
      {data &&
        data?.data?.cartDTOS[0]?.aifCartResDTOS?.map((AIFDetails: any) => (
          <AIFProposal
            AIFDetails={AIFDetails}
            deleteProposal={deleteProposal}
          />
        ))}

      {data && data?.data?.cartDTOS[0]?.pmsCartResDTOS.length !== 0 && (
        <Typography variant="h5" gutterBottom>
          PMS
        </Typography>
      )}
      {data &&
        data?.data?.cartDTOS[0]?.pmsCartResDTOS?.map((PMSDetails: any) => (
          <PMSProposal
            PMSDetails={PMSDetails}
            deleteProposal={deleteProposal}
          />
        ))}

      {data && data?.data?.cartDTOS[0]?.bondCartResDTOS.length !== 0 && (
        <Typography variant="h5" gutterBottom>
          Bond
        </Typography>
      )}
      {data &&
        data?.data?.cartDTOS[0]?.bondCartResDTOS?.map((BondDetails: any) => (
          <BondProposal
            BondDetails={BondDetails}
            deleteProposal={deleteProposal}
          />
        ))}

      {data && data?.data?.cartDTOS[0]?.unlistedCartResDTOS.length !== 0 && (
        <Typography variant="h5" gutterBottom>
          Unlisted
        </Typography>
      )}
      {data &&
        data?.data?.cartDTOS[0]?.unlistedCartResDTOS?.map(
          (UnlistedDetails: any) => (
            <UnlistedProposal
              UnlistedDetails={UnlistedDetails}
              deleteProposal={deleteProposal}
            />
          )
        )}
    </>
  );
};
