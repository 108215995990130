export const productTypeEnum = {
  MUTUAL_FUND: 1,
  FIXED_INCOME: 2,
  PRIMARY_ISSUE: 3,
  PORTFOLIO_MANAGEMENT_SYSTEM: 4,
  ALTERNATIVE_INVESTMENT_FUND: 5,
  INSURANCE: 6,
  REAL_ESTATE: 7,
  STARTUP_ECOSYSTEM: 8,
  EQUITY_RESEARCH: 9,
  TRAINING: 10,
  UNLISTED: 11,
  BOND_WORK: 12,
  RESEARCH_SERVICES: 13,
  NCD: 14,
  LOAN: 15,
};
