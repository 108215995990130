import { Grid, Container } from "@mui/material";
import React from "react";
import "../css/footer.css";

const FooterDisclaimer = () => {
  return (
    <div className="color-white bg-dark disclaimer-footer">
      <Container className="container cont1" style={{ padding: "2px" }}>
        <Grid container className="inside-contRow ">
          <div>
            <h5 className="neof-indesk-footer-disclaimer"> DISCLAIMER</h5>
            <p style={{ marginBottom: "0px" }}>
            This website is owned, operated and maintained by InvestValue Fintech Private Limited, a company incorporated under the laws of India. The platform and the services thereunder are provided on an "as is" basis. Use of the service and the platform is at your own risk. Company makes no warranty that the use of the service and the platform will be continuous, uninterrupted, bug-free, error-free, virus-free, free of defects, free of technical problems, nor that it will meet all of your needs. To the extent permitted by applicable law, Company expressly disclaims all other warranties, conditions, results, guarantees, or representations with respect to the service and the platform, whether express or implied, including, but not limited to, the implied warranties of merchantability, merchantable or satisfactory quality, fitness for a particular purpose, non-infringement of third party rights, or arising from the course of performance, course of dealing, or usage of trade. Investment in securities market are subject to market risks, read all the related documents carefully before investing. The valuation of securities may increase or decrease depending on the factors affecting the securities market.


            </p>
            <p>
              {" "}
              ARN - 239464, InvestValue Fintech pvt ltd is AMFI registered Mutual Fund distributor. GST No -        27AAGCI2884K1Z3.
            </p>
            <div>
              <h6 className="neof-indesk-footer-disclaimer">
                Registered Address:{" "}
              </h6>
              <p>
                A/303 Gokul Arcade, Off Andheri Sahar Road, Swami Nityanan,
                Mumbai-400057
              </p>
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

export default FooterDisclaimer;
