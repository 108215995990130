import { ThemeProvider } from '@emotion/react'
import { Box, CircularProgress, Container, Grid, LinearProgress, Typography, createTheme, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getSectorExposureDetails } from '../../../api/Api'

const defaultTheme = createTheme()

const PortfolioDiversify = () => {
  const [dataExposureDetails, ExposureDetails] = useState<any>([])
  const [datastatementSectorExposure, statementSectorExposure] = useState<any>([])
  const [showCircularProgress, setshowCircularProgress] = useState(false);


  useEffect(() => {
    setshowCircularProgress(true);
    getSectorExposureDetails()
      .then((response:any) => {
        ExposureDetails(response.data.sectorWiseResponseArrays)
        statementSectorExposure(response.data.statementForSectorExposure)
      }).then(()=>{
        setshowCircularProgress(false);
      })
  }, [])

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '&.MuiLinearProgress-root': {
      height: 20,
      width: '100%',
      backgroundColor: '#032b5b',
      borderRadius: '10px'
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#000',
      borderRadius: '10px'
    }
  }));
  return (
    <div>
      {showCircularProgress == true ? (
                    <CircularProgress
                      color="secondary"
                      size={50}
                      thickness={5}
                    />
                  ) : (
                    <ThemeProvider theme={defaultTheme}>
            <Container>
                <Box>
                    <Typography className='main-tab-portfolio-composition-h2-tag' style={{ textAlign: 'center' }}>{datastatementSectorExposure}</Typography>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className='assest-aegment-allocation assest-aegment-allocation-duplicate'>
                                <div>
                                    {dataExposureDetails?.map((item:any, index:any) => (
                                        <>

                                            <Box position="relative" display="inline-flex" sx={{ width: '100%', mb: 5 }} key={index}>
                                                <Box className='prograss-bar-top-header-report-image'>
                                                    <img style={{ width: '300%' }} className='' src={'PortfolioEquitySectorDiversity/' + item.name + '.svg'} alt="" />
                                                </Box>

                                                <BorderLinearProgress
                                                    sx={{ height: 25, width: '100%', borderRadius: '8px', backgroundColor: '#fff', border: '1px solid black' }}
                                                    variant="determinate"
                                                    value={item.value} />
                                                <Box
                                                    top={0}
                                                    left={0}
                                                    bottom={0}
                                                    right={0}
                                                    position="absolute"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Typography sx={{ color: '#fff' }} variant="body2">{`${item.name} ${item.value}%`}</Typography>
                                                </Box>
                                            </Box></>

                                    ))}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </ThemeProvider>
                  )}
    </div>
  )
}

export default PortfolioDiversify