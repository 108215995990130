import { Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import equity from "../../images/InvestmentProductIMG/equity.png"
import StartWithUs from '../StartWithUs/StartWithUs';import ArrowRightIcon from '@mui/icons-material/ArrowRight';


const PrivateEquity = () => {
  window.scrollTo(0, 0);
  return (
<section>
        <Container>
        <Grid md={12} className={sessionStorage.getItem("JWT") ? 'your-dynamic-css-class-product' : 'default-class-product'}>
       <Typography style={{ textAlign: "left", fontSize: "30px", fontWeight: "bold",borderBottom: "4px solid black"}}>Private Equity </Typography>
       <Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold", textDecoration: "underline",paddingTop:"10px" }}>Industry Update </Typography>
       <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
       The popularity of unlisted shares has grown as a result of the competition between new-age companies to reach the necessary  threshold for being listed on the stock market.
        The majority of unlisted stocks are related to start-ups or small businesses. Evidently,  small businesses have a smaller basis and hence expand more quickly than well-established businesses.
        <br />
        <br />
        In contrast to investing later, investing in a start-up at an early stage will benefit the investor more because it will result in greater  profits and ownership holdings.
         Early investment in a start-up not only promises higher returns but also makes sure the investor  provides guidance and decisions to the business. Every stage that a startup goes through presents different opportunities and risks  for investors. 
         <br />
         <br />
         What is unlisted shares?
         <br />
         Unlisted shares are the shares of the companies which are not listed on any stock exchange and thereby it is not traded publicly. The shareholders of such unlisted companies do not get the privilege which are available to the shareholders of companies listed on stock exchanges
          (i.e BSE/ NSE/ MSEI). Shares of unlisted companies may get listed in future on the stock exchanges. We facilitate trades in such unlisted shares by matching buyers and sellers thereby enabling liquidity to current shareholders and enabling new investors to participate in future growth of the company.  
      </Typography>
      </Grid>
      <br />
      
    <br />

   
   
  




{sessionStorage.getItem("JWT")?"":
<Grid mt={2} mb={2}>
<StartWithUs/>
</Grid>
}
        </Container>
    </section>
  )
}

export default PrivateEquity