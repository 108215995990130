import { Grid } from "@mui/material";
import React from "react";
import fixedincome from "../images/01_fixedincome.png";
import mutualfund from "../images/02_mutualfund.png";
import pms from "../images/03_pms.png";
import aif from "../images/04_aif.png";
import primaryissue from "../images/05_primaryissue.png";
import insurance from "../images/06_insurance.png";
import realestate from "../images/07_realestate.png";
import startupecosystem from "../images/08_startupecosystem.png";
import equity_research from "../images/09_equity_research.png";
import training from "../images/10_training.png";
import "../css/investmentprod.css";
import { Link } from "react-router-dom";

const InvestmentProd = () => {
  return (
    <div className="indeskcard-maindiv">
      <section className="section-plans" id="section-plans">
        <div className="u-center-text u-margin-bottom-big" id="product">
          <h2 className="heading-secondary">Investment Products</h2>
        </div>

        <Grid spacing={4} container className="indesk-card-one">
          <Grid item lg={2} md={4} sm={6} className=" index-card-colll1">
            <Link to="/ProductFixedIncome">
              <div className="card-main">
                <div className="card__side card__side--front-1">
                  <div className="card-pic-equity-div">
                    <img src={fixedincome} className="caed-pic-equity" />

                    <span className="indesk-card-spn">Fixed Income</span>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__details">
                      <p>
                        Fixed income is a class of assets and securities that
                        pay out a set level of cash flows to investors,
                        typically in the form of fixed interest or dividends.{" "}
                        <span style={{ color: "#0000ff" }}>ReadMore</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
          <Grid item lg={2} md={4} sm={6} className=" index-card-colll1">
            <Link to="/ProductMutualFund">
              <div className="card-main">
                <div className="card__side card__side--front-1">
                  <div className="card-pic-equity-div">
                    <img src={mutualfund} className="caed-pic-equity" />

                    <span className="indesk-card-spn">Mutual Fund</span>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__details">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "justify",
                        }}
                      >
                        A mutual fund is a company that pools money from many
                        investors and invests the money in securities such as
                        stocks, bonds, and short-term debt.{" "}
                        <span style={{ color: "#0000ff" }}>ReadMore</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
          <Grid item lg={2} md={4} sm={6} className=" index-card-colll1">
            <Link to="/ProductPMS">
              <div className="card-main">
                <div className="card__side card__side--front-1">
                  <div className="card-pic-equity-div">
                    <img src={pms} className="caed-pic-equity" />

                    <span className="indesk-card-spn">PMS</span>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__details">
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          textAlign: "justify",
                        }}
                      >
                        Portfolio Management Services (PMS), service offered by
                        the Portfolio Manager, is an investment portfolio in
                        stocks, fixed income, debt, cash, structured products
                        and other individual securities.{" "}
                        <span style={{ color: "#0000ff" }}>ReadMore</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
          <Grid item lg={2} md={4} sm={6} className=" index-card-colll1">
            <Link to="/ProductAIF">
              <div className="card-main">
                <div className="card__side card__side--front-1">
                  <div className="card-pic-equity-div">
                    <img src={aif} className="caed-pic-equity" />

                    <span className="indesk-card-spn">AIF</span>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__details">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "justify",
                        }}
                      >
                        Alternative Investment Funds (AIF for short) are those
                        funds created or established in India as a privately
                        pooled investment vehicle in order to collect funds.{" "}
                        <span style={{ color: "#0000ff" }}>ReadMore</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
          <Grid item lg={2} md={4} sm={6} className=" index-card-colll1">
            <Link to="/PrivateEquity">
              <div className="card-main">
                <div className="card__side card__side--front-1">
                  <div className="card-pic-equity-div">
                    <img src={primaryissue} className="caed-pic-equity" />

                    <span className="indesk-card-spn">Private Equity</span>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__details">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "justify",
                        }}
                      >
                        Private equity, in a nutshell, is the investment of
                        equity capital in private companies.{" "}
                        <span style={{ color: "#0000ff" }}>ReadMore</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
        </Grid>
        <br />
        <Grid spacing={4} container className="indesk-card-one">
          <Grid item lg={2} md={4} sm={6} className=" index-card-colll1">
            <Link to="ProductInsurance">
              <div className="card-main">
                <div className="card__side card__side--front-1">
                  <div className="card-pic-equity-div">
                    <img src={insurance} className="caed-pic-equity" />

                    <span className="indesk-card-spn">Insurance</span>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__details">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "justify",
                        }}
                      >
                        Insurance is a way to manage your risk. When you buy
                        insurance, you purchase protection against unexpected
                        financial losses.{" "}
                        <span style={{ color: "#0000ff" }}>ReadMore</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
          <Grid item lg={2} md={4} sm={6} className=" index-card-colll1">
            <Link to="/ProductRealEstate">
              <div className="card-main">
                <div className="card__side card__side--front-1">
                  <div className="card-pic-equity-div">
                    <img src={realestate} className="caed-pic-equity" />

                    <span className="indesk-card-spn"> Real Estate</span>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__details">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "justify",
                        }}
                      >
                        Real estate Investments generates income or is otherwise
                        intended for investment purposes rather than as a
                        primary residence.{" "}
                        <span style={{ color: "#0000ff" }}>ReadMore</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
          <Grid item lg={2} md={4} sm={6} className=" index-card-colll1">
            <Link to="StartupEcosystem">
              <div className="card-main">
                <div className="card__side card__side--front-1">
                  <div className="card-pic-equity-div">
                    <img src={startupecosystem} className="caed-pic-equity" />

                    <span className="indesk-card-spn">Startup ecosystem</span>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__details">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "justify",
                        }}
                      >
                        A startup ecosystem is a network of resources — people,
                        investors, institutions, and companies — that works
                        together to create an environment for startups to
                        thrive.{" "}
                        <span style={{ color: "#0000ff" }}>ReadMore</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
          <Grid item lg={2} md={4} sm={6} className=" index-card-colll1">
            <Link to="ProductEquity">
              <div className="card-main">
                <div className="card__side card__side--front-1">
                  <div className="card-pic-equity-div">
                    <img src={equity_research} className="caed-pic-equity" />

                    {/* <span className="indesk-card-spn">Equity/research</span> */}
                    <span className="indesk-card-spn">Stock Broking</span>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__details">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "justify",
                        }}
                      >
                        Stock broking involves buying and selling financial
                        instruments, such as stocks and bonds, on behalf of
                        clients through stock exchanges.
                        <span style={{ color: "#0000ff" }}>ReadMore</span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
          <Grid item lg={2} md={4} sm={6} className=" index-card-colll1">
            <Link to="/ProductTraining">
              <div className="card-main">
                <div className="card__side card__side--front-1">
                  <div className="card-pic-equity-div">
                    <img src={training} className="caed-pic-equity" />

                    <span className="indesk-card-spn">Training</span>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__details">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "justify",
                        }}
                      >
                        A Structured Financial market courses designed to
                        accelerate your financial market literacy thereby giving
                        you an edge in your Financial Career.{" "}
                        <span style={{ color: "#0000ff" }}>ReadMore</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default InvestmentProd;
