/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  "& .MuiTextField-root": { m: 1 },
};

const AddNewUnlistedMasterModel = (props: any) => {
  const [reportDocumentFile, setReportDocumentFile] = useState<any>();
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [scriptLogoFile, setScriptLogoFile] = useState<any>();

  const resolver: any = async (values: any) => {
    return {
      values: !values.scriptName ? {} : values,
      errors: !values.scriptName
        ? {
            scriptName: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.isinNumber
        ? {
            isinNumber: {
              type: "required",
              message: "This is required.",
            },
          }
        : values.faceValue.length < 1
        ? {
            faceValue: {
              type: "required",
              message: "This is required.",
            },
          }
        : isNaN(values.faceValue)
        ? {
            faceValue: {
              type: "required",
              message: "This is required.",
            },
          }
        : {},
    };
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: resolver,
  });

  const onSubmit = (data: any) => {
    setshowCircularProgress(true);
    const formData = new FormData();
    formData.append("scriptLogo", scriptLogoFile);
    formData.append("reportDocument", reportDocumentFile);
    formData.append("unlistedDto", JSON.stringify({ ...data }));
    axios
      .post("unlistedMaster/saveUnlistedMaster", formData)
      .then(() => toast.success("Unlisted Added Successfully!"))
      .then(() => {
        setshowCircularProgress(false);
        props.onClose();
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        toast.error(
          err.response.data ? err.response.data.status.message.title : "Error"
        );
      });
  };

  return (
    <>
      <Modal
        keepMounted
        open={props.open}
        onClose={() => props.onClose()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <IconButton
            style={{
              position: "absolute",
              fontSize: "18px",
              top: "8px",
              right: "5px",
            }}
            onClick={() => props.onClose()}
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </IconButton>
          <Typography
            id="keep-mounted-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Add Unlisted
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                id="outlined-basic"
                label="Script Name"
                variant="outlined"
                size="small"
                error={errors.scriptName ? true : false}
                helperText={
                  errors.scriptName === undefined
                    ? ""
                    : errors.scriptName.message + ""
                }
                {...register("scriptName")}
              />

              <TextField
                id="outlined-basic"
                label="ISIN Number"
                variant="outlined"
                size="small"
                error={errors.isinNumber ? true : false}
                helperText={
                  errors.isinNumber === undefined
                    ? ""
                    : errors.isinNumber.message + ""
                }
                {...register("isinNumber")}
              />

              <TextField
                id="outlined-basic"
                label="Face Value"
                variant="outlined"
                size="small"
                type={"number"}
                error={errors.faceValue ? true : false}
                helperText={
                  errors.faceValue === undefined
                    ? ""
                    : errors.faceValue.message + ""
                }
                {...register("faceValue", { valueAsNumber: true })}
              />

              <TextField
                id="outlined-basic"
                label="Investor Link"
                variant="outlined"
                size="small"
                {...register("investorLink")}
              />
            </Grid>

            <Stack spacing={2} mt={2}>
              <Box>
                {/* <FormControl
              error={errors.scriptLogo?true:false}
              helperText={ errors.scriptLogo === undefined? "" : errors.scriptLogo.message + "" } 
            > */}
                <Button variant="contained" component="label">
                  Upload Logo
                  <input
                    hidden
                    type="file"
                    name="form"
                    onChange={(event: any) =>
                      setScriptLogoFile(event.target.files[0])
                    }
                    style={{ marginBottom: "10px" }}
                  />
                </Button>
                <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                  {scriptLogoFile === undefined
                    ? "No file choosen"
                    : scriptLogoFile.name}
                </Typography>
                {/* </FormControl> */}
              </Box>

              <Box>
                <Button variant="contained" component="label">
                  Upload Report File
                  <input
                    hidden
                    type="file"
                    name="form"
                    onChange={(event: any) =>
                      setReportDocumentFile(event.target.files[0])
                    }
                    style={{ marginBottom: "10px" }}
                  />
                </Button>
                <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                  {reportDocumentFile === undefined
                    ? "No file choosen"
                    : reportDocumentFile.name}
                </Typography>
              </Box>
            </Stack>
            <Grid>
              <Button
                type="submit"
                variant="contained"
                sx={{ marginTop: "6%" }}
              >
                {" "}
                {showCircularProgress === true ? (
                  <CircularProgress color="secondary" size={20} thickness={5} />
                ) : (
                  ""
                )}
                {"  "} Submit
              </Button>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddNewUnlistedMasterModel;
