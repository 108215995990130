import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect } from 'react'
import { withParam } from '../../utils/Router.Helper';
import axios from 'axios';
import { GET_SCHEME_DATA_FROM_AMC_ID } from '../../utils/API_Names';
import AddScheme from './AddScheme';
import { Link } from 'react-router-dom';
import AmcInsuranceSwitch from './AmcInsuranceSwitch';


interface PropsType {
  params: {
    amcId: String;
    ProductMasterCode: string;
  };
}

const AmcInsurance = (props: PropsType) => {
  console.log("manage",props)
  const { amcId } = props.params;
    const [dataInsuranceamc,setInsuranceamc] = React.useState<any>([])
    const [amcName, setAMCName] = React.useState("");
    const [productCode, setProductCode] = React.useState("");
    const [ProductMasterCode,setProductMasterCode ] = React.useState("");
    const [refresh, setRefresh] = React.useState(true);
    
      useEffect(() => {
        axios.get(`${GET_SCHEME_DATA_FROM_AMC_ID}${amcId}`)
          .then((res: any) => {
            setInsuranceamc(res.data.amcDetails);
            setAMCName(res.data.amcDetails[0].amcName);
            setProductMasterCode(res.data.amcDetails[0].listOfFunds[0].productMasterCode)
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }, []);
     
  console.log("priewuhjgry",ProductMasterCode)
  return (
    <div>
       <Grid >
        <AddScheme amcId={amcId} amcName={amcName}  productCode={"Insurance"}/>
      </Grid>
     <Grid mt={5}>
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell align="center"></TableCell>
            <TableCell align="center">Category</TableCell>
            <TableCell align="center">Product Name</TableCell>
            <TableCell align="center">Insurance Type</TableCell>
            <TableCell align="center">Payment Type</TableCell>
            {sessionStorage.getItem("Profile") === "B" && (
      <>
        <TableCell align="center">First Year</TableCell>
        <TableCell align="center">Second Year</TableCell>
        <TableCell align="center">Third Year</TableCell>
      </>
    )}
    <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { dataInsuranceamc[0]?.listOfFunds?.map((row:any,index: number) => (
            <TableRow
            key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell><img  style={{width:"30px"}}  src={row.insLogo} alt="loanLogo" /></TableCell>
              <TableCell align="center">{row.category}</TableCell>
              <TableCell align="center" sx={{textDecoration: "underline"}}>
                    <Link to={"/AmcInsuranceScheme/"+ row.productMasterCode}  > {row.productName}</Link>
             </TableCell>
              <TableCell align="center">{row.insuranceType}</TableCell>
              <TableCell align="center">{row.paymentType}</TableCell>
              {sessionStorage.getItem("Profile") === "B" && (
      <>
        <TableCell align="center">{row.firstYearRevenue}</TableCell>
        <TableCell align="center">{row.secondYearRevenue}</TableCell>
        <TableCell align="center">{row.thirdYearRevenue}</TableCell>
      </>
    )}
          <TableCell align="left" sx={{display:"flex", p:"17px"}}>
          <AmcInsuranceSwitch
            amcId={row.amcId} 
            productType={row.productCode}
            productMasterCode={row.productMasterCode} 
            isActive={row.isActive} 
            setRefresh={setRefresh} 
            refresh={refresh}
          />



            </TableCell>  
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
     </Grid>
    </div>
  )
}

export default withParam(AmcInsurance)




