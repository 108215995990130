import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import Container from '@mui/material/Container'
import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import StartWithUs from '../StartWithUs/StartWithUs';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';


const ProductInsurance = () => {
  return (
    <section>
        <Container>
        <Grid md={12} className={sessionStorage.getItem("JWT") ? 'your-dynamic-css-class-product' : 'default-class-product'}>
       <Typography style={{ textAlign: "left", fontSize: "30px", fontWeight: "bold",borderBottom: "4px solid black"}}>Insurance </Typography>
       <Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold", textDecoration: "underline",paddingTop:"10px" }}>What is the Definition of Insurance?</Typography>
       <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
       Insurance is a legal contract between a person and an insurance business in which the 
insurer promises to provide financial protection (Sum guaranteed) against unforeseen events 
for a certain price (premium).  
      </Typography>
      </Grid>
      <br />
      <Typography style={{ textAlign: "left", fontSize: "25px", fontWeight: "bold"}}>There are typically 2 type of Insurances  </Typography>
      <List>
      <ListItem>
        <ListItemText primary="•	General Insurance" />
      </ListItem>
      <ListItem>
        <ListItemText primary="• Life Insurance " />
      </ListItem>
    </List>
    <br />
      <Typography style={{ textAlign: "left", fontSize: "25px", fontWeight: "bold"}}>1. General Insurance</Typography>
      <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "bold"}}>Some of the kinds of general insurance offered in India are as follows :</Typography>
    
      <List>
      <ListItem>
        <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Health Care Coverage" />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Automobile Insurance" />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Insurance against fire" />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Insurance for Travel" />
      </ListItem>
    </List>
    <br />

    <Typography style={{ textAlign: "left", fontSize: "25px", fontWeight: "bold"}}>2. Life Insurance </Typography>
      <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "bold"}}>Life insurance comes in a variety of forms. The most prevalent types of life insurance policies 
offered in India are as follows : </Typography>
    
      <List>
      <ListItem>
        <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Term Life Insurance" />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Unit-Linked Insurance Plans " />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Whole Life Insurance" />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Endowment Plans" />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Child Plans for Educations" />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Retirement Plans" />
      </ListItem>
      
    </List>
    <br />
   
  




{sessionStorage.getItem("JWT")?"":
<Grid mt={2} mb={2}>
<StartWithUs/>
</Grid>
}
        </Container>
    </section>
  )
}

export default ProductInsurance