import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { TabContext } from "@mui/lab";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OnGoingNCD from "./OnGoingNCD";
import UpComingNCD from "./UpComingNCD";
import AddUpdateNCDModel from "./AddUpdateNCDModel";
import { useQuery } from "react-query";
import { getAllNCD } from "../../../api/Api";

const NCD = () => {
  const [value, setValue] = React.useState("0");
  const [addNcdModel, setAddNcdModel] = useState(false);
  const [ncdData, setNcdData] = React.useState<any>([]);

  const { data, refetch } = useQuery("getNdcData", () => getAllNCD(), {
    onSuccess: (res: any) => {
      setNcdData(res.data.ncdDTOS);
    },
    staleTime: Infinity,
    cacheTime: 1000,
  });

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  function handleChangeAddNcdModel(status: any) {
    status === "open" ? (
      setAddNcdModel(true)
    ) : status === "close" ? (
      setAddNcdModel(false)
    ) : (
      <></>
    );
  }

  let ongoing: any = {};
  let upcoming: any = {};
  let closed: any = {};

  ncdData &&
    ncdData.map((ncd: any, index: any) => {
      const currentDate = new Date();
      const openDate = new Date(ncd.openDate);
      const closeDate = new Date(ncd.closeDate);

      if (openDate > currentDate) {
        upcoming[index] = ncd;
      } else if (closeDate < currentDate) {
        closed[index] = ncd;
      } else if (openDate < currentDate && closeDate > currentDate) {
        ongoing[index] = ncd;
      }
    });

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={12}
        justifyContent="space-between"
        marginBottom={1}
      >
        <Grid item>
          <Typography variant="h5" textAlign={"center"}>
            Non Convertible Debenture's{" "}
          </Typography>
        </Grid>
        {sessionStorage.getItem("Profile") === "B" && (
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                handleChangeAddNcdModel("open");
              }}
            >
              Add NCD's{" "}
            </Button>
          </Grid>
        )}
      </Grid>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="On Going NCD's" value="0" />
            <Tab label="Upcoming NCD's" value="1" />
            <Tab label="Closed NCD's" value="2" />
          </TabList>
        </Box>
        <TabPanel value="0">
          <OnGoingNCD
            data={
              ncdData &&
              Object.entries(ongoing).map((ongoingData: any) => ongoingData[1])
            }
          />
        </TabPanel>
        <TabPanel value="1">
          <UpComingNCD
            data={ncdData && Object.entries(upcoming).map((en: any) => en[1])}
          />
        </TabPanel>
        <TabPanel value="2">
          <UpComingNCD
            data={ncdData && Object.entries(closed).map((en: any) => en[1])}
          />
        </TabPanel>
      </TabContext>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          variant="subtitle2"
          textAlign={"end"}
          style={{ color: "red" }}
          sx={{ marginRight: 3 }}
        >
          <b>
            Note- In case of joint account application applicant should be
            primary holder
          </b>
        </Typography>
      </Box>

      <AddUpdateNCDModel
        open={addNcdModel}
        onClose={() => handleChangeAddNcdModel("close")}
        data={ncdData}
        refetch={refetch}
      />
    </>
  );
};

export default NCD;
