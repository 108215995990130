import { Box, Button, CircularProgress, Grid, IconButton, Modal, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { SAVE_RESEARCH_SERVICES_PLAN } from '../../../utils/API_Names';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    "& .MuiTextField-root": { m: 1 },
};

const AddNewResearchServicesPlanModel = (props: any) => {

    const [showCircularProgress, setshowCircularProgress] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset} = useForm();

    const onSubmit = (data: any) => {
        // console.log("mera Data",data)
        axios.post(SAVE_RESEARCH_SERVICES_PLAN, data)
            .then(() => toast.success("Research Services Plan Added Successfully!"))
            .then(()=>window.location.reload())
            // .then(() => { setshowCircularProgress(false); props.onClose();reset() })
            .catch((err: any) => { setshowCircularProgress(false); toast.error(err.response.data ? err.response.data.status.message.title : "Error") });
    }
    const registerOptions = {
        subscriptionPlan: { required: "Please Enter Subscription Plan" },
        planDescription: { required: "Please Enter Plan Description" },
    }
    return (
        <>
            <Modal
                keepMounted
                open={props.open}
                onClose={() => props.onClose()}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                <IconButton style={{ position: 'absolute',fontSize:"18px" ,top: "8px", right: "5px"}} onClick={() => props.onClose()}>
      <CloseIcon style={{fontSize:"18px"}} />
    </IconButton>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Add Research Services Plan
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <Grid style={{display: "flex", flexDirection:"column"}}>
                        <TextField
                            id="outlined-basic"
                            label="Subscription Plan"
                            variant="outlined"
                            error={errors.subscriptionPlan ? true : false}
                            helperText={!errors.subscriptionPlan ? "" : errors.subscriptionPlan.message + ""}
                            {...register("subscriptionPlan",registerOptions.subscriptionPlan)}
                        />

                        <TextField
                            id="outlined-basic"
                            label="Plan Description"
                            variant="outlined"
                            error={errors.planDescription ? true : false}
                            helperText={!errors.planDescription ? "" : errors.planDescription.message + ""}
                            {...register("planDescription",registerOptions.planDescription)}
                        />
                        </Grid>
                        <Grid style={{textAlign:"center"}}>
                        <Button type="submit" variant="contained" sx={{ marginTop: '6%' }}> {showCircularProgress ? (
                            <CircularProgress
                                color="secondary"
                                size={20}
                                thickness={5}
                            />
                        ) : (
                            ""
                        )}{"  "} Submit</Button>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </>
    )
}

export default AddNewResearchServicesPlanModel