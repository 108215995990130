import { Container, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BOPayoutTables from './BOPayoutTables'
import axios from 'axios';

const BackOffPayoutDash = () => {

    const[payoutList, setPayoutList] = useState<any>();
    const[paidUnpaid, setPaidUnpaid] = useState<any>([]);

    // useEffect(() => {
    //   axios.get("payout/getPayoutOfProduct").then((res:any)=>{
    //     setPayoutList(res.data.payoutList);
    //   });

    //   axios.get("payout/getTotalPaidUnpaidPayout").then((res:any)=>{
    //     setPaidUnpaid(res.data.payoutList);
    //   })
    // }, [])

    console.log("Payout", payoutList)
    console.log("PaidUnpaid", paidUnpaid)

  return (
    <Container maxWidth="xl" sx={{ backgroundColor:'#eef2f6'}}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid sx={{marginTop:'17px', marginBottom:'5%'}} item xs={12} md={12}>
                        <BOPayoutTables/>
                    </Grid>
                </Grid> 
            </Grid>
        </Grid>
    </Container>
  )
}

export default BackOffPayoutDash