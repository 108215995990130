export const fundCategories = [
  { id: 1, name: "Large Cap", value: "Large Cap" },
  { id: 2, name: "Mid Cap", value: "Mid Cap" },
  { id: 3, name: "Small Cap", value: "Small Cap" },
  { id: 4, name: "Flexi Cap", value: "Flexi Cap" },
  { id: 5, name: "Multi Cap", value: "Multi Cap" },
  { id: 6, name: "Large & Mid Cap", value: "Large & Mid Cap" },
  { id: 7, name: "Small & Mid Cap", value: "Small & Mid Cap" },
  { id: 8, name: "Thematic", value: "Thematic" },
  { id: 9, name: "Arbitrage", value: "Arbitrage" },
  { id: 10, name: "ETF", value: "ETF" },
  { id: 11, name: "Multi Asset", value: "Multi Asset" },
  { id: 12, name: "Debt", value: "Debt" },
  { id: 13, name: "Others", value: "others" },
  { id: 14, name: "Sectoral", value: "Sectoral" },
];
