import { Button, Grid } from '@mui/material'
import React, { useState } from "react";
import AddNewResearchServicesPlanModel from './AddNewResearchServicesPlanModel';
import ResearchPlans from './ResearchPlans';
import AddNewResearchServicesModel from './AddNewResearchServicesModel';

const ResearchServices = () => {

    const [researchServicesPlanModel, setResearchServicesPlanModel] = useState(false);
    const [researchServicesModel, setResearchServicesModel] = useState(false);


    function addResearchServicesPlanModel(status: any) {
        status === "open" ? (
            setResearchServicesPlanModel(true)
        ) : status === "close" ? (
            setResearchServicesPlanModel(false)
        ) : (
            <></>
        );
    }

    function addResearchServicesModel(status: any) {
        status === "open" ? (
            setResearchServicesModel(true)
        ) : status === "close" ? (
            setResearchServicesModel(false)
        ) : (
            <></>
        );
    }

    return (
        <>
            {sessionStorage.getItem("Profile") === "B" || sessionStorage.getItem("Profile") === "M" ?
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={6}>

                        <Button
                            variant="contained"
                            sx={{ mb: 2 }}
                            onClick={() => addResearchServicesPlanModel("open")}
                        >
                            Add New Plan
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            sx={{ mb: 2 }}
                            onClick={() => addResearchServicesModel("open")}
                        >
                            Add New Research Services
                        </Button>
                    </Grid>
                </Grid>: <></>
            }
            <ResearchPlans />

            <AddNewResearchServicesPlanModel
                open={researchServicesPlanModel}
                onClose={() => addResearchServicesPlanModel("close")}
            />
            <AddNewResearchServicesModel
                open={researchServicesModel}
                onClose={() => addResearchServicesModel("close")}
            />
        </>
    )
}

export default ResearchServices