import React from "react";
import { Modal, Box, TextField, Button } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const ManufactureUnlistedEditModal = ({
  open,
  handleClose,
  editedFields,
  handleFieldChange,
  handleSave,
}) => {
  const inputStyle = { width: "100%" };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...modalStyle, width: 400 }}>
        <h2 style={{ textAlign: "center" }}>{editedFields?.scriptName}</h2>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            label="New Valid Till Date"
            inputFormat="YYYY-MM-DD"
            disablePast
            value={editedFields.newValidTillDate}
            onChange={(newValue) =>
              handleFieldChange("newValidTillDate", newValue)
            }
            renderInput={(params) => <TextField {...params} sx={inputStyle} />}
          />
        </LocalizationProvider>
        <TextField
          label="New Quantity"
          type="number"
          value={editedFields.newQuantity}
          onChange={(e) => handleFieldChange("newQuantity", e.target.value)}
          sx={inputStyle}
          margin="normal"
        />
        <TextField
          label="New Cost Price"
          type="number"
          value={editedFields.newCostPrice}
          onChange={(e) =>
            handleFieldChange("newManufactureBuyRate", e.target.value)
          }
          sx={inputStyle}
          margin="normal"
        />
        <TextField
          label="New Carrying Cost"
          type="number"
          value={editedFields.newCarryingCost}
          onChange={(e) =>
            handleFieldChange("newManufactureCarryingCost", e.target.value)
          }
          sx={inputStyle}
          margin="normal"
        />
        <TextField
          label="New Landing Price"
          type="number"
          value={editedFields.newLandingPrice}
          onChange={(e) =>
            handleFieldChange("newManufactureSaleRate", e.target.value)
          }
          sx={inputStyle}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave(editedFields.productMasterCode)}
          sx={{ mt: 2 }}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default ManufactureUnlistedEditModal;
