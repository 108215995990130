import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import AddUpdateNCDModel from "./AddUpdateNCDModel";
import { changeDate } from './../../../utils/Helper';
import { Link, useNavigate } from "react-router-dom";

function createData(
  IF: string,
  CR: number,
  EY: number,
  BR: number
) {
  return { IF, CR, EY, BR };
}

const OnGoingNCD = (props:any) => {
  const { data } = props;

  const [addNcdModel, setAddNcdModel] = React.useState(false);
  const [ncdData, setNcdData] = React.useState<any>([]);

  function handleChangeAddNcdModel(status: any) {
    status === "open" ? (
      setAddNcdModel(true)
    ) : status === "close" ? (
      setAddNcdModel(false)
    ) : (
      <></>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
            <TableCell align="left" width={50}>Logo</TableCell>
            <TableCell align="left" >Company Name</TableCell>
            <TableCell align="left" >Issue Price</TableCell>
            <TableCell align="left" >Open Date</TableCell>
            <TableCell align="left" >Close Date</TableCell>
            <TableCell align="left" >Revenue Percent</TableCell>
            <TableCell align="left" >Bond Rating</TableCell>
            { sessionStorage.getItem("Profile")==="B" && <TableCell align="left">Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data?.map((ncd:any) => (
              <TableRow
                key={ncd.productMasterCode}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left" component="th" scope="row">
                  
                  <img src={ncd.ncdCompanyLogo} alt="NCD Logo" width={"100%"}/>
                </TableCell>
                <TableCell align="left" sx={{textDecoration: 'underline'}}>
                <Link to={"/NcdDetails/"+ncd.ncdId}>
                      {ncd.ncdName}
                  </Link></TableCell>
                <TableCell align="left">{ncd.issuePrice}</TableCell>
                <TableCell align="left">{changeDate(ncd.openDate)}</TableCell>
                <TableCell align="left">{changeDate(ncd.closeDate)}</TableCell>
                <TableCell align="left">{ncd.revenueSharing}</TableCell>
                <TableCell align="left">{ncd.bondRating}</TableCell>
                { sessionStorage.getItem("Profile")==="B" && <TableCell align="left">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setNcdData(ncd);
                      handleChangeAddNcdModel("open");
                    }}
                    size="small"
                  >
                    {" "}
                    Edit NCD's{" "}
                  </Button>
                </TableCell> }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddUpdateNCDModel
        open={addNcdModel}
        onClose={() => handleChangeAddNcdModel("close")}
        data={ncdData}
      />
    </>
  );
};

export default OnGoingNCD;
