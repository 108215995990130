import { Box, CircularProgress, Grid, ThemeProvider, Typography, createTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getPortfolioComposition, getSegmentationAllocation } from '../../../api/Api'
import { Doughnut } from 'react-chartjs-2'

const PortfolioAllocation = () => {
  const [pcdata, setpcData] = useState<any>([])
  const [SegmentData, SetSegment] = useState([])
  const [riskmeterdata, riskmeter] = useState([])
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const defaultTheme = createTheme()

  useEffect(() => {
    setshowCircularProgress(true);
    getPortfolioComposition().then((response) => { setpcData(response.data) })
    getSegmentationAllocation().then((response) => { SetSegment(response.data.segmentWiseResponseArrays) }).then(()=>{
      setshowCircularProgress(false);
    })
  }, [])

  const data = {
    labels:pcdata.arrayResponses?.filter((auto:any)=>auto.holdingAmount!==0).map((item:any)=>(item.accountType)),
      datasets: [
          
        {
          label: 'hdhdhd# of Votes',
          data: pcdata.arrayResponses?.filter((auto:any)=>auto.holdingAmount!==0).map((item:any)=>(item.holdingAmount)), 
          backgroundColor: [
            "#b1dffa",
            "#f5e9d2",
            "#b2dce3",
            "#c3edca",
            "#dedede",
            "#f6d1ab",
            "#c8908e",
            "#615376",
            "#bccddb",
            "#ddcfe8",
            "#dfcbb1",
          ],
          display:true,
          borderColor: "#CCF0FF"
        }
      ]
    };
 
 
    
const DataOne = {
  labels:SegmentData?.map((item:any)=>(item.name)),
    datasets: [
        
      {
        
        label: 'hdhdhd# of Votes',
        data: SegmentData?.map((item:any)=>(item.value)), 
        backgroundColor: [
          "#ddcfe8",
          "#b2dce3",
          "#c3edca",
          "#dedede",
          "#f6d1ab",
          "#b1dffa",
          "#f5e9d2",
          "#615376",
          "#bccddb",
          "#dfcbb1", 
          "#c8908e",
 
        ],
        display:true,
        borderColor: "#CCF0FF"
      }
    ]
  };
  return (
    <div>
     {showCircularProgress == true ? (
                    <CircularProgress
                      color="secondary"
                      size={50}
                      thickness={5}
                    />
                  ) : (
                    <ThemeProvider theme={defaultTheme}>
                    <Grid container className='main-tab-portfolio-composition' style={{ textAlign: 'center', background: 'aliceblue' }}>
                        <Typography variant='h6' className='main-tab-portfolio-composition-h2-tag'>{sessionStorage.getItem('riskmeter')}</Typography>
                    
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <span className='assest-aegment-allocation-all-h4' style={{ borderRadius: '25px' }}>Assest Allocation</span>
                            <Box className="portfolio-composition-chart-main-div" sx={{ margin: '20px auto', width: '70%' }}>
                            <Doughnut
     data={data}
     options={{
       plugins: {
         legend: {
           display: true,
           position:'bottom',
           labels:{
        color: 'rgb(11, 0, 0)',
        usePointStyle: true,
           }
         },
         tooltip: {
           enabled: true
         }
       },
       rotation: -90,
       circumference: 360,
       cutout: "70%",
       maintainAspectRatio: true,
       responsive: true,
       
     }}
    />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <span className='assest-aegment-allocation-all-h4' style={{ borderRadius: '25px' }}>Segment Allocation </span>
                            <Box className="portfolio-composition-chart-main-div" sx={{ margin: '20px auto', width: '70%' }}>
                            <Doughnut
      data={DataOne}
      options={{
        plugins: {
          legend: {
            display: true,
            position:'bottom',
            labels:{
         color: 'rgb(11, 0, 0)',
         usePointStyle: true,
            }
          },
          tooltip: {
            enabled: true
          }
        },
        rotation: -90,
        circumference: 360,
        cutout: "70%",
        maintainAspectRatio: true,
        responsive: true,
        
      }}
    />
                            </Box>
                        </Grid>
                    </Grid>
                    </ThemeProvider>
                  )}
    </div>
  )
}

export default PortfolioAllocation