import React from 'react'
import { Link } from 'react-router-dom'
import newreleaseblog_02 from "../images/newreleaseblog_02.png"
import "../css/blogs1.css"
import { Container } from '@mui/material'

const Blog3 = () => {
  return (
    <Container maxWidth="lg">
<section className="blog-single">
	<div className="container cont1">
		<div className="row">
			<div className="col-md-10 m-auto single-page-col-main">
		
				<article className="single-post">
				
					<div className="post-title text-center">
						<h1 className='blog-single-main-education-h1'>
                        <p>Importance of smart and informed financial planning for child education</p>
                        </h1>
					    <ul className="list-inline post-tag single-blog-date">
						  <li className="list-inline-item">
								<span>September 26, 2021</span>
							</li>
						</ul>
					</div>
				
					<div className="post-body">
				     <div className="feature-image"style={{textAlign:'center'}} >
						<img src={newreleaseblog_02} alt="" style={{width:"43%"}}  />
						</div>
						<div className="blog-page-desp-p-tag">
    <p>Education is an imperative instrument for the growth of a country's economy, generating
awareness and creating a better environment overall. A well-educated population,
powered with the appropriate knowledge, proficiency and expertise is essential for the
economic and social development of a country. The education sector has a massive
responsibility of shaping the future of a nation. In 2011, around 50% of our population
was less than 24 years of age. Today, around two thirds of our population is in the
working age group (15-64 years). Over the next 2 decades, the working population in
India is expected to rise, and quality higher education could be one of the important
factors in the growth of our economy.
    </p>
    <br/>
    <p>The median age of India is 28.4 years, which means the majority of the country
comprises young students and youth. Now, Indian parents have become more inclined
towards providing their wards higher and quality education and are ready to shell out
huge sums even at the cost of sacrificing their retirement plans. According to the report,
titled The Value of Education, the Price of Success, by HSBC, the cost of higher
education is being financed by parents by giving up on personal time to earn the extra
money needed to send their children to university. The study said one in two parents
take up a second job or work for extra hours to get the money needed. HSBC polled
10,478 parents and 1,507 university students across 15 countries and territories. The
findings were based on a sample of parents with at least one child of age 23 years or
younger currently pursuing degree courses and on a sample of students aged 18 to 34
in university undergraduate or postgraduate education.
<br/>
<br/>
        <h4>According to the report, 71% of parents and 82% of
students agree that despite the significant cost and
sacrifices involved in studying at university, it is a
worthwhile investment.</h4>
<br/>

<b>69 percent of students believe that higher education leads to better opportunities to
secure a first job, 56 percent think that it gives better job prospects throughout their
career and 53 percent believe university education provides faster career progression
and aids in earning more money.</b>
    </p>
    <p>
	As per ET Online research , Indian parents spends on an average Rs 30 lakh for their
child's schooling between age 3 to age 17. Various research suggests that the cost of
college education ranges between Rs 8 lakhs to Rs 45 Lakhs depending upon the
program chosen. There are various other expenses which go before university education
including coaching or entrance preparation fees, accommodation, transport, laptops,
textbooks etc. Taking into account the amount parents contribute and the actual
education expenses, one can clearly see the significant funding gap the families face.
To fill up this gap, parents take education loans, work extra hours or take up an
additional job, or even borrow from friends and relatives. Students too, face a big
challenge balancing work and study due to lack of funds. On an average day, students
spend 2.5 hours in paid employment rather than in the library or studying at home, trying
to bridge the funding gap.
    </p>
    <p>
	The study finding says, 48% wish they had started saving for their child’s education
earlier, 59% worry they don’t have the financial resources to support their child’s
education and 40% wish they had saved more regularly. This is the high time that
parents must realize the importance of planning their finances well to be able to easily
fund their child's education without feeling the extra pressure. Proactive planning and
being aware of the best possible choices by taking help from a professional financial
advisor could ensure better financial outcomes for parents and students alike.
    </p>
</div>
					</div>
				</article>
			
			
			</div>
		</div>
		

<div className='wrapper-next-butoon-blogs-span-main'>
<div className="wrapper-next-butoon-blogs">
  <a className="cta-privious" href="">
	{/* <Link to={"/BlogSingle2"}><span className='wrapper-next-butoon-blogs-span'>PREVIOUS</span> </Link>
    <span className='wrapper-next-butoon-blogs-span'>
      <svg style={{transform: "rotate(180deg)"}} width="px" height="43px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path className="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path className="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path className="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
        </g>
      </svg>
    </span>  */}
  </a>
</div>
<div className='wrapper-next-butoon-blogs-home-main1'>
{/* <Link to={"/Blogs"}><span className='wrapper-next-butoon-blogs2-span'><AiOutlineHome className='wrapper-next-butoon-blogs2-home-icone' title="HOME"></AiOutlineHome></span> </Link> */}
</div>
<div className="wrapper-next-butoon-blogs">
  <a className="cta" href="">
	{/* <Link to={"/BlogSingle4"}><span className='wrapper-next-butoon-blogs-span'>NEXT</span> </Link>
    <span className='wrapper-next-butoon-blogs-span'>
      <svg width="px" height="43px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path className="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path className="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path className="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
        </g>
      </svg>
    </span>  */}
  </a>
</div>
</div>
    {/* <div className="blog-single-related-artical">
		<div className="row">
			<div className="col-12 ">
			<h2 className='blog-single-artical-h2'>Related Articles</h2>
			</div>
		</div>
		<div className="row">
			<div className="col-lg-4 col-md-6">
               <article className="post-sm">
				
					<div className="post-thumb">
						<a href="blog-single.html"><img src={post} alt="" /></a>
					</div>
				
					<div className="post-title blog-single-post-title">
						<h3><a href="blog-single.html">Praesent sapien massa, convallis a pellentesque nec</a></h3>
					</div>
				
					<div className="post-meta">
						<ul className="list-inline post-tag">
							<li className="list-inline-item">
							
                                <img src={feature} alt="" />
							</li>
							<li className="list-inline-item">
								<a href="#">Rozy Afzal</a>
							</li>
							<li className="list-inline-item">
								August 22, 2021
							</li>
						</ul>
					</div>
				
					<div className="post-details">
						<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. </p>
					</div>
				</article>
			</div>
			<div className="col-lg-4 col-md-6">
		
				<article className="post-sm">
		
					<div className="post-thumb">
						<a href="blog-single.html"><img src={posts} alt="" /></a>
					</div>
				
					<div className="post-title blog-single-post-title">
						<h3><a href="blog-single.html">Praesent sapien massa, convallis a pellentesque nec</a></h3>
					</div>
				
					<div className="post-meta">
						<ul className="list-inline post-tag">
							<li className="list-inline-item">
							
                                <img src={feature} alt="" />
							</li>
							<li className="list-inline-item">
								<a href="#">Rozy Afzal</a>
							</li>
							<li className="list-inline-item">
								August 14, 2021
							</li>
						</ul>
					</div>
				
					<div className="post-details">
						<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. </p>
					</div>
				</article>
			</div>
			<div className="col-lg-4 col-md-6">
		
				<article className="post-sm">
				
					<div className="post-thumb">
						<a href="blog-single.html"><img src={postes} alt="" /></a>
					</div>
				
					<div className="post-title blog-single-post-title">
						<h3><a href="blog-single.html">Praesent sapien massa, convallis a pellentesque nec</a></h3>
					</div>
			
					<div className="post-meta">
						<ul className="list-inline post-tag">
							<li className="list-inline-item">
							
                                <img src={feature} alt="" />
							</li>
							<li className="list-inline-item">
								<a href="#">Rozy Afzal</a>
							</li>
							<li className="list-inline-item">
								August 11, 2021
							</li>
						</ul>
					</div>
				
					<div className="post-details">
						<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. </p>
					</div>
				</article>
			</div>
		</div>
	</div> */}
	</div>
</section>


      </Container>
  )
}

export default Blog3