import { Button, Card, CardActions, CardContent, CardMedia, Paper, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';

export const InformationBox = (props:any) => {
	const { title1 } = props
	const { title2 } = props
	const { title3 } = props
	const { title4 } = props
	const { Count } = props
  return (
	<Paper elevation={6} >
		 <Card>
      <CardMedia
        image="/static/images/cards/contemplative-reptile.jpg"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" >
          { title1 }
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
          { title2 }
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
          { title3 }
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
          { title4 }
        </Typography>
        <Typography variant="h6" color="text.secondary">
          { Count }
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
	</Paper>
  )
}