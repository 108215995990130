import { Box, Grid, Tab, Typography } from '@mui/material'
import React from 'react'
import ManageAmcSortTable from './ManageAmcSortTable'
import { withParam } from '../../utils/Router.Helper'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import OrderBookTableData from '../OrderBookTableData'
import AmcLoan from './AmcLoan'
import AmcInsurance from './AmcInsurance'

const ManageAmc = () => {
	const [value, setValue] = React.useState('PMS');

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };
	  console.log(";lkjhgfdghjkl",value)
	return (
		
	<>
		<Typography variant='h3'gutterBottom> Manage AMC </Typography>
		<Grid item xs={12}>
    
	<Box sx={{ width: '100%', typography: 'body1' }}>
		  <TabContext value={value}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
			  <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
			  <Tab label="pms" value="PMS" />
				<Tab label="aif" value="AIF" />
				<Tab label="loan" value="LOAN" />
				<Tab label="insurance" value="INSURANCE" />
			  </TabList>
			</Box>
			<TabPanel value="PMS">
			  {value === "PMS" && <ManageAmcSortTable valueClick={value} />}
			</TabPanel>
			<TabPanel value="AIF">
			{value === "AIF" && <ManageAmcSortTable valueClick={value} />}
			</TabPanel>
			<TabPanel value="LOAN">
			{/* <AmcLoan></AmcLoan> */}
			{value === "LOAN" && <ManageAmcSortTable valueClick={value} />}
			</TabPanel>
			<TabPanel value="INSURANCE">
			{value === "INSURANCE" && <ManageAmcSortTable valueClick={value} />}
		     {/* <AmcInsurance></AmcInsurance> */}
			  </TabPanel>
		  </TabContext>
		</Box>
	</Grid>




		{/* <ManageAmcSortTable/> */}
	</>
  )
}

export default withParam(ManageAmc) 