/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import BOPayoutTables from '../BOPayoutDashBoard/BOPayoutTables'
import TotalGrowthBarChart from '../PayoutDashboard/TotalGrowthBarChart'
// import TotalGrowthBarChart from '../../TotalGrowthBarChart'

const EmployeePayout = () => {
  return (
	<>
	{/* <BOPayoutTables/> */}
	<TotalGrowthBarChart />
	</>
  )
}

export default EmployeePayout