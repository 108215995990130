import React, { useState } from 'react'
import { withParam } from '../../utils/Router.Helper'
import { UnlistedMasterShareById } from '../../api/Api';
import { Viewer } from '@react-pdf-viewer/core';
import { Container , Box, Typography, Button, Modal, TextField, Stack, Grid, IconButton, Tooltip } from '@mui/material'
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { DownloadDocument } from './DownloadDocument';
import AddNewUnlistedMasterModel from './AddNewUnlistedMasterModel';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const AdminUnlistedMasterDetails = (props:any) => {
  const zoomPluginInstance = zoomPlugin();
	const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
    const clientDetails = props?.clientDetails;
    const [unlistedModel, setUnlistedModel] = useState(false)
    const [file, setFile] = useState<any>([]);
    const [reportDocumentFile, setReportDocumentFile] = useState<any>();
    const [logoFile, setLogoFile] = useState<any>();
    const [clientFile, setClientFile] = useState<any>("");
    const {data, isLoading, error, isError, refetch} = useQuery("UnlistedMasterShareById",()=>UnlistedMasterShareById(props.params.unlistedMasterCode)
    // ,{
    //     enabled: false,
    //   }
      );
    const [clientFileDocId, setClientFileDocId] = useState<any>("");
    console.log(data)
    const [viewDocumentOpen, setViewDocumentOpen] = useState(false)

    function updateUnlistedShareModel(status:any){
        status==="open"?
        setUnlistedModel(true)
        : status==="close" ? setUnlistedModel(false) : <></>
    }

    const resolver: any = async (values: any) => {
        return {
            values: !values.scriptName ? {} : values,
            errors: !values.scriptName ? {
                scriptName: {
                  type: "required",
                  message: "This is required.",
                },
              }
              :values.faceValue.length < 1
              ?{
                faceValue : {
                  type: "required",
                  message: "This is required.",
                }
              } 
              : isNaN(values.faceValue)?{
                faceValue : {
                  type: "required",
                  message: "This is required.",
                }
              }:{}
        }
    }

    const { register, handleSubmit, formState : {errors} } = useForm({
        resolver:resolver
    });

    const disabledClassNameProps = { className: "Mui-disabled" };

    const onSubmit = (data: any) => {
        console.log("data--",data)
        const formData = new FormData();
        file.map((item:any) => {
            formData.append(item[0],item[1]);
        })
        formData.append("unlistedDto",JSON.stringify({...data,unlistedMasterCode:props.params.unlistedMasterCode}))
        formData.append("reportDocument",reportDocumentFile);
        formData.append("scriptLogo",logoFile);
        axios.put("unlistedMaster/updateUnlistedMaster",formData).then(()=>toast.success("Updated Successfully!"))
        .then(()=>{updateUnlistedShareModel("close");refetch()})
        .then(() => {setTimeout(() => {window.location.reload();}, 3000);})
        .catch((err:any)=>toast.error(!!err.response?.data?.status?.message?.title ? err.response.data.status.message.title : err.response.data.messageType))
    }
    const fileDownload = (uuid:any) => {
      axios({
        url: "documentupload/download?documentId="+uuid,
        method: "GET",
        responseType: "blob" // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link:any = document.createElement("a");
        link.href = url;
        var date1 = new Date();
        link.setAttribute(
            "download",
            "Download_Document" + ("0" + date1.getDate()).slice(-2) + "_" + ("0" + (date1.getMonth() + 1)).slice(-2) + "_" + date1.getFullYear() + ".pdf"
        );
        document.body.appendChild(link);
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
    }

    const activeInactiveUnlistedMaster = () => {
        axios.put("unlistedMaster/softDeleteUnlistedMaster/"+props.params.unlistedMasterCode)
        .then(()=>{toast.success("Success");refetch()})
        .catch((err:any)=>toast.error(!!err.response?.data?.status?.message?.title ? err.response.data.status.message.title : err.response.data.messageType))
    }
    const viewDocument = (fileType:any) => {
      axios.get("documentupload?documentId="+fileType).then((res:any)=>{
            setClientFile(res?.data?.documentFilePath)
            setClientFileDocId(fileType);
            console.log("includes",res?.data?.documentFilePath.includes(".pdf"))
          })
    }
    console.log("clientFile",clientFile)
    return (
        <Container maxWidth={'xl'}>
        <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "3%" , textAlign:'left' }}>
            { data &&  
            <>
            <img style={{height:'100px', width:'100px'}} src={data.data.unlistedList[0].scriptLogo} alt="Script Logo"/>
            <Typography variant='h6' fontWeight={'400'} >Script Name : <b>{data.data.unlistedList[0].scriptName}</b></Typography>
            <Typography variant='h6' fontWeight={'400'} >Isin Number : <b>{data.data.unlistedList[0].isinNumber}</b></Typography>
            <Typography variant='h6' fontWeight={'400'} >Face Value : <b>{data.data.unlistedList[0].faceValue}</b></Typography>
            <Typography variant='h6' fontWeight={'400'} onClick={()=>{ data.data.unlistedList[0].investorLink && window.open("/"+data.data.unlistedList[0].investorLink,"_blank")}}>Investor Link : <b>{data.data.unlistedList[0].investorLink || " - "}</b></Typography>
            <Typography variant='h6' fontWeight={'400'} >Status : <b>{data.data.unlistedList[0].isActive?"Active":"InActive"}</b></Typography>
            </>
            }
            <Stack spacing={3} direction='row' marginTop={2}>
            <Button variant='contained' onClick={()=>updateUnlistedShareModel("open")}>
                Update
            </Button>
            <Button variant="contained" onClick={activeInactiveUnlistedMaster}>{data && data.data.unlistedList[0].isActive===true? "Inactive Unlisted" : "Active Unlisted"}</Button>
            {/* { data && 
            (<DownloadDocument reportDocument={data.data.unlistedList[0].reportDocument}/>) } */}
            {data && data.data.unlistedList[0].reportDocument ? 
            <Button variant="contained" onClick={()=>{setViewDocumentOpen(true);
            viewDocument(data.data.unlistedList[0].reportDocument)}}> View </Button>:
            <Button variant="contained" disabled> View </Button>}
            </Stack>
            <br />
          <Grid item xs={12} sm={12} md={8} lg={8}>
          <Modal
            keepMounted
            open={viewDocumentOpen}
            onClose={() => setViewDocumentOpen(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={style}>
            <IconButton
      style={{ position: 'absolute',fontSize:"18px" ,top: "8px", right: "5px"}}
      onClick={() => setViewDocumentOpen(false)}
    >
      <CloseIcon style={{fontSize:"18px"}} />
    </IconButton>
          { !!clientFile ? 
           clientFile.includes(".pdf") ? 
        <div
          className="rpv-core__viewer"
          style={{
              border: '1px solid rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              height: '600px',
              width: '100%',
          }}
        >
          <div
              style={{
                  alignItems: 'center',
                  backgroundColor: '#eeeeee',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '4px',
              }}
          >
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
              <Tooltip title="Download">
                <IconButton onClick={()=>fileDownload(clientFileDocId)}>
                  <CloudDownloadIcon/>
                </IconButton>
              </Tooltip>
          </div>
          <div
              style={{
                  flex: 1,
                  overflow: 'hidden',
              }}
          >
      			   { !!clientFile ?
               <Viewer fileUrl={clientFile} plugins={[zoomPluginInstance]} /> 
      				: " No Data Found" }
      			</div>
      			</div> : 
            <img src={clientFile} alt="image" height={"50%"} width={"auto"}/> : "" 
          }
            </Box>
          </Modal>
          </Grid>
        </Box>
            <Modal
         open={unlistedModel}
         onClose={()=>updateUnlistedShareModel("close")}
         >
            <Box sx={style}>
            <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Update Unlisted Share
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField 
            id="outlined-basic" 
            label="Script Name" 
            variant="outlined" 
            defaultValue={data && data.data.unlistedList[0].scriptName}
            error={errors.scriptName?true:false}
            helperText={ errors.scriptName === undefined? "" : errors.scriptName.message + "" }
            {...register("scriptName")}
            />
            <TextField 
            id="outlined-basic" 
            label="Isin Number" 
            variant="outlined" 
            defaultValue={data && data.data.unlistedList[0].isinNumber}
            error={errors.isinNumber?true:false}
            helperText={ errors.isinNumber === undefined? "" : errors.isinNumber.message + "" }
            inputProps={{ readOnly: true }}
            {...register("isinNumber")}
            />
            <TextField 
            id="outlined-basic" 
            label="Face Value" 
            variant="outlined" 
            type='number'
            defaultValue={data && data.data.unlistedList[0].faceValue}
            error={errors.faceValue?true:false}
            helperText={ errors.faceValue === undefined? "" : errors.faceValue.message + "" }
            {...register("faceValue",{valueAsNumber: true})}
            />
            <TextField 
            id="outlined-basic" 
            label="Investor Link" 
            variant="outlined" 
            defaultValue={data && data.data.unlistedList[0].investorLink}
            {...register("investorLink")}
            />
                <Box>
                <Button variant='contained' component='label' title='Hello'>
                Update Report File
                <input hidden type="file" accept='.doc, .pdf' name='form' onChange={(event:any)=>setReportDocumentFile(event.target.files[0])} style={{ marginBottom: "10px" }} />
                </Button>
                <Typography variant='caption' sx={{marginLeft:'2rem'}}>
                  { reportDocumentFile === undefined ? "( .doc , .pdf )" : reportDocumentFile.name }
                  </Typography>
                </Box>
                <Box sx={{marginTop:2}}>
                <Button variant='contained' component='label'>
                Update Logo
                <input hidden accept='.jpg , .png , .jpeg' type="file" name='form' onChange={(event:any)=>setLogoFile(event.target.files[0])} style={{ marginBottom: "10px" }} />
                </Button>
                <Typography variant='caption' sx={{marginLeft:'2rem', marginTop:2}}>{ logoFile === undefined ? "( .png , .jpg , .jpeg)" : logoFile.name }</Typography>
                </Box>

                <Button type="submit" variant="contained" sx={{marginTop:'5%'}}>Submit</Button>
          </form>
          </Box>
          </Modal>            
          </Container>
  )
}

export default withParam(AdminUnlistedMasterDetails)


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    "& .MuiTextField-root": { m: 1 },
  };