import { Container, Grid, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import StartWithUs from '../StartWithUs/StartWithUs';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const ProductAIF = () => {
  window.scrollTo(0,0)
  return (
//    <section>
//     <Container>
// <Grid container spacing={2} style={{justifyContent: "space-around"}} className={sessionStorage.getItem("JWT") ? 'your-dynamic-css-class-product' : 'default-class-product'}>
//  <Grid md={7}>
//  <Typography style={{ textAlign: "left", fontSize: "30px", fontWeight: "bold",borderBottom: "4px solid black"}}>Alternative Investment Fund (AIF)</Typography>
//  <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
//  The overall size of the Alternative Investment Fund (AIF) industry is at around INR 7 lakh crores as
// compared to INR 2 lakh crores in 2018. CAT II and CAT III saw a CAGR of 23% over the past 5 years.
// CAT II witnessed growth in commitments from roughly INR 1 lakh crore to INR 6 lakh crores. CAT III
// funds witnessed a ~ 5X growth in the past 5 years with a CAGR growth of 37%. The growth in
// Alternatives Investment Fund AUM is expected to accelerate at 11.5% CAGR over the next 5 years.
// </Typography>
// <Typography style={{ textAlign: "left", fontSize: "25px", fontWeight: "bold"}}>Why to invest in AIF Fund?</Typography>

//  <Grid container>
//   <Grid xs={1}>
//   <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
//   </Grid>
//   <Grid xs={11}>
//     <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
//     Alternative Investments may help in reducing volatility that is commonly associated with
//   traditional investments as their performances are not dependent on the ups and downs of a
//   stock market.
//     </Typography>
  
//   </Grid>
//   <Grid xs={1}>
//   <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
//   </Grid>
//   <Grid xs={11}>
//     <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
//     Helps in diversification in terms of markets strategies and investment styles
//     </Typography>
  
//   </Grid>
//   <Grid xs={1}>
//   <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
//   </Grid>
//   <Grid xs={11}>
//     <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
//     Strong potential in improving performance. High corpus amount gives the fund manager the
//     flexibility to explore new strategies to maximize returns
//     </Typography>
  
//   </Grid>

//  </Grid>


//  </Grid>
//  <Grid md={4} style={{marginTop:"53px",background: "aliceblue"}}>
//   <Grid container>
//     <Grid md={1}>
//   <ArrowRightAltIcon></ArrowRightAltIcon>
//     </Grid>
//     <Grid md={11}>
//     <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingRight:"5px"}} >
//     CAT I- AIFs which invest in start-up or early stage ventures or social ventures or SMEs or infrastructure or
// other sectors or areas which the government or regulators consider as socially or economically desirable.
//       </Typography>
//     </Grid>
//     <Grid md={1}>
//   <ArrowRightAltIcon></ArrowRightAltIcon>
//     </Grid>
//     <Grid md={11}>
//     <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingRight:"5px"}} >
//     CAT II- AIFs which do not fall in Category I and III and which do not undertake leverage or borrowing other than to meet day-to-day operational requirements .
//       </Typography>
//     </Grid>
//     <Grid md={1}>
//   <ArrowRightAltIcon></ArrowRightAltIcon>
//     </Grid>
//     <Grid md={11}>
//     <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingRight:"5px"}} >
//     CAT III-Category 3 AIFs are those funds which give returns under a short period of time. These funds use complex and diverse trading strategies to achieve their goals
//       </Typography>
//     </Grid>

// </Grid>
//  </Grid>
// </Grid>
// <br />
// <Grid md={12} >
// <Typography style={{fontSize:"25px",background:"aqua",fontWeight:"bold",padding:"8px",borderRadius:"10px"}}>Alternative Investment Funds (AIF)</Typography>
// <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 650 }} aria-label="simple table">
//         <TableHead>
//           <TableRow>
//             <TableCell align="center"  style={{fontSize:"16px"}}>AMC</TableCell>
//             <TableCell align="center" style={{fontSize:"16px"}}>STRATEGY</TableCell>
//             <TableCell align="center" style={{fontSize:"16px"}}>CATEGORY</TableCell>
//             <TableCell align='center' style={{fontSize:"16px"}}>PERIODIC RETURNS (%)
//             <TableCell align="right" style={{fontSize:"16px"}}></TableCell>
//             <TableCell align="right" style={{fontSize:"16px"}}>3M</TableCell>
//             <TableCell align="right" style={{fontSize:"16px"}}>6M</TableCell>
//             <TableCell align="right" style={{fontSize:"16px"}}>1YR</TableCell>
//             <TableCell align="right" style={{fontSize:"16px"}}>3YR</TableCell>
//             <TableCell align="center" style={{fontSize:"16px"}}>SINCE INCEPTION</TableCell>
//             </TableCell>

//           </TableRow>
//         </TableHead>
//         <TableBody>
//             <TableRow sx={{border: "0"}}>
//               <TableCell component="th" scope="row" align="center"  style={{fontSize:"15px"}}>Buoyant Capital</TableCell>
//               <TableCell align="center" style={{fontSize:"15px"}}>Buoyant Capital Opportunities</TableCell>
//               <TableCell align="center" style={{fontSize:"15px"}}>CAT-III(Long Only)</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>
//               <TableCell align="right" style={{fontSize:"15px"}}></TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>13.80%</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>16.60%</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>NA</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>NA</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>16.50%</TableCell>
//               </TableCell>
              
//             </TableRow>
//             <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
//               <TableCell component="th" scope="row" align="center"  style={{fontSize:"15px"}}>Edelweiss Investment Management</TableCell>
//               <TableCell align="center" style={{fontSize:"15px"}}>Nuvama Enhanced Dynamic Growth Equity (Edge) Fund</TableCell>
//               <TableCell align="center" style={{fontSize:"15px"}}>CAT-III(Long Only)</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>
//               <TableCell align="right" style={{fontSize:"15px"}}></TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>9.72%</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>12.52</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>22.31%</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>NA</TableCell>
//               <TableCell align="right" style={{fontSize:"15px"}}>18.10%</TableCell>
//               </TableCell>
              
//             </TableRow>
        
//         </TableBody>
//       </Table>
//     </TableContainer>
//   {sessionStorage.getItem("JWT")?"":
// <Grid mt={2} mb={2}>
// <StartWithUs/>
// </Grid>
// }
// </Grid>
// </Container>
//    </section>
<Container>
<Grid md={12} className={sessionStorage.getItem("JWT") ? 'your-dynamic-css-class-product' : 'default-class-product'}>
<Typography style={{ textAlign: "left", fontSize: "30px", fontWeight: "bold",borderBottom: "4px solid black"}}>Alternative Investment Funds (AIF) </Typography>
<Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold", textDecoration: "underline",paddingTop:"10px" }}>Industry Update </Typography>
<Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
The overall size of the Alternative Investment Fund (AIF) industry is at around INR 7 lakh crores as compared to INR 2 lakh crores in 2018.
 CAT II and CAT III saw a CAGR of 23% over the past 5 years. CAT II witnessed growth in commitments from roughly INR 1 lakh crore to INR 6 lakh crores.
  CAT III funds witnessed a ~ 5X growth in the past 5 years with a CAGR growth of 37%. The growth in
   Alternatives Investment Fund AUM is expected to accelerate at 11.5% CAGR over the next 5 years.
</Typography>
</Grid>
<br />
<Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold", textDecoration: "none",paddingTop:"10px" }}>Why to invest in AIF Fund? </Typography>
<Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingTop:"10px"}}>
      •	 Alternative Investments may help in reducing volatility that is commonly associated with traditional investments as their performances are not dependent on the ups and downs of a stock market.
      </Typography>
      <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingTop:"10px"}}>
      •	 Helps in diversification in terms of markets strategies and investment styles
      </Typography>
      <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingTop:"10px"}}>
      •	 Strong potential in improving performance. High corpus amount gives the fund manager the flexibility to explore new strategies to maximize returns
      </Typography>
    
<br />
<TableContainer component={Paper}>
<Table aria-label="simple table">
  <thead>
    <tr style={{border:"2px solid"}}>
      <th style={{ width: '20%',borderRight:"2px  solid ",padding:"10px" }}>AIF Category</th>
      <th>AIF Category</th>
    </tr>
  </thead>
  <tbody>
    <tr  style={{border:"2px solid"}}>
      <td style={{ width: '20%',borderRight:"2px  solid ",fontWeight:"500"  }}>CAT- I</td>
      <td style={{textAlign:"justify",padding:"5PX"}}>AIFs which invest in start-up or early stage ventures or social ventures or SMEs or
        infrastructure or other sectors or areas which the government or regulators consider as socially or economically desirable.</td>
    </tr>
    <tr  style={{border:"2px solid"}}>
      <td style={{ width: '20%',borderRight:"2px  solid ",fontWeight:"500"  }}>CAT- II</td>
      <td style={{textAlign:"justify",padding:"5PX"}}>AIFs which do not fall in Category I and III and which do not undertake leverage or borrowing other than to meet day-to-day operational requirements .</td>
    </tr>
    <tr  style={{border:"2px solid"}}>
      <td style={{ width: '20%',borderRight:"2px  solid ",fontWeight:"500" }}>CAT- III</td>
      <td style={{textAlign:"justify",padding:"5PX"}}>CAT-III	AIFs are those funds which give returns under a short period of time.
         These funds use complex and diverse trading strategies to achieve their goalsCAT-III	AIFs are those funds which give returns under a short period of time.
          These funds use complex and diverse trading strategies to achieve their goals</td>
    </tr>
  </tbody>
</Table>
</TableContainer>
<br />
<br />









{sessionStorage.getItem("JWT")?"":
<Grid mt={2} mb={2}>
<StartWithUs/>
</Grid>
}
</Container>
  )
}

export default ProductAIF