/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import React from "react";
import {
  DASHBOARD_REPORT_UPLOAD,
  DELETE_REPORT_FILE,
} from "../../utils/API_Names";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ProductFileType } from "../../Constants/ProductFileType";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { listOfAllEmployeeFiles } from "../../api/Api";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

const UploadReport = () => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const [uploadReport, setUploadReport] = React.useState<any>(null);
  const [fileType, setFileType] = React.useState<string>("");
  const [fileMonth, setFileMonth] = React.useState<any>(dayjs());
  const [clientFile, setClientFile] = React.useState<any>("");

  const [showCircularProgress, setShowCircularProgress] = React.useState(false);

  const { data, refetch } = useQuery(["getFile", fileType], () =>
    listOfAllEmployeeFiles(fileType)
  );

  const uploadPost = () => {
    setShowCircularProgress(true);
    const formData = new FormData();
    formData.append("uploadReport", uploadReport);
    formData.append(
      "month",
      ("0" + (fileMonth.$M + 1)).slice(-2) + "-" + fileMonth.$y
    );
    formData.append("documentType", fileType);
    axios
      .post(DASHBOARD_REPORT_UPLOAD, formData)
      .then(() => {
        toast.success("File Upload Successfully!");
        setShowCircularProgress(false);
      })
      .then(()=>{window.location.reload()})
      .catch(() => {
        toast.error("Something Went Wrong!");
        setShowCircularProgress(false);
      });
  };

  const downloadDocument = (documentId: any) => {
    axios({
      url: "report/getReportOfferDownload?documentId=" + documentId,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link: any = document.createElement("a");
      link.href = url;
      var date1 = new Date();
      link.setAttribute(
        "download",
        fileType +
          ("0" + date1.getDate()).slice(-2) +
          "_" +
          ("0" + (date1.getMonth() + 1)).slice(-2) +
          "_" +
          date1.getFullYear() +
          ".pdf"
      );
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };

  const viewDocument = (filePath: any) => {
    axios.get("documentupload?documentId=" + filePath).then((res: any) => {
      setClientFile(res?.data?.documentFilePath);
    });
  };

  const deleteDocument = (documentId: any) => {
    axios
      .delete(`${DELETE_REPORT_FILE}${documentId}`)
      .then(() => {
        toast.success("File Has been Deleted Successfully!");
        refetch();
      })
      .catch(() => {
        toast.error("Something Went Wrong!. ");
      });
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {" "}
        Upload Report Files{" "}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid container item columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Select File Date"
                inputFormat="MM/YYYY"
                views={["month", "year"]}
                value={fileMonth}
                onChange={(newValue: any) => {
                  setFileMonth(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} 
					fullWidth
				  size="small" />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              id="Product"
              options={ProductFileType.map((option: any) => option)}
              getOptionLabel={(options: any) => options.label}
              onChange={(options: any, newValue: any) => {
                setFileType(newValue?.value);
				setClientFile("")
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  size="small"
				  fullWidth
                  label="Select Product Type"
				//   sx={{ minWidth: "50%" }}
				/>
              )}
            />
          </Grid>
        </Grid>
        <Grid container item columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
			<Button
              component="label"
              size="small"
              variant="contained"
              color="warning"
              startIcon={<CloudUploadIcon />}
              sx={{ mb: 1 }}
            >
              Choose file
              <input
                hidden
                type="file"
                onChange={(event: any) =>
                  setUploadReport(event.target.files[0])
                }
              />
            </Button>
            <Typography sx={{ fontSize: 12 }}>{uploadReport?.name}</Typography>
		  </Grid>
      { fileType && uploadReport ? 
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              variant="contained"
              size="small"
              onClick={uploadPost}
              disabled={showCircularProgress}
              sx={{ mt: { xs: 1 } }}
            >
              {showCircularProgress === true ? (
                <CircularProgress color="secondary" size={20} thickness={5} />
              ) : (
                ""
              )}{" "}
              Upload File{" "}
            </Button>
		  </Grid>
      :
      <Grid item xs={12} sm={12} md={12} lg={12}>
      <Button
      variant="contained"
      size="small"
      disabled
      sx={{ mt: { xs: 1 } }}
    >
      {showCircularProgress === true ? (
        <CircularProgress color="secondary" size={20} thickness={5} />
      ) : (
        ""
      )}{" "}
      Upload File{" "}
    </Button>
    </Grid>
}
		  <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6"> Report Files </Typography>
		  </Grid>
			</Grid>
            <TableContainer>
	              <Table
	                sx={{ minWidth: 150 }}
	                aria-label="simple table"
	                size="small"
	              >
	                <TableHead>
	                  <TableRow>
	                    <TableCell>File Name</TableCell>
	                    <TableCell align="right">Download</TableCell>
	                    <TableCell align="right">View</TableCell>
	                    <TableCell align="right">Delete</TableCell>
	                  </TableRow>
	                </TableHead>
	                <TableBody>
	                  {data &&
	                    data.data.reportResponseDTO.map((item: any) => {
	                      return (
	                        <>
	                          <TableRow
	                            sx={{
	                              "&:last-child td, &:last-child th": {
	                                border: 0,
	                              },
	                              padding: "8px",
	                            }}
	                          >
	                            <TableCell component="th" scope="row">
	                              {item.month}
	                            </TableCell>
	                            <TableCell align="right">
	                              <Tooltip
	                                title="Download"
	                                onClick={() =>
	                                  downloadDocument(item.documentId)
	                                }
	                              >
	                                <IconButton>
	                                  <CloudDownloadIcon
	                                    sx={{ pb: 0 }}
	                                    color="success"
	                                  />
	                                </IconButton>
	                              </Tooltip>
	                            </TableCell>
	                            <TableCell align="right">
	                              <Tooltip
	                                title="View"
	                                onClick={() => viewDocument(item.filePath)}
	                              >
	                                <IconButton>
	                                  <VisibilityIcon sx={{ pb: 0 }} color="info" />
	                                </IconButton>
	                              </Tooltip>
	                            </TableCell>
	                            <TableCell align="right">
	                              <Tooltip
	                                title="Delete"
	                                onClick={() => deleteDocument(item.documentId)}
	                              >
	                                <IconButton>
	                                  <DeleteIcon sx={{ pb: 0 }} color="error" />
	                                </IconButton>
	                              </Tooltip>
	                            </TableCell>
	                          </TableRow>
	                        </>
	                      );
	                    })}
	                </TableBody>
	              </Table>
            </TableContainer>
          
      
        { clientFile && 
		<div
          className="rpv-core__viewer"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
            height: "450px",
            width: "90%",
            margin: 15,
          }}
        >
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#eeeeee",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              display: "flex",
              justifyContent: "center",
              padding: "4px",
            }}
          >
            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </div>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
            }}
          >
            {!!clientFile && (
              <Viewer fileUrl={clientFile} plugins={[zoomPluginInstance]} />
            )}
          </div>
        </div>}
      </Grid>
    </>
  );
};

export default UploadReport;
