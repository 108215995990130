import {
  Autocomplete,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  GetAllClient,
  GetBrokeragePayoutByCompanyMasterCode,
} from "../../../api/Api";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { productTypeEnum } from "../../../Constants/ProductTypeEnum";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { changeDate } from "../../../utils/Helper";
import axios from "axios";
import { toast } from "react-toastify";
import AddSeries from "./AddSeries";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClientModalForOrder from "../../ClientModalForOrder";
import { CREATE_NEW_ORDER } from "../../../utils/API_Names";

const NcdTableHeadData = (props: any) => {
  const ncd = props.data;
  const minimumLotSize = props.minimumLotSize;
  const issuePrice = props.issuePrice;
  const companyMasterCode = props.companyMasterCode;
  const companyTypeCode = props.companyTypeCode;
  const distributorCode = props.distributorCode;

  const [clientId, setClientId] = useState("");
  const [orderDate, seOrderDate] = useState(new Date());
  const [uploadForm, setUploadForm] = useState<Blob | string | any>("");
  const [clientDetails, setClientDetails] = React.useState<any>("");
  const [applicationType, setApplicationType] = useState("");
  const [clientData, setClientData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [totalInvestmentError, setTotalInvestmentError] = useState("");
  const [applicationTypeLabel, setApplicationTypeLabel] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const ApplicationType = [
    { label: "Retail Individual", value: "Retail_Individual" },
    { label: "HNI Individual", value: "HNI_Individual" },
    {
      label: "Non-Institutional Investors",
      value: "Non_Institutional_Investors",
    },
    { label: "Institutional Investors", value: "Institutional_Investors" },
  ];

  const data2 = useQuery(
    ["getAllClients", distributorCode, companyMasterCode],
    () => GetAllClient(companyMasterCode, distributorCode),
    {
      onSuccess: (res: any) => {
        setClientData(res.data.clients);
      },
      staleTime: Infinity,
    }
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data: any) => {
    if (uploadForm) {
      const formData = new FormData();
      formData.append("document", uploadForm);
      axios.post("documentupload", formData).then((res: any) => {
        let ncdDto = {
          productMasterCode: ncd.productMasterCode,
          orderDate: changeDate(orderDate),
          clientId: clientId,
          version: 1,
          productTypeCode: productTypeEnum.NCD,
          distCompanyMasterCode: companyMasterCode,
          distEmployeeCode: distributorCode,
          buyRate: issuePrice,
          quantity: data.quantity,
          fromRefNo: res.data.documentId,
          formAckNo: data.formAckNo,
          applicationType: applicationType,
        };
        axios
          .post(CREATE_NEW_ORDER, ncdDto)
          .then(
            (res: any) =>
              res && toast.success(res?.data?.status?.message?.title)
          )
          .then(() => {
            window.location.reload();
          })
          .catch((err: any) => {
            toast.error(
              err?.response?.data?.status?.message?.title
                ? err?.response?.data?.status?.message?.title
                : err?.response?.data?.title
            );
          });
      });
    } else {
      toast.error("Please Upload File");
    }
  };

  let totalInvestmentAmount = !isNaN(issuePrice * watch("quantity"))
    ? issuePrice * watch("quantity")
    : 0;

  const validateQuantity = (v: any) => {
    // for Retail Individual
    if (applicationType === ApplicationType[0].value) {
      if (parseFloat(v) < minimumLotSize) {
        return "Quantity should be equal or greater than " + minimumLotSize;
      }
    }
    // other than Retail Individual
    else {
      if (parseFloat(v) < minimumLotSize) {
        return "Quantity should be equal or greater than " + minimumLotSize;
      }
    }
  };

  const validateTotalInvestment = () => {
    // for Retail Individual
    if (applicationType === ApplicationType[0].value) {
      // Revenue must be less than 10,00,000
      if (getValues("quantity") * issuePrice > 1000000) {
        setTotalInvestmentError(
          `The total value should not be more than 10,00,000 for the ${applicationTypeLabel}`
        );
      } else {
        setTotalInvestmentError("");
      }
    }
    // other than Retail Individual
    else {
      // Revenue must be greater than 10,00,000
      if (getValues("quantity") * issuePrice <= 1000000) {
        setTotalInvestmentError(
          `The total value should be more than 10,00,000 for the ${applicationTypeLabel}`
        );
      } else {
        setTotalInvestmentError("");
      }
    }
  };

  const registerOptions = {
    quantity: {
      required: "Quantity is required",
      validate: validateQuantity,
      valueAsNumber: true,
    },
    formAckNo: {
      required: "Please Enter Form Acknowledgement No.",
    },
    clients: { required: "Select a Client" },
  };

  const { data: brokerageData } = useQuery(
    ["GetBrokeragePayoutByCompanyMasterCode", companyMasterCode],
    () =>
      GetBrokeragePayoutByCompanyMasterCode(
        sessionStorage.getItem("Profile") === "D"
          ? sessionStorage.getItem("CompanyMasterCode")
          : companyMasterCode
      ),
    {
      enabled: !!companyMasterCode || sessionStorage.getItem("Profile") === "D",
    }
  );

  const ncdBrokerage =
    brokerageData &&
    brokerageData.data?.companyBrokerage?.filter(
      (item: any) => item?.productTypeCode === productTypeEnum?.NCD
    )[0];

  let revenue = 0;
  if (applicationType === ApplicationType[0].value) {
    revenue = totalInvestmentAmount * (ncd.retailIndividual / 100);
  } else if (applicationType === ApplicationType[1].value) {
    revenue = totalInvestmentAmount * (ncd.hniIndividual / 100);
  } else if (applicationType === ApplicationType[2].value) {
    revenue = totalInvestmentAmount * (ncd.nonInstitutionalInvestors / 100);
  } else if (applicationType === ApplicationType[3].value) {
    revenue = totalInvestmentAmount * (ncd.institutionalInvestors / 100);
  }

  let DisBrokerageValue = !isNaN(
    revenue * (ncdBrokerage?.brokerageValueForCustomer / 100)
  )
    ? revenue * (ncdBrokerage?.brokerageValueForCustomer / 100)
    : 0;

  // error on quantity on changing application type (not initially)-
  useEffect(() => {
    if (getValues("quantity")) {
      trigger("quantity");
    }
  }, [applicationType]);

  useEffect(() => {
    validateTotalInvestment();
  }, [getValues("quantity"), applicationType]);

  return (
    <>
      <TableRow
        key={ncd.ncdSeriesId}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell align="left" component="th" scope="row">
          {ncd.productName}
        </TableCell>
        <TableCell align="left"> {ncd.interestFrequency}</TableCell>
        <TableCell align="left"> {ncd.couponRate}</TableCell>
        <TableCell align="left"> {ncd.tenure}</TableCell>
        <TableCell align="left">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label="Order Date"
              inputFormat="DD/MM/YYYY"
              value={orderDate}
              onChange={(newValue: any) => {
                seOrderDate(newValue.toJSON());
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  sx={{ width: "100%" }}
                  error={errors.orderDate ? true : false}
                  helperText={
                    errors.orderDate === undefined
                      ? ""
                      : errors.orderDate.message + ""
                  }
                  {...register("orderDate")}
                />
              )}
            />
          </LocalizationProvider>
        </TableCell>
        <TableCell align="left">
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                key={companyMasterCode}
                options={clientData?.map((options: any) => options)}
                getOptionLabel={(options: any) =>
                  options.fullName + " - " + options.panCardNo
                }
                onChange={(options: any, newValue) => {
                  setClientId(newValue ? newValue.clientId : "");
                  setClientDetails(newValue ? newValue : "");
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Select Clients"
                    size="small"
                    error={errors.clients ? true : false}
                    helperText={
                      !errors.clients ? "" : errors.clients.message + ""
                    }
                    {...register("clients", registerOptions.clients)}
                  />
                )}
              />
            </form>
          </>
        </TableCell>
        <TableCell align="left">
          {clientId && <ClientModalForOrder clientDetails={clientDetails} />}
        </TableCell>
        <TableCell align="left">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={ApplicationType.map((option: any) => option)}
            getOptionLabel={(options: any) => options.label}
            onChange={(options: any, newValue) => {
              setApplicationType(newValue?.value);
              setApplicationTypeLabel(newValue?.label);
            }}
            renderInput={(params: any) => (
              <TextField {...params} label="Application Type" size="small" />
            )}
          />
        </TableCell>
        <TableCell align="left">
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Quantity"
              size="small"
              fullWidth
              type="number"
              error={errors.quantity ? true : false}
              helperText={
                errors.quantity === undefined
                  ? ""
                  : errors.quantity.message + ""
              }
              {...register("quantity", registerOptions.quantity)}
            />
          </form>
        </TableCell>
        <TableCell align="left"> {issuePrice}</TableCell>
        <TableCell align="left">
          {" "}
          <Typography>
            {totalInvestmentAmount?.toLocaleString("en-IN")}
          </Typography>{" "}
          {totalInvestmentError && (
            <Typography sx={{ fontSize: "0.75rem" }} color="error">
              {totalInvestmentError}
            </Typography>
          )}
        </TableCell>
        <TableCell align="left">
          {companyTypeCode == "D" ||
          sessionStorage.getItem("Profile") === "D" ? (
            <Typography>{DisBrokerageValue.toFixed(2)} </Typography>
          ) : (
            <Typography>{revenue.toFixed(2)} </Typography>
          )}
        </TableCell>
        <TableCell align="left">
          <TextField
            label="Form Acknowledgement No."
            size="small"
            fullWidth
            error={errors.formAckNo ? true : false}
            helperText={
              errors.formAckNo === undefined
                ? ""
                : errors.formAckNo.message + ""
            }
            {...register("formAckNo", registerOptions.formAckNo)}
          />
        </TableCell>
        <TableCell align="left">
          <Button
            component="label"
            size="small"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            sx={{ mb: 1 }}
            disabled={!ncd.isActive}
          >
            Upload file
            <input
              hidden
              type="file"
              accept=".pdf"
              onChange={(event: any) => setUploadForm(event.target.files[0])}
            />
          </Button>
          <Typography sx={{ fontSize: 14 }}>
            {!!!uploadForm ? " No file choosen" : " " + uploadForm?.name}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Button
              variant="contained"
              type="submit"
              disabled={!ncd.isActive}
              sx={{ mb: 1 }}
            >
              {" "}
              Apply{" "}
            </Button>
          </form>
          {sessionStorage.getItem("Profile") === "B" && (
            <Button variant="contained" onClick={handleOpen}>
              {" "}
              Edit{" "}
            </Button>
          )}
        </TableCell>
      </TableRow>
      <AddSeries open={open} onClose={handleClose} data={ncd} />
    </>
  );
};

export default NcdTableHeadData;
