export const benchMarks = [
  { id: 1, name: "Nifty 50 TRI", value: "NIFTY 50 Total Return Index" },
  { id: 2, name: "S&P BSE 500 TRI", value: "S&P BSE 500 Total Return Index" },
  { id: 3, name: "MSEI SX 40 TRI", value: "MSEI SX 40 Total Return Index" },
  { id: 4, name: "Nifty Medium to Long term Debt Index", value: "Equity" },
  { id: 5, name: "Crisil Credit Index", value: "Equity" },
  {
    id: 6,
    name: "Crisil Composite Bond Fund Index",
    value: "Crisil Composite Bond Fund Index",
  },
  {
    id: 7,
    name: "Nifty 50 Hybrid Composite Debt 50:50 Index",
    value: "Equity",
  },
  {
    id: 8,
    name: "BSE S&P hybrid",
    value: "Equity",
  },
  {
    id: 9,
    name: "Crisil Hybrid 50+50 Moderate Index",
    value: "Crisil Hybrid 50+50 Moderate Index",
  },
  {
    id: 10,
    name: "NSE Multi Asset Index 1",
    value: "NSE Multi Asset Index 1",
  },
  {
    id: 11,
    name: "NSE Multi Asset Index 2",
    value: "NSE Multi Asset Index 2",
  },
  {
    id: 12,
    name: "Crisil Multi Asset Index 3",
    value: "Crisil Multi Asset Index 3",
  },
  {
    id: 13,
    name: "Average of NSE Mid-cap 100 TRI and NSE Small-cap 100 TRI",
    value: "Average of NSE Mid-cap 100 TRI and NSE Small-cap 100 TRI",
  },
  {
    id: 14,
    name: "BSE 500",
    value: "BSE 500",
  },
  {
    id: 15,
    name: "CRISIL Arbitrage Index",
    value: "CRISIL Arbitrage Index",
  },
  {
    id: 16,
    name: "CRISIL Liquid Fund Index",
    value: "CRISIL Liquid Fund Index",
  },
  {
    id: 17,
    name: "NIFTY 200 Total Return Index",
    value: "NIFTY 200 Total Return Index",
  },
  {
    id: 18,
    name: "NIFTY 50 & BSE SMALLCAP",
    value: "NIFTY 50 & BSE SMALLCAP",
  },
  {
    id: 19,
    name: "NIFTY 50 Arbitrage Index",
    value: "NIFTY 50 Arbitrage Index",
  },
  {
    id: 20,
    name: "NIFTY 50 Index",
    value: "NIFTY 50 Index",
  },
  {
    id: 21,
    name: "NIFTY 500 Index",
    value: "NIFTY 500 Index",
  },
  {
    id: 22,
    name: "NIFTY Financial Services Index",
    value: "NIFTY Financial Services Index",
  },
  {
    id: 23,
    name: "NIFTY India Consumption Index",
    value: "NIFTY India Consumption Index",
  },
  {
    id: 24,
    name: "NIFTY Mid Small Cap 400",
    value: "NIFTY Mid Small Cap 400",
  },
  {
    id: 25,
    name: "NIFTY Mid Small Cap 400 Total Return Index",
    value: "NIFTY Mid Small Cap 400 Total Return Index",
  },
  {
    id: 26,
    name: "NIFTY Midcap 100 Index",
    value: "NIFTY Midcap 100 Index",
  },
  {
    id: 27,
    name: "NIFTY Smallcap 100 Index",
    value: "NIFTY Smallcap 100 Index",
  },
  {
    id: 28,
    name: "S&P BSE 100 Index",
    value: "S&P BSE 100 Index",
  },
  {
    id: 29,
    name: "S&P BSE 200 Index",
    value: "S&P BSE 200 Index",
  },
  {
    id: 30,
    name: "S&P BSE 500 Index",
    value: "S&P BSE 500 Index",
  },
  {
    id: 31,
    name: "S&P BSE Small Cap Index",
    value: "S&P BSE Small Cap Index",
  },
  {
    id: 32,
    name: "80% Nifty 500 + 20% Nifty 4-8Y G-Sec",
    value: "80% Nifty 500 + 20% Nifty 4-8Y G-Sec",
  },
  {
    id: 33,
    name: "Active Aggressive Composite",
    value: "Active Aggressive Composite",
  },
  {
    id: 34,
    name: "Active Aggressive plus Composite",
    value: "Active Aggressive plus Composite",
  },
  {
    id: 35,
    name: "Active Balanced Composite",
    value: "Active Balanced Composite",
  },
  {
    id: 36,
    name: "Active Growth Composite",
    value: "Active Growth Composite",
  },
  {
    id: 37,
    name: "NIFTY Medium to Long Duration Debt Index",
    value: "NIFTY Medium to Long Duration Debt Index",
  },
  {
    id: 38,
    name: "NIFTY Smallcap 250 Index",
    value: "NIFTY Smallcap 250 Index",
  },
  {
    id: 39,
    name: "Nifty50 Shariah",
    value: "Nifty50 Shariah",
  },
  {
    id: 40,
    name: "S&P BSE INDIA BOND INDEX",
    value: "S&P BSE INDIA BOND INDEX",
  },
  {
    id: 41,
    name: "S&P BSE INDIA CORPORATE BOND INDEX",
    value: "S&P BSE INDIA CORPORATE BOND INDEX",
  },
  {
    id: 42,
    name: "S&P BSE Mid Cap Index",
    value: "S&P BSE Mid Cap Index",
  },
  {
    id: 43,
    name: "S&P BSE Sensex Index",
    value: "S&P BSE Sensex Index",
  },
];
