import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { CREATE_NCD_SERIES, UPDATE_NCD_SERIES } from "../../../utils/API_Names";
import { toast } from "react-toastify";
import { bondEnum } from "../../../Constants/bondEnum";
import CloseIcon from "@mui/icons-material/Close";

const AddSeries = (props: any) => {
  // console.log("props", props);

  const [isActive, setIsActive] = React.useState<boolean>(
    props?.data?.isActive
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const [interestFrequency, setInterestFrequency] = React.useState<any>(
    props.data.interestFrequency
  );

  const onSubmit = (data: any) => {
    // console.log("data", data);
    if (!!props.data) {
      axios
        .put(UPDATE_NCD_SERIES, {
          ...data,
          productMasterCode: props?.data?.productMasterCode,
          interestFrequency: interestFrequency,
          isActive,
        })
        .then((res: any) => {
          res && toast.success(res?.data?.status?.message?.title);
          window.location.reload();
        })
        .catch((err: any) => {
          toast.error(
            err?.response?.data?.status?.message?.title
              ? err?.response?.data?.status?.message?.title
              : err?.response?.data?.title
          );
        });
    } else {
      axios
        .post(CREATE_NCD_SERIES, {
          ...data,
          ncdId: props.ncdId,
          interestFrequency: interestFrequency,
          isActive,
        })
        .then((res: any) => {
          res && toast.success(res?.data?.status?.message?.title);
          window.location.reload();
        })
        .catch((err: any) => {
          toast.error(
            err?.response?.data?.status?.message?.title
              ? err?.response?.data?.status?.message?.title
              : err?.response?.data?.title
          );
        });
    }
  };

  const validateCouponRate = (v: any) => {
    console.log("interestFrequency", interestFrequency);
    if (interestFrequency === bondEnum.CouponFrequencyEnum[4].value) {
      return null;
    } else if (!v) {
      return "Enter Coupoun Rate";
    } else if (!(parseFloat(v) >= 0 && parseFloat(v) <= 100)) {
      return "Coupon Rate to be between 0-100";
    }
  };
  const registerOptions: any = {
    couponRate: {
      validate: validateCouponRate,
      valueAsNumber: true,
    },
    interestFrequency: { required: "Enter Interest Frequency " },
    productName: { required: "Enter Series Name " },
    tenure: { required: "Enter Tenure " },
    amtOnMaturity: { required: "Enter Amount " },
    effectiveYield: {
      required: "Enter Effective Yield",
      validate: {
        validRange: (v: any) =>
          (parseFloat(v) >= 0 && parseFloat(v) <= 100) ||
          "Effective Yield to be between 0-100",
      },
      valueAsNumber: true,
    },
    retailIndividual: {
      required: "Enter Retail Individual",
      validate: {
        validRange: (v: any) =>
          (parseFloat(v) >= 0 && parseFloat(v) <= 100) ||
          "Retail Individual to be between 0-100",
      },
      valueAsNumber: true,
    },
    hniIndividual: {
      required: "Enter HNI Individual",
      validate: {
        validRange: (v: any) =>
          (parseFloat(v) >= 0 && parseFloat(v) <= 100) ||
          "HNI Individual to be between 0-100",
      },
      valueAsNumber: true,
    },
    nonInstitutionalInvestors: {
      required: "Enter Non-Institutional Investors",
      validate: {
        validRange: (v: any) =>
          (parseFloat(v) >= 0 && parseFloat(v) <= 100) ||
          "Non-Institutional Investors to be between 0-100",
      },
      valueAsNumber: true,
    },
    institutionalInvestors: {
      required: "Enter Institutional Investors",
      validate: {
        validRange: (v: any) =>
          (parseFloat(v) >= 0 && parseFloat(v) <= 100) ||
          "Institutional Investors to be between 0-100",
      },
      valueAsNumber: true,
    },
  };

  return (
    <>
      <Modal open={props.open} onClose={() => props.onClose()}>
        <Box sx={style}>
          <IconButton
            style={{
              position: "absolute",
              fontSize: "18px",
              top: "8px",
              right: "5px",
            }}
            onClick={() => props.onClose()}
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </IconButton>
          <Typography
            variant="h5"
            component="h2"
            textAlign={"center"}
            gutterBottom
          >
            Series Details
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12} sm={9} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Series Name"
                  variant="outlined"
                  fullWidth
                  defaultValue={props?.data?.productName}
                  error={errors.productName ? true : false}
                  helperText={
                    !errors.productName ? "" : errors.productName.message + ""
                  }
                  {...register("productName", registerOptions.productName)}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={6} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={bondEnum.CouponFrequencyEnum.map(
                    (option: any) => option
                  )}
                  getOptionLabel={(options: any) => options.label}
                  defaultValue={{
                    label: interestFrequency ? interestFrequency : "",
                  }}
                  onChange={(options: any, newValue) => {
                    setInterestFrequency(newValue.value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Interest Payout Frequency"
                      variant="outlined"
                      fullWidth
                      error={errors.interestFrequency ? true : false}
                      helperText={
                        !errors.interestFrequency
                          ? ""
                          : errors.interestFrequency.message + ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Tenure (Months)"
                  variant="outlined"
                  fullWidth
                  defaultValue={props?.data?.tenure}
                  error={errors.tenure ? true : false}
                  helperText={!errors.tenure ? "" : errors.tenure.message + ""}
                  {...register("tenure", registerOptions.tenure)}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Coupon Rate (%)"
                  variant="outlined"
                  fullWidth
                  defaultValue={
                    props?.data?.couponRate ? props?.data?.couponRate : ""
                  }
                  error={errors.couponRate ? true : false}
                  helperText={
                    !errors.couponRate ? "" : errors.couponRate.message + ""
                  }
                  {...register("couponRate", registerOptions.couponRate)}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Effective yield (%)"
                  variant="outlined"
                  fullWidth
                  defaultValue={props?.data?.effectiveYield}
                  error={errors.effectiveYield ? true : false}
                  helperText={
                    !errors.effectiveYield
                      ? ""
                      : errors.effectiveYield.message + ""
                  }
                  {...register(
                    "effectiveYield",
                    registerOptions.effectiveYield
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Amount of Maturity (Rs)"
                  variant="outlined"
                  fullWidth
                  defaultValue={props?.data?.amtOnMaturity}
                  error={errors.amtOnMaturity ? true : false}
                  helperText={
                    !errors.amtOnMaturity
                      ? ""
                      : errors.amtOnMaturity.message + ""
                  }
                  {...register("amtOnMaturity", registerOptions.amtOnMaturity)}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={6} lg={3}></Grid>
              <Grid item xs={12} sm={9} md={6} lg={3}></Grid>
              <Grid item xs={12} sm={9} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Retail Individual (%)"
                  variant="outlined"
                  fullWidth
                  defaultValue={props?.data?.retailIndividual}
                  error={errors.retailIndividual ? true : false}
                  helperText={
                    !errors.retailIndividual
                      ? ""
                      : errors.retailIndividual.message + ""
                  }
                  {...register(
                    "retailIndividual",
                    registerOptions.retailIndividual
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="HNI Individual (%)"
                  variant="outlined"
                  fullWidth
                  defaultValue={props?.data?.hniIndividual}
                  error={errors.hniIndividual ? true : false}
                  helperText={
                    !errors.hniIndividual
                      ? ""
                      : errors.hniIndividual.message + ""
                  }
                  {...register("hniIndividual", registerOptions.hniIndividual)}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Non-Institutional Investors (%)"
                  variant="outlined"
                  fullWidth
                  defaultValue={props?.data?.nonInstitutionalInvestors}
                  error={errors.nonInstitutionalInvestors ? true : false}
                  helperText={
                    !errors.nonInstitutionalInvestors
                      ? ""
                      : errors.nonInstitutionalInvestors.message + ""
                  }
                  {...register(
                    "nonInstitutionalInvestors",
                    registerOptions.nonInstitutionalInvestors
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={6} lg={3}>
                <TextField
                  id="outlined-basic"
                  label="Institutional Investors (%)"
                  variant="outlined"
                  fullWidth
                  defaultValue={props?.data?.institutionalInvestors}
                  error={errors.institutionalInvestors ? true : false}
                  helperText={
                    !errors.institutionalInvestors
                      ? ""
                      : errors.institutionalInvestors.message + ""
                  }
                  {...register(
                    "institutionalInvestors",
                    registerOptions.institutionalInvestors
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography>
                  {" "}
                  Status : {isActive ? "Active" : "In Active"}{" "}
                </Typography>
                <Switch
                  checked={isActive}
                  onChange={() => setIsActive(!isActive)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} textAlign={"center"}>
                <Button type="submit" variant="contained">
                  {" "}
                  {!!props.data ? "Update" : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "100%",
};

export default AddSeries;
