import { ThemeProvider } from '@emotion/react'
import { Box, CircularProgress, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, createTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getPortfolioDashBoard } from '../../api/Api'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { options } from '../PayoutDashboard/PayoutBarChart'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);


const AnalyzerDashboard = () => {

const [riskmeterdata, riskmeter] = useState<any>([])
  const [TopBottomdata, TopBottom] = useState<any>([])
  const [segmentother, otherSegment] = useState<any>([])
  const [DataCompanyWiseOther, otherListDetailscopmany] = useState<any>([])
  const [Companywise, setCompanyWise] = useState<any>([])
  const [SectorWise, setSectoreWise] = useState<any>([])
  const [topdata, readmore] = useState('5')
  const [Readmore, setReadmore] = useState(true)
  const [topDataSectorwise, DataSectorwise] = useState('5')
  const [sectoeReadmore, setsectoeReadmore] = useState(true)

  const defaultTheme = createTheme()
  const isXs = window.innerWidth <= 900
  const isSm = useMediaQuery(defaultTheme.breakpoints.down('sm'))
  const casId = sessionStorage.getItem('casId')
  const [showCircularProgress, setshowCircularProgress] = useState(false);


  useEffect(() => {
    if (casId === null || casId === 'null') {
      console.error('casId is null or undefined')
    } else {
      getDashboardData()
    }
  }, [casId])


  const showMore = () => {
    readmore(DataCompanyWiseOther.length)
    setReadmore(false)
  }
  const showLess = () => {
    readmore('5')
    setReadmore(true)
  }

  const SectorWiseRM = () => {
    DataSectorwise(segmentother.length)
    setsectoeReadmore(false)
  }
  const SectorWiseLess = () => {
    DataSectorwise('5')
    setsectoeReadmore(true)
  }
  const data = {
    labels:Companywise?.companyWiseResponseArrays?.map((item:any)=>(item.name)),
      datasets: [
          
        {
          
          label: 'hdhdhd# of Votes',
          data:Companywise?.companyWiseResponseArrays?.map((item:any)=>(item.weighting)),
          backgroundColor: [
            "#b1dffa",
            "#f5e9d2",
            "#b2dce3",
            "#c3edca",
            "#dedede",
            "#f6d1ab",
            "#c8908e",
            "#615376",
            "#bccddb",
            "#ddcfe8",
            "#dfcbb1",
           
   
          ],
          display:true,
          borderColor: "#CCF0FF"
        }
      ]
    };
   
  const sector = {
    labels:SectorWise?.map((item:any)=>(item.name)),
      datasets: [
          
        {
          label: 'hdhdhd# of Votes',
          data:SectorWise?.map((item:any)=>(item.value)),
          backgroundColor: [
          
            "#ddcfe8",
            "#b2dce3",
            "#c3edca",
            "#dedede",
            "#f6d1ab",
            "#b1dffa",
            "#f5e9d2",
            "#615376",
            "#bccddb",
            "#dfcbb1", 
            "#c8908e",
          ],
          display:true,
          borderColor: "#CCF0FF"
        }
      ]
    };

 

    const getDashboardData = async () => {
       setshowCircularProgress(true)
        try {
          const response = await getPortfolioDashBoard()
          console.log("dfghjkjhgfd",response.data)
          riskmeter(response.data.riskoMeterResponse)
          TopBottom(response.data.topGainerAndLoserResponse)
          setCompanyWise(response.data.companyWiseResponse)
          otherListDetailscopmany(response.data.companyWiseResponse.otherListDetails)
          setSectoreWise(response.data.sectorWiseResponseDTO.sectorWiseResponseArrays)
          otherSegment(response.data.sectorWiseResponseDTO.otherListDetails)
          if (response.data.riskoMeterResponse && response.data.riskoMeterResponse.riskDescription) {
            const riskDescription = response.data.riskoMeterResponse.riskDescription
            sessionStorage.setItem('riskmeter', riskDescription)
          } else {
            console.error('riskoMeterResponse or riskDescription not found in the response')
          }
          setshowCircularProgress(false);
        } catch (error) {
          console.error('An error occurred:', error)
        }
      }

  return (
    <div>
      

           <ThemeProvider theme={defaultTheme}>
           {showCircularProgress === true ? (
                    <CircularProgress
                      color="secondary"
                      size={50}
                      thickness={5}
                    />
                  ) : (
                    <Container component='main' maxWidth='lg' sx={{ padding: '0px', mb: '2em' }}>
                    <Box sx={{ textAlign: 'start' }}>
                        <span className='Performance-of-Schemes-top'>Performance of Schemes (1Yr Retrun)</span>
                    </Box>
                    <Grid container className='top-performance-bottom-performance' sx={{ p: 0 }}>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: 0, borderRight: isXs ? '0px solid' : '2px solid', borderBottom: isXs ? '2px solid' : '0px solid' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} textAlign={'start'} mb={2} >
                                <span className='top-gainer-pr' >Top Performer</span>
                            </Grid>
                            {TopBottomdata.portfolioTopGainerResponse?.map((item:any, index:any) => (
                                <Grid key={index} container sx={{ marginBottom: 2, padding: 0 }}>
                                    <Grid textAlign={'start'} sx={{ paddingLeft: '1em' }} item xs={9} sm={9} md={9} lg={9} >{item.name}</Grid>
                                    <Grid textAlign={'start'} item xs={1} sm={1} md={1} lg={1}><ArrowDropUpIcon sx={{ color: '#009c48', fontSize: '30px' }}></ArrowDropUpIcon> </Grid>
                                    <Grid textAlign={'start'} item xs={2} sm={2} md={2} lg={2} sx={{ color: '#009c48' }} >{parseFloat(item.value).toFixed(2)}%</Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <Grid item xs={12} sm={12} md={12} lg={12} textAlign={isXs ? 'start' : 'end'} mb={2} >
                                <span className={isXs ? 'top-gainer-pr-bottomXs' : 'top-gainer-pr-bottom'} >Bottom Performer</span>
                            </Grid>
    
                            {TopBottomdata.portfolioLoserResponse?.map((item:any, index:any) => (
                                <Grid key={index} container sx={{ marginBottom: 2, padding: 0 }}>
                                    <Grid textAlign={'start'} sx={{ paddingLeft: '1em' }} item xs={9} sm={9} md={9} lg={9}>{item.name}</Grid>
                                    <Grid textAlign={'start'} item xs={1} sm={1} md={1} lg={1}><ArrowDropDownIcon style={{ color: 'red', fontSize: '30px' }}></ArrowDropDownIcon></Grid>
                                    <Grid textAlign={'start'} item xs={2} sm={2} md={2} lg={2} sx={{ color: 'red' }}>{parseFloat(item.value).toFixed(2)}%</Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container className='main-row-riskometer-assest-allocation'>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box className='direct-growth-riskometer-img-portfolio-report'>
                                <img style={{ width: '70%' }} className='' src={'Riskmfit/' + riskmeterdata?.risk + '.png'} alt="Riskometer" />
                                <Box>
                                    <p className='riskometer-description-imgew-p'>{riskmeterdata.riskDescription}</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
    
                    <Grid container className='company-sector-wise-main-row-btm'>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box className='company-sector-wise-span-title-main-div'>
                                <span className='company-sector-wise-span-title'>Company Wise Assest Allocation</span>
                            </Box>
                            <Box className='assest-aegment-allocation assest-aegment-allocation-duplicate'>
                                <Box>
                               
                     <Doughnut data={data} 
                     options={{
        plugins: {
          legend: {
            display: false,
            position:'left',
            labels:{
                color: 'rgb(11, 0, 0)',
            }
          },
          tooltip: {
            enabled: true
          }
        },
        rotation: -90,
        circumference: 360,
        cutout: "70%",
        maintainAspectRatio: true,
        responsive: true,
      }}
    />
                    
                                </Box>
                            </Box>
                            <br />
    
                            <Box style={{ fontSize: '12px', textAlign: 'justify' }}>
                                <span className='compny-wise-others'>
                                    Others:
                                </span>
    
                                <Box className='duplicate-table-main-company-sector'>
                                    <Table>
                                        <TableHead>
                                            <TableRow className='compony-sector-table-header-tr'>
                                                <TableCell className='sector-name-allocation-bg-color' sx={{ borderRight: '2px solid white' }}>Sector Name</TableCell>
                                                <TableCell className='allocation-tabl-sector-th-td sector-name-allocation-bg-color'>Allocation (%)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </Box>
    
                                <div className='main-table-company-sector-scroll' id="style-13">
                                    <Table>
                                        <TableHead>
    
                                        </TableHead>
                                        {DataCompanyWiseOther?.filter((auto:any) => auto.weighting !== 0).slice(0, topdata).map((item:any, index:any) => (
                                            <TableBody key={index}>
                                                <TableRow>
                                                    <TableCell >{item.name}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }} className='allocation-tabl-sector-th-td'>{item.weighting.toFixed(2)}%</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ))}
                                    </Table>
                                </div>
                                {Readmore
                                  ? <span className='readmpore-less-pr-detals' onClick={showMore}> <b>View More</b> </span>
                                  : <span className='readmpore-less-pr-detals' onClick={showLess}> <b>View Less</b> </span>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box className='company-sector-wise-span-title-main-div'>
                                <span className='company-sector-wise-span-title'>Sector Wise Assest Allocation</span>
                            </Box>
                            <Box className='assest-aegment-allocation assest-aegment-allocation-duplicate'>
                                <Box>
                      <Doughnut
      data={sector}
      options={{
        plugins: {
          legend: {
            display: false,
            position:'left',
            labels:{
                color: 'rgb(11, 0, 0)',
            }
          },
          tooltip: {
            enabled: true
          }
        },
        rotation: -90,
        circumference: 360,
        cutout: "70%",
        maintainAspectRatio: true,
        responsive: true,
      }}
    />
                  
                                </Box>
                            </Box>
                            <br />
    
                            <Box style={{ fontSize: '12px', textAlign: 'justify' }}>
                                <span className='compny-wise-others'>
                                    Others:
                                </span>
    
                                <Box className='duplicate-table-main-company-sector'>
                                    <Table>
                                        <TableHead>
                                            <TableRow className='compony-sector-table-header-tr'>
                                                <TableCell className='sector-name-allocation-bg-color' sx={{ borderRight: '2px solid white' }}>Sector Name</TableCell>
                                                <TableCell className='allocation-tabl-sector-th-td sector-name-allocation-bg-color'>Allocation (%)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </Box>
    
                                <div className='main-table-company-sector-scroll' id="style-13">
                                    <Table >
                                        <TableHead>
    
                                        </TableHead>
                                        {segmentother?.filter((auto:any) => auto.value !== 0).slice(0, topDataSectorwise).map((item:any, index:any) => (
                                            <TableBody key={index}>
                                                <TableRow>
                                                    <TableCell >{item.name}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }} className='allocation-tabl-sector-th-td'>{item.value.toFixed(2)}%</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ))}
                                    </Table>
                                </div>
                                {sectoeReadmore
                                  ? <span className='readmpore-less-pr-detals' onClick={SectorWiseRM}> <b>View More</b> </span>
                                  : <span className='readmpore-less-pr-detals' onClick={SectorWiseLess}> <b>View Less</b> </span>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                  )}
        </ThemeProvider>
    </div>
  )
}

export default AnalyzerDashboard