import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { GetAllClient } from "../api/Api";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { changeDate, convertDateYMD } from "../utils/Helper";
import { CREATE_NEW_ORDER, PRODUCT_LIST, SAVE_PROPOSAL } from "../utils/API_Names";
import { productTypeEnum } from "../Constants/ProductTypeEnum";
import ClientModalForOrder from "./ClientModalForOrder";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const LoanTableHeadData = (props: any) => {
  const item: any = props.item;
  const distributorCode = props.distributorCode;
  const distributorUserCode = props.distributorUserCode;
  const companyMasterCode = props.companyMasterCode;
  const JV = props.JV;

  const [clientId, setClientId] = useState("");
  const [clientDetails, setClientDetails] = useState<any>("");
  const [buyRate, setBuyRate] = useState(item.maxAmount);
  const [tenure, setTenure] = useState(item.maxTenure);
  const [orderDate, seOrderDate] = useState(new Date());
  const [uploadForm, setUploadForm] = useState<Blob | string | any>("")
  const [open, setOpen] = React.useState(false);
  
  const handleOpen = () => setOpen(true);
    GetAllClient(companyMasterCode, distributorCode)
  const data2 = useQuery(["getAllClients", distributorCode, companyMasterCode], () =>
    GetAllClient(companyMasterCode, distributorCode),
    {
      enabled : sessionStorage.getItem("Profile")==="B" ? (!!distributorCode && !!companyMasterCode) : sessionStorage.getItem("Profile")==="E" ? (!!companyMasterCode) : true
    }
  );

  const loanOrder = (data:any) => {
    let loanOrderData = {
      loanType:item.loanType,
      productName:item.productName,
      productMasterCode: item.productMasterCode,
      clientId: clientId === "" ? "" : clientId,
      version: 1,
      productTypeCode: item.productTypeCode,
      segment:item.segment,
      loanTenure:data.loanTenure,
      buyRate:data.buyRate,
      formAckNo:data.formAckNo,
      
      distCompanyMasterCode: !!sessionStorage.getItem("CompanyData")
        ? sessionStorage.getItem("CompanyData")
        : companyMasterCode,
      distEmployeeCode: distributorCode,
      orderDate: changeDate(orderDate),
    };
    axios
      .post(CREATE_NEW_ORDER, loanOrderData)
      .then((res: any) => res && toast.success(res?.data?.status?.message?.title))
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onChange" });

  const registerOptions: any = {
    loanTenure: { required: "Enter Tenure",
    pattern: {
			value : /^[0-9]+$/,
			message : "Invalid Loan Amount"
		  },
    validate: {
      maxTenure: (value:any) => {
        const numericValue = parseInt(value, 10); // Parse the value as an integer
        return numericValue <= 10 || "Tenure should not be more than 10 years.";
      },
    },
    },
    buyRate: { required: "Loan Amount.",
    pattern: {
			value : /^[0-9]+$/,
			message : "Invalid Loan Amount"
		  }},
      clients :{ required: "Select a Client" },
    }
  const onSubmit = (data: any, item: any) => {
    console.log("data--", data);
    console.log("item--", item);
  };

  return (
    <>
      <TableRow>
        <TableCell align="center" sx={{ width:140 }}>
          <form onSubmit={handleSubmit(onSubmit)} id="form1">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Order Date"
                inputFormat="DD/MM/YYYY"
                value={orderDate}
                onChange={(newValue: any) => {
                  seOrderDate(newValue.toJSON());
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    sx={{ width: "100%" }}
                    error={errors.orderDate ? true : false}
                    helperText={
                      errors.orderDate === undefined
                        ? ""
                        : errors.orderDate.message + ""
                    }
                    {...register("orderDate")}
                  />
                )}
              />
            </LocalizationProvider>
          </form>
        </TableCell>
        <TableCell component="th" scope="row" align='center'>
        <Typography>{item.loanType}</Typography>
        </TableCell>
        <TableCell component="th" scope="row" align='center'>
        <Typography>{item.productName}</Typography>
        </TableCell>
        <TableCell component="th" scope="row" align='center'>
        <Typography>{item.segment}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ width:150 }}>
          <form onSubmit={handleSubmit(onSubmit)} id="form1">
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Tenure"
              size="small"
              error={errors.loanTenure ? true : false}
              helperText={
                errors.loanTenure === undefined
                  ? ""
                  : errors.loanTenure.message + ""
              }
              onChangeCapture={(e: any) => setTenure(e.target.value)}
              InputProps={{ inputProps: { min: "0", max: "10", step: "1" } }}
              {...register("loanTenure",registerOptions.loanTenure)}
            />
          </form>
        </TableCell>
        <TableCell align="center" sx={{ width:150 }}>
          <form onSubmit={handleSubmit(onSubmit)} id="form1">
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Loan Amount"
              size="small"
              defaultValue={item.buyRate}
              value={buyRate}
              error={errors.buyRate ? true : false}
              helperText={
                errors.buyRate === undefined
                  ? ""
                  : errors.buyRate.message + ""
              }
              onChangeCapture={(e: any) => setBuyRate(e.target.value)}
              InputProps={{ inputProps: { min: "0", max: "10", step: "1" } }}
              {...register("buyRate",registerOptions.buyRate)}
            />
          </form>
        </TableCell>
        <TableCell component="th" scope="row" align='center'>
          {data2.data && (
            <>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={data2.data.data.clients.map((options: any) => options)}
              getOptionLabel={(options: any) =>
                options.fullName + " - " + options.panCardNo
              }
              onChange={(options: any, newValue) => {
                setClientId(newValue ? newValue.clientId : "");
                setClientDetails(newValue ? newValue : "");
                newValue
                ? setValue("maxTenure", tenure)
                : setValue("maxAmount", buyRate);
              }}
              renderInput={(params: any) => (
                <form onSubmit={handleSubmit(onSubmit)} id="form1">
                  <TextField
                            {...params}
                            label="Select Clients"
                            size="small"
                            error={errors.clients ? true : false}
                            helperText={
                              !errors.clients ? "" : errors.clients.message + ""
                            }
                              {...register("clients",registerOptions.clients)}
                          />
                </form>
              )}
              />
              
            </>
          )}
        </TableCell>
        <TableCell>
        { clientId && <ClientModalForOrder clientDetails={clientDetails} /> }
        </TableCell>
          <TableCell align='left'>
                 <TextField 
				  	        label="Form Acknowledgement No." 
					          size="small" 
                    fullWidth
					          error={errors.formAckNo ? true : false}
                    helperText={
                      errors.formAckNo === undefined
                        ? ""
                        : errors.formAckNo.message + ""
                    }
                    {...register("formAckNo", registerOptions.formAckNo)}
				          />
                  </TableCell>
          <TableCell align="left"> 
                <Button component="label" size='small' variant="contained" startIcon={<CloudUploadIcon />} sx={{ mb:1 }}>
                Upload file
                <input hidden type="file" onChange={(event:any)=>setUploadForm(event.target.files[0])} />
                </Button>
                <Typography sx={{ fontSize:14 }}>{!!!uploadForm ? " No file choosen" : " " + uploadForm?.name}</Typography>
                </TableCell>
        <TableCell align="center" sx={{p:0.5, width:140}} >
          <form onSubmit={handleSubmit(loanOrder)} id="form1">
            <Button
              size="small"
              variant="contained"
              type="submit"
              id="form1"
            >
              Order
            </Button>
          </form>
        </TableCell>
      </TableRow>

    </>
  );
};


export default LoanTableHeadData;
