/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledTableCell } from "../../Constants/TableStyle";
import Visibilityicone from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { withNavigate } from "../../utils/Router.Helper";
import { getCasFile } from "../../api/Api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
// import { getCasFile } from '../../api/Api';

const PortfolioAnalyzer = () => {
  const navigate = useNavigate();
  const [userCasFile, setUserCasFile] = useState([]);
  const [pancardValue, setPancardValue] = useState("");
  const [password, setPassword] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [analyzerFlag, setAnalyzerFlag] = useState("");
  const [copyPanToPassword, setCopyPanToPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [gettokenData, tokenData] = useState(false);
  const [pancardError, setPancardError] = useState(false);
  const [state, setState] = useState({
    showPanError: false,
    PanImport: false,
  });
  const validatePancard = (value: any) => {
    const isValidPancard = /^[A-Z]{5}[0-9]{4}[A-Z]$/.test(value);
    setPancardError(!isValidPancard);
    return isValidPancard;
  };

  const Setpan = (e: any) => {
    const inputValue = e.target.value;
    if (validatePancard(inputValue)) {
      setState({ showPanError: false, PanImport: true });
    } else {
      setState({ showPanError: true, PanImport: false });
    }
  };
  const validateEmail = (value: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const validatePassword = (value: any) => {
    const isValidPassword = value.trim() !== "";
    return isValidPassword;
  };

  const [errors, setErrors] = useState({
    Pan: false,
    Email: false,
    Password: false,
  });

  useEffect(() => {
    getCasFile().then((resp: any) => setUserCasFile(resp.data.userCasList));
  }, []);

  const redirectUser = (UserPan: any) => {
    const state = { pan: UserPan };
    navigate("/PortfolioAnalysisDetails", { state });
  };
  const handleClickShowPassword = () => setShowPassword((show: any) => !show);

  const handelChangePan = (e: any) => {
    setPancardValue(e.target.value.toUpperCase());
  };

  const handelChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleCheck = (e: any) => {
    if (e.target.checked) {
      setPassword(pancardValue);
    } else {
      setPassword("");
    }
  };
  const HandleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const Email = data.get("Email");
    const newErrors = {
      Pan: !pancardValue,
      Email: !Email,
      Password: !password,
    };

    if (!selectedFile) {
      setUploadError(true);
    } else {
      setUploadError(false);
    }

    // Set errors based on newErrors (assuming you want to use this for validation)
    setErrors(newErrors);

    if (!pancardValue || !Email || !password || !selectedFile) {
      return;
    }

    try {
      setUploading(true);
      setUploadError(false);

      const formData = new FormData();
      formData.append("CAS File", selectedFile);
      formData.append("password", password);
      formData.append("email", Email);
      formData.append("pan", pancardValue);
      const state = { pan: pancardValue };

      const response = await axios.post(
        "portfolioanalyzer/UploadCASFile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Specify the correct content type
          },
        }
      );

      // Handle the response here, e.g., redirect or display a message
      if (response) {
        if (response.status === 200) {
          setUploading(false);
          sessionStorage.setItem("casId", response.data.casId);
          navigate("/PortfolioAnalysisDetails", { state });
        } else {
          // Handle other response statuses
        }
      } else {
        // Handle cases where the response is undefined (e.g., network error)
        console.error("Failed to fetch data");
      }
    } catch (err) {
      console.error("An error occurred during file upload:", err);
    }
  };

  const authenticateAndGetToken = async () => {
    try {
      const response = await axios.post(
        "/portfolioanalyzer/i4e/authentication"
      );

      if (!response.data) {
        throw new Error(`Authentication failed. Status: ${response.status}`);
      }

      const data = response.data;
      if (!data.jwt || data.jwt.trim() === "") {
        console.error("JWT is missing or empty in the authentication response");
        return null;
      }

      return data.jwt;
    } catch (error) {
      console.error("An error occurred during authentication:", error);
      throw error;
    }
  };

  const downloadPortfoliopdf = async (UserPan: any) => {
    try {
      setshowCircularProgress(true);
      const accessToken = await authenticateAndGetToken();
      if (!accessToken) {
        return;
      }
      const url = `${process.env.REACT_APP_I4E_URL}/api/common/pdf?panCard=${UserPan}`;
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/pdf",
          Authorization: ` ${accessToken}`,
        },
      });
      if (!resp.ok) {
        console.error(`Error downloading PDF. Status: ${resp.status}`);
        return;
      }
      const blob = await resp.blob();
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = "Portfolio.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(a.href);
    } catch (error) {
      console.error("An error occurred during PDF download:", error);
    } finally {
      setshowCircularProgress(false);
    }
  };
  return (
    <div>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={2}
        sx={{ padding: "0rem 3rem" }}
      >
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <Paper elevation={10}>
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, padding: 2, backgroundColor: "#032b5b" }}>
                  <UploadFileIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Portfolio Analyzer
                </Typography>
              </Box>
              <Box
                component="form"
                onSubmit={HandleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={2}
                  sx={{ alignItems: "baseline" }}
                >
                  <Grid item xs={12} sm={12} md={4} lg={4} textAlign={"start"}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="PanCard"
                      label="PAN Card "
                      name="Pan"
                      autoComplete="Pan"
                      value={pancardValue}
                      onChange={handelChangePan}
                      onBlur={(e) => Setpan(e)}
                      error={errors.Pan}
                      helperText={
                        errors.Pan ? "Please fill in Pan Details." : ""
                      }
                      inputProps={{
                        form: {
                          autocomplete: "off",
                        },
                      }}
                    />
                    {state.showPanError === true ? (
                      <span style={{ color: "red" }}>Please enter PAN No.</span>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="Email"
                      label="Email "
                      name="Email"
                      autoComplete="Email"
                      error={errors.Email}
                      helperText={errors.Email ? "Please fill your Email." : ""}
                      inputProps={{
                        form: {
                          autocomplete: "off",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} textAlign={"start"}>
                    <TextField
                      id="Password"
                      label="Password"
                      variant="outlined"
                      fullWidth
                      value={password}
                      name="Password"
                      margin="normal"
                      onChange={handelChangePassword}
                      error={errors.Password}
                      helperText={
                        errors.Password
                          ? "Please fill the password For Cas File."
                          : ""
                      }
                      type={showPassword ? "text" : "password"}
                      autoComplete="current-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={copyPanToPassword}
                          onChange={(e) => {
                            setCopyPanToPassword(!copyPanToPassword);
                            handleCheck(e);
                          }}
                          name="copyPanToPassword"
                          color="primary"
                        />
                      }
                      label="Same as PAN No."
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ textAlign: "center" }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ mt: 1, mb: 0.5 }}
                    >
                      <input
                        hidden
                        type="file"
                        accept=".pdf"
                        onChange={handleFileChange}
                      />
                      {/* <FileUploadIcon /> */}
                      <Typography>Upload CAS File</Typography>
                    </Button>
                    {selectedFile ? (
                      <Typography>{selectedFile.name}</Typography>
                    ) : (
                      <Typography>No file chosen</Typography>
                    )}
                    {uploadError && (
                      <p style={{ color: "red" }}>
                        Please upload a valid CAS file
                      </p>
                    )}
                  </Grid>
                  {analyzerFlag === "Y" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        sx={{ textAlign: "center" }}
                      >
                        <Button
                          type="submit"
                          disabled={uploading}
                          variant="contained"
                        >
                          {" "}
                          {uploading ? (
                            <CircularProgress
                              size={20}
                              thickness={8}
                              color="primary"
                              sx={{ marginRight: 1 }}
                            />
                          ) : (
                            ""
                          )}
                          Import your Investment
                        </Button>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        sx={{ textAlign: "center" }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            mt: 1,
                            mb: 0.5,
                            background: "#032b5b",
                            color: "#fff",
                            minWidth: "30%",
                          }}
                        >
                          Show Uploaded Cas File
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ textAlign: "center" }}
                    >
                      <Button
                        type="submit"
                        disabled={uploading}
                        variant="contained"
                      >
                        {uploading ? (
                          <CircularProgress
                            size={20}
                            thickness={8}
                            color="primary"
                            sx={{ marginRight: 1 }}
                          />
                        ) : (
                          ""
                        )}
                        Import your Investment
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={4}>
          <Paper elevation={10}>
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 2.5,
                paddingBottom: 2.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography component="h1" variant="h5">
                  {" "}
                  Uploaded CAS Files ({userCasFile.length})
                </Typography>
              </Box>
              <br />
              <Box sx={{ width: "100%", padding: "0rem 1rem" }}>
                <TableContainer>
                  <div
                    style={{ height: "308px", overflow: "overlay" }}
                    id="style-13"
                  >
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            align="center"
                            sx={{ fontSize: "1em", fontWeight: "bold" }}
                          >
                            User PAN Card
                          </StyledTableCell>
                          {sessionStorage.getItem("userTypeCode") === "AG" ? (
                            <></>
                          ) : (
                            <StyledTableCell
                              align="center"
                              sx={{ fontSize: "1em", fontWeight: "bold" }}
                            >
                              Download
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            align="center"
                            sx={{ fontSize: "1em", fontWeight: "bold" }}
                          >
                            View
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userCasFile
                          ? userCasFile.map((data: any) => (
                              <TableRow key={data.ucID}>
                                <TableCell align="center">
                                  {data.panCardNo}
                                </TableCell>
                                <TableCell align="center">
                                  <DownloadIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={() =>
                                      downloadPortfoliopdf(data.panCardNo)
                                    }
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <VisibilityIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => redirectUser(data.panCardNo)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          : ""}
                      </TableBody>
                    </Table>
                  </div>
                </TableContainer>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withNavigate(PortfolioAnalyzer);
