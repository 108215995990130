/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import {
  GetAllClient,
  GetPreOrderByClientID,
  GetUnlistedDetails,
  GetUnlistedOrderByClientID,
  listOfManufactureNDistributorCompanies,
} from "../../api/Api";
import { useQuery } from "react-query";
import { changeDate, convertDate } from "../../utils/Helper";
import { Link } from "react-router-dom";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { useEffect } from "react";
import axios from "axios";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import UploadTermSheet from "../Termsheet/UploadTermSheet/UploadTermSheet";
import {
  UPDATE_PRE_ORDER,
  UPDATE_PRE_ORDER_STATUS,
} from "../../utils/API_Names";
import { toast } from "react-toastify";
import {
  ProductTypeForPerOrder,
  ProductTypeForPerOrderForD,
} from "../../Constants/ProductFileType";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any, comparator: any) {
  const stabilizedThis = array?.map((el: any, index: any) => [el, index]);
  stabilizedThis?.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el: any) => el[0]);
}

const profile = sessionStorage.getItem("Profile");

const headCells = [
  {
    id: "orderId",
    numeric: false,
    disablePadding: true,
    label: "Order ID",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Order Date",
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
  },
  {
    id: "productName",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
  {
    id: "productType",
    numeric: false,
    disablePadding: false,
    label: "Product Type",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  ...(profile === "B"
    ? [
        {
          id: "manufacturerBuyRate",
          numeric: false,
          disablePadding: false,
          label: "Manufacturer Cost Price",
        },
        {
          id: "manufacturerCarryingCost",
          numeric: false,
          disablePadding: false,
          label: "Manufacturer Carrying Cost",
        },
        {
          id: "buyPrice",
          numeric: false,
          disablePadding: false,
          label: "Manufacturer Landing Price",
        },
      ]
    : []),
  {
    id: "userRate",
    numeric: false,
    disablePadding: false,
    label: "User Rate",
  },
  {
    id: "rejectedReason",
    numeric: false,
    disablePadding: false,
    label: "Rejected Reason",
  },
  {
    id: "rejectedReason",
    numeric: false,
    disablePadding: false,
    label: "Edit Order",
  },
  // {
  //   id: "action",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Action",
  // },
  {
    id: "convertOrder",
    numeric: false,
    disablePadding: false,
    label: "Convert Order",
  },
  {
    id: "editOrder",
    numeric: false,
    disablePadding: false,
    label: "Rejected Order",
  },
];

function EnhancedTableHead(props: any) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableToolbar(props: any) {
  const { numSelected } = props;
  const { downloadTermSheet } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Pre Order Book
        </Typography>
      )}

      {numSelected > 0 ? (
        <Button
          variant="contained"
          onClick={() => downloadTermSheet()}
          sx={{ mt: 2, mb: 2 }}
        >
          <CloudDownloadIcon sx={{ mr: 1 }} /> Termsheet
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={() =>
            toast.error("Please Select Order To Download Term Sheet")
          }
          sx={{ mt: 2, mb: 2 }}
        >
          <CloudDownloadIcon sx={{ mr: 1 }} /> Termsheet
        </Button>
      )}
    </Toolbar>
  );
}

const inputStyle = {
  width: "75px",
  padding: "0.5rem 0.5rem",
  borderRadius: "4px",
  border: "1px solid darkgray",
};

const PreOrderTable = () => {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("orderId");
  const [selected, setSelected] = React.useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState<any>([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [setTermSheetData, setSetTermSheetData] = React.useState([]);
  const [clientDetails, setClientDetails] = React.useState<any>();
  const [quantity, setQuantity] = React.useState<any>();
  const [userRate, setUserRate] = React.useState<any>();
  const [rejectReason, setRejectReason] = React.useState<any>();
  const [companyMasterCode, setCompanyMasterCode] = React.useState();
  const [orderDetails, setOrderDetails] = React.useState<any>();
  const [productType, setProductType] = React.useState<any>();
  const [open, setOpen] = React.useState<any>(false);
  const [editOrderModel, setEditOrderModel] = React.useState<any>(false);
  const [editedRows, setEditedRows] = React.useState<any>({});
  const [manufacturerBuyRate, setManufacturerBuyRate] = React.useState<any>("");
  const [manufacturerCarryingCost, setManufacturerCarryingCost] =
    React.useState<any>("");
  const [buyPrice, setBuyPrice] = React.useState<any>("");

  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompanie",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled:
        sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E",
      refetchOnWindowFocus: false,
    }
  );

  const { data: clientData } = useQuery(
    ["getAllClients", companyMasterCode],
    () => GetAllClient(companyMasterCode),
    {
      enabled: !!companyMasterCode || sessionStorage.getItem("Profile") === "D",
      refetchOnWindowFocus: false,
    }
  );

  const { data, refetch } = useQuery(
    ["GetUnlistedDetail", clientDetails?.clientId, productType],
    () => GetPreOrderByClientID(clientDetails?.clientId, productType),
    {
      enabled: !!clientDetails,
      onSuccess(data) {
        setRows(data.data.orderList);
      },
      refetchOnWindowFocus: false,
    }
  );

  const handleFieldChange = (e: any, orderId: any, field: any) => {
    const value = e.target.value;
    setEditedRows((prev: any) => ({
      ...prev,
      [orderId]: {
        ...prev[orderId],
        [field]: value,
      },
    }));
  };

  const handleSave = (orderId: any) => {
    const updatedRows = editedRows[orderId];
    const originalRow = rows.find((row: any) => row.orderId === orderId);
    console.log("updatedRows", updatedRows);
    console.log("originalRow", originalRow);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected: any = rows.map((n: any) => n.orderId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: any, name: any) => {
    const selectedIndex: any = selected.indexOf(name);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSetTermSheetData(newSelected.map((i: any) => "orderId=" + i));
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeDense = (event: any) => {
    setDense(event.target.checked);
  };

  const isSelected: any = (name: never) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows: any =
    data &&
    data.data.orderList.filter((item: any) => {
      return (
        item.clientName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.productName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.productType?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        convertDate(item.createdAt)?.includes(searchTerm.toLowerCase())
      );
    });

  const downloadTermSheet = () => {
    axios({
      url: "product/order/termSheet?" + setTermSheetData.join("&"),
      method: "GET",
      responseType: "blob", // important
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link: any = document.createElement("a");
      link.href = url;
      var date1 = new Date();
      link.setAttribute(
        "download",
        "TermSheet_" +
          ("0" + date1.getDate()).slice(-2) +
          "_" +
          ("0" + (date1.getMonth() + 1)).slice(-2) +
          "_" +
          date1.getFullYear() +
          ".pdf"
      );
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };

  const convertOrder = (orderId: any) => {
    axios
      .put(`${UPDATE_PRE_ORDER_STATUS}${orderId}`)
      .then(() => {
        toast.success("Order Has Been Moved To Order Book");
        const newSelected: any = selected.filter(
          (item: any) => item !== orderId
        );
        setSelected(newSelected);
        refetch();
      })
      .catch(() => {
        toast.error("Something Went Wrong.");
      });
  };

  const submitRejectedReason = (data: any) => {
    if (rejectReason) {
      const rejectedReasonDto: any = {
        orderId: orderDetails?.orderId,
        productTypeCode: productType,
        rejectedReason: rejectReason,
      };
      axios.put(UPDATE_PRE_ORDER, rejectedReasonDto).then((res: any) => {
        toast.success("Your Order Has Been Rejected Successfully! ");
        setOpen(false);
        setRejectReason("");
        refetch();
      });
    } else {
      toast.error("Please Enter A Rejected Reason");
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const registerOptions: any = {
    quantity: { required: "Enter Quantity" },
  };
  const onSubmit = (data: any) => {
    const orderUpdateDTO = {
      tcs: quantity * userRate * (orderDetails?.productTcs / 100),
      quantity: data.quantity,
      userRate: userRate,
      totalValue:
        quantity * userRate +
          quantity * userRate * (orderDetails?.productTcs / 100) || null,
      orderId: orderDetails?.orderId,
      productTypeCode: productType,
      manufacturerBuyRate: Number(manufacturerBuyRate),
      manufacturerCarryingCost: Number(manufacturerCarryingCost),
      buyPrice: Number(buyPrice),
    };
    axios
      .put("product/order", orderUpdateDTO)
      .then((res: any) => {
        setEditOrderModel(false);
        toast.success("Order Has Been Updated SuccessFully!");
        refetch();
      })
      .catch((err: any) => {
        toast.error("Something Went Wrong!");
      });
    console.log("orderUpdateDTO", orderUpdateDTO);
  };

  // const updateOrder = () => {

  //   const orderUpdateDTO = {
  //     "tcs": quantity * userRate * (orderDetails?.productTcs / 100),
  //     "quantity": quantity,
  //     "userRate": userRate,
  //     "totalValue": (quantity * userRate + quantity * userRate * (orderDetails?.productTcs / 100)) || null,
  //     "orderId":orderDetails?.orderId,
  //     "productTypeCode":productType,
  //   }

  //   axios.put("product/order",orderUpdateDTO).then((res:any)=>{
  //     setEditOrderModel(false)
  //     toast.success("Order Has Been Updated SuccessFully!")
  //   }).catch((err:any)=>{
  //     toast.error("Something Went Wrong!")
  //   })

  // }
  let message;
  if (!productType) {
    message = "Please Select a Product";
  } else if (
    !companyMasterCode &&
    (sessionStorage.getItem("Profile") === "B" ||
      sessionStorage.getItem("Profile") === "E")
  ) {
    message = "Please Select a Company";
  } else if (productType && !clientDetails) {
    message = "Please Select a Client";
  } else if (productType && clientDetails && rows.length === 0) {
    message = "No Pending Pre Orders";
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Autocomplete
          disableClearable
          disablePortal
          id="combo-box-demo"
          options={
            sessionStorage.getItem("Profile") === "D"
              ? ProductTypeForPerOrderForD.map((options: any) => options)
              : ProductTypeForPerOrder.map((options: any) => options)
          }
          getOptionLabel={(options: any) => options.productName}
          sx={{ maxWidth: 300, mb: 2 }}
          fullWidth
          onChange={(options: any, newValue: any) => {
            setProductType(newValue ? newValue.productTypeCode : "");
          }}
          renderInput={(params: any) => (
            <TextField {...params} label="Select Product" size="small" />
          )}
        />
        {sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E" ? (
          <Autocomplete
            sx={{ maxWidth: 300, mb: 2 }}
            disablePortal
            id="combo-box-demo"
            options={
              distributorCompanyList &&
              distributorCompanyList.data.companies.map(
                (options: any) => options
              )
            }
            getOptionLabel={(options: any) =>
              options.companyName + " - " + options.companyTypeCode
            }
            fullWidth
            onChange={(options: any, newValue) => {
              setCompanyMasterCode(newValue ? newValue.companyMasterCode : "");
            }}
            renderInput={(params: any) => (
              <TextField {...params} label="Select Company" size="small" />
            )}
          />
        ) : (
          <></>
        )}
        {productType && clientData && (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={
              clientData &&
              clientData.data.clients.map((options: any) => options)
            }
            getOptionLabel={(options: any) =>
              // options.clientId + " - " +
              options.fullName + " - " + options.panCardNo
            }
            sx={{ maxWidth: 300, mb: 2 }}
            fullWidth
            onChange={(options: any, newValue: any) => {
              setClientDetails(newValue ? newValue : "");
            }}
            renderInput={(params: any) => (
              <TextField {...params} label="Select Clients" size="small" />
            )}
          />
        )}
        {productType &&
          (rows.length !== 0 ? (
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                downloadTermSheet={downloadTermSheet}
                // selectedPayout={selected}
              />
              <TableContainer>
                <Table
                  sx={{ minWidth: 1550 }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row: any, index: any) => {
                      const isItemSelected = isSelected(row.orderId);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          //onClick={(event) => handleClick(event, row.orderId)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.orderId}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              onClick={(event: any) =>
                                handleClick(event, row.orderId)
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.orderId}
                          </TableCell>
                          <TableCell align="left">
                            {row.createdAt === "null"
                              ? "-"
                              : convertDate(row.createdAt)}
                          </TableCell>
                          <TableCell align="left">
                            {row.clientName === "null" ? "-" : row.clientName}
                          </TableCell>
                          <TableCell align="left">
                            {row.productName ? row.productName : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.productType ? row.productType : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.quantity
                              ? row.quantity.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 1}
                          </TableCell>
                          {profile === "B" && (
                            <>
                              {/* // Manufacturer Cost Price */}
                              <TableCell align="left">
                                {row.manufacturerBuyRate
                                  ? row.manufacturerBuyRate.toLocaleString(
                                      "en-IN",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )
                                  : "-"}
                              </TableCell>
                              {/* Manufacturer Carrying Cost */}
                              <TableCell align="left">
                                {row.manufacturerCarryingCost
                                  ? row.manufacturerCarryingCost.toLocaleString(
                                      "en-IN",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )
                                  : "-"}
                              </TableCell>
                              {/* Manufacturer Landing Price */}
                              <TableCell align="left">
                                {row.buyPrice
                                  ? row.buyPrice.toLocaleString("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : "-"}
                              </TableCell>
                            </>
                          )}
                          <TableCell align="left">
                            {row.userRate || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.rejectedReason ? row.rejectedReason : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.productType === "UNLISTED" &&
                            sessionStorage.getItem("Profile") === "B" ? (
                              <Button
                                variant="contained"
                                size="small"
                                disabled={!!row.rejectedReason}
                                onClick={() => {
                                  setQuantity(row.quantity);
                                  setUserRate(row.userRate);
                                  setEditOrderModel(true);
                                  setOrderDetails(row);
                                  setManufacturerBuyRate(
                                    row.manufacturerBuyRate
                                  );
                                  setManufacturerCarryingCost(
                                    row.manufacturerCarryingCost
                                  );
                                  setBuyPrice(row.buyPrice);
                                }}
                              >
                                Edit Order
                              </Button>
                            ) : (
                              <Button variant="contained" size="small" disabled>
                                Edit Order
                              </Button>
                            )}
                          </TableCell>
                          {/* <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleSave(row?.orderId)}
                            >
                              Save
                            </Button>
                          </TableCell> */}
                          <TableCell align="left">
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => convertOrder(row.orderId)}
                              disabled={!!row.rejectedReason}
                            >
                              Convert To Order
                            </Button>
                          </TableCell>
                          <TableCell align="left">
                            {row.productType === "UNLISTED" &&
                            sessionStorage.getItem("Profile") === "B" ? (
                              <IconButton
                                disabled={!!row.rejectedReason}
                                onClick={() => {
                                  setOpen(true);
                                  setOrderDetails(row);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            ) : (
                              <IconButton disabled>
                                <CloseIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/* {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          ) : (
            ""
          ))}

        <Typography> {message} </Typography>
      </Box>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
            alignContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h5"> Reject Order </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography> Order Id : {orderDetails?.orderId} </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="outlined-basic"
                label="Rejected Reason"
                variant="outlined"
                fullWidth
                sx={{ maxWidth: 300 }}
                onChange={(event) => setRejectReason(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button variant="contained" onClick={submitRejectedReason}>
                {" "}
                Submit Rejected Reason{" "}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={editOrderModel}
        onClose={() => setEditOrderModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              alignContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h5"> Edit Order </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography> Order Id : {orderDetails?.orderId} </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Quantity"
                  // value={"default"}
                  variant="outlined"
                  fullWidth
                  sx={{ maxWidth: 300 }}
                  // value={quantity}
                  defaultValue={quantity}
                  // onChange={(event)=>setQuantity(event.target.value)}
                  error={errors.quantity ? true : false}
                  helperText={
                    errors.quantity === undefined
                      ? ""
                      : errors.quantity.message + ""
                  }
                  {...register("quantity", registerOptions.quantity)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="User Rate"
                  variant="outlined"
                  fullWidth
                  value={userRate}
                  sx={{ maxWidth: 300 }}
                  onChange={(event) => setUserRate(event.target.value)}
                />
              </Grid>
              {/* // Manufacturer Cost Price */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Manufacturer Cost Price"
                  variant="outlined"
                  fullWidth
                  value={manufacturerBuyRate}
                  sx={{ maxWidth: 300 }}
                  onChange={(event) =>
                    setManufacturerBuyRate(event.target.value)
                  }
                />
              </Grid>
              {/* // Manufacturer Carrying Cost */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Manufacturer Carrying Cost"
                  variant="outlined"
                  fullWidth
                  value={manufacturerCarryingCost}
                  sx={{ maxWidth: 300 }}
                  onChange={(event) =>
                    setManufacturerCarryingCost(event.target.value)
                  }
                />
              </Grid>
              {/* // Manufacturer Landing Price */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Manufacturer Landing Price"
                  variant="outlined"
                  fullWidth
                  value={buyPrice}
                  sx={{ maxWidth: 300 }}
                  onChange={(event) => setBuyPrice(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography>
                  {" "}
                  TCS :{" "}
                  {(
                    quantity *
                    userRate *
                    (orderDetails?.productTcs / 100)
                  ).toFixed(2)}{" "}
                  %{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography>
                  {" "}
                  Total Value :{" "}
                  {(
                    quantity * userRate +
                    quantity * userRate * (orderDetails?.productTcs / 100)
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button variant="contained" type="submit">
                  {" "}
                  Update Order{" "}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "80%",
  overflowY: "auto",
};

export default PreOrderTable;
