import {
  Alert,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  SnackbarOrigin,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useQuery } from "react-query";
import { getPasswordPolicy } from "../api/Api";

export interface State extends SnackbarOrigin {
  openSnack: boolean;
}

const userLogout = () => {
  axios.post("authentication/logout");
  sessionStorage.clear();
  window.location.href = "/";
  console.log("User Log out function");
};


const ChangePassword = () => {
  const [authErrorMessage, setauthErrorMessage] = useState("");
  const [authErrortype, setauthErrortype] = useState<any>("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
   const [errorMessage, setErrorMessage] = useState("");
   const [confError, setConfError] = useState("");
   const [policy,setPolicy]=useState<any>([]);

   const { data } = useQuery("passWordPolicy",getPasswordPolicy,{onSuccess:(data:any)=>{ setPolicy(data.data.passwordPolicy[0]) }});
console.log("policy",policy)

const passList = [
  {
    primary : "Minimum "+policy.uppercaseAlphabet+" Upper Case Required"
  },
  {
    primary : "Minimum "+policy.lowerAlphabet+" Lower Case Required"
  },
  {
    primary : "Minimum "+policy.numNumeric+" Numeric Required"
  },
]

   function handlePassword(event:any) {
      let new_pass = event.target.value;
      console.log("new_pass",new_pass.length)
      if(new_pass.length===0){
        setErrorMessage("");
        setPassword("")
      }else{
        setPassword(new_pass);
        // regular expressions to validate password
        var lowerCase = /[a-z]/g;
        var upperCase = /[A-Z]/g;
        var numbers = /[0-9]/g;
        if (!new_pass.match(upperCase)) {
           setErrorMessage("Password should contains upperCase letters!");
        } else if (!new_pass.match(lowerCase)) {
           setErrorMessage("Password should contain lowerCase letters!");
        } else if (!new_pass.match(numbers)) {
           setErrorMessage("Password should contains numbers also!");
        }else {
           setErrorMessage(""); 
        }
      }
   }

   function handleConfirm(event:any){
    let conf = event.target.value;
    setConfPassword(conf)
    if(conf !== password){
      setConfError("Password Does not Match");
    }
    else{
      setConfError("");
    }
   }

  const [state, setState] = React.useState<State>({
    openSnack: false,
    vertical: "top",
    horizontal: "right",
  });

  const { openSnack } = state;

  const resolver = async (values: any) => {
    return {
      values: !values.oldPassword ? {} : values,
      errors: !values.oldPassword
        ? {
            oldPassword: {
              type: "required",
              message: "This is required.",
            },
          }
        : {},
    };
  };

  const { register, handleSubmit } = useForm({ resolver });

  const onSubmit = (data: any) => {
    // console.log("Data",data)
    if (data.newPassword === data.conformPassword) {
      axios
        .put("userProfile/updatePassword", {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        })
        .then(() => {
          setauthErrortype("success");
          setauthErrorMessage("Password Change successfully");
          userLogout();
        })
        .catch((err: any) => {
          setauthErrortype("error");
          setauthErrorMessage(err.response.data.status.message.title);
        });
    } else setauthErrortype("error");
    setauthErrorMessage("Password Does't Match");
    setState({ openSnack: true, vertical: "top", horizontal: "right" });
  };

  const handleCloseSnackbar = () => {
    setState({ ...state, openSnack: false });
  };

  console.log("errorMessage",errorMessage)

  return (
    <Container maxWidth="xl">
      <div>
        <Box
          sx={{
            paddingTop: 10,
            "& .MuiTextField-root": { m: 1, width: "25ch" },
            display:'flex',
            justifyContent:'center'
          }}
        >
          <Paper sx={{
            height:'100%',
            width:400,
            borderRadius:'15px',
            padding:'32px',
            backgroundColor:'#e7eeff'
          }} elevation={3}>
          <Typography variant="h4" component="h3" sx={{mb:3}}>
            Change Password
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              id="outlined-basic"
              label="Old Password"
              variant="outlined"
              fullWidth
              sx={{width:'100%'}}
              {...register("oldPassword")}
            />
            <TextField
              id="outlined-basic"
              label="New Password"
              variant="outlined"
              value={password}
              {...register("newPassword")}
              onChange={handlePassword}
            />
            <div style = {{ color: "red", fontSize:'0.8rem', marginLeft:'21%', textAlign:'left' }}> {errorMessage} </div>
            <TextField
              id="outlined-basic"
              label="Conform Password"
              variant="outlined"
              value={confPassword}
              {...register("conformPassword")}
              onChange={handleConfirm}
            />
            <div style = {{ color: "red", fontSize:'0.8rem', marginLeft:'21%', textAlign:'left' }}> {confError} </div>
          <List dense sx={{marginLeft:'12%'}}>
            {passList.map((it:any) => (
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={it.primary}
                  />
                </ListItem>
            ))}
            </List>
            <Button type="submit" color="primary" variant="contained">
              Submit
            </Button>
          </form>
          </Paper>
        </Box>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnack}
          onClose={handleCloseSnackbar}
          message="I love snacks"
          autoHideDuration={4000}
        >
          <Alert severity={authErrortype} sx={{ width: "100%" }}>
            {authErrorMessage}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
};

export default ChangePassword;
