/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { withParam } from "../utils/Router.Helper";

import { TabContext } from "@mui/lab";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OrderBookTableData from "./OrderBookTableData";
import OrderBookRequestForQuote from "./OrderBookRequestForQuote";
import OrderBookType from "./OrderBookType";
import { useQueryClient } from "react-query";

const OrderBook = () => {
  const [value, setValue] = React.useState("ORDER BOOK");
  const queryClient = useQueryClient();

  const refetchRequestForQuotesData = async () => {
    try {
      // Refetch the "RequestForQuote" query
      await queryClient.refetchQueries("RequestForQuote");
    } catch (error) {
      console.error("Error refetching request for quotes data:", error);
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === "REQUEST FOR QUOTE") {
      // Fetch request for quotes data on tab change
      refetchRequestForQuotesData();
    }
  };

  return (
    <div
    // maxWidth="xl"
    >
      <Box sx={{ minHeight: "calc(100vh - 150px)" }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Order Book
        </Typography>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab label="Order Book" value="ORDER BOOK" />
                {sessionStorage.getItem("Profile") === "B" ? (
                  <Tab label="Request For Quote" value="REQUEST FOR QUOTE" />
                ) : (
                  <></>
                )}
              </TabList>
            </Box>
            <TabPanel value="ORDER BOOK">
              {/* <OrderBookTableData/> */}
              <OrderBookType></OrderBookType>
            </TabPanel>
            {sessionStorage.getItem("Profile") === "B" ? (
              <TabPanel value="REQUEST FOR QUOTE" sx={{ padding: "24px 0px" }}>
                <OrderBookRequestForQuote />
              </TabPanel>
            ) : (
              <></>
            )}
          </TabContext>
        </Box>
      </Box>
    </div>
  );
};

export default withParam(OrderBook);
