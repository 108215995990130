import { Container } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import newreleaseblogs from "../images/newreleaseblogs.png"


const Blog4 = () => {
  return (
    <Container maxWidth="lg">
<section className="blog-single">
	<div className="container cont1">
		<div className="row">
			<div className="col-md-10 m-auto single-page-col-main">
		
				<article className="single-post">
				
					<div className="post-title text-center">
						<h1 className='blog-single-main-education-h1'>
                        <p>Parent's guide to calculate children's education expenses</p>
                        </h1>
					    <ul className="list-inline post-tag single-blog-date">
						  <li className="list-inline-item">
								<span>September 26, 2021</span>
							</li>
						</ul>
					</div>
				
					<div className="post-body">
				     <div className="feature-image" style={{textAlign:'center'}}>
						<img src={newreleaseblogs} alt="" style={{width:"43%"}}  />
						</div>
						<div className="blog-page-desp-p-tag">
    <p>Education is turning out to be one of the biggest outflows families are paying out.
Quality education needs investment and planning. Global research suggests that
investing in education pays a higher return than a financial investment in stocks and
bonds. "For one extra year of schooling globally, the average individual return is 9%." But
before investing, one must have a rough idea of how much the higher education of his
child would cost at the time when his child reaches that age. It is pivotal to estimate the
amount one needs to save for his child's higher education, so that the parent can plan
his investments accordingly. Here is a quick guide to calculate the monthly savings you
would require to fund your child's future education, in stepwise format.

    </p>
    <br/>
    <p>
       <h4> 1) Ascertain the time frame:</h4>
	   For designing the investment portfolio, time is one of the most important factors. You
can easily calculate the term by computing the difference between the age at which
your child is going to pursue higher studies and the present age of your child. This time
span that we got, determines how long you have to invest. For eg: if your child is 5 year
old at present and would pursue his/her degree course at the age of 18 years, so you
have the time frame of 13 years to save.
    </p>
    {/* <br/> */}
    <p>
       <h4> 2) Analyse the cost of the course your child would like to pursue:</h4>
	   Explore and find out the current education cost of the courses your child is likely to
pursue in future. The cost varies with the type of courses, institutes and their location
(whether local or abroad). Also, there are several expenses other than tuition fees that
have to be taken into consideration as well, such as the cost of books, stationery,
accommodation, food, transport, coaching etc. So, this groundwork of getting an
estimate of the total cost of education is the second step towards determining your
saving goals.
    </p> 
    {/* <br/> */}
    <p>
       <h4> 3) Take in to account education Inflation rate:</h4>
	   Education inflation rate is about 10-12 percent, which is twice the household inflation.
Considering the impact of inflation, the education cost is expected to hike faster than
the cost of other services. So, keeping the inflation factor in mind for calculating the
future cost of education is crucial. For eg: Let's assume the current total cost of a
particular degree course is around 15 lacs, so in the time span of 13 years keeping in
account the 10% inflation rate, the amount you need for the same course would be
around 52 lacs after 13 years.
    </p>
    {/* <br/> */}
    <p>
        <h4>4) Choose return rate wisely:</h4>
		For building the required education corpus it is wise to pick the rate of return which is a
little higher than the rate of inflation. For eg: investing in mutual funds or equity is
expected to give you higher returns than depositing your savings in a bank. For this you
may also choose to take help from the experts.

    </p>
    {/* <br/> */}
    <p>
       <h4> 5) Determine your monthly investment target:</h4>
	   Now that you have all the data and maths done for the required rate of return and the
financial goal to be attained, it would be effortless for you to calculate the amount that
you need to set aside as your monthly savings to be invested in a planned and intelligent
manner to achieve your child's higher education goals.
    </p>
    {/* <br/> */}
    <p>
        <h4>6)  Keep reviewing your investments:</h4>
		Though you are sorted, once you set the goal amount to be invested in monthly SIP, it is
highly advisable to scrutinize your current plan periodically and increase your SIP
amount with the increase in your income or savings.
    </p>
    <br/>
    <p>
	This simple six step process will allow you to accomplish your targets easily and
effectively, and you will be able to fund the higher education of your child without feeling
the financial burden.
    </p>
    
</div>
					</div>
				</article>
			
			
			</div>
		</div>
		
<div className='wrapper-next-butoon-blogs-span-main-home'>
<div className="wrapper-next-butoon-blogs">
  <a className="cta-privious" href="">
	{/* <Link to={"/BlogSingle3"}><span className='wrapper-next-butoon-blogs-span'>PREVIOUS</span> </Link>
    <span className='wrapper-next-butoon-blogs-span'>
      <svg style={{transform: "rotate(180deg)"}} width="px" height="43px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path className="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path className="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path className="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
        </g>
      </svg>
    </span>  */}
  </a>
</div>
<div className='wrapper-next-butoon-blogs-home-main1'>
{/* <Link to={"/Blogs"}><span className='wrapper-next-butoon-blogs2-span'><AiOutlineHome className='wrapper-next-butoon-blogs2-home-icone' title="HOME"></AiOutlineHome></span> </Link> */}
</div>
</div>
    {/* <div className="blog-single-related-artical">
		<div className="row">
			<div className="col-12 ">
			<h2 className='blog-single-artical-h2'>Related Articles</h2>
			</div>
		</div>
		<div className="row">
			<div className="col-lg-4 col-md-6">
               <article className="post-sm">
				
					<div className="post-thumb">
						<a href="blog-single.html"><img src={post} alt="" /></a>
					</div>
				
					<div className="post-title blog-single-post-title">
						<h3><a href="blog-single.html">Praesent sapien massa, convallis a pellentesque nec</a></h3>
					</div>
				
					<div className="post-meta">
						<ul className="list-inline post-tag">
							<li className="list-inline-item">
							
                                <img src={feature} alt="" />
							</li>
							<li className="list-inline-item">
								<a href="#">Rozy Afzal</a>
							</li>
							<li className="list-inline-item">
								August 22, 2021
							</li>
						</ul>
					</div>
				
					<div className="post-details">
						<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. </p>
					</div>
				</article>
			</div>
			<div className="col-lg-4 col-md-6">
		
				<article className="post-sm">
		
					<div className="post-thumb">
						<a href="blog-single.html"><img src={posts} alt="" /></a>
					</div>
				
					<div className="post-title blog-single-post-title">
						<h3><a href="blog-single.html">Praesent sapien massa, convallis a pellentesque nec</a></h3>
					</div>
				
					<div className="post-meta">
						<ul className="list-inline post-tag">
							<li className="list-inline-item">
							
                                <img src={feature} alt="" />
							</li>
							<li className="list-inline-item">
								<a href="#">Rozy Afzal</a>
							</li>
							<li className="list-inline-item">
								August 14, 2021
							</li>
						</ul>
					</div>
				
					<div className="post-details">
						<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. </p>
					</div>
				</article>
			</div>
			<div className="col-lg-4 col-md-6">
		
				<article className="post-sm">
				
					<div className="post-thumb">
						<a href="blog-single.html"><img src={postes} alt="" /></a>
					</div>
				
					<div className="post-title blog-single-post-title">
						<h3><a href="blog-single.html">Praesent sapien massa, convallis a pellentesque nec</a></h3>
					</div>
			
					<div className="post-meta">
						<ul className="list-inline post-tag">
							<li className="list-inline-item">
							
                                <img src={feature} alt="" />
							</li>
							<li className="list-inline-item">
								<a href="#">Rozy Afzal</a>
							</li>
							<li className="list-inline-item">
								August 11, 2021
							</li>
						</ul>
					</div>
				
					<div className="post-details">
						<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. </p>
					</div>
				</article>
			</div>
		</div>
	</div> */}
	</div>
</section>


      </Container>
  )
}

export default Blog4