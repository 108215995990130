/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import { useMutation } from "react-query";
import {
  AIF_AMC_LIST,
  AIF_GET_BY_ID,
  AIF_LIST_ALL,
  BOOK_A_CALL_GET_BY_ID,
  CART_BY_USER,
  EMPLOYEE_DASHBOARD_FILE_LIST,
  GET_ALL_BOOK_A_CALL,
  GET_ALL_MUTUAL_FUND,
  GET_ALL_TRANSACTION,
  GET_SCHEME_DATA_FROM_AMC_ID,
  LIST_OF_CLIENTS,
  MUTUAL_FUND_GET_BY_ID,
  PMS_AMC_LIST,
  MF_AMC_LIST,
  PMS_GET_ALL,
  PMS_GET_BY_ID,
  PRODUCT_LIST,
  SELF_USER_PROFILE,
  UNLISTED_FIXED_RATE,
  MANAGER_BY_ID,
  GET_ALL_RESEARCH_SERVICES_PLAN,
  GET_ALL_NCD,
  GET_NCD_BY_ID,
  PASSWORD_POLICY,
  TRANSACTION_BY_CLIENT,
} from "../utils/API_Names";
import { toast } from "react-toastify";

export const fetchAifData = async () => {
  try {
    const data: any = await axios.get("aif/listOfAif");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

const addUserData = (hero: any) => {
  return axios({
    method: "post",
    url: "/therapist",
    data: hero,
  });
};

export const useAddUserData = () => {
  return useMutation(addUserData, {
    onError: () => {
      console.error("Error adding therapist");
    },
  });
};

export const fetchEquityData = async () => {
  try {
    const data: any = await axios.get("equity");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const fetchFixedIncData = async () => {
  try {
    const data: any = await axios.get("fixedIncome/listOfFixedIncome");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const fetchInsuranceData = async () => {
  try {
    const data: any = await axios.get("insurance");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const fetchMutualFundData = async () => {
  try {
    const data: any = await axios.get("mutualFund");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const fetchPmsData = async () => {
  try {
    const data: any = await axios.get("pms");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const fetchPrimaryIsData = async () => {
  try {
    const data: any = await axios.get("primaryissue");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const fetchRealEstateData = async () => {
  try {
    const data: any = await axios.get("realestate");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const fetchStartupData = async () => {
  try {
    const data: any = await axios.get("startupecosystem");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const fetchTrainingData = async () => {
  try {
    const data: any = await axios.get("training");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getAllUnlistedShare = async () => {
  try {
    const data: any = await axios.get("unlisted/getAllUnlisted");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getAllUnlistedShareMaster = async () => {
  try {
    const data: any = await axios.get(
      "unlistedMaster/listOfAllUnlistedMasterDetails"
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getAllUnlistedShareById = async (UnlistedId: any) => {
  try {
    const data: any = await axios.get("unlisted/getUnlistedById/" + UnlistedId);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const UnlistedMasterShareById = async (UnlistedMasterId: any) => {
  try {
    const data: any = await axios.get(
      "unlistedMaster/getUnlistedMasterDetails/" + UnlistedMasterId
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const UnlistedById = async (UnlistedId: any) => {
  try {
    const data: any = await axios.get("unlisted/getAllForManu/" + UnlistedId);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetActiveUnlisted = async () => {
  try {
    const data: any = await axios.get("unlisted/getUnlistedIsActive");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetAllUnlistedWithQuantity = async (companyMasterCode: any) => {
  try {
    const data: any = await axios.get(
      "unlisted/listOfCustomerAndBrokerSaleRateWithRange" + companyMasterCode
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetAllUnlistedMinMax = async (unlistedMasterCode: any) => {
  try {
    const data: any = await axios.get(
      "unlisted/getAllMinMaxValue" + unlistedMasterCode
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetByIdProductList = async (
  unlistedMasterCode: any,
  companyMasterCode: any
) => {
  try {
    const data: any = await axios.get(
      "unlisted/getUnlistedDetails/" + unlistedMasterCode + companyMasterCode
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetFixedRateUnlisted = async () => {
  try {
    const data: any = await axios.get(
      UNLISTED_FIXED_RATE + "listOfAllUnlistedFixedRateDetails"
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetUnlistedBackofficeCode = async (
  unlistedBackOfficeCode: any
) => {
  try {
    const data: any = await axios.get(
      "unlistedFixedRate/getUnlistedFixedRate/" + unlistedBackOfficeCode
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetAllUnlDetails = async () => {
  try {
    const data: any = await axios.get("unlisted/listScriptNameNScriptLogo");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetAllUnlistedMasterDetails = async () => {
  try {
    const data: any = await axios.get(
      "unlistedMaster/listOfAllUnlistedMasterDetails"
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetBrokeragePayout = async (companyMasterCode: any) => {
  try {
    const data: any = await axios.get(
      "companies/CompanyBrokerage/" + companyMasterCode
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetAllBrokerMaster = async () => {
  try {
    const data: any = await axios.get("Brokers/listOfBroker");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetBrokeragePayoutByCompanyMasterCode = async (
  companyMasterCode: any
) => {
  try {
    const data: any = await axios.get(
      "companyBrokerage/getByCompanyMasterCode/" + companyMasterCode
    );

    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetBrokerMasterById = async (brokerMasterCode: any) => {
  try {
    const data: any = await axios.get(
      "Brokers/brokerDetail/" + brokerMasterCode
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};
export const GetBondsMaster = async () => {
  try {
    const data: any = await axios.get("bondwork/listOfAllBondDetails");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetBondsMasterActiveInactiveList = async (bondStatus: any) => {
  try {
    const data: any = await axios.get(
      "bondwork/listOfAllBondDetails" + bondStatus
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetAllClient = async (
  companyMasterCode: String = "",
  employeeCode: String = "",
  forSelf: String = "false"
) => {
  try {
    // Check if forSelf should be true based on the profile
    if (sessionStorage.getItem("Profile") === "E") {
      forSelf = "true";
    }
    const data: any = await axios.get(
      `${LIST_OF_CLIENTS}?companyMasterCode=${companyMasterCode}&employeeCode=${employeeCode}&forSelf=${forSelf}`
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetClientById = async (ClientId: any) => {
  try {
    const data: any = await axios.get("client/clientDetail/" + ClientId);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

// export const GetUnlistedDetails = async (convertedOrder:string = "true") => {
//   try {
//     const data: any = await axios.get(`product/order/byuser?convertedOrder=true&orderStatus=COMPLETED`);

//     return data;
//   } catch (error: any) {
//     if (error.response.status === 401) {
//       sessionStorage.removeItem("JWT")
//       window.location.href = "/"
//     }
//     throw Error("Unable to fetch data");
//   }

// }

// localhost:8081/iv/v1/product/order/byuser?convertedOrder=true&fromDate=2023-11-01&maxInvAmt=100&minInvAmt=100000000&orderStatus=PENDING&productTypeCode=11&toDate=2023-12-02

export const GetUnlistedDetails = async (
  convertedOrder: string = "true",
  orderStatus: string,
  fromDate: string,
  toDate: string,
  ProductNameData: any,
  investmentAmount: string
) => {
  try {
    // const data: any = await axios.get(`product/order/byuser?convertedOrder=${convertedOrder}&fromDate=2023-11-29&orderStatus=${orderStatus}&toDate=2023-11-30`);
    const data: any = await axios.get(
      `product/order/byuser?convertedOrder=${convertedOrder}&fromDate=${fromDate}&tier=${investmentAmount}&orderStatus=${orderStatus}&productTypeCodes=${ProductNameData}&toDate=${toDate}`
    );
    return data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetUnlistedOrderByClientID = async (
  clientID: any,
  productTypeCode: any,
  convertedOrder: string = "true"
) => {
  try {
    const data: any = await axios.get(
      "product/order/byuser/" +
        clientID +
        "/" +
        productTypeCode +
        "/" +
        convertedOrder
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetPreOrderByClientID = async (
  clientID: any,
  productTypeCode: any
) => {
  try {
    const data: any = await axios.get(
      "product/order/preOrder/" + clientID + "/" + productTypeCode
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetUnlistedOrderStatusDetailsById = async (productType: any) => {
  try {
    const data: any = await axios.get(
      "product/getProductWorkFlow?productTypeCode=" + productType
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetUnlistedOrderSnapshotById = async (UnlistedOrderId: any) => {
  try {
    const data: any = await axios.get(
      "product/OrderSnapshot?orderId=" + UnlistedOrderId
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetUnlistedOrderById = async (
  UnlistedOrderId: any,
  stepSequence: any,
  productTypeCode: any
) => {
  try {
    // const data: any = await axios.get("product/order/workflow/" + UnlistedOrderId+"/"+productTypeCode+"?stepSequence="+stepSequence);
    const data: any = await axios.get(
      `product/?orderId=${UnlistedOrderId}&productTypeCode=${productTypeCode}&stepSequence=${stepSequence}`
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetAllBonds = async () => {
  try {
    const data: any = await axios.get("bondwork/listOfAllBondDetails");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

// export const GetAllManuUnlistedOrders = async () => {
//   try {
//     const data: any = await axios.get("unlisted/listAllUnlistedProducts");
//     return data;
//   } catch (error: any) {
//     if (error.response.status === 401) {
//       sessionStorage.removeItem("JWT")
//       window.location.href = "/"
//     }
//     throw Error("Unable to fetch data");
//   }
// }

export const userProfile_getUserTypeDetails = async () => {
  try {
    const data: any = await axios.get("userProfile/getUserTypeDetails");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const company_listOfCompanies = async () => {
  try {
    const data: any = await axios.get("company/listOfCompanies");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const product_listOfAllProductType = async () => {
  try {
    const data: any = await axios.get(PRODUCT_LIST);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const product_listOfAllProductType_WithOtpToken = async () => {
  try {
    const data: any = await axios.get(PRODUCT_LIST, {
      headers: {
        Authorization: sessionStorage.getItem("OTP_Token"),
      },
    });
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("OTP_Token");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const product_ProductTypeByID = async (Id: any) => {
  try {
    const data: any = await axios.get("product/getProductTypeDetail/" + Id);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const Brokers_listOfBroker = async () => {
  try {
    const data: any = await axios.get("Brokers/listOfBroker");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const listOfManufactureNDistributorCompanies = async (UserType: any) => {
  try {
    const data: any = await axios.get(
      "company/listOfManufactureNDistributorCompanies/companyTypeCode?companyTypeCode=" +
        UserType
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const MenufactureOrderDetails = async (productMasterCode: any) => {
  try {
    const data: any = await axios.get(
      "unlisted/getUnlistedProductDetails/" + productMasterCode
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetAllBondQuote = async () => {
  try {
    const data: any = await axios.get("quoteDetails/listOfAllQuotesDetails");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetQuoteDetailsByID = async (QuoteId: any) => {
  try {
    const data: any = await axios.get(
      "quoteDetails/getQuoteDetails/" + QuoteId
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetQuoteDetailsByIsin = async (Isin: any) => {
  try {
    const data: any = await axios.get(
      "quoteDetails/getQuoteDataDetails/" + Isin
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetYtmYtcByPrice = async (
  Isin: any,
  manufacturePrice: any,
  salePrice: any,
  employeePrice: any
) => {
  try {
    const data: any = await axios.get(
      "quoteDetails/xirrCalculation?isinNumber=" +
        Isin +
        "&manufacturePrice=" +
        manufacturePrice +
        "&salePrice=" +
        salePrice +
        "&employeePrice=" +
        employeePrice
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetPriceByYtm = async (
  Isin: any,
  manufactureYtm: any,
  saleYtm: any,
  employeeYtm: any
) => {
  try {
    const data: any = await axios.get(
      "quoteDetails/xnpvCalculation?isinNumber=" +
        Isin +
        "&saleYtm=" +
        saleYtm +
        "&ytm=" +
        manufactureYtm +
        "&employeeYtm=" +
        employeeYtm
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const Get_Bond_Details_By_Isin = async (Isin: any) => {
  try {
    const data: any = await axios.get("bondwork/listOfBondDetails/" + Isin);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetMaturityCashFlow = async (Isin: any) => {
  try {
    const data: any = await axios.get(
      "cashflow/listOfCashFlowMaturityDateByIsinNumber/{isinNumber}?isinNumber=" +
        Isin
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetListOfUserByCompanyMasterCodeAndUserTypeCode = async (
  UserTypeCode: any,
  companyMasterCode: any
) => {
  try {
    const data: any = await axios.get(
      "userProfile/listOfUserTypeUserProfile/" +
        UserTypeCode +
        "?companyMasterCode=" +
        companyMasterCode
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const RequestForQuote = async () => {
  try {
    const data: any = await axios.get("product/requestForQuote");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const proposalListByUser = async (ClientId: any) => {
  try {
    const data: any = await axios.get(`${CART_BY_USER}${ClientId}`);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const AifAmcList = async () => {
  try {
    const data: any = await axios.get(AIF_AMC_LIST + "&isActive=YES");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const PmsAmcList = async () => {
  try {
    const data: any = await axios.get(PMS_AMC_LIST + "&isActive=YES");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const MfAmcList = async () => {
  try {
    const data: any = await axios.get(MF_AMC_LIST + "&isActive=YES");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const AifList = async (filterDataURL: any, isFocused: String = "") => {
  try {
    const data: any = await axios.get(
      `${AIF_LIST_ALL}?isFocusedFund=${isFocused}`
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};
export const pmsgetAllList = async (isFocused: String = "") => {
  try {
    const data: any = await axios.get(
      `${PMS_GET_ALL}?isFocusedFund=${isFocused}`
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const AifGetById = async (SchemeCode: any) => {
  try {
    const data: any = await axios.get(`${AIF_GET_BY_ID}${SchemeCode}`);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const PmsGetById = async (SchemeCode: any) => {
  try {
    const data: any = await axios.get(`${PMS_GET_BY_ID}${SchemeCode}`);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const ManagerGetById = async (ManagerCode: any) => {
  try {
    const data: any = await axios.get(`${MANAGER_BY_ID}${ManagerCode}`);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const MfGetAllList = async (isFocused: String = "") => {
  try {
    const data: any = await axios.get(
      `${GET_ALL_MUTUAL_FUND}?isFocusedFund=${isFocused}`
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const MfGetById = async (SchemeCode: any) => {
  try {
    const data: any = await axios.get(`${MUTUAL_FUND_GET_BY_ID}${SchemeCode}`);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const selfProfile = async () => {
  try {
    const data: any = await axios.get(SELF_USER_PROFILE);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const listOfAllEmployeeFiles = async (
  documentType: String = "",
  month: String = ""
) => {
  try {
    const data: any = await axios.get(
      `${EMPLOYEE_DASHBOARD_FILE_LIST}?documentType=${documentType}&month=${month}`
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getTransactionDetails = async () => {
  try {
    const data: any = await axios.get(`${GET_ALL_TRANSACTION}`);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getAllBookACall = async () => {
  try {
    const data: any = await axios.get(`${GET_ALL_BOOK_A_CALL}`);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const BookACallGetById = async (bookCallDetailsId: string = "") => {
  try {
    const data: any = await axios.get(
      `${BOOK_A_CALL_GET_BY_ID}${bookCallDetailsId}`
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetAllManuUnlistedOrders = async () => {
  const data = await axios
    .get("unlisted/listAllUnlistedProducts")
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      console.log("err-", err);
      if (err.response.status === 400) {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      }
      if (err.response.status === 403) {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      }
      if (err.response.status === 401) {
        toast.warning("Please re-login or You will redirect to Home page");
        setTimeout(() => {
          sessionStorage.removeItem("JWT");
          window.location.href = "/";
        }, 6000);
      }
      // return Error(err);
    });
  return data;
};

export const getAllResearchServicesPlan = async () => {
  try {
    const data: any = await axios.get(`${GET_ALL_RESEARCH_SERVICES_PLAN}`);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getAllNCD = async () => {
  try {
    const data: any = await axios.get(`${GET_ALL_NCD}`);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getNcdById = async (productMasterCode: String = "") => {
  try {
    const data: any = await axios.get(`${GET_NCD_BY_ID}${productMasterCode}`);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

// *************************** start PortfolioAnalyzer************************

export const getportfolioanalyzer = async (pan: any) => {
  try {
    const data: any = await axios.get("portfolioanalyzer/cas?panNumber=" + pan);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getCasFile = async () => {
  try {
    const data: any = await axios.get("portfolioanalyzer/getCasByUser");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getPortfolioOverview = async () => {
  try {
    const data: any = await axios.get(
      "portfolioanalyzer/portfolioOverview?casId=" +
        sessionStorage.getItem("casId")
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getPortfolioDashBoard = async () => {
  try {
    const data: any = await axios.get(
      "portfolioanalyzer/portfolioDashBoard?casId=" +
        sessionStorage.getItem("casId")
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export async function getUnderOutPerforming(selectedReturnTypeData: any) {
  try {
    const queryParams = [];
    queryParams.push(`casId=${sessionStorage.getItem("casId")}`);

    // Add query parameters based on userTypeData values
    if (selectedReturnTypeData["5 Year"]) {
      queryParams.push("isFiveYrReturn=true");
    } else {
      queryParams.push("isFiveYrReturn=false");
    }
    if (selectedReturnTypeData["1 Year"]) {
      queryParams.push("isOneYrReturn=true");
    } else {
      queryParams.push("isOneYrReturn=false");
    }

    if (selectedReturnTypeData["3 Year"]) {
      queryParams.push("isThreeYrReturn=true");
    } else {
      queryParams.push("isThreeYrReturn=false");
    }

    const url = `portfolioanalyzer/getUnderAndOutPerformingSchemes?${queryParams.join(
      "&"
    )}`;
    const response = await axios.get(url);

    return response;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
}

// export const getPortfolioDashBoard=async ()=>{
//   try{
//     const data : any = await axios.get('portfolioanalyzer/portfolioDashBoard?casId='+sessionStorage.getItem('casId'))
//     return data;
//   } catch (error: any) {
//     if (error.response.status === 401) {
//       sessionStorage.removeItem("JWT")
//       window.location.href = "/"
//     }
//     throw Error("Unable to fetch data");
//   }
// }
export const getModelPortFolio = async () => {
  try {
    const data = await axios.get("portfolioanalyzer/modelPortfolio");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getPortfolioComposition = async () => {
  try {
    const data = await axios.get(
      "portfolioanalyzer/AssetAllocationDataOfUser?casId=" +
        sessionStorage.getItem("casId")
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getSegmentationAllocation = async () => {
  try {
    const data = await axios.get(
      "portfolioanalyzer/getSegmentAllocationResponse?casId=" +
        sessionStorage.getItem("casId")
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};
export const getPortfolioReportDetails = async () => {
  try {
    const data = await axios.get(
      "portfolioanalyzer/getDetailFolioDetailsForPortfolio?casId=" +
        sessionStorage.getItem("casId")
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};
export const getSectorExposureDetails = async () => {
  try {
    const data = await axios.get(
      "portfolioanalyzer/sectorExposureDetails?casId=" +
        sessionStorage.getItem("casId")
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};
export const getUserPortfolioProfile = async () => {
  try {
    const data = await axios.get(
      "portfolioanalyzer/getUserPortfolioProfile?casId=" +
        sessionStorage.getItem("casId")
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};
export const getQuality = async () => {
  try {
    const data = await axios.get(
      "portfolioanalyzer/portfolioAnalysisByZScore?casId=" +
        sessionStorage.getItem("casId")
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getPasswordPolicy = async () => {
  try {
    const data = await axios.get(PASSWORD_POLICY);
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getProductTotalAmo = async () => {
  try {
    const data = await axios.get("trade/fetchTradeByUser");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const GetTransactionByClient = async (
  ClientId: any,
  ProductTypeCode: any
) => {
  try {
    const data: any = await axios.get(
      TRANSACTION_BY_CLIENT +
        "?clientId=" +
        ClientId +
        "&productTypeCode=" +
        ProductTypeCode
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("Unable to fetch data");
  }
};

export const getLoanAmc = async () => {
  try {
    const data = await axios.get("loanMaster/listofLoan");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("unable to fetch data");
  }
};

export const getInsuranceAmc = async () => {
  try {
    const data = await axios.get("insurance/listOfInsurance");
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("unable to fetch data");
  }
};
export const getFixedIncomeOrderList = async () => {
  try {
    const data = await axios.get(
      `filter/search?interestPaymentFrequency=${""}&securedUnsecured=${""}&callPutDate=${""}&faceValue=${""}&issuerType=${""}&modeOfIssuance=${""}&rating=${""}&seniorityOfRepayment=${""}&tier=${""}${""}${""}${""}${""}${""}`
    );
    return data;
  } catch (error: any) {
    if (error.response.status === 401) {
      sessionStorage.removeItem("JWT");
      window.location.href = "/";
    }
    throw Error("unable to fetch data");
  }
};
