import { Box, Button, CircularProgress, Container, Grid, Modal, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useQuery } from 'react-query';
import { withParam } from '../utils/Router.Helper'
import { GetUnlistedBackofficeCode } from '../api/Api';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  "& .MuiTextField-root": { m: 1 },
};

const FixedRateDetail = (props:any) => {
  const [unlistedFixedRateModel, setUnlistedFixedRateModel] = useState(false)
  const [showCircularProgress, setshowCircularProgress] = useState(false);

    console.log(props.params.unlistedBackOfficeCode)

    const {data,refetch} = useQuery("GetBackOfficeUnlistedCode", ()=>GetUnlistedBackofficeCode(props.params.unlistedBackOfficeCode));
    console.log('Fixed Data', data);

    function updateFixRateModel(status:any){
      status=="open"?
      setUnlistedFixedRateModel(true)
      : status=="close" ? setUnlistedFixedRateModel(false) : <></>
  }

  const resolver: any = async (values: any) => {
    return {
        values: !values.fixedValue ? {} : values,
        errors: !values.fixedValue ? {
          fixedValue: {
              type: "required",
              message: "This is required.",
            },
          }:{},
    }
  }

  const onSubmit = async (submitedData: any) => {
    setshowCircularProgress(true)
    const newSubmitedData = {
      ...submitedData,
      unlistedFixedRateCode:data && data.data.unlistedFixedDetails[0].unlistedFixedRateCode,
      companyMasterCode:data && data.data.unlistedFixedDetails[0].companyMasterCode,
      unlistedMasterCode:data && data.data.unlistedFixedDetails[0].unlistedMasterCode
    }
  
    // mutate(newSubmitedData);
    axios.put("unlistedFixedRate/updateUnlistedFixedRate",newSubmitedData).then(()=>{
      setshowCircularProgress(false); 
      updateFixRateModel("close")
      toast.success("Fixed rate Updated!")
      refetch()
    })
    .catch((err:any)=>toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title))
    refetch()
  }

  const { register, handleSubmit, formState} = useForm({
    resolver:resolver
  });

  return (
    <>
    <Container maxWidth='xl'>
        <Box sx={{ marginTop: "10%" }}>
           {
            data && data.data.unlistedFixedDetails.map((it:any)=>(
                <>
                <h4>Company Name : <b>{it.companyName}</b></h4>
                <h4>Fixed Value : <b>{it.fixedValue}</b></h4>
                <h4>ISIN no : <b>{it.isinNumber}</b></h4>
                <h4>Unlisted Name : <b>{it.scriptName}</b></h4>
                </>
            ))
           }
           <Button variant='contained' onClick={()=>updateFixRateModel("open")}>Update</Button>
        </Box>

        <Modal
         open={unlistedFixedRateModel}
         onClose={()=>updateFixRateModel("close")}
         >
            <Box sx={style}>
            <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Update Fixed Rate
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>

          <TextField 
            id="outlined-basic" 
            label="Fixed Value" 
            variant="outlined" 
            defaultValue={data && data.data.unlistedFixedDetails[0].fixedValue}
            {...register("fixedValue")}
          />
          <Grid item xs={12} sm={12} md={4} lg={3} ml={1}>
          <Button type="submit" variant="contained" >{showCircularProgress == true ? (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      thickness={5}
                    />
                  ) : (
                    ""
                  )}{" "} Submit
            </Button>
          </Grid>
          </form>
            </Box>
        </Modal>

        
    </Container>
    </>
  )
}



export default withParam(FixedRateDetail)