import { Button, Grid, Modal, Switch, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { product_ProductTypeByID } from '../api/Api'
import { withParam } from '../utils/Router.Helper'

const ProductTypeByID = (props:any) => {

    const ProductId = props.params.productTypeCode

    const { data,refetch } = useQuery(["ProductGetByID",ProductId],()=>product_ProductTypeByID(ProductId),{onSuccess: (data) => {setActive(data && data.data.productType[0].isActive)}})

    const [open, setOpen] = React.useState(false);
    const [Active, setActive] = React.useState(false);
    const UpdateModelOpen = (id:any) => setOpen(true)
    const handleClose = () => setOpen(false)

    const onSubmit = (data:any) => {
        axios.put("product/updateProductType",{...data,productTypeCode:ProductId,isActive:Active})
        .then(()=>toast.success("Product Type Update Successfully"))
        .then(()=>{
          handleClose();
          refetch();
        })
        .catch((err:any)=>{
          toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title);
          console.log("Err-",err.response.data)
        }
        )
        
    }
    const { register, handleSubmit, formState: { errors }} = useForm({ mode: "onChange" });
    const registerOptions: any =
    {
      gstValue: { required: "Enter GST Number" },
      tcsValue: { required: "Enter TCS Value" },
      stampDutyValue: { required: "Enter Stamp Duty Valuea"},
    }
    
  return (
    <>
    { data && (
        <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "5%" , textAlign:'left',marginLeft:"5%",marginRight:"5%" }}> 
            { data.data.productType.map((item:any)=>(
                <>
                    <Typography gutterBottom> Product Name : {item.productName} </Typography>
                    <Typography gutterBottom> GST : {item.gstValue}% </Typography>
                    <Typography gutterBottom> TCS : {item.tcsValue}% </Typography>
                    <Typography gutterBottom> Stamp Duty : {item.stampDutyValue}% </Typography>
                    <Typography gutterBottom> Product Description : {item.description} </Typography>
                    <Typography gutterBottom> Status : {item.isActive? "Active" : "In Active"} </Typography>
                    <Button variant="contained" onClick={UpdateModelOpen} sx={{marginTop:2}}>Update</Button>
                </>
            ))}
        </Box>
    ) }

<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Product Type
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Box component="form"  sx={{ '& .MuiTextField-root': { m: 1, width: '30ch' }, }} noValidate autoComplete="off" >
            <Grid container sx={{justifyContent:'center'}}>
            <form>
                    <TextField 
                         id={"outlined-basic"+props.data}
                        label="Product Name" 
                        variant="outlined"
                        defaultValue={data && data.data.productType[0].productName}
                        {...register("productName")} 
                    />
                    <TextField 
                        id={"outlined-basic"+props.data}
                        label="Description" 
                        variant="outlined"
                        defaultValue={data && data.data.productType[0].description}
                        {...register("description")} 
                    />
                    <TextField 
                        id={"outlined-basic"+props.data}
                        label="GST Value" 
                        variant="outlined"
                        defaultValue={data && data.data.productType[0].gstValue}
                        error={errors.gstValue?true:false}
                                helperText={
                                  errors.gstValue == undefined
                                  ? ""
                                  : errors.gstValue.message + ""
                                } 
                        {...register("gstValue", registerOptions.gstValue)} 
                    />
                    <TextField 
                        id={"outlined-basic"+props.data}
                        label="TCS Value" 
                        variant="outlined"
                        defaultValue={data && data.data.productType[0].tcsValue}
                        error={errors.tcsValue?true:false}
                                helperText={
                                  errors.tcsValue == undefined
                                  ? ""
                                  : errors.tcsValue.message + ""
                                } 
                        {...register("tcsValue", registerOptions.tcsValue)} 
                    />
                    <TextField 
                        id={"outlined-basic"+props.data}
                        label="Stamp Duty" 
                        variant="outlined"
                        defaultValue={data && data.data.productType[0].stampDutyValue}
                        error={errors.stampDutyValue?true:false}
                                helperText={
                                  errors.stampDutyValue == undefined
                                  ? ""
                                  : errors.stampDutyValue.message + ""
                                } 
                        {...register("stampDutyValue", registerOptions.stampDutyValue)} 
                    />
                     <Typography id="modal-modal-title" variant="h6" >
            Status
          </Typography>
                    <Switch
                        checked={Active}
                        onChange={()=>setActive(!Active)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography id="modal-modal-title" variant="h6" >
                    <Button variant="contained" type='submit' onClick={handleSubmit(onSubmit)}>Submit</Button>
                    </Typography>
                </form>

            </Grid>
            </Box>
          </Typography>
        </Box>
      </Modal>

    </>
  )
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius:"10px",
  maxHeight: "80%",
  overflowY: "auto",
  };

export default withParam(ProductTypeByID)