import { Autocomplete, Box, Button, CircularProgress, Divider, Grid, IconButton, Modal, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { SAVE_AMC } from '../../utils/API_Names';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';


function ProductCode(name: string, calories: number, fat: number, carbs: number, protein: number) {
	return { name, calories, fat, carbs, protein };
  }

const AddAmc = () => {
	
	const [unlistedModelOpen, setUnlistedModelOpen] = useState(false);
	const [showCircularProgress, setshowCircularProgress] = useState(false);
	const [loanType, setLoanType] = useState('');

	function unlistedModel(status:any){
		return status==="open"?
		  setUnlistedModelOpen(true)
		  : status==="close" ? setUnlistedModelOpen(false) :  null
		}

		const { 
			register, 
			handleSubmit,
			control,
			setValue,
			reset,
			getValues,
			watch,
			formState: { errors } } = useForm({ mode: "onChange" });

			const registerOptions = {
				amcName : { required: "Please Enter An AMC Name",
				pattern: {
					value : /^[a-zA-Z0-9\s]+$/,
					message : "Invalid AMC Name"
				  }
				},
				amcSebiNo: {
					required: "Please Enter Sebi Number",
					pattern: {
					  value: /^[a-zA-Z0-9.,&()@_+\-]+$/,
					  message: "Invalid Sebi Number. Alphanumeric and special characters ., & () @ -_ + are allowed."
					}
				  }
				  ,
				amcWebsite:{ required: "Please Enter AMC Website" },
				amcAddress : { required: "Please Enter AMC Address" },
				amcCode : { required: "Please Enter AMC Code" },
				amcContactNumber : { required: "Please Enter AMC Contact Number" },
				amcContactPerson : { required: "Please Enter AMC Contact Person" },
				amcDescription : { required: "Please Enter AMC Description" },
				amcEmailId : { required: "Please Enter AMC  Email Id" },
				productCode : { required: "Please Enter AMC productCode" },
			}
			const [companyLogo, setCompanyLogo] = useState<any>();

			const onsubmit = (data:any) => {
				setshowCircularProgress(true);
				const formData = new FormData();
				formData.append("amcLogo",companyLogo);
				formData.append("AmcDTO",JSON.stringify(data))
				axios.post(SAVE_AMC,formData).then((res:any)=>{
					setshowCircularProgress(false);
					unlistedModel("close")
					toast.success("AMC added Successfully!");
				}).catch((err)=>{
					setshowCircularProgress(false);
					toast.error( err?.response?.data?.status?.message?.title
						? err?.response?.data?.status?.message?.title
						: err?.response?.data?.title)
				})
				console.log("data",data)
			}
			const ProductCode = [
				{ code: 'AIF' },
				{ code: 'PMS' },
				{ code: 'INSURANCE' },
				{ code: 'LOAN' },
			  ];
			  


  return (
	<>
		<Button variant='contained' onClick={() => unlistedModel("open")}> <AddIcon/> Add AMC </Button>

		<Modal
            keepMounted
            open={unlistedModelOpen}
            onClose={() => unlistedModel("close")}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={style}>
			<IconButton
      style={{ position: 'absolute',fontSize:"18px" ,top: "8px", right: "5px"}}
      onClick={() => unlistedModel("close")}
    >
      <CloseIcon style={{fontSize:"18px"}} />
    </IconButton>
				<Typography variant='h4' textAlign={"center"} gutterBottom> Add AMC </Typography>
				<Divider/>
				<Grid container rowSpacing={2} columnSpacing={2}>
						<Grid item xs={12} sm={9} md={6} lg={3}>
					<form onSubmit={handleSubmit(onsubmit)}>
						<TextField
		                    id="outlined-basic"
		                    label="AMC Name"
		                    variant="outlined"
		                    error={errors.amcName?true:false}
		                    helperText={ !errors.amcName ? "" : errors.amcName.message + "" }
		                    fullWidth
		                      {...register("amcName",registerOptions.amcName)}
		                    />
					</form>
						</Grid>
						<Grid item xs={12} sm={9} md={6} lg={3}>
					<form onSubmit={handleSubmit(onsubmit)}>
						<TextField
		                    id="outlined-basic"
		                    label="Sebi Registration No"
		                    variant="outlined"
		                    error={errors.amcSebiNo?true:false}
		                    helperText={ !errors.amcSebiNo ? "" : errors.amcSebiNo.message + "" }
		                    fullWidth
		                      {...register("amcSebiNo",registerOptions.amcSebiNo)}
		                    />
						</form>
						</Grid>
						<Grid item xs={12} sm={9} md={6} lg={3}>
					<form onSubmit={handleSubmit(onsubmit)}>
						<TextField
		                    id="outlined-basic"
		                    label="AMC Website"
		                    variant="outlined"
		                    error={errors.amcWebsite?true:false}
		                    helperText={ !errors.amcWebsite ? "" : errors.amcWebsite.message + "" }
		                    fullWidth
		                      {...register("amcWebsite",registerOptions.amcWebsite)}
		                    />
					</form>
						</Grid>
						<Grid item xs={12} sm={9} md={6} lg={3}>
					<form onSubmit={handleSubmit(onsubmit)}>
						<TextField
		                    id="outlined-basic"
		                    label="AMC Address"
		                    variant="outlined"
		                    error={errors.amcAddress?true:false}
		                    helperText={ !errors.amcAddress ? "" : errors.amcAddress.message + "" }
		                    fullWidth
		                      {...register("amcAddress",registerOptions.amcAddress)}
		                    />
					</form>
						</Grid>
						{/* <Grid item xs={12} sm={12} md={4} lg={3}>
					<form onSubmit={handleSubmit(onsubmit)}>
						<TextField
		                    id="outlined-basic"
		                    label="AMC Code"
		                    variant="outlined"
		                    error={errors.amcCode?true:false}
		                    helperText={ !errors.amcCode ? "" : errors.amcCode.message + "" }
		                    fullWidth
		                      {...register("amcCode",registerOptions.amcCode)}
		                    />
					</form>
						</Grid> */}
						<Grid item xs={12} sm={9} md={6} lg={3}>
					<form onSubmit={handleSubmit(onsubmit)}>
						<TextField
		                    id="outlined-basic"
		                    label="AMC Contact No"
		                    variant="outlined"
		                    error={errors.amcContactNumber?true:false}
		                    helperText={ !errors.amcContactNumber ? "" : errors.amcContactNumber.message + "" }
		                    fullWidth
		                      {...register("amcContactNumber",registerOptions.amcContactNumber)}
		                    />
					</form>
						</Grid>
						<Grid item xs={12} sm={9} md={6} lg={3}>
					<form onSubmit={handleSubmit(onsubmit)}>
						<TextField
		                    id="outlined-basic"
		                    label="AMC Contact Person"
		                    variant="outlined"
		                    error={errors.amcContactPerson?true:false}
		                    helperText={ !errors.amcContactPerson ? "" : errors.amcContactPerson.message + "" }
		                    fullWidth
		                      {...register("amcContactPerson",registerOptions.amcContactPerson)}
		                    />
					</form>
						</Grid>
						{/* <Grid item xs={12} sm={9} md={6} lg={3}>
					<form onSubmit={handleSubmit(onsubmit)}>
						<TextField
		                    id="outlined-basic"
		                    label="AMC Description"
		                    variant="outlined"
		                    error={errors.amcDescription?true:false}
		                    helperText={ !errors.amcDescription ? "" : errors.amcDescription.message + "" }
		                    fullWidth
		                      {...register("amcDescription",registerOptions.amcDescription)}
		                    />
					</form>
						</Grid> */}
						<Grid item xs={12} sm={9} md={6} lg={3}>
					<form onSubmit={handleSubmit(onsubmit)}>
						<TextField
		                    id="outlined-basic"
		                    label="AMC Email Id"
							type='Email'
		                    variant="outlined"
		                    error={errors.amcEmailId?true:false}
		                    helperText={ !errors.amcEmailId ? "" : errors.amcEmailId.message + "" }
		                    fullWidth
		                      {...register("amcEmailId",registerOptions.amcEmailId)}
		                    />
					</form>
						</Grid>
						{/* <Grid item xs={12} sm={12} md={4} lg={3}>
					<form onSubmit={handleSubmit(onsubmit)}>
					<TextField
		                    id="outlined-basic"
		                    label="Product Code"
		                    variant="outlined"
		                    error={errors.productCode?true:false}
		                    helperText={ !errors.productCode ? "" : errors.productCode.message + "" }
		                    fullWidth
		                      {...register("productCode",registerOptions.productCode)}
		                    />
					</form>
					</Grid> */}
					 <Grid item xs={12} sm={9} md={6} lg={3}>
          <form onSubmit={handleSubmit(onsubmit)}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={ProductCode}
              getOptionLabel={(option:any) => option.code}
              fullWidth
              onChange={(event: any, newValue: any) => {
                console.log("Selected Loan Type:", newValue?.segment);
                setLoanType(newValue?.code);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Product Code"
                  fullWidth
                  {...register("productCode",registerOptions.productCode)}
                />
              )}
            />
          </form>
                            </Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
					<Box>
					<Button variant="contained" component="label">
						Upload Company Logo 
						<input
						hidden
						type="file"
						accept=".jpg,.jpeg,.png"
						name="companyLogo"
						onChange={(event: any) =>
							setCompanyLogo(event.target.files[0])
						}
						/>
					</Button>
					<Typography variant="caption" sx={{ marginLeft: "0.5rem" }}>
						{companyLogo == undefined
						? "No file choosen"
						: companyLogo.name}
					</Typography>
					</Box>
					</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} textAlign={"center"}>
					<form onSubmit={handleSubmit(onsubmit)}>
					<Button type='submit' variant='contained' disabled={showCircularProgress}> 
					{showCircularProgress === true ? (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      thickness={5}
                    />
                  ) : (
                    ""
                  )}{"  "}
					Submit</Button>
					</form>
					</Grid>
				</Grid>
			</Box>
			</Modal>
	</>
  )
}

const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    "& .MuiTextField-root": { m: 1 },
  };

export default AddAmc