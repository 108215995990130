import React from 'react'
import { Link } from 'react-router-dom'
import mostseenblog from "../images/mostseenblog.png"
import "../css/blogs1.css"
import { Container } from '@mui/material'

const Blog1 = () => {
  return (
  <Container maxWidth="lg">
  <section className="blog-single">
      <div className="container cont1">
          <div className="row">
              <div className="col-md-10 m-auto single-page-col-main">
          
                  <article className="single-post">
                  
                      <div className="post-title text-center">
                          <h1 className='blog-single-main-education-h1'>NeoFinDesk:</h1>
                          <p className='blog-single-main-education-p'>We help Indian families build better financial futures to plan and fund their children’s education.</p>
                          <ul className="list-inline post-tag single-blog-date">
                            <li className="list-inline-item">
                                  <span>August 8, 2021</span>
                              </li>
                          </ul>
                      </div>
                  
                      <div className="post-body">
                       <div className="feature-image" style={{textAlign:'center'}}>
                          <img src={mostseenblog} alt="" style={{width:"43%"}} />
                          </div>
                          <div className='blog-page-desp-p-tag'>
                      <p >Education is the best gift that any parent can give to their children. Whether belonging to a lower or middle-class background, each parent's most cherished dream is to be able to provide their child with top-quality education, thereby securing their child's future. One can still manage basic education without much hassle but when it comes to enrolling their child in degree courses such as engineering, medical, or management, it becomes difficult for parents to cope with the rising cost of the course fees and boarding expenditure along with miscellaneous expenses. And more so, it turns out to be backbreaking for parents to shell out such an amount of money if their child wants to pursue his/her studies abroad. But no parent would like to compromise on their child's dream education and consequently the career for the lack of adequate funds. Children's education needs strategic planning from parents to avoid any shortage of money to fund their higher education. Here comes the role of NeoFinDesk, a platform that aims to cater to all the requirements one needs for pursuing graduation/post-graduation in India or even abroad. NeoFinDesk offers parents well-structured, tailor-made, personalized investment plans according to their income and need, to save for their children's education when they grow up. It will help you to estimate the cost of the courses you are looking for (tuition fee, books, etc), the living cost (accommodation, food, etc), and even the traveling expenses and insurance required in case of studying abroad, through our specialized technology which calculates all the expenses with the help of a special algorithm that runs on the data collected from all over the world by our research team.</p>
                                     <br />
                                     <p>NeoFinDesk's vision is not just limited to providing funding solutions to our users but making the whole process of career choice, and decision-making easier and accessible to all groups of people whether in tier 1, tier 2, or 3 cities of our country with the help of our expertise through a well learned and research-oriented team of professionals. Unlike any other fintech company, the USP of NeoFinDesk is it is first of its kind platform which is solely dedicated to solving all the problems and providing the solution in the best possible way related to education and higher studies. It has a unique blend of global information about higher education and education savings financial products, helping to attain your child's educational goals in the most easiest and uncomplicated way.</p>
                                       <br />
                                           </div>
                      </div>
                  </article>
              
              
              </div>
          </div>
  
  <div className='wrapper-next-butoon-blogs-span-main'>
      <div className="wrapper-next-butoon-blogs">
    <a className="cta-privious" href="">
      {/* <span className='wrapper-next-butoon-blogs-span'>NEXT</span> */}
      {/* <Link to={"/BlogSingleBudget"}><span className='wrapper-next-butoon-blogs-span'>PREVIOUS</span> </Link>
      <span className='wrapper-next-butoon-blogs-span'>
        <svg style={{transform: "rotate(180deg)"}} width="px" height="43px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path className="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
            <path className="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
            <path className="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
          </g>
        </svg>
      </span>  */}
    </a>
  </div>
      
  <div className='wrapper-next-butoon-blogs-home-main1'>
  {/* <Link to={"/Blogs"}><span className='wrapper-next-butoon-blogs2-span'><AiOutlineHome className='wrapper-next-butoon-blogs2-home-icone' style={{width:"40px",color:"#0d6efd"}} title="HOME"></AiOutlineHome></span> </Link> */}
  </div>
  <div className="wrapper-next-butoon-blogs">
    <div className="cta">
      {/* <Link to={"/BlogSingle2"}><span className='wrapper-next-butoon-blogs-span'>NEXT</span> </Link>
      <span className='wrapper-next-butoon-blogs-span'>
        <svg width="px" height="43px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path className="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
            <path className="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
            <path className="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
          </g>
        </svg>
      </span>  */}
    </div>
  </div>
  </div>
      </div>
  </section>
    </Container>
  )
}

export default Blog1