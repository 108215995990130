import { Container, Grid, Box } from "@mui/material";
import React from "react";
import "../css/whyus.css";
import Approach from "../images/ourapproach_01.png"
import whyUs from "../images/whyus.png"

const WhyUs = () => {
  return (
    <>
    <Box sx={{backgroundColor:'#00538a'}}>
      {/* <!-- OurServices ------ Our Approach --> */}
    <section className="iv-ourService-banner">
      <Container maxWidth="lg" id="approachTab">
        <Grid container sx={{color:'#fff'}}>
          <Grid item lg={6} md={6} className="col-lg-6 align-self-center text-white">
            <span className="iv-ourService-head">
              <h2>Our Approach</h2>
            </span>
            <p className="iv-ourService-desc">

              Our product and service offerings are built to harness technology, research, analytics and data science.
              We aim to provide our IT and digital expertise to clients for their business's success.
            </p>
            <p className="iv-ourService-desc">
              Our products are built to solve sustainability issues and challenges providing the required scale and
              speed in achieving success in client wealth generation and wealth protection.
            </p>
          </Grid>
          <Grid item md={5} lg={5} className="col-lg-5 mx-auto justify-content-center d-flex align-items-center mob-offerings"
            sx={{marginRight: "0px !important", marginLeft:'2rem'}}>
            <img className="iv-ourService-img-Apprch fade-in-right" src={Approach} alt="Our Approach"/>
          </Grid>
        </Grid>
      </Container>
    </section>

    {/* <!--  OurServices ----- Why Us --> */}
    <section className="iv-ourService-banner" style={{paddingBottom: "50px"}}>
      <Container maxWidth="lg" id="whyusTab">
        <Grid container sx={{color:'#fff'}}>
          <Grid item md={5} lg={5} className="col-lg-5 mx-auto justify-content-center d-flex align-items-center"
            style={{marginLeft: "0px !important"}}>
            <img className="iv-ourService-img-Why fade-in-left" src={whyUs} alt="Why Us"/>
          </Grid>
          <Grid item md={6} lg={6} className="col-lg-6 align-self-center text-white">
            <span className="iv-ourService-head">
              <h2>Why Us</h2>
            </span>
            <p className="iv-ourService-desc">

              The adoption of technology to help non-institutional or high-net-worth advisors and clients, get access to
              complex products is an important shift that needs to take place.
            </p>
            <p className="iv-ourService-desc">
              A multi-issuer platform essentially connects the issuers and distributors, leading to a more efficient
              marketplace. Empowering the client-facing user, backed by a robust technology can potentially enable even
              generalists to offer investment advice along with the specialists.
            </p>
          </Grid>
        </Grid>
      </Container>
    </section>
    </Box>
    </>
  );
};

export default WhyUs;
