/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";

const BusinessPartnerForEmployee = (props: any) => {
  const { title } = props;
  const { Count } = props;
  const { monthlyCount } = props;
  const { quartlycount } = props;
  const { yearlyCount } = props;
  const { prevQuarterValue } = props;
  const { prevQuarterStartDate } = props;
  const { prevQuarterEndDate } = props;
  const { ytdCount } = props;
  const { BarData } = props;
  const { yearly } = props;
  const { quarterly } = props;
  const { monthly } = props;
  const { currentDate } = props;

  return (
    <Paper elevation={6} sx={{ pb: 0 }}>
      <Card sx={{ pb: 0 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ background: "#b4c6e7" }}
                colSpan={5}
                align="center"
              >
                {title} ({Count})
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Month <br />
                <b style={{ fontSize: "10px" }}>({monthly})</b>
              </TableCell>
              <TableCell
                style={{ fontSize: "12px", fontWeight: "700", padding: "0" }}
                align="center"
              >
                Quarter <br />
                <b style={{ fontSize: "10px" }}>({quarterly})</b>
              </TableCell>
              <TableCell
                style={{ fontSize: "12px", fontWeight: "700", padding: "0" }}
                align="center"
              >
                {" "}
                Prev. Quarter <br />
                <b style={{ fontSize: "10px" }}>
                  ({prevQuarterStartDate}-{prevQuarterEndDate})
                </b>
              </TableCell>
              <TableCell
                style={{ fontSize: "12px", fontWeight: "700" }}
                align="center"
              >
                YTD <br />
                <b style={{ fontSize: "10px" }}>({currentDate})</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                style={{ fontSize: "10px", textAlign: "center" }}
                component="th"
                scope="row"
              >
                <b>{monthlyCount}</b>
              </TableCell>
              <TableCell style={{ fontSize: "10px" }} align="center">
                <b>{quartlycount}</b>
              </TableCell>
              <TableCell style={{ fontSize: "10px" }} align="center">
                <b>{prevQuarterValue}</b>
              </TableCell>
              <TableCell style={{ fontSize: "10px" }} align="center">
                <b>{ytdCount}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </Paper>
  );
};

export default BusinessPartnerForEmployee;
