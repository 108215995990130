import React from "react";
import { withParam } from "../../utils/Router.Helper";
import { useQuery } from "react-query";
import { BookACallGetById, product_listOfAllProductType } from "../../api/Api";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, Resolver } from "react-hook-form";
import axios from "axios";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import { Label } from "@mui/icons-material";
import {
  ExistingTypeEnum,
  MeetingTypeEnum,
  statusTypeEnum,
} from "../../Constants/BookACallEnum";

const BookACallGetByID = (props: any) => {
  const Id = props.params.ID;
  const textFieldSize = "small";

  const [edit, setedit] = React.useState(true);
  const [productName, setProductName] = React.useState<any>();
  const [employeeData, setEmployeeData] = React.useState<any>([]);
  const [companyList, setcompanyList] = React.useState<any>([]);
  const [toDate, setToDate] = React.useState<any>(dayjs(new Date().toJSON()));
  const [fromDate, setFromDate] = React.useState<any>(
    dayjs(new Date().toJSON())
  );
  const [meetingTime, setMeetingTime] = React.useState<any>(
    dayjs(new Date().toJSON())
  );
  const [orderDate, seOrderDate] = React.useState(new Date());

  const { data, refetch } = useQuery(
    ["BookACallByid", Id],
    () => BookACallGetById(Id),
    {
      onSuccess(data) {
        seOrderDate(data.data.bookACallList[0].availabilityDate);
        setToDate(data.data.bookACallList[0].availabilityToTime);
        setFromDate(data.data.bookACallList[0].availabilityFromTime);
        setMeetingTime(data.data.bookACallList[0].meetingTime);
      },
      staleTime: Infinity,
    }
  );

  const { data: productData } = useQuery(
    "ProductData",
    product_listOfAllProductType,
    {
      staleTime: Infinity,
    }
  );

  const { register, handleSubmit, formState, setValue, getValues, watch } =
    useForm();

  const registerOptions = {
    address: {
      required: "Address is required",
      pattern: {
        value: /[a-zA-Z0-9//s]*/,
        message: "Invalid Script name",
      },
    },
    isinNumber: {
      required: "ISIN is required",
      pattern: {
        value: /^[A-Za-z0-9]+$/,
        message: "Invalid ISIN number",
      },
    },
    faceValue: {
      validate: {
        positive: (v: any) => parseInt(v) > 0 || "should be greater than 0",
      },
      required: "Face value is required",
      valueAsNumber: true,
    },
  };

  React.useEffect(() => {
    axios
      .get(
        "company/listOfManufactureNDistributorCompanies/companyTypeCode?companyTypeCode=E"
      )
      .then((res: any) => setcompanyList(res.data.companies));

    const indexes =
      data &&
      data.data.bookACallList.reduce((acc: any, item: any) => {
        const index =
          productData &&
          productData.data.productType.findIndex(
            (element: any) => element.productTypeCode === item.productTypeCode
          );
        if (index !== -1) {
          acc.push(index);
        }
        return acc;
      }, []);
    setProductName(indexes?.toString());
  }, [data, productData]);

  React.useEffect(() => {
    companyList.length !== 0 &&
      axios
        .get(
          "employeeProfile/employeeByCompany?companyMasterCode=" +
            companyList[0]?.companyMasterCode
        )
        .then((res: any) => {
          setEmployeeData(res.data.employeeProfileDTOS);
        });
  }, [companyList]);

  const indexOfEmployee: any =
    data &&
    (employeeData &&
      employeeData.indexOf(
        employeeData.filter(
          (item: any) =>
            item.employeeCode ===
            data.data?.bookACallList[0]?.mappedEmployeeCode
        )[0]
      )) === -1
      ? -1
      : employeeData &&
        employeeData.indexOf(
          employeeData.filter(
            (item: any) =>
              item.employeeCode ===
              data.data?.bookACallList[0]?.mappedEmployeeCode
          )[0]
        );

  const indexOfStatus: any =
    data &&
    statusTypeEnum.indexOf(
      statusTypeEnum.filter(
        (ite) => ite.value === data.data.bookACallList[0].status
      )[0]
    );
  const empData = employeeData && employeeData[indexOfEmployee]?.employeeCode;

  const onSubmit = (bookACallData: any) => {
    const sentData = {
      ...bookACallData,
      bookCallDetailsId: Id,
      availabilityDate: orderDate,
      brokerMasterCode: data.data.bookACallList[0].brokerMasterCode,
      productTypeCode: !!getValues("productTypeCode")
        ? getValues("productTypeCode")
        : data.data.bookACallList[0].productTypeCode,
      mappedEmployeeCode: !!bookACallData.mappedEmployeeCode
        ? bookACallData.mappedEmployeeCode
        : empData,
      availabilityFromTime: fromDate,
      availabilityToTime: toDate,
      meetingTime: meetingTime,
    };
    axios
      .put("bookACall/updateBookACall", sentData)
      .then((res: any) => toast.success("Update successfully!"))
      .then(() => {
        window.location.reload();
        window.scrollTo(0, 0);
      })
      .catch((err: any) =>
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        )
      );
  };

  const handleCancelClick = () => {
    setedit(true);
    refetch();
  };

  return (
    <>
      <Box sx={{ paddingTop: 3 }}></Box>
      <Typography variant="h4" gutterBottom pb={2}>
        {" "}
        Book A Call Details
      </Typography>
      {data && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                size={textFieldSize}
                fullWidth
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                className={edit ? "textDisable" : ""}
                defaultValue={
                  data && data.data.bookACallList[0].contactPersonFullName
                }
                InputProps={{ readOnly: edit }}
                {...register("contactPersonFullName")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                size={textFieldSize}
                fullWidth
                id="outlined-basic"
                label="Email Id"
                variant="outlined"
                className={edit ? "textDisable" : ""}
                defaultValue={data && data.data.bookACallList[0].emailId}
                InputProps={{ readOnly: edit }}
                {...register("emailId")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                size={textFieldSize}
                fullWidth
                id="outlined-basic"
                label="Address"
                variant="outlined"
                className={edit ? "textDisable" : ""}
                defaultValue={data && data.data.bookACallList[0].address}
                InputProps={{ readOnly: edit }}
                {...register("address")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                size={textFieldSize}
                fullWidth
                id="outlined-basic"
                label="Mobile No"
                variant="outlined"
                className={edit ? "textDisable" : ""}
                defaultValue={data && data.data.bookACallList[0].mobileNo}
                InputProps={{ readOnly: edit }}
                {...register("mobileNo")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                size={textFieldSize}
                fullWidth
                id="outlined-basic"
                label="Company Name"
                variant="outlined"
                className={edit ? "textDisable" : ""}
                defaultValue={data && data.data.bookACallList[0].companyName}
                InputProps={{ readOnly: edit }}
                {...register("companyName")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="Available Date"
                  inputFormat="DD/MM/YYYY"
                  value={orderDate}
                  disablePast
                  onChange={(newValue: any) => {
                    seOrderDate(newValue?.toJSON());
                    setValue("availabilityDate", newValue?.toJSON());
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      className={edit ? "textDisable" : ""}
                      size={textFieldSize}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                  label={"From"}
                  openTo="minutes"
                  value={fromDate}
                  onChange={(newValue: any) => {
                    console.log(newValue);
                    setFromDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size={textFieldSize}
                      className={edit ? "textDisable" : ""}
                      {...register("availabilityFromTime")}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                  label={"To"}
                  openTo="minutes"
                  value={toDate}
                  onChange={(newValue: any) => {
                    setToDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      className={edit ? "textDisable" : ""}
                      size={textFieldSize}
                      {...register("availabilityToTime")}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size={textFieldSize}
                options={ExistingTypeEnum}
                defaultValue={
                  data &&
                  ExistingTypeEnum.find(
                    (item) =>
                      data.data.bookACallList[0].isExisting.toString() ===
                      item.value
                  )
                }
                className={edit ? "textDisable" : ""}
                getOptionLabel={(options: any) => options.Label}
                onChange={(options: any, newValue) => {
                  setValue("isExisting", newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Is Existing"
                    className={edit ? "textDisable" : ""}
                  />
                )}
              />
            </Grid>
            {employeeData && indexOfEmployee > -1 && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  id="Select Employee"
                  options={employeeData.map((option: any) => option)}
                  defaultValue={employeeData[indexOfEmployee]}
                  getOptionLabel={(options: any) =>
                    options?.employeeCode +
                    "- " +
                    options?.firstName +
                    " " +
                    options?.lastName
                  }
                  size={textFieldSize}
                  onChange={(options: any, newValue) => {
                    setValue("mappedEmployeeCode", newValue?.employeeCode);
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={edit ? "textDisable" : ""}
                      label="Select Employee"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                size={textFieldSize}
                fullWidth
                id="outlined-basic"
                label="Meeting Link"
                variant="outlined"
                className={edit ? "textDisable" : ""}
                defaultValue={data && data.data.bookACallList[0].meetingLink}
                InputProps={{ readOnly: edit }}
                {...register("meetingLink")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                  label={"Meeting Time"}
                  openTo="minutes"
                  value={meetingTime}
                  onChange={(newValue: any) => {
                    setMeetingTime(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size={textFieldSize}
                      className={edit ? "textDisable" : ""}
                      defaultValue={
                        data && data.data.bookACallList[0].meetingTime
                      }
                      {...register("meetingTime")}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size={textFieldSize}
                options={MeetingTypeEnum}
                defaultValue={
                  data &&
                  MeetingTypeEnum.find(
                    (item) =>
                      data.data.bookACallList[0].meetingType === item.value
                  )
                }
                className={edit ? "textDisable" : ""}
                getOptionLabel={(options: any) => options.Label}
                onChange={(options: any, newValue) => {
                  setValue("meetingType", newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Meeting Type"
                    className={edit ? "textDisable" : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {productName && (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size={textFieldSize}
                  className={edit ? "textDisable" : ""}
                  options={
                    productData &&
                    productData.data.productType.map((option: any) => option)
                  }
                  getOptionLabel={(options: any) => options.productName}
                  defaultValue={
                    productData && productData.data.productType[productName]
                  }
                  onChange={(options: any, newValue) => {
                    setValue("productTypeCode", newValue?.productTypeCode);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product"
                      className={edit ? "textDisable" : ""}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                size={textFieldSize}
                fullWidth
                id="outlined-basic"
                label="Remarks"
                variant="outlined"
                className={edit ? "textDisable" : ""}
                defaultValue={data && data.data.bookACallList[0].remarks}
                InputProps={{ readOnly: edit }}
                {...register("remarks")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {productData && indexOfStatus > -1 && (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size={textFieldSize}
                  options={statusTypeEnum}
                  className={edit ? "textDisable" : ""}
                  getOptionLabel={(options: any) => options.Label}
                  defaultValue={productData && statusTypeEnum[indexOfStatus]}
                  onChange={(options: any, newValue) => {
                    setValue("status", newValue?.value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      className={edit ? "textDisable" : ""}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={12}>
              {edit && (
                <Button
                  sx={{ margin: "10px 0" }}
                  variant="contained"
                  //  type="submit"
                  onClick={() => setedit(false)}
                >
                  Edit Details
                </Button>
              )}
              {!edit && (
                <>
                  <Stack
                    spacing={2}
                    sx={{ justifyContent: "center", margin: "10px 0" }}
                    direction="row"
                  >
                    <Button variant="contained" type="submit">
                      Save Details
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default withParam(BookACallGetByID);
