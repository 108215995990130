/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unreachable */
/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import { Button, TextField } from "@mui/material";
import { convertDate } from "../../utils/Helper";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ManufactureUnlistedEditModal from "./ManufactureUnlistedEditModal";
import dayjs from "dayjs";

const inputStyle = {
  width: "75px",
  padding: "0.5rem 0.5rem",
  borderRadius: "4px",
  border: "1px solid darkgray",
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// const headCells = [
//   {
//     id: "productMasterCode",
//     numeric: false,
//     label: "Code",
//   },
//   {
//     id: "logo",
//     numeric: false,
//     disablePadding: true,
//     label: "Logo",
//   },
//   {
//     id: "scriptName",
//     numeric: false,
//     disablePadding: false,
//     label: "Script Name",
//   },
//   {
//     id: "createdAt",
//     numeric: false,
//     disablePadding: false,
//     label: "Create At",
//   },
//   {
//     id: "validTillDate",
//     numeric: false,
//     disablePadding: false,
//     label: "Current Valid Date",
//   },
//   {
//     id: "quantity",
//     numeric: false,
//     disablePadding: false,
//     label: "Current Quantity",
//   },
//   {
//     id: "manufactureBuyRate",
//     numeric: false,
//     disablePadding: false,
//     label: "Current Cost Price",
//   },
//   {
//     id: "manufactureCarryingCost",
//     numeric: false,
//     disablePadding: false,
//     label: "Current Carrying Price",
//   },
//   {
//     id: "manufactureSaleRate",
//     numeric: false,
//     disablePadding: false,
//     // label: 'Sell Rate',
//     label: "Current Landing Price",
//   },
//   {
//     id: "isActive",
//     numeric: false,
//     disablePadding: false,
//     label: "Status",
//   },
//   {
//     id: "action",
//     numeric: false,
//     disablePadding: false,
//     label: "Action",
//   },
// ];

const getHeadCells = (isEditing) => {
  const defaultCells = [
    {
      id: "productMasterCode",
      numeric: false,
      // disablePadding: true,
      label: "Code",
    },
    {
      id: "logo",
      numeric: false,
      disablePadding: true,
      label: "Logo",
    },
    {
      id: "scriptName",
      numeric: false,
      disablePadding: false,
      label: "Script Name",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Create At",
    },
    {
      id: "validTillDate",
      numeric: false,
      disablePadding: false,
      label: "Current Valid Date",
    },
    {
      id: "quantity",
      numeric: false,
      disablePadding: false,
      label: "Current Quantity",
    },
    {
      id: "manufactureBuyRate",
      numeric: false,
      disablePadding: false,
      label: "Current Cost Price",
    },
    {
      id: "manufactureCarryingCost",
      numeric: false,
      disablePadding: false,
      label: "Current Carrying Price",
    },
    {
      id: "manufactureSaleRate",
      numeric: false,
      disablePadding: false,
      label: "Current Landing Price",
    },
    {
      id: "newValidTillDate",
      numeric: false,
      label: "New Valid Date",
    },
    {
      id: "newQuantity",
      numeric: false,
      label: "New Quantity",
    },
    {
      id: "newManufactureBuyRate",
      numeric: false,
      label: "New Cost Price",
    },
    {
      id: "newManufacturerCarryingCost",
      numeric: false,
      label: "New Carrying Price",
    },
    {
      id: "newManufactureSaleRate",
      numeric: false,
      label: "New Landing Price",
    },
    // {
    //   id: "isActive",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Status",
    // },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Action",
    },
  ];
  return defaultCells;
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isEditing,
  } = props;

  const headCells = getHeadCells(isEditing);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ManufactureUnlistedSortTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("scriptName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  // to edit the data
  const [editMode, setEditMode] = React.useState({});
  const [editedRows, setEditedRows] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const today = new Date().toISOString().split("T")[0];
  const [editedFields, setEditedFields] = React.useState({
    // render todays date as default in format YYYY-MM-DD
    newValidTillDate: today,
    newQuantity: "",
    newManufactureBuyRate: "",
    newManufacturerCarryingCost: "",
    newManufactureSaleRate: "",
    scriptName: "",
  });

  // const handleFieldChange = (field, value) => {
  //   setEditedFields((prev) => ({
  //     ...prev,
  //     [field]: value,
  //   }));
  // };

  const handleFieldChange = (e, productMasterCode, field) => {
    const value = e.target.value;
    setEditedRows((prev) => ({
      ...prev,
      [productMasterCode]: {
        ...prev[productMasterCode],
        [field]: value,
      },
    }));
  };

  // const handleEditClick = (row) => {
  //   setSelectedRow(row);
  //   setEditedFields({
  //     newValidTillDate: today,
  //     newQuantity: "",
  //     newManufactureBuyRate: "",
  //     newManufacturerCarryingCost: "",
  //     newManufactureSaleRate: "",
  //     productMasterCode: row.productMasterCode,
  //     scriptName: row.scriptName,
  //   });
  //   setOpenModal(true);
  // };

  const handleSave = async (productMasterCode) => {
    const updateRows = editedRows[productMasterCode];
    const originalRow = rows.find(
      (row) => row.productMasterCode === productMasterCode
    );
    // Format the validTillDate to YYYY-MM-DD format
    const formattedValidTillDate = dayjs(originalRow?.validTillDate).format(
      "YYYY-MM-DD"
    );
    const newFormattedValidTillDate = dayjs(
      updateRows?.newValidTillDate
    ).format("YYYY-MM-DD");
    // Create the payload for the updated row by merging the original and updated values
    const updatedRowPayload = {
      quantity: originalRow?.quantity,
      manufactureBuyRate: originalRow?.manufactureBuyRate,
      manufactureSaleRate: originalRow?.manufactureSaleRate,
      manufacturerCarryingCost: originalRow?.manufacturerCarryingCost,
      description: "",
      companyName: originalRow?.companyName || "Invest Value Fintech Pvt Ltd",
      scriptName: originalRow?.scriptName,
      productTypeCode: 11,
      productName: originalRow?.scriptName,
      unlistedMasterCode: originalRow?.unlistedMasterCode,
      companyMasterCode: originalRow?.companyMasterCode,
      validTillDate: formattedValidTillDate,
      // adding new fields to the payload
      newQuantity: Number(updateRows?.newQuantity) || originalRow?.quantity,
      newManufactureBuyRate:
        Number(updateRows?.newManufactureBuyRate) ||
        originalRow?.manufactureBuyRate,
      newManufactureSaleRate:
        updateRows?.newManufactureSaleRate?.toLowerCase() === "cnc"
          ? 0
          : Number(updateRows?.newManufactureSaleRate) ||
            originalRow?.manufactureSaleRate,
      newManufacturerCarryingCost:
        Number(updateRows?.newManufacturerCarryingCost) ||
        originalRow?.manufacturerCarryingCost,
      newValidTillDate: newFormattedValidTillDate,
      productMasterCode: originalRow?.productMasterCode,
    };
    try {
      const response = await axios.post(
        "unlisted/saveUnlisted",
        updatedRowPayload
      );
      if (response?.data?.status?.resultType === "SUCCESSFUL") {
        toast.success("Unlisted data updated successfully!!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Error updating unlisted data!!");
      }
      const updatedRows = rows.map((row) => {
        if (row.productMasterCode === productMasterCode) {
          return { ...row, ...updatedRows };
        }
        return row;
      });
      setRows(updatedRows);
      setEditMode((prev) => ({ ...prev, [productMasterCode]: false }));
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  React.useEffect(() => {
    function createData(
      productMasterCode,
      logo,
      scriptName,
      createdAt,
      validTillDate,
      quantity,
      manufactureSaleRate,
      isActive,
      manufactureBuyRate,
      unlistedMasterCode,
      companyName,
      companyMasterCode,
      manufacturerCarryingCost
    ) {
      return {
        productMasterCode,
        logo,
        scriptName,
        createdAt,
        validTillDate,
        quantity,
        manufactureSaleRate,
        isActive,
        manufactureBuyRate,
        unlistedMasterCode,
        companyName,
        companyMasterCode,
        manufacturerCarryingCost,
      };
    }

    const rowData =
      props &&
      props.data.map((item) =>
        createData(
          item.productMasterCode,
          item.scriptLogo,
          item.scriptName + "".trim(),
          item.createdAt + "".trim(),
          item.validTillDate,
          item.quantity + 0,
          item.manufactureSaleRate + 0,
          item.isActive,
          item.manufactureBuyRate + 0,
          item.unlistedMasterCode,
          item.companyName,
          item.companyMasterCode,
          item.manufacturerCarryingCost + 0
        )
      );
    setRows(rowData);
  }, [props]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.productMasterCode);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              // isEditing={Object.keys(editMode).length > 0}
              isEditing={Object.values(editMode).some((value) => value)}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.productMasterCode);
                const labelId = `enhanced-table-checkbox-${index}`;
                const isEditing = editMode[row.productMasterCode];
                return (
                  <TableRow
                    hover
                    // onClick={(event) =>
                    //   handleClick(event, row.productMasterCode)
                    // }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.productMasterCode}
                    selected={isItemSelected}
                    // sx={{ cursor: "pointer" }}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell> */}

                    <TableCell
                      // component="th"
                      id={labelId}
                      scope="row"
                      // padding="none"
                    >
                      {" "}
                      {row.productMasterCode}{" "}
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "16px 0" }}>
                      <img
                        style={{ height: "30px", width: "30px" }}
                        src={row.logo}
                        alt=""
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {" "}
                      <Link
                        to={
                          "/MenufactureOrderUnlisted/" + row.productMasterCode
                        }
                      >
                        {" "}
                        {row.scriptName === "null" ? "-" : row.scriptName}{" "}
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      {row.createdAt === "null"
                        ? "-"
                        : convertDate(row.createdAt)}
                    </TableCell>
                    <TableCell align="left">
                      {convertDate(row.validTillDate)}
                    </TableCell>
                    <TableCell align="left">{row.quantity || "-"}</TableCell>
                    {/* COST PRICE */}
                    <TableCell align="left">
                      {row.manufactureBuyRate || "-"}
                    </TableCell>
                    {/* CARRYING COST */}
                    <TableCell align="left">
                      {row.manufacturerCarryingCost || "-"}
                    </TableCell>
                    {/* LANDING PRICE */}
                    <TableCell align="left">
                      {row.manufactureSaleRate === 0
                        ? "CNC"
                        : row.manufactureSaleRate}
                    </TableCell>
                    {/* {isEditing ? (
                      <> */}
                    <TableCell align="left">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Valid Till Date"
                          inputFormat="DD/MM/YYYY"
                          disablePast
                          value={
                            editedRows[row.productMasterCode]?.newValidTillDate
                          }
                          onChange={(newValue) => {
                            handleFieldChange(
                              { target: { value: newValue } },
                              row.productMasterCode,
                              "newValidTillDate"
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              sx={{ width: "130px" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </TableCell>
                    <TableCell align="left">
                      <input
                        type="number"
                        style={inputStyle}
                        value={
                          editedRows[row.productMasterCode]?.newQuantity || ""
                        }
                        onChange={(e) =>
                          handleFieldChange(
                            e,
                            row.productMasterCode,
                            "newQuantity"
                          )
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <input
                        type="number"
                        style={inputStyle}
                        value={
                          editedRows[row.productMasterCode]
                            ?.newManufactureBuyRate || ""
                        }
                        onChange={(e) =>
                          handleFieldChange(
                            e,
                            row.productMasterCode,
                            "newManufactureBuyRate"
                          )
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <input
                        type="number"
                        style={inputStyle}
                        value={
                          editedRows[row.productMasterCode]
                            ?.newManufacturerCarryingCost || ""
                        }
                        onChange={(e) =>
                          handleFieldChange(
                            e,
                            row.productMasterCode,
                            "newManufacturerCarryingCost"
                          )
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <input
                        // type="number"
                        style={inputStyle}
                        value={
                          editedRows[row.productMasterCode]
                            ?.newManufactureSaleRate || ""
                        }
                        onChange={(e) =>
                          handleFieldChange(
                            e,
                            row.productMasterCode,
                            "newManufactureSaleRate"
                          )
                        }
                      />
                    </TableCell>
                    {/* </>
                    ) : null} */}
                    {/* <TableCell align="left">
                      {row.isActive ? <>Enable</> : <>Disable</>}
                    </TableCell> */}
                    <TableCell>
                      {/* {isEditing ? ( */}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSave(row.productMasterCode)}
                      >
                        Save
                      </Button>
                      {/* )  */}
                      {/* // : (
                      //   <IconButton onClick={() => handleEditClick(row)}>
                      //     <EditIcon />
                      //   </IconButton>
                      // )} */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ManufactureUnlistedEditModal
        open={openModal}
        setOpen={setOpenModal}
        handleClose={() => setOpenModal(false)}
        editedFields={editedFields}
        handleFieldChange={handleFieldChange}
        handleSave={handleSave}
      />
    </Box>
  );
}
