import { ThemeProvider } from '@emotion/react'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, CircularProgress, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, createTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getPortfolioOverview, getUnderOutPerforming } from '../../api/Api'

const AnalyzerPerformance = () => {
  const [datamfitisin, mfitisin] = useState<any>([])
  const [datanavHistry, navHistry] = useState<any>([])
  const [IsinList, SetIsinList] = useState<any>([])
  const [DynamicList, SetDynamicList] = useState<any>([])
  const [dataUnderPerforming, UnderPerforming] = useState<any>([])
  const [dataOutPerforming, OutPerforming] = useState<any>([])
  const [resultstatement, Resstatement] = useState<any>([])
  const [dataoutPercentage, outPercentage] = useState<any>([])
  const [dataunderPercentage, underPercentage] = useState<any>([])
  const [dataperformanceOutPut, overallperformanceOutPut] = useState<any>([])
  const [expanded, setExpanded] = useState<any>(false)
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const defaultTheme = createTheme()

  const [selectedReturnTypeData, setUserSelectedReturn] = useState({
    '1 Year': true,
    '3 Year': false,
    '5 Year': false
  })
  const handleChange = (panel:any) => (event:any, isExpanded:any) => {
    setExpanded(isExpanded ? panel : false)
  }
  const color = ['#00ffff', '#7fffd4', '#8a2be2', '#a52a2a', '#d2691e', '#6495ed', '#00008b', 'b8860b', '#483d8b', 'rgb(255, 99, 132)',
  'rgb(54, 162, 235)',
  'rgb(255, 206, 86)',
  'rgb(75, 192, 192)',
  'rgb(153, 102, 255)',
  'rgb(255, 159, 64)',
  'rgb(25, 111, 64)',
  'rgb(189, 200, 134)',
  'rgb(243, 50, 190)',
  'rgb(228, 58, 94)',
  'rgb(210, 88, 1)',
  'rgb(134, 6, 207)',
  'rgb(43, 242, 180)',
  'rgb(149, 202, 6)',
  'rgb(76, 212, 52)',
  'rgb(172, 204, 151)',
  'rgb(236, 192, 73)',
  'rgb(17, 121, 22)',
  'rgb(44, 72, 197)',
  'rgb(238, 27, 45)',
  'rgb(125, 107, 80)',
  'rgb(133, 248, 125)',
  'rgb(217, 223, 101)',
  'rgb(37, 72, 176)']

  useEffect(() => {
    setshowCircularProgress(true);
    getUnderOutPerforming(selectedReturnTypeData)
      .then((response) => {
        UnderPerforming(response.data.underPerformingSchemeList)
        OutPerforming(response.data.outPerformingSchemeList)
        Resstatement(response.data.performanceResult)
        outPercentage(response.data.outperformingAveragePercentage)
        underPercentage(response.data.underperformingAveragePercentage)
        overallperformanceOutPut(response.data.performanceOutPut)
      })

      getPortfolioOverview()
      .then((response) => {
        mfitisin(response.data.portfolioFundDetailsDTOList)
        SetIsinList(response.data.portfolioFundDetailsDTOList.filter((data:any) => data.fundName != null).map((item:any) => (item.fundName)))
        response.data.portfolioFundDetailsDTOList.filter((data:any) => data.isin != null).map((item:any) => callAIP(item.isin))
      }).then(()=>{
        setshowCircularProgress(false);
      })
  }, [])

  const callAIP = async (isin:any) => {
    // const date = new Date()
    // const day = date.getDate()
    // const month = date.getMonth() + 1
    // const year = date.getFullYear()
    // const previousyear = date.getFullYear() - 1
    // const currentDate = `${year}-${month}-${day}`
    // const previousDate = `${previousyear}-${month}-${day}`
    // getNavhistoryData(currentDate, isin, previousDate)
    //   .then((response) => {
    //     SetDynamicList((prevArray) => [...prevArray, response])
    //   })
  }

  const getDataset = (json:any) => {
    // const Array = []
    // let JSONObj = new Object()
    // json.map((item:any, index:any) => {
    //   JSONObj = new Object()
    //   JSONObj.label = IsinList[index]
    //   JSONObj.data = item.navHistoryList?.map((item:any) => (item.NavValue))
    //   JSONObj.fill = true
    //   JSONObj.borderColor = color[index]
    //   return Array.push(JSONObj)
    // })
    // return Array
  }

  const data = {
    labels: DynamicList[0]?.navHistoryList?.map((item:any) => ((item.NAVDate))),
    datasets: getDataset(DynamicList)
  }

  const getDataset1 = (json:any) => {
    // const data = json.map((item:any, index:any) => ({
    //   type: 'line',
    //   name: IsinList[index],
    //   data: item.navHistoryList?.map((value:any) => parseFloat(value.NavValue))
    // }))
    // return data
  }
  const handleYearChange = (newValue: '1 Year' | '3 Year' | '5 Year') => {
    const updatedReturnTypeData = { ...selectedReturnTypeData };

    // Set all values to false
    for (const key in updatedReturnTypeData) {
      updatedReturnTypeData[key as '1 Year' | '3 Year' | '5 Year'] = false;
    }

    // Set the selected value to true
    updatedReturnTypeData[newValue] = true;
    console.log(updatedReturnTypeData);

    setUserSelectedReturn(updatedReturnTypeData)
    getUnderOutPerforming(updatedReturnTypeData)
      .then((response) => {
        UnderPerforming(response.data.underPerformingSchemeList)
        OutPerforming(response.data.outPerformingSchemeList)
        Resstatement(response.data.performanceResult)
        outPercentage(response.data.outperformingAveragePercentage)
        underPercentage(response.data.underperformingAveragePercentage)
        overallperformanceOutPut(response.data.performanceOutPut)
      })
  }

  const dropDownValues = [
    { label: '1 Year', value: '1 Year' },
    { label: '3 Year', value: '3 Year' },
    { label: '5 Year', value: '5 Year' }
  ]

  console.log("sdfghjklkjhgfd",dataUnderPerforming)
  return (
    <div>
      {showCircularProgress == true ? (
                    <CircularProgress
                      color="secondary"
                      size={50}
                      thickness={5}
                    />
                  ) : (
                    <ThemeProvider theme={defaultTheme}>
                    <Box className='main-div-total-return-with-pa' style={{ padding: '1px' }}>
                      <Typography className='performance-total-rtnsss'>{resultstatement}</Typography>
                      <Typography variant='h2' className='performance-total-rtn-h2'></Typography>
                    </Box>
                    <TableContainer>
                      <Table aria-label='simple table'>
                        <TableHead style={{ background: '#acd3e8', color: '#fff' }} >
                          <TableRow className='main-tr-top-post-investment-holding-imp '>
                            <TableCell sx={{ fontSize: '18px', color: '#032b5b', backgroundColor: '#acd3e8', textAlign: 'center' }} className='performance-under-main-td'>Performance</TableCell>
                            <TableCell sx={{ fontSize: '18px', color: '#032b5b', backgroundColor: '#acd3e8', textAlign: 'center' }} className='start-quality-portfolio-and-zone'>Portfolio %</TableCell>
                            <TableCell sx={{ fontSize: '18px', color: '#032b5b', backgroundColor: '#acd3e8', textAlign: 'center' }} className='start-quality-portfolio-and-zone'>
                              <FormControl fullWidth>
                                <Autocomplete
                                  options={dropDownValues}
              
                                  onChange={(_, newValue) => {
                                    const selectedValue = newValue.value as '1 Year' | '3 Year' | '5 Year';
                                    handleYearChange(selectedValue);
                                  }}
                                  
                                  defaultValue={dropDownValues[0]}
                                  getOptionLabel={(option) => option.label}
                                  renderInput={(params) => <TextField {...params} label='Year'  />}
                                  disableClearable={true}
                                  freeSolo={false} // Disable free text input
                                  autoComplete={false} // Remove the autoComplete prop to prevent typing
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
              
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{
                          backgroundColor: '#f0f0f0',
                          margin: 0
                        }}
                      >
              
                        <TableContainer>
                          <Table aria-label='simple table' >
                            <TableBody >
                              <TableRow sx={{ borderBottom: 'hidden' }}>
                                <TableCell width={'50%'} sx={{ textAlign: 'center', padding: 0 }} className='' > <span className='quality-span-icone'><TrendingDownIcon className='underperformance-icone'></TrendingDownIcon></span>Underperforming Schemes</TableCell>
                                <TableCell width={'30%'} sx={{ textAlign: 'center', padding: 0 }} >{dataunderPercentage}%</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
              
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: '1.5em' }}>
                        <TableContainer>
                          <Table aria-label='simple table' >
                            <TableBody sx={{ background: '#e7f1ff' }}>
              
                              {dataUnderPerforming.map((item:any, index:any) => (
                                <TableRow key={index}>
                                  <TableCell className='' sx={{ width: '50%' }}>{item.fundName}</TableCell>
                                  <TableCell className='' sx={{ width: '28%', textAlign: 'center' }}>{item.percentageOfPortfolio}%</TableCell>
                                  <TableCell></TableCell>
                                <TableCell></TableCell>
              
                                </TableRow>
                              ))}
              
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{ backgroundColor: '#f0f0f0', margin: 0 }}
                      >
              
                        <TableContainer>
                          <Table aria-label='simple table' >
                            <TableBody >
                              <TableRow style={{ borderBottom: 'hidden' }}>
                                <TableCell width={'50%'} sx={{ textAlign: 'center', padding: 0 }} className='' > <span className='quality-span-icone'><TrendingUpIcon className='outperforming-icone'></TrendingUpIcon></span>Outperforming Schemes</TableCell>
                                <TableCell width={'30%'} sx={{ textAlign: 'center', padding: 0 }} >{dataoutPercentage}%</TableCell>
                                <TableCell></TableCell>
              
                                <TableCell></TableCell>
              
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: '1.5em' }}>
                        <TableContainer>
                          <Table aria-label='simple table' >
                            <TableBody sx={{ background: '#e7f1ff' }}>
              
                              {dataOutPerforming.map((item:any, index:any) => (
                                <TableRow key={index}>
                                  <TableCell className='' sx={{ width: '50%' }}>{item.fundName}</TableCell>
                                  <TableCell className='' sx={{ width: '28%', textAlign: 'center' }}>{item.percentageOfPortfolio}%</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
              
                                </TableRow>
                              ))}
              
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
              
                    {/* ACCORDIAN END */}
                    {/* GRAPHHHH */}
                    <Box sx={{ mb: '2em' }}>
                      <Box className='company-sector-wise-main-row-btm' >
                        <Box style={{ marginTop: '20px' }}>
                          <Box className='main-div-total-return-with-pa'>
                            <Typography className='performance-total-rtnsss'>Comparative Graph</Typography>
                            <Typography variant='h2' className='performance-total-rtn-h2'></Typography>
                          </Box>
                          <Box className='limeChart-porffolio-performance'>
                          </Box>
                        </Box>
                      </Box>
                      <Box className='main-div-total-return-with-pa' sx={{ padding: '5px', borderRadius: '0px 0px 25px 25px' }}>
                        <Typography variant='h2' className='performance-total-rtn-h2'></Typography>
                      </Box>
                    </Box>
                  </ThemeProvider>
                  )}
    </div>
  )
}

export default AnalyzerPerformance