import {
  Container,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Grid,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  InputBase,
  IconButton,
  TablePagination,
  CircularProgress,
  Fab,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { withParam } from "../utils/Router.Helper";
import { GetAllBrokerMaster } from "../api/Api";
import { mb } from "../Constants/Color";
import States from "../Constants/States";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { AccountTypeEnum } from "../Constants/AccountTypeEnum";

const style = {
  position: "absolute" as "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  overflowY: "auto",
  height: "90%",
};

const BrokerMaster = () => {
  const [open, setOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<any>(null);

  const [brokerLogofile, setBrokerLogoFile] = useState<any>();
  const [registerOfCertificatefile, setregisterOfCertificateFile] =
    useState<any>();
  const [pancardDocfile, setpancardDocFile] = useState<any>();
  const [cancelCheque, setCancelCheque] = useState<any>();
  const [gstNumberCertificatefile, setgstNumberCertificateFile] =
    useState<any>();
  const [isChecked, setIsChecked] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [checked, setChecked] = React.useState(true);
  const [errMessage, setErrMessage] = useState<string>("");

  const [bankDetails, setBankDetails] = useState([
    {
      bankName: "",
      accountNO: "",
      accountHolderName: "",
      branchName: "",
      ifscCode: "",
      micrCode: "",
      accountType: "",
      bankAddress: "",
      city: "",
      state: "",
      country: "",
      pinCode: "",
      dpId: "",
      dpName: "",
      clientCode: "",
      isDefault: true,
    },
  ]);

  const handleErr = () => {
    const hasEmptyField = bankDetails.some((detail) =>
      Object.values(detail).some((value) => value === "")
    );
    if (hasEmptyField) {
      setErrMessage("Please fill  the All bank details");
    } else {
      setErrMessage("");
    }
  };

  const { data, refetch } = useQuery("getAllBrokerMaster", GetAllBrokerMaster);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredBroker =
    data &&
    data.data.brokers.filter((item: any) => {
      return item.brokerName.toLowerCase().includes(searchTerm.toLowerCase());
      // || item.userName.toLowerCase().includes(searchTerm.toLowerCase())
    });

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const resolver = async (values: any) => {
    return {
      values: !values.brokerName ? {} : values,
      errors: !values.brokerName
        ? {
            brokerName: {
              type: "required",
              message: "Enter Broker Name.",
            },
          }
        : !values.country
        ? {
            country: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.state
        ? {
            state: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.city
        ? {
            city: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.addressLine1
        ? {
            addressLine1: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.addressLine2
        ? {
            addressLine2: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.emailId
        ? {
            emailId: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.addressLine3
        ? {
            addressLine3: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.pincode
        ? {
            pincode: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.cinNumber
        ? {
            cinNumber: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.gstNumber
        ? {
            gstNumber: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.pancard
        ? {
            pancard: {
              type: "required",
              message: "This is required.",
            },
          }
        : {},
    };
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: resolver,
  });

  const handleAddRow = () => {
    setBankDetails([
      ...bankDetails,
      {
        bankName: "",
        accountNO: "",
        accountHolderName: "",
        branchName: "",
        ifscCode: "",
        micrCode: "",
        accountType: "",
        bankAddress: "",
        city: "",
        state: "",
        country: "",
        pinCode: "",
        dpId: "",
        dpName: "",
        clientCode: "",
        isDefault: false,
      },
    ]);
  };

  const handleRemoveRow = (index: any) => {
    const updatedDetails = bankDetails.filter((_, i) => i !== index);
    setBankDetails(updatedDetails);
  };

  const handleInputChange = (index: any, field: any, value: any) => {
    const updatedDetails: any = [...bankDetails];
    updatedDetails[index][field] = value;
    setBankDetails(updatedDetails);
  };

  const handleDefaultBankChange = (event: any, index: any, field: any) => {
    const updatedDetails: any = [...bankDetails];
    updatedDetails[index][field] = event.target.checked;
    setBankDetails(updatedDetails);
  };

  const onSubmit = (data: any) => {
    handleErr();
    setshowCircularProgress(true);
    const formData = new FormData();

    formData.append("brokerLogo", brokerLogofile);
    formData.append("registerOfCertificate", registerOfCertificatefile);
    formData.append("pancardDoc", pancardDocfile);
    formData.append("gstNumberCertificate", gstNumberCertificatefile);
    formData.append("cancelCheque", cancelCheque);

    const dto: any = {
      ...data,
      bankDetailsDTOS: bankDetails,
      viewLogo: isChecked,
    };
    formData.append("brokerDto", JSON.stringify(dto));

    axios
      .post("Brokers/saveBroker", formData)
      .then(() => {
        setshowCircularProgress(false);
        setOpen(false);
        refetch();
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        toast.error(
          err.response.data
            ? err.response.data.status.message.title
            : "Kindly Upload Files"
        );
      });
  };

  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ marginTop: "3%" }}>
          <Typography variant="h5" sx={{ fontWeight: "500" }} gutterBottom>
            Broker Master
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "70%",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Broker by Name"
                  onChange={handleSearch}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: "end" }}>
              <Button variant="contained" onClick={handleOpen}>
                Add Broker
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper} elevation={3}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    align="center"
                  >
                    Broker Name
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    align="center"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    align="center"
                  >
                    Pan Card
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "1rem", fontWeight: "600" }}
                    align="center"
                  >
                    City
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredBroker &&
                  filteredBroker
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item: any) => (
                      <Link
                        display={"table-row"}
                        sx={{
                          textDecoration: "none",
                          "&:hover": { backgroundColor: "lightgray" },
                        }}
                        href={"/brokerDetails/" + item.brokerMasterCode}
                      >
                        <TableCell sx={{ padding: "10px" }} align="center">
                          {item.brokerName}
                        </TableCell>
                        <TableCell sx={{ padding: "10px" }} align="center">
                          {item.emailId}
                        </TableCell>
                        <TableCell sx={{ padding: "10px" }} align="center">
                          {item.pancard}
                        </TableCell>
                        <TableCell sx={{ padding: "10px" }} align="center">
                          {item.city}
                        </TableCell>
                      </Link>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 30, 50]}
            component="div"
            count={data && data.data.brokers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90%",
            overflow: "scroll",
          }}
        >
          <Box sx={style} className="modal-scroll">
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Typography
                  variant="h5"
                  mb={2}
                  textAlign={"center"}
                  gutterBottom
                >
                  Add Broker
                </Typography>
                <Typography variant="h6" mb={2}>
                  Personal Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="outlined-basic"
                      label="Broker Name"
                      variant="outlined"
                      error={errors.brokerName ? true : false}
                      helperText={
                        errors.brokerName == undefined
                          ? ""
                          : errors.brokerName.message + ""
                      }
                      fullWidth
                      {...register("brokerName")}
                      sx={{ mb: mb }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Autocomplete
                      options={States.countries}
                      getOptionLabel={(option: any) => option.country}
                      onChange={(event, value) => setSelectedCountry(value)}
                      sx={{ mb: mb }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          variant="outlined"
                          error={errors.country ? true : false}
                          helperText={
                            errors.country == undefined
                              ? ""
                              : errors.country.message + ""
                          }
                          {...register("country")}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Autocomplete
                      options={selectedCountry?.states || []}
                      onChange={(event, value) => setSelectedState(value)}
                      sx={{ mb: mb }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          variant="outlined"
                          error={errors.state ? true : false}
                          helperText={
                            errors.state == undefined
                              ? ""
                              : errors.state.message + ""
                          }
                          {...register("state")}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="outlined-basic"
                      label="City"
                      variant="outlined"
                      error={errors.city ? true : false}
                      helperText={
                        errors.city == undefined ? "" : errors.city.message + ""
                      }
                      {...register("city")}
                      sx={{ mb: mb }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="outlined-basic"
                      label="Address Line 1"
                      variant="outlined"
                      error={errors.addressLine1 ? true : false}
                      helperText={
                        errors.addressLine1 == undefined
                          ? ""
                          : errors.addressLine1.message + ""
                      }
                      {...register("addressLine1")}
                      sx={{ mb: mb }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="outlined-basic"
                      label="Address Line 2"
                      variant="outlined"
                      error={errors.addressLine2 ? true : false}
                      helperText={
                        errors.addressLine2 == undefined
                          ? ""
                          : errors.addressLine2.message + ""
                      }
                      {...register("addressLine2")}
                      sx={{ mb: mb }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      type="email"
                      error={errors.emailId ? true : false}
                      helperText={
                        errors.emailId == undefined
                          ? ""
                          : errors.emailId.message + ""
                      }
                      variant="outlined"
                      {...register("emailId")}
                      sx={{ mb: mb }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="outlined-basic"
                      label="Address Line 3"
                      error={errors.addressLine3 ? true : false}
                      helperText={
                        errors.addressLine3 == undefined
                          ? ""
                          : errors.addressLine3.message + ""
                      }
                      variant="outlined"
                      {...register("addressLine3")}
                      sx={{ mb: mb }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="outlined-basic"
                      label="Pin Code"
                      error={errors.pincode ? true : false}
                      helperText={
                        errors.pincode == undefined
                          ? ""
                          : errors.pincode.message + ""
                      }
                      variant="outlined"
                      {...register("pincode")}
                      sx={{ mb: mb }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="outlined-basic"
                      label="CIN Number"
                      error={errors.cinNumber ? true : false}
                      helperText={
                        errors.cinNumber == undefined
                          ? ""
                          : errors.cinNumber.message + ""
                      }
                      variant="outlined"
                      {...register("cinNumber")}
                      sx={{ mb: mb }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="outlined-basic"
                      label="GST Number"
                      error={errors.gstNumber ? true : false}
                      helperText={
                        errors.gstNumber == undefined
                          ? ""
                          : errors.gstNumber.message + ""
                      }
                      variant="outlined"
                      {...register("gstNumber")}
                      sx={{ mb: mb }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="outlined-basic"
                      label="Pancard"
                      error={errors.pancard ? true : false}
                      helperText={
                        errors.pancard == undefined
                          ? ""
                          : errors.pancard.message + ""
                      }
                      variant="outlined"
                      {...register("pancard")}
                      sx={{ mb: mb }}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                {bankDetails.map((item: any, index: any) => (
                  <Paper elevation={8} sx={{ p: 2, mb: 2 }}>
                    <Grid
                      container
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h6" mb={2} gutterBottom>
                        Bank Details {index + 1} :
                      </Typography>
                      <Grid alignItems={"center"} sx={{ mb: 2 }}>
                        <Fab
                          color="primary"
                          aria-label="add"
                          variant="extended"
                          sx={{ mr: 1 }}
                          onClick={() => handleRemoveRow(index)}
                        >
                          <RemoveIcon /> Remove
                        </Fab>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Bank Name"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(
                              index,
                              "bankName",
                              e.target.value
                            );
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Account No"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(
                              index,
                              "accountNO",
                              e.target.value
                            );
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Autocomplete
                          options={AccountTypeEnum.map((option) => option)}
                          getOptionLabel={(option) => option.Label}
                          onChange={(e: any) => {
                            handleInputChange(
                              index,
                              "accountType",
                              e.target.value
                            );
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Account Type"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Bank Branch"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(
                              index,
                              "branchName",
                              e.target.value
                            );
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="IFSC Code"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(
                              index,
                              "ifscCode",
                              e.target.value
                            );
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="MICR Code"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(
                              index,
                              "micrCode",
                              e.target.value
                            );
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Bank Address"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(
                              index,
                              "bankAddress",
                              e.target.value
                            );
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Account Holder Name"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(
                              index,
                              "accountHolderName",
                              e.target.value
                            );
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Country"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(index, "country", e.target.value);
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="State"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(index, "state", e.target.value);
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Pin Code"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(index, "pinCode", e.target.value);
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="City"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(index, "city", e.target.value);
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="DP ID"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(index, "dpId", e.target.value);
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="DP Name"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(index, "dpName", e.target.value);
                            handleErr();
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Client ID"
                          variant="outlined"
                          onChange={(e) => {
                            handleInputChange(
                              index,
                              "clientCode",
                              e.target.value
                            );
                            handleErr(); // Note: Call the function here
                          }}
                          sx={{ mb: mb }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Checkbox
                          checked={bankDetails[index].isDefault}
                          onChange={(e) =>
                            handleDefaultBankChange(e, index, "isDefault")
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />{" "}
                        Default Bank
                      </Grid>
                    </Grid>
                    {errMessage && (
                      <div
                        style={{
                          color: "red",
                          marginTop: "10px",
                          textAlign: "center",
                        }}
                      >
                        {errMessage}
                      </div>
                    )}
                  </Paper>
                ))}

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ textAlign: "end" }}
                >
                  <Fab
                    color="primary"
                    aria-label="add"
                    variant="extended"
                    sx={{ mr: 1 }}
                    onClick={handleAddRow}
                  >
                    <AddIcon />
                    Add Bank
                  </Fab>
                </Grid>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Show Logo"
                />

                <Grid container spacing={2} mb={4}>
                  <Grid item md={6}>
                    <Typography variant="subtitle1">Broker Logo :</Typography>
                    <input
                      type="file"
                      name="brokerLogo"
                      accept=".jpg"
                      onChange={(event: any) =>
                        setBrokerLogoFile(event.target.files[0])
                      }
                    />
                    <Typography variant="subtitle1">
                      GST Certificate :
                    </Typography>
                    <input
                      type="file"
                      name="gstNumberCertificate"
                      accept=".pdf"
                      onChange={(event: any) =>
                        setregisterOfCertificateFile(event.target.files[0])
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="subtitle1">Pancard :</Typography>
                    <input
                      type="file"
                      name="pancardDoc"
                      accept=".pdf"
                      onChange={(event: any) =>
                        setpancardDocFile(event.target.files[0])
                      }
                    />
                    <Typography variant="subtitle1">
                      Register Certificate :
                    </Typography>
                    <input
                      type="file"
                      name="registerOfCertificate"
                      accept=".pdf"
                      onChange={(event: any) =>
                        setgstNumberCertificateFile(event.target.files[0])
                      }
                    />
                    <Typography variant="subtitle1">Cancel Cheque:</Typography>
                    <input
                      type="file"
                      name="CancelCheque"
                      onChange={(event: any) =>
                        setCancelCheque(event.target.files[0])
                      }
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={showCircularProgress}
                >
                  {" "}
                  {showCircularProgress ? (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      thickness={5}
                    />
                  ) : (
                    ""
                  )}
                  Submit
                </Button>
              </form>
            </Box>
          </Box>
        </Modal>
      </Container>
    </>
  );
};

export default withParam(BrokerMaster);
