import React from "react";
import { useQuery } from "react-query";
import { RequestForQuote } from "../api/Api";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { convertDate } from "../utils/Helper";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";

const OrderBookRequestForQuote = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [page, setPage] = React.useState(0);
  const [sortOrder, setSortOrder] = React.useState("asc"); // "asc" or "desc"
  const [searchTerm, setSearchTerm] = React.useState("");
  const [requestForQuoteData, setRequestForQuoteData] = React.useState([]);

  const { data, refetch } = useQuery(
    "RequestForQuote",
    () => RequestForQuote(),
    {
      onSuccess: (res: any) => {
        const reqForQuote = res?.data?.requestForQuoteDTOS.map(
          (item: any, index: any) => {
            return { ...item, SrNo: index + 1 };
          }
        );
        setRequestForQuoteData(reqForQuote);
      },
      staleTime: Infinity,
    }
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSort = () => {
    // Toggle between "asc" and "desc" when clicking on the sorting button
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchRequest = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredRequestQuotes = requestForQuoteData?.filter((quote: any) => {
    return quote?.scriptName?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const filteredCount = filteredRequestQuotes.length;
  const pageCount = Math.ceil(filteredCount / rowsPerPage);
  // Ensure the current page is within bounds after filtering
  const adjustedPage = Math.min(page, pageCount - 1);

  const updateRFQ = (productTypeCode: any, requestForQuoteId: any) => {
    axios
      .put(
        `product/requestForQuote/approval?productTypeCode=${productTypeCode}&requestForQuoteId=${requestForQuoteId}`
      )
      .then((res: any) => toast.success(res?.data?.status?.message?.title))
      .then(() => refetch())
      .catch((err: any) =>
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        )
      );
  };

  // const requestForQuotes =
  //   data && data.data.requestForQuoteDTOS
  //     ? [...data.data.requestForQuoteDTOS]
  //     : [];
  // const requestForQuotesData =
  //   data && data.data.requestForQuoteDTOS
  //     ? [...data.data.requestForQuoteDTOS].sort((a: any, b: any) => {
  //         const nameA = a.scriptName;
  //         const nameB = b.scriptName;

  //         if (sortOrder === "asc") {
  //           return nameA.localeCompare(nameB);
  //         } else {
  //           return nameB.localeCompare(nameA);
  //         }
  //       })
  //     : [];
  // console.log(requestForQuotesData);
  // console.log(requestForQuotes);

  return (
    <>
      {requestForQuoteData && (
        <Grid container spacing={2}>
          <Grid item md={12} sx={{ display: "flex", width: "100%" }}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Script Name"
                onChange={handleSearchRequest}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item md={12}>
            <TableContainer sx={{ mb: 4 }} component={Paper} elevation={3}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Request ID</TableCell>
                    <TableCell align="center">Order Date</TableCell>
                    <TableCell align="center">Product Master Code</TableCell>
                    <TableCell align="center">Employee Code</TableCell>
                    <TableCell
                      align="center"
                      // onClick={handleSort}
                      // style={{ cursor: "pointer" }}
                    >
                      Script Name
                      {/* {sortOrder === "asc" ? "▲" : "▼"} */}
                    </TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="center">User Rate</TableCell>
                    <TableCell align="center">Client Id</TableCell>
                    <TableCell align="center">
                      Distributor Company Name
                    </TableCell>
                    <TableCell align="center">Distributor Name</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRequestQuotes
                    // .sort(
                    //   (a: any, b: any) =>
                    //     b.requestForQuoteId - a.requestForQuoteId
                    // )
                    .slice(
                      adjustedPage * rowsPerPage,
                      Math.min(
                        adjustedPage * rowsPerPage + rowsPerPage,
                        filteredCount
                      )
                    )
                    .map((item: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {item.requestForQuoteId}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: 0 }}>
                          {item.orderDate}
                        </TableCell>
                        <TableCell align="center">
                          {item.productMasterCode}
                        </TableCell>
                        <TableCell align="center">
                          {item.distEmployeeCode}
                        </TableCell>
                        <TableCell align="center">{item.scriptName}</TableCell>
                        <TableCell align="center">{item.quantity}</TableCell>
                        <TableCell align="center">{item.userRate}</TableCell>
                        <TableCell align="center">{item.clientId}</TableCell>
                        <TableCell align="center">
                          {item.distCompanyName}
                        </TableCell>
                        <TableCell align="center">{item.distName}</TableCell>
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() =>
                              updateRFQ(
                                item.productTypeCode,
                                item.requestForQuoteId
                              )
                            }
                          >
                            Accept Order
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[30, 50]}
                component="div"
                count={filteredCount ? filteredCount : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default OrderBookRequestForQuote;
