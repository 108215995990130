import {
    Box,
    Button,
    Container,
    Grid,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import axios from "axios";
  import React, { useEffect, useState } from "react";
  import { useForm, Resolver } from "react-hook-form";
  import { withParam } from '../utils/Router.Helper'
  import { useMutation } from 'react-query';
  import { toast } from 'react-toastify';
import { SELF_USER_PROFILE } from "../utils/API_Names";
  
  type FormValues = {
    userCompany: string;
    UserCode: string;
    firstName: string;
    middleName: string;
    lastName: string;
    mobileNo: string;
    email: string;
    country: string;
    state: string;
    city: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    pin: string;
    accountManager: string;
    companyMasterCode: Number;
    employeeCode:Number;
    userCode:String;
  };
  
  const UserProfileView = (props: any) => {
    const [UserProfile, setUserProfile] = useState<any>();
    const [companyData, setCompanyData] = useState<any>();
    const [edit, setedit] = useState(true);
  
    useEffect(() => {
      axios
        .get(SELF_USER_PROFILE)
        .then((res) => setUserProfile(res.data.userProfile)).catch((err)=>{
          toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title)
        })
    }, []);

    useEffect(()=>{
      if(UserProfile && UserProfile.companyMasterCode){
        axios.get("company/companyDetail/"+UserProfile.companyMasterCode).then((res:any)=>setCompanyData(res.data.companies)).catch((err)=>{
          toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title)})
      }
    },[UserProfile])
  
    const resolver: Resolver<FormValues> = async (values) => {
      return {
        values: !values.firstName ? {} : values,
        errors: !values.firstName ? {
            firstName: {
                type: "required",
                message: "First name is required."
              },
              lastName:{
                type: "required",
                message: "Last name is required."
              }
            }
          : {},
      };
    };
  
    const { register, handleSubmit, formState} = useForm({
      resolver: resolver
    });
  
    const {errors} = formState;
    
    const onSubmit = (data:any) => {
      // mutation.mutate(data)
      // mutation.isSuccess ? toast.success("User Updated!") : <></>
      // mutation.isError ? toast.error("Something Went Wrong!") : <></>
  
      axios.put("userProfile/selfProfileUpdate",{
        ...data,
        companyMasterCode:UserProfile.companyMasterCode,
        userCode:UserProfile.userCode,
        userTypeCode:UserProfile.userTypeCode
      })
      .then((res:any)=>toast.success("User Profile Update Successfully!"))
      .catch((err:any)=>toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title))
    }
    console.log("companyData",companyData)
  
    const textFieldSize = "small"
    return (
      <>
        <Container maxWidth="xl">
          <Box paddingTop="90px">
              {UserProfile && (
                <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box component="form"  sx={{ '& .MuiTextField-root': { m: 1, width: '30ch' }, }} noValidate autoComplete="off" >
              <Typography variant="h5" sx={{pb:3}}>Personal Details</Typography>
                <Grid container sx={{justifyContent:'center'}}>
                <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} id="outlined-basic" label="First Name" variant="outlined" className={edit ? "textDisable" : ""} error={errors.firstName?true:false} defaultValue={UserProfile.firstName} InputProps={{readOnly: edit}} {...register("firstName", { required: true })}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} id="outlined-basic" label="Middle Name" variant="outlined" className={edit ? "textDisable" : ""} defaultValue={UserProfile.middleName} InputProps={{readOnly: edit}} {...register("middleName")}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} id="outlined-basic" label="Last Name" variant="outlined" className={edit ? "textDisable" : ""} defaultValue={UserProfile.lastName} InputProps={{readOnly: edit}}  {...register("lastName")}/>
                     </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} id="outlined-basic" label="Email" variant="outlined" className={edit ? "textDisable" : ""} defaultValue={UserProfile.email} InputProps={{readOnly: edit}} {...register("email")}/>
                     </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} id="outlined-basic" label="Mobile No" variant="outlined" className={edit ? "textDisable" : ""} defaultValue={UserProfile.mobileNo} InputProps={{readOnly: edit}} {...register("mobileNo")}/>
                     </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} id="outlined-basic" label="Account Manager" variant="outlined" className={edit ? "textDisable" : ""} defaultValue={UserProfile.accountManager} InputProps={{readOnly: edit}} {...register("accountManager")}/>
                     </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} disabled id="outlined-basic" label="Company Master Code" variant="outlined" className={edit ? "textDisable" : ""} defaultValue={UserProfile.companyMasterCode} InputProps={{readOnly: true}}/>
                     </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} disabled id="outlined-basic" label="Broker Master Code" variant="outlined" className={edit ? "textDisable" : ""} defaultValue={UserProfile.brokerMasterCode} InputProps={{readOnly: edit}}/>
                     </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} id="outlined-basic" label="Employee Code" variant="outlined" className={edit ? "textDisable" : ""} defaultValue={UserProfile.employeeCode} InputProps={{readOnly: edit}} {...register("employeeCode")}/>
                     </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} disabled id="outlined-basic" label="User Code" variant="outlined" className={edit ? "textDisable" : ""} defaultValue={UserProfile.userCode} InputProps={{readOnly: edit}}/>
                  </Grid>
                  </Grid>

                  { companyData && <Typography variant="h5" sx={{pb:3}}>Company Details</Typography>}

                  <Grid container sx={{justifyContent:'center'}}>
                  { companyData && <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} disabled id="outlined-basic" label="Company Type" variant="outlined" defaultValue={companyData[0].compType} InputProps={{readOnly: edit}}/>
                  </Grid> }
                  { companyData && <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} disabled id="outlined-basic" label="Company Name" variant="outlined" defaultValue={companyData[0].companyName} InputProps={{readOnly: edit}}/>
                  </Grid> }
                  { companyData && <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} disabled id="outlined-basic" label="Company Email Id" variant="outlined" defaultValue={companyData[0].emailId} InputProps={{readOnly: edit}}/>
                  </Grid> }
                  { companyData && <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} disabled id="outlined-basic" label="Company GST Number" variant="outlined" defaultValue={companyData[0].gstNumber} InputProps={{readOnly: edit}}/>
                  </Grid> }
                  { companyData && <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} disabled id="outlined-basic" label="Company Pan card" variant="outlined" defaultValue={companyData[0].pancard} InputProps={{readOnly: edit}}/>
                  </Grid> }
                  { companyData && <Grid item xs={12} sm={12} md={4} lg={3} >
                    <TextField size={textFieldSize} disabled id="outlined-basic" label="Company CIN" variant="outlined" defaultValue={companyData[0].cinNumber} InputProps={{readOnly: edit}}/>
                  </Grid> }
                </Grid>
                
                 
              </Box>
                {edit && ( <Button sx={{margin:'10px 0'}} variant="contained" type="submit" onClick={()=>setedit(false)}>Edit Details</Button> )}
                  {!edit && (
                    <>
                      <Stack
                        spacing={2}
                        sx={{ justifyContent: "center", margin: "10px 0" }}
                        direction="row"
                      >
                        <Button variant="contained" type="submit">
                          Save Details
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          onClick={() => setedit(true)}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </>
                  )}
                </form>
              </>
            )}
          </Box>
        </Container>
      </>
    );
  };
  
  export default withParam(UserProfileView);
  