import { ChangeCompanyReducer,quarterWiseClientData,currMonthWiseData,yearlyDatacount,UnlistedTotalAmount,ChangeCompareAifFund,TotalCountOfClient,ChangeComparePmsFund,ChangeCompareMfFund,ClientCount,MonthlyCountOfClient,prevQuarterData,ytdDataClient } from "./ChangeCompany";
import { combineReducers } from 'redux'

export const rootReducer = combineReducers({
	ChangeCompanyReducer,
	ChangeCompareAifFund,
	ChangeComparePmsFund,
	ChangeCompareMfFund,
	ClientCount,
	TotalCountOfClient,
	MonthlyCountOfClient,
	UnlistedTotalAmount,
	quarterWiseClientData,
	currMonthWiseData,
	yearlyDatacount,
	prevQuarterData,
	ytdDataClient
})

