import { Box, Button, Card, Chip, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { convertDate } from "../../../utils/Helper";

const BondProposal = (props: any) => {
  const { BondDetails } = props;
  const { deleteProposal } = props;
  console.log("Product Logo URL:", BondDetails?.productLogo);
  return (
    <>
      <Paper>
        <Card sx={{mb:2, backgroundColor:"#f3f3f3", p: 1}}>
        <Grid container sx={{ mb: 0}} >
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <img src={BondDetails?.productLogo}
                alt="Bond logo"
                width={100}
                height={50}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3} sx={{ textAlign : { lg:"left",md:"center" } }}>
            <Grid item xs={12} sm={12} md={4} lg={10} textAlign={"left"}>
            {BondDetails?.coupon|| "-" } {(BondDetails?.productName) || "-" } {convertDate(BondDetails?.maturityDate) || "-" }
            </Grid>
            <Chip
                  label={BondDetails?.isinNumber}
                  color="success"
                />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2} textAlign={"left"}>
              <Typography variant="subtitle1"><b>YTM : {BondDetails?.sellYtm  || "-" }</b></Typography>
              <Typography variant="subtitle1"><b>YTC : {BondDetails?.sellYtc || "NA"}</b></Typography>
              </Grid>

            <Grid item xs={12} sm={12} md={4} lg={2} textAlign={"left"}>
              <Typography variant="subtitle1">{BondDetails?.rating1|| "-" }</Typography> 
              <Typography variant="subtitle1">{BondDetails?.seniority|| "-" }</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2} textAlign={"left"}>
              <Typography variant="subtitle1">
                <Chip
                  label={`Price : ${BondDetails?.clientPrice}`}
                  color="success"
                />
              </Typography>
              <Typography variant="subtitle1">
                <b>Quantum:</b> {BondDetails?.maxValue?.toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={1} textAlign={"right"}>
              <Tooltip title="Delete">
                <IconButton>
                  <DeleteIcon onClick={()=>deleteProposal(BondDetails.cartId)}/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Card>
      </Paper>
    </>
  );
};

export default BondProposal;
