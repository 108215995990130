import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const AIFProposal = (props: any) => {
  const { AIFDetails } = props;
  const { deleteProposal } = props;
  return (
    <>
      <Paper sx={{ mb: 2 }}>
        <Paper sx={{ p: 1, backgroundColor:"#f3f3f3"}}>
          <Grid container sx={{ mb: 0 }}>
            <Grid item xs={12} sm={12} md={4} lg={2} textAlign={"left"}>
              <img
                src={AIFDetails?.productLogo}
                alt="AIF logo"
                width={100}
                height={50}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} textAlign={"left"}>
                <Typography variant="subtitle1">
                  {AIFDetails?.productName}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2} textAlign={"center"}>
              <Typography>
                {AIFDetails?.aifSchemeClassification}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={1} textAlign={"center"}>
              <Typography variant="subtitle1">Nav : {AIFDetails?.aifNav}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2} textAlign={"center"}>
              <Typography variant="subtitle1">
                <Chip
                  label={`Price : ${AIFDetails?.clientPrice}`}
                  color="success"
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={1} textAlign={"right"}>
              <Tooltip title="Delete">
                <IconButton>
                  <DeleteIcon onClick={()=>deleteProposal(AIFDetails.cartId)}/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
    </>
  );
};

export default AIFProposal;
