import { Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import StartWithUs from '../StartWithUs/StartWithUs'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const ProductRealEstate = () => {
  return (
    <section>
        <Container>
        <Grid md={12} className={sessionStorage.getItem("JWT") ? 'your-dynamic-css-class-product' : 'default-class-product'}>
       <Typography style={{ textAlign: "left", fontSize: "30px", fontWeight: "bold",borderBottom: "4px solid black"}}>Real Estate  </Typography>
       <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
       Real estate Investments generates income or is otherwise intended for investment purposes 
rather than as a primary residence. It is common for investors to own multiple pieces of real 
estate, one of which serves as a primary residence while the others are used to generate 
rental income and profits through price appreciation. The tax implications for investment 
real estate are often different than those for residential real estate.   
      </Typography>
      </Grid>
      <br />
      <List>
      <ListItem>
        <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Investment real estate can provide opportunities for investors to build wealth, increase income, and diversify an investment portfolio." />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Residential investments typically involve homes, townhouses, and condominiums." />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="An investment in commercial real estate might involve the ownership of retail stores, office 
        buildings, or storage facilities and warehouses." />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Investment real estate can create capital gains for investors due to increases in property 
        value as well as provide rental income." />
      </ListItem>
    </List>
    <br />

   
   
  




{sessionStorage.getItem("JWT")?"":
<Grid mt={2} mb={2}>
<StartWithUs/>
</Grid>
}
        </Container>
    </section>
  )
}

export default ProductRealEstate