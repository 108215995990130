import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

const cashfolwData = [
  {
    redemption: 10,
  },
  {
    redemption: 20,
  },
  {
    redemption: 30,
  },
  {
    redemption: 40,
  },
  {
    redemption: 50,
  },
  {
    redemption: 60,
  },
  {
    redemption: 70,
  },
  {
    redemption: 80,
  },
  {
    redemption: 90,
  },
  {
    redemption: 100,
  },
  {
    redemption: 110,
  },
  {
    redemption: 120,
  },
]

 
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

const BarChartCall = (props:any) => {
    const [cashflow, setCashflow] = useState<any>()

    useEffect(() => {
      axios.get('cashflow/listOfCashFlowCallDateByIsinNumber/{isinNumber}?isinNumber=' + props.isin)
      .then((res:any)=> setCashflow(res.data.cashFlowMaturityCallList))
    }, [  ])

    console.log("cashflow call", cashflow)

    const cashFlowList = cashflow && cashflow.map((it:any)=> (
      it.totalPayout
    )) 

    const callCashFlowLable = cashflow && cashflow.map((it:any)=>(
        it.redemptionDate.slice(0,4)
    ))

    console.log("List cash flow call", cashFlowList)
    
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Cash Flow',
          },
        },
      };
      
    //   const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October', 'November', 'December'];
      const labels = callCashFlowLable
    
    //   const cashData = cashfolwData.map((it:any) => (it.redemption))
    //   console.log("cashData", cashData);
      
    
       const data = {
        labels,
        datasets: [
          { 
            label:"Rs",
            data: cashFlowList,
            backgroundColor: 'rgb(12, 167, 29,0.3)',
          },
        ],
      };
  return (
    <Bar height="100px" options={options} data={data} />
  )
}

export default BarChartCall