/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useQuery } from "react-query";
import { withParam } from "../utils/Router.Helper";
import {
  GetUnlistedOrderById,
  GetUnlistedOrderSnapshotById,
  GetUnlistedOrderStatusDetailsById,
} from "../api/Api";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axios from "axios";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import { convertDate } from "../utils/Helper";
import EditIcon from "@mui/icons-material/Edit";
import { style } from "./Clients";
import { useForm } from "react-hook-form";
import { useId } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { changeDate } from "./../utils/Helper";
import { Viewer } from "@react-pdf-viewer/core";
import {
  Container,
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  Stack,
  Grid,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const NewOrderWorkFlow = (props: any) => {
  const TextId = useId();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const clientDetails = props?.clientDetails;

  const { newOrderBookId } = props.params;
  const { productTypeCode } = props.params;

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [UnlistedOrder_Snapshot_Data, set_UnlistedOrder_Snapshot_Data] =
    useState<any>();
  const [workFlowData, setworkFlowData] = useState<any>();
  const [UploadFileReferanceNo, setUploadFileReferanceNo] = useState<any>("");
  const [PaymentReferanceNo, setPaymentReferanceNo] = useState<any>(null);
  const [StockReferanceNo, setStockReferanceNo] = useState<any>(null);
  const [biddingId, setBiddingId] = useState<any>(null);
  const [Backbtn, setBackbtn] = useState<any>("");
  const [rejectedReason, setRejectedReason] = useState<any>("");
  const [documentReject, setDocumentReject] = useState<any>("");
  const [paymentReject, setPaymentReject] = useState<any>("");
  const [clientVerificationReject, setClientVerificationReject] =
    useState<any>("");
  const [paymentTransferNo, setPaymentTransferNo] = useState<any>("");
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [orderDate, seOrderDate] = useState<any>(new Date());
  const [portFolioActivationDate, setPortFolioActivationDate] = useState<any>(
    new Date()
  );
  const [paymentTransferOrderDate, setPaymentTransferOrderDate] = useState<any>(
    new Date()
  );
  const [paymentInitiatorDate, setPaymentInitiatorDate] = useState<any>(
    new Date()
  );
  const [paymentConfirmDate, setPaymentConfirmDate] = useState<any>(new Date());
  const [clientFile, setClientFile] = useState<any>("");
  const [viewDocumentOpen, setViewDocumentOpen] = useState(false);
  const [formSubmitReject, setFormSubmitReject] = useState<any>("");
  const [formProcessReject, setFormProcessReject] = useState<any>("");
  const [paymentRefNo, setPaymentRefNo] = useState<any>("");
  const [portfolioActivationReject, setPortfolioActivationReject] =
    useState<any>("");
  const [remarkForm, setRemarkForm] = useState<any>("");
  const [documentVerificationRemark, setDocumentVerificationRemark] =
    useState<any>("");
  //const [paymentReferenceNo, setpaymentReferenceNo] = useState<any>("")
  const [clientVerificationCallRemark, setClientVerificationCallRemark] =
    useState<any>("");
  const [subscriptionActivationDate, setSubscriptionActivationDate] =
    useState<any>(new Date());
  const [formSubmittedDate, setFormSubmittedDate] = useState<any>(new Date());
  const [accountOpenDate, setAccountOpenDate] = useState<any>(new Date());
  const [paymentTransDate, setPaymentTransDate] = useState<any>(new Date());
  const [clientVerificationDate, setClientVerificationDate] = useState<any>(
    new Date()
  );
  const [ncdAppNo, setNcdAppNo] = useState<any>("");
  const [ncdAppSubmissionDate, setNcdAppSubmissionDate] = useState<any>(
    new Date()
  );
  const [biddingDate, setBiddingDate] = useState<any>(new Date());
  const [allotmentUnits, setAllotmentUnits] = useState<any>("");
  const [totalInvestmentAmount, setTotalInvestmentAmount] = useState<any>("");
  const [documentVerificationDate, setDocumentVerificationDate] = useState<any>(
    new Date()
  );

  const [EditDetailsModel, setEditDetailsModel] = useState<any>();
  const [clientAadhaarCardfile, setClientAadhaarCardfile] = useState<any>();
  const [clientCancelChequefile, setClientCancelChequefile] = useState<any>();
  const [clientMasterListfile, setClientMasterListfile] = useState<any>();
  const [clientPanCardfile, setClientPanCardfile] = useState<any>();
  const [clientProfileImagefile, setClientProfileImagefile] = useState<any>();
  const [portfolioStatement, setPortfolioStatement] = useState<any>();
  const [cancelCheque, setCancelCheque] = useState<any>();
  const [clientFileDocId, setClientFileDocId] = useState<any>("");
  const [acknowledgementReferenceNo, setAcknowledgementReferenceNo] =
    useState<any>("");
  const [uploadForm, setUploadForm] = useState<any>("");
  const [formAckNo, setFormAckNo] = useState<any>("");
  const [loanVerificationRemark, setLoanVerificationRemark] = useState<any>("");
  const [loanVerificationDate, setLoanVerificationDate] = useState<any>(
    new Date()
  );
  const [loanFormProcessingRemark, setLoanFormProcessingRemark] =
    useState<any>("");
  const [loanFormProcessingDate, setLoanFormProcessingDate] = useState<any>(
    new Date()
  );
  const [loanSanctionedAmt, setLoanSanctionedAmt] = useState<any>("");
  const [loanSanctionedDate, setLoanSanctionedDate] = useState<any>(new Date());
  const [loanDisbursementAmt, setLoanDisbursementAmt] = useState<any>("");
  const [loanDisbursementDate, setLoanDisbursementDate] = useState<any>(
    new Date()
  );

  // API call
  const { data, refetch: refetchOrderStatus } = useQuery(
    "GetUnlistedDetails",
    () => GetUnlistedOrderStatusDetailsById(productTypeCode),
    {
      staleTime: Infinity,
      cacheTime: 1000,
    }
  );

  const { data: UnlistedOrderSnapshotData, refetch: refetchOrderSnapShot } =
    useQuery(
      "GetUnlistedOrderSnapshotById",
      () => GetUnlistedOrderSnapshotById(newOrderBookId),
      {
        onSuccess: (data: any) => {
          set_UnlistedOrder_Snapshot_Data(data.data);
          setActiveStep(data.data.newOrderResDTOs.length - 1);
        },
        staleTime: Infinity,
        cacheTime: 1000,
      }
    );

  const { data: UnlistedOrderById, refetch } = useQuery(
    ["GetUnlistedOrder", UnlistedOrder_Snapshot_Data, activeStep, Backbtn],
    () => GetUnlistedOrderById(newOrderBookId, Backbtn, productTypeCode),
    {
      onSuccess: (data: any) => {
        setworkFlowData(data.data);
        // setValue("quantity",data.data.orderDetails.quantity);
        // setValue("userRate",data.data.orderDetails.userRate);
      },
      staleTime: Infinity,
      cacheTime: 1000,
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const registerOptions = {
    userRate: { required: "User Rate is required" },
    quantity: { required: "Quantity is required" },
    paymentRefNO: { required: "paymentRefNO is required" },
  };

  const tcsValue = 0;
  const totalValue = 0;
  const clientName = "";
  const creationDate = "";

  // Steps of Trail
  const steps: any =
    UnlistedOrder_Snapshot_Data && UnlistedOrder_Snapshot_Data.newOrderResDTOs;

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleBack = () => {
    setBackbtn(
      UnlistedOrderById.data.userSnapshot.previousStepSequence
        ? UnlistedOrderById.data.userSnapshot.previousStepSequence
        : UnlistedOrderById.data.orderDetails.stepSequence
    );
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const downloadTermSheet = () => {
    axios({
      url: "product/order/termSheet?orderId=" + newOrderBookId,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link: any = document.createElement("a");
      link.href = url;
      var date1 = new Date();
      link.setAttribute(
        "download",
        "TermSheet_" +
          ("0" + date1.getDate()).slice(-2) +
          "_" +
          ("0" + (date1.getMonth() + 1)).slice(-2) +
          "_" +
          date1.getFullYear() +
          ".pdf"
      );
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };

  const orderWorkFlow = async (StepMovingSequence: any) => {
    setBackbtn("");
    setshowCircularProgress(true);

    const formData = new FormData();
    let portfolioUploadForm;
    if (portfolioStatement) {
      const formData = new FormData();
      formData.append("document", portfolioStatement);
      await axios.post("documentupload", formData).then((res: any) => {
        portfolioUploadForm = res.data.documentId;
      });
    }

    let fromRefNo;
    if (uploadForm) {
      const formData = new FormData();
      formData.append("document", uploadForm);
      await axios.post("documentupload", formData).then((res: any) => {
        fromRefNo = res.data.documentId;
      });
    }

    var orderWorkflowRequestDTO = {
      orderId: parseInt(newOrderBookId),
      // "prevStepSequence": UnlistedOrderById.data.newOrderResDTOs.preStepSequence,
      // "currentStepSequence": UnlistedOrderById.data.newOrderResDTOs.stepSequence,
      statusId: StepMovingSequence,
      productTypeCode: parseInt(productTypeCode),
      paymentRefNo: !!PaymentReferanceNo ? PaymentReferanceNo : null,

      paymentInitiatorDate: changeDate(paymentInitiatorDate),
      paymentConfirmDate: changeDate(paymentConfirmDate),

      stockTransferRefNo: !!StockReferanceNo ? StockReferanceNo : null,
      formProcessReject: !!remarkForm ? remarkForm : null,
      stockTransferDate: changeDate(orderDate),
      portFolioActivationDate: changeDate(portFolioActivationDate),
      portfolioUploadForm: portfolioUploadForm,
      documentReject: !!documentVerificationRemark
        ? documentVerificationRemark
        : null,
      //"paymentTransferNo" : paymentReferenceNo,
      clientVerificationReject: !!clientVerificationCallRemark
        ? clientVerificationCallRemark
        : null,
      subscriptionActivationDate: changeDate(subscriptionActivationDate),
      formSubmittedDate: changeDate(formSubmittedDate),
      accOpenDate: changeDate(accountOpenDate),
      paymentTransDate: changeDate(paymentTransDate),
      clientVerificationDate: changeDate(clientVerificationDate),
      ncdAppNo: ncdAppNo,
      ncdAppSubmissionDate: changeDate(ncdAppSubmissionDate),
      biddingId: biddingId,
      biddingDate: changeDate(biddingDate),
      allotmentUnits: allotmentUnits,
      totalInvestmentAmount: totalInvestmentAmount,
      documentVerificationDate: changeDate(documentVerificationDate),
      acknowledgementReferenceNo: acknowledgementReferenceNo,
      fromRefNo: fromRefNo,
      formAckNo: formAckNo,
      loanVerificationRemark: loanVerificationRemark,
      loanVerificationDate: changeDate(loanVerificationDate),
      loanFormProcessingRemark: loanFormProcessingRemark,
      loanFormProcessingDate: changeDate(loanFormProcessingDate),
      loanSanctionedAmt: loanSanctionedAmt,
      loanSanctionedDate: changeDate(loanSanctionedDate),
      loanDisbursementAmt: loanDisbursementAmt,
      loanDisbursementDate: changeDate(loanDisbursementDate),
      // "uploadDocRefNo": !!UploadFileReferanceNo ? UploadFileReferanceNo.data.documentId : null,
      // "rejectedReason": !!rejectedReason?rejectedReason:null,
      // "documentReject": !!documentReject?documentReject:null,
      // "paymentReject": !!paymentReject?paymentReject:null,
      // "clientVerificationReject": !!clientVerificationReject?clientVerificationReject:null,
      // "paymentTransferNo": !!paymentTransferNo?paymentTransferNo:null,
      // "biddingId" : !!biddingId ? biddingId : null,
      // "formSubmitReject":!!formSubmitReject?formSubmitReject:null,
      // "formProcessReject":!!formProcessReject?formProcessReject:null,
      // "portfolioActivationReject":!!portfolioActivationReject?portfolioActivationReject:null,
    };

    formData.append("clientAadhaarCard", clientAadhaarCardfile);
    formData.append("clientCancelCheque", cancelCheque);
    formData.append("clientMasterList", clientMasterListfile);
    formData.append("clientPanCard", clientPanCardfile);
    formData.append("clientProfileImage", clientProfileImagefile);
    formData.append(
      "newOrderRequestDTO",
      JSON.stringify(orderWorkflowRequestDTO)
    );

    axios
      .post("product/orderWorkflow", formData)
      .then(() => {
        refetch();
        refetchOrderStatus();
        refetchOrderSnapShot();
        setshowCircularProgress(false);
      })
      .catch((res: any) => {
        toast.error(
          res.response
            ? res?.response?.data?.status?.message?.title
            : "Something Went Wrong"
        );
        setshowCircularProgress(false);
      });
  };

  const onSubmit = (data: any) => {
    setshowCircularProgress(true);
    const orderUpdateDTO = {
      tcs: tcsValue,
      quantity: data.quantity,
      userRate: data.userRate,
      totalValue: totalValue,
      paymentRefNO: !!data.paymentRefNO ? data.paymentRefNO : null,
      orderId: UnlistedOrderById.data.orderDetails.orderId,
      productTypeCode: UnlistedOrderById.data.orderDetails.productTypeCode,
    };
    axios
      .put("product/order", orderUpdateDTO)
      .then((res) => {
        toast.success("Order Update Successfully!");
        setshowCircularProgress(false);
        setEditDetailsModel(false);
      })
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };

  const fileDownload = (uuid: any) => {
    axios.get("documentupload?documentId=" + uuid).then((res: any) => {
      axios({
        url: "documentupload/download?documentId=" + uuid,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link: any = document.createElement("a");
        link.href = url;
        var date1 = new Date();
        link.setAttribute("download", res.data.documentFileName);
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
    });
  };
  const downloadDocument = (documentId: any) => {
    if (documentId) {
      axios.get("documentupload?documentId=" + documentId).then((res: any) => {
        axios({
          url: "documentupload/download?documentId=" + documentId,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", res.data.documentFileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      });
    }
  };

  const downloadDealSheet = () => {
    axios({
      url: "product/order/dealSheet?orderId=" + newOrderBookId,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link: any = document.createElement("a");
      link.href = url;
      var date1 = new Date();
      link.setAttribute(
        "download",
        UnlistedOrderById &&
          UnlistedOrderById.data?.orderDetails?.distributorName +
            "_" +
            ("0" + date1.getDate()).slice(-2) +
            "/" +
            ("0" + (date1.getMonth() + 1)).slice(-2) +
            "/" +
            date1.getFullYear() +
            ".pdf"
      );
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };
  const viewDocument = (fileType: any) => {
    axios.get("documentupload?documentId=" + fileType).then((res: any) => {
      setViewDocumentOpen(true);
      setClientFile(res?.data?.documentFilePath);
      setClientFileDocId(fileType);
    });
  };

  // const viewDocument = async (fileType: any) => {
  //   let contextVersion = "iv/v1";
  //   const defaultAxiosURL = process.env.REACT_APP_BASE_URL + contextVersion;
  //   try {
  //     const url = `${defaultAxiosURL}/documentupload?documentId=${fileType}`;
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `${sessionStorage.getItem("JWT")}`,
  //       },
  //     });
  //     if (!response.ok) {
  //       throw new Error("Network error");
  //     }
  //     const data = await response.json();
  //     if (data.status.resultType === "SUCCESSFUL") {
  //       setViewDocumentOpen(true);
  //       setClientFile(data.documentFilePath);
  //       setClientFileDocId(data.documentId);
  //     } else {
  //       console.error("Failed to fetch document:", data.status.message);
  //     }
  //   } catch (error) {
  //     console.error(
  //       "There has been a problem with your fetch operation:",
  //       error
  //     );
  //   }
  // };

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 150px)",
        marginTop: "10%",
        marginLeft: "2%",
      }}
    >
      {UnlistedOrderById && UnlistedOrder_Snapshot_Data && data && (
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} sx={{ m: 2 }}>
            {data &&
              data.data.newOrderResDTOs
                .map((item: any) => item.stepName)
                .map((label: any, index: any) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    {UnlistedOrderById &&
                      UnlistedOrderById.data?.displayFields?.map(
                        (inputData: any) => (
                          <>
                            <TableRow
                              key={inputData.key}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {" "}
                                {inputData.label} :{" "}
                              </TableCell>
                              <TableCell align="justify">
                                {" "}
                                {inputData.value}
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      )}
                    {data &&
                      data.data?.viewDocument?.map((inputData: any) => (
                        <TableRow
                          key={inputData.key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {inputData.label}
                          </TableCell>
                          <TableCell align="left">
                            {data && data.data?.viewDocument ? (
                              <Button
                                variant="contained"
                                sx={{ m: 1 }}
                                onClick={() => fileDownload(inputData.value)}
                              >
                                {inputData.label}
                              </Button>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid container sx={{ mb: 2 }}>
                {UnlistedOrderById &&
                  UnlistedOrderById.data?.viewDocument.map((item: any) => (
                    <Grid
                      item
                      sm={12}
                      md={12}
                      xs={12}
                      xl={12}
                      textAlign={"left"}
                    >
                      <Typography variant="caption">
                        {" "}
                        {item.label} :{" "}
                      </Typography>
                      {/* <Button variant='contained' size='small' sx={{ m:1}} color='warning'  onClick={()=>fileDownload(item?.value)} > Download { item.label } </Button> */}
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ m: 1 }}
                        onClick={() => viewDocument(item?.value)}
                      >
                        {" "}
                        View {item.label}{" "}
                      </Button>
                    </Grid>
                  ))}
              </Grid>

              <Grid
                container
                sx={{ alignItems: "center", justifyContent: "space-around" }}
              ></Grid>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    {clientName ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Client Name :{" "}
                        </TableCell>
                        <TableCell align="left">{clientName}</TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {creationDate ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Creation Date :{" "}
                        </TableCell>
                        {/* <TableCell align="left">{!!creationDate ? convertDate(creationDate.split("T")[0]) : "--"}</TableCell> */}
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById.data?.orderDetails?.updatedDate ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Updated Date :{" "}
                        </TableCell>
                        <TableCell align="left">
                          {!!UnlistedOrderById.data.orderDetails.updatedDate
                            ? convertDate(
                                UnlistedOrderById.data.orderDetails.updatedDate.split(
                                  "T"
                                )[0]
                              )
                            : "--"}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                    {UnlistedOrderById.data?.orderDetails
                      ?.distributorUserName ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Distributor User Name:{" "}
                        </TableCell>
                        <TableCell align="left">
                          {
                            UnlistedOrderById.data?.orderDetails
                              ?.distributorUserName
                          }
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {/* { clientName? 
                  <TableRow
                    key={TextId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row"> Client Pan Card : </TableCell>
                    <TableCell align="left">{UnlistedOrderById.data.orderDetails.clientPanCard?
                    <Button variant='contained' sx={{m:1}} onClick={()=>fileDownload(UnlistedOrderById.data.orderDetails.clientPanCard)}>Download Pan Card</Button> : <Alert severity="error" sx={{width:"50%"}}>Please add Pan Card For this Client !</Alert> }
            </TableCell>
                    </TableRow> : <></>
                 } */}

                    {clientName ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Client Cancel Cheque :{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data?.orderDetails
                            ?.clientCancelCheque ? (
                            <Button
                              variant="contained"
                              sx={{ m: 1 }}
                              onClick={() =>
                                fileDownload(
                                  UnlistedOrderById.data?.orderDetails
                                    ?.clientCancelCheque
                                )
                              }
                            >
                              Download Cancel Cheque
                            </Button>
                          ) : (
                            <Alert severity="error" sx={{ width: "50%" }}>
                              Please add client Cancel Cheque !
                            </Alert>
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {clientName ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Client Master List :{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data?.orderDetails
                            ?.clientMasterList ? (
                            <Button
                              variant="contained"
                              sx={{ m: 1 }}
                              onClick={() =>
                                fileDownload(
                                  UnlistedOrderById.data.orderDetails
                                    .clientMasterList
                                )
                              }
                            >
                              Download client Master List
                            </Button>
                          ) : (
                            <Alert severity="error" sx={{ width: "50%" }}>
                              Please add client Master List ( CML Copy ) !
                            </Alert>
                          )}
                          <br />
                          {UnlistedOrderById.data.orderDetails
                            .clientMasterList ? (
                            <Button
                              variant="contained"
                              sx={{ m: 1 }}
                              onClick={() => {
                                setViewDocumentOpen(true);
                                viewDocument(
                                  UnlistedOrderById.data.orderDetails
                                    .clientMasterList
                                );
                              }}
                            >
                              View
                            </Button>
                          ) : (
                            <Alert severity="error" sx={{ width: "50%" }}>
                              Please add client Master List ( CML Copy ) !
                            </Alert>
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {/* { clientName? 
                  <TableRow
                    key={TextId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row"> Client Profile Image : </TableCell>
                    <TableCell align="left">{UnlistedOrderById.data.orderDetails.clientProfileImage?
                    <Button variant='contained' sx={{m:1}} onClick={()=>fileDownload(UnlistedOrderById.data.orderDetails.clientProfileImage)}>Download client Profile Image</Button> : <Alert severity="error" sx={{width:"50%"}}>Please add client profile image !</Alert> }
            </TableCell>
                    </TableRow> : <></>
                 } */}

                    {/* { clientName? 
                  <TableRow
                    key={TextId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row"> Client Aadhaar Card : </TableCell>
                    <TableCell align="left">{UnlistedOrderById.data.orderDetails.clientAadhaarCardDocument?
                    <Button variant='contained' sx={{m:1}} onClick={()=>fileDownload(UnlistedOrderById.data.orderDetails.clientAadhaarCardDocument)}>Download client Aadhaar Card</Button> : <Alert severity="error" sx={{width:"50%"}}>Please add client aadhaar card !</Alert> }
            </TableCell>
                    </TableRow> : <></>
                 } */}

                    {UnlistedOrderById?.data?.orderDetails?.distributorName ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Distributor Name{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails?.distributorName}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById.data?.orderDetails?.quantity && (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Quantity :{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data?.orderDetails?.quantity}
                        </TableCell>
                      </TableRow>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.userRate ? (
                      <TableRow
                        key={UnlistedOrderById?.data?.orderDetails?.userRate}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          User Rate :{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails.userRate}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.productType ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Product Type :{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails.productType}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.accruedInterest ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Accrued Interest:{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails.accruedInterest}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.coupon ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Coupon:{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails.coupon}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.dealDate ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Deal Date:{" "}
                        </TableCell>
                        <TableCell align="left">
                          {convertDate(
                            UnlistedOrderById.data.orderDetails.dealDate
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.isinNumber ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          ISIN Number:{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails.isinNumber}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.price ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Price:{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails.price}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.prinicipalAmount ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Prinicipal Amount:{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails.prinicipalAmount}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.settlementDate ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Settlement Date:{" "}
                        </TableCell>
                        <TableCell align="left">
                          {convertDate(
                            UnlistedOrderById.data.orderDetails.settlementDate
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails
                      ?.totalConsideration ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Total Consideration:{" "}
                        </TableCell>
                        <TableCell align="left">
                          {
                            UnlistedOrderById.data.orderDetails
                              .totalConsideration
                          }
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.yield ? (
                      <TableRow
                        key={TextId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Yield:{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails.yield}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.status ? (
                      <TableRow
                        key={UnlistedOrderById.data.orderDetails.status}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Order Status :{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails.status}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.productName ? (
                      <TableRow
                        key={UnlistedOrderById.data.orderDetails.productName}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Product Name :{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails.productName}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                    {UnlistedOrderById?.data?.orderDetails?.brokerBankName ? (
                      <TableRow
                        key={UnlistedOrderById.data.orderDetails.brokerBankName}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Broker Bank Name:{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails?.brokerBankName}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                    {UnlistedOrderById?.data?.orderDetails?.brokerAccountNo ? (
                      <TableRow
                        key={
                          UnlistedOrderById.data.orderDetails.brokerAccountNo
                        }
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Broker Account No:{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails?.brokerAccountNo}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                    {UnlistedOrderById?.data?.orderDetails?.category ? (
                      <TableRow
                        key={UnlistedOrderById.data.orderDetails.category}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Category{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails?.category}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}

                    {UnlistedOrderById?.data?.orderDetails?.tenure ? (
                      <TableRow
                        key={UnlistedOrderById.data.orderDetails.tenure}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {" "}
                          Tenure{" "}
                        </TableCell>
                        <TableCell align="left">
                          {UnlistedOrderById.data.orderDetails?.tenure}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Start of AIF */}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.uploadDocument?.map((item: any) =>
                  item.label === "Upload Form" ? (
                    <>
                      <Button
                        component="label"
                        size="small"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        sx={{ mb: 1 }}
                      >
                        select {item.label}
                        <input
                          hidden
                          type="file"
                          onChange={(event: any) =>
                            setUploadForm(event.target.files[0])
                          }
                        />
                      </Button>
                      <Typography sx={{ fontSize: 12 }}>
                        {uploadForm?.name}
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )
                )}
              <hr style={{ visibility: "hidden" }} />
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Acknowledgement Reference No" ? (
                    <>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        sx={{ m: "0px 12px 0px 12px" }}
                        label={item.label}
                        variant="outlined"
                        onChange={(e) =>
                          setAcknowledgementReferenceNo(e.target.value)
                        }
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Form Submitted Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Form Submitted Date"
                          inputFormat="DD/MM/YYYY"
                          value={formSubmittedDate}
                          disableFuture
                          onChange={(newValue: any) => {
                            setFormSubmittedDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Remark For Form Processing" ? (
                    <>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        sx={{ m: "0px 12px 0px 12px" }}
                        label={item.label}
                        variant="outlined"
                        onChange={(e) => setRemarkForm(e.target.value)}
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Account Open Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Account Open Date"
                          inputFormat="DD/MM/YYYY"
                          value={accountOpenDate}
                          disableFuture
                          onChange={(newValue: any) => {
                            setAccountOpenDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              error={errors.orderDate ? true : false}
                              helperText={
                                errors.orderDate === undefined
                                  ? ""
                                  : errors.orderDate.message + ""
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Payment Reference No" ? (
                    <>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        sx={{ m: "0px 12px 0px 12px" }}
                        label={item.label}
                        variant="outlined"
                        onChange={(e: any) =>
                          setPaymentReferanceNo(e.target.value)
                        }
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Payment Transfer Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Payment Transfer Date"
                          inputFormat="DD/MM/YYYY"
                          value={paymentTransDate}
                          disableFuture
                          onChange={(newValue: any) => {
                            setPaymentTransDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Portfolio Activation Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Portfolio Activation Date"
                          inputFormat="DD/MM/YYYY"
                          value={portFolioActivationDate}
                          disableFuture
                          onChange={(newValue: any) => {
                            setPortFolioActivationDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              error={errors.orderDate ? true : false}
                              helperText={
                                errors.orderDate === undefined
                                  ? ""
                                  : errors.orderDate.message + ""
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.uploadDocument?.map((item: any) =>
                  item.label === "Portfolio Statement" ? (
                    <>
                      <Button
                        component="label"
                        size="small"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        sx={{ mb: 1 }}
                      >
                        select {item.label}
                        <input
                          hidden
                          type="file"
                          onChange={(event: any) =>
                            setPortfolioStatement(event.target.files[0])
                          }
                        />
                      </Button>
                      <Typography sx={{ fontSize: 12 }}>
                        {portfolioStatement?.name}
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )
                )}

              {/* End Of AIF */}

              {/* Start of PMS same as AIF */}

              {/* End Of PMS  same as AIF*/}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Payment Acknowledgement" ? (
                    <>
                      <TextField
                        sx={{ m: "0px 12px 0px 12px" }}
                        id="outlined-basic"
                        size="small"
                        label={item.label}
                        variant="outlined"
                        onChange={(e: any) =>
                          setPaymentReferanceNo(e.target.value)
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Payment Initiator Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Payment Initiator Date"
                          inputFormat="DD/MM/YYYY"
                          value={paymentInitiatorDate}
                          disableFuture
                          onChange={(newValue: any) => {
                            setPaymentInitiatorDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Payment Confirmation Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Payment Confirmation Date"
                          inputFormat="DD/MM/YYYY"
                          value={paymentConfirmDate}
                          disableFuture
                          onChange={(newValue: any) => {
                            setPaymentConfirmDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Stock Transfer Number" ? (
                    <>
                      <TextField
                        id="outlined-basic"
                        sx={{ m: "0px 12px 0px 12px" }}
                        size="small"
                        label={item.label}
                        variant="outlined"
                        onChange={(e) => setStockReferanceNo(e.target.value)}
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Stock Transfer Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Stock Transfer Date"
                          inputFormat="DD/MM/YYYY"
                          value={orderDate}
                          disableFuture
                          onChange={(newValue: any) => {
                            seOrderDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              error={errors.orderDate ? true : false}
                              helperText={
                                errors.orderDate === undefined
                                  ? ""
                                  : errors.orderDate.message + ""
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}

              {/* Start of RS */}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.uploadDocument?.map((item: any) =>
                  item.label === "Cancel Cheque" ? (
                    <>
                      <Button
                        component="label"
                        size="small"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        sx={{ mb: 1 }}
                      >
                        select {item.label}
                        <input
                          hidden
                          type="file"
                          onChange={(event: any) =>
                            setCancelCheque(event.target.files[0])
                          }
                        />
                      </Button>
                      <Typography sx={{ fontSize: 12 }}>
                        {cancelCheque?.name}
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )
                )}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Document Verification Remark" ? (
                    <>
                      <TextField
                        id="outlined-basic"
                        sx={{ m: "0px 12px 0px 12px" }}
                        size="small"
                        label={item.label}
                        variant="outlined"
                        onChange={(e) =>
                          setDocumentVerificationRemark(e.target.value)
                        }
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Document Verification Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Document Verification Date"
                          inputFormat="DD/MM/YYYY"
                          value={documentVerificationDate}
                          disableFuture
                          onChange={(newValue: any) => {
                            setDocumentVerificationDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Client Verification Call Remark" ? (
                    <>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        sx={{ m: "0px 12px 0px 12px" }}
                        label={item.label}
                        variant="outlined"
                        onChange={(e) =>
                          setClientVerificationCallRemark(e.target.value)
                        }
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Client Verification Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Client Verification Date"
                          inputFormat="DD/MM/YYYY"
                          value={clientVerificationDate}
                          disableFuture
                          onChange={(newValue: any) => {
                            setClientVerificationDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Subscription Activation Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Subscription Activation Date"
                          inputFormat="DD/MM/YYYY"
                          value={subscriptionActivationDate}
                          disableFuture
                          onChange={(newValue: any) => {
                            setSubscriptionActivationDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}

              {/* End of RS */}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Application No" ? (
                    <>
                      <TextField
                        id="outlined-basic"
                        sx={{ m: "0px 12px 0px 12px" }}
                        size="small"
                        label={item.label}
                        variant="outlined"
                        onChange={(e) => setNcdAppNo(e.target.value)}
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Application Submission Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Application Submission Date"
                          inputFormat="DD/MM/YYYY"
                          value={ncdAppSubmissionDate}
                          disableFuture
                          onChange={(newValue: any) => {
                            setNcdAppSubmissionDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Bidding Id" ? (
                    <>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        sx={{ m: "0px 12px 0px 12px" }}
                        label={item.label}
                        variant="outlined"
                        onChange={(e) => setBiddingId(e.target.value)}
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Bidding Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Bidding Date"
                          inputFormat="DD/MM/YYYY"
                          disableFuture
                          value={biddingDate}
                          onChange={(newValue: any) => {
                            setBiddingDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Allotment Units" ? (
                    <>
                      <TextField
                        type="number"
                        id="outlined-basic"
                        sx={{ m: "0px 12px 0px 12px" }}
                        size="small"
                        label={item.label}
                        variant="outlined"
                        onChange={(e) => setAllotmentUnits(e.target.value)}
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Total Investment Amount" ? (
                    <>
                      <TextField
                        type="number"
                        id="outlined-basic"
                        sx={{ m: "0px 12px 0px 12px" }}
                        size="small"
                        label={item.label}
                        variant="outlined"
                        onChange={(e) =>
                          setTotalInvestmentAmount(e.target.value)
                        }
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}

              {/* Loan */}

              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Loan Acknowledge Ref No" ? (
                    <>
                      <TextField
                        id="outlined-basic"
                        sx={{ m: "0px 12px 0px 12px" }}
                        size="small"
                        label={item.label}
                        variant="outlined"
                        onChange={(e) => setFormAckNo(e.target.value)}
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Loan Verification Remark" ? (
                    <>
                      <TextField
                        id="outlined-basic"
                        sx={{ m: "0px 12px 0px 12px" }}
                        size="small"
                        label={item.label}
                        variant="outlined"
                        onChange={(e) =>
                          setLoanVerificationRemark(e.target.value)
                        }
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Loan Verification Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label={item.label}
                          inputFormat="DD/MM/YYYY"
                          disableFuture
                          value={biddingDate}
                          onChange={(newValue: any) => {
                            setLoanVerificationDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Loan Form Processing Remark" ? (
                    <>
                      <TextField
                        id="outlined-basic"
                        sx={{ m: "0px 12px 0px 12px" }}
                        size="small"
                        label={item.label}
                        variant="outlined"
                        onChange={(e) =>
                          setLoanFormProcessingRemark(e.target.value)
                        }
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Loan Form Processing Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label={item.label}
                          inputFormat="DD/MM/YYYY"
                          disableFuture
                          value={biddingDate}
                          onChange={(newValue: any) => {
                            setLoanFormProcessingDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}
              <Box mt={3} />
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Loan Approval Amount" ? (
                    <>
                      <TextField
                        type="number"
                        id="outlined-basic"
                        sx={{ m: "0px 12px 0px 12px" }}
                        size="small"
                        label={item.label}
                        variant="outlined"
                        onChange={(e) => setLoanSanctionedAmt(e.target.value)}
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Loan Approval Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label={item.label}
                          inputFormat="DD/MM/YYYY"
                          disableFuture
                          value={biddingDate}
                          onChange={(newValue: any) => {
                            setLoanSanctionedDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Loan Disburement Amount" ? (
                    <>
                      <TextField
                        type="number"
                        id="outlined-basic"
                        sx={{ m: "0px 12px 0px 12px" }}
                        size="small"
                        label={item.label}
                        variant="outlined"
                        onChange={(e) => setLoanDisbursementAmt(e.target.value)}
                        error={StockReferanceNo ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                )}
              {UnlistedOrderById &&
                UnlistedOrderById.data?.textField.map((item: any) =>
                  item.label === "Loan Disbursement Date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label={item.label}
                          inputFormat="DD/MM/YYYY"
                          disableFuture
                          value={biddingDate}
                          onChange={(newValue: any) => {
                            setLoanDisbursementDate(newValue.toJSON());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <></>
                  )
                )}
              {/* Submit Button */}
              <Grid
                container
                rowSpacing={1}
                columnSpacing={1}
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                {UnlistedOrderById &&
                  UnlistedOrderById.data?.submitButton?.map(
                    (inputData: any) => (
                      <Grid item sx={{ m: 1 }}>
                        <Button
                          variant="contained"
                          size="small"
                          value={inputData.value}
                          onClick={() => {
                            setPortfolioStatement(null);
                            orderWorkFlow(inputData.value);
                          }}
                          placeholder={inputData.label}
                          disabled={showCircularProgress}
                        >
                          {showCircularProgress ? (
                            <CircularProgress
                              color="secondary"
                              size={20}
                              thickness={5}
                            />
                          ) : (
                            ""
                          )}{" "}
                          {inputData.label}
                        </Button>
                      </Grid>
                    )
                  )}
              </Grid>
              {/* { UnlistedOrderById && UnlistedOrderById.data?.textField.map((item:any)=>(item.label==="Subscription Activation Date"?<>
            <TextField id="outlined-basic" size="small" label={item.label} variant="outlined" onChange={(e)=>setSubscriptionActivationDate(e.target.value)} error={StockReferanceNo?false:true}/>
            </>:<></>
            ))} */}

              {/* <Box sx={{ mt: 2, mb: 2, justifyContent:"center" }}>
            <Stack spacing={3} direction="row" sx={{justifyContent: "center"}} >
          { UnlistedOrderById.data && UnlistedOrderById.data.additionalDetails.files.map((item:any)=>(
            <Button variant='contained' color='primary' onClick={()=>fileDownload(item.value)}> Download { item.fileName } </Button>
          ))}
          { UnlistedOrderById.data && UnlistedOrderById.data.additionalDetails.files.map((item:any)=>(
            <Button variant='contained' color='primary' onClick={()=>{setViewDocumentOpen(true);
              viewDocument(item.value)}}> View { item.fileName } </Button>
          ))}


            { UnlistedOrderById.data.inputFields.map((item:any)=>(item.labelName==="Stock Transfer"?<>
            <TextField id="outlined-basic" size="small" label={item.labelName} variant="outlined" onChange={(e)=>setStockReferanceNo(e.target.value)} error={StockReferanceNo?false:true}/>
            </>:<></>
            ))}
            { UnlistedOrderById.data.inputFields.map((item:any)=>(item.labelName==="Form Submitted Reason"?<>
            <TextField id="outlined-basic" size="small" label={item.labelName} variant="outlined" onChange={(e)=>setFormSubmitReject(e.target.value)} error={StockReferanceNo?false:true}/>
            </>:<></>
            ))}
            { UnlistedOrderById.data.inputFields.map((item:any)=>(item.labelName==="Form Process Reason"?<>
            <TextField id="outlined-basic" size="small" label={item.labelName} variant="outlined" onChange={(e)=>setFormProcessReject(e.target.value)} error={StockReferanceNo?false:true}/>
            </>:<></>
            ))}
            { UnlistedOrderById.data.inputFields.map((item:any)=>(item.labelName==="Payment clearance"?<>
            <TextField id="outlined-basic" size="small" label={item.labelName} variant="outlined" onChange={(e)=>setPaymentRefNo(e.target.value)} error={StockReferanceNo?false:true}/>
            </>:<></>
            ))}
            { UnlistedOrderById.data.inputFields.map((item:any)=>(item.labelName==="Portfolio Activation Reason"?<>
            <TextField id="outlined-basic" size="small" label={item.labelName} variant="outlined" onChange={(e)=>setPortfolioActivationReject(e.target.value)} error={StockReferanceNo?false:true}/>
            </>:<></>
            ))}
            
            { UnlistedOrderById.data.inputFields.map((item:any)=>(item.labelName==="Stock Transfer Date"?<>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Stock Transfer Date"
                inputFormat="DD/MM/YYYY"
                value={orderDate}
                onChange={(newValue: any) => {
                  seOrderDate(newValue.toJSON());
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    error={errors.orderDate ? true : false}
                    helperText={
                      errors.orderDate === undefined
                        ? ""
                        : errors.orderDate.message + ""
                    }
                  />
                )}
              />
            </LocalizationProvider>
            </>:<></>
            ))}
            { UnlistedOrderById.data.inputFields.map((item:any)=>(item.labelName==="Payment Initiator Date"?<>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Payment Initiator Date"
                inputFormat="DD/MM/YYYY"
                value={paymentInitiatorDate}
                onChange={(newValue: any) => {
                  setPaymentInitiatorDate(newValue.toJSON());
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            </>:<></>
            ))}
            { UnlistedOrderById.data.inputFields.map((item:any)=>(item.labelName==="Payment Confirm Date"?<>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Payment Confirm Date"
                inputFormat="DD/MM/YYYY"
                value={paymentConfirmDate}
                onChange={(newValue: any) => {
                  setPaymentConfirmDate(newValue.toJSON());
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            </>:<></>
            ))}

            { UnlistedOrderById.data.inputFields.map((item:any)=>(item.labelName==="Ncd Bidding"?<>
            <TextField id="outlined-basic" size="small" label={item.labelName} variant="outlined" onChange={(e)=>setBiddingId(e.target.value)} error={StockReferanceNo?false:true}/>
            </>:<></>  
            ))}

            { UnlistedOrderById.data.actions.statusFlowMaps?.map((item:any)=>item.action==="RO"?<>
            <TextField id="outlined-basic" size="small" label={item.actionDescription} variant="outlined" onChange={(e)=>setRejectedReason(e.target.value)} error={StockReferanceNo?false:true}/>
            </>:<></>)}

            { UnlistedOrderById.data.actions.statusFlowMaps?.map((item:any)=>item.action==="DVR"?<>
            <TextField id="outlined-basic" size="small" label={item.actionDescription} variant="outlined" onChange={(e)=>setDocumentReject(e.target.value)} error={documentReject?false:true}/>
            </>:<></>)}

            { UnlistedOrderById.data.actions.statusFlowMaps?.map((item:any)=>item.action==="PI"?<>
            <TextField id="outlined-basic" size="small" label={item.actionDescription} variant="outlined" onChange={(e)=>setPaymentReject(e.target.value)} error={paymentReject?false:true}/>
            </>:<></>)}

            { UnlistedOrderById.data.actions.statusFlowMaps?.map((item:any)=>item.action==="CVCF"?<>
            <TextField id="outlined-basic" size="small" label={item.actionDescription} variant="outlined" onChange={(e)=>setClientVerificationReject(e.target.value)} error={clientVerificationReject?false:true}/>
            </>:<></>)}

            { UnlistedOrderById.data.actions.statusFlowMaps?.map((item:any)=>item.action==="PINFO"?<>
            <TextField id="outlined-basic" size="small" label={item.actionDescription} variant="outlined" onChange={(e)=>setPaymentTransferNo(e.target.value)} error={paymentTransferNo?false:true}/>
            </>:<></>)}

            { UnlistedOrderById.data.additionalDetails.file==="Deal Slip"?<>
            <Button id="outlined-basic" variant="contained" color={'primary'} onClick={downloadDealSheet}> Download {UnlistedOrderById.data.additionalDetails.file} </Button>
            </>:<></>}
            {/* { UnlistedOrderById.data.additionalDetails.file==="Deal Slip"?<>
            <Button id="outlined-basic" variant="contained" color={'primary'} onClick={()=>{setViewDocumentOpen(true);
              viewDocument(UnlistedOrderById.data.additionalDetails.file)}} > View {UnlistedOrderById.data.additionalDetails.file} </Button>
            </>:<></>} 
           
            { UnlistedOrderById.data && UnlistedOrderById.data.actions.statusFlowMaps===null?<></>:
              UnlistedOrderById.data.actions.statusFlowMaps.map((item:any)=>(
              <Button 
                disabled={ item.movingStepSequence===null?true:false}
                variant='contained' 
                onClick={()=> orderWorkFlow(item.movingStepSequence) } >Submit { item.actionDescription }</Button>
              ))
            } 

            <Button variant='contained' onClick={()=>setEditDetailsModel(true)} disabled={!UnlistedOrderById.data.orderDetails.isUpdatable}> <EditIcon/> Edit Order </Button>

            </Stack>
            </Box> */}
              <Box marginBottom={5}>
                <Stack spacing={3} direction="row">
                  {UnlistedOrderById.data?.userSnapshot
                    ?.previousStepSequence ? (
                    <Button
                      color="inherit"
                      variant="contained"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                  ) : (
                    <></>
                  )}

                  {/* <Box sx={{ flex: '1 1 auto' }} /> */}
                  {UnlistedOrderById.data?.userSnapshot?.nextStepSequence ? (
                    <Button
                      variant="contained"
                      onClick={() => {
                        orderWorkFlow(
                          UnlistedOrderById.data?.userSnapshot?.nextStepSequence
                        );
                        setBackbtn(
                          UnlistedOrderById.data?.userSnapshot?.nextStepSequence
                        );
                      }}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Box>
            </React.Fragment>
          )}
        </Box>
      )}

      {/* <Modal
  open={EditDetailsModel}
  onClose={()=>setEditDetailsModel(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Edit Order
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <Grid container gap={2}>
          <Grid xs={12} md={12}>Distributor Name : {UnlistedOrderById && UnlistedOrderById?.data?.orderDetails?.distributorName}</Grid>
          <Grid xs={12} md={12}>Product Type: {UnlistedOrderById && UnlistedOrderById?.data?.orderDetails?.productType}</Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container gap={2}>
            <Grid xs={12} md={12} >
              <TextField 
                id="outlined-basic" 
                size="small" 
                label="Quantity" 
                variant="outlined" 
                error={!!errors.quantity }
                helperText={errors.quantity && errors.quantity.message+""}
                defaultValue={UnlistedOrderById && UnlistedOrderById?.data?.orderDetails?.quantity}
                fullWidth
                {...register("quantity",registerOptions.quantity)}
                />
            </Grid>
            <Grid xs={12} md={12}>
              <TextField 
                id="outlined-basic" 
                size="small" 
                label="User Rate" 
                defaultValue={UnlistedOrderById && UnlistedOrderById?.data?.orderDetails?.userRate}
                variant="outlined" 
                fullWidth
                error={!!errors.userRate }
                helperText={errors.userRate && errors.userRate.message+""}
                {...register("userRate",registerOptions.userRate)}
               />
            </Grid>
            
            <Grid xs={12} md={12}>
              <TextField 
                id="outlined-basic" 
                size="small" 
                label="paymentRefNO" 
                defaultValue={UnlistedOrderById && UnlistedOrderById?.data?.orderDetails?.paymentRefNO}
                variant="outlined" 
                fullWidth
                error={!!errors.paymentRefNO }
                helperText={errors.paymentRefNO && errors.paymentRefNO.message+""}
                {...register("paymentRefNO",registerOptions.paymentRefNO)}
               />
            </Grid> 
            <Grid xs={12} md={12}>Total TCS : { tcsValue }</Grid>
            <Grid xs={12} md={12}>Total Value : { totalValue }</Grid>
          </Grid>
            <Button variant='contained' type="submit" disabled={showCircularProgress}>
            {showCircularProgress?(
              <CircularProgress
                color="secondary"
                size={20}
                thickness={5}
              />
            ) : (
              ""
            )}{" "}
            Update Order</Button>
        </form>
      </Grid>
    </Typography>
  </Box>
</Modal>   */}
      <Modal
        keepMounted
        open={viewDocumentOpen}
        onClose={() => setViewDocumentOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          {
            !!clientFile ? (
              clientFile.includes(".pdf") ? (
                <div
                  className="rpv-core__viewer"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    display: "flex",
                    flexDirection: "column",
                    height: "600px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      backgroundColor: "#eeeeee",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      justifyContent: "center",
                      padding: "4px",
                    }}
                  >
                    <ZoomOutButton />
                    <ZoomPopover />
                    <ZoomInButton />
                    <Tooltip title="Download">
                      <IconButton>
                        <CloudDownloadIcon
                          onClick={() => downloadDocument(clientFileDocId)}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      overflow: "hidden",
                    }}
                  >
                    {!!clientFile ? (
                      <Viewer
                        fileUrl={clientFile}
                        plugins={[zoomPluginInstance]}
                      />
                    ) : (
                      " No Data Found"
                    )}
                  </div>
                </div>
              ) : (
                <img
                  src={clientFile}
                  alt="image"
                  height={"50%"}
                  width={"auto"}
                />
              )
            ) : (
              ""
            )
            // <Typography variant="h6" textAlign={"center"} sx={{mt:"15%"}}>No Data Found</Typography>
          }
        </Box>
      </Modal>
    </Box>
  );
};

export default withParam(NewOrderWorkFlow);
