import React, { useState } from 'react'
import { useQuery } from "react-query";
import { getAllNCD, getNcdById, listOfManufactureNDistributorCompanies, GetListOfUserByCompanyMasterCodeAndUserTypeCode, GetAllClient } from '../../api/Api';
import { Autocomplete, Box, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import NcdTableHeadData from '../Products/NCD/NcdTableHeadData';

const OrderWindowForNCD = () => {

    const [ncdId, setNcdId] = useState<any>();
    const { data } = useQuery("getNdcData", getAllNCD)
    const [ncdData, setNcdData] = useState<any>();
    const [companyMasterCode, setCompanyMasterCode] = React.useState();
    const [companyTypeCode, setCompanyTypeCode] = React.useState();
    const [distributorCode, setDistributorCode] = React.useState("");
    const [distributorUserCode, setDistributorUserCode] = React.useState("")

    let ongoing: any = {}
    data && data?.data?.ncdDTOS.map((ncd: any, index: any) => {
        const currentDate = new Date();
        const openDate = new Date(ncd.openDate);
        const closeDate = new Date(ncd.closeDate);
        if (openDate < currentDate && closeDate > currentDate) {
            console.log("Lies between", ncd)
            ongoing[index] = ncd
        }
    })

    const { data: ncd, refetch } = useQuery(["getNcdDetail", ncdId], () => getNcdById(ncdId), { enabled:!!ncdId, onSuccess: (data: any) => { setNcdData(data.data.ncdDTOS[0]) } })

    const { data: distributorCompanyList } = useQuery(
        "ManufactureNDistributorCompanie",
        () => listOfManufactureNDistributorCompanies("D,E"),
        {
            enabled: sessionStorage.getItem("Profile") === "B" || sessionStorage.getItem("Profile") === "E",
        }
    );

    const { data: UserProfilesByCompanyMasterCode } = useQuery(
        ["userProfileByCompany", companyMasterCode, companyTypeCode],
        () =>
            GetListOfUserByCompanyMasterCodeAndUserTypeCode(companyTypeCode === "S" ? "E" : companyTypeCode, companyMasterCode),
        {
            enabled: !!companyMasterCode,
        }
    );

    return (
        <>
            {data && Object.entries(ongoing).map((ongoingData: any) => (ongoingData[1])) ? (
                <Autocomplete
                    sx={{ width: 500 }}
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    options={
                        data && Object.entries(ongoing).map((ongoingData: any) => (ongoingData[1])).map((option: any) => option)
                    }
                    getOptionLabel={(options: any) => options?.ncdName}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                        >
                            <img
                                loading="lazy"
                                width="20"
                                src={option?.amcInfoDTO?.amcLogo}
                                srcSet={option?.amcInfoDTO?.amcLogo}
                                alt=""
                            />
                            {option?.ncdName}
                        </Box>
                    )}
                    onChange={(options: any, newValue) => {
                        setNcdId(newValue.ncdId || "");
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Select NCD" size='small' />
                    )}
                />
            ) : (
                <Typography variant="subtitle1" textAlign={"center"} sx={{ mt: 2 }}>
                    <CircularProgress size={15} sx={{ mr: 1 }} />
                    Please Wait We're Fetching NCD Schemes For You...{" "}
                </Typography>
            )}
            {ncdData ?
                <Grid container spacing={2} textAlign={"center"} sx={{ mb: 1, mt: 1 }}>
                    <Grid container spacing={2} textAlign={"center"} sx={{ mb: 1 }}>
                        <Grid item xs={12} sm={9} md={6} lg={3}>
                            <img
                                src={ncdData.ncdCompanyLogo}
                                width={150}
                                alt="AMC logo"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={8}>
                            <Typography variant="h5" fontWeight={"bold"}>
                                {ncdData.ncdName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} textAlign={"center"} sx={{ mb: 1 }}>
                        <Grid item xs={12} sm={9} md={6} lg={3}>
                            <Typography variant="subtitle1">
                                Issue Size
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                {ncdData.issueSize} Cr
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={6} lg={3}>
                            <Typography variant="subtitle1">
                                Issue Price
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                {ncdData.issuePrice}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={6} lg={3}>
                            <Typography variant="subtitle1">
                                Lot Size
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                {ncdData.minimumLotSize}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={6} lg={3}>
                            <Typography variant="subtitle1">
                                Minimum Investment Amount
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                {ncdData.minimumInvestment}
                            </Typography>
                        </Grid>
                    </Grid>


                    { distributorCompanyList && (sessionStorage.getItem("Profile") === "B" || sessionStorage.getItem("Profile") === "E") ? (
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={
                                    distributorCompanyList &&
                                    distributorCompanyList.data.companies.map(
                                        (options: any) => options
                                    )
                                }
                                getOptionLabel={(options: any) => options.companyName + " - " + options.companyTypeCode}
                                fullWidth
                                onChange={(options: any, newValue) => {
                                    setCompanyMasterCode(newValue ? newValue.companyMasterCode : "");
                                    setCompanyTypeCode(newValue ? newValue.companyTypeCode : "");
                                }}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        label="Select Distributor Company"
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}

                    {UserProfilesByCompanyMasterCode && (sessionStorage.getItem("Profile") === "B" || sessionStorage.getItem("Profile") === "E") ?
                        <>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={UserProfilesByCompanyMasterCode ? UserProfilesByCompanyMasterCode.data.userProfile.map((options: any) => options) : []}
                                    getOptionLabel={(options: any) => options?.userCode + "- " + options?.firstName + " " + options?.lastName}
                                    fullWidth
                                    onChange={(options: any, newValue) => {
                                        setDistributorCode(newValue ? newValue.employeeCode : "");
                                        setDistributorUserCode(newValue ? newValue.userCode : "");
                                    }}
                                    renderInput={(params: any) => (
                                        <TextField {...params} label="Select Distributor User" size='small' />
                                    )}
                                />
                            </Grid>
                        </>
                        : <></>}



                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 1150 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                <TableCell align="left">Series Name</TableCell>
                                <TableCell align="left">Interest Payout Frequency</TableCell>
                                <TableCell align="left">Coupon Rate (%)</TableCell>
                                <TableCell align="left">Tenure (Months)</TableCell>
                                <TableCell align="left" sx={{ minWidth: 150 }}>Select Date</TableCell>
                                <TableCell align="left" sx={{ minWidth: 300 }}>Select Client</TableCell>
                                <TableCell align="left" sx={{ minWidth: 120 }}></TableCell>
                                <TableCell align="left" sx={{ minWidth: 250 }}>Application Type</TableCell>
                                <TableCell align="left" sx={{ minWidth: 150 }}>Quantity</TableCell>
                                <TableCell align="left">Issue Price</TableCell>
                                <TableCell align="left">Total</TableCell>
                                <TableCell align="left" sx={{ minWidth: 100 }}>Revenue</TableCell>
                                <TableCell align="left" sx={{ minWidth: 30 }}>Form Acknowledgement Number</TableCell>
                                <TableCell align="left" sx={{ minWidth: 30 }}>Form Upload</TableCell>
                                <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ncdData && ncdData.ncdSeriesDTO?.map((ncd: any) => (
                                    <NcdTableHeadData
                                        data={ncd}
                                        minimumInvestment={ncdData.minimumInvestment}
                                        minimumLotSize={ncdData.minimumLotSize}
                                        issuePrice={ncdData.issuePrice} 
                                        revenueSharing={ncdData.revenueSharing}
                                        companyMasterCode={companyMasterCode}
                                        companyTypeCode={companyTypeCode}
                                        distributorCode={distributorCode}
                                        distributorUserCode={distributorUserCode}
                                        productMasterCode={ncd.productMasterCode}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                : <></>}
        </>
    )
}

export default OrderWindowForNCD