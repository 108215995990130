import { Container , Box, Typography, Button, Modal, TextField } from '@mui/material'
import axios from 'axios';
import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { withParam } from '../utils/Router.Helper'
import { getAllUnlistedShareById } from '../api/Api';

const UnlistedDetails = (props: any) => {
  // console.log("Props",props.params.productMasterCode);
  const {data, isLoading, error, isError} = useQuery("UnlistedShare",()=>getAllUnlistedShareById(props.params.productMasterCode));
  console.log("data",data)
  
  const [unlistedModel, setUnlistedModel] = useState(false)


  function updateUnlistedShareModel(status:any){
    status==="open"?
    setUnlistedModel(true)
    : status==="close" ? setUnlistedModel(false) : <></>
}

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  "& .MuiTextField-root": { m: 1 },
};

const onSubmit = async (submitedData: any) => {
  const newSubmitedData = {
    ...submitedData,
            productTypeCode:11,          
            minAmount:0,
            maxAmount:0,
            companyMasterCode:1,
            productMasterCode:data && data.data.unlisteds[0].productMasterCode
  }
  console.log(newSubmitedData)
  updateUnlistedShareModel("close")
  // mutate(newSubmitedData);
  const { data: response } = await axios.put("unlisted",newSubmitedData)
  return response.data;
}

const {   mutate } = useMutation(onSubmit, {
  onSuccess: data => {
     console.log(data);
     const message = "success"
     alert(message)
},
 onError: () => {
      alert("there was an error")
},
});


const resolver: any = async (values: any) => {
  return {
      values: !values.quantity ? {} : values,
      errors: !values.quantity ? {
          quantity: {
            type: "required",
            message: "This is required.",
          },
        }:{},
  }
}

const { register, handleSubmit, formState} = useForm({
  resolver:resolver
});


  return (
    <Container maxWidth={'xl'}>
        <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "5%" }}>
            <Typography variant='subtitle1'>
            description : {data && data.data.unlisteds[0].description}
            </Typography>
            <Typography variant='subtitle1'>
            brokerRate :{data && data.data.unlisteds[0].brokerRate}
            </Typography>
                <Typography variant='subtitle1'>
                distributorSaleRate : {data && data.data.unlisteds[0].distributorSaleRate}
                  </Typography>
                <Typography variant='subtitle1'>
                manufactureSaleRate : {data && data.data.unlisteds[0].manufactureSaleRate}
                  </Typography>
                <Typography variant='subtitle1'>
                productName : {data && data.data.unlisteds[0].productName}
                  </Typography>
                <Typography variant='subtitle1'>
                quantity : {data && data.data.unlisteds[0].quantity}
                  </Typography>

            <Button variant='contained'>
                Submit
            </Button>
            {sessionStorage.getItem("Profile")==="M"?
            <Button variant='contained' onClick={()=>updateUnlistedShareModel("open")}>
                Update
            </Button>
            :<></>
            }
            {/* <Button variant='contained' onClick={()=>updateUnlistedShareModel("open")}>
                Delete
            </Button> */}
        </Box>

        <Modal
         open={unlistedModel}
         onClose={()=>updateUnlistedShareModel("close")}
         >
            <Box sx={style}>
            <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Update Unlisted Share
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField 
            id="outlined-basic" 
            label="Quantity" 
            variant="outlined" 
            type="number"
            defaultValue={data && data.data.unlisteds[0].quantity}
            {...register("quantity",{valueAsNumber: true})}
            />
            <TextField 
            id="outlined-basic" 
            label="Manufacture Sale Rate" 
            variant="outlined" 
            defaultValue={data && data.data.unlisteds[0].manufactureSaleRate}
            {...register("manufactureSaleRate",{valueAsNumber: true})}
            />
            <TextField 
            id="outlined-basic" 
            label="Broker Rate" 
            variant="outlined" 
            defaultValue={data && data.data.unlisteds[0].brokerRate}
            {...register("brokerRate",{valueAsNumber: true})}
            />
            <TextField 
            id="outlined-basic" 
            label="Distributor Sale Rate" 
            variant="outlined" 
            defaultValue={data && data.data.unlisteds[0].distributorSaleRate}
            {...register("distributorSaleRate",{valueAsNumber: true})}
            />
            <TextField 
            id="outlined-basic" 
            label="Product Name" 
            variant="outlined" 
            defaultValue={data && data.data.unlisteds[0].productName}
            {...register("productName")}
            />
            <TextField 
            id="outlined-basic" 
            label="description" 
            variant="outlined" 
            defaultValue={data && data.data.unlisteds[0].description}
            {...register("description")}
            />
            <Button type="submit" variant="contained" >Submit</Button>
          </form>
            </Box>
        </Modal>
        
    </Container>
  )
}

export default withParam(UnlistedDetails)