import { Autocomplete, Box, Button, CircularProgress, Grid, IconButton, Modal, Switch, TextField, Typography } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { ResearchServiceCategory, ResearchServiceMonthCounts, ResearchServiceSegment } from '../../../Constants/ResearchServiceMonthCounts';
import { SAVE_RESEARCH_SERVICES, UPDATE_RESEARCH_SERVICES } from '../../../utils/API_Names';
import { getAllResearchServicesPlan } from '../../../api/Api';
import { useQuery } from 'react-query';
import { Container } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    "& .MuiTextField-root": { m: 1 },
};

const AddNewResearchServicesModel = (props: any) => {

    console.log("props:",props.data)
    const [ editData, setEditData ] =  useState(props.data);
    const [showCircularProgress, setshowCircularProgress] = useState(false);
    const [subscriptionPlanId, setSubscriptionPlanId] = useState();
    const [category, setCategory] = useState();
    const [planAmount, setPlanAmount] = useState(props.data?.planAmount);
    const [tenure, setTenure] = useState();
    const [segment, setSegment] = useState();

    const [isActive, setIsActive] = React.useState<any>(false)
    
    const { data } = useQuery(
        "GetResearchServicesPlan",
        getAllResearchServicesPlan
        );
        
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        mode:"all"
    });

    React.useEffect(() => {
        setEditData(props.data);
        setPlanAmount(props.data?.planAmount);
        setIsActive(props.data?.isActive);
        setValue("planAmount",props.data?.planAmount);
        setValue("category",props.data?.category);
        setValue("subscriptionPlan",props.data?.subscriptionPlan);
        setValue("month",props.data?.month);
        setValue("segment",props.data?.segment);
        return()=>{
            setEditData("");
        setPlanAmount(0);
        setIsActive("");
        }
    }, [props])
    

    const onSubmit = (submitData: any) => {
        console.log("data",submitData)
        if(!!!editData){
            axios.post(SAVE_RESEARCH_SERVICES, {
                ...submitData,
                researchServicesPlanId: subscriptionPlanId,
                category: category,
                tenure: tenure,
                segment: segment,
                revenue: null,
            }).then(() => toast.success("Research Services Plan Added Successfully!")).then(() => window.location.reload())
                // .then(() => { setshowCircularProgress(false); props.onClose(); reset() })
                .catch((err: any) => { setshowCircularProgress(false); toast.error(err.response.data ? err.response.data.status.message.title : "Error") })
        }else{   

            const UpdateData = {
                ...submitData,
                productMasterCode : props.data?.productMasterCode,
                researchServicesPlanId: !!subscriptionPlanId ? subscriptionPlanId : props.data?.researchServicesPlanId,
                category: !!category ? category : props.data?.category,
                tenure: !!tenure ? tenure : props.data?.tenure,
                segment: !!segment ? segment : props.data?.segment ,
                revenue: null,
                planAmount:!!planAmount ? planAmount : props.data?.planAmount,
                isActive:isActive
            }            
            axios.put(UPDATE_RESEARCH_SERVICES, UpdateData)
            .then(() => toast.success("Research Services Plan Added Successfully!"))
            .then(() => window.location.reload())
            // .then(() => { setshowCircularProgress(false); props.onClose(); reset() })
            .catch((err: any) => { setshowCircularProgress(false); toast.error(err.response.data ? err?.response?.data?.status?.message?.title : "Error") })
        } 

    }

    const handleIsActive = () => {
        setIsActive(!isActive);
      }

    const registerOptions = {
        category:{required: "Please Select Category"},
        subscriptionPlan:{required: "Please Select Plan"},
        month:{required: "Please Select Tenure"},
        planAmount: { required: "Please Enter Plan Amount",
        pattern: {
            value: /^[0-9]+$/,
            message: "Enter Plan Amount"
          },
        validate: (value: number) => {
            if (value <= 0) {
              return "Plan Amount must be greater than 0";
            }
        },
    },
        revenue: { required: "Please Enter Revenue" },
        segment: { required: "Please Select Segment" },
    }

    return (
        <>
            <Modal
                open={props.open}
                onClose={() => props.onClose()}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                <IconButton style={{ position: 'absolute',fontSize:"18px" ,top: "8px", right: "5px"}} onClick={() => props.onClose()}>
      <CloseIcon style={{fontSize:"18px"}} />
    </IconButton>
                    {!editData ?
                        <>
                            <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                                Add Research Services
                            </Typography>
                        </> :
                        <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                            Edit Research Services
                        </Typography>
                    }
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid style={{display: "flex", flexDirection:"column"}}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={ResearchServiceCategory.map((options: any) => options)}
                            defaultValue={props.data}
                            getOptionLabel={(options: any) => options.category}
                            sx={{ width: 267 }}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    label="Select Category"
                                    error={errors.category ? true : false}
                                    helperText={
                                        errors.category === undefined
                                        ? ""
                                        : errors.category.message + ""
                                    }
                                    {...register("category", registerOptions.category)}
                                />
                            )}
                            onChange={(options: any, newValue) => {
                                setCategory(newValue?.value);
                            }}
                        />

                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            defaultValue={props.data}
                            options={data && data.data.researchServicesPlanDTOs.map((options: any) => options)}
                            getOptionLabel={(options: any) => options.subscriptionPlan}
                            sx={{ width: 267 }}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    label="Select Subscription Plan"
                                    {...register("subscriptionPlan")}
                                    error={errors.subscriptionPlan ? true : false}
                                    helperText={
                                        errors.subscriptionPlan === undefined
                                            ? ""
                                            : errors.subscriptionPlan.message + ""
                                    }
                                    {...register("subscriptionPlan", registerOptions.subscriptionPlan)}
                                    />
                                    )}
                                    onChange={(options: any, newValue) => {
                                        setSubscriptionPlanId(newValue?.researchServicesPlanId);
                                    }}
                                    />

                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            defaultValue={props.data}
                            options={ResearchServiceMonthCounts.map((options: any) => options)}
                            getOptionLabel={(options: any) => options.month}
                            sx={{ width: 267 }}
                            renderInput={(params: any) => (
                                <TextField
                                {...params}
                                size="small"
                                label="Select Tenure"
                                {...register("month")}
                                error={errors.month ? true : false}
                                helperText={
                                    errors.month === undefined
                                    ? ""
                                    : errors.month.message + ""
                                }
                                {...register("month", registerOptions.month)}
                                />
                            )}
                            onChange={(options: any, newValue) => {
                                setTenure(newValue?.value);
                            }}
                        />

                        <TextField
                            id="outlined-basic"
                            label="Plan Amount"
                            variant="outlined"
                            size='small'
                            value={planAmount}
                            onChangeCapture={(e:any)=>setPlanAmount(e.target.value)}
                            error={errors.planAmount ? true : false}
                            helperText={!errors.planAmount ? "" : errors.planAmount.message + ""}
                            {...register("planAmount", registerOptions.planAmount)}
                        />

                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            defaultValue={props.data}
                            options={ResearchServiceSegment.map((options: any) => options)}
                            getOptionLabel={(options: any) => options.segment}
                            sx={{ width: 267 }}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    label="Select Segment"
                                    {...register("segment")}
                                    error={errors.segment ? true : false}
                                    helperText={
                                        errors.segment === undefined
                                            ? ""
                                            : errors.segment.message + ""
                                    }
                                    {...register("segment", registerOptions.segment)}
                                />
                            )}
                            onChange={(options: any, newValue) => {
                                setSegment(newValue?.value);
                            }}
                        />
                        </Grid>
                        { !!editData &&
                        <>
                        <Typography>
                            Status 
                        </Typography>
                        <Switch
                          checked={isActive}
                          onChange={handleIsActive}
                          inputProps={{ 'aria-label': 'controlled' }}
                          /> 
                          </>
                        }


                        <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button type="submit" variant="contained" sx={{ marginTop: '6%' }}> {showCircularProgress ? (
                            <CircularProgress
                            color="secondary"
                            size={20}
                            thickness={5}
                            />
                            ) : (
                                ""
                                )}{"  "} Submit
                        </Button>
                                </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </>
    )
}

export default AddNewResearchServicesModel