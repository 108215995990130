import { ThemeProvider } from '@emotion/react'
import { Box, Container, Grid, Tab, Tabs, Typography, createTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AnalyzerDashboard from './AnalyzerDashboard'
import AnalyzerOverview from './AnalyzerOverview'
import AnalyzerPerformance from './AnalyzerPerformance'
import AnalyzerAnalysis from './AnalyzerAnalysis'
import AnalyzerSuggested from './AnalyzerSuggested'
import "../../Components/PortfolioAnalyzer/portfolioanalysis.css"
import { getPortfolioOverview, getportfolioanalyzer } from '../../api/Api'
import { useLocation } from 'react-router-dom'
import { CommaFormater2 } from '../converter'
const defaultTheme = createTheme()


const PortfolioAnalysisDetails = (props:any) => {
    const location = useLocation();
    const panCard = location.state ? location.state.pan : null;
    const [selectedTab, setSelectedTab] = useState(1)
    const [usernamepan, setUsernamepan] = useState<any>(''); 
    const [currentValue, setCurrentValue] = useState(0)
   
    


    useEffect(() => {
      getpanemail()
    }, []);

    // const getpanemail = async () => {
    //     try {
    //       const response = await getportfolioanalyzer(panCard);
    //       console.log('Response:', response);
    //       if (response) {
    //         setUsernamepan(response);
    //         sessionStorage.setItem('casId', usernamepan?.data?.casId);
    //       } else {
    //         console.error('API response is empty or undefined.');
    //       }
    //     } catch (error) {
    //       console.error('Error:', error);
    //     }
    //   };
    // const getpanemail = async () => {
    //   try {
    //     const response = await getportfolioanalyzer(panCard).then((response) => { setUsernamepan(response);
    //        sessionStorage.setItem('casId', response.data.casId) }).then(() => getCurrentValue())
    //   } catch (error) {
    //     console.log('Error', error)
    //   }
    // }
    const getpanemail = async () => {
      try {
        const response = await getportfolioanalyzer(panCard);
        if (response) {
          setUsernamepan(response);
          sessionStorage.setItem('casId', response.data.casId);
          await getCurrentValue();
        } else {
          console.error('API response is empty or undefined.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

      const getCurrentValue= async()=>{
        try{
            const response = await getPortfolioOverview()
            console.log("msvfghjklhg",response)
            setCurrentValue(response.data.overAllCurrentValue)
        }
        catch (error) {
            console.log('error', error)
          }
      }
const handleTabChange = (event:any, newValue:any) => {
    setSelectedTab(newValue)
    window.scrollTo(0, 0)
  }
const numDifferentiation = (value:any) => {
    const val = Math.abs(value);
    if (val >= 10000000) {
      const formattedValue = (val / 10000000).toFixed(2);
      return CommaFormater2(formattedValue) + ' Cr';
    } else if (val >= 100000) {
      const formattedValue = (val / 100000).toFixed(2);
      return CommaFormater2(formattedValue) + ' Lakh';
    } else if (val >= 1000) {
      return CommaFormater2(val);
    } else {
      return val.toString();
    }
  };

  const activeTabStyle = {
    backgroundColor: '#032b5b',
    borderRadius: '10px 10px 0px 0px',
    color: '#fff',
    marginRight: 2,
    borderBottom: '0px solid transparent'
  }
  const inactiveTabStyle = {
    backgroundColor: '#d8d8d8',
    borderRadius: '10px 10px 0px 0px',
    marginRight: 2,
    borderBottom: '0px solid transparent'

  }
    const renderSelectedTabComponent = () => {
        switch (selectedTab) {
          case 1:
            return <AnalyzerDashboard />
          case 2:
            return <AnalyzerOverview></AnalyzerOverview>
          case 3:
            return <AnalyzerPerformance></AnalyzerPerformance>
          case 4:
            return <AnalyzerAnalysis></AnalyzerAnalysis>
          case 5:
            return <AnalyzerSuggested></AnalyzerSuggested>
          default:
            return null
        }
      }
      console.log("myproperdata",usernamepan)
      console.log("mystorageid",sessionStorage.getItem('casId'))
  return (
   
    <div>
        <ThemeProvider theme={defaultTheme}>
            <Container component='main' maxWidth='lg'>

                <Grid container columnSpacing={2} rowSpacing={2} sx={{ justifyContent: 'space-between' }}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Typography
                            variant="h5"
                            className="portfolio-helth-check-heading-top">
                            PORTFOLIO HEALTH CHECK
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <div className="name-nd-email-pportfolio-report">
                            <p className="portfolio-report-name">
                               {usernamepan.data?.casFileUserName}
                            </p>
                            <p className="portfolio-report-name-tag-pan-name">
                                PAN:{' '}
                                <span>
                                    {(usernamepan.data?.casFilePan)?.replace(/\w(?=\w{4})/g, '*')}
                                </span>{' '}
                                | EMAIL-ID: <span>
                                    {usernamepan.data?.casFileEmail}
                                    </span>
                            </p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2} rowSpacing={2} sx={{}}>
                    <Grid item xs={12} md={6} lg={4}>
                        <div className="portfolio-report-helth-check-col-main-div">
                            <p className="portfolio-helath-check-return-p">
                                CURRENT PORTFOLIO VALUE
                            </p>
                            <h3 className="portfolio-helath-check-return-amount-h3">
                                {numDifferentiation(currentValue)}
                            </h3>
                        </div>
                    </Grid>
                </Grid>
                <Box sx={{ justifyContent: 'center', display: 'flex', borderBottom: '3px solid #000'}}>
                    <Tabs
                        id=""
                        sx={{

                          justifyContent: 'center',
                          color:"#000"
                        }}
                        value={selectedTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab
                            label="DASHBOARD"
                            value={1}
                            sx={selectedTab === 1 ? activeTabStyle : inactiveTabStyle}
                        />
                        <Tab
                            label="OVERVIEW"
                            value={2}
                            sx={selectedTab === 2 ? activeTabStyle : inactiveTabStyle}
                        />
                        <Tab
                            label="PERFORMANCE"
                            value={3}
                            sx={selectedTab === 3 ? activeTabStyle : inactiveTabStyle}
                        />
                        <Tab
                            label="ANALYSIS"
                            value={4}
                            sx={selectedTab === 4 ? activeTabStyle : inactiveTabStyle}
                        />
                        <Tab
                            label="I4E MODEL PORTFOLIO"
                            value={5}
                            sx={selectedTab === 5 ? activeTabStyle : inactiveTabStyle}
                        />
                    </Tabs>
                </Box>
                <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                    {renderSelectedTabComponent()}
                </Typography>

            </Container>

        </ThemeProvider>
    </div>
  )
}

export default PortfolioAnalysisDetails