import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import EmployeeClientData from '../EmployeeDashBoard/EmployeeClientData';
import EmployeePayout from '../EmployeeDashBoard/EmployeePayout';
import EmployeeTransaction from '../EmployeeDashBoard/EmployeeTransaction';
// import EmployeeTransaction from '../EmployeeDashBoard/EmployeeTransaction';

export const TableGridForAllData = () => {
	const [value, setValue] = React.useState('1');

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
	  setValue(newValue);
	};
  return (
	<>
		<Box sx={{marginTop:"5%", width: '100%', typography: 'body1',bgcolor: 'background.paper' }}>
      <TabContext value={value}>
        <Box sx={{ bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Clients" value="1" />
            <Tab label="Transaction" value="2" />
            <Tab label="Payout" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <EmployeeClientData/>
        </TabPanel>
        <TabPanel value="2">
        <EmployeeTransaction/>
        </TabPanel>
        <TabPanel value="3">
          <EmployeePayout/>
        </TabPanel>
      </TabContext>
    </Box>
	</>
  )
}