import React from 'react'
import { Grid } from '@mui/material'
import { InformationBox } from './InformationBox';
import { useQuery } from 'react-query';
import { selfProfile } from '../../api/Api';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

export const InformationGrid = () => {

	const { data } = useQuery("UserProfile",selfProfile)
	const ClientCounts = useSelector((state: any) => state.TotalCountOfClient);

	const xs=12; // Small Screen 
	const sm=6;  
	const md=3;
	const lg=3;
  return (
		<Grid container columnSpacing={2} rowSpacing={2} >
			<Grid item xs={xs} sm={sm} md={md} lg={lg}>
				<Link to="/clients">
				<InformationBox title1="Total Clients" 
				Count={data ? ClientCounts:0} 
				/>
				</Link>
			</Grid>
			<Grid item xs={xs} sm={sm} md={md} lg={lg}>
				<InformationBox title2="AUM"
				Count={data && data.data.totalTransactionAmount?data.data.totalTransactionAmount:0} 
				/>
			</Grid>
			<Grid item xs={xs} sm={sm} md={md} lg={lg}>
			<Link to="/payoutDashboard">
				<InformationBox title3="Total Payout"
				Count={data && data.data.totalRevenue?data.data.totalRevenue:0} 
				/>
			</Link>
			</Grid>
			<Grid item xs={xs} sm={sm} md={md} lg={lg}>
			<InformationBox title4="Business Partner"
				Count={data && data.data.noOfBusinessPartner?data.data.noOfBusinessPartner:0} 
				/>
			</Grid>
		</Grid>
  )
}
