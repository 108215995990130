import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import React from 'react'
import BarChartMaturity from './BarChartMaturity';
import BarChartCall from './BarChartCall';
import { withParam } from '../utils/Router.Helper';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

const CashFlowTab = (props:any) => {

    console.log("props cashflow" , props);

    const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Maturity" {...a11yProps(0)} />
          {props.callPut == "Yes" ? <Tab label="Call" {...a11yProps(1)} /> : <></>}
          
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <BarChartMaturity isin={props.isin}/>
      </TabPanel>
      {
        props.callPut == "Yes" ? 
        <TabPanel value={value} index={1} >
        <BarChartCall isin={props.isin}/>
      </TabPanel>
      : <></>
      }
      
    </Box>
    </>
  )
}

export default withParam(CashFlowTab)