import React from 'react'
import { withState } from '../../utils/Router.Helper'
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import { PmsGetById, pmsgetAllList } from '../../api/Api'
import { useQuery } from 'react-query'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom'

const ComparePMSFundPage = (props:any) => {
	const compareData = props.state 

	  const { data:pmsList,isLoading, isFetching, isError} = useQuery(["PmsDetailsList"],()=>pmsgetAllList())

	  function createData(
		name: string,
		calories: number,
		fat: number,
		carbs: number,
		protein: number,
	  ) {
		return { name, calories, fat, carbs, protein };
	  }
	  
	  const rows = [
		createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
		createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
		createData('Eclair', 262, 16.0, 24, 6.0),
		createData('Cupcake', 305, 3.7, 67, 4.3),
		createData('Gingerbread', 356, 16.0, 49, 3.9),
	  ];

	  React.useEffect(() => {
      if(!!compareData && compareData.length!==0){
		    set_AMC_1_Data(compareData[0]?.schemeCode);
		    set_AMC_2_Data(compareData[1]?.schemeCode);
		    set_AMC_3_Data(compareData[2]?.schemeCode);
		    set_AMC_4_Data(compareData[3]?.schemeCode);
      }
	  }, [compareData])
	  

	  const [amc_1_Data, set_AMC_1_Data] = React.useState<any>("")
	  const [amc_2_Data, set_AMC_2_Data] = React.useState<any>("")
	  const [amc_3_Data, set_AMC_3_Data] = React.useState<any>("")
	  const [amc_4_Data, set_AMC_4_Data] = React.useState<any>("")

	  const { data:pmsOne} = useQuery(["PMSGetById1",amc_1_Data], () => PmsGetById(amc_1_Data),{
		enabled:!!amc_1_Data
	  });
	  const { data:pmsTwo} = useQuery(["PMSGetById2",amc_2_Data], () => PmsGetById(amc_2_Data),{
		enabled:!!amc_2_Data
	  });
	  const { data:pmsThree} = useQuery(["PMSGetById3",amc_3_Data], () => PmsGetById(amc_3_Data),{
		enabled:!!amc_3_Data
	  });
	  const { data:pmsFour} = useQuery(["PMSGetById4",amc_4_Data], () => PmsGetById(amc_4_Data),{
		enabled:!!amc_4_Data
	  });

  return (
	<>
		<Typography variant='h4' gutterBottom>Compare PMS Fund</Typography>
		<TableContainer component={Paper}>
      <Table sx={{ minWidth: 1550 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" width={300}>
			</TableCell>
            <TableCell align="left" width={300}>
			<Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={pmsList && pmsList.data.pmsDTOList.map((option: any) => option)}
                    getOptionLabel={(options: any) => options.schemeName}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                        loading="lazy"
                        width="20"
                        src={option?.amcInfoDTO?.amcLogo}
                        srcSet={option?.amcInfoDTO?.amcLogo}
                        alt=""
                        />
                        {option.schemeName} 
                      </Box>
                      )}
                    onChange={(options: any, newValue) => {
                      set_AMC_1_Data(newValue?.schemeCode);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Scheme" />
                    )}
                  />
			</TableCell>
            <TableCell align="left" width={300}>
			<Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={pmsList && pmsList.data.pmsDTOList.map((option: any) => option)}
                    getOptionLabel={(options: any) => options.schemeName}
					renderOption={(props, option) => (
						<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
						  <img
							loading="lazy"
							width="20"
							src={option?.amcInfoDTO?.amcLogo}
							srcSet={option?.amcInfoDTO?.amcLogo}
							alt=""
						  />
						  {option.schemeName} 
						</Box>
					  )}
					//   defaultValue={compareData[1]}
                    onChange={(options: any, newValue) => {
						set_AMC_2_Data(newValue?.schemeCode);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Scheme" />
                    )}
                  />
			</TableCell>
            <TableCell align="left" width={300}>
				<Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={pmsList && pmsList.data.pmsDTOList.map((option: any) => option)}
                    getOptionLabel={(options: any) => options.schemeName}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                        loading="lazy"
                        width="20"
                        src={option?.amcInfoDTO?.amcLogo}
                        srcSet={option?.amcInfoDTO?.amcLogo}
                        alt=""
                        />
                        {option.schemeName} 
                      </Box>
                      )}
                    // defaultValue={compareData[2]}
                    onChange={(options: any, newValue) => {
						set_AMC_3_Data(newValue?.schemeCode);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Scheme" />
                    )}
                  />
			</TableCell>
            <TableCell align="left" width={300}>
							<Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={pmsList && pmsList.data.pmsDTOList.map((option: any) => option)}
                    getOptionLabel={(options: any) => options.schemeName}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                        loading="lazy"
                        width="20"
                        src={option?.amcInfoDTO?.amcLogo}
                        srcSet={option?.amcInfoDTO?.amcLogo}
                        alt=""
                        />
                        {option.schemeName} 
                      </Box>
                      )}
                    // defaultValue={compareData[3]}
                    onChange={(options: any, newValue) => {
						set_AMC_4_Data(newValue?.schemeCode);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Scheme" />
                    )}
                  />
			</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell  scope="row"></TableCell>
              <TableCell align="left">
                <Link to={"/pmsScreener/"+( pmsOne && pmsOne.data.pmsDTOList[0]?.schemeCode)}>
                  {pmsOne && pmsOne.data.pmsDTOList[0]?.schemeCode && <Button variant="contained">Order Now</Button>}
                </Link>
              </TableCell>
              <TableCell align="left">
                <Link to={"/pmsScreener/"+( pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeCode)}>
                  {pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeCode && <Button variant="contained">Order Now</Button>}
                </Link>
              </TableCell>
              <TableCell align="left">
                <Link to={"/pmsScreener/"+( pmsThree && pmsThree.data.pmsDTOList[0]?.schemeCode)}>
                  {pmsThree && pmsThree.data.pmsDTOList[0]?.schemeCode && <Button variant="contained">Order Now</Button>}
                </Link>
              </TableCell>
              <TableCell align="left">
                <Link to={"/pmsScreener/"+( pmsFour && pmsFour.data.pmsDTOList[0]?.schemeCode)}>
                  {pmsFour && pmsFour.data.pmsDTOList[0]?.schemeCode && <Button variant="contained">Order Now</Button>}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">AMC Logo</TableCell>
              <TableCell align="left"><img src={pmsOne && pmsOne.data.pmsDTOList[0]?.amcInfoDTO?.amcLogo} width="auto" height={30} alt="PMS Logo"/></TableCell>
              <TableCell align="left"><img src={pmsTwo && pmsTwo.data.pmsDTOList[0]?.amcInfoDTO?.amcLogo}width="auto" height={30} alt="PMS Logo"/></TableCell>
              <TableCell align="left"><img src={pmsThree && pmsThree.data.pmsDTOList[0]?.amcInfoDTO?.amcLogo}width="auto" height={30} alt="PMS Logo"/></TableCell>
              <TableCell align="left"><img src={pmsFour && pmsFour.data.pmsDTOList[0]?.amcInfoDTO?.amcLogo}width="auto" height={30} alt="PMS Logo"/></TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">AMC Name</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.amcInfoDTO?.amcName || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.amcInfoDTO?.amcName || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.amcInfoDTO?.amcName || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.amcInfoDTO?.amcName || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Name</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemeName || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeName || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemeName || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemeName || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Code</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemeCode || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeCode || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemeCode || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemeCode || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">AMC Code</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.amcCode || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.amcCode || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.amcCode || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.amcCode || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Inception Date</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemeInceptionDate || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeInceptionDate || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemeInceptionDate || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemeInceptionDate || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Benchmark Name</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemeBenchmarkName || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeBenchmarkName || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemeBenchmarkName || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemeBenchmarkName || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Min Investment</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemeMinInvestment || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeMinInvestment || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemeMinInvestment || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemeMinInvestment || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Exit Load</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemeExitLoad || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeExitLoad || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemeExitLoad || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemeExitLoad || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Fee Structure</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemeFeeStructure || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeFeeStructure || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemeFeeStructure || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemeFeeStructure || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Expense Ratio</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemeExpenseRatio || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeExpenseRatio || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemeExpenseRatio || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemeExpenseRatio || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Plan Type</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemePlanType || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemePlanType || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemePlanType || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemePlanType || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Min Locking Period</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemeMinLockingPeriod || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeMinLockingPeriod || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemeMinLockingPeriod || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemeMinLockingPeriod || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Classification</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemeClassification || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeClassification || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemeClassification || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemeClassification || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Fund Manager</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemeFundManager || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeFundManager || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemeFundManager || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemeFundManager || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Objective</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.schemeObjective || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeObjective || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.schemeObjective || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.schemeObjective || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Active Returns 1M</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.activeReturns1month || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.activeReturns1month || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.activeReturns1month || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.activeReturns1month || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Active Returns 3M</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.activeReturns3month || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.activeReturns3month || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.activeReturns3month || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.activeReturns3month || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Active Returns 6M</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.activeReturns6month || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.activeReturns6month || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.activeReturns6month || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.activeReturns6month || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Active Returns 1Y</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.activeReturns1year || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.activeReturns1year || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.activeReturns1year || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.activeReturns1year || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Active Returns 3Y</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.activeReturns3year || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.activeReturns3year || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.activeReturns3year || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.activeReturns3year || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Active Returns 5Y</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.activeReturns5year || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.activeReturns5year || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.activeReturns5year || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.activeReturns5year || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Active Returns SI</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.activeReturnsSi || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.activeReturnsSi || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.activeReturnsSi || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.activeReturnsSi || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">AUM</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.aum || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.aum || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.aum || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.aum || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Benchmark Name</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.benchmarkName || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.benchmarkName || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.benchmarkName || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.benchmarkName || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Benchmark Returns 1M</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.bmRet1m || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.bmRet1m || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.bmRet1m || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.bmRet1m || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Benchmark Returns 3M</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.bmRet3m || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.bmRet3m || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.bmRet3m || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.bmRet3m || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Benchmark Returns 6M</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.bmRet6m || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.bmRet6m || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.bmRet6m || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.bmRet6m || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Benchmark Returns 1Y</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.bmRet1y || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.bmRet1y || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.bmRet1y || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.bmRet1y || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Benchmark Returns 2Y</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.bmRet2y || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.bmRet2y || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.bmRet2y || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.bmRet2y || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Benchmark Returns 3Y</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.bmRet3y || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.bmRet3y || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.bmRet3y || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.bmRet3y || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Benchmark Returns 5Y</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.bmRet5y || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.bmRet5y || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.bmRet5y || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.bmRet5y || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Benchmark Returns Ince</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.bmRetInce || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.bmRetInce || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.bmRetInce || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.bmRetInce || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">NAV</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.nav || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.nav || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.nav || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.nav || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Returns 1M</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.schemeRet1m || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.schemeRet1m || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.schemeRet1m || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.schemeRet1m || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Returns 3m</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.schemeRet3m || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.schemeRet3m || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.schemeRet3m || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.schemeRet3m || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Returns 6M</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.schemeRet6m || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.schemeRet6m || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.schemeRet6m || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.schemeRet6m || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Returns 1Y</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.schemeRet1y || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.schemeRet1y || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.schemeRet1y || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.schemeRet1y || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Returns 2Y</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.schemeRet2y || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.schemeRet2y || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.schemeRet2y || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.schemeRet2y || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Returns 3Y</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.schemeRet3y || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.schemeRet3y || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.schemeRet3y || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.schemeRet3y || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Returns 5Y</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.schemeRet5y || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.schemeRet5y || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.schemeRet5y || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.schemeRet5y || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Scheme Returns Ince</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.fundPerformance?.schemeRetInce || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.fundPerformance?.schemeRetInce || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.fundPerformance?.schemeRetInce || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.fundPerformance?.schemeRetInce || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row">Total Stocks</TableCell>
              <TableCell align="left">{pmsOne && pmsOne.data.pmsDTOList[0]?.portfolioCharacteristics?.totalStocks || "-"}</TableCell>
              <TableCell align="left">{pmsTwo && pmsTwo.data.pmsDTOList[0]?.portfolioCharacteristics?.totalStocks || "-"}</TableCell>
              <TableCell align="left">{pmsThree && pmsThree.data.pmsDTOList[0]?.portfolioCharacteristics?.totalStocks || "-"}</TableCell>
              <TableCell align="left">{pmsFour && pmsFour.data.pmsDTOList[0]?.portfolioCharacteristics?.totalStocks || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  scope="row"></TableCell>
              <TableCell align="left">
                <Link to={"/pmsScreener/"+( pmsOne && pmsOne.data.pmsDTOList[0]?.schemeCode)}>
                  {pmsOne && pmsOne.data.pmsDTOList[0]?.schemeCode && <Button variant="contained">Order Now</Button>}
                </Link>
              </TableCell>
              <TableCell align="left">
                <Link to={"/pmsScreener/"+( pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeCode)}>
                  {pmsTwo && pmsTwo.data.pmsDTOList[0]?.schemeCode && <Button variant="contained">Order Now</Button>}
                </Link>
              </TableCell>
              <TableCell align="left">
                <Link to={"/pmsScreener/"+( pmsThree && pmsThree.data.pmsDTOList[0]?.schemeCode)}>
                  {pmsThree && pmsThree.data.pmsDTOList[0]?.schemeCode && <Button variant="contained">Order Now</Button>}
                </Link>
              </TableCell>
              <TableCell align="left">
                <Link to={"/pmsScreener/"+( pmsFour && pmsFour.data.pmsDTOList[0]?.schemeCode)}>
                  {pmsFour && pmsFour.data.pmsDTOList[0]?.schemeCode && <Button variant="contained">Order Now</Button>}
                </Link>
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
	</>
  )
}

export default withState(ComparePMSFundPage) 