import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  Autocomplete,
  Grid,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { SEND_OTP } from "../../utils/API_Names";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import MultiSelect from "../../utils/MultiSelect";
import MultiSelectComp from "../../utils/MultiSelectComp";
import States from "../../Constants/States";
import { useQuery } from "react-query";
import { product_listOfAllProductType_WithOtpToken } from "../../api/Api";
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  "& .MuiTextField-root": { m: 1 },
  overflowY: "auto",
  maxHeight: "80%",
};

const StartWithUsLoginModel = (props:any) => {
	const open = props.open
	const handleClose = props.handleClose

  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [formValue, setFormValue] = useState({ phoneNumber: "", otp: "" });

  const [compTypeValue, setCompTypeValue] = useState<any>();
  const [companyLogofile, setcompanyLogofile] = useState<any>();
  const [registerOfCertificatefile, setregisterOfCertificatefile] =
    useState<any>();
  const [gstNumberDocfile, setgstNumberDocfile] = useState<any>();
  const [pancardDocfile, setpancardDocfile] = useState<any>();
  const [cancelChequefile, setcancelChequefile] = useState<any>();
  const [isChecked, setIsChecked] = useState(true);
  const [ProductTypeCode, setProductTypeCode] = useState([]);
  const [products, setProducts] = useState<any>([]);
  // const [open, setOpen] = React.useState(props.open);
  const [companyData, setCompanyData] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [CompanyType, setCompanyType] = useState("");
  const [userCode, setUserCode] = useState<any>(sessionStorage.getItem("Pro"));
  const [CompanyList, setCompanyList] = useState([]);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [userCodeList, setUserCodeList] = useState([]);
  const [showOtpField, setShowOtpField] = useState(false);

  const resolver = async (values: any) => {
    return {
      values: !values.companyName ? {} : values,
      errors: !values.companyName
        ? {
            companyName: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.pancard
        ? {
            pancard: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.emailId
        ? {
            emailId: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.noOfUser
        ? {
            noOfUser: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.country
        ? {
            country: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.state
        ? {
            state: {
              type: "required",
              message: "This is required.",
            },
          }
        : ProductTypeCode.length == 0
        ? {
            productsType: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.city
        ? {
            city: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.addressLine1
        ? {
            addressLine1: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.addressLine2
        ? {
            addressLine2: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.addressLine3
        ? {
            addressLine3: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.pin
        ? {
            pin: {
              type: "required",
              message: "This is required.",
            },
          }
        : {},
    };
  };

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({ resolver });

  const { data, refetch } = useQuery(
    ["ListOfProductTypeWithOtpToken", sessionStorage.getItem("OTP_Token")],
    product_listOfAllProductType_WithOtpToken,
    {
      enabled: !!sessionStorage.getItem("OTP_Token"),
      onSuccess: (data: any) => setProducts(data.data.productType),
    }
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
    console.log("formValue", formValue);
  };

  const generateOTP = () => {
    axios
      .post(SEND_OTP, formValue)
      .then((res) => {
        setShowOtpField(true);
        toast.success(`OTP has been sent to ${formValue.phoneNumber}`);
      })
      .catch((err: any) =>
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        )
      );
  };

  const submitOTP = () => {
    axios
      .post("authentication/otpAuthentication", formValue)
      .then((res: any) => {
        sessionStorage.setItem("OTP_Token", res?.data);
        refetch();
      })
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const onSubmit = (data: any) => {
    setshowCircularProgress(true);
    const formData = new FormData();
    formData.append("registerOfCertificate", registerOfCertificatefile);
    formData.append("gstNumberDoc", gstNumberDocfile);
    formData.append("panCardDoc", pancardDocfile);
    formData.append("cancelCheque", cancelChequefile);
    formData.append("companyLogo", companyLogofile);

    const dto: any = {
      ...data,
      viewLogo: isChecked,
      products: ProductTypeCode.toString(),
    };
    formData.append("companyDto", JSON.stringify(dto));
    console.log("Data", formData);

    axios
      .post("company/saveCompany", formData, {
        headers: {
          Authorization: sessionStorage.getItem("OTP_Token"),
        },
      })
      .then(() => toast.success("Company added successfully!"))
      .then(() => {
        setshowCircularProgress(false);
        handleClose(false);
        window.location.reload();
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        toast.error(
          err.response.data ? err.response.data.status.message.title : "Error"
        );
      });
  };

  return (
    <>
    <Grid>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        
      >
        
        <Box sx={style}>
        <IconButton
      style={{ position: 'absolute',fontSize:"18px" ,top: "8px", right: "5px"}}
      onClick={() => handleClose()}
    >
      <CloseIcon style={{fontSize:"18px"}} />
    </IconButton>
          {!sessionStorage.getItem("OTP_Token") ? (
            <>
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h6"
                style={{textAlign:"center"}}
              >
                Submit your Company Details after Login with OTP
              </Typography>
              <form>
                <Box sx={{textAlign:"center"}} >
                  <Typography
                    id="keep-mounted-modal-description"
                    sx={{ mt: 2 }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Mobile No"
                      variant="outlined"
                      sx={{width:300}}
                      onClick={() => setShowOtpField(false)}
                      name="phoneNumber"
                      onChange={handleChange}
                      style={{textAlign:"center"}}
                    />

                    {showOtpField ? (
                      <TextField
                        id="outlined-basic"
                        label="OTP"
                        variant="outlined"
                        sx={{width:300}}
                        //   value={formValue.password}
                        name="otp"
                        onChange={handleChange}
                      />
                    ) : (
                      <></>
                    )}
                  </Typography>
                  <Stack direction="row" spacing={2} style={{display:"flex",justifyContent:"space-around"}}>
                    {!showOtpField ? (
                      <Button
                        variant="outlined"
                        onClick={generateOTP}
                        disabled={showCircularProgress}
                      >
                        {" "}
                        {showCircularProgress === true ? (
                          <CircularProgress
                            color="secondary"
                            size={20}
                            thickness={5}
                          />
                        ) : (
                          ""
                        )}{" "}
                        Sent OTP
                      </Button>
                    ) : (
                      <Button variant="contained" onClick={submitOTP}>
                        Verify OTP
                      </Button>
                    )}
                  </Stack>
                </Box>
              </form>
            </>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h6"
                  textAlign={"center"}
                >
                  Submit Your Company Details
                </Typography>

                <Box>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="INDIVIDUAL"
                  >
                    <Stack direction={"row"} spacing={3}>
                      <FormControlLabel
                        value="INDIVIDUAL"
                        {...register("compType")}
                        control={<Radio />}
                        label="Individual"
                      />
                      <FormControlLabel
                        value="CORPORATE"
                        {...register("compType")}
                        control={<Radio />}
                        label="Corporate"
                      />
                    </Stack>
                  </RadioGroup>
                  <Grid container gap={1} style={{display:"flex",justifyContent:"space-between"}}>
                    <Grid item xs={12} sm={12} md={4} lg={3} >
                      <TextField
                        id="outlined-basic"
                        label="Company Name"
                        variant="outlined"
                        defaultValue={companyData.companyName}
                        fullWidth
                        error={errors.companyName ? true : false}
                        helperText={
                          errors.companyName == undefined
                            ? ""
                            : errors.companyName.message + ""
                        }
                        {...register("companyName")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={[
                          {
                            companyType: "Manufacture",
                            companyCode: "M",
                          },
                          { companyType: "Distributor", companyCode: "D" },
                        ].map((option: any) => option)}
                        getOptionLabel={(options: any) => options.companyType}
                        onChange={(options: any, newValue) => {
                          setCompanyType(newValue.companyCode);
                          setValue("companyTypeCode", newValue.companyCode);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company Type"
                            error={errors.companyTypeCode ? true : false}
                            helperText={
                              errors.companyTypeCode == undefined
                                ? ""
                                : errors.companyTypeCode.message + ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {CompanyType == "D" ? (
                      <>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <Controller
                            control={control}
                            name="Product"
                            rules={{
                              required: "Veuillez choisir une réponse",
                            }}
                            render={({ field: { onChange } }) => (
                              <MultiSelectComp
                                items={CompanyList}
                                label="Assign Company"
                                selectAllLabel="Select All"
                                onChange={(e: any) =>
                                  setCompanyList(
                                    e.map((i: any) => i.companyMasterCode)
                                  )
                                }
                              />
                            )}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextField
                        id="outlined-basic"
                        label="CIN Number"
                        variant="outlined"
                        defaultValue={companyData.cinNumber}
                        fullWidth
                        error={errors.cinNumber ? true : false}
                        helperText={
                          errors.cinNumber == undefined
                            ? ""
                            : errors.cinNumber.message + ""
                        }
                        {...register("cinNumber")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextField
                        id="outlined-basic"
                        label="GST Number"
                        variant="outlined"
                        defaultValue={companyData.gstNumber}
                        fullWidth
                        error={errors.gstNumber ? true : false}
                        helperText={
                          errors.gstNumber == undefined
                            ? ""
                            : errors.gstNumber.message + ""
                        }
                        {...register("gstNumber")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextField
                        id="outlined-basic"
                        label="PAN card No"
                        variant="outlined"
                        defaultValue={companyData.pancard}
                        fullWidth
                        error={errors.pancard ? true : false}
                        helperText={
                          errors.pancard == undefined
                            ? ""
                            : errors.pancard.message + ""
                        }
                        {...register("pancard")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextField
                        id="outlined-basic"
                        label="Email Id"
                        variant="outlined"
                        defaultValue={companyData.emailId}
                        fullWidth
                        error={errors.emailId ? true : false}
                        helperText={
                          errors.emailId == undefined
                            ? ""
                            : errors.emailId.message + ""
                        }
                        {...register("emailId")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextField
                        id="outlined-basic"
                        label="Phone No"
                        variant="outlined"
                        defaultValue={companyData.emailId}
                        fullWidth
                        error={errors.emailId ? true : false}
                        helperText={
                          errors.emailId == undefined
                            ? ""
                            : errors.emailId.message + ""
                        }
                        {...register("phoneNo")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextField
                        id="outlined-basic"
                        label="No Of User"
                        variant="outlined"
                        defaultValue={companyData.noOfUser}
                        fullWidth
                        type="number"
                        error={errors.noOfUser ? true : false}
                        helperText={
                          errors.noOfUser == undefined
                            ? ""
                            : errors.noOfUser.message + ""
                        }
                        {...register("noOfUser", { valueAsNumber: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <Autocomplete
                        options={States.countries}
                        getOptionLabel={(option) => option.country}
                        onChange={(event, value) => {
                          setSelectedCountry(value);
                          setValue("country", value && value.country);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country"
                            variant="outlined"
                            error={errors.country ? true : false}
                            helperText={
                              errors.country == undefined
                                ? ""
                                : errors.country.message + ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {selectedCountry && (
                      <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Autocomplete
                          options={selectedCountry.states}
                          onChange={(event, value) => {
                            setSelectedState(value);
                            setValue("state", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              variant="outlined"
                              error={errors.state ? true : false}
                              helperText={
                                errors.state == undefined
                                  ? ""
                                  : errors.state.message + ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {!userCode ? (
                      <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={userCodeList.map((option: any) => option)}
                          getOptionLabel={(options: any) => options.userCode}
                          onChange={(options: any, newValue) => {
                            setUserCode(newValue.userCode);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="User Role" />
                          )}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextField
                        id="outlined-basic"
                        label="City"
                        variant="outlined"
                        defaultValue={companyData.city}
                        fullWidth
                        error={errors.city ? true : false}
                        helperText={
                          errors.city == undefined
                            ? ""
                            : errors.city.message + ""
                        }
                        {...register("city")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextField
                        id="outlined-basic"
                        label="Address Line 1"
                        defaultValue={companyData.addressLine1}
                        variant="outlined"
                        fullWidth
                        error={errors.addressLine1 ? true : false}
                        helperText={
                          errors.addressLine1 == undefined
                            ? ""
                            : errors.addressLine1.message + ""
                        }
                        {...register("addressLine1")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextField
                        id="outlined-basic"
                        label="Address Line 2"
                        defaultValue={companyData.addressLine2}
                        variant="outlined"
                        fullWidth
                        error={errors.addressLine2 ? true : false}
                        helperText={
                          errors.addressLine2 == undefined
                            ? ""
                            : errors.addressLine2.message + ""
                        }
                        {...register("addressLine2")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextField
                        id="outlined-basic"
                        label="Address Line 3"
                        variant="outlined"
                        defaultValue={companyData.addressLine3}
                        fullWidth
                        error={errors.addressLine3 ? true : false}
                        helperText={
                          errors.addressLine3 == undefined
                            ? ""
                            : errors.addressLine3.message + ""
                        }
                        {...register("addressLine3")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextField
                        id="outlined-basic"
                        label="PIN Code"
                        variant="outlined"
                        defaultValue={companyData.pin}
                        fullWidth
                        error={errors.pin ? true : false}
                        helperText={
                          errors.pin == undefined ? "" : errors.pin.message + ""
                        }
                        {...register("pin")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <Controller
                        control={control}
                        name="Product"
                        rules={{
                          required: "Veuillez choisir une réponse",
                        }}
                        render={({ field: { onChange } }) => (
                          <MultiSelect
                            error={false}
                            helperText={errors?.productsType?.message}
                            items={products}
                            label="Products"
                            selectAllLabel="Select All"
                            onChange={(e: any) =>
                              setProductTypeCode(
                                e.map((i: any) => i.productTypeCode)
                              )
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Show Logo"
                    />
                  </Box>

                  <Typography variant="subtitle1"  style={{fontSize:"20px"}}>
                    Registration Certificate : &nbsp;
                    <input
                    type="file"
                    accept=".pdf"
                    name="registerOfCertificate"
                    onChange={(event: any) =>
                      setregisterOfCertificatefile(event.target.files[0])
                    }
                    />
                    </Typography>
                  <br />
                  <Typography variant="subtitle1"  style={{fontSize:"20px"}}>GST Certificate : &nbsp;
                  <input
                    type="file"
                    accept=".pdf"
                    name="gstNumberDoc"
                    onChange={(event: any) =>
                      setgstNumberDocfile(event.target.files[0])
                    }
                    />
                    </Typography>
                  <br />
                  <Typography variant="subtitle1"  style={{fontSize:"20px"}}>Pancard : &nbsp; 
                  <input
                    type="file"
                    accept=".pdf"
                    name="pancardDoc"
                    onChange={(event: any) =>
                      setpancardDocfile(event.target.files[0])
                    }
                    />
                    </Typography>
                  <br />
                  <Typography variant="subtitle1"  style={{fontSize:"20px"}}>Cancel Cheque : &nbsp;
                  <input
                    type="file"
                    accept=".jpg"
                    name="cancelCheque"
                    onChange={(event: any) =>
                      setcancelChequefile(event.target.files[0])
                    }
                    />
                    </Typography>
                  <br />
                  <Typography variant="subtitle1"  style={{fontSize:"20px"}}>Company Logo : &nbsp;
                  <input
                    type="file"
                    accept=".jpg"
                    name="companyLogo"
                    onChange={(event: any) =>
                      setcompanyLogofile(event.target.files[0])
                    }
                    />
                    </Typography>
                  <br />

                  <div style={{ width: "100%", marginTop: "10px",textAlign:'center' }}>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        handleSubmit(onSubmit);
                        setCompTypeValue(getValues("compType"));
                      }}
                      disabled={showCircularProgress}
                    >
                      {showCircularProgress == true ? (
                        <CircularProgress
                          color="secondary"
                          size={20}
                          thickness={5}
                        />
                      ) : (
                        ""
                      )}{" "}
                      Submit
                    </Button>
                  </div>
                </Box>
              </Box>
            </form>
          )}
        </Box>
      </Modal>
      </Grid>
    </>
  );
};

export default StartWithUsLoginModel;
