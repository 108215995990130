import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputBase,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { useQuery } from "react-query";
import { MfAmcList } from "../../api/Api";

const FilterMF = (props: any) => {
  const { data: mfAmcList } = useQuery("MF_AMC_LISTs", MfAmcList, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => setAmcData(data.data.amcDetails),
  });

  const [amcData, setAmcData] = React.useState<String[]>([]);
  const [searchTerm, setSearchTerm] = React.useState("");

  const [value, setValue] = React.useState("1M");

  const [filterAMC, setFilterAMC] = React.useState<any>([]);

  const [selectedAMC, setSelectedAMC] = React.useState<any>([]);
  const [selectedAUM, setSelectedAUM] = useState<string | null>(null);

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleChange = (event: any) => {
    const value1 = "sortByReturn=" + event.target.value;
    props.setReturn(value1);
    setValue(event.target.value);
  };

  React.useEffect(() => {
    props.setFilterDataURL(filterAMC);
  }, [filterAMC, value, props]);

  const filteredAMCList =
    mfAmcList &&
    amcData.filter((item: any) => {
      return item.amcName.toLowerCase().includes(searchTerm.toLowerCase());
      // || item.userName.toLowerCase().includes(searchTerm.toLowerCase())
    });

  const changeAMCList = (event: any, amcId: any) => {
    const value = "amcIds=" + amcId;

    if (selectedAMC.includes(amcId)) {
      setSelectedAMC(selectedAMC.filter((name: any) => name !== amcId));
    } else {
      setSelectedAMC([...selectedAMC, amcId]);
    }

    const isChecked = event.target.checked;
    if (isChecked) {
      setFilterAMC([...filterAMC, value]);
    } else {
      setFilterAMC(filterAMC.filter((item: any) => item !== value));
    }
  };

  const changeAssetClass = (event: any, assetClass: any) => {
    const value = "assets=" + assetClass;
    const isChecked = event.target.checked;
    if (isChecked) {
      setFilterAMC([...filterAMC, value]);
    } else {
      setFilterAMC(filterAMC.filter((item: any) => item !== value));
    }
  };

  const changeCategories = (event: any, categories: any) => {
    const value = "categories=" + encodeURIComponent(categories);
    const isChecked = event.target.checked;
    if (isChecked) {
      setFilterAMC([...filterAMC, value]);
    } else {
      setFilterAMC(filterAMC.filter((item: any) => item !== value));
    }
  };

  const changeBenchMark = (event: any, assetClass: any) => {
    const value = "benchmarks=" + assetClass;
    const isChecked = event.target.checked;
    if (isChecked) {
      setFilterAMC([...filterAMC, value]);
    } else {
      setFilterAMC(filterAMC.filter((item: any) => item !== value));
    }
  };

  // const changeAUM = (event:any,min:any,max:any) => {
  //   const value = "maxAum="+max+"&minAum="+min;
  //   const isChecked = event.target.checked;
  //     if (isChecked) {
  //       setFilterAMC([...filterAMC, value]);
  //     } else {
  //       setFilterAMC(filterAMC.filter((item:any) => item !== value));
  //     }
  // }
  const changeAUM = (min: string, max: string) => {
    const value = `minAum=${min}&maxAum=${max}`;
    setSelectedAUM((prevValue) => (prevValue === value ? null : value));
    setFilterAMC((prevFilters: any) =>
      prevFilters.includes(value) ? [] : [value]
    );
  };
  console.log("value", value);

  return (
    <div className="filter_container filter_container-filter-aif-main">
      <div className="filter_dropdowns">
        <Paper
          sx={{
            height: "47px",
            width: "100%",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            display: "flex",
          }}
        >
          <Typography
            sx={{
              letterSpacing: "1px",
              fontWeight: "600",
              padding: "10px",
              color: "primary",
              marginLeft: "5px",
            }}
          >
            Filters
          </Typography>
          <TuneRoundedIcon
            sx={{ fontSize: "1rem", marginTop: "15px", color: "primary" }}
          />
          <Button
            sx={{ textTransform: "none", margin: "10px", marginLeft: "auto" }}
            size="small"
            onClick={() => window.location.reload()}
          >
            Clear
          </Button>
        </Paper>
        {/* AMC */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {/* <ListAltRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
            <Typography
              sx={{
                // marginLeft:'8px',
                fontSize: "0.9rem",
                fontWeight: "500",
                color: "#3c3b3b",
              }}
            >
              AMC
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search AMC"
                  onChange={handleSearch}
                />
              </Paper>

              <FormGroup
                sx={{
                  overflowX: "scroll",
                  maxHeight: "350px",
                  textAlign: "left",
                  flexDirection: "row",
                }}
              >
                {filteredAMCList &&
                  filteredAMCList
                    .sort((a: any, b: any) =>
                      b.amcName.toLowerCase() < a.amcName.toLowerCase() ? 1 : -1
                    )
                    .map((amc: any) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={amc.amcId}
                            checked={selectedAMC.includes(amc.amcId)}
                          />
                        }
                        label={amc.amcName}
                        onChange={(event: any) =>
                          changeAMCList(event, amc.amcId)
                        }
                        id={amc.amc_id}
                      />
                    ))}
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Assect Class */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {/* <PeopleAltRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
            <Typography
              sx={{
                color: "#3c3b3b",
                //   marginLeft: "8px",
                fontSize: "0.9rem",
                fontWeight: "500",
                letterSpacing: "1px",
              }}
            >
              Asset
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Equity"
                  onChange={(event: any) => changeAssetClass(event, "Equity")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Debt"
                  onChange={(event: any) => changeAssetClass(event, "Debt")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Hybrid"
                  onChange={(event: any) => changeAssetClass(event, "Hybrid")}
                />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {/* <StoreRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
            <Typography
              sx={{
                color: "#3c3b3b",
                //   marginLeft: "8px",
                fontSize: "0.9rem",
                fontWeight: "500",
                letterSpacing: "1px",
              }}
            >
              Category
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup>
                {/* <FormControlLabel control={<Checkbox />} label="All Category" onChange={(event:any)=>changeCategories(event,"All Category")}/> */}
                <FormControlLabel
                  control={<Checkbox />}
                  label="Large Cap "
                  onChange={(event: any) =>
                    changeCategories(event, "Large Cap ")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Midcap"
                  onChange={(event: any) => changeCategories(event, "Midcap")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Small Cap"
                  onChange={(event: any) =>
                    changeCategories(event, "Small Cap")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Flexicap"
                  onChange={(event: any) =>
                    changeCategories(event, "Flexi Cap")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Multicap"
                  onChange={(event: any) =>
                    changeCategories(event, "Multi Cap")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Large & Mid Cap"
                  onChange={(event: any) =>
                    changeCategories(event, "Large & Mid Cap")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Small & Mid Cap"
                  onChange={(event: any) =>
                    changeCategories(event, "Small & Mid Cap")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Thematic"
                  onChange={(event: any) => changeCategories(event, "Thematic")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Arbitrage"
                  onChange={(event: any) =>
                    changeCategories(event, "Arbitrage")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="ETF"
                  onChange={(event: any) => changeCategories(event, "ETF")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Multi Asset"
                  onChange={(event: any) =>
                    changeCategories(event, "Multi Asset")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Debt"
                  onChange={(event: any) => changeCategories(event, "Debt")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="others"
                  onChange={(event: any) => changeCategories(event, "others")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Sectoral"
                  onChange={(event: any) => changeCategories(event, "Sectoral")}
                />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {/* <SendRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
            <Typography
              sx={{
                color: "#3c3b3b",
                //   marginLeft: "8px",
                fontSize: "0.9rem",
                fontWeight: "500",
                letterSpacing: "1px",
              }}
            >
              Bench Mark
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup>
                {/* <FormControlLabel control={<Checkbox />} label="All Benchmark" onChange={(event:any)=>changeBenchMark(event,"All Benchmark")}/> */}
                <FormControlLabel
                  control={<Checkbox />}
                  label="Nifty 50 TRI"
                  onChange={(event: any) =>
                    changeBenchMark(event, "Nifty 50 TRI")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="S&P BSE 500 TRI"
                  onChange={(event: any) =>
                    changeBenchMark(event, "S&P BSE 500 TRI")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="MSEI SX 40 TRI"
                  onChange={(event: any) =>
                    changeBenchMark(event, "MSEI SX 40 TRI")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Nifty Medium to Long term Debt Index"
                  onChange={(event: any) => changeBenchMark(event, "Equity")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Crisil Credit Index"
                  onChange={(event: any) => changeBenchMark(event, "Equity")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Crisil Composite Bond Fund Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "Crisil Composite Bond Fund Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Nifty 50 Hybrid Composite Debt 50:50 Index"
                  onChange={(event: any) => changeBenchMark(event, "Equity")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="BSE S&P hybrid"
                  onChange={(event: any) => changeBenchMark(event, "Equity")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Crisil Hybrid 50+50 Moderate Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "Crisil Hybrid 50+50 Moderate Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NSE Multi Asset Index 1"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NSE Multi Asset Index 1")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NSE Multi Asset Index 2"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NSE Multi Asset Index 2")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Crisil Multi Asset Index 3"
                  onChange={(event: any) =>
                    changeBenchMark(event, "Crisil Multi Asset Index 3")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Average of NSE Mid-cap 100 TRI and NSE Small-cap 100 TRI"
                  onChange={(event: any) =>
                    changeBenchMark(
                      event,
                      "Average of NSE Mid-cap 100 TRI and NSE Small-cap 100 TRI"
                    )
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="BSE 500"
                  onChange={(event: any) => changeBenchMark(event, "BSE 500")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="CRISIL Arbitrage Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "CRISIL Arbitrage Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="CRISIL Liquid Fund Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "CRISIL Liquid Fund Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY 200 Total Return Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NIFTY 200 Total Return Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY 50 & BSE SMALLCAP"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NIFTY 50 & BSE SMALLCAP")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY 50 Arbitrage Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NIFTY 50 Arbitrage Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY 50 Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NIFTY 50 Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY 500 Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NIFTY 500 Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY Financial Services Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NIFTY Financial Services Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY India Consumption Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NIFTY India Consumption Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY Mid Small Cap 400"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NIFTY Mid Small Cap 400")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY Mid Small Cap 400 Total Return Index"
                  onChange={(event: any) =>
                    changeBenchMark(
                      event,
                      "NIFTY Mid Small Cap 400 Total Return Index"
                    )
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY Midcap 100 Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NIFTY Midcap 100 Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY Smallcap 100 Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NIFTY Smallcap 100 Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="S&P BSE 100 Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "S&P BSE 100 Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="S&P BSE 200 Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "S&P BSE 200 Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="S&P BSE 500 Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "S&P BSE 500 Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="S&P BSE Small Cap Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "S&P BSE Small Cap Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="80% Nifty 500 + 20% Nifty 4-8Y G-Sec"
                  onChange={(event: any) =>
                    changeBenchMark(
                      event,
                      "80% Nifty 500 + 20% Nifty 4-8Y G-Sec"
                    )
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Active Aggressive Composite"
                  onChange={(event: any) =>
                    changeBenchMark(event, "Active Aggressive Composite")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Active Aggressive plus Composite"
                  onChange={(event: any) =>
                    changeBenchMark(event, "Active Aggressive plus Composite")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Active Balanced Composite"
                  onChange={(event: any) =>
                    changeBenchMark(event, "Active Balanced Composite")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Active Growth Composite"
                  onChange={(event: any) =>
                    changeBenchMark(event, "Active Growth Composite")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY Medium to Long Duration Debt Index"
                  onChange={(event: any) =>
                    changeBenchMark(
                      event,
                      "NIFTY Medium to Long Duration Debt Index"
                    )
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="NIFTY Smallcap 250 Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "NIFTY Smallcap 250 Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Nifty50 Shariah"
                  onChange={(event: any) =>
                    changeBenchMark(event, "Nifty50 Shariah")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="S&P BSE INDIA BOND INDEX"
                  onChange={(event: any) =>
                    changeBenchMark(event, "S&P BSE INDIA BOND INDEX")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="S&P BSE INDIA CORPORATE BOND INDEX"
                  onChange={(event: any) =>
                    changeBenchMark(event, "S&P BSE INDIA CORPORATE BOND INDEX")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="S&P BSE Mid Cap Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "S&P BSE Mid Cap Index")
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="S&P BSE Sensex Index"
                  onChange={(event: any) =>
                    changeBenchMark(event, "S&P BSE Sensex Index")
                  }
                />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                color: "#3c3b3b",
                fontSize: "0.9rem",
                fontWeight: "500",
                letterSpacing: "1px",
              }}
            >
              AUM
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Typography>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="< 100 Cr" onChange={(event:any)=>changeAUM(event,"0","100")}/>
                  <FormControlLabel control={<Checkbox />} label="100-500 cr" onChange={(event:any)=>changeAUM(event,"100","500")}/>
                  <FormControlLabel control={<Checkbox />} label="500- 2000 Cr" onChange={(event:any)=>changeAUM(event,"500","2000")}/>
                  <FormControlLabel control={<Checkbox />} label="> 2000 cr" onChange={(event:any)=>changeAUM(event,"2000","0")}/>
                </FormGroup>
              </Typography> */}
            <Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Radio checked={selectedAUM === "minAum=0&maxAum=100"} />
                  }
                  label="< 100 Cr"
                  onChange={() => changeAUM("0", "100")}
                />
                <FormControlLabel
                  control={
                    <Radio checked={selectedAUM === "minAum=100&maxAum=500"} />
                  }
                  label="100-500 cr"
                  onChange={() => changeAUM("100", "500")}
                />
                <FormControlLabel
                  control={
                    <Radio checked={selectedAUM === "minAum=500&maxAum=2000"} />
                  }
                  label="500-2000 Cr"
                  onChange={() => changeAUM("500", "2000")}
                />
                <FormControlLabel
                  control={
                    <Radio checked={selectedAUM === "minAum=2000&maxAum=0"} />
                  }
                  label="> 2000 cr"
                  onChange={() => changeAUM("2000", "0")}
                />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                color: "#3c3b3b",

                fontSize: "0.9rem",
                fontWeight: "500",
                letterSpacing: "1px",
              }}
            >
              Returns
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign={"left"}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1M"
                    control={<Radio />}
                    label="1 Month"
                  />
                  <FormControlLabel
                    value="3M"
                    control={<Radio />}
                    label="3 Month"
                  />
                  <FormControlLabel
                    value="6M"
                    control={<Radio />}
                    label="6 Month"
                  />
                  <FormControlLabel
                    value="1Y"
                    control={<Radio />}
                    label="1 Year"
                  />
                  <FormControlLabel
                    value="2Y"
                    control={<Radio />}
                    label="2 Year"
                  />
                  <FormControlLabel
                    value="3Y"
                    control={<Radio />}
                    label="3 Year"
                  />
                  <FormControlLabel
                    value="5Y"
                    control={<Radio />}
                    label="5 Year"
                  />
                  <FormControlLabel
                    value="Ince"
                    control={<Radio />}
                    label="Since Inception"
                  />
                </RadioGroup>
              </FormControl>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default FilterMF;
