import { Container, Grid, Typography, List, ListItem, ListItemText } from '@mui/material'
import React from 'react'
import StartWithUs from '../StartWithUs/StartWithUs'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const StartupEcosystem = () => {
  return (
    <div>
        <section>
        <Container>
        <Grid md={12} className={sessionStorage.getItem("JWT") ? 'your-dynamic-css-class-product' : 'default-class-product'}>
       <Typography style={{ textAlign: "left", fontSize: "30px", fontWeight: "bold",borderBottom: "4px solid black"}}>Startup Ecosystem </Typography>
       <Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold", textDecoration: "underline",paddingTop:"10px" }}>Current Scenario of the Startup Ecosystem in India </Typography>
       <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
       India has emerged as the 3rd largest ecosystem for startups globally as of 31st May 2023. India 
       ranks 2nd in innovation quality with top positions in the quality of scientific publications and the 
       quality of its universities among middle-income economies.
       <br />
       <br />
       Indian Startup Ecosystem has seen exponential growth in past few years (2015-2022):
       <br />
       <br />
       15X increase in the total funding of startups
       <br />
       <br />
       9X increase in the number of investors
       <br />
       <br />
       7X increase in the number of incubators
       <br />
       <br />
       As of May 2023, India is home to 108 Unicorns with a total valuation of USD 340.80 Bn.
       <br />
       <br />
       Out of the total number of unicorns, 44 unicorns were born in 2021 and 21 unicorns were born in 2022.
      </Typography>
      </Grid>
      <br />
      <Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold", textDecoration: "underline",paddingTop:"10px" }}>Challenges Faced by Indian Startups  </Typography>
      <Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold",paddingTop:"10px" }}>Funding Challenges:  </Typography>
       <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
       Indian startups encounter difficulties in securing adequate funding for their ventures. Limited access 
       to capital inhibits their growth potential and hampers innovation. Startups face challenges in 
       attracting investors and obtaining venture capital due to various factors such as risk aversion, 
     uncertain market conditions, and lack of investor confidence.   
      </Typography>

      <Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold",paddingTop:"10px" }}>Revenue Generation Struggles:  </Typography>
       <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
       Many startups face challenges in generating sustainable revenues. They often struggle to find viable 
       business models, monetize their products or services, and achieve profitability. Limited market reach, 
       competition from established players, and insufficient customer acquisition pose additional hurdles.   
      </Typography>

      <Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold",paddingTop:"10px" }}>Lack of Supportive Infrastructure:</Typography>
       <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
       The absence of a robust infrastructure ecosystem can impede the growth of startups. 
       <br />
       Challenges include inadequate physical infrastructure, limited access to technological resources, and 
      a dearth of incubation centers, mentorship programs, and networking opportunities. Startups 
     require supportive environments to thrive and access necessary resources, expertise, and guidance.    
      </Typography>

      <Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold",paddingTop:"10px" }}>Regulatory Environment and Tax Structures: </Typography>
       <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
       Startups in India face regulatory hurdles and complex tax structures. 
       <br />
       Cumbersome compliance processes, bureaucratic red tape, and ambiguous regulations create 
       obstacles for startups. Taxation complexities can add to the administrative burden and impact 
       profitability.    
      </Typography>
     
    <br />

   
   
  




{sessionStorage.getItem("JWT")?"":
<Grid mt={2} mb={2}>
<StartWithUs/>
</Grid>
}
        </Container>
    </section>
    </div>
  )
}

export default StartupEcosystem