import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography, createTheme, styled, useMediaQuery } from '@mui/material'

import React, { useEffect, useState } from 'react'
import { getPortfolioReportDetails } from '../../../api/Api'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CommaFormater2 } from '../../converter'
import { PolarArea } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
  } from 'chart.js';
ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
const defaultTheme = createTheme()

const CustomStyledTableContainer = styled(TableContainer)`
  overflow-x: ${(props:any) => (props.isLGScreen ? 'hidden' : 'auto')};
`




const DetailedPortfolio = () => {
  const [detailsdeta, setdetailsdeta] = useState<any>([])
  const [expanded, setExpanded] = useState<any>(false)
  const [dataTopHolding, TopHolding] = useState<any>('5')
  const [readmore, readless] = useState(true)
  const isLGScreen = useMediaQuery((theme:any) => theme.breakpoints.up('lg'))
  const [showCircularProgress, setshowCircularProgress] = useState(false);


  useEffect(() => {
    setshowCircularProgress(true);
    getPortfolioReportDetails().then((response) => { setdetailsdeta(response.data.detailFolioResponseArrays) }).then(()=>{
        setshowCircularProgress(false);
    })
  }, [])


  const numDifferentiation = (value:any) => {
    const val = Math.abs(value);
    if (val >= 10000000) {
      const formattedValue = (val / 10000000).toFixed(2);
      return CommaFormater2(formattedValue) + ' Cr';
    } else if (val >= 100000) {
      const formattedValue = (val / 100000).toFixed(2);
      return CommaFormater2(formattedValue) + ' Lakh';
    } else if (val >= 1000) {
      return CommaFormater2(val);
    } else {
      return val.toString();
    }
  };
  const handleChange = (panel:any) => (event:any, isExpanded:any) => {
    setExpanded(isExpanded ? panel : false)
  }
  const HoldingReadmore = () => {
    TopHolding('10')
    readless(false)
  }
  console.log("dfghjkl",detailsdeta)
  return (
    <div>
      {showCircularProgress == true ? (
                    <CircularProgress
                      color="secondary"
                      size={50}
                      thickness={5}
                    />
                  ) : (
                    <ThemeProvider theme={defaultTheme}>
                    <Box sx={{ backgroundColor: '#f0f8ff', margin: '0px 16px', borderRadius: '10px', padding: '10px' }}>
                    {detailsdeta?.filter((a:any) => (a.schemeName != null)).map((item:any, index:any) => (
                        <div className='portfolio-report-dtls-main-div' key={index} style={{ marginBottom: '2em' }}>
                            <div>
                                <Typography style={{ textTransform: 'uppercase' }} className='details-pf-fund-amc-top-data'> AMC-{item.amcname ? item.amcname : '-'}</Typography>
                            </div>
                            <Grid container className='details-pf-main-col-main-row-up'>
                                <Grid item xs={10} sm={10} md={10} lg={10} className='details-pf-main-col'>
        
                                    <Grid item xs={6} sm={6} md={6} lg={6} className='details-pf-sub-main-col'>
                                        <Typography className='details-pf-sub-main-colfund-p'>{item.schemeName}</Typography>
                                    </Grid>
                                    <div >
                                        <TableContainer style={{ overflowX: isLGScreen ? 'hidden' : 'auto' }}>
                                            <Table >
                                                <TableHead>
                                                    <TableRow className="details-pf-sub-main-col-p-tag-name-new">
                                                        <TableCell>NAV({item.navDate})</TableCell>
                                                        <TableCell>Rating</TableCell>
                                                        <TableCell>AUM</TableCell>
                                                        <TableCell>Fund Manager</TableCell>
                                                        <TableCell>Category Name</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow className="etails-pf-col-p-tag-name-data-below-new">
                                                        <TableCell className='portfolio-report-data-dtls'>{item.nav == null ? '-' : item.nav}</TableCell>
                                                        <TableCell className='portfolio-report-data-dtls' style={{ padding: '0' }}>
                                                            <h2 style={{ margin: '0px' }}>{item.morningStarRating === 5 ? <span style={{ color: 'gold', fontSize: '20px' }}>★★★★★</span> : ''}</h2>
                                                            <h2 style={{ margin: '0px' }}>{item.morningStarRating === 4 ? <span style={{ color: 'gold', fontSize: '20px' }}>★★★★</span> : ''}</h2>
                                                            <h2 style={{ margin: '0px' }}>{item.morningStarRating === 3 ? <span style={{ color: 'gold', fontSize: '20px' }}>★★★</span> : ''}</h2>
                                                            <h2 style={{ margin: '0px' }}>{item.morningStarRating === 2 ? <span style={{ color: 'gold', fontSize: '20px' }}>★★</span> : ''}</h2>
                                                            <h2 style={{ margin: '0px' }}>{item.morningStarRating === 1 ? <span style={{ color: 'gold', fontSize: '25px' }}>★</span> : ''}</h2>
                                                            <h2 style={{ margin: '0px' }}>{item.morningStarRating === '' || item.morningStarRating == null ? <span style={{ fontSize: '12px' }}>Not Rated</span> : ''}</h2>
                                                        </TableCell>
                                                        <TableCell className='portfolio-report-data-dtls'>{numDifferentiation(item.aum)} </TableCell>
                                                        <TableCell className='portfolio-report-data-dtls'>{item.fundManagerName}</TableCell>
                                                        <TableCell className='portfolio-report-data-dtls'>{item.categoryName}</TableCell>
        
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </Grid>
        
                            </Grid>
                            <Grid container className='expolor-beta-return-main-type-pf'>
                                <Grid item xs={8} sm={8} md={4} lg={3} className=''>
                                    <div className='dtls-report-expense-main-div'>
                                        <div className='dtls-report-expense-main-div-p '>
                                            <p className='Expense-Ration-report-p'>
                                                <span>SHARPE RATIO*</span>
        
                                            </p>
                                        </div>
                                        <div className='high-avg-low-main-pf'>
                                            <div>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only'>Scheme</p>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only-nd-data'>{(item.sharpRatio?.oneYrSharpRatio == null ? '-' : parseFloat(item.sharpRatio.oneYrSharpRatio).toFixed(2))}</p>
                                            </div>
                                            <div>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only'> </p>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only-nd-data'>V/S</p>
                                            </div>
                                            <div>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only'>Category</p>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only-nd-data'>{(item.sharpRatio?.oneYrCategorySharpRatio == null ? '-' : parseFloat(item.sharpRatio.oneYrCategorySharpRatio).toFixed(2))}</p>
                                            </div>
                                        </div>
                                        <div className='ratio-statement-differen-btn-all'>
                                            <p className='ratio-statement-differen-p-tag'>{item.sharpRatio?.statement}</p>
                                        </div>
                                    </div>
                                </Grid>
        
                                <Grid item xs={8} sm={8} md={4} lg={3} >
        
                                    <div className='dtls-report-expense-main-div'>
                                        <div className='dtls-report-expense-main-div-p dtls-report-expense-main-div-p-dulicate'>
                                            <p className='Expense-Ration-report-p'>
                                                <span>BETA*</span>
                                            </p>
                                        </div>
                                        <div className='high-avg-low-main-pf'>
                                            <div>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only'>Scheme</p>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only-nd-data'>{(item.betaRatio?.oneYrBeta == null ? '-' : parseFloat(item.betaRatio.oneYrBeta).toFixed(2))}</p>
                                            </div>
                                            <div>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only'></p>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only-nd-data'>V/S</p>
                                            </div>
                                            <div>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only'>Category</p>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only-nd-data'>{(item.betaRatio?.oneYrCategoryBeta == null ? '-' : parseFloat(item.betaRatio.oneYrCategoryBeta).toFixed(2))}</p>
                                            </div>
                                        </div>
                                        <div className='ratio-statement-differen-btn-all'>
                                            <p className='ratio-statement-differen-p-tag'>{item.betaRatio?.statement}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={8} sm={8} md={4} lg={3}>
                                    <div className='dtls-report-expense-main-div'>
                                        <div className='dtls-report-expense-main-div-p dtls-report-expense-main-div-p-dulicate-repet'>
                                            <p className='Expense-Ration-report-p'>
                                                <span>RETURNS*</span>
                                            </p>
                                        </div>
                                        <div className='high-avg-low-main-pf'>
                                            <div>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only'>Scheme</p>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only-nd-data'>{(item.returnList?.oneYrReturn == null ? '-' : parseFloat(item.returnList.oneYrReturn).toFixed(2))}</p>
                                            </div>
                                            <div>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only'></p>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only-nd-data'>V/S</p>
                                            </div>
                                            <div>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only'>Category</p>
                                                <p className='high-avg-main-pf-p-data main-low-avg-high-only-nd-data'>{(item.returnList?.oneYrCategoryReturn == null ? '-' : parseFloat(item.returnList.oneYrCategoryReturn).toFixed(2))}</p>
                                            </div>
                                        </div>
                                        <div className='ratio-statement-differen-btn-all'>
                                            <p className='ratio-statement-differen-p-tag'>{item.returnList?.statement}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <div>
                                    <span className='ratio-statement-portfolio-report'>*The above values are of 1 year</span>
                                </div>
                            </Grid>
        
                            <div className='portfolio-report-details-statement-first-second'>
                                <div className='portfolio-report-firststatement'>{item.topPerformerResponseArrays[0]?.firstStatement}</div>
                                <div className='portfolio-report-firststatement'>{item.topPerformerResponseArrays[0]?.secondStatement}</div>
                                {item.topPerformerResponseArrays[0]?.thirdStatement === '' || item.topPerformerResponseArrays[0]?.thirdStatement == null
                                  ? <></>
                                  : <div className=''>
                                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                sx={{
                                                  backgroundColor: '#f0f0f0',
                                                  margin: 0
                                                }}
                                            >
                                                <div style={{ padding: '14px 22px', color: '#003d6d', fontWeight: '600', fontSize: '16px' }}>
                                                    {item.topPerformerResponseArrays[0]?.thirdStatement}
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ padding: '1.5em' }}>
                                                <TableContainer>
                                                    <Table sx={{ width: '100%', marginBottom: '0px' }}>
                                                        <TableHead>
                                                            <TableRow className='statement-two-table-list'>
                                                                <TableCell className='sector-name-allocation-bg-color' sx={{ width: '50%' }}>Name</TableCell>
                                                                <TableCell className='sector-name-allocation-bg-color' sx={{ textAlign: 'center' }}>One Year CAGR Return in % </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                    </Table>
                                                </TableContainer>
                                                <div className='portfolio-report-secondstatement' id="style-13">
                                                    <TableContainer>
                                                        <Table sx={{ width: '100%', marginBottom: '0px' }}>
                                                            <TableBody>
                                                                {item.topPerformerResponseArrays[0]?.outperformingSchemes?.map((i:any, index:any) =>
                                                                    <TableRow className='portfolio-statement-tr-main-table' key={index}>
                                                                        <TableCell style={{ width: '50%' }} >{i.name}</TableCell>
                                                                        <TableCell style={{ textAlign: 'center' }} >{(i.oneYearReturn)}</TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
        
                                    </div>
                                }
                            </div>
        
                            {item.topFiveHoldings.length === 0
                              ? <></>
                              : <>
                                    <h2 className='report-Top-five-Holding'>Top Holdings of Fund </h2>
                                    <div className=''>
                                        <div className=''>
                                            <TableContainer>
                                                <Table className='' sx={{ width: '100%', marginBottom: '0px' }}>
                                                    <TableHead>
                                                        <TableRow className='top-five-holding-table'>
                                                            <TableCell className=''>Scrip</TableCell>
                                                            <TableCell className=''>Holding </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody style={{ fontSize: '18px' }}>
                                                        {item.topFiveHoldings.slice(0, dataTopHolding).map((i:any, index:any) =>
                                                            <TableRow className='top-ten-holding-list-main-tr' key={index}>
                                                                <TableCell >{i.script}</TableCell>
                                                                <TableCell >{i.holding.toFixed(2)}%</TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                        <div style={{ textAlign: 'right', marginBottom: '15px' }}>
                                            {
                                                readmore
                                                  ? <span className='readmpore-less-pr-detals-duppp' onClick={HoldingReadmore}>View More</span>
                                                  : <></>
                                            }
                                        </div>
        
                                    </div></>}
        
                                    {item.sectorWiseResponseArrays?.some((sectorObj:any) => sectorObj.value !== 0) && (
                <div>
                  <h2 className='report-Top-five-Holding'>Top 10 Sector Allocation</h2>
                  <div className='sub-main-report-Top-five-Holding-div'>
                     <PolarArea
                      options={{
                        plugins: {
                          legend: {
                            display: true,
                            position:'left',
                            labels:{
                                color: 'rgb(11, 0, 0)',
                                usePointStyle: true,
                            }
                          },
                          tooltip: {
                            enabled: true
                          }
                        }}}
                     
                     data={{
                     labels: item.sectorWiseResponseArrays?.filter((auto:any)=>auto.value!=0).map((i:any)=>(i.name)),
                     datasets: [
                       {
                         data:item.sectorWiseResponseArrays?.filter((auto:any)=>auto.value!=0).map((i:any)=>(i.value)),
                         backgroundColor: [
                          "#ddcfe8",
                           "#b2dce3",
                           "#c3edca",
                           "#dedede",
                           "#f6d1ab",
                           "#b1dffa",
                           "#f5e9d2",
                           "#615376",
                           "#bccddb",
                           "#dfcbb1"
 
                         ],
                         borderWidth: 1,
                       },
    
                     ],
 
                   }} />
                         </div>
                </div>
                                    )}
                        </div>
                    ))}
                    </Box>
                </ThemeProvider>
                  )}
    </div>
  )
}

export default DetailedPortfolio