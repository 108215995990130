export const bondEnum = {
    BondTypeEnum : [
        { label :"Corporate Bond", value:"CORPORATE_BOND" },
        { label :"G Sec", value:"G_SEC" },
        { label :"SDL", value:"SDL" },
    ],
    CallPutDateEnum: [
        {label:"Yes", value:"YES"},
        {label:"No" ,value:"NO"},
    ],
    CouponBasisEnum: [
        {label:"Fixed Rate", value:"FIXED_RATE"},
        {label:"Variable Rate", value:"VARIABLE_RATE"},
        {label:"Zero Coupon", value:"ZERO_COUPON"},
        {label:"MLDs", value:"MLDS"},
    ],
    CouponFrequencyEnum:  [
        {label:"Annual", value:"ANNUAL"},
        {label:"Half Yearly", value:"HALF_YEARLY"},
        {label:"Monthly", value:"MONTHLY"},
        {label:"Quarterly", value:"QUARTERLY"},
        {label:"Cumulative", value:"CUMULATIVE"},
    ],
    DayCountEnum:  [
        {label:"30 / 360", value:"ACTUAL"},
        {label:"Actual / Actual", value:"ACTUAL_ACTUAL"},

    ],
    GaurentedEnum:  [
        {label:"State Government", value:"STATE_GOVERNMENT"},
        {label:"Central Government", value:"CENTRAL_GOVERNMENT"},
        {label:"No", value:"NO"},
    ],
    IssuerTypeEnum:  [
        {label:"NBFC", value:"NBFC"},
        {label:"HOUSING FINANCE COMPANY", value:"HOUSING_FINANCE_COMPANY"},
        {label:"POWER SECTOR", value:"POWER_SECTOR"},
        {label:"OIL & GAS", value:"OIL_AND_GAS"},
        {label:"BANK", value:"BANK"},
        {label:"INSURANCE COMPANY", value:"INSURANCE_COMPANY"},
        {label:"CORPORATE", value:"CORPORATE"},
        {label:"MUNICIPAL CORPORATION", value:"MUNICIPAL_CORPORATION"},
        {label:"PSU", value:"PSU"},
        {label:"MICRO FINANCE", value:"MICRO_FINANCE"},
        {label:"INFRASTRUCTURE", value:"INFRASTRUCTURE"},
        {label:"OTHERS", value:"OTHERS"},
    ],
    ListedUnlistedEnum:  [
        {label:"Listed", value:"LISTED"},
        {label:"Unlisted", value:"UNLISTED"},
    ],
    ModeOfIssuanceEnum:  [
        {label:"Public Issue", value:"PUBLIC_ISSUE"},
        {label:"Private Placement", value:"PRIVATE_PLACEMENT"},
        {label:"Bonus", value:"BONUS"},
    ],
    PsuNonPsuEnum:  [
        {label:"PSU", value:"PSU"},
        {label:"Non PSU", value:"NON_PSU"},
    ]

,    RepaymentEnum:  [
        {label:"Senior", value:"SENIOR"},
        {label:"Subordinate", value:"SUBORDINATE"},
        {label:"Perpetual", value:"PERPETUAL"},
    ],
    SecuredUnsecuredEnum:  [
        {label:"Secured", value:"SECURED"},
        {label:"Unsecured", value:"UNSECURED"},
    ],
    TaxabilityEnum:  [
        {label:"Taxable", value:"TAXABLE"},
        {label:"Tax Free", value:"TAX_FREE"},
    ],
    TierEnum:  [
        {label:"TIER 1", value:"TIER_1"},
        {label:"ADDITIONAL TIER 1", value:"ADDITIONAL_TIER_1"},
        {label:"TIER 2", value:"TIER_2"},
    ],

}