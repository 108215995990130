import React from 'react'
import { Link } from 'react-router-dom'
import newreleaseblog_01 from "../images/newreleaseblog_01.png"
import "../css/blogs1.css"
import { Container } from '@mui/material'

const Blog2 = () => {
  return (
    <Container maxWidth="lg">
    <section className="blog-single">
	<div className="container cont1">
		<div className="row">
			<div className="col-md-10 m-auto single-page-col-main">
		
				<article className="single-post">
				
					<div className="post-title text-center">
						<h3 className='blog-single-main-education-h1'>
                        <p>Tips for Indian parents to plan for the rising cost of higher education</p>
                        </h3>
					    <ul className="list-inline post-tag single-blog-date">
						  <li className="list-inline-item">
								<span>September 26, 2021</span>
							</li>
						</ul>
					</div>
				
					<div className="post-body">
				     <div className="feature-image" style={{textAlign:'center'}}>
						<img src={newreleaseblog_01} alt="" style={{width:"43%"}} />
						</div>
						<div className="blog-page-desp-p-tag">
    <p>The young (18-29 years) in India constitute 22 per cent of its population. People falling
under this age bracket are the potential consumers of the higher education industry of
India, as well as the world. With the rising awareness of the importance of education
among people, the education industry has seen considerable growth not only in the
increased number of young population enrolling in colleges and degree courses, but
also has witnessed a steep increase in the cost of education. This dramatic increase in
the cost of higher education has put a lot of burden on parents, who are struggling hard
to fulfil the financial needs of schooling their children. The children, therefore, have to
compromise on the quality of education, either by choosing underrated universities or
compromising on the choice of course. Though the government colleges and institutes
offer the quality courses at much lesser cost than the private institutes, but due to
limited number of seats and higher cut-off, not all students manage to get admission.
Whereas, the renowned private colleges which provide quality education and best in
class facilities are out of reach to the average person being expensive. So, what can
parents do or should do to be well-prepared in advance to secure their children's future
by saving enough funds till the time their children reach the age for higher education?
    </p>
    
    <p>Here are a few pointers that every parent must keep in mind, to be able to fund their
child's higher education effortlessly.

    </p>
    
    <p>
       <h4> 1) Start Early:</h4> As we say, "Early Bird Catches The Worm", so do the early planning and investments
help you fetch more returns. From the time one becomes a parent, they automatically
start feeling a sense of responsibility towards the newborn, of providing them the best
of education leading to a promising career and to be able to find a suitable match  and
spend lavishly on the weddings. So, it is quite essential that you start saving for your
child's education as early as possible.
    </p>
    
    <p>
       <h4> 2) Invest Smartly:</h4> An early start is just not enough, investing optimally also matters. To save effectively
and substantially you need to plan strategically. <b>A thoughtful investment at the correct time helps you save more.</b>
    </p>
    
    <p>
       <h4> 3)  Setting Your Goals Well In Advance:</h4> Early discovery of your goal gives you the dominance of the situation. Knowing what
degree course your child seeks to pursue and which country or university your child
wants to study, makes your calculation much easier. Now you have a tentative estimate
of the cost of education you need to bear and therefore you can calculate the monthly
savings that you would require to fulfil the stipulated demand
    </p>
    
    <p>
       <h4> 4)  Go For Realistic And Achievable Goals:</h4> One must realize his financial conditions and monthly income before setting out a
particular amount as a monthly saving to be invested for a child's education plan.
Because being consistent is a crucial part of achieving your long term goals.

    </p>
    <p><h4>5) Take Help of Experts:</h4> Unless you are involved and well versed with the financial products, it is arduous and
baffling for you to choose the best investment options available, as the market is
flooded with innumerable alternatives and choices. Taking advice from experts who are
well experienced and proficient in both career counselling and financing is advisable for
a better and secured approach. These experts help you with all the aspects concerning
the education of your child, right from the discovery journey of education destination,
analysing cost, comparing finance options to making an informed choice. Such experts
and platforms make your task easy by providing quick and simple, easy to use
technology backed by expertise and intelligence.</p>
<br/>
<p>These 5 simple ways will help you achieve your children's quality education goal and
thereby allow them to follow their dreams and pursue studies of their choice of place
and university without being concerned about the amount of money required for their
studies.</p>
</div>
					</div>
				</article>
			
			
			</div>
		</div>
		
<div className='wrapper-next-butoon-blogs-span-main'>
<div className="wrapper-next-butoon-blogs">
  <a className="cta-privious" href="">
    {/* <span className='wrapper-next-butoon-blogs-span'>NEXT</span> */}
	{/* <Link to={"/BlogSingle"}><span className='wrapper-next-butoon-blogs-span'>PREVIOUS</span> </Link>
    <span className='wrapper-next-butoon-blogs-span'>
      <svg style={{transform: "rotate(180deg)"}} width="px" height="43px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path className="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path className="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path className="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
        </g>
      </svg>
    </span>  */}
  </a>
</div>
<div className='wrapper-next-butoon-blogs-home-main1'>
{/* <Link to={"/Blogs"}><span className='wrapper-next-butoon-blogs2-span'><AiOutlineHome className='wrapper-next-butoon-blogs2-home-icone' title="HOME"></AiOutlineHome></span> </Link> */}
</div>
<div className="wrapper-next-butoon-blogs">
  <a className="cta" href="">
	{/* <Link to={"/BlogSingle3"}><span className='wrapper-next-butoon-blogs-span'>NEXT</span> </Link>
    <span className='wrapper-next-butoon-blogs-span'>
      <svg width="px" height="43px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path className="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path className="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path className="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
        </g>
      </svg>
    </span>  */}
  </a>
</div>
</div>










    {/* <div className="blog-single-related-artical">
		<div className="row">
			<div className="col-12 ">
			<h2 className='blog-single-artical-h2'>Related Articles</h2>
			</div>
		</div>
		<div className="row">
			<div className="col-lg-4 col-md-6">
               <article className="post-sm">
				
					<div className="post-thumb">
						<a href="blog-single.html"><img src={post} alt="" /></a>
					</div>
				
					<div className="post-title blog-single-post-title">
						<h3><a href="blog-single.html">Praesent sapien massa, convallis a pellentesque nec</a></h3>
					</div>
				
					<div className="post-meta">
						<ul className="list-inline post-tag">
							<li className="list-inline-item">
							
                                <img src={feature} alt="" />
							</li>
							<li className="list-inline-item">
								<a href="#">Rozy Afzal</a>
							</li>
							<li className="list-inline-item">
								August 22, 2021
							</li>
						</ul>
					</div>
				
					<div className="post-details">
						<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. </p>
					</div>
				</article>
			</div>
			<div className="col-lg-4 col-md-6">
		
				<article className="post-sm">
		
					<div className="post-thumb">
						<a href="blog-single.html"><img src={posts} alt="" /></a>
					</div>
				
					<div className="post-title blog-single-post-title">
						<h3><a href="blog-single.html">Praesent sapien massa, convallis a pellentesque nec</a></h3>
					</div>
				
					<div className="post-meta">
						<ul className="list-inline post-tag">
							<li className="list-inline-item">
							
                                <img src={feature} alt="" />
							</li>
							<li className="list-inline-item">
								<a href="#">Rozy Afzal</a>
							</li>
							<li className="list-inline-item">
								August 14, 2021
							</li>
						</ul>
					</div>
				
					<div className="post-details">
						<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. </p>
					</div>
				</article>
			</div>
			<div className="col-lg-4 col-md-6">
		
				<article className="post-sm">
				
					<div className="post-thumb">
						<a href="blog-single.html"><img src={postes} alt="" /></a>
					</div>
				
					<div className="post-title blog-single-post-title">
						<h3><a href="blog-single.html">Praesent sapien massa, convallis a pellentesque nec</a></h3>
					</div>
			
					<div className="post-meta">
						<ul className="list-inline post-tag">
							<li className="list-inline-item">
							
                                <img src={feature} alt="" />
							</li>
							<li className="list-inline-item">
								<a href="#">Rozy Afzal</a>
							</li>
							<li className="list-inline-item">
								August 11, 2021
							</li>
						</ul>
					</div>
				
					<div className="post-details">
						<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. </p>
					</div>
				</article>
			</div>
		</div>
	</div> */}
	</div>
    </section>
    </Container>
  )
}

export default Blog2