import React from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TextField,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useQuery } from "react-query";
import {
  GetBrokeragePayoutByCompanyMasterCode,
  GetByIdProductList,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  product_listOfAllProductType,
} from "../api/Api";
import { withParam } from "../utils/Router.Helper";
import TableHeadData from "./TableHeadData";

const DistributorUnlisted = (props: any) => {
  const [distributorCode, setDistributorCode] = React.useState("");
  const [distributorUserCode, setDistributorUserCode] = React.useState("");
  const [JV, setJV] = React.useState("");

  const companyMasterCode = !!sessionStorage.getItem("CompanyData")
    ? sessionStorage.getItem("CompanyData")
    : !!sessionStorage.getItem("CompanyMasterCode")
    ? sessionStorage.getItem("CompanyMasterCode")
    : "";

  const { data } = useQuery(
    "UnlistedProductListByID",
    () =>
      GetByIdProductList(
        props.params.unlistedMasterCode,
        "?companyMasterCode=" + companyMasterCode
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    [
      "userProfileByCompany",
      sessionStorage.getItem("CompanyData"),
      sessionStorage.getItem("CompanyTypeCode"),
    ],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        sessionStorage.getItem("CompanyTypeCode") === "S"
          ? "E"
          : sessionStorage.getItem("CompanyTypeCode"),
        sessionStorage.getItem("CompanyData")
      ),
    {
      enabled:
        !!sessionStorage.getItem("CompanyData") &&
        !!sessionStorage.getItem("CompanyTypeCode"),
      refetchOnWindowFocus: false,
    }
  );

  // LIST OF ALL PRODUCT TYPES
  const { data: listOfAllProductType } = useQuery(
    "ListOfAllProductTypes",
    product_listOfAllProductType,
    {
      refetchOnWindowFocus: false,
    }
  );
  // fetch UNLISTED product type code
  let productTypeCodeFromList = listOfAllProductType?.data?.productType.filter(
    (productObj: any) => productObj.productName === "UNLISTED"
  )[0].productTypeCode;

  // FETCH BROKERAGE PAYOUT
  const { data: fetchBrokeragePayout } = useQuery(
    "GetBrokeragePayoutByCompanyMasterCode",
    () => GetBrokeragePayoutByCompanyMasterCode(companyMasterCode),
    {
      refetchOnWindowFocus: false,
    }
  );
  const filteredUnlistedBrokeragePayout =
    fetchBrokeragePayout?.data?.companyBrokerage?.filter(
      (brokerageObj: any) =>
        brokerageObj.productTypeCode === productTypeCodeFromList
    );
  // destructuring brokerage payout from the filtered array and assign null if the array is empty
  let brokeragePayout = filteredUnlistedBrokeragePayout?.length
    ? filteredUnlistedBrokeragePayout[0]
    : null;

  return (
    <>
      <Container maxWidth="xl" sx={{ padding: 0 }}>
        <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "3%" }}>
          {data && (
            <>
              <Grid container mb={4}>
                <Grid item md={4} sx={{ display: "flex" }}>
                  <img
                    src={data.data.unlistedList[0].scriptLogo}
                    style={{ width: "80px", height: "80px" }}
                    alt=""
                  />
                  <div style={{ marginLeft: "1rem" }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={"400"}
                      gutterBottom
                    >
                      {" "}
                      <b>Script Name :</b>{" "}
                      {data.data.unlistedList[0].scriptName}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={"400"}
                      gutterBottom
                    >
                      {" "}
                      <b>ISIN :</b> {data.data.unlistedList[0].isinNumber}
                    </Typography>

                    {sessionStorage.getItem("Profile") === "B" ||
                    sessionStorage.getItem("Profile") === "E" ? (
                      <>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={
                            UserProfilesByCompanyMasterCode
                              ? UserProfilesByCompanyMasterCode.data.userProfile.map(
                                  (options: any) => options
                                )
                              : []
                          }
                          getOptionLabel={(options: any) => options?.userCode}
                          sx={{ width: 308, mt: 2 }}
                          onChange={(options: any, newValue) => {
                            setDistributorCode(
                              newValue ? newValue.employeeCode : ""
                            );
                            setDistributorUserCode(
                              newValue ? newValue.userCode : ""
                            );
                            // sessionStorage.setItem("CompanyData",newValue?.employeeCode)
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label="Select User"
                              size="small"
                            />
                          )}
                        />
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          label="JV"
                          onChange={(e: any) => setJV(e.target.value)}
                          sx={{ width: 308, mt: 2 }}
                          size="small"
                          // {...register("clientSaleRate")}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
              </Grid>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 1070 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ width: 150 }}>
                        Order Date
                      </TableCell>
                      {/* manufacture cost price */}
                      <TableCell align="center" sx={{ width: 150 }}>
                        Manufacture Cost Price
                      </TableCell>
                      <TableCell align="center" sx={{ width: 150 }}>
                        Manufacture Carrying Cost
                      </TableCell>
                      <TableCell align="center" sx={{ width: 150 }}>
                        Landing Price
                      </TableCell>
                      <TableCell align="center" sx={{ width: 150 }}>
                        Sell Price
                      </TableCell>
                      {/* <TableCell align='center'>TCS (0.1%)</TableCell> */}
                      <TableCell align="center">Total</TableCell>
                      <TableCell align="center" sx={{ width: 150 }}>
                        Bid Quantity
                      </TableCell>
                      <TableCell align="center" sx={{ width: 350 }}>
                        Client
                      </TableCell>
                      {/* // hide rm revenue and distributor payout  */}
                      {sessionStorage.getItem("Profile") === "E" &&
                      sessionStorage.getItem("CompanyTypeCode") ===
                        "S" ? null : (
                        <>
                          <TableCell align="center">RM Revenue</TableCell>
                          <TableCell align="center">
                            Distributor Payout
                          </TableCell>
                        </>
                      )}
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">Order</TableCell>
                      {/* <TableCell align="center">Add To Proposal</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.data?.unlistedList?.map((item: any) => (
                      <TableHeadData
                        item={item}
                        distributorCode={distributorCode}
                        JV={JV}
                        companyMasterCode={companyMasterCode}
                        distributorUserCode={distributorUserCode}
                        brokeragePayout={
                          brokeragePayout ? brokeragePayout : null
                        }
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default withParam(DistributorUnlisted);
