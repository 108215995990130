import { TableRows } from "@mui/icons-material"
import { Autocomplete, Box, Button, Chip, Grid, Stack, TableCell, TableRow, TextField, Typography } from "@mui/material"
import React from 'react'
import { useForm } from "react-hook-form"
import { TextFieldSizeSmall, mb } from "../../Constants/Color"
import { convertDate }  from "../../utils/Helper"
import { GetPriceByYtm, GetYtmYtcByPrice } from "../../api/Api"
import { useQuery } from "react-query"
import { useId } from 'react';
import axios from "axios"
import { toast } from "react-toastify"

const QuoteTableData = (props:any) => {
    const TextId = useId()
    const row:any = props.row;
    const [ edit, setEdit ] = React.useState(true)

    const [refatchYtmYtc, setrefatchYtmYtc] = React.useState(false)
    const [refatchPrice, setrefatchPrice] = React.useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
      } = useForm({ mode: "onBlur" });

      const registerFunctions:any = {};
      registerFunctions[TextId] = register;

      const newSetValueFunction:any = {};
      newSetValueFunction[TextId]=setValue;

      const newGetValueFunction:any = {};
      newGetValueFunction[TextId]=getValues;
      console.log("row",props)
    //   console.log("registerSetFunctions",newSetValueFunction[TextId])
    //   console.log("registerGetFunctions",newGetValueFunction[TextId])

      const onSubmit = (data: any) => {
        const DataDto = { 
            ...data,
            productMasterCode:row.productMasterCode,
            quoteDate:new Date(),
            isActive:true,
            isinNumber:row.isinNumber
        }
        console.log("DataDto",DataDto)
        axios.put("quoteDetails/updateQuoteDetails",DataDto)
            .then(()=>{props.refatch();toast.success("Quote added Successfully")})
            .catch((res)=>toast.error(res.response.data.status.message.title))
            
      }

      const registerOptions = {
        // isinNumber: { required: "Isin is required" },
        // minValue: { required: "Min Value is required" },
        salePrice:{ required: "Sale Price is required" },
        saleYtm:{ required: "Sale YTM is required" },
        saleYtc:{ required: "Sale YTC is required" },      
        multiple:{ required: "Multiple Value is required" },
        minValue:{ required: "Minimum Value is required" },
        maxValue:{ required: "Maximum Value is required" },
        ytc:{ required: "Manufacturer YTC is required" },
        ytm:{ required: "Manufacturer YTM is required" },
        manufacturerPrice:{ required: "Manufacturer Price is required" },
        employeePrice:{ required: "Employee Price is required" },
        employeeYtc:{ required: "Employee YTC is required" },
        employeeYtm:{ required: "Employee YTM is required" },
    };

    const { data:YtmYtcvalue } = useQuery("YtmYtcDetails"+TextId,()=>GetYtmYtcByPrice(row.isinNumber,newGetValueFunction[TextId]("manufacturerPrice"),newGetValueFunction[TextId]("salePrice"),newGetValueFunction[TextId]("employeePrice")),{
        // The query will not execute until the userId exists
          enabled: !!row.isinNumber && (!!newGetValueFunction[TextId]("salePrice") || !!newGetValueFunction[TextId]("manufacturerPrice")) && refatchYtmYtc,
          refetchOnWindowFocus: false,
          onSuccess:(data:any)=>{
            newSetValueFunction[TextId]("saleYtm",data.data.xirrNXnpvDTO.saleYtm);
            newSetValueFunction[TextId]("saleYtc",data.data.xirrNXnpvDTO.saleYtc);
            newSetValueFunction[TextId]("ytm",data.data.xirrNXnpvDTO.ytm);
            newSetValueFunction[TextId]("ytc",data.data.xirrNXnpvDTO.ytc);
            setrefatchYtmYtc(false)
        }})
    
        const { data:getPrice } = useQuery("PriceDetails"+TextId,()=>GetPriceByYtm(row.isinNumber,newGetValueFunction[TextId]("ytm"),newGetValueFunction[TextId]("saleYtm"),newGetValueFunction[TextId]("employeeYtm")),{
            // The query will not execute until the userId exists
              enabled: !!row.isinNumber && !!newGetValueFunction[TextId]("ytm") && !!newGetValueFunction[TextId]("saleYtm") && refatchPrice,
              refetchOnWindowFocus: false,
              onSuccess:(data:any)=>{
                console.log("Menu Price",data.data.xirrNXnpvDTO)
                newSetValueFunction[TextId]("manufacturerPrice",data.data.xirrNXnpvDTO.manufacturerPrice);
                newSetValueFunction[TextId]("salePrice",data.data.xirrNXnpvDTO.salePrice);
                setrefatchPrice(false)
                // setValue("ytm",data.data.xirrNXnpvDTO.ytm);
                // setValue("ytc",data.data.xirrNXnpvDTO.ytc);
            }})

    function checkValue(value:any){
        return !!value?value:"-"
    }

    function get_Ytm_Ytc(event:any){
        setrefatchYtmYtc(true)
    }
    
    function get_Price(event:any){
        setrefatchPrice(true)
    }

    const active_inactive_quote = (productMasterCode:any) => {
        axios.put(`quoteDetails/activeNInActive?productMasterCode=${productMasterCode}`).then((res:any)=>{
            toast.success(res?.data?.status?.message?.title);
            props.refatch();
          }).catch((err:any)=>{
            toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title)
          })
    }

  return (

                    <TableRow>
                        <TableCell sx={{p:1}}>
                        <Chip 
                            variant="outlined" 
                            label={ row.issuerName === undefined || row.coupon === undefined ?  row?.abbreviation + " / " + convertDate(row.maturityDate)  : row?.abbreviation + " / " + row.isinNumber + " / " + row.coupon + "% / " + convertDate(row.maturityDate) } /> 
                        </TableCell>
                        <TableCell sx={{p:0}} align="center"> 
                        { !edit ? <form onSubmit={handleSubmit(onSubmit)} id="QuoteBookForm">  
                                <TextField
                                id={"outlined-basic"+TextId}
                                label="Min Value"
                                variant="outlined"
                                fullWidth
                                defaultValue={row.minValue}
                                error={errors.minValue ? true : false}
                                helperText={
                                    errors.minValue === undefined
                                    ? ""
                                    : errors.minValue.message + ""
                                }
                                {...registerFunctions[TextId]("minValue",registerOptions.minValue)}
                                InputProps={{ readOnly: edit }}
                                size={TextFieldSizeSmall}
                                sx={{
                                    ".css-11t7jcx-MuiInputBase-root-MuiOutlinedInput-root":{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                /> 
                            </form> : row.minValue.toLocaleString('en-IN', {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: 'INR'
}) }
                        </TableCell> 

                        <TableCell sx={{p:0}} align="center"> 
                          { !edit ? <form onSubmit={handleSubmit(onSubmit)} id="QuoteBookForm">  
                                <TextField
                                id={"outlined-basic"+TextId}
                                label="Multiple"
                                variant="outlined"
                                fullWidth
                                defaultValue={row.multiple}
                                error={errors.multiple ? true : false}
                                helperText={
                                    errors.multiple === undefined
                                    ? ""
                                    : errors.multiple.message + ""
                                }
                                {...registerFunctions[TextId]("multiple",registerOptions.multiple)}
                                InputProps={{ readOnly: edit }}
                                size={TextFieldSizeSmall}
                                sx={{
                                    ".css-11t7jcx-MuiInputBase-root-MuiOutlinedInput-root":{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                /> 
                            </form> : row.multiple.toLocaleString('en-IN', {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: 'INR'
}) }
                        </TableCell>

                        <TableCell sx={{p:0}} align="center"> 
                          { !edit ? <form onSubmit={handleSubmit(onSubmit)} id="QuoteBookForm">  
                                <TextField
                                id={"outlined-basic"+TextId}
                                label="Max Value"
                                variant="outlined"
                                fullWidth
                                defaultValue={row.maxValue}
                                error={errors.maxValue ? true : false}
                                helperText={
                                    errors.maxValue === undefined
                                    ? ""
                                    : errors.maxValue.message + ""
                                }
                                {...registerFunctions[TextId]("maxValue",registerOptions.maxValue)}
                                InputProps={{ readOnly: edit }}
                                size={TextFieldSizeSmall}
                                sx={{
                                    ".css-11t7jcx-MuiInputBase-root-MuiOutlinedInput-root":{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                /> 
                            </form> : row.maxValue.toLocaleString('en-IN', {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: 'INR'
}) }
                         </TableCell>
                        
                        <TableCell sx={{p:0}} align="center"> 
                        { !edit ? <form onSubmit={handleSubmit(onSubmit)} id="QuoteBookForm">  
                                <TextField
                                id={"outlined-basic"+TextId}
                                label="Manufacture YTM"
                                variant="outlined"
                                fullWidth
                                defaultValue={row.ytm}
                                error={errors.ytm ? true : false}
                                helperText={
                                    errors.ytm === undefined
                                    ? ""
                                    : errors.ytm.message + ""
                                }
                                {...registerFunctions[TextId]("ytm",registerOptions.ytm)}
                                InputProps={{ readOnly: edit }}
                                size={TextFieldSizeSmall}
                                sx={{
                                    ".css-11t7jcx-MuiInputBase-root-MuiOutlinedInput-root":{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                /> 
                            </form> : row.ytm }
                        </TableCell>

                        <TableCell align="center" sx={{p:0}}> 
                        { !edit ? <form onSubmit={handleSubmit(onSubmit)} id="QuoteBookForm">  
                                <TextField
                                id={"outlined-basic"+TextId}
                                label="Manufacture Ytc"
                                variant="outlined"
                                fullWidth
                                defaultValue={row.ytc}
                                error={errors.ytc ? true : false}
                                helperText={
                                    errors.ytc === undefined
                                    ? ""
                                    : errors.ytc.message + ""
                                }
                                {...registerFunctions[TextId]("ytc",registerOptions.ytc)}
                                InputProps={{ readOnly: edit }}
                                size={TextFieldSizeSmall}
                                sx={{
                                    ".css-11t7jcx-MuiInputBase-root-MuiOutlinedInput-root":{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                /> 
                            </form> : row.ytc } 
                        </TableCell>

                        <TableCell sx={{p:0}} align="center"> 
                        { !edit ? <form onSubmit={handleSubmit(onSubmit)} id="QuoteBookForm">  
                                <TextField
                                id={"outlined-basic"+TextId}
                                label="Manufacture Price"
                                variant="outlined"
                                fullWidth
                                defaultValue={row.manufacturerPrice}
                                error={errors.manufacturerPrice ? true : false}
                                helperText={
                                    errors.manufacturerPrice === undefined
                                    ? ""
                                    : errors.manufacturerPrice.message + ""
                                }
                                {...registerFunctions[TextId]("manufacturerPrice",registerOptions.manufacturerPrice)}
                                InputProps={{ readOnly: edit }}
                                size={TextFieldSizeSmall}
                                sx={{
                                    ".css-11t7jcx-MuiInputBase-root-MuiOutlinedInput-root":{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                /> 
                            </form> : row.manufacturerPrice }
                        </TableCell>

                        <TableCell sx={{p:0}} align="center"> 
                        { !edit ? <form onSubmit={handleSubmit(onSubmit)} id="QuoteBookForm">  
                                <TextField
                                id={"outlined-basic"+TextId}
                                label="Sale YTM"
                                variant="outlined"
                                fullWidth
                                defaultValue={row.saleYtm}
                                error={errors.saleYtm ? true : false}
                                helperText={
                                    errors.saleYtm === undefined
                                    ? ""
                                    : errors.saleYtm.message + ""
                                }
                                {...registerFunctions[TextId]("saleYtm",registerOptions.saleYtm)}
                                InputProps={{ readOnly: edit }}
                                size={TextFieldSizeSmall}
                                sx={{
                                    ".css-11t7jcx-MuiInputBase-root-MuiOutlinedInput-root":{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                /> 
                            </form> : row.saleYtm }
                        </TableCell>

                        <TableCell sx={{p:0}} align="center"> 
                        { !edit ? <form onSubmit={handleSubmit(onSubmit)} id="QuoteBookForm">  
                                <TextField
                                id={"outlined-basic"+TextId}
                                label="Sale YTC"
                                variant="outlined"
                                fullWidth
                                defaultValue={row.saleYtc}
                                error={errors.saleYtc ? true : false}
                                helperText={
                                    errors.saleYtc === undefined
                                    ? ""
                                    : errors.saleYtc.message + ""
                                }
                                {...registerFunctions[TextId]("saleYtc",registerOptions.saleYtc)}
                                InputProps={{ readOnly: edit }}
                                size={TextFieldSizeSmall}
                                sx={{
                                    ".css-11t7jcx-MuiInputBase-root-MuiOutlinedInput-root":{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                /> 
                            </form>  : row.saleYtc }
                        </TableCell>

                        <TableCell sx={{p:0}} align="center"> 
                          { !edit ? 
                        <form onSubmit={handleSubmit(onSubmit)} id="QuoteBookForm">  
                                <TextField
                                id={"outlined-basic1-"+TextId}
                                label="Sale Price"
                                variant="outlined"
                                fullWidth
                                defaultValue={row.salePrice}
                                error={errors.salePrice ? true : false}
                                helperText={
                                    errors.salePrice === undefined
                                    ? ""
                                    : errors.salePrice.message + ""
                                }
                                {...registerFunctions[TextId]("salePrice",registerOptions.salePrice)}
                                InputProps={{ readOnly: edit }}
                                size={TextFieldSizeSmall}
                                sx={{
                                    ".css-11t7jcx-MuiInputBase-root-MuiOutlinedInput-root":{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                /> 
                            </form> : row.salePrice }
                        </TableCell>

                        <TableCell sx={{p:0}} align="center"> 
                          { !edit ? 
                        <form onSubmit={handleSubmit(onSubmit)} id="QuoteBookForm">  
                                <TextField
                                id={"outlined-basic1-"+TextId}
                                label="E. YTC"
                                variant="outlined"
                                fullWidth
                                defaultValue={row.employeeYtc}
                                error={errors.employeeYtc ? true : false}
                                helperText={
                                    errors.employeeYtc === undefined
                                    ? ""
                                    : errors.employeeYtc.message + ""
                                }
                                {...registerFunctions[TextId]("employeeYtc",registerOptions.employeeYtc)}
                                InputProps={{ readOnly: edit }}
                                size={TextFieldSizeSmall}
                                sx={{
                                    ".css-11t7jcx-MuiInputBase-root-MuiOutlinedInput-root":{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                /> 
                            </form> : row.employeeYtc }
                        </TableCell>

                        <TableCell sx={{p:0}} align="center"> 
                          { !edit ? 
                        <form onSubmit={handleSubmit(onSubmit)} id="QuoteBookForm">  
                                <TextField
                                id={"outlined-basic1-"+TextId}
                                label="E. YTM"
                                variant="outlined"
                                fullWidth
                                defaultValue={row.employeeYtm}
                                error={errors.employeeYtm ? true : false}
                                helperText={
                                    errors.employeeYtm === undefined
                                    ? ""
                                    : errors.employeeYtm.message + ""
                                }
                                {...registerFunctions[TextId]("employeeYtm",registerOptions.employeeYtm)}
                                InputProps={{ readOnly: edit }}
                                size={TextFieldSizeSmall}
                                sx={{
                                    ".css-11t7jcx-MuiInputBase-root-MuiOutlinedInput-root":{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                /> 
                            </form> : row.employeeYtm }
                        </TableCell>

                        <TableCell sx={{p:0}} align="center"> 
                          { !edit ? 
                        <form onSubmit={handleSubmit(onSubmit)} id="QuoteBookForm">  
                                <TextField
                                id={"outlined-basic1-"+TextId}
                                label="E. Price"
                                variant="outlined"
                                fullWidth
                                defaultValue={row.employeePrice}
                                error={errors.employeePrice ? true : false}
                                helperText={
                                    errors.employeePrice === undefined
                                    ? ""
                                    : errors.employeePrice.message + ""
                                }
                                {...registerFunctions[TextId]("employeePrice",registerOptions.employeePrice)}
                                InputProps={{ readOnly: edit }}
                                size={TextFieldSizeSmall}
                                sx={{
                                    ".css-11t7jcx-MuiInputBase-root-MuiOutlinedInput-root":{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                /> 
                            </form> : row.employeePrice }
                        </TableCell>

                          <TableCell sx={{p:1}} align="right">
                            <form onSubmit={handleSubmit(onSubmit)} id="form1">
                            <Stack direction={'row'} spacing={2}> 
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={()=>active_inactive_quote(row.productMasterCode)}
                                size="small"
                                >
                                { row.isActive ? "Inactive" : "Active"}
                              </Button>
                              {edit ?
                              <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                disabled
                               >
                                YTM/YTC
                              </Button>
                              :
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={get_Ytm_Ytc}
                                size="small"
                                >
                                YTM/YTC
                              </Button>
                              }
                              {edit ?
                              <Button
                              color="primary"
                              variant="contained"
                              disabled
                              size="small"
                              >
                               Price
                            </Button>
                            :
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={get_Price}
                                size="small"
                                >
                                 Price
                              </Button>
                              }
                              { edit ?  
                                <Button
                                    color="primary"
                                    type="submit"
                                    id="form1"
                                    variant="contained"
                                    size="small"
                                    onClick={() =>setEdit(!edit)}>
                                    edit</Button>
                                : 
                                <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={()=>setEdit(!edit)}>
                                    Update</Button>
                                }   
                            </Stack> 
                        </form>    
                              
                          </TableCell>
                        </TableRow>
                   
  )
}

export default QuoteTableData