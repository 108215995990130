import { Box, CircularProgress, Switch, TableCell } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { AMC_ACTIVE_INACTIVE } from '../../utils/API_Names';

export const ManageAmcTableSwitch = (props:any) => {
	const { amcId, isActive, productCode } = props

	const [activeCircularProgress, SetActiveCircularProgress] = useState(false);
	const [checked, setChecked] = React.useState(isActive);

	React.useEffect(() => {
		setChecked(isActive)
	}, [props,isActive])
	

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		SetActiveCircularProgress(true)
		axios.put(AMC_ACTIVE_INACTIVE + amcId).then(()=>{
			SetActiveCircularProgress(false);
			setChecked(!event.target.checked);
			props.setRefresh(!props.refresh)
		}).catch((err:any)=>{
			SetActiveCircularProgress(false);
			toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title)	
		})
	  };	
  return (
	<>
		<Switch
			checked={checked}
			disabled={activeCircularProgress}
			onChange={handleChange}
			inputProps={{ 'aria-label': 'controlled' }} />
                <Box sx={{ pt:1 }}> {activeCircularProgress ? <CircularProgress size={20} /> : <></>}</Box>
	</>
  )
}

