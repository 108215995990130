import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useQuery } from "react-query";
import { listOfAllEmployeeFiles } from "../../api/Api";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import axios from "axios";
import { Viewer } from "@react-pdf-viewer/core";
import {
  Container,
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  Stack,
  Grid,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { style } from "../Clients";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";

const DropDownFiles = () => {
  const [accordionPanel, setAccordionPanel] = React.useState<String>("");
  const [expanded, setExpanded] = React.useState<String | false>(false);
  const [clientFile, setClientFile] = useState<any>("");
  const [clientDocId, setClientDocId] = useState<any>("");
  const [viewDocumentOpen, setViewDocumentOpen] = useState(false);

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const { data } = useQuery(
    ["getFile", accordionPanel],
    () => listOfAllEmployeeFiles(accordionPanel),
    {
      enabled: !!accordionPanel,
      staleTime: Infinity,
    }
  );

  const handleChange =
    (panel: any, ProductType: any) => (event: any, isExpanded: any) => {
      setExpanded(isExpanded ? panel : false);
      setAccordionPanel(ProductType);
    };

  const viewDocument = (fileType: any) => {
    axios.get("documentupload?documentId=" + fileType).then((res: any) => {
      setClientDocId(fileType);
      setClientFile(res?.data?.documentFilePath);
    });
  };

  const downloadDocument = (documentId: any) => {
    if (documentId) {
      axios.get("documentupload?documentId=" + documentId).then((res: any) => {
        axios({
          url: "documentupload/download?documentId=" + documentId,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", res.data.documentFileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      });
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1", "PRODUCT_OFFERING")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Product Offering</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {data &&
              data.data.reportResponseDTO.map((item: any) => (
                <>
                  <Typography variant="subtitle1" sx={{ cursor: "pointer" }}>
                    <InsertDriveFileIcon /> {item.month}
                    <Button
                      variant="contained"
                      sx={{ m: 1, marginLeft: "20px" }}
                      onClick={() => {
                        setViewDocumentOpen(true);
                        viewDocument(item.filePath);
                      }}
                    >
                      View
                    </Button>
                  </Typography>
                </>
              ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2", "PRODUCT_DESK")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Product Desk</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {data &&
              data.data.reportResponseDTO.map((item: any) => (
                <>
                  <Typography variant="subtitle1" sx={{ cursor: "pointer" }}>
                    <InsertDriveFileIcon /> {item.month}
                    <Button
                      variant="contained"
                      sx={{ m: 1, marginLeft: "20px" }}
                      onClick={() => {
                        setViewDocumentOpen(true);
                        viewDocument(item.filePath);
                      }}
                    >
                      View
                    </Button>
                  </Typography>
                </>
              ))}
          </AccordionDetails>
        </Accordion>
        {sessionStorage.getItem("Profile") === "E" ? (
          <>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3", "REVENUE_SHEET")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>Revenue Sheet</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {data &&
                  data.data.reportResponseDTO.map((item: any) => (
                    <>
                      <Typography
                        variant="subtitle1"
                        sx={{ cursor: "pointer" }}
                      >
                        <InsertDriveFileIcon /> {item.month}
                        <Button
                          variant="contained"
                          sx={{ m: 1, marginLeft: "20px" }}
                          onClick={() => {
                            setViewDocumentOpen(true);
                            viewDocument(item.filePath);
                          }}
                        >
                          {" "}
                          View{" "}
                        </Button>
                      </Typography>
                    </>
                  ))}
              </AccordionDetails>
            </Accordion>
          </>
        ) : (
          <></>
        )}
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4", "PRODUCT_COLLATERALS")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Product Collaterals</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* { data && data.data.reportResponseDTO.map((item:any)=>(
		      <Typography variant='subtitle1' onClick={()=>downloadTermSheet(item.documentType,item.documentId)} sx={{ cursor:"pointer" }}>
					<InsertDriveFileIcon/> { item.month }
		      </Typography>
			))} */}

            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() =>
                window.open(
                  "https://workdrive.zohopublic.in/external/43ee74057e0b3b71b4e55ecca1373abbe293809c05ab36c353192b3a8894c943"
                )
              }
            >
              Product Collaterals
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5", "contest")}
          sx={{ pb: 2 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Contest</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {data &&
              data.data.reportResponseDTO.map((item: any) => (
                <>
                  <Typography variant="subtitle1" sx={{ cursor: "pointer" }}>
                    <InsertDriveFileIcon /> {item.month}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ m: 1 }}
                    onClick={() => {
                      setViewDocumentOpen(true);
                      viewDocument(item.filePath);
                    }}
                  >
                    View
                  </Button>
                </>
              ))}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Modal
        keepMounted
        open={viewDocumentOpen}
        onClose={() => setViewDocumentOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <IconButton
            style={{
              position: "absolute",
              fontSize: "18px",
              top: "33px",
              right: "20px",
            }}
            onClick={() => setViewDocumentOpen(false)}
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </IconButton>
          {
            !!clientFile ? (
              clientFile.includes(".pdf") ? (
                <div
                  className="rpv-core__viewer"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    display: "flex",
                    flexDirection: "column",
                    height: "600px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      backgroundColor: "#eeeeee",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      justifyContent: "center",
                      padding: "4px",
                    }}
                  >
                    <ZoomOutButton />
                    <ZoomPopover />
                    <ZoomInButton />
                    <Tooltip title="Download">
                      <IconButton>
                        <CloudDownloadIcon
                          onClick={() => downloadDocument(clientDocId)}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      overflow: "hidden",
                    }}
                  >
                    {!!clientFile ? (
                      <Viewer
                        fileUrl={clientFile}
                        plugins={[zoomPluginInstance]}
                      />
                    ) : (
                      " No Data Found"
                    )}
                  </div>
                </div>
              ) : (
                <img
                  src={clientFile}
                  alt="image"
                  height={"50%"}
                  width={"auto"}
                />
              )
            ) : (
              ""
            )
            // <Typography variant="h6" textAlign={"center"} sx={{mt:"15%"}}>No Data Found</Typography>
          }
        </Box>
      </Modal>
    </>
  );
};

export default DropDownFiles;
