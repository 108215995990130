import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useQuery } from "react-query";
import {
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  getNcdById,
  listOfManufactureNDistributorCompanies,
} from "../../../api/Api";
import { useForm } from "react-hook-form";
import { withParam } from "../../../utils/Router.Helper";
import NcdTableHeadData from "./NcdTableHeadData";
import AddSeries from "./AddSeries";

const NcdScreener = (props: any) => {
  const { productMasterCode } = props.params;

  const [companyMasterCode, setCompanyMasterCode] = React.useState<any>();
  const [companyTypeCode, setCompanyTypeCode] = React.useState();
  const [distributorCode, setDistributorCode] = React.useState("");
  const [distributorUserCode, setDistributorUserCode] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data } = useQuery(
    ["getNcdDetail", productMasterCode],
    () => getNcdById(productMasterCode),
    {
      staleTime: Infinity,
    }
  );

  const {
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompanie",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled:
        sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E",
      staleTime: Infinity,
    }
  );

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", companyMasterCode, companyTypeCode],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        companyTypeCode === "S" ? "E" : companyTypeCode,
        companyMasterCode
      ),
    {
      enabled: !!companyMasterCode,
    }
  );

  return (
    <>
      <Grid
        container
        rowSpacing={4}
        columnSpacing={2}
        sx={{
          mb: 3,
        }}
      >
        {data &&
          data.data.ncdDTOS.map((ncd: any) => (
            <>
              <Grid
                container
                spacing={8}
                sx={{
                  justifyContent: "flex-start",
                  margin: "0 auto",
                  mb: 2,
                }}
              >
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <img
                    src={data.data.ncdDTOS[0].ncdCompanyLogo}
                    width={150}
                    alt="AMC logo"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={8}>
                  <Typography variant="h5" fontWeight={"bold"}>
                    {data.data.ncdDTOS[0]?.ncdName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} textAlign={"center"} sx={{ mb: 1 }}>
                <Grid item xs={12} sm={9} md={6} lg={3}>
                  <Typography variant="subtitle1">Issue Size</Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {data.data.ncdDTOS[0]?.issueSize} Cr
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={3}>
                  <Typography variant="subtitle1">Issue Price</Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {data.data.ncdDTOS[0]?.issuePrice}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={3}>
                  <Typography variant="subtitle1">Lot Size</Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {data?.data?.ncdDTOS[0]?.minimumLotSize}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={3}>
                  <Typography variant="subtitle1">
                    Minimum Investment Amount
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {data.data.ncdDTOS[0].minimumInvestment}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ))}

        {sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E" ? (
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={
                distributorCompanyList &&
                distributorCompanyList.data.companies.map(
                  (options: any) => options
                )
              }
              getOptionLabel={(options: any) =>
                options.companyName + " - " + options.companyTypeCode
              }
              fullWidth
              onChange={(options: any, newValue) => {
                setCompanyMasterCode(
                  newValue ? newValue.companyMasterCode : ""
                );
                setCompanyTypeCode(newValue ? newValue.companyTypeCode : "");
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Select Distributor Company"
                  size="small"
                />
              )}
            />
          </Grid>
        ) : (
          <></>
        )}

        {(sessionStorage.getItem("Profile") === "B" ||
          sessionStorage.getItem("Profile") === "E") &&
        UserProfilesByCompanyMasterCode ? (
          <>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={
                  UserProfilesByCompanyMasterCode
                    ? UserProfilesByCompanyMasterCode.data.userProfile.map(
                        (options: any) => options
                      )
                    : []
                }
                getOptionLabel={(options: any) =>
                  options?.userCode +
                  "- " +
                  options?.firstName +
                  " " +
                  options?.lastName
                }
                fullWidth
                onChange={(options: any, newValue) => {
                  setDistributorCode(newValue ? newValue.employeeCode : "");
                  setDistributorUserCode(newValue ? newValue.userCode : "");
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Select Distributor User"
                    size="small"
                  />
                )}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}

        {sessionStorage.getItem("Profile") === "B" && (
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Button variant="contained" onClick={handleOpen}>
              Add Series
            </Button>
            <AddSeries
              open={open}
              onClose={handleClose}
              data={""}
              ncdId={data && data?.data?.ncdDTOS[0]?.ncdId}
            />
          </Grid>
        )}
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1150 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Series Name</TableCell>
              <TableCell align="left">Interest Payout Frequency</TableCell>
              <TableCell align="left">Coupon Rate (%)</TableCell>
              <TableCell align="left">Tenure (Months)</TableCell>
              <TableCell align="left" sx={{ minWidth: 150 }}>
                Select Date
              </TableCell>
              <TableCell align="center" sx={{ minWidth: 300 }}>
                Select Client
              </TableCell>
              <TableCell align="left" sx={{ minWidth: 120 }}></TableCell>
              <TableCell align="left" sx={{ minWidth: 250 }}>
                Application Type
              </TableCell>
              <TableCell align="left" sx={{ minWidth: 150 }}>
                Quantity
              </TableCell>
              <TableCell align="left">Issue Price</TableCell>
              <TableCell align="left">Total</TableCell>
              <TableCell align="left" sx={{ minWidth: 100 }}>
                Revenue
              </TableCell>
              <TableCell align="left" sx={{ minWidth: 30 }}>
                Form Acknowledgement Number
              </TableCell>
              <TableCell align="left" sx={{ minWidth: 30 }}>
                Form Upload
              </TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.data.ncdDTOS[0]?.ncdSeriesDTO?.map((ncd: any) => (
                <NcdTableHeadData
                  data={ncd}
                  minimumInvestment={data?.data?.ncdDTOS[0]?.minimumInvestment}
                  minimumLotSize={data?.data?.ncdDTOS[0]?.minimumLotSize}
                  issuePrice={data?.data?.ncdDTOS[0]?.issuePrice}
                  revenueSharing={data?.data?.ncdDTOS[0]?.revenueSharing}
                  companyMasterCode={companyMasterCode}
                  companyTypeCode={companyTypeCode}
                  distributorCode={distributorCode}
                  distributorUserCode={distributorUserCode}
                  productMasterCode={ncd.productMasterCode}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withParam(NcdScreener);
