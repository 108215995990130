import { Box, Link, Button, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch, InputBase, IconButton } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
// import { Link } from 'react-router-dom';
import { GetAllManuUnlistedOrders, GetAllUnlDetails, GetAllUnlistedMasterDetails, getAllUnlistedShare } from '../../api/Api';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import ActiveUnlistedSortTable from './ActiveUnlistedSortTable';
import SearchIcon from '@mui/icons-material/Search';

const ActiveUnlisted = () => {
  
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredActiveUnlisted, setfilteredActiveUnlisted] = useState("");

    
    const {data} = useQuery("UnlistedScpNamScpLgo",GetAllManuUnlistedOrders);
    console.log("Data", data);
    
    const filteredActiveUnlistedList = data && data.data.unlistedDetailsList.filter((item:any) => {
      return (
        item.scriptName.toLowerCase().includes(searchTerm.toLowerCase()) || item.isinNumber.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });

    const handleSearch = (event:any) => {
      console.log("Search Item",event.target.value)
      setSearchTerm(event.target.value);
    };

    console.log("filteredActiveUnlisted",filteredActiveUnlistedList)
    
  return (
    <Container maxWidth='xl'>
        <Box sx={{ marginTop: "5%" }}>
        <div style={{ height: 400, width: '100%' }}>

        <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
                mb:2
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by Script Name"
                onChange={handleSearch}
                inputRef={input => input && input.focus()}
                autoFocus={true}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            
        {/* { data && filteredActiveUnlistedList && <ActiveUnlistedSortTable data={data && data.data.unlistedDetailsList}/> } */}
        { data && filteredActiveUnlistedList && <ActiveUnlistedSortTable data={filteredActiveUnlistedList}/> }
        </div>
        </Box>
    </Container>
  )
}

export default ActiveUnlisted