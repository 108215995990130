import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const UploadTermSheet = (props:any) => {
  const [termsheetReferanceNo, setTermsheetReferanceNo] = useState(props.data?.termsheetReferanceNo)
  const [FormUploadFile, setFormUploadFile] = useState<any>();
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const fileUpload = () => {
    if (FormUploadFile && termsheetReferanceNo !== "") {
      const formData = new FormData();
      formData.append("document", FormUploadFile)
      axios.post("product/order/termSheet/upload?termSheetRefNo=" + termsheetReferanceNo, formData)
        .then(() => toast.success("Term Sheet Uploaded Successfully!"))
        .catch((err: any) => { setshowCircularProgress(false); toast.error(err.response.data ? err.response.data.status.message.title : "Error") });
    }else{
      toast.error("Please Select a File")
    }
    }

  //console.log("error", errors)
  const registerOptions = {

    referenceNo: { required: "Enter Reference No" },
  }

  return (
    <>
      <Box
        sx={{ margin: "3%" }}
      >
        {/* <Typography variant='h4'>Upload Term Sheet</Typography> */}
        <Stack spacing={2} maxWidth={"100%"} alignItems={'center'} marginTop={2}>
          <Button variant='outlined' component='label'>
            Upload Term Sheet
            <input hidden type="file" accept='.pdf' name="Form Upload File" onChange={(event: any) => setFormUploadFile(event.target.files[0])} />
          </Button>
          <Typography variant="subtitle1">{!!!FormUploadFile ? " No file choosen" : "Selected File: " + FormUploadFile?.name}   </Typography>

          <form onSubmit={handleSubmit(fileUpload)}>
            <TextField
              id="outlined-basic"
              label="Reference No"
              variant="outlined"
              value={termsheetReferanceNo}
              onChangeCapture={(e:any) => setTermsheetReferanceNo(e.target.value)}
              error={errors.referenceNo ? true : false}
              helperText={!errors.referenceNo ? "" : errors.referenceNo.message + ""}
              {...register("referenceNo", registerOptions.referenceNo)}
              />
          </form>
          <form onSubmit={handleSubmit(fileUpload)}>
            <Button type="submit" variant="contained" >
              {showCircularProgress ? (
                <CircularProgress
                  color="secondary"
                  size={20}
                  thickness={5}
                />
              ) : (
                ""
              )}{"  "}
              Submit
            </Button>
          </form>
        </Stack>

      </Box>
    </>
  )
}

export default UploadTermSheet