import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  InputBase,
  Link,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/material";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SearchIcon from "@mui/icons-material/Search";

const PartnerManagement = () => {
  const [getCompanyByType, setgetCompanyByType] = useState([]);
  const [CompanyType, setCompanyType] = useState(
    sessionStorage.getItem("Profile") === "B" ? "M" : "D"
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);
  const [selectedCompanyType, setSelectedCompanyType] = useState(null);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSearchPartner = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = () => {
    // Toggle between "asc" and "desc" when clicking on the sorting button
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    // setgetCompanyByType([]);
    axios
      .get(
        "company/listOfManufactureNDistributorCompanies/companyTypeCode?companyTypeCode=" +
          CompanyType
      )
      .then((res: any) => {
        setgetCompanyByType(res.data.companies);
      });
  }, [CompanyType]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredPartners =
    getCompanyByType &&
    getCompanyByType.filter((comp: any) => {
      return comp.companyName.toLowerCase().includes(searchTerm.toLowerCase());
    });

  function downloadPayoutExcel() {
    let selectedCompanyCode;
    if (CompanyType === "D") {
      selectedCompanyCode = "D";
    } else if (CompanyType === "E") {
      selectedCompanyCode = "E";
    } else {
      return;
    }
    axios({
      url: `/company/partnerExcelSheetDownload/companyTypeCode?companyTypeCode=${selectedCompanyCode}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      var date1 = new Date();
      link.setAttribute(
        "download",
        "PayoutStatement_" +
          ("0" + date1.getDate()).slice(-2) +
          "_" +
          ("0" + (date1.getMonth() + 1)).slice(-2) +
          "_" +
          date1.getFullYear() +
          ".xlsx"
      );
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      //link.parentNode.removeChild(link);
    });
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{ paddingTop: 4, "& .MuiTextField-root": { m: 1, width: "25ch" } }}
      >
        <Typography variant="h4" gutterBottom>
          Partner Details
        </Typography>
        <Container>
          <Box sx={{ width: 300, p: 3 }}>
            {sessionStorage.getItem("Profile") === "E" ? (
              <></>
            ) : (
              <>
                <Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={[
                      { companyType: "Distributor", companyCode: "D" },
                      { companyType: "Self", companyCode: "E" },
                    ].map((option: any) => option)}
                    defaultValue={{
                      companyType: "Distributor",
                      companyCode: "D",
                    }}
                    getOptionLabel={(options: any) => options.companyType}
                    onChange={(options: any, newValue) => {
                      setCompanyType(newValue?.companyCode);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Partner Type" />
                    )}
                  />
                </Typography>
              </>
            )}
          </Box>
          <Grid
            container
            spacing={3}
            justifyContent={"space-between"}
            marginLeft={0}
          >
            {/* Search Box */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Paper
                component="form"
                sx={{
                  p: "4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                  mb: "0rem",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Partner"
                  onChange={handleSearchPartner}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Box>
            <Button
              variant="contained"
              onClick={handleButtonClick}
              sx={{ m: 2 }}
            >
              Download
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={downloadPayoutExcel}>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<DownloadForOfflineIcon />}
                >
                  Excel
                </Button>
              </MenuItem>
            </Menu>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Partner Code</TableCell>
                  <TableCell>Partner Name</TableCell>
                  <TableCell>Email Id</TableCell>
                  <TableCell>Contact No.</TableCell>
                  <TableCell>Contact Person Name</TableCell>
                  <TableCell>RM Name</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPartners &&
                  filteredPartners
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index) => (
                      <Link
                        key={index}
                        display={"table-row"}
                        sx={{
                          textDecoration: "none",
                          "&:hover": { backgroundColor: "lightgray" },
                        }}
                        href={"/company/" + row.companyMasterCode}
                      >
                        <TableCell>{row.companyMasterCode}</TableCell>
                        <TableCell component="th" scope="row">
                          {row.companyName + " - " + row.companyTypeCode}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.emailId}
                        </TableCell>
                        <TableCell>{row.phoneNo}</TableCell>
                        <TableCell>{row.contactPersonName}</TableCell>
                        <TableCell>{row.rmName}</TableCell>
                        <TableCell>
                          {row.isActive ? "Active" : "In Active"}
                        </TableCell>
                      </Link>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 30, 50]}
            component="div"
            count={filteredPartners ? filteredPartners.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          ></Box>
        </Container>
      </Box>
    </Container>
  );
};

export default PartnerManagement;
