import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled';
import { Box , Grid, Typography, Avatar, Paper } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppOutlined';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyOutlined';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveOutlined';
import axios from 'axios';

const EarningCard = (props:any) => {

    const payoutList = props.payout;
    console.log("Payoutlist Prop", payoutList);
    
  return (
    <Paper elevation={3} sx={{
    backgroundColor: "#5e35b1",
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    overflowY:'scroll',
    height:'225px',
    borderRadius:"10px",
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        borderRadius: '50%',
        top: -85,
        right: -95,
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.5,
    }
    }}>
                    <Box sx={{ p: 1.5 , mb: 3}}>
                        <Grid container direction="row">
                            <Grid item md={4}>
                                <Typography variant='body1'><b>Products</b></Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant='body1'><b>Paid</b></Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant='body1'><b>Unpaid</b></Typography>
                            </Grid>
                        </Grid>

                        {payoutList && payoutList.map((item:any) => (
                        <Grid container direction="row" sx={{mt:1}}>
                            <Grid item md={4}>
                                <Typography variant='body1'>{item.productName}</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant='body1'>₹{item.totalPayoutGiven}</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant='body1'>₹{item.totalPayoutRemain}</Typography>
                            </Grid>
                        </Grid>
                        ))}
                    </Box>
                </Paper>
  )
}

export default EarningCard