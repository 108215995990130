import { Card, CardContent, CardMedia, Paper, Typography } from '@mui/material'
import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const ProductConnect = () => {
  return (
	<>
		<Paper elevation={6} sx={{ width:"100%" }}>
		 <Card >
      <CardMedia
        image="/static/images/cards/contemplative-reptile.jpg"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
			Product Connect 
        </Typography>

		<TableContainer component={Paper}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Product Owner Name</TableCell>
            <TableCell align="left">Product</TableCell>
            <TableCell align="right">Contact Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">Samiksha</TableCell>
              <TableCell align="left">Unlisted</TableCell>
              <TableCell align="right">9831012345</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">Sunny Trisal</TableCell>
              <TableCell align="left">Fixed Income</TableCell>
              <TableCell align="right">9831012567</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">Ritesh Kumar Jaiswal</TableCell>
              <TableCell align="left">AIF, PMS</TableCell>
              <TableCell align="right">9831067890</TableCell>
            </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
      </CardContent>
    </Card>
	</Paper>
	</>
  )
}

export default ProductConnect