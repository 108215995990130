import { Button } from "@mui/material";
import React from "react";
import StartWithUsLoginModel from "./StartWithUsLoginModel";

const StartWithUs = () => {
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="contained"
        type="button"
        className="main-button bkc-button"
		onClick={handleOpen}
      >
        Starts with US
      </Button>

	  <StartWithUsLoginModel open={open} handleClose={handleClose} />
    </>
  );
};

export default StartWithUs;
