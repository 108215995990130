/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Tooltip from "@mui/material/Tooltip";
import { productTypeEnum } from "../../Constants/ProductTypeEnum";
import { SAVE_PROPOSAL } from "../../utils/API_Names";
import { toast } from "react-toastify";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useQuery } from "react-query";
import { GetAllClient } from "../../api/Api";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";

const AddToProposalModal = (props: any) => {
  const { data } = props;
  const [investNowModalOpen, setInvestNowModalOpen] = React.useState(false);
  const [clientID, setClientID] = React.useState<any | null>();
  const [price, setPrice] = React.useState<any>(data?.salePrice);
  const [spread, setSpread] = React.useState<any>(0);
  const [YTM, setYTM] = React.useState<any>(data?.saleYtm);
  const [clientData, setClientData] = React.useState<any>([]);

  const { data: clientsData } = useQuery(
    "getAllClients",
    () => GetAllClient("", "", "false"),
    {
      enabled: sessionStorage.getItem("Profile") == "M" ? false : true,
      onSuccess: (data: any) => {
        setClientData(data.data.clients);
      },
      staleTime: Infinity,
    }
  );

  const addToProposal = () => {
    let proposalDTO = {
      clientId: clientID,
      productMasterCode: data.productMasterCode,
      productTypeCode: productTypeEnum.BOND_WORK,
      clientPrice: price,
    };
    axios
      .post(SAVE_PROPOSAL, proposalDTO)
      .then(
        (res: any) => res && toast.success(res?.data?.status?.message?.title)
      )
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };

  const handleSpreadAdd = () => {
    if (spread >= 0) {
      setSpread(spread + 0.1);
    }
  };

  const handleSpreadSubs = () => {
    if (spread <= 0.1) {
      setSpread(0);
    } else {
      setSpread(spread - 0.1);
    }
  };

  const getSpreadYield = () => {
    var sellPrice = parseFloat(price) + parseFloat(spread?.toFixed(2));
    if (sellPrice) {
      axios
        .get(
          "quoteDetails/xirrCalculation?isinNumber=" +
            props?.data?.isinNumber +
            "&salePrice=" +
            sellPrice?.toFixed(2)
        )
        .then((res: any) => {
          setYTM(res?.data?.xirrNXnpvDTO?.saleYtm);
        });
    }
  };

  useEffect(() => {
    getSpreadYield();
  }, [parseFloat(price) + parseFloat(spread?.toFixed(2))]);

  return (
    <div>
      {sessionStorage.getItem("Profile") === "E" ||
      sessionStorage.getItem("Profile") === "D" ? (
        <Tooltip title="Proposal">
          <IconButton>
            <ControlPointIcon onClick={() => setInvestNowModalOpen(true)} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Proposal">
          <IconButton disabled>
            <ControlPointIcon />
          </IconButton>
        </Tooltip>
      )}
      <Modal
        open={investNowModalOpen}
        onClose={() => setInvestNowModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <IconButton
              style={{
                position: "absolute",
                fontSize: "18px",
                top: "8px",
                right: "5px",
              }}
              onClick={() => setInvestNowModalOpen(false)}
            >
              <CloseIcon style={{ fontSize: "18px" }} />
            </IconButton>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h5" textAlign={"center"} gutterBottom>
                {data?.issuerName}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              textAlign="center"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={clientData}
                getOptionLabel={(options: any) =>
                  options.fullName + " - " + options.panCardNo
                }
                sx={{ minWidth: 290, maxWidth: 570 }}
                onChange={(event: any, options: any, newValue) => {
                  newValue === "clear"
                    ? setClientID(null)
                    : setClientID(options.clientId);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Select Client"
                    margin="normal"
                    size="small"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography gutterBottom> Coupon: {data?.coupon}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography gutterBottom>
                Sale Price:
                <IconButton
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={() => {
                    handleSpreadSubs();
                  }}
                >
                  <RemoveIcon sx={{ color: "#000" }} />
                </IconButton>
                {spread?.toFixed(2)}
                <IconButton
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={() => {
                    handleSpreadAdd();
                  }}
                >
                  <AddIcon sx={{ margin: "auto", color: "#000" }} />
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography gutterBottom>
                Sale Ytm: {parseFloat(YTM).toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography gutterBottom>
                Price: {(parseFloat(price) + parseFloat(spread)).toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Typography gutterBottom>
                Tenure Remaining: {data?.tenureYear}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              textAlign="center"
              pt={2}
            >
              <Button
                variant="contained"
                onClick={() => addToProposal()}
                disabled={!!!clientID}
              >
                Add To Proposal
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  height: "50%",
  overflowY: "auto",
};

export default AddToProposalModal;
