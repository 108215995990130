import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import AddUpdateNCDModel from "./AddUpdateNCDModel";
import { Link, useNavigate } from "react-router-dom";
import { changeDate } from "../../../utils/Helper";

function createData(
  companyName: string,
  issuePrice: string,
  openDate: string,
  closeDate: string,
  tenure: string,
  interestFrequency: string,
  couponRate: string,
  effectiveYield: string,
  bondRating: string
) {
  return {
    companyName,
    issuePrice,
    openDate,
    closeDate,
    tenure,
    interestFrequency,
    couponRate,
    effectiveYield,
    bondRating,
  };
}

const UpComingNCD = (props: any) => {
  const { data } = props;
  const [addNcdModel, setAddNcdModel] = React.useState(false);
  const [ncdData, setNcdData] = React.useState<any>([]);

  function handleChangeAddNcdModel(status: any) {
    status === "open" ? (
      setAddNcdModel(true)
    ) : status === "close" ? (
      setAddNcdModel(false)
    ) : (
      <></>
    );
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" width={50}>
                Logo
              </TableCell>
              <TableCell align="left" width={300}>
                Company Name
              </TableCell>
              <TableCell align="left" width={100}>
                Issue Price
              </TableCell>
              <TableCell align="left" width={150}>
                Open Date
              </TableCell>
              <TableCell align="left" width={200}>
                Close Date
              </TableCell>
              <TableCell align="left" width={200}>
                Revenue Percent
              </TableCell>
              <TableCell align="left" width={200}>
                Bond Rating
              </TableCell>
              {sessionStorage.getItem("Profile") === "B" && (
                <TableCell align="left">Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.map((ncd: any) => (
                <TableRow
                  key={ncd.ncdId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left" component="th" scope="row">
                    <img
                      src={ncd.ncdCompanyLogo}
                      alt="NCD Logo"
                      width={"100%"}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ textDecoration: "underline" }}>
                    <Link to={"/NcdDetails/" + ncd.ncdId}>{ncd.ncdName}</Link>
                  </TableCell>
                  <TableCell align="left">{ncd.issuePrice}</TableCell>
                  <TableCell align="left">{changeDate(ncd.openDate)}</TableCell>
                  <TableCell align="left">
                    {changeDate(ncd.closeDate)}
                  </TableCell>
                  <TableCell align="left">{ncd.revenueSharing}</TableCell>
                  <TableCell align="left">{ncd.bondRating}</TableCell>
                  {sessionStorage.getItem("Profile") === "B" && (
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        onClick={() => {
                          setNcdData(ncd);
                          handleChangeAddNcdModel("open");
                        }}
                        size="small"
                      >
                        {" "}
                        Edit NCD's{" "}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddUpdateNCDModel
        open={addNcdModel}
        onClose={() => handleChangeAddNcdModel("close")}
        data={ncdData}
      />
    </>
  );
};

export default UpComingNCD;
