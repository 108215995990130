export function CommaFormater (number) {
    return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number)
  }
  export function CommaFormater1 (number) {
    if (number === undefined) {
      return 0
    } else {
      return new Intl.NumberFormat('en-IN', { style: 'decimal' }).format(number)
    }
  }
  export function CommaFormater2 (number) {
    if (number === undefined) {
      return 0
    } else {
      return new Intl.NumberFormat('en-IN').format(number)
    }
  }