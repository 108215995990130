import React, { useEffect, useState } from 'react'
import { withParam } from '../../utils/Router.Helper';
import { useForm } from 'react-hook-form';
import { GET_LOAN_SCHEME, GET_SCHEME_DATA_FROM_AMC_ID, UPDATE_LOAN_SCHEME_API } from '../../utils/API_Names';
import axios from 'axios';
import { Box, Button, Card, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
interface PropsType {
    params: {
      amcId: String;
      productMasterCode:String
    };
  }



const AmcLoanScheme = (props: PropsType) => {
    const { amcId } = props.params;
  const { productMasterCode } = props.params;
  const [dataloanData, setLoanAmc] = useState<any>([]);
  const [amcName, setAMCName] = useState('');
  const [unlistedModel, setUnlistedModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [dataimg, setimg] = useState<any>([]);


  const [loanlogo, setLoanLogo] = useState<any>(null);

    const handleUpdateClick = (row: any) => {
        setSelectedRow(row);
        updateUnlistedShareModel('open');
      };
    
      function updateUnlistedShareModel(status: any) {
        status === 'open'
          ? setUnlistedModel(true)
          : status === 'close'
          ? setUnlistedModel(false)
          : <></>;
      }
    useEffect(() => {
        if (productMasterCode) {
          axios.get(`${GET_LOAN_SCHEME}=${productMasterCode}`)
            .then((res: any) => {
              setLoanAmc(res.data.loanDTOList);
              setAMCName(res.data.insurance.amcName);
              setAMCName(res.data.amcDetails[0].amcName);
              setimg(res.data.loanDTOList)
            })
            .catch((error) => {
            });
        }
      }, [productMasterCode]);
    
      const onSubmit = async (submitedData: any) => {
        const formData: any = new FormData();
        formData.append("loanLogo", loanlogo);
        const newSubmitedData = {
          ...submitedData,
          productMasterCode: selectedRow?.productMasterCode,
          amcId: amcId,
        };
        formData.append("loanMasterDTO", JSON.stringify(newSubmitedData));
        try {
          await axios.put(`${UPDATE_LOAN_SCHEME_API}`, formData);
          updateUnlistedShareModel('close');
          window.location.reload();
        } catch (error) {
        }
      };
      const handleLogoFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        setLoanLogo(file);
      };

      const { register, handleSubmit, watch } = useForm({ mode: 'onChange' });

  return (

  <section>
     {dataloanData?.map((row: any, index: number) => (
     <Card style={{marginTop:"20px"}} variant="outlined"  >
<Grid container spacing={2}>
    <Grid item xs={12}>
    <Typography  style={{fontSize:"30px", fontWeight:"700",color:"#00d5dd"}}>{row.productName}</Typography>
    </Grid>
  <Grid item xs={4}>
  <img width={90} src={row.loanLogo}  alt="" />
  </Grid>
  <Grid item xs={2}>
  <Typography  style={{fontSize:"25px", fontWeight:"500"}}>Loan Type</Typography>
<Typography  style={{fontSize:"20px", fontWeight:"400",color:"#00d5dd"}}>{row.loanType}</Typography>
  </Grid>
  <Grid item xs={2}>
  <Typography  style={{fontSize:"25px", fontWeight:"500"}}>Segment</Typography>
<Typography  style={{fontSize:"20px", fontWeight:"400",color:"#00d5dd"}}>{row.segment}</Typography>
  </Grid>
  <Grid item xs={2}>
  <Typography  style={{fontSize:"25px", fontWeight:"500"}}>Tenure</Typography>
<Typography  style={{fontSize:"20px", fontWeight:"400",color:"#00d5dd"}}>{row.maxTenure}</Typography>
  </Grid>
  <Grid item xs={2}>
<Button variant="contained" onClick={() => handleUpdateClick(row)}>Update</Button>
            
  </Grid>
  <Modal
       open={unlistedModel} 
       onClose={() => updateUnlistedShareModel('close')}>
        <Box sx={style}>
        <IconButton style={{ position: 'absolute',fontSize:"18px" ,top: "8px", right: "5px"}} onClick={() => updateUnlistedShareModel('close')}>
      <CloseIcon style={{fontSize:"18px"}} />
       </IconButton>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2" mb={2}>
            Update Loan
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} >
              <Grid xs={3}>
            <TextField
              id="outlined-basic"
              label="Loan Type"
              variant="outlined"
              defaultValue={selectedRow?.loanType}
              {...register('loanType')}
            />
            </Grid>
            <Grid xs={3}>
            <TextField
              id="outlined-basic"
              label="Product"
              variant="outlined"
              defaultValue={selectedRow?.productName}
              {...register('productName')}
            />
            </Grid>
            <Grid xs={3}>
            <TextField
              id="outlined-basic"
              label="Loan Segment"
              variant="outlined"
              defaultValue={selectedRow?.segment}
              {...register('segment')}
            />
            </Grid>
            <Grid xs={3}>
            <TextField
              id="outlined-basic"
              label="Tenure"
              variant="outlined"
              defaultValue={selectedRow?.maxTenure}
              {...register('maxTenure')}
            />
            </Grid>
           <Grid xs={3}>
  <Button variant='contained' component='label'>
    Update Logo
    <input
  hidden
  accept='.jpg, .png, .jpeg'
  type="file"
  onChange={handleLogoFileChange}
  style={{ marginBottom: "10px" }}
/>
  </Button>
</Grid>
            </Grid>
            <Grid style={{textAlign:"center",marginTop:"30px"}}>
            <Button type="submit" variant="contained">
              Submit
            </Button>
            </Grid>
          </form>
        </Box>
      </Modal>
</Grid>
</Card>
              ))}
             
             </section>
  )
}

export default withParam(AmcLoanScheme)
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper', // Use your preferred background color here
    boxShadow: 24,
    p:2,
  };

