import {
    Autocomplete,
    Grid,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    TableBody,
    Typography,
    Button,
  } from "@mui/material";
  import React, { useState } from "react";
  import { useQuery } from "react-query";
  import {
    GetAllUnlistedWithQuantity,
    GetListOfUserByCompanyMasterCodeAndUserTypeCode,
    getLoanAmc,
    listOfManufactureNDistributorCompanies,
  } from "../../api/Api";
import LoanTableHeadData from "../LoanTableHeadData";
  

const OrderWindowForLoan = () => {
        const [companyMasterCode, setCompanyMasterCode] = useState();
        const [companyTypeCode, setCompanyTypeCode] = React.useState();
        const [ distributorCode, setDistributorCode ] = React.useState("")
        const [ distributorUserCode, setDistributorUserCode ] = React.useState("")
        const [ loanId, setLoanId ] = React.useState("")
      
        const { data: distributorCompanyList } = useQuery(
          "ManufactureNDistributorCompanie",() => listOfManufactureNDistributorCompanies("D,E"),{
            enabled: sessionStorage.getItem("Profile") === "B" || sessionStorage.getItem("Profile") === "E",
            keepPreviousData:false,
          }
        );
      
        const { data:listOfLoan } = useQuery(["listOfLoanDetails"], () => getLoanAmc());
      
        const { data:unlistedList} = useQuery(["GetAllUnlistedWithQuantity", companyMasterCode],() =>
        GetAllUnlistedWithQuantity(
        (!!companyMasterCode && sessionStorage.getItem("Profile") === "B") || (!!companyMasterCode && sessionStorage.getItem("Profile") === "E")
          ? "?companyMasterCode=" + companyMasterCode
          : ""
        ),
          {
            enabled:
              (sessionStorage.getItem("Profile") === "B" && !!companyMasterCode) ||
              (sessionStorage.getItem("Profile") === "E" && !!companyMasterCode) ||
              sessionStorage.getItem("Profile") === "D",
          keepPreviousData:false,
          }
        );
      
        const { data:UserProfilesByCompanyMasterCode } = useQuery(["userProfileByCompany",companyMasterCode,companyTypeCode],()=>GetListOfUserByCompanyMasterCodeAndUserTypeCode(companyTypeCode==="S"?"E":companyTypeCode,companyMasterCode),{
          enabled:!!companyMasterCode,
          keepPreviousData:false,
        }) 
      
        return (
          <>
          <Typography variant="h5" textAlign={"center"}> Loan Order </Typography>
        <Grid container rowSpacing={1} columnSpacing={1}>
            {sessionStorage.getItem("Profile") === "B" || sessionStorage.getItem("Profile") === "E" ? (
          <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={
                  distributorCompanyList &&
                  distributorCompanyList.data.companies.map((options: any) => options)
                }
                getOptionLabel={(options: any) => options.companyName + " - " + options.companyTypeCode}
                onChange={(options: any, newValue) => {
                  setCompanyMasterCode(newValue ? newValue.companyMasterCode : "");
                  setCompanyTypeCode(newValue ? newValue.companyTypeCode : "");
                  sessionStorage.setItem(
                    "CompanyData",
                    newValue?.companyMasterCode
                  );
                  setLoanId("")
                }}
                renderInput={(params: any) => (
                  <TextField {...params} label="Select Company" size={"small"}/>
                )}
              />
          </Grid>
            ) : (
              <></>
            )}
            
      { unlistedList && (sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile") === "E" ? 
              <>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={UserProfilesByCompanyMasterCode ? UserProfilesByCompanyMasterCode.data.userProfile.map((options: any) => options ) : [] }
                  getOptionLabel={(options: any) => options?.userCode +"- "+ options?.firstName +" "+ options?.lastName }
                  onChange={(options: any, newValue) =>{
                    setDistributorCode(newValue?newValue.employeeCode:"");
                    setDistributorUserCode(newValue?newValue.userCode:"");
                   }}
                  renderInput={(params: any) => (
                    <TextField {...params} label="Select User" size={"small"}/>
                  )}
                  /> 
                </Grid>
              </>
      :<></> )}
        </Grid>
      
        <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 1200 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align='center'>Order Date</TableCell>
                            <TableCell align='center'>Insuarance Type</TableCell>
                            <TableCell align='center'>Product Name</TableCell>
                            <TableCell align='center'>Segment</TableCell>
                            <TableCell align="center">Tenure</TableCell>
                            <TableCell align="center">Loan Amount</TableCell>
                            <TableCell align="center">Client</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">Form Acknowledgement No</TableCell>
                            <TableCell align="center">Upload Form</TableCell>
                            <TableCell align="center">Order</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {listOfLoan && listOfLoan?.data?.loanDTOList?.map((item: any) => (
                        <LoanTableHeadData item={item} distributorCode={distributorCode} companyMasterCode={companyMasterCode} distributorUserCode={distributorUserCode}/>
                        ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

          </>
        );
      };
      export default OrderWindowForLoan;