import { Switch, CircularProgress, Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { POST_AMC_INSURANCE_SWITCH } from '../../utils/API_Names';
import axios from 'axios';
import { toast } from 'react-toastify';

const AmcInsuranceSwitch = (props: any) => {
  const { productMasterCode, isActive } = props;
  const [activeCircularProgress, setActiveCircularProgress] = useState(false);
	const [checked, setChecked] = React.useState(isActive);

  useEffect(() => {
    setChecked(isActive);
  }, [isActive]);

  useEffect(() => {
    // This effect will run whenever 'checked' changes
    props.setRefresh(!props.refresh);
  }, [checked, props.setRefresh, props.refresh]);

  const handleChange = () => {
    setActiveCircularProgress(true);
    axios.put(`${POST_AMC_INSURANCE_SWITCH}${productMasterCode}`)
      .then(() => {
        setActiveCircularProgress(false);
        setChecked(!checked);
        props.setRefresh(!props.refresh)
        toast.success(`insurance ${checked ? 'deactivated' : 'activated'} successfully`);
      })
      .catch((err: any) => {
        setActiveCircularProgress(false);
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };


  return (
    <>
      <Switch
        checked={checked}
        disabled={activeCircularProgress}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Box sx={{ pt: 1 }}>{activeCircularProgress ? <CircularProgress size={20} /> : <></>}</Box>
    </>
  );
};

export default AmcInsuranceSwitch;
