import { Autocomplete, Box, Button, Container, 
         FormControl, 
         Grid, 
         IconButton, 
         InputAdornment, 
         InputBase, 
         InputLabel, 
         Link, 
         Modal, 
         OutlinedInput, 
         Paper, 
         Tab, 
         Table, 
         TableBody, 
         TableCell, 
         TableContainer, 
         TableHead, 
         TableRow, 
         Tabs, 
         TextField, 
         Typography
        } from '@mui/material';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { Resolver, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import States from '../Constants/States';


type FormValues = {
    userCompany: string;
    UserCode: string;
    firstName: string;
    middleName: string;
    lastName: string;
    mobileNo: string;
    email: string;
    country: string;
    state: string;
    city: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    pin: string;
    accountManager: string;
  };

const resolver: Resolver<FormValues> = async (values) => {
    return {
      values: !values.firstName ? {} : values,
      errors: !values.firstName
        ? {
            firstName: {
              type: "required",
              message: "This is required.",
            },
          }
        : {},
    };
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "20px",
    boxShadow: 24,
    '&::-webkit-scrollbar': {
      background: 'transparent',
    },
    p: 4,
    "& .MuiTextField-root": { m: 1 },
    overflowY: "scroll",
    maxHeight: "80%",
  };
  

const Test = () => {

  const [value, setValue] = useState<any>("A");
  const [userdata, setUserData] = useState<any>([]);
  const [companyCode, setCompanyCode] = useState("");
  const [UserType, setUserType] = useState("");
  const [brokerCode, setBrokerCode] = useState<any>("");
  const [productlist, setproductlist] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [UserCode, SetUserCode] = useState([]);
  const [brokerList, setBrokerList] = useState<any>();
  const [companyList, setcompanyList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [ProductList, setProductList] = useState([]);
  const [CompanyWiseProduct, setCompanyWiseProduct] = useState([]);
  const [UserProfile, setUserProfile] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event:any) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = userdata.filter((user:any) => {
    return (
      user.userCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.firstName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  console.log("search data",filteredUsers)

  useEffect(() => {
    axios
      .get("userProfile/getUserTypeDetails")
      .then((res: any) => SetUserCode(res.data.userType))
      // .catch((err:any)=>{<AlertPopup type="error" message="hello"/>})
    axios
      .get("company/listOfCompanies")
      .then((res: any) => setcompanyList(res.data.companies));
    axios
      .get("product/listOfAllProductType")
      .then((res: any) => setProductList(res.data.productType));
    axios
      .get("Brokers")
      .then((res: any) => setBrokerList(res.data.brokers));
  }, []);


  useEffect(() => {
    UserType === "D" || UserType === "M"
      ? axios
          .get(
            "companies/getCompanyType/companyTypeCode?companyTypeCode=" +
              UserType
          )
          .then((res: any) => setcompanyList(res.data.companies))
      : setCompanyCode("");
    UserType === "" ? (
      <></>
    ) : (
      axios
        .get("userProfile/listOfUserTypeUserProfile/" + UserType)
        .then((res) => setUserProfile(res.data.userProfile))
    );
  }, [UserType]);

  useEffect(() => {
    companyCode !== "" &&
      axios
        .get("sourceDestination/sourceValue?sourceValue=" + companyCode)
        .then((res: any) => setCompanyWiseProduct(res.data.sourceDes));
  }, [companyCode]);
  
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(()=>{
      axios.get("userProfile/listOfAllUserProfileDetails").then((res:any)=>{
           setUserData(res.data.userProfile)
      })
  },[value, open,])
  
  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };

  const { register, handleSubmit } = useForm({
        resolver: resolver,
    });

    const onSubmit = (data: any) => {
      console.log("Data", {
        ...data,
        companyMasterCode: companyCode,
        userTypeCode: UserType,
        brokerMasterCode: brokerCode,
      });
      axios
        .post("user" + (productlist && "?productType=" + productlist), {
          ...data,
          companyMasterCode: companyCode,
          userTypeCode: UserType,
          brokerMasterCode:
            brokerCode === ""
              ? sessionStorage.getItem("BrokerMasterCode")
              : brokerCode,
          user: { isActive: "" },
        })
        .then((res: any) => toast.success("User added successfully!"))
        .then(() => setOpen(false))
        .catch((err: any) =>
          toast.error(
            err.response.data ? err.response.data.status.message.title : "Error"
          )
        );
    };

  
  return (
    <Container maxWidth="xl">
      <div style={{ display: "flex", flexDirection: "row", marginTop: "10%" }}>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <Paper sx={{ height: "500px" }}>
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                sx={{ minWidth: 150 }}
              >
                <Tab label="Admins" value="A" />
                <Tab label="Back Offices" value="B" />
                <Tab label="Manufacturers" value="M" />
                <Tab label="Distributors" value="D" />
              </Tabs>
            </Paper>
          </Grid>

          <Grid item md={9}>
            <Box sx={{display:'flex', width:'100%', marginBottom:'2%'}}>

            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by user name or user code"
                onChange={handleSearch}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>

            <Button endIcon={<AddIcon/>} variant='contained' size='small' onClick={handleOpen} sx={{marginLeft:'auto'}}>Add User </Button>

            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>User Code</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone No</TableCell>
                    <TableCell>User Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers
                    .filter((it: any) => it.userTypeCode === value)
                    .map((row: any) =>
                      row.userCode === sessionStorage.getItem("Pro") ? (
                        <></>
                      ) : (
                        <Link 
                            display={"table-row"}
                            sx={{
                                textDecoration: "none",
                                "&:hover": { backgroundColor: "lightgray" },
                            }}
                            href={"/A1/" + row.userCode}
                            key={row.userCode}>
                        
                          <TableCell component="th" scope="row">
                            {row.userCode}
                          </TableCell>
                          <TableCell component="th">{row.firstName}</TableCell>
                          <TableCell component="th">{row.mobileNo}</TableCell>
                          <TableCell component="th">
                            {row.userTypeCode}
                          </TableCell>
                        </Link>
                      )
                    )}
                  {/* {userdata.map((row:any, index:any) =>
                      <TableRow key={row.firstName}>
                        <TableCell component="th" scope="row">
                          {row.userCode}
                        </TableCell>
                        <TableCell component="th">{row.firstName}</TableCell>
                        <TableCell component="th">{row.mobileNo}</TableCell>
                        <TableCell component="th">{row.userTypeCode}</TableCell>
                      </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
      <Modal
                open={open}
                onClose={handleClose}
                sx={{
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  height: '100%',
                  overflow: 'scroll',
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box sx={style}>
                    <Typography
                      variant="h6"
                      component="h2"
                      textAlign={'center'}
                      mb={3}
                    >
                      Add New User
                    </Typography>

                    <Typography id="keep-mounted-modal-description">
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={UserCode.map((option: any) => option)}
                        getOptionLabel={(options: any) => options.value}
                        onChange={(options: any, newValue) => {
                          setUserType(newValue.code);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="User Role" />
                        )}
                      />

                      {sessionStorage.getItem("Profile") === "A" &&
                      sessionStorage.getItem("BrokerMasterCode") === "1" ? (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={
                            brokerList &&
                            brokerList.map((option: any) => option)
                          }
                          getOptionLabel={(options: any) => options.brokerName}
                          onChange={(options: any, newValue) => {
                            setBrokerCode(newValue.brokerMasterCode);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Broker" />
                          )}
                        />
                      ) : (
                        <> </>
                      )}

                      {!UserType && (UserType === "D" || UserType === "M") ? (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          getOptionLabel={(options: any) => options.companyName}
                          options={companyList.map((option: any) => option)}
                          onChange={(options: any, newValue) => {
                            setCompanyCode(newValue.companyMasterCode);
                          }}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="User Company" />
                          )}
                        />
                      ) : (
                        <></>
                      )}
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        {...register("firstName")}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Middle Name"
                        variant="outlined"
                        fullWidth
                        {...register("middleName")}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        {...register("lastName")}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Mobile no"
                        variant="outlined"
                        fullWidth
                        {...register("mobileNo")}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        {...register("email")}
                      />
                      <Autocomplete
                        options={States.countries}
                        getOptionLabel={(option) => option.country}
                        onChange={(event, value) => setSelectedCountry(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country"
                            variant="outlined"
                            {...register("country")}
                          />
                        )}
                      />
                      {selectedCountry && (
                        <Autocomplete
                          options={selectedCountry.states}
                          onChange={(event, value) => setSelectedState(value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              variant="outlined"
                              {...register("state")}
                            />
                          )}
                        />
                      )}
                      <TextField
                        id="outlined-basic"
                        label="City"
                        variant="outlined"
                        fullWidth
                        {...register("city")}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Address Line 1"
                        variant="outlined"
                        fullWidth
                        {...register("addressLine1")}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Address Line 2"
                        variant="outlined"
                        fullWidth
                        {...register("addressLine2")}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Address Line 3"
                        variant="outlined"
                        fullWidth
                        {...register("addressLine3")}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Pin Code"
                        variant="outlined"
                        fullWidth
                        {...register("pin")}
                      />
                      {UserType === "D" || UserType === "M" ? (
                        <>
                          <TextField
                            id="outlined-basic"
                            label="Account Manager"
                            variant="outlined"
                            fullWidth
                            {...register("accountManager")}
                          />

                          <Autocomplete
                            multiple
                            id="combo-box-demo"
                            // CompanyWiseProduct.map((it)=>ProductList.filter((i)=>i.productTypeCode===it.destinationValue)).map((u)=>u[0])
                            // getOptionLabel={CompanyWiseProduct.map((it:any)=>ProductList.filter((i:any)=>i.productTypeCode===it.destinationValue)).map((u:any)=>u[0])}

                            getOptionLabel={(options: any) =>
                              options.productName
                            }
                            // options={CompanyWiseProduct.map((it: any) =>
                            //   ProductList.filter(
                            //     (i: any) =>
                            //       parseInt(i.productTypeCode) ===
                            //       parseInt(it.destinationValue)
                            //   )
                            // ).map((options: any) => options[0])}
                            // options={ProductList.map((options: any) => options)}
                            options={CompanyWiseProduct.map((it: any) =>
                              ProductList.filter(
                                (i: any) =>
                                  parseInt(i.productTypeCode) ===
                                  parseInt(it.destinationValue)
                              )
                            ).map((options: any) => options[0])}
                            onChange={(options: any, newValue) => {
                              setproductlist(
                                newValue.map((i: any) => i.productTypeCode)
                              );
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Products" />
                            )}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      <div style={{textAlign:'center', width:'100%'}}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        sx={{marginTop:'10px'}}
                      >
                        Submit
                      </Button>
                      </div>
                    </Typography>
                  </Box>
                </form>
              </Modal>
    </Container>
  );
}

export default Test