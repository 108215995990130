import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { productTypeEnum } from "../../Constants/ProductTypeEnum";
import { toast } from "react-toastify";
import { changeDate } from "../../utils/Helper";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

let headCells = [
  {
    id: "orderId",
    numeric: false,
    disablePadding: true,
    label: "Order ID",
  },
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Distributor Company Name",
  },
  {
    id: "disUserName",
    numeric: false,
    disablePadding: false,
    label: "Distributor Name",
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: false,
    label: "Employee Name",
  },
  {
    id: "scriptName",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client",
  },
  {
    id: "buyRate",
    numeric: true,
    disablePadding: false,
    label: "Investment Amount",
  },
  {
    id: "totalTranValue",
    numeric: true,
    disablePadding: false,
    label: "Total Transaction Value",
  },
  {
    id: "payoutAmount",
    numeric: true,
    disablePadding: false,
    label: "Gross Amount",
  },
  {
    id: "gstAmount",
    numeric: true,
    disablePadding: false,
    label: "GST Amount",
  },
  {
    id: "tdsAmount",
    numeric: true,
    disablePadding: false,
    label: "TDS Amount",
  },
  {
    id: "totalPayout",
    numeric: true,
    disablePadding: false,
    label: "Net Payout",
  },
  {
    id: "payoutGiven",
    numeric: false,
    disablePadding: false,
    label: "Payout Given",
  },
];
if (
  sessionStorage.getItem("Profile") === "B" ||
  sessionStorage.getItem("Profile") === "E"
) {
  let backOfficeArray = [
    {
      id: "brokerPayout",
      numeric: false,
      disablePadding: false,
      label: "Revenue",
    },
    {
      id: "revenueGiven",
      numeric: false,
      disablePadding: false,
      label: "Revenue Credit",
    },
  ];
  headCells = headCells.concat(backOfficeArray);
}
if (sessionStorage.getItem("Profile") === "B") {
  let backOfficeArray1 = [
    {
      id: "payoutId",
      numeric: false,
      disablePadding: false,
      label: "Payout Action",
    },
    {
      id: "revenueId",
      numeric: false,
      disablePadding: false,
      label: "Revenue Credit Action",
    },
  ];
  headCells = headCells.concat(backOfficeArray1);
}
if (
  sessionStorage.getItem("Profile") === "B" ||
  sessionStorage.getItem("Profile") === "D"
) {
  let backOfficeArray2 = [
    {
      id: "invoice",
      numeric: false,
      disablePadding: false,
      label: "Action",
    },
  ];
  headCells = headCells.concat(backOfficeArray2);
}
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const { toDate } = props;
  const { fromDate } = props;
  const { selectedPayout } = props;
  const { downloadSingleInvoice, tableDataLength } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function downloadPayoutPDF() {
    axios({
      url: `payout/payoutStatementInPDF?fromDate=${
        fromDate.split("T")[0]
      }&productTypeCode=${productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM}&toDate=${
        toDate.split("T")[0]
      }`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      var date1 = new Date();
      link.setAttribute(
        "download",
        "Payout_" +
          ("0" + date1.getDate()).slice(-2) +
          "_" +
          ("0" + (date1.getMonth() + 1)).slice(-2) +
          "_" +
          date1.getFullYear() +
          ".pdf"
      );
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  }
  function downloadPayoutPDFByID() {
    let orderIdParam = selectedPayout.join("&orderId=");
    axios({
      url: `payout/payoutInvoice?orderIds=${orderIdParam}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      var date1 = new Date();
      link.setAttribute(
        "download",
        "PayoutDetail_" +
          ("0" + date1.getDate()).slice(-2) +
          "_" +
          ("0" + (date1.getMonth() + 1)).slice(-2) +
          "_" +
          date1.getFullYear() +
          ".pdf"
      );
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  }
  function downloadPayoutExcel() {
    axios({
      url: `payout/payoutStatementInExcel?fromDate=${
        fromDate?.split("T")[0]
      }&productTypeCode=4&toDate=${toDate?.split("T")[0]}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      var date1 = new Date();
      link.setAttribute(
        "download",
        "PayoutStatement_" +
          ("0" + date1.getDate()).slice(-2) +
          "_" +
          ("0" + (date1.getMonth() + 1)).slice(-2) +
          "_" +
          date1.getFullYear() +
          ".xlsx"
      );
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {sessionStorage.getItem("Profile") === "E"
            ? "PMS Revenue Credit"
            : ""}
          {sessionStorage.getItem("Profile") === "D" ? "PMS Payout" : ""}
          {sessionStorage.getItem("Profile") === "B" ? "PMS Payout" : ""}
        </Typography>
      )}
      {tableDataLength > 0 ? (
        <>
          <Button variant="contained" onClick={handleButtonClick} sx={{ m: 2 }}>
            Download
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {numSelected > 0 ? (
              // <MenuItem onClick={downloadPayoutPDFByID}>
              //   <Button variant="text" color="primary" startIcon={<DownloadForOfflineIcon />}>
              //     PDF
              //   </Button>
              // </MenuItem>
              ""
            ) : (
              <MenuItem onClick={downloadPayoutPDF}>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<DownloadForOfflineIcon />}
                >
                  PDF
                </Button>
              </MenuItem>
            )}
            <MenuItem onClick={downloadPayoutExcel}>
              <Button
                variant="text"
                color="primary"
                startIcon={<DownloadForOfflineIcon />}
              >
                Excel
              </Button>
            </MenuItem>
          </Menu>
        </>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function PmsPayoutSortTable(props) {
  console.log("Props", props);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("orderId");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [refresh, setRefresh] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [emptyResult, setEmptyResult] = React.useState(false);
  const dateString = props.toDate;
  const toDate = new Date(dateString);
  // toDate.setDate(toDate.getDate() + 1);

  React.useEffect(() => {
    // axios.get("payout?productTypeCode="+productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM)
    axios
      .get(
        "payout/payoutFilter?fromDate=" +
          changeDate(props.fromDate) +
          "&productTypeCode=" +
          productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM +
          "&toDate=" +
          changeDate(props.toDate) +
          ""
      )
      .then((res) => {
        function createData(
          orderId,
          companyName,
          disUserName,
          employeeName,
          scriptName,
          clientName,
          buyRate,
          totalTranValue,
          payoutAmount,
          gstAmount,
          tdsAmount,
          totalPayout,
          payoutGiven,
          brokerPayout,
          revenueGiven,
          payoutId
        ) {
          return {
            orderId,
            companyName,
            disUserName,
            employeeName,
            scriptName,
            clientName,
            buyRate,
            totalTranValue,
            payoutAmount,
            gstAmount,
            tdsAmount,
            totalPayout,
            payoutGiven,
            brokerPayout,
            revenueGiven,
            payoutId,
          };
        }

        if (res.data.payoutDTOList.length === 0) {
          setEmptyResult(true);
        } else {
          const rowData = res.data.payoutDTOList.map((item) =>
            createData(
              item.orderId,
              item.companyName + "".trim(),
              item.disUserName + "".trim(),
              item.employeeName + "".trim(),
              item.scriptName + "".trim(),
              item.clientName + "".trim(),
              item.buyRate + 0,
              item.totalTranValue + 0,
              item.payoutAmount + 0,
              item.gstAmount + 0,
              item.tdsAmount + 0,
              item.totalPayout + 0,
              item.payoutGiven,
              item.brokerPayout,
              item.revenueGiven,
              item.payoutId
            )
          );
          setRows(rowData);
        }
      })
      .catch((error) => {
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props.toDate, props.fromDate, refresh]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.orderId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  function downloadSingleInvoice(orderId) {
    axios({
      url: `payout/payoutInvoice?orderIds=${orderId}&productTypeCode=4`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      var date1 = new Date();
      link.setAttribute(
        "download",
        "PMS_Invoice" +
          ("0" + date1.getDate()).slice(-2) +
          "_" +
          ("0" + (date1.getMonth() + 1)).slice(-2) +
          "_" +
          date1.getFullYear() +
          ".pdf"
      );
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  }
  const approvePayout = (orderId) => {
    axios
      .put(`payout/approve?orderId=${orderId}`)
      .then(() => setRefresh(!refresh))
      .catch((err) => {
        toast.error("Something Went Wrong!");
      });
  };
  const approveRevenueGiven = (orderId) => {
    axios
      .put(`payout/approveRevenueGiven?orderId=${orderId}`)
      .then(() => setRefresh(!refresh))
      .catch((err) => {
        toast.error("Something Went Wrong!");
      });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          toDate={props.toDate}
          fromDate={props.fromDate}
          selectedPayout={selected}
          downloadSingleInvoice={downloadSingleInvoice}
          tableDataLength={visibleRows.length}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {isLoading ? (
                // Show loading spinner
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell
                    style={{
                      position: "relative",
                      left: "40rem",
                      padding: "0.5rem",
                    }}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : emptyResult ? (
                // Show "No results found" statement
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell
                    style={{
                      position: "relative",
                      left: "40rem",
                      padding: 0,
                    }}
                  >
                    <h6>No results found...</h6>
                  </TableCell>
                </TableRow>
              ) : (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.orderId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      //hover
                      //onClick={(event) => handleClick(event, row.orderId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.orderId}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell></TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.orderId}
                      </TableCell>
                      <TableCell align="left">
                        {row.companyName || "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row.disUserName === "null" ? "-" : row.disUserName}
                      </TableCell>
                      <TableCell align="left">
                        {row.employeeName || "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row.scriptName || "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row.clientName === "null" ? "-" : row.clientName}
                      </TableCell>
                      <TableCell align="right">
                        {row.buyRate.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {row.totalTranValue.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {row.payoutAmount.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {row.gstAmount.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) || "-"}
                      </TableCell>
                      <TableCell align="right">
                        {row.tdsAmount.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) || "-"}
                      </TableCell>
                      <TableCell align="right">
                        {row.totalPayout.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell align="left">
                        {row.payoutGiven ? <>Yes</> : <>No</>}
                      </TableCell>
                      {sessionStorage.getItem("Profile") === "B" ||
                      sessionStorage.getItem("Profile") === "E" ? (
                        <TableCell align="left">
                          {row.brokerPayout || "-"}
                        </TableCell>
                      ) : (
                        <></>
                      )}
                      {sessionStorage.getItem("Profile") === "B" ||
                      sessionStorage.getItem("Profile") === "E" ? (
                        <TableCell align="left">
                          {row.revenueGiven ? <>Yes</> : <>No</>}
                        </TableCell>
                      ) : (
                        <></>
                      )}
                      {sessionStorage.getItem("Profile") == "B" ? (
                        <TableCell align="left">
                          {row.payoutGiven ? (
                            <Button variant="contained" disabled>
                              Paid
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => {
                                approvePayout(row.orderId);
                              }}
                            >
                              {" "}
                              Paid
                            </Button>
                          )}
                        </TableCell>
                      ) : (
                        <></>
                      )}
                      {sessionStorage.getItem("Profile") == "B" ? (
                        <TableCell align="left">
                          {row.revenueGiven ? (
                            <Button variant="contained" disabled>
                              Paid
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => {
                                approveRevenueGiven(row.orderId);
                              }}
                            >
                              {" "}
                              Paid
                            </Button>
                          )}
                        </TableCell>
                      ) : (
                        <></>
                      )}
                      {sessionStorage.getItem("Profile") === "B" ||
                      sessionStorage.getItem("Profile") === "D" ? (
                        <TableCell align="left">
                          <Button
                            variant="contained"
                            onClick={() => {
                              downloadSingleInvoice(row.orderId);
                            }}
                          >
                            Invoice
                          </Button>
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
