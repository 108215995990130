import React from 'react'
import StartWithUs from '../StartWithUs/StartWithUs'
import { Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const ProductEquity = () => {
  return (
    <Container>
    <Grid md={12} className={sessionStorage.getItem("JWT") ? 'your-dynamic-css-class-product' : 'default-class-product'}>
   <Typography style={{ textAlign: "left", fontSize: "30px", fontWeight: "bold",borderBottom: "4px solid black"}}>Stock Broking</Typography>
   <Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold", textDecoration: "underline",paddingTop:"10px" }}>Benefits of opening an equity trading account </Typography>
   <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
   Purchasing or selling shares once required speaking with your broker on the phone or sending a letter through the mail. Shares were held in tangible form,
    clearing took a long time, and fraud, forgery, or impersonation risk were constant concerns. All of this changed when online equity trading accounts were made available in the Indian markets.
     Here are more benefits:
  </Typography>
  </Grid>
  <br />

  <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify"}}>
  <span style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>•	No Paperwork:</span> As mentioned earlier, before demat accounts came around, trading and investing required lots of paperwork 
  and shares had to be held physically. An equity trading account (demat account) eliminates this requirement.
  </Typography>
  <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify"}}>
  <span style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>• Invest in Your Favoured Instruments: </span>
  Subscribe for IPOs or mutual funds, invest in public companies whose story you want to be a part of, invest short term or long – it’s your market!
  </Typography>
  <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify"}}>
  <span style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>• Trading Much Faster & Diversified </span>
  Shares across segments can be purchased or sold instantly using an equity trading account. Settlements are quick and allow multi-leg trading strategies.
  </Typography>
  <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify"}}>
  <span style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>• Prevents Loss, Theft & Damage to Your Holdings: </span>
  Your holdings are held in dematerialized form, which ensures that they are guarded against deterioration through use, loss or theft.
  </Typography>
  <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify"}}>
  <span style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>• Different Instruments in a Single Account:  </span>
  You can invest in stocks, Commodities, F&O, ELSS, Currencies and much more through your online demat account
  </Typography>
  <br />
  <br />
  <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "bold"}}>Our Strength: -</Typography>
      <List>
      <ListItem>
        <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="An enriched trading experience with best-in-class platform." />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Strong in-house qualified research team." />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Dedicated Dealing team for all your real time trades." />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Contract Note for all your trades" />
      </ListItem>
    </List>
    <br />


 
 









{sessionStorage.getItem("JWT")?"":
<Grid mt={2} mb={2}>
<StartWithUs/>
</Grid>
}
    </Container>
    
  )
}

export default ProductEquity