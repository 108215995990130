import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormGroup,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useQuery } from "react-query";
import { PmsAmcList } from "../../api/Api";
import { benchMarks } from "./FIltersBenchmark";
import { fundCategories } from "./FiltersFundCategories";

const FilterPMS = (props: any) => {
  const { data: aifAmcList } = useQuery("PMS_AMC_LISTs", PmsAmcList, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => setAmcData(data.data.amcDetails),
  });

  const [amcData, setAmcData] = React.useState<String[]>([]);
  const [value, setValue] = React.useState("1M");
  const [filterAMC, setFilterAMC] = React.useState<any>([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedAMC, setSelectedAMC] = React.useState<any>([]);
  const [selectedAUM, setSelectedAUM] = useState<string | null>(null);
  const [searchBenchmark, setSearchBenchmark] = React.useState("");
  const [searchCategory, setSearchCategory] = React.useState("");

  React.useEffect(() => {
    props.setFilterDataURL(filterAMC);
  }, [filterAMC, value, props]);

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchBenchmark = (event: any) => {
    setSearchBenchmark(event.target.value);
  };

  const handleSearchCategory = (event: any) => {
    setSearchCategory(event.target.value);
  };

  const filteredAMCList =
    aifAmcList &&
    amcData.filter((item: any) => {
      return item.amcName.toLowerCase().includes(searchTerm.toLowerCase());
    });

  const filteredBenchMarks =
    benchMarks &&
    benchMarks.filter((benchMark: any) => {
      return benchMark.name
        .toLowerCase()
        .includes(searchBenchmark.toLowerCase());
    });

  const filteredCategories =
    fundCategories &&
    fundCategories.filter((fundCat: any) => {
      return fundCat.name.toLowerCase().includes(searchCategory.toLowerCase());
    });

  const changeAMCList = (event: any, amcId: any) => {
    const value = "amcIds=" + amcId;

    if (selectedAMC.includes(amcId)) {
      setSelectedAMC(selectedAMC.filter((name: any) => name !== amcId));
    } else {
      setSelectedAMC([...selectedAMC, amcId]);
    }

    const isChecked = event.target.checked;
    if (isChecked) {
      setFilterAMC([...filterAMC, value]);
    } else {
      setFilterAMC(filterAMC.filter((item: any) => item !== value));
    }
  };

  const changeAssetClass = (event: any, assetClass: any) => {
    const value = "assets=" + assetClass;
    const isChecked = event.target.checked;
    if (isChecked) {
      setFilterAMC([...filterAMC, value]);
    } else {
      setFilterAMC(filterAMC.filter((item: any) => item !== value));
    }
  };

  const changeCategories = (event: any, categories: any) => {
    const value = "categories=" + encodeURIComponent(categories);
    const isChecked = event.target.checked;
    if (isChecked) {
      setFilterAMC([...filterAMC, value]);
    } else {
      setFilterAMC(filterAMC.filter((item: any) => item !== value));
    }
  };

  const changeBenchMark = (event: any, assetClass: any) => {
    // const value = "benchmarks=" + assetClass;
    const value = "benchmarks=" + encodeURIComponent(assetClass);
    const isChecked = event.target.checked;
    if (isChecked) {
      setFilterAMC([...filterAMC, value]);
    } else {
      setFilterAMC(filterAMC.filter((item: any) => item !== value));
    }
  };

  // const changeAUM = (event: any, min: any, max: any) => {
  //   const value = "maxAum=" + max + "&minAum=" + min;
  //   const isChecked = event.target.checked;
  //   if (isChecked) {
  //     setFilterAMC([...filterAMC, value]);
  //   } else {
  //     setFilterAMC(filterAMC.filter((item: any) => item !== value));
  //   }
  // }
  const changeAUM = (min: string, max: string) => {
    const value = `minAum=${min}&maxAum=${max}`;
    setSelectedAUM((prevValue) => (prevValue === value ? null : value));
    setFilterAMC((prevFilters: any) =>
      prevFilters.includes(value) ? [] : [value]
    );
  };

  const handleChange = (event: any) => {
    const value1 = "sortByReturn=" + event.target.value;
    props.setReturn(value1);
    setValue(event.target.value);
  };

  return (
    <div className="filter_container filter_container-filter-aif-main">
      <div className="filter_dropdowns">
        <Paper
          sx={{
            height: "47px",
            width: "100%",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            display: "flex",
          }}
        >
          <Typography
            sx={{
              letterSpacing: "1px",
              fontWeight: "600",
              padding: "10px",
              color: "primary",
              marginLeft: "5px",
            }}
          >
            Filters
          </Typography>
          <TuneRoundedIcon
            sx={{ fontSize: "1rem", marginTop: "15px", color: "primary" }}
          />
          <Button
            sx={{ textTransform: "none", margin: "10px", marginLeft: "auto" }}
            size="small"
          >
            Clear
          </Button>
        </Paper>
        {/* AMC */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {/* <ListAltRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
            <Typography
              sx={{
                // marginLeft:'8px',
                fontSize: "0.9rem",
                fontWeight: "500",
                color: "#3c3b3b",
              }}
            >
              AMC
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {/* Search AMC */}
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search AMC"
                  onChange={handleSearch}
                />
              </Paper>

              <FormGroup
                sx={{
                  overflowX: "scroll",
                  maxHeight: "350px",
                  textAlign: "left",
                  flexDirection: "row",
                }}
              >
                {filteredAMCList &&
                  filteredAMCList
                    .sort((a: any, b: any) =>
                      b.amcName.toLowerCase() < a.amcName.toLowerCase() ? 1 : -1
                    )
                    .map((amc: any) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={amc.amcId}
                            checked={selectedAMC.includes(amc.amcId)}
                          />
                        }
                        label={amc.amcName}
                        onChange={(event: any) =>
                          changeAMCList(event, amc.amcId)
                        }
                        id={amc.amc_id}
                      />
                    ))}
              </FormGroup>

              {/* <Checkbox
                checked={amc.checked}
                onChange={handleChange}
                id={amc.amcId}
                inputProps={{ 'aria-label': 'controlled' }}
              /> */}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Assect Class */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {/* <PeopleAltRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
            <Typography
              sx={{
                color: "#3c3b3b",
                //   marginLeft: "8px",
                fontSize: "0.9rem",
                fontWeight: "500",
                letterSpacing: "1px",
              }}
            >
              Asset
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Equity"
                  onChange={(event: any) => changeAssetClass(event, "Equity")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Debt"
                  onChange={(event: any) => changeAssetClass(event, "Debt")}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Hybrid"
                  onChange={(event: any) => changeAssetClass(event, "Hybrid")}
                />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {/* <StoreRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
            <Typography
              sx={{
                color: "#3c3b3b",
                //   marginLeft: "8px",
                fontSize: "0.9rem",
                fontWeight: "500",
                letterSpacing: "1px",
              }}
            >
              Category
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {/* Search Category */}
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Category"
                  onChange={handleSearchCategory}
                />
              </Paper>
              <FormGroup>
                {/* <FormControlLabel control={<Checkbox />} label="All Category" onChange={(event: any) => changeCategories(event, "All Category")} /> */}
                {filteredCategories.map((fundCat) => {
                  const { id, name, value } = fundCat;
                  return (
                    <FormControlLabel
                      key={id}
                      control={<Checkbox />}
                      label={name}
                      onChange={(event: any) => changeCategories(event, value)}
                    />
                  );
                })}
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {/* <SendRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
            <Typography
              sx={{
                color: "#3c3b3b",
                //   marginLeft: "8px",
                fontSize: "0.9rem",
                fontWeight: "500",
                letterSpacing: "1px",
              }}
            >
              Bench Mark
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {/* Search Benchmark */}
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Benchmark"
                  onChange={handleSearchBenchmark}
                />
              </Paper>
              <FormGroup>
                {/* <FormControlLabel control={<Checkbox />} label="All Benchmark" onChange={(event: any) => changeBenchMark(event, "All Benchmark")} /> */}
                {filteredBenchMarks.map((benchMark) => {
                  const { id, name, value } = benchMark;
                  return (
                    <FormControlLabel
                      key={id}
                      control={<Checkbox />}
                      label={name}
                      onChange={(event: any) => changeBenchMark(event, value)}
                    />
                  );
                })}
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                color: "#3c3b3b",
                fontSize: "0.9rem",
                fontWeight: "500",
                letterSpacing: "1px",
              }}
            >
              AUM
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Typography>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="< 100 Cr" onChange={(event: any) => changeAUM(event, "0", "100")} />
                <FormControlLabel control={<Checkbox />} label="100-500 cr" onChange={(event: any) => changeAUM(event, "100", "500")} />
                <FormControlLabel control={<Checkbox />} label="500- 2000 Cr" onChange={(event: any) => changeAUM(event, "500", "2000")} />
                <FormControlLabel control={<Checkbox />} label="> 2000 cr" onChange={(event: any) => changeAUM(event, "2000", "0")} />
              </FormGroup>
            </Typography> */}
            <Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Radio checked={selectedAUM === "minAum=0&maxAum=100"} />
                  }
                  label="< 100 Cr"
                  onChange={() => changeAUM("0", "100")}
                />
                <FormControlLabel
                  control={
                    <Radio checked={selectedAUM === "minAum=100&maxAum=500"} />
                  }
                  label="100-500 cr"
                  onChange={() => changeAUM("100", "500")}
                />
                <FormControlLabel
                  control={
                    <Radio checked={selectedAUM === "minAum=500&maxAum=2000"} />
                  }
                  label="500-2000 Cr"
                  onChange={() => changeAUM("500", "2000")}
                />
                <FormControlLabel
                  control={
                    <Radio checked={selectedAUM === "minAum=2000&maxAum=0"} />
                  }
                  label="> 2000 cr"
                  onChange={() => changeAUM("2000", "0")}
                />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                color: "#3c3b3b",

                fontSize: "0.9rem",
                fontWeight: "500",
                letterSpacing: "1px",
              }}
            >
              Returns
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign={"left"}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1M"
                    control={<Radio />}
                    label="1 Month"
                  />
                  <FormControlLabel
                    value="3M"
                    control={<Radio />}
                    label="3 Month"
                  />
                  <FormControlLabel
                    value="6M"
                    control={<Radio />}
                    label="6 Month"
                  />
                  <FormControlLabel
                    value="1Y"
                    control={<Radio />}
                    label="1 Year"
                  />
                  <FormControlLabel
                    value="2Y"
                    control={<Radio />}
                    label="2 Year"
                  />
                  <FormControlLabel
                    value="3Y"
                    control={<Radio />}
                    label="3 Year"
                  />
                  <FormControlLabel
                    value="5Y"
                    control={<Radio />}
                    label="5 Year"
                  />
                  <FormControlLabel
                    value="Ince"
                    control={<Radio />}
                    label="Since Inception"
                  />
                </RadioGroup>
              </FormControl>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default FilterPMS;
