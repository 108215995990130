/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { MenufactureOrderDetails } from "../../api/Api";
import { withParam } from "../../utils/Router.Helper";
import {
  Container,
  Box,
  Typography,
  Button,
  Modal,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
// import { DownloadDocument } from "./DownloadDocument";
import { toast } from "react-toastify";
import axios from "axios";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  "& .MuiTextField-root": { m: 1 },
};

const MenufactureOrderDetail = (props: any) => {
  const { data, refetch } = useQuery(
    "ManufactureOrder",
    () => MenufactureOrderDetails(productMasterCode),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [unlistedModel, setUnlistedModel] = useState(false);
  const [maturityDate, setMaturityDate] = React.useState<Dayjs | any>(
    data && data.data.unlisteds[0].validTillDate
  );

  const resolver: any = async (values: any) => {
    return {
      values: !values.manufactureSaleRate ? {} : values,
      errors: !values.manufactureSaleRate
        ? {
            manufactureSaleRate: {
              type: "required",
              message: "This is required.",
            },
          }
        : {},
    };
  };

  const { register, handleSubmit, setValue } = useForm({
    resolver: resolver,
  });

  useEffect(() => {
    setValue(
      "manufactureSaleRate",
      (data && data?.data?.unlisteds[0]?.manufactureSaleRate) || 0
    );
    setValue(
      "manufactureBuyRate",
      (data && data?.data?.unlisteds[0]?.manufactureBuyRate) || 0
    );
    setValue("quantity", data && data?.data?.unlisteds[0]?.quantity);
    setMaturityDate(data && data?.data?.unlisteds[0]?.validTillDate);
  }, [data]);

  const productMasterCode = props.params.productMasterCode;

  function updateUnlistedShareModel(status: any) {
    return status === "open"
      ? setUnlistedModel(true)
      : status === "close"
      ? setUnlistedModel(false)
      : "";
  }

  const activeInactiveUnlisted = () => {
    axios
      .put("unlisted/softDeleteUnlistedProduct/" + productMasterCode)
      .then(() => {
        toast.success("Success");
        refetch();
      })
      .catch((err: any) =>
        toast.error(
          err.response.data ? err.response.data.status.message.title : "Error"
        )
      );
  };

  const onSubmit = (data: any) => {
    var postData = {
      ...data,
      productMasterCode: productMasterCode,
      validTillDate: maturityDate,
    };
    axios
      .put("unlisted/updateUnlisted", postData)
      .then(() => toast.success("Updated Successfully!"))
      .then(() => {
        updateUnlistedShareModel("close");
        refetch();
      })
      .catch((err: any) =>
        toast.error(
          err.response.data ? err.response.data.status.message.title : "Error"
        )
      );
  };

  return (
    <Container maxWidth={"xl"}>
      <Box
        sx={{
          minHeight: "calc(100vh - 150px)",
          marginTop: "5%",
          textAlign: "left",
          marginLeft: "5%",
          marginRight: "5%",
        }}
      >
        {!!data ? (
          <>
            <Typography gutterBottom>
              Product Name : {data.data.unlisteds[0].productName}
            </Typography>
            <Typography gutterBottom>
              Quantity : {data.data.unlisteds[0].quantity}
            </Typography>
            <Typography gutterBottom>
              Manufacture Cost Price :{" "}
              {data?.data?.unlisteds[0]?.manufactureBuyRate || 0}
            </Typography>
            <Typography gutterBottom>
              Manufacture Landing Price :{" "}
              {data.data.unlisteds[0].manufactureSaleRate}
            </Typography>
            <Typography gutterBottom>
              Description : {data.data.unlisteds[0].description}
            </Typography>
          </>
        ) : (
          <></>
        )}
        {/* <Stack spacing={2} direction="row"> */}
        <Button
          variant="contained"
          sx={{ mr: 1, mt: 2 }}
          size="small"
          onClick={() => updateUnlistedShareModel("open")}
        >
          Update
        </Button>
        {/* { data && (
            <DownloadDocument reportDocument={"reportDocument"} unlistedMasterCode={data.data.unlisteds[0].unlistedMasterCode}/> 
        ) } */}
        {/* </Stack> */}

        <Button
          variant="contained"
          sx={{ mt: 2 }}
          size="small"
          onClick={activeInactiveUnlisted}
        >
          {data && data.data.unlisteds[0].isActive == true
            ? "Inactive Unlisted"
            : "Active Unlisted"}
        </Button>
      </Box>

      <Modal
        open={unlistedModel}
        onClose={() => updateUnlistedShareModel("close")}
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Update Unlisted Share
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {sessionStorage.getItem("Profile") === "B" && (
              <TextField
                size="small"
                id="outlined-basic"
                label="Manufacture Cost Price"
                variant="outlined"
                {...register("manufactureBuyRate")}
                disabled
              />
            )}
            <TextField
              size="small"
              id="outlined-basic"
              label="Manufacture Landing Price"
              variant="outlined"
              {...register("manufactureSaleRate")}
              disabled
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="Quantity"
              variant="outlined"
              {...register("quantity")}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Valid Till Date"
                inputFormat="DD/MM/YYYY"
                value={maturityDate}
                onChange={(newValue) => {
                  setMaturityDate(newValue.toJSON());
                  setValue("validTillDate", newValue.toJSON());
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    // sx={{width:"100%", marginBottom:'20px'}}
                  />
                )}
              />
            </LocalizationProvider>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </Container>
  );
};

export default withParam(MenufactureOrderDetail);
