import { Button, CircularProgress, Container, Grid, Paper , Stack, Typography} from '@mui/material'
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { withParam } from '../utils/Router.Helper';
import Calculator from './Calculator';
import "../css/bond-details.css"
import {bondData} from "../Constants/bondData"
import ArticleIcon from '@mui/icons-material/Article';
import BarChart from './BarChartMaturity';
import CashFlowTab from './CashFlowTab';
import { toast } from 'react-toastify';
import { convertDateDMY } from '../utils/Helper';
import { bondEnum } from '../Constants/bondEnum';

const BondDetails = (props:any) => {
    console.log(props.params);
    
    const [Bdata, setBdata] = useState<any>([]);
    const [showCircularProgress, setshowCircularProgress] = useState(false);
    // const [active , setActive] = useState<any>(!!!Bdata.isActive);

    const ApproveBond = (active:any) => {
    setshowCircularProgress(true)
    const formData = new FormData();
    formData.append("bondWorkDTO",JSON.stringify({
        abbreviation: Bdata.abbreviation,
                bondType: Bdata.bondType,
                callDate: Bdata.callDate,
                callPutDate: Bdata.callPutDate,
                coupon: Bdata.coupon,
                couponBasis: Bdata.couponBasis,
                couponFrequency: Bdata.couponFrequency,
                dayCountConvention: Bdata.dayCountConvention,
                debentureTrustee: Bdata.debentureTrustee,
                faceValue: Bdata.faceValue,
                governmentGauranteed: Bdata.governmentGauranteed,
                imPdfRefNo: Bdata.imPdfRefNo,
                interestPaymentDates: Bdata.interestPaymentDates,
                isActive: active,
                isApproved: Bdata.isApproved,
                isinNumber: Bdata.isinNumber,
                issuanceDate: Bdata.issuanceDate,
                issuerName: Bdata.issuerName,
                issuerType: Bdata.issuerType,
                listedUnlisted: Bdata.listedUnlisted,
                maturityDate: Bdata.maturityDate,
                modeOfIssuance: Bdata.modeOfIssuance,
                psuNonPsu: Bdata.psuNonPsu,
                putDate: Bdata.putDate,
                rating1: Bdata.rating1,
                rating2: Bdata.rating2,
                rating3: Bdata.rating3,
                recordDays: Bdata.recordDays,
                remarks: Bdata.remarks,
                rtaDetails: Bdata.rtaDetails,
                securedUnsecured: Bdata.securedUnsecured,
                seniorityOfRepayment: Bdata.seniorityOfRepayment,
                stepUpStepDown: Bdata.stepUpStepDown,
                taxability: Bdata.taxability,
                tier: Bdata.tier
    })) 
        axios.put("bondwork/updateBondWork" , formData)
        .then((res:any)=> toast.success("Successfull"))
        .then(()=> {setshowCircularProgress(false);})
        .then(()=>window.location.reload())
        .catch((err:any)=>{setshowCircularProgress(false); toast.error( 
            !!err.response?.data?.status?.message?.title ? err.response.data.status.message.title : err.response.data.messageType)})
    }


  const getApiData = async () => {
    axios.get("bondwork/listOfBondDetails/"+(props.params.isin)).then((res:any) => {
      setBdata(res.data.bondWork[0])
    })
    .catch((error:any) => {
      console.error("Error",error);
    })
  } 
    useEffect(() => {
      getApiData();
    }, []);

    console.log("BondData",Bdata)

  return (
    <Box sx={{padding:'0 24px', marginTop:'2%'}}>
                <Box className='bond_heading'>
                 <ArticleIcon/><Typography sx={{marginLeft:'1rem'}} variant='h5'>{Bdata.issuerName}</Typography>
                </Box>
        <Grid container spacing={0.5}>
            <Grid item md={9}>

                <Box className='essensial_container'>
                    <div className='essensial_heading'>
                    <Typography variant='h5'>Essentials</Typography>
                    </div>
                    <div>
                        <Grid container sx={{color:'#786c6c'}}>
                            <Grid item md={2.4} sx={{textAlign:'center'}}>
                                <Typography variant='caption'>ISIN</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{Bdata.isinNumber}</b></Typography>

                                <Typography variant='caption'>Put Option</Typography>
                                <Typography variant='subtitle1'sx={{marginBottom:'10%'}}><b>{!!Bdata.putDate ? convertDateDMY(Bdata.putDate.slice(0,10)) : "--"}</b></Typography>
                            </Grid>
                            <Grid item md={2.4} sx={{textAlign:'center'}}>
                                <Typography variant='caption'>Coupon Rate</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{Bdata.coupon}%</b></Typography>

                                <Typography variant='caption'>Interest payment Frequency</Typography>
                                {/* <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{Bdata.couponFrequency}</b></Typography> */}
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{bondEnum.CouponFrequencyEnum.find(entry => entry.value === Bdata.couponFrequency)?.label}</b></Typography>
                            </Grid>
                            <Grid item md={2.4} sx={{textAlign:'center'}}>
                                <Typography variant='caption'>Maturity Date</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{Bdata.maturityDate && convertDateDMY(Bdata.maturityDate.slice(0,10))}</b></Typography>

                                <Typography variant='caption'>Interest Payment Dates</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{Bdata.interestPaymentDates && Bdata.interestPaymentDates}</b></Typography>
                            </Grid>
                            <Grid item md={2.4} sx={{textAlign:'center'}}>
                                <Typography variant='caption'>Face Value</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{Bdata.faceValue && Bdata.faceValue.toLocaleString('en-IN')}</b></Typography>

                                <Typography variant='caption'>Rating</Typography>
                                <Typography variant='subtitle1'sx={{marginBottom:'10%'}}><b>{Bdata.rating1}</b></Typography>
                            </Grid>
                            <Grid item md={2.4} sx={{textAlign:'center'}}>
                            <Typography variant='caption'>Call Option</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{!!Bdata.callDate ? convertDateDMY(Bdata.callDate.slice(0,10)) : "--"}</b></Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Box>

                <Box className='issuer_container' sx={{p:"2%"}}>
                    <div className='issuer_heading'>
                    <Typography variant='h5'>Additional Details</Typography>
                    </div>
                    <div>
                        <Grid container sx={{color:'#786c6c'}}>
                            <Grid item md={2.4} sx={{textAlign:'center'}}>
                                <Typography variant='caption'>Govt. Guarantee</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{bondEnum.GaurentedEnum.find(entry => entry.value === Bdata.governmentGauranteed)?.label}</b></Typography>

                                <Typography variant='caption'>Coupon Type</Typography>
                                <Typography variant='subtitle1'sx={{marginBottom:'10%'}}><b>{ bondEnum.CouponBasisEnum.find(entry => entry.value === Bdata.couponBasis)?.label}</b></Typography>

                                <Typography variant='caption'>Issuer Type</Typography>
                                <Typography variant='subtitle1'sx={{marginBottom:'10%'}}><b>{bondEnum.IssuerTypeEnum.find(entry => entry.value === Bdata.issuerType)?.label}</b></Typography>
                            </Grid>
                            <Grid item md={2.4} sx={{textAlign:'center'}}>
                                <Typography variant='caption'>Security</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{bondEnum.SecuredUnsecuredEnum.find(entry => entry.value === Bdata.securedUnsecured)?.label}</b></Typography>

                                <Typography variant='caption'>Issue Date</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{Bdata.issuanceDate && convertDateDMY(Bdata.issuanceDate.slice(0,10))}</b></Typography>

                                <Typography variant='caption'>Debenture Trustee</Typography>
                                <Typography variant='subtitle1'sx={{marginBottom:'10%'}}><b>{Bdata.debentureTrustee}</b></Typography>
                            </Grid>
                            <Grid item md={2.4} sx={{textAlign:'center'}}>
                                <Typography variant='caption'>Seniority</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{bondEnum.RepaymentEnum.find(entry => entry.value === Bdata.seniorityOfRepayment)?.label}</b></Typography>

                                {/* <Typography variant='caption'>Issue Size</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{Bdata.issueSize}</b></Typography> */}

                                <Typography variant='caption'>RTA</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{Bdata.rtaDetails}</b></Typography>
                            </Grid>
                            <Grid item md={2.4} sx={{textAlign:'center'}}>
                                <Typography variant='caption'>Taxability</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{ bondEnum.TaxabilityEnum.find(entry => entry.value === Bdata.taxability)?.label}</b></Typography>

                                <Typography variant='caption'>Issue Type</Typography>
                                <Typography variant='subtitle1'sx={{marginBottom:'10%'}}><b>{bondEnum.ModeOfIssuanceEnum.find(entry => entry.value === Bdata.modeOfIssuance)?.label}</b></Typography>
                            </Grid>
                            <Grid item md={2.4} sx={{textAlign:'center'}}>
                                <Typography variant='caption'>Listing Status</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{bondEnum.ListedUnlistedEnum.find(entry => entry.value === Bdata.listedUnlisted)?.label}</b></Typography>

                                <Typography variant='caption'>Ownership</Typography>
                                <Typography variant='subtitle1' sx={{marginBottom:'10%'}}><b>{bondEnum.PsuNonPsuEnum.find(entry => entry.value === Bdata.psuNonPsu)?.label}</b></Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Grid>

            <Grid item md={3}>
                <div className='calc_container'>
                <Box>
                    <Calculator item={Bdata}/>
                </Box>
                </div>
            </Grid>

        </Grid>

        <div className='cashflow_heading'>
            <Typography variant='h6'>Cashflow</Typography>
        </div>

        <Grid container>
            <Grid item md={12}>
                <Container>
                {/* <BarChartToggle item={Bdata}/> */}
                {/* <BarChart/> */}
                <CashFlowTab isin={props.params.isin} callPut={Bdata.callPutDate}/>
                </Container>
            </Grid>
            {
                sessionStorage.getItem('Profile') == 'A' || sessionStorage.getItem('Profile') == 'B' ? (

            <Stack sx={{ justifyContent:'center', margin:'20px auto'}} spacing={3} direction={'row'}>
                <Button sx={{marginLeft:'auto'}} href={'/updateCashflow/'+ Bdata.isinNumber} size='small' variant='contained'>Update Cashflow</Button>
                <Button href={"/bondUpdate/"+Bdata.isinNumber} variant='contained' >Update Bond</Button>
                {
                    Bdata.isActive ? (
                    <Button 
                        variant='contained' 
                        onClick={()=>ApproveBond(false)} 
                    >
                        {showCircularProgress == true ? (
                          <CircularProgress
                            color="secondary"
                            size={20}
                            thickness={5}
                          />
                        ) : (
                          ""
                  )}
                        In Active
                    </Button>
                    ):(
                    <Button variant='contained' onClick={()=>ApproveBond(true)}>Activation</Button>
                    )
                }
                
            </Stack>
                )
                :
                <></>
            }
        </Grid>
    </Box>
  )
}

export default withParam(BondDetails)