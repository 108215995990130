import {
  Container,
  Box,
  Typography,
  IconButton,
  Snackbar,
  Button,
  CircularProgress,
  Modal,
  Fade,
  Backdrop,
  Grid,
} from "@mui/material";
import React, {useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { withParam } from "../../utils/Router.Helper";
import { fetchEquityData } from "../../api/Api";
import { useQuery } from "react-query";
import AddProduct from "./AIF/AddAIFProduct";
import FilterEquity from "../Filters/FilterEquity";

const Equity = () => {
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModel] = useState(false);

  const handleModalOpen = () => {
    setOpenModel(true);
  };
  const handleModalClose = () => {
    setOpenModel(false);
  };
  const handleOpenSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    visibility: "visible !important",
    opacity: "1 !important",
  };

  function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number
  ) {
    return { name, calories, fat, carbs, protein };
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // const {data, isLoading, error, isError} = useQuery("EquityData",fetchEquityData);

  // if (isError) {
  //   return (
  //     <Container maxWidth={"xl"}>
  //   <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "10%" }}>
  //     {/* {error.message} */}ERROR
  //     </Box>
  //     </Container>
  //     )
  // }

  return (
    <>
    {/* {
      isLoading ? (
        <CircularProgress
                    size={68}
                    sx={{
                      color: "primary",
                      position: "absolute",
                      top: 300,
                      left: 650,
                      zIndex: 1,
                    }}
                  />
      ) : 
      (
        <Container maxWidth={"xl"}>
        <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "1%" }}>
          <AddProduct/>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <FilterEquity/>
            </Grid>
            <Grid item md={9}>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell align="right">Scheme Name</TableCell>
                  <TableCell align="right">One Yr Return</TableCell>
                  <TableCell align="right">Two Yr Return</TableCell>
                  <TableCell align="right">Three Yr Return</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Delete Product</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              </TableBody>
            </Table>
          </TableContainer>
            </Grid>
          </Grid> 
        </Box>
      </Container>
      )
    }
      
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message="Note archived"
        action={action}
      /> */}

      <Container maxWidth='xl' sx={{marginTop:'10%'}}>
        <Typography variant="h4">
        Coming Soon...
        </Typography>
      </Container>
    </>
  );
};

export default withParam(Equity);
