export const CART_BY_USER = "proposal/getAllProposal?clientId=" 
export const SEND_OTP = "authentication/sendOtp"
export const PRODUCT_LIST = "product/listOfProductType"
export const UNLISTED_FIXED_RATE = "unlistedFixedRate/"

export const LIST_OF_CLIENTS = "client/listOfClient"

// get the data of amc list of aif
export const AIF_AMC_LIST = "amc/fetch?productCode=AIF"
export const PMS_AMC_LIST = "amc/fetch?productCode=PMS"
export const MF_AMC_LIST = "amc/fetch?productCode=MF"

// get all amc list
export const GET_ALL_AMC_LIST = "amc/getAllAmc"

// get all scheme list of aif scheme
export const AIF_LIST_ALL = "aif/aifAllDetailsOfFinalyca"

// get particular scheme data by scheme code
export const AIF_GET_BY_ID= "aif/aifDetailBySchemeCode/"

// Put method for active / inactive amc by amc id
export const AMC_ACTIVE_INACTIVE = "amc/activeNInActive/"

// get all scheme by amc id
export const GET_SCHEME_DATA_FROM_AMC_ID = "amc/getSchemeListByAmcIdNProductCode?amcId="

// Pms get all scheme list 
export const PMS_GET_ALL = "pms/getAllPmsDetails"

export const PMS_GET_BY_ID = "pms/getPmsDetailBySchemeCode/"

export const SELF_USER_PROFILE = "userProfile/profileView"

export const EMPLOYEE_DASHBOARD_FILE_LIST = "report/getReportListByMonthNDocType"

export const GET_ALL_MUTUAL_FUND = "mutualFund/getAllMutualFunds"
export const MUTUAL_FUND_GET_BY_ID = "mutualFund/getMutualFundBySchemeCode/"
export const SAVE_PROPOSAL = "proposal/saveProposal"
export const DELETE_PROPOSAL = "proposal/delete/"

export const GET_ALL_TRANSACTION = "trade/fetchTradeClientData"

export const DASHBOARD_REPORT_UPLOAD = "report/reportUpload"

export const CREATE_BOOK_A_CALL = "bookACall/createBookACall"
export const GET_ALL_BOOK_A_CALL = "bookACall/getAllBookACall"
export const BOOK_A_CALL_GET_BY_ID = "bookACall/"

export const ACTIVE_INACTIVE_AIF = "aif/activeInActive?productMasterCode="
export const ACTIVE_INACTIVE_PMS = "pms/activeInActive?productMasterCode="

export const SAVE_AMC = "amc/saveAmc"

export const MANAGER_BY_ID="fundManager/getFundManagerDetailByCode/"
export const SAVE_RESEARCH_SERVICES_PLAN="researchServices/saveResearchServicesPlan"
export const GET_ALL_RESEARCH_SERVICES_PLAN="researchServices/getAllResearchServicesPlan"
export const SAVE_RESEARCH_SERVICES="researchServices/saveResearchServiceSubscription"
export const UPDATE_RESEARCH_SERVICES="researchServices/updateResearchServiceSubscription"
export const RESEARCH_SERVICES_GET_ALL="researchServices/getAllResearchServiceSubscription"

export const GET_ALL_NCD= "ncd/getAllNcdData"
export const GET_NCD_BY_ID = "ncd/getNcdById/"

export const CREATE_NCD_SERIES = "ncd/createNcdSeries"
export const UPDATE_NCD_SERIES = "ncd/updateNcdSeries"

export const UPDATE_PRE_ORDER_STATUS = "product/order/changeOrderStatus/"
export const UPDATE_PRE_ORDER = "product/order/updatePreOrder"

export const DELETE_REPORT_FILE = "report/deleteReportData?documentId="
export const CREATE_NEW_ORDER = "product/createNewOrder"

export const PASSWORD_POLICY='userProfile/getPasswordPolicy'
export const CREATE_NEW_AIF_SCHEME='aif/createAifFundScheme'
export const CREATE_NEW_PMS_SCHEME='pms/createPmsScheme'
export const TRANSACTION_BY_CLIENT='trade/transactionByClient'
export const PRODUCT_lOAN_API ='loanMaster/saveLoan'
export const PRODUCT_INSURANCE_API='insurance/saveInsurance'
export const UPDATE_LOAN_SCHEME_API='loanMaster/updateLoan'
export const UPDATE_INSURANCE_SCHEME='insurance/updateInsurance'
export const GET_INSURANCE_SCHEME='insurance/insuranceDetail'
export const GET_LOAN_SCHEME='loanMaster?productMasterCode'
export const POST_BUSINESS_UPLOAD='payout/uploadBusinessFile'
export const POST_AMC_LOAN_SWITCH="loanMaster/loanStatus?productMasterCode="
export const POST_AMC_INSURANCE_SWITCH="insurance/insuranceStatus?productMasterCode="

