import React from "react";
import { withParam } from "../../utils/Router.Helper";
import { Typography } from "@mui/material";
import { useQuery } from "react-query";
import ManageSchemeSortTable from "./ManageSchemeSortTable";
import { useLocation } from 'react-router-dom';

interface PropsType {
  params: {
    amcId: String;
  };
}

const ManageAmcById = (props: PropsType) => {
  
const location = useLocation();
const data = location;
  const { amcId } = props.params;


  return (
	<>
  	<Typography variant="h4" gutterBottom> Manage AMC Schemes </Typography>
  	<ManageSchemeSortTable amcId={amcId} data={location?.state} />
	</>
  );
};

export default withParam(ManageAmcById);
