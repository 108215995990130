import React from "react";
import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import UnlistedTransaction from "../Transactions/UnlistedTransaction";
import BondTransaction from "../Transactions/BondTransaction";
import PmsTransaction from "../Transactions/PmsTransaction";
import FixedIncomeTransaction from "../Transactions/FixedincomeTransaction";
import MutualFundTransaction from "../Transactions/MutualFundTransaction";
import EquityTransaction from "../Transactions/EquityTransaction";
import InsuranceTransaction from "../Transactions/InsuranceTransaction";
import PrimaryIssueTransaction from "../Transactions/PrimaryIssueTransaction";
import StartupEcoTransaction from "../Transactions/StartupEcoTransaction";
import TrainingTransaction from "../Transactions/TrainingTransaction";
import AifTransaction from "../Transactions/AifTransaction";
import Ncd from "../Transactions/Ncd";
import ResearchServices from "../Transactions/ResearchServices";

interface TabPanelProps {
  children?: React.ReactNode;
  index: Number;
  value: Number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const EmployeeTransaction = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Paper elevation={3} sx={{ borderRadius: "10px" }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Unlisted" {...a11yProps(0)} />
            {/* <Tab label="Bond" {...a11yProps(1)} /> */}
            <Tab label="PMS" {...a11yProps(1)} />
            <Tab label="AIF" {...a11yProps(2)} />
            {/* <Tab label="Mutual Fund" {...a11yProps(3)} /> */}
            {/* <Tab label="Equity" {...a11yProps(5)} /> */}
            {sessionStorage.getItem("Profile") !== "D" && (
              <Tab label="Fixed Income" {...a11yProps(3)} />
            )}
            <Tab label="Research Services" {...a11yProps(4)} />
            <Tab label="NCD" {...a11yProps(5)} />
            {/* <Tab label="Insurance" {...a11yProps(6)} />
            <Tab label="Primary Issue" {...a11yProps(7)} />
            <Tab label="Startup Ecosystem" {...a11yProps(8)} />
            <Tab label="Training" {...a11yProps(9)} /> */}
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <UnlistedTransaction />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PmsTransaction />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AifTransaction />
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <MutualFundTransaction />
        </TabPanel> */}
        {sessionStorage.getItem("Profile") !== "D" ? (
          <>
            <TabPanel value={value} index={3}>
              <FixedIncomeTransaction />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ResearchServices />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Ncd />
            </TabPanel>
          </>
        ) : (
          <>
            <TabPanel value={value} index={3}>
              <ResearchServices />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Ncd />
            </TabPanel>
          </>
        )}
        {/* <TabPanel value={value} index={4}>
          <EquityTransaction />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <InsuranceTransaction />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <PrimaryIssueTransaction />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <StartupEcoTransaction />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <TrainingTransaction />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BondTransaction />
        </TabPanel> */}
      </Box>
    </Paper>
  );
};

export default EmployeeTransaction;
