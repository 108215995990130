import React from 'react';
import {
    Autocomplete,
    Container,
    Divider,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
    Stack,
    Button,
    createFilterOptions,
    Box,
  } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Calculator from "./Calculator";
import { withParam } from '../utils/Router.Helper';
import { toast } from 'react-toastify';
import TransactionCalc from './TransactionCalc';
import { GetAllClient, GetListOfUserByCompanyMasterCodeAndUserTypeCode, listOfManufactureNDistributorCompanies } from '../api/Api';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { productTypeEnum } from '../Constants/ProductTypeEnum';
import { CREATE_NEW_ORDER, SAVE_PROPOSAL } from '../utils/API_Names';

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  const style1 = {
    overflow: "scroll",
  };

  const BondTransaction = (props:any) => {
    let navigate = useNavigate();
    const [clientData, setClientData] = useState<any>([]);
    const [clientID, setClientID] = useState<any | null>();
    const [filteredID, setFilteredID] = useState<any | null>();
    const [BondDetails, setBondDetails] = useState<any | null>();
    const [companyMasterCode, setCompanyMasterCode] = useState<any | null>();
    const [distributorCode, setDistributorCode] = useState<any | null>();

    const [productMasterCode , setProductMasterCode] = useState<any>();
    const [productTypeCode , setProductTypeCode] = useState<any>();
    const [companyTypeCode, setCompanyTypeCode] = React.useState();

    const { data:ListOfCompany } = useQuery("ListOfManuAndDistList",()=>listOfManufactureNDistributorCompanies("D,E"))
    const { data:UserProfilesByCompanyMasterCode } = useQuery(["userProfileByCompany",companyMasterCode,companyTypeCode],()=>GetListOfUserByCompanyMasterCodeAndUserTypeCode(companyTypeCode==="S"?"E":companyTypeCode,companyMasterCode),{
      enabled:(!!companyMasterCode && sessionStorage.getItem("Profile")==="B") || (!!companyMasterCode && sessionStorage.getItem("Profile")==="E")
    }) 

    const { data } = useQuery(["getAllClients",companyMasterCode,distributorCode], () => GetAllClient(companyMasterCode,distributorCode), {
      onSuccess: (data: any) => {
        setClientData(data.data.clients)
      },
    });
    //Getting Client list data
  // useEffect(() => {
  //   axios
  //     .get("client/listOfClient")
  //     .then((res: any) => {
  //       console.log("client data",res.data);
  //       setClientData(res.data.clients);
  //     })
  //     .catch((error: any) => {
  //       console.error(error);
  //     });
  // }, []);

  console.log("Client--Data",clientID)

   //Filter Client Id
   useEffect(() => {
    clientData
      .filter((item: any) => item.clientId === clientID)
      .map((it: any) => setFilteredID(it));
  }, [clientData, clientID]);

  //Get Quote Details
  useEffect(()=>{
    axios.get('quoteDetails/getQuoteDataDetails/' + props?.params.isin)
    .then((res:any)=>{
      setProductMasterCode(res.data.quoteDetails[0].productMasterCode);
      setProductTypeCode(res.data.quoteDetails[0].productTypeCode);
      }
      )
  },[])

  console.log("clientID", clientID);
  console.log("filteredID", filteredID);
  console.log("product master code" , productMasterCode);
  console.log("product type code" , productTypeCode);


  //Get Bond Details
  useEffect(()=>{
    axios.get('bondwork/listOfBondDetails/' + props?.params.isin)
    .then((res:any) => setBondDetails(res.data.bondWork[0]));
  },[]) 

  console.log("Bond Details --" , companyTypeCode);
  console.log("spread--" , parseFloat(sessionStorage.getItem("spread")+""));

  //Order Api
  const placeOrder = () => {
    const obj = {
      "orderDate": new Date(),
      "productMasterCode": productMasterCode,
      "clientId": clientID,
      "version": 1,
      "productTypeCode": productTypeCode,
      "quantity": parseFloat(sessionStorage.getItem('quantity')+""),
      "userRate": parseFloat(sessionStorage.getItem('price')+""), //sell price
      "tcs": 0.5 * parseFloat(sessionStorage.getItem('price')+"") , 
      "totalValue": 0,
      "bondType": BondDetails?.bondType,
      "isinNumber": BondDetails?.isinNumber,
      "accountStatus": "PENDING",
      "coupon": BondDetails?.coupon,
      "issuerName": BondDetails?.issuerName,
      "maturityDate": BondDetails?.maturityDate,
      "transactionType": "string",
      "optionSelected": "string",
      "clientName": filteredID?.fullName,
      "distributorId": 0,
      "distributorName": "string",
      "settlementType": "Bilateral",
      "settlementMode": "OFF_MARKET",
      "dealDate": sessionStorage.getItem('dealDate'),
      "settlementDate": sessionStorage.getItem('valueDate'),
      "orderValue": 0,
      "price": parseFloat(sessionStorage.getItem('price')+""),
      "yield": parseFloat(sessionStorage.getItem('yield')+""),
      "accruedInterest": parseFloat(sessionStorage.getItem('Accrued')+""),
      "totalConsideration": parseFloat(sessionStorage.getItem('TotalConsideration')+""),
      "status": "string",
      "prinicipalAmount": parseFloat(sessionStorage.getItem('Principle')+""),
      "bondPrice": parseFloat(sessionStorage.getItem("value")+""),
      "spreadValue": parseFloat(sessionStorage.getItem("spread")+""),
      "distCompanyMasterCode" : companyMasterCode,
      "distEmployeeCode":distributorCode
      
    }
    axios.post(CREATE_NEW_ORDER, obj)
    // .then((res:any) => toast.success(res?.data?.status?.message?.title))
    .then((res:any) => toast.success("You Can View Your Orders In Pre Order Window."))
    .then(()=>
    {
      sessionStorage.removeItem('Accrued');
      sessionStorage.removeItem('TotalConsideration');
      sessionStorage.removeItem('Principle');
      sessionStorage.removeItem('price');
      sessionStorage.removeItem('yield');
      sessionStorage.removeItem('spread');
      sessionStorage.removeItem('value');
      sessionStorage.removeItem('quantity');
      sessionStorage.removeItem('dealDate');
      sessionStorage.removeItem('valueDate');
    }
    ).then(()=>navigate(`/bondDetails/${BondDetails?.isinNumber}`))
    .catch((err: any) => {
      toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title);
   });
  }

  const addToProposal = () => {
    let proposalDTO = {
      "clientId": clientID,
      "productMasterCode": productMasterCode,
      "productTypeCode": productTypeEnum.BOND_WORK,
      "clientPrice":  parseFloat(sessionStorage.getItem('price')+""),
    };
    axios.post(SAVE_PROPOSAL,proposalDTO).then((res:any)=>res && toast.success(res?.data?.status?.message?.title)).catch((err:any)=>{
      toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title);
    })
  }

    return (
        <Container>
        <Grid container sx={{marginTop:'1%'}} columnSpacing={2} rowSpacing={2}>
          <Grid container spacing={2} style={{paddingLeft:"33px"}}> 
            {sessionStorage.getItem("Profile") === "B" || sessionStorage.getItem("Profile") === "E" ? (
                <Grid item xs={12} sm={12} md={4} lg={3} textAlign="center">
                    <Autocomplete
                    size='small'
                      options={ListOfCompany ? ListOfCompany.data.companies : []}
                      getOptionLabel={(option: any) => option.companyName +" - "+ option.companyTypeCode}
                      sx={{ mb:1 }}
                      onChange={(event: any, value: any) => {
                        setCompanyMasterCode(
                          !!value ? value.companyMasterCode : ""
                        );
                        setCompanyTypeCode(
                          value ? value.companyTypeCode : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Company"
                          fullWidth
                          variant="outlined"                        
                        />
                      )}
                    />
                </Grid>
                  ) : (
                    <></>
                  )}
             { sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile") === "E" ? 
                  companyMasterCode && companyMasterCode && (
                    <Grid item xs={12} sm={12} md={4} lg={3} textAlign="center">
                      <Autocomplete
                        size='small'
                        disablePortal
                        id="combo-box-demo"
                        fullWidth
                        options={UserProfilesByCompanyMasterCode ? UserProfilesByCompanyMasterCode.data.userProfile.map((options: any) => options) : []}
                        getOptionLabel={(options: any) => options.userCode + "- " + options?.firstName + " " + options?.lastName}
                        onChange={(options: any, newValue) => {
                          setDistributorCode(newValue ? newValue.employeeCode : "");
                          // sessionStorage.setItem("CompanyData",newValue?.employeeCode)
                        }}
                        renderInput={(params: any) => (
                          <TextField {...params} label="Select User" />
                        )}
                      />
                    </Grid>
                  )
                :<></> }
      </Grid>
      <Grid item md={7} lg={7} textAlign="center">  
            <Grid container>
            { sessionStorage.getItem("Profile")==="B" || sessionStorage.getItem("Profile") === "E" ? 
            distributorCode && distributorCode && companyMasterCode &&(
  <Grid item md={6} lg={6} textAlign="center">
  <Autocomplete
    disablePortal
    id="combo-box-demo"
    options={clientData}
    getOptionLabel={(options: any) => options.fullName + " - " + options.panCardNo}
    sx={{
      width: 300,
      paddingLeft: "5%",
      paddingTop: "1%",
      margin: "12px 0",
    }}
    onChange={(event: any, options: any, newValue) => {
      newValue === "clear"
        ? setClientID(null)
        : setClientID(options.clientId);
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Select Client"
        margin="normal"
        size="small"
      />
    )}
  />
  <Divider variant="middle" />
</Grid>
):<></> }
              

              <Grid item md={6} lg={6} textAlign="center">
                <Link to={"/clients"} >
                    <Button
                      variant="contained"
                      color='primary'
                      sx={{
                        padding: "10px",
                        width: "48%",
                        marginTop: "9%",
                      }}
                      size="small"
                      >
                      Add New Client
                    </Button>
                  </Link>
                <Divider variant="middle" sx={{ marginTop: "32px" }} />
              </Grid>


              <Box sx={{ border: "0.2px solid black", borderRadius: "10px" }}>
                <Grid container>
                  <Grid item md={6} lg={6} textAlign="center">
                    <Typography variant="h6">Bank Details</Typography>
                    <TextField
                      label={"Bank Name"}
                      id="margin-normal"
                      InputProps={{ readOnly: true }}
                      value={clientData ? (clientID != null ? filteredID?.bankName : "") : ""}
                      sx={{ margin: "15px 0", width: 300 }}
                      size="small"
                    />
                  </Grid>
                  <Grid item md={6} lg={6} textAlign="center">
                    <Typography variant="h6">DEMAT Details</Typography>
                    <TextField
                      label={"Depository"}
                      id="margin-normal"
                      InputProps={{ readOnly: true }}
                      value={clientData ? (clientID != null ?filteredID?.dpName : "") : ""}
                      sx={{ margin: "15px 0", width: 300 }}
                      size="small"
                    />
                  </Grid>

                  <Grid item md={6} lg={6} textAlign="center">
                    <TextField
                      id="outlined-number"
                      type="text"
                      label="Branch"
                      InputProps={{ readOnly: true }}
                      value={clientData ? ( clientID != null ? filteredID?.branchName : ""): ""}
                      sx={{ margin: "10px 0", width: 300 }}
                      size="small"
                    />
                  </Grid>
                  <Grid item md={6} lg={6} textAlign="center">
                    <TextField
                      label={"DP Name"}
                      id="margin-normal"
                      InputProps={{ readOnly: true }}
                      value={clientData ? (clientID != null ?filteredID?.dpName : ""):""}
                      sx={{ margin: "10px 0", width: 300 }}
                      size="small"
                    />
                  </Grid>

                  <Grid item md={6} lg={6} textAlign="center">
                    <TextField
                      id="outlined-number"
                      label="IFSC"
                      InputProps={{ readOnly: true }}
                      value={clientData ?(clientID != null ?filteredID?.ifscCode : ""):""}
                      sx={{ margin: "10px 0", width: 300 }}
                      size="small"
                    />
                  </Grid>
                  <Grid item md={6} lg={6} textAlign="center">
                    <TextField
                      label={"DP ID"}
                      id="margin-normal"
                      InputProps={{ readOnly: true }}
                      value={clientData ? (clientID != null ?filteredID?.dpId : ""):""}
                      sx={{ margin: "10px 0", width: 300 }}
                      size="small"
                    />
                  </Grid>

                  <Grid item md={6} lg={6} textAlign="center">
                    <TextField
                      id="outlined-number"
                      label="Bank Account Number"
                      InputProps={{ readOnly: true }}
                      value={clientData ?(clientID != null ?filteredID?.accountNo : ""):""}
                      sx={{ margin: "10px 0", width: 300 }}
                      size="small"
                    />
                  </Grid>
                  <Grid item md={6} lg={6} textAlign="center">
                    <TextField
                      label={"Client ID"}
                      id="margin-normal"
                      InputProps={{ readOnly: true }}
                      value={clientData ?(clientID != null ?filteredID?.clientCode : ""):""}
                      sx={{ margin: "10px 0", width: 300 }}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Box>
           
              <Button
                sx={{
                  marginLeft: "290px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
                variant="contained"
                onClick={placeOrder}
                disabled={!!!distributorCode}
              >
                Submit
              </Button>

              { sessionStorage.getItem("Profile") === "E" || sessionStorage.getItem("Profile") === "D" ? 
              <Button
                sx={{
                  marginLeft: "290px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
                variant="contained"
                onClick={()=>addToProposal()}
                disabled={!!!clientID}
              >
                Add To Proposal
              </Button>
              :
              <Button
                sx={{
                  marginLeft: "290px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
                variant="contained"
                disabled
              >
                Send Proposal to Distributor
              </Button>}
              
            </Grid>
          </Grid>

          <Grid item md={4} lg={4} ml={4}>
            <Box sx={{
                marginLeft:'4%',
                border:'1px solid rgb(224, 223, 223)',
                borderRadius:'8px',
                textAlign:'center',
                height:'95.8%'
            }}>
            <TransactionCalc/>
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  }

  export default withParam(BondTransaction)