import { Box } from '@mui/system'
import React from 'react'

const ErrorPage = (props:any) => {
  return (
	<Box>
		<img src={"https://neofindesklocal.s3.us-east-2.amazonaws.com/400_BAD_REQUEST.svg"} alt="Error Page" width={props.width} height={props.height}/>
	</Box>
  )
}

export default ErrorPage