import React from "react";
import Slider from "react-slick";
import "../css/blogs.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const Blogs = () => {
  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 1,
    slidesPerRow: 1,
  };
  return (
    <>
      <div className="blogs-main">
        <div className="blogs-header">
          <span>Blogs & More</span>
        </div>

        <div className="slick-cont">
          <Slider {...settings}>
            <div>
              <Link to={"/blog1"}>
              <img
                src={require("../images/mostseenblog.png")}
                alt="Credit to Joshua Earle on Unsplash"
              />
              </Link>
              <p>
              <b>NeoFinDesk:</b> We help Indian families build 
                better financial futures to plan and fund their children’s education.
              </p>
            </div>
            <div>
              <Link to={"/blog2"}>
              <img
                src={require("../images/newreleaseblog_01.png")}
                alt="Credit to Alisa Anton on Unsplash"
              />
              </Link>
              <p>
              Tips for Indian parents to plan for the rising cost of higher education
              </p>
            </div>
            <div>
              <Link to={"/blog3"}>
              <img
                src={require("../images/newreleaseblog_02.png")}
                alt="Credit to Igor Ovsyannykov on Unsplash"
              />
              </Link>
              <p>
              Importance of smart and informed financial planning for child education
              </p>
            </div>
            <div>
              <Link to="/blog4">
              <img
                src={require("../images/newreleaseblogs.png")}
                alt="Credit to Pierre Châtel-Innocenti on Unsplash"
              />
              </Link>
              <p>
              Parent's guide to calculate children's education expenses
              </p>
            </div>
            {/* <div>
              <img
                src={require("../images/insurance.jpeg")}
                alt="Credit to Richard Nolan on Unsplash"
              />
            </div>
            <div>
              <img
                src={require("../images/insurance.jpeg")}
                alt="Credit to Cristina Gottardi on Unsplash"
              />
            </div>
            <div>
              <img
                src={require("../images/insurance.jpeg")}
                alt="Credit to Cristina Gottardi on Unsplash"
              />
            </div>
            <div>
              <img
                src={require("../images/insurance.jpeg")}
                alt="Credit to Cristina Gottardi on Unsplash"
              />
            </div>
            <div>
              <img
                src={require("../images/insurance.jpeg")}
                alt="Credit to Cristina Gottardi on Unsplash"
              />
            </div>
            <div>
              <img
                src={require("../images/insurance.jpeg")}
                alt="Credit to Cristina Gottardi on Unsplash"
              />
            </div>
            <div>
              <img
                src={require("../images/insurance.jpeg")}
                alt="Credit to Cristina Gottardi on Unsplash"
              />
            </div>
            <div>
              <img
                src={require("../images/insurance.jpeg")}
                alt="Credit to Cristina Gottardi on Unsplash"
              />
            </div> */}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Blogs;
