import { Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from 'react-toastify';
import axios from 'axios';
import dayjs from "dayjs";
import { POST_BUSINESS_UPLOAD } from '../utils/API_Names';

const BusinessUpload = () => {
    const [file, setfile] = React.useState<any>(null);
    const [businessUplaodData,setBusinessUplaodData]= React.useState<any>("")
    const [showCircularProgress, setShowCircularProgress] = React.useState(false);
  

    const uploadPost = () => {
        setShowCircularProgress(true);
        const formData = new FormData()
        formData.append("file", file);
        axios.post(POST_BUSINESS_UPLOAD, formData)
          .then(response => {
            console.log(response.data);
            setBusinessUplaodData(response.data)
            toast.success("File Upload Successfully!");
            setShowCircularProgress(false);
          })
          .then(() => {
            // window.location.reload();
          })
          .catch(error => {
            console.error(error);
            toast.error("Something Went Wrong!");
            setShowCircularProgress(false);
          });
      };
       console.log("myddaaddada",businessUplaodData)
  return (
<div>
      <Grid>
      <Typography variant="h6" gutterBottom>{" "} Business upload for AIF & PMS {" "}</Typography>
      <Grid container item columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
			<Button
              component="label"
              size="small"
              variant="contained"
              color="warning"
              startIcon={<CloudUploadIcon />}
              sx={{ mb: 1 }}
            >
              Choose file
              <input
                hidden
                type="file"
                onChange={(event: any) =>
                  setfile(event.target.files[0])
                }
              />
            </Button>
              <Typography sx={{ fontSize: 12 }}>{file?.name}</Typography>
		  </Grid>
      { file ? 
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              variant="contained"
              size="small"
              onClick={uploadPost}
              disabled={showCircularProgress}
              sx={{ mt: { xs: 1 } }}
            >
              {showCircularProgress === true ? (
                <CircularProgress color="secondary" size={20} thickness={5} />
              ) : (
                ""
              )}{" "}
              Upload File{" "}
            </Button>
		  </Grid>
      :
      <Grid item xs={12} sm={12} md={12} lg={12}>
      <Button
      variant="contained"
      size="small"
      disabled
      sx={{ mt: { xs: 1 } }}
    >
      {showCircularProgress === true ? (
        <CircularProgress color="secondary" size={20} thickness={5} />
      ) : (
        ""
      )}{" "}
      Upload File{" "}
    </Button>
    </Grid>
}
</Grid>
           {businessUplaodData &&(
            <Grid item xs={12} sm={12} md={12} lg={12} mt={5}>
            <Typography variant="h6"> Report Files </Typography>

           < Grid>
          <Typography style={{fontSize:"24px",marginLeft:"10px",textAlign:"left",fontWeight:"500"}}>Success Count : {businessUplaodData.successCount}</Typography>
          <Typography style={{fontSize:"24px",marginLeft:"10px",textAlign:"left",fontWeight:"500"}}>Failed Count : {businessUplaodData.failedCount}</Typography>
          </Grid>

                <Grid>
                <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{background: "rgb(163, 229, 229)"}}>
          <TableRow>
            <TableCell align='center'>Date</TableCell>
            <TableCell align="center">Month</TableCell>
            <TableCell align="center">Client Name</TableCell>
            <TableCell align="center">Scheme Name</TableCell>
            <TableCell align="center">Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {businessUplaodData.businessFileErrorRes?.map((row:any) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align='center' component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="center">{row.month}</TableCell>
              <TableCell align="center">{row.clientName}</TableCell>
              <TableCell align="center">{row.schemeName}</TableCell>
              <TableCell align="center">{row.reason}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
               </TableContainer>
                </Grid>
                </Grid>
           )}
		  

                </Grid>
      
</div>
  )
}

export default BusinessUpload