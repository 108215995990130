import { Container, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const Investkul = () => {
  return (
    <>
      <Container>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={8}>
            <div className='InvestKul-Blogs-Main-Page'>
              <div>
                <p className='InvestKul-Blogs-Main-Page-Details'>We help Indian families build better financial futures to plan and fund their children’s education.</p>
                <Link className='InvestKul-Blogs-Main-Page-Url' to={"/products"}>Continue Reading</Link>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} marginTop={"15px"}>
            <div className='blogbg'>
              <p className='blogs'><b>BLOGS</b></p>
              <div className='InvestKul-Blogs-suggestion' id="InvestKul-blogs-Suggest">
                <div className='InvestKul-Blogs-inside-div'>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <img className="InvestKul-Blogs-img-fluid" src="https://stage.invest4edu.com/newreleaseblog_01.png" alt="Post-Image" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={8} textAlign={"left"}>
                    <p className='margin-bottom-p0'>Tips for Indian parents to plan for the rising cost of higher education</p>
                    <Link className='InvestKul-Blogs-Url' to={"/products"}>Continue Reading</Link>
                  </Grid>
                  </Grid>
                </div>
                <div className='InvestKul-Blogs-inside-div'>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <img className="InvestKul-Blogs-img-fluid" src="https://stage.invest4edu.com/newreleaseblog_02.png" alt="Post-Image" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={8} textAlign={"left"}>
                    <p className='margin-bottom-p0'>Importance of smart and informed financial planning for child education</p>
                    <Link className='InvestKul-Blogs-Url' to={"/products"}>Continue Reading</Link>
                  </Grid>
                  </Grid>
                </div>
                <div className='InvestKul-Blogs-inside-div'>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <img className="InvestKul-Blogs-img-fluid" src="https://stage.invest4edu.com/newreleaseblogs.png" alt="Post-Image" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={8} textAlign={"left"}>
                    <p className='margin-bottom-p0'>Parent's guide to calculate children's education expenses</p>
                    <Link className='InvestKul-Blogs-Url' to={"/products"}>Continue Reading</Link>
                  </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Investkul