import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { withParam } from '../../utils/Router.Helper';
import { useQuery } from 'react-query';
import AddScheme from './AddScheme';
import { GET_SCHEME_DATA_FROM_AMC_ID, UPDATE_LOAN_SCHEME_API } from '../../utils/API_Names';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ManageAmcTableSwitch } from './ManageAmcTableSwitch';
import { ManageSchemeTableSwitch } from './ManageSchemeTableSwitch';
import AmcLoanSwitch from './AmcLoanSwitch';

interface PropsType {
  params: {
    amcId: String;
    productMasterCode:string
  };
}

const AmcLoan = (props: PropsType) => {
  const { amcId } = props.params;
  const [dataloanData, setLoanAmc] = useState<any>([]);
  const [amcName, setAMCName] = useState('');
  const [unlistedModel, setUnlistedModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [productMasterCode, setproductMasterCode] = useState<any>(null);
  const [refresh, setRefresh] = React.useState(true);





  useEffect(() => {
    axios
      .get(`${GET_SCHEME_DATA_FROM_AMC_ID}${amcId}`)
      .then((res: any) => {
        setLoanAmc(res.data.amcDetails);
        setAMCName(res.data.amcDetails[0].amcName);
        setproductMasterCode(res.data.amcDetails[0].listOfFunds[0].productMasterCode)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [refresh,amcId]);

  
  const {
    register,
    handleSubmit,
    formState: { errors } } = useForm({ mode: "onChange" });
console.log("jasghdhd",productMasterCode)
  return (
    <div>
      <Grid>
        <AddScheme amcId={amcId} amcName={amcName} productCode={'Loan'} />
      </Grid>
      <Grid mt={5}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">Loan Type </TableCell>
                <TableCell align="center">Product</TableCell>
                <TableCell align="center">Loan Segment</TableCell>
                <TableCell align="center">Tenure</TableCell>
                {/* <TableCell align="center">Actions</TableCell> */}
                {sessionStorage.getItem("Profile") === "B" && (
      <>
        <TableCell align="center">First Year</TableCell>
        <TableCell align="center">Second Year</TableCell>
        <TableCell align="center">Third Year</TableCell>
      </>
    )}
     <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataloanData[0]?.listOfFunds?.map((row: any, index: number) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center">
                    <img style={{ width: '30px' }} src={row.loanLogo} alt="loanLogo" />
                  </TableCell>
                  <TableCell align="center">{row.loanType}</TableCell>
                  {/* <TableCell align="center">{row.productName}</TableCell> */}
                  <TableCell align="center" sx={{textDecoration: "underline"}}>
                    <Link to={"/AmcLoanScheme/"+ row.productMasterCode}> {row.productName}</Link>
                   </TableCell>
                  <TableCell align="center">{row.segment}</TableCell>
                  <TableCell align="center">{row.maxTenure}</TableCell>
           
                  {sessionStorage.getItem("Profile") === "B" && (
      <>
        <TableCell align="center">{row.firstYearReturn}</TableCell>
        <TableCell align="center">{row.secondYearReturn}</TableCell>
        <TableCell align="center">{row.thirdYearReturn}</TableCell>
      </>
    )}  
           
                <TableCell align="left" sx={{display:"flex", p:"17px"}}>
                          <AmcLoanSwitch 
                        amcId={row.amcId} 
                        productType={row.productCode}
                        productMasterCode ={row.productMasterCode} 
                        isActive={row.isActive} 
                        setRefresh={setRefresh} 
                        refresh={refresh}
                      />
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
};

export default withParam(AmcLoan);
