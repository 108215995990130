import {
  Container,
  Box,
  Typography,
  IconButton,
  Snackbar,
  Button,
  CircularProgress,
  Modal,
  Fade,
  Backdrop,
  Grid,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { withParam } from "../../utils/Router.Helper";
import { fetchMutualFundData } from "../../api/Api";
import { useQuery } from "react-query";
import AddProduct from "./AIF/AddAIFProduct";
import FilterMF from "../Filters/FilterMF";
import { GET_ALL_MUTUAL_FUND } from "../../utils/API_Names";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CompareMFFund from "./CompareMFFund";
import { toast } from "react-toastify";

const MutualFund = () => {
  const dispatch = useDispatch();
  const CompareMFFundData = useSelector((state:any)=> state.ChangeCompareMfFund)
  const [ MFList, setMFList ] = React.useState<any>([])
  const [ filterDataURL, setFilterDataURL ] = React.useState([])
  const [ Return, setReturn ] = React.useState([])
  const [ AUM, setAUM ] = React.useState([])
  const [ isLoading, setIsLoading ] = React.useState(false)

   React.useEffect(()=>{
    axios.get(`${GET_ALL_MUTUAL_FUND}?${filterDataURL.join("&")}${Return}`).then((res:any)=>{ console.log("Res",res);setIsLoading(false);setMFList(res)}).catch((err:any)=>{
      setIsLoading(false);
      toast.error("Something went Wrong!")
    })
   },[filterDataURL,Return,AUM])

   const xs=3;
   const sm=3;
   const md=1;
   const lg=1;

  const changeHandleCompareMF = (event:any,aifData:any) => {
    const checked = event.target.checked
    if(checked){
      dispatch({ type: 'AddCompareMfFund',payload: aifData })
    }else{
      dispatch({ type: 'RemoveCompareMfFund',payload: aifData })
    }
  }
  return (
    <>
    {
      (
        <Container maxWidth={"xl"}>
          <Box sx={{ minHeight: "calc(100vh - 50px)", marginTop: "1%" }}>

            {/* <AddAIFProduct /> */}

            <Grid container spacing={2}>
              <Grid item md={3}>
                <FilterMF
                 setFilterDataURL={setFilterDataURL} 
                 setReturn={setReturn}
                 setAUM={setAUM}
                 />
                 <CompareMFFund/>
              </Grid>
              <Grid item md={9} >
                {MFList && MFList?.data?.mutualFundResponse.map((row: any) => (
                  <Paper elevation={8} sx={{mb:3, p:1, }} >
                    <Grid container spacing={2} textAlign={"left"}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        textDecoration: "none",
                      }}
                    >
                      <Grid item xs>
                        <Box>
                          <img src={row?.amcInfoDTO?.amcLogo} width={100} alt="MF logo"/>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Link to={"/MfScreener/" + row.schemeCode}>
                          <Typography fontWeight={"500"} variant="h5">
                            {row.schemeName}
                          </Typography>
                        </Link>
                        <Typography>
                          <Chip label={!!row?.schemeBenchmarkName ? row?.schemeBenchmarkName : "-"}  sx={{mr:1}}/>
                          <Chip label={!!row?.fundPerformance?.aum ? row?.fundPerformance?.aum +"  Cr" : "-"} />
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography align="right">{row?.schemePlanType}</Typography>
                        <Typography align="right" sx={{ display:"flex",justifyContent: "end"}}>
                            <FormGroup >
                              <FormControlLabel control={
                              <Checkbox 
                              checked={CompareMFFundData.includes(row)}
                              onClick={(event:any)=>changeHandleCompareMF(event,row)}
                              disabled={ CompareMFFundData.includes(row) ? false : CompareMFFundData?.length===4}
                              />
                              } 
                              label="Compare"  />
                            </FormGroup>
                          </Typography>
                      </Grid>
                    </Grid>
                    <Grid container columnGap={"6%"}
                      sx={{ m: 1 }}>
                      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                        <Typography>1M</Typography>
                        <Typography>{row?.fundPerformance?.schemeRet1m || "-" }</Typography>
                      </Grid>
                      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                        <Typography>3M</Typography>
                        <Typography>{row?.fundPerformance?.schemeRet3m || "-" }</Typography>
                      </Grid>
                      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                        <Typography>6M</Typography>
                        <Typography>{row?.fundPerformance?.schemeRet6m || "-" }</Typography>
                      </Grid>
                      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                        <Typography>1Y</Typography>
                        <Typography>{row?.fundPerformance?.schemeRet1y || "-" }</Typography>
                      </Grid>
                      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                        <Typography>3Y</Typography>
                        <Typography>{row?.fundPerformance?.schemeRet3y || "-" }</Typography>
                      </Grid>
                      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                        <Typography>5Y</Typography>
                        <Typography>{row?.fundPerformance?.schemeRet5y || "-"}</Typography>
                      </Grid>
                      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                        <Typography>SL</Typography>
                        <Typography>{row?.fundPerformance?.schemeRetInce || "-" }</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    
  </>
  );
};

export default withParam(MutualFund);
