import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { withParam } from "../utils/Router.Helper";
import Calculator from "./Calculator";
import "../css/bond-details.css";
import { bondData } from "../Constants/bondData";
import ArticleIcon from "@mui/icons-material/Article";
import BarChart from "./BarChartMaturity";
import CashFlowTab from "./CashFlowTab";
import { toast } from "react-toastify";
import { convertDateDMY } from "../utils/Helper";
import { bondEnum } from "../Constants/bondEnum";
import { useQuery } from "react-query";
import FixedIncomeOrderCalc from "./FixedIncomeOrderCalc";
import {
  GetAllClient,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  getFixedIncomeOrderList,
  listOfManufactureNDistributorCompanies,
} from "../api/Api";
import { useNavigate } from "react-router";

const FixedIncomeOrde = (props: any) => {
  const [Bdata, setBdata] = useState<any>([]);
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [FixedData, setfixedIncome] = useState<any>();
  const [fixedDataselect, setFixedselectData] = useState<any>();
  const [clientData, setClientData] = useState<any>([]);
  const [clientID, setClientID] = useState<any | null>();
  const [filteredID, setFilteredID] = useState<any | null>();
  const [BondDetails, setBondDetails] = useState<any | null>();
  const [companyMasterCode, setCompanyMasterCode] = useState<any | null>();
  const [distributorCode, setDistributorCode] = useState<any | null>();
  const [productMasterCode, setProductMasterCode] = useState<any>();
  const [productTypeCode, setProductTypeCode] = useState<any>();
  const [companyTypeCode, setCompanyTypeCode] = React.useState();

  useEffect(() => {
    getFixedIncomeOrderList()
      .then((resp) => setfixedIncome(resp.data.searchfilterList))
      .then(() => {});
  }, []);

  const getApiData = async () => {
    try {
      const response = await axios.get(
        `bondwork/listOfBondDetails/${fixedDataselect}`
      );
      setBdata(response.data.bondWork[0]);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    if (fixedDataselect) {
      getApiData();
    }
  }, [fixedDataselect]);

  const { data: ListOfCompany } = useQuery("ListOfManuAndDistList", () =>
    listOfManufactureNDistributorCompanies("D,E")
  );
  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", companyMasterCode, companyTypeCode],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        companyTypeCode === "S" ? "E" : companyTypeCode,
        companyMasterCode
      ),
    {
      enabled:
        (!!companyMasterCode && sessionStorage.getItem("Profile") === "B") ||
        (!!companyMasterCode && sessionStorage.getItem("Profile") === "E"),
      staleTime: Infinity,
    }
  );

  const { data } = useQuery(
    ["getAllClients", companyMasterCode, distributorCode],
    () => GetAllClient(companyMasterCode, distributorCode),
    {
      onSuccess: (data: any) => {
        setClientData(data.data.clients);
      },
      staleTime: Infinity,
    }
  );
  //Filter Client Id
  useEffect(() => {
    clientData
      .filter((item: any) => item.clientId === clientID)
      .map((it: any) => setFilteredID(it));
  }, [clientData, clientID]);

  useEffect(() => {
    if (Bdata.isinNumber) {
      axios
        .get("quoteDetails/getQuoteDataDetails/" + Bdata.isinNumber)
        .then((res) => {
          setProductMasterCode(res.data.quoteDetails[0].productMasterCode);
          setProductTypeCode(res.data.quoteDetails[0].productTypeCode);
        })
        .catch((error) => {
          // Handle error if the request fails
          console.error("Error fetching data:", error);
        });
    }
  }, [Bdata]);
  useEffect(() => {
    if (Bdata.isinNumber) {
      axios
        .get("bondwork/listOfBondDetails/" + Bdata.isinNumber)
        .then((res) => setBondDetails(res.data.bondWork[0]))
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [Bdata.isinNumber]);
  return (
    <>
      {FixedData ? (
        <Autocomplete
          sx={{ width: 500 }}
          disablePortal
          id="combo-box-demo"
          options={FixedData?.map((option: any) => ({
            label: option.issuerName,
            value: option.isinNumber,
          }))}
          getOptionLabel={(option: any) => option.label}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.label}
            </Box>
          )}
          onChange={(event, newValue) => {
            setFixedselectData(newValue ? newValue.value : "");
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Scheme" size="small" />
          )}
        />
      ) : (
        <Typography variant="subtitle1" textAlign={"center"} sx={{ mt: 2 }}>
          <CircularProgress size={15} sx={{ mr: 1 }} />
          Please Wait We're Fetching PMS Schemes For You...{" "}
        </Typography>
      )}

      {productMasterCode && (
        <Grid
          container
          sx={{ marginTop: "1%" }}
          columnSpacing={2}
          rowSpacing={2}
        >
          <Grid
            container
            spacing={2}
            style={{
              paddingLeft: "33px",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            {sessionStorage.getItem("Profile") === "B" ||
            sessionStorage.getItem("Profile") === "E" ? (
              <Grid item xs={12} sm={12} md={4} lg={3} textAlign="center">
                <Autocomplete
                  size="small"
                  options={ListOfCompany ? ListOfCompany.data.companies : []}
                  getOptionLabel={(option: any) =>
                    option.companyName + " - " + option.companyTypeCode
                  }
                  sx={{ mb: 1 }}
                  onChange={(event: any, value: any) => {
                    setCompanyMasterCode(
                      !!value ? value.companyMasterCode : ""
                    );
                    setCompanyTypeCode(value ? value.companyTypeCode : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Company"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            ) : (
              <></>
            )}
            {sessionStorage.getItem("Profile") === "B" ||
            sessionStorage.getItem("Profile") === "E" ? (
              companyMasterCode &&
              companyMasterCode && (
                <Grid item xs={12} sm={12} md={4} lg={3} textAlign="center">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    options={
                      UserProfilesByCompanyMasterCode
                        ? UserProfilesByCompanyMasterCode.data.userProfile.map(
                            (options: any) => options
                          )
                        : []
                    }
                    getOptionLabel={(options: any) =>
                      options.userCode +
                      "- " +
                      options?.firstName +
                      " " +
                      options?.lastName
                    }
                    onChange={(options: any, newValue) => {
                      setDistributorCode(newValue ? newValue.employeeCode : "");
                      // sessionStorage.setItem("CompanyData",newValue?.employeeCode)
                    }}
                    renderInput={(params: any) => (
                      <TextField {...params} label="Select User" />
                    )}
                  />
                </Grid>
              )
            ) : (
              <></>
            )}

            {distributorCode && distributorCode && (
              <Grid item xs={12} sm={12} md={4} lg={3} textAlign="center">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={clientData}
                  getOptionLabel={(options: any) =>
                    options.fullName + " - " + options.panCardNo
                  }
                  sx={{
                    width: 300,
                    paddingLeft: "5%",
                    paddingTop: "1%",
                    margin: "12px 0",
                  }}
                  onChange={(event: any, options: any, newValue) => {
                    newValue === "clear"
                      ? setClientID(null)
                      : setClientID(options.clientId);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Client"
                      margin="normal"
                      size="small"
                    />
                  )}
                />
                <Divider variant="middle" />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      {fixedDataselect && (
        <Box sx={{ padding: "0 24px", marginTop: "2%" }}>
          <Box className="bond_heading">
            <ArticleIcon />
            <Typography sx={{ marginLeft: "1rem" }} variant="h5">
              {Bdata.issuerName}
            </Typography>
          </Box>
          <Grid container spacing={0.5}>
            <Grid item md={12}>
              <Box className="essensial_container">
                <div className="essensial_heading">
                  <Typography variant="h5">Essentials</Typography>
                </div>
                <div>
                  <Grid container sx={{ color: "#786c6c" }}>
                    <Grid item md={2.4} sx={{ textAlign: "center" }}>
                      <Typography variant="caption">ISIN</Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ marginBottom: "10%" }}
                      >
                        <b>{Bdata.isinNumber}</b>
                      </Typography>

                      <Typography variant="caption">Put Option</Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ marginBottom: "10%" }}
                      >
                        <b>
                          {!!Bdata.putDate
                            ? convertDateDMY(Bdata.putDate.slice(0, 10))
                            : "--"}
                        </b>
                      </Typography>
                    </Grid>
                    <Grid item md={2.4} sx={{ textAlign: "center" }}>
                      <Typography variant="caption">Coupon Rate</Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ marginBottom: "10%" }}
                      >
                        <b>{Bdata.coupon}%</b>
                      </Typography>

                      <Typography variant="caption">
                        Interest payment Frequency
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        sx={{ marginBottom: "10%" }}
                      >
                        <b>
                          {
                            bondEnum.CouponFrequencyEnum.find(
                              (entry) => entry.value === Bdata.couponFrequency
                            )?.label
                          }
                        </b>
                      </Typography>
                    </Grid>
                    <Grid item md={2.4} sx={{ textAlign: "center" }}>
                      <Typography variant="caption">Maturity Date</Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ marginBottom: "10%" }}
                      >
                        <b>
                          {Bdata.maturityDate &&
                            convertDateDMY(Bdata.maturityDate.slice(0, 10))}
                        </b>
                      </Typography>

                      <Typography variant="caption">
                        Interest Payment Dates
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ marginBottom: "10%" }}
                      >
                        <b>
                          {Bdata.interestPaymentDates &&
                            Bdata.interestPaymentDates}
                        </b>
                      </Typography>
                    </Grid>
                    <Grid item md={2.4} sx={{ textAlign: "center" }}>
                      <Typography variant="caption">Face Value</Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ marginBottom: "10%" }}
                      >
                        <b>
                          {Bdata.faceValue &&
                            Bdata.faceValue.toLocaleString("en-IN")}
                        </b>
                      </Typography>

                      <Typography variant="caption">Rating</Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ marginBottom: "10%" }}
                      >
                        <b>{Bdata.rating1}</b>
                      </Typography>
                    </Grid>
                    <Grid item md={2.4} sx={{ textAlign: "center" }}>
                      <Typography variant="caption">Call Option</Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ marginBottom: "10%" }}
                      >
                        <b>
                          {!!Bdata.callDate
                            ? convertDateDMY(Bdata.callDate.slice(0, 10))
                            : "--"}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Grid>

            <Grid item md={12}>
              <div className="calc_container">
                <Box>
                  <FixedIncomeOrderCalc
                    item={Bdata}
                    product={{
                      Bdata,
                      productMasterCode,
                      productTypeCode,
                      clientID,
                      BondDetails,
                      filteredID,
                      companyMasterCode,
                      distributorCode,
                    }}
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default withParam(FixedIncomeOrde);
