import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from 'react'
import DeleteIcon from "@mui/icons-material/Delete";

const UnlistedProposal = (props:any) => {
  const { UnlistedDetails } = props;
  const { deleteProposal } = props;
  return (
	<>
  <Paper>
    <Card sx={{mb:2}}>
      <Card sx={{ p: 1,backgroundColor:"#f3f3f3" }}>
          <Grid container sx={{ mb: 0,backgroundColor:"#f3f3f3" }}>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <img
                src={UnlistedDetails?.productLogo}
                alt="Unlisted logo"
                width={100}
                height={50}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography variant="h6" gutterBottom>
                {UnlistedDetails?.productName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={4}>
              <Typography variant="h6" gutterBottom>
              <Chip
                  label={`Price : ${UnlistedDetails?.clientPrice}`}
                  color="success"
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1}>
              <Tooltip title="Delete">
                <IconButton>
                  <DeleteIcon onClick={()=>deleteProposal(UnlistedDetails.cartId)}/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Card>
    </Card>
  </Paper>
  </>
  )
}

export default UnlistedProposal