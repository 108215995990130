import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Stack,
  FormControlLabel,
  Switch,
  Autocomplete,
  Paper,
  Fab,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { withParam } from "../utils/Router.Helper";
import { GetBrokerMasterById } from "../api/Api";
import profileImg from "./images/profile-icon.png";
import { AccountTypeEnum } from "../Constants/AccountTypeEnum";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { mb } from "../Constants/Color";
import { Bounce, toast } from "react-toastify";

const BrokerDetails = (props: any) => {
  // console.log("Props",props.params.brokerMasterCode);
  const [edit, setEdit] = useState(true);
  const [active, setActive] = useState(true);
  const [isChecked, setIsChecked] = useState();
  const [brokerLogofile, setBrokerLogoFile] = useState<any>();
  const [registerOfCertificatefile, setregisterOfCertificateFile] =
    useState<any>();
  const [pancardDocfile, setpancardDocFile] = useState<any>();
  const [gstNumberCertificatefile, setgstNumberCertificateFile] =
    useState<any>();
  const [cancelCheque, setCancelCheque] = useState<any>();

  const [bankDetails, setBankDetails] = useState([
    {
      bankName: "",
      accountNO: "",
      accountHolderName: "",
      branchName: "",
      ifscCode: "",
      micrCode: "",
      accountType: "",
      bankAddress: "",
      city: "",
      state: "",
      country: "",
      pinCode: "",
      dpId: "",
      dpName: "",
      clientCode: "",
      isDefault: true,
    },
  ]);

  const handleLogoChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const { data, refetch } = useQuery(
    "getBrokerMasterById",
    () => GetBrokerMasterById(props.params.brokerMasterCode),
    {
      onSuccess: (data) => {
        setIsChecked(data.data.brokers[0].viewLogo);
        setBankDetails(data.data.brokers[0].bankDetailsDTOS);
      },
      staleTime: Infinity,
    }
  );
  // console.log("Data", data);

  const resolver: any = async (values: any) => {
    return {
      values: !values.brokerName ? {} : values,
      errors: !values.brokerName
        ? {
            brokerName: {
              type: "required",
              message: "Enter Broker Name.",
            },
          }
        : !values.country
        ? {
            country: {
              type: "required",
              message: "Enter Country.",
            },
          }
        : !values.state
        ? {
            state: {
              type: "required",
              message: "Enter State",
            },
          }
        : !values.city
        ? {
            city: {
              type: "required",
              message: "Enter City",
            },
          }
        : !values.addressLine1
        ? {
            addressLine1: {
              type: "required",
              message: "Enter Address Line 1",
            },
          }
        : !values.addressLine2
        ? {
            addressLine2: {
              type: "required",
              message: "Enter Address Line 2",
            },
          }
        : !values.emailId
        ? {
            emailId: {
              type: "required",
              message: "Enter Email Id",
            },
          }
        : !values.addressLine3
        ? {
            addressLine3: {
              type: "required",
              message: "Enter Address Line 3",
            },
          }
        : !values.pincode
        ? {
            pincode: {
              type: "required",
              message: "Enter Pin Code",
            },
          }
        : !values.cinNumber
        ? {
            cinNumber: {
              type: "required",
              message: "Enter CIN Number",
            },
          }
        : !values.gstNumber
        ? {
            gstNumber: {
              type: "required",
              message: "Enter GST Number",
            },
          }
        : !values.pancard
        ? {
            pancard: {
              type: "required",
              message: "Enter Pan Card",
            },
          }
        : {},
    };
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: resolver,
  });

  const onSubmit = (Formdata: any) => {
    console.log("formData", Formdata);
    const formData = new FormData();
    formData.append("brokerLogo", brokerLogofile);
    formData.append("registerOfCertificate", registerOfCertificatefile);
    formData.append("pancardDoc", pancardDocfile);
    formData.append("gstNumberCertificate", gstNumberCertificatefile);
    formData.append("cancelCheque", cancelCheque);
    formData.append(
      "brokerDto",
      JSON.stringify({
        ...Formdata,
        bankDetailsDTOS: bankDetails,
        brokerMasterCode: data && data.data.brokers[0].brokerMasterCode,
        viewLogo: isChecked,
      })
    );
    // Check if at least one bank in bankDetailsDTOS has isDefault set to true
    const isDefaultSelected = bankDetails.some((bank) => bank.isDefault);
    if (!isDefaultSelected) {
      // If none of the banks is selected as default, throw an error or handle accordingly
      toast.error("Please select one of the above banks as Default!", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Bounce,
      });
      return;
    }
    console.log("Data", {
      ...Formdata,
      bankDetailsDTOS: bankDetails,
      brokerMasterCode: data && data.data.brokers[0].brokerMasterCode,
    });
    axios
      .put("Brokers/updateBroker", formData)
      .then((res: any) => {
        toast.success("Broker Updated Successfully!", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Bounce,
        });
        refetch();
      })
      .then(() => setEdit(true));
  };

  const handleInputChange = (index: any, field: any, value: any) => {
    const updatedDetails: any = [...bankDetails];
    updatedDetails[index][field] = value;
    setBankDetails(updatedDetails);
  };

  const handleRemoveRow = (index: any) => {
    const updatedDetails = bankDetails.filter((_, i) => i !== index);
    setBankDetails(updatedDetails);
  };
  const handleAddRow = () => {
    setBankDetails([
      ...bankDetails,
      {
        bankName: "",
        accountNO: "",
        accountHolderName: "",
        branchName: "",
        ifscCode: "",
        micrCode: "",
        accountType: "",
        bankAddress: "",
        city: "",
        state: "",
        country: "",
        pinCode: "",
        dpId: "",
        dpName: "",
        clientCode: "",
        isDefault: false,
      },
    ]);
  };

  const handleDefaultBankChange = (
    event: any,
    currentIndex: any,
    propertyName: any
  ) => {
    const { checked } = event.target;
    // If the current bank is being checked, update its isDefault property
    const updatedDetails = bankDetails.map((bankDetail, i) => ({
      ...bankDetail,
      [propertyName]: i === currentIndex ? checked : false,
    }));
    setBankDetails(updatedDetails);
  };

  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ marginTop: "5%" }}>
          <Grid container>
            {data && data.data.brokers.length != 0 && (
              <Grid
                item
                md={12}
                sx={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  style={{ width: "130px", height: "40px" }}
                  src={data.data.brokers[0].brokerLogo}
                  alt=""
                />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid
                    container
                    alignItems="center"
                    sx={{
                      textAlign: "center",
                      marginBottom: "5%",
                      marginTop: "5%",
                    }}
                  >
                    <Grid item md={3}>
                      <TextField
                        label="Broker Name"
                        className={edit ? "textDisable" : ""}
                        defaultValue={data.data.brokers[0].brokerName}
                        sx={{ width: "90%", marginBottom: "1rem" }}
                        error={errors.brokerName ? true : false}
                        helperText={
                          errors.brokerName === undefined
                            ? ""
                            : errors.brokerName.message + ""
                        }
                        InputProps={{ readOnly: edit }}
                        {...register("brokerName")}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <TextField
                        label="Country"
                        className={edit ? "textDisable" : ""}
                        defaultValue={data.data.brokers[0].country}
                        sx={{ width: "90%", marginBottom: "1rem" }}
                        error={errors.country ? true : false}
                        helperText={
                          errors.country == undefined
                            ? ""
                            : errors.country.message + ""
                        }
                        InputProps={{ readOnly: edit }}
                        {...register("country")}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <TextField
                        label="City"
                        className={edit ? "textDisable" : ""}
                        defaultValue={data.data.brokers[0].city}
                        sx={{ width: "90%", marginBottom: "1rem" }}
                        error={errors.city ? true : false}
                        helperText={
                          errors.city == undefined
                            ? ""
                            : errors.city.message + ""
                        }
                        InputProps={{ readOnly: edit }}
                        {...register("city")}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <TextField
                        label="Pancard"
                        className={edit ? "textDisable" : ""}
                        defaultValue={data.data.brokers[0].pancard}
                        sx={{ width: "90%", marginBottom: "1rem" }}
                        error={errors.pancard ? true : false}
                        helperText={
                          errors.pancard == undefined
                            ? ""
                            : errors.pancard.message + ""
                        }
                        InputProps={{ readOnly: edit }}
                        {...register("pancard")}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <TextField
                        label="GST Number"
                        className={edit ? "textDisable" : ""}
                        defaultValue={data.data.brokers[0].gstNumber}
                        sx={{ width: "90%", marginBottom: "1rem" }}
                        error={errors.gstNumber ? true : false}
                        helperText={
                          errors.gstNumber == undefined
                            ? ""
                            : errors.gstNumber.message + ""
                        }
                        InputProps={{ readOnly: edit }}
                        {...register("gstNumber")}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <TextField
                        label="Email"
                        type="email"
                        className={edit ? "textDisable" : ""}
                        defaultValue={data.data.brokers[0].emailId}
                        sx={{ width: "90%", marginBottom: "1rem" }}
                        error={errors.emailId ? true : false}
                        helperText={
                          errors.emailId == undefined
                            ? ""
                            : errors.emailId.message + ""
                        }
                        InputProps={{ readOnly: edit }}
                        {...register("emailId")}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <TextField
                        label="State"
                        className={edit ? "textDisable" : ""}
                        defaultValue={data.data.brokers[0].state}
                        sx={{ width: "90%", marginBottom: "1rem" }}
                        error={errors.state ? true : false}
                        helperText={
                          errors.state == undefined
                            ? ""
                            : errors.state.message + ""
                        }
                        InputProps={{ readOnly: edit }}
                        {...register("state")}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <TextField
                        label="Pin Code"
                        className={edit ? "textDisable" : ""}
                        defaultValue={data.data.brokers[0].pincode}
                        sx={{ width: "90%", marginBottom: "1rem" }}
                        error={errors.pincode ? true : false}
                        helperText={
                          errors.pincode == undefined
                            ? ""
                            : errors.pincode.message + ""
                        }
                        InputProps={{ readOnly: edit }}
                        {...register("pincode")}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <TextField
                        label="CIN Number"
                        className={edit ? "textDisable" : ""}
                        defaultValue={data.data.brokers[0].cinNumber}
                        sx={{ width: "90%", marginBottom: "1rem" }}
                        error={errors.cinNumber ? true : false}
                        helperText={
                          errors.cinNumber == undefined
                            ? ""
                            : errors.cinNumber.message + ""
                        }
                        InputProps={{ readOnly: edit }}
                        {...register("cinNumber")}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <TextField
                        label="Address Line 1"
                        className={edit ? "textDisable" : ""}
                        defaultValue={data.data.brokers[0].addressLine1}
                        sx={{ width: "90%", marginBottom: "1rem" }}
                        error={errors.addressLine1 ? true : false}
                        helperText={
                          errors.addressLine1 == undefined
                            ? ""
                            : errors.addressLine1.message + ""
                        }
                        InputProps={{ readOnly: edit }}
                        {...register("addressLine1")}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <TextField
                        label="Address Line 2"
                        className={edit ? "textDisable" : ""}
                        defaultValue={data.data.brokers[0].addressLine2}
                        sx={{ width: "90%", marginBottom: "1rem" }}
                        error={errors.addressLine2 ? true : false}
                        helperText={
                          errors.addressLine2 == undefined
                            ? ""
                            : errors.addressLine2.message + ""
                        }
                        InputProps={{ readOnly: edit }}
                        {...register("addressLine2")}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <TextField
                        label="Address Line 3"
                        className={edit ? "textDisable" : ""}
                        defaultValue={data.data.brokers[0].addressLine3}
                        sx={{ width: "90%", marginBottom: "1rem" }}
                        error={errors.addressLine3 ? true : false}
                        helperText={
                          errors.addressLine3 == undefined
                            ? ""
                            : errors.addressLine3.message + ""
                        }
                        InputProps={{ readOnly: edit }}
                        {...register("addressLine3")}
                      />
                    </Grid>

                    {/* <Typography variant='h6' mb={2}>Bank Details</Typography> */}
                    {bankDetails.map((item: any, index: any) => (
                      <Paper elevation={8} sx={{ p: 2, mb: 2 }}>
                        <Grid
                          container
                          sx={{
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" mb={2} gutterBottom>
                            Bank Details {index + 1} :
                          </Typography>
                          {!edit && (
                            <Grid
                              alignItems={"center"}
                              sx={{ mb: 2, position: "sticky" }}
                            >
                              <Fab
                                color="primary"
                                aria-label="add"
                                variant="extended"
                                sx={{ mr: 1 }}
                                onClick={() => handleRemoveRow(index)}
                              >
                                <RemoveIcon /> Remove
                              </Fab>
                            </Grid>
                          )}
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Bank Name"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.bankName}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "bankName",
                                  e.target.value
                                )
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Account No"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.accountNO}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "accountNO",
                                  e.target.value
                                )
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Autocomplete
                              id={index}
                              options={AccountTypeEnum.map((option) => option)}
                              getOptionLabel={(option) => option.Label}
                              defaultValue={AccountTypeEnum[0]}
                              onChange={(options: any, newValue) => {
                                handleInputChange(
                                  index,
                                  "accountType",
                                  newValue?.value
                                );
                                // setUserType(newValue?.value);
                              }}
                              // onChange={(e:any)=>handleInputChange(index,"accountType",e.target.value)}
                              sx={{ mb: mb }}
                              disabled={edit ? true : false}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Account Type"
                                  className={edit ? "textDisable" : ""}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Bank Branch"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.branchName}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "branchName",
                                  e.target.value
                                )
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="IFSC Code"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.ifscCode}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "ifscCode",
                                  e.target.value
                                )
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="MICR Code"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.micrCode}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "micrCode",
                                  e.target.value
                                )
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Bank Address"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.bankAddress}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "bankAddress",
                                  e.target.value
                                )
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Account Holder Name"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.accountHolderName}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "accountHolderName",
                                  e.target.value
                                )
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="City"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.city}
                              onChange={(e) =>
                                handleInputChange(index, "city", e.target.value)
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="State"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.state}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "state",
                                  e.target.value
                                )
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Pin Code"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.pinCode}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "pinCode",
                                  e.target.value
                                )
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Country"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.city}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "country",
                                  e.target.value
                                )
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="DP ID"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.dpId}
                              onChange={(e) =>
                                handleInputChange(index, "dpId", e.target.value)
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="DP Name"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.dpName}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "dpName",
                                  e.target.value
                                )
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Client ID"
                              className={edit ? "textDisable" : ""}
                              variant="outlined"
                              defaultValue={item.clientCode}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "clientCode",
                                  e.target.value
                                )
                              }
                              sx={{ mb: mb }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Checkbox
                              disabled={edit}
                              checked={bankDetails[index].isDefault}
                              onChange={(e) =>
                                handleDefaultBankChange(e, index, "isDefault")
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />{" "}
                            Default Bank
                          </Grid>
                        </Grid>
                      </Paper>
                    ))}

                    {!edit && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ textAlign: "end" }}
                      >
                        <Fab
                          color="primary"
                          aria-label="add"
                          variant="extended"
                          sx={{ mr: 1 }}
                          onClick={handleAddRow}
                        >
                          <AddIcon />
                          Add Bank
                        </Fab>
                      </Grid>
                    )}

                    <Grid item md={12} textAlign="left" sx={{ mb: 2 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={edit}
                            checked={isChecked}
                            onChange={handleLogoChange}
                          />
                        }
                        label="Show Logo"
                      />
                    </Grid>

                    {edit ? (
                      <></>
                    ) : (
                      <>
                        <Grid container sx={{ marginBottom: "10px" }}>
                          <Grid item md={12} textAlign="left" sx={{ mb: 2 }}>
                            <Button variant="contained" component="label">
                              Broker Logo
                              <input
                                hidden
                                type="file"
                                accept=".jpg"
                                name="brokerLogo"
                                onChange={(event: any) =>
                                  setBrokerLogoFile(event.target.files[0])
                                }
                              />
                            </Button>
                            <Typography
                              variant="caption"
                              sx={{ marginLeft: "2rem" }}
                            >
                              {brokerLogofile == undefined
                                ? "No file choosen"
                                : brokerLogofile.name}
                            </Typography>
                          </Grid>
                          <Grid item md={12} textAlign="left" sx={{ mb: 2 }}>
                            <Button variant="contained" component="label">
                              GST Certificate
                              <input
                                hidden
                                type="file"
                                accept=".pdf"
                                name="gstNumberCertificate"
                                onChange={(event: any) =>
                                  setgstNumberCertificateFile(
                                    event.target.files[0]
                                  )
                                }
                              />
                            </Button>
                            <Typography
                              variant="caption"
                              sx={{ marginLeft: "2rem" }}
                            >
                              {gstNumberCertificatefile == undefined
                                ? "No file choosen"
                                : gstNumberCertificatefile.name}
                            </Typography>
                          </Grid>
                          <Grid item md={12} textAlign="left" sx={{ mb: 2 }}>
                            <Box>
                              <Button variant="contained" component="label">
                                Upload PAN Card
                                <input
                                  hidden
                                  type="file"
                                  accept=".pdf"
                                  name="pancardDoc"
                                  onChange={(event: any) =>
                                    setpancardDocFile(event.target.files[0])
                                  }
                                />
                              </Button>
                              <Typography
                                variant="caption"
                                sx={{ marginLeft: "2rem" }}
                              >
                                {pancardDocfile == undefined
                                  ? "No file choosen"
                                  : pancardDocfile.name}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item md={12} textAlign="left" sx={{ mb: 2 }}>
                            <Box>
                              <Button variant="contained" component="label">
                                Register Certificate
                                <input
                                  hidden
                                  type="file"
                                  accept=".pdf"
                                  name="registerOfCertificate"
                                  onChange={(event: any) =>
                                    setregisterOfCertificateFile(
                                      event.target.files[0]
                                    )
                                  }
                                />
                              </Button>
                              <Typography
                                variant="caption"
                                sx={{ marginLeft: "2rem" }}
                              >
                                {registerOfCertificatefile == undefined
                                  ? "No file choosen"
                                  : registerOfCertificatefile.name}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item md={12} textAlign="left" sx={{ mb: 2 }}>
                            <Box>
                              <Button variant="contained" component="label">
                                Cancel Cheque
                                <input
                                  hidden
                                  type="file"
                                  accept=".pdf"
                                  name="CancelCheque"
                                  onChange={(event: any) =>
                                    setCancelCheque(event.target.files[0])
                                  }
                                />
                              </Button>
                              <Typography
                                variant="caption"
                                sx={{ marginLeft: "2rem" }}
                              >
                                {cancelCheque == undefined
                                  ? "No file choosen"
                                  : cancelCheque.name}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Stack spacing={3} direction="row">
                      {edit ? (
                        <Button
                          variant="contained"
                          onClick={() => setEdit(!edit)}
                        >
                          Edit
                        </Button>
                      ) : (
                        <>
                          <Button variant="contained" type="submit">
                            Submit
                          </Button>
                          <Button
                            variant="contained"
                            type="submit"
                            onClick={() => setEdit(true)}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      {data.data.brokers[0].isActive ? (
                        <Button
                          variant="contained"
                          onClick={() =>
                            axios
                              .put(
                                "Brokers/deleteBroker/" +
                                  data.data.brokers[0].brokerMasterCode
                              )
                              .then(() => refetch())
                          }
                        >
                          Inactive
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() =>
                            axios
                              .put(
                                "Brokers/deleteBroker/" +
                                  data.data.brokers[0].brokerMasterCode
                              )
                              .then(() => refetch())
                          }
                        >
                          Active
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                </form>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default withParam(BrokerDetails);
