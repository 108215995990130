import { List, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import { Box } from '@mui/system'
import React, { useMemo, useState } from 'react'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const passList = [
  {
    primary : "3 characters minimum"
  },
  {
    primary : "must contain one number"
  },
  {
    primary : "one uppercase"
  },
]

const Test2 = () => {
    const [startDate, setStartDate] = useState(new Date());

    const [password, setPassword] = useState("");

    function validatePassword() {
      return [
        password.length >= 3,
        /\d/.test(password),
        /[A-Z]/.test(password)
      ];
    }
    
    const validationResults = useMemo(() => validatePassword(), [password]);

  return (
    <Box mt={10}>
        <h1>Test2</h1>
        <DatePicker selected={startDate} onChange={(date:any) => setStartDate(date)} />

        <List dense sx={{marginLeft:'12%'}}>
  {passList.map((it:any, index:number) => (
    <ListItem style={{backgroundColor: validationResults[index] ? "green" : "red"}}>
      <ListItemIcon>
        <KeyboardArrowRightIcon />
      </ListItemIcon>
      <ListItemText
        primary={it.primary}
      />
    </ListItem>
  ))}
</List>

<TextField
  label="Password"
  type="password"
  value={password}
  onChange={(event) => setPassword(event.target.value)}
/>


    </Box>
  )
}

export default Test2