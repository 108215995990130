import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import NetassetMF from "../../images/InvestmentProductIMG/NetassetMF.png"
import NetassetSIPMF from "../../images/InvestmentProductIMG/NetassetSIPMF.png"
import categoryMF from "../../images/InvestmentProductIMG/categoryMF.png"
import StartWithUs from '../StartWithUs/StartWithUs';

const ProductMutualFund = () => {
  window.scrollTo(0, 0);
  return (
    <section>
      <Container>
        <Grid container spacing={2} style={{justifyContent: "space-around"}} className={sessionStorage.getItem("JWT") ? 'your-dynamic-css-class-product' : 'default-class-product'}>
 <Grid md={7}>
 <Typography style={{ textAlign: "left", fontSize: "30px", fontWeight: "bold",borderBottom: "4px solid black"}}>Mutual Fund</Typography>
 <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify",paddingTop:"10px"}}>According to data released by the Association of Mutual Funds in India (AMFI), inflows into Indian
equities Mutual Funds increased for the 29th consecutive month, although the inflows continued
to be erratic because investors preferred small-cap and mid-cap funds owing to stretched
valuations in large-cap stocks. The equity mutual funds have witnessed a net inflow at Rs 7,505
crore in July 2023 as against inflow of Rs 8,245 crore in June 2023, even though the net inflows in
July were lower than the net inflow of June, it’s substantial in absolute terms. July SIP inflows
stood at an all-time high of 15,243 crores. The AUM under mutual fund SIPs was Rs 8.32 lakh
crore in July, against Rs 7.93 lakh crore in the previous month.
The Net Assets Under Management (NAUM) of Indian Mutual Fund Industry as on July, 2023
stood at Rs. 46.37 lakh crore as compared to Rs. 44.39 lakh crore in the month of June, 2023. The
steady rise in the net AUM was driven by inflows in Debt schemes and Hybrid Schemes. The small
cap funds attracted the most investment out of all equity groups even though the inflows into
these funds decreased compared to June, when it had received Rs 5,472 crore, July had an influx
of Rs 4,171 crore.</Typography>
<Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold", textDecoration: "underline" }}>Debt Funds</Typography>
<Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}} >Meanwhile, the total debt scheme reported inflow of Rs 61,440 crore versus outflows of Rs
14,136 crore (MoM). The hybrid fund witnessed inflow at Rs 12,421 crore. Additionally, liquid
funds under debt schemes had an influx of Rs 51,938 crore in July compared to a Rs 28,545
outflow in June.</Typography>

<Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold", textDecoration: "underline" }}>ETF’s</Typography>
<Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}} >Index funds saw an influx of Rs. 1,170 crore in the month of July 2023 and Gold ETF’s saw an
inflow of Rs. 456 crore as equites became expensive following its recent surge and investors are
looking at Gold as an alternate investment</Typography>

 </Grid>
 <Grid md={4} style={{marginTop:"55px",background: "aliceblue"}}>
  <Grid container>
    <Grid md={1}>
  <ArrowRightAltIcon></ArrowRightAltIcon>
    </Grid>
    <Grid md={11}>
    <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingRight:"5px"}} >RBI continued the pause in the latest monetary policy statement on Aug 10, 2023 and also maintained the
     policy stance as “Withdrawal of Accommodation.” </Typography>
    </Grid>

</Grid>
<br />
<Grid container>
    <Grid md={1}>
  <ArrowRightAltIcon></ArrowRightAltIcon>
    </Grid>
    <Grid md={11}>
    <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingRight:"5px"}} >
    The Net Assets Under anagement (NAUM) of Indian Mutual Fund Industry as on July, 2023 stood at Rs. 46.37 lakh
crore as compared to Rs. 44.39 lakh crore in the month of June, 2023.
     </Typography>
    </Grid>

</Grid>
<br />
<Grid container>
    <Grid md={1}>
  <ArrowRightAltIcon></ArrowRightAltIcon>
    </Grid>
    <Grid md={11}>
    <Typography style={{fontSize:"18px",fontWeight:"400",textAlign:"justify",paddingRight:"5px"}} >
    The small cap funds attracted the most investment out of all equity groups even though the
    inflows into these funds decreased compared to June. July had an influx of Rs 4,171 crore.
     </Typography>
    </Grid>

</Grid>
 </Grid>

<Grid md={12} mt={5}>
  <img style={{maxWidth:"100%",height:"auto"}} src={NetassetMF} alt="" />
</Grid>
<Grid md={12} mt={5}>
<Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}> 
The largest withdrawal from large cap funds was Rs 1,880 crore. Since the past three months, this category has had outflows.
In July 2023, Systematic Investment Plan (SIP) inflows reached a record high, showing an increasing trend of investor interest in
mutual funds. The Association of Mutual Funds in India (AMFI) recently revealed data showing that SIP inflows hit a new high of Rs
15,242.7 crore during the examined month. In July 2023, there were 6.8 crore SIP accounts, up from 6.65 crore in June 2023.
</Typography>
<br />
  <img style={{maxWidth:"100%",height:"auto"}} src={NetassetSIPMF} alt="" />
</Grid>

</Grid>
<Grid mt={4}>
<Typography style={{ textAlign: "left", fontSize: "larger", fontWeight: "bold", textDecoration: "underline" }}>Equity Funds - Category Wise Performance</Typography>
<Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}} >Meanwhile, the total debt scheme reported inflow of Rs 61,440 crore versus outflows of Rs
14,136 crore (MoM). The hybrid fund witnessed inflow at Rs 12,421 crore. Additionally, liquid
funds under debt schemes had an influx of Rs 51,938 crore in July compared to a Rs 28,545
outflow in June.</Typography> 
<Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"center"}}  mt={2}>The largest withdrawal from large cap funds was Rs 1,880 crore. Since the past three months, this category has had outflows</Typography>
  <br />
 <img style={{maxWidth:"100%",height:"auto"}} src={categoryMF} alt="" />
</Grid>
{sessionStorage.getItem("JWT")?"":
<Grid mt={2} mb={2}>
<StartWithUs/>
</Grid>
}
</Container>
    </section>
  )
}

export default ProductMutualFund