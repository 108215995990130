export const AccountTypeEnum = [
	{ value:"SAVINGS_ACCOUNT",Label:"Savings Account" },
	{ value:"CURRENT_ACCOUNT",Label:"Current Account" },
	{ value:"FIXED_DEPOSIT_ACCOUNT",Label:"Fixed Deposit Account" },
	{ value:"RECURRING_DEPOSIT_ACCOUNT",Label:"Recurring Deposit Account" },
	{ value:"SENIOR_CITIZEN_SAVINGS_SCHEME",Label:"Senior Citizen Savings Scheme (SCSS)" },
	{ value:"PUBLIC_PROVIDENT_FUND_ACCOUNT",Label:"Public Provident Fund (PPF) Account" },
	{ value:"EMPLOYEE_PROVIDENT_FUND_ACCOUNT",Label:"Employee Provident Fund (EPF) Account" },
	{ value:"EMPLOYEE_PENSION_SCHEME_ACCOUNT",Label:"Employee Pension Scheme (EPS) Account" },
	{ value:"KISAN_VIKAS_PATRA",Label:"Kisan Vikas Patra (KVP)" },
	{ value:"NATIONAL_SAVINGS_CERTIFICATE",Label:"National Savings Certificate (NSC)" },
	{ value:"SUKANYA_SAMRIDDHI_YOJANA_ACCOUNT",Label:"Sukanya Samriddhi Yojana (SSY) Account" },
	{ value:"PRADHAN_MANTRI_JAN_DHAN_YOJANA_ACCOUNT",Label:"Pradhan Mantri Jan Dhan Yojana (PMJDY) Account" },
	{ value:"PRADHAN_MANTRI_KISAN_SAMMAN_NIDHI_ACCOUNT",Label:"Pradhan Mantri Kisan Samman Nidhi (PM-KISAN) Account" },
	{ value:"ATAL_PENSION_YOJANA_ACCOUNT",Label:"Atal Pension Yojana (APY) Account" },
	{ value:"JANANI_SURAKSHA_YOJANA_ACCOUNT",Label:"Janani Suraksha Yojana (JSY) Account" },
	{ value:"NPS_TIER_1_AND_TIER_2_ACCOUNTS",Label:"NPS Tier 1 and Tier 2 Accounts (National Pension System)" },
	{ value:"NON_RESIDENT_EXTERNAL_ACCOUNT",Label:"Non-Resident External (NRE) Account" },
	{ value:"NON_RESIDENT_ORDINARY_ACCOUNT",Label:"Non-Resident Ordinary (NRO) Account" },
	{ value:"FOREIGN_CURRENCY_NON_RESIDENT_ACCOUNT",Label:"Foreign Currency Non-Resident (FCNR) Account" },
	{ value:"MINOR_SAVINGS_ACCOUNT",Label:"Minor Savings Account" },
	{ value:"TRUST_OR_NGO_SAVINGS_ACCOUNT",Label:"Trust or NGO Savings Account" },
	{ value:"ESCROW_ACCOUNT",Label:"Escrow Account" },
	{ value:"NON_PROFIT_ORGANIZATION_ACCOUNT",Label:"Non-Profit Organization Account" },
	{ value:"CLUB_OR_ASSOCIATION_ACCOUNT",Label:"Club or Association Account" },
	{ value:"HUF_ACCOUNT",Label:"HUF (Hindu Undivided Family) Account" },
	{ value:"PROFESSIONAL_SAVINGS_ACCOUNT",Label:"Professional Savings Account" },
	{ value:"BASIC_SAVINGS_BANK_DEPOSIT_ACCOUNT",Label:"Basic Savings Bank Deposit Account (BSBDA)" },
	{ value:"PRADHAN_MANTRI_MUDRA_YOJANA_ACCOUNT",Label:"Pradhan Mantri Mudra Yojana (PMMY) Account" },
	{ value:"START_UP_CURRENT_ACCOUNT",Label:"Start-Up Current Account" },
	{ value:"BASIC_CURRENT_ACCOUNT",Label:"Basic Current Account" },
	{ value:"RESIDENT_FOREIGN_CURRENCY_ACCOUNT",Label:"Resident Foreign Currency Account (RFC)" },
	{ value:"DEMAT_ACCOUNT",Label:"Demat Account" },
	{ value:"CENTRAL_GOVERNMENT_ACCOUNT",Label:"Central Government Account (CGA)" },
	{ value:"URBAN_COOPERATIVE_BANK_ACCOUNT",Label:"Urban Cooperative Bank Account" },
	{ value:"SMALL_FINANCE_BANK_ACCOUNT",Label:"Small Finance Bank Account" },
	{ value:"PAYMENTS_BANK_ACCOUNT",Label:"Payments Bank Account" },
	{ value:"GREEN_ACCOUNT",Label:"Green Account (Eco-Friendly Account)" },
	{ value:"SALARY_ACCOUNT",Label:"Salary Account" },
	{ value:"OVERDRAFT_ACCOUNT",Label:"Overdraft Account" },
	{ value:"LOAN_ACCOUNT",Label:"Loan Account" },
	{ value:"",Label:"" },
]

