import React, { useEffect, useState } from 'react'
import { withParam } from '../../utils/Router.Helper';
import { GET_INSURANCE_SCHEME, GET_SCHEME_DATA_FROM_AMC_ID, UPDATE_INSURANCE_SCHEME } from '../../utils/API_Names';
import axios from 'axios';
import { Box, Button, Card, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';

interface PropsType {
    params: {
      amcId: String;
      productMasterCode:String
    };
  }


const AmcInsuranceScheme = (props:any) => {
const { amcId } = props.params;
const ProductMasterCode = props.params
const [dataInsuranceamc,setInsuranceamc] = React.useState<any>([])
const [amcName, setAMCName] = React.useState("");
const [productCode, setProductCode] = React.useState("");
const [unlistedModel, setUnlistedModel] = useState(false);
const [selectedRow, setSelectedRow] = useState<any>(null);
const [AMCId, setAmcID] = useState<any>(null);
const [insLogo, setInsuranceLogo] = useState<any>(null);

const handleUpdateClick = (row: any) => {
    setSelectedRow(row);
    updateUnlistedShareModel('open');
  };
  function updateUnlistedShareModel(status: any) {
    status === 'open'
      ? setUnlistedModel(true)
      : status === 'close'
      ? setUnlistedModel(false)
      : <></>;
  }
 
  useEffect(() => {
    // Ensure that ProductMasterCode is defined before making the request
    if (ProductMasterCode && ProductMasterCode.ProductMasterCode) {
      const productMasterCode = ProductMasterCode.ProductMasterCode;
      const productMasterCodeString = String(productMasterCode);
      axios.get(`${GET_INSURANCE_SCHEME}/${productMasterCodeString}`)
        .then((res: any) => {
          setInsuranceamc(res.data.insurance);
          setAMCName(res.data.insurance.amcName);
          setAmcID(res.data.insurance[0].amcId)
        })
        .catch((error) => {
        });
    }
  }, [ProductMasterCode]); // Include ProductMasterCode in the dependency array
  

  const onSubmit = async (submitedData: any) => {
    const formData: any = new FormData();
    formData.append("insLogo", insLogo);
    const newSubmitedData = {
      ...submitedData,
      productMasterCode: selectedRow?.productMasterCode,
    };
    updateUnlistedShareModel('close');
    formData.append("insuranceDTO", JSON.stringify(newSubmitedData));
    try {
      await axios.put(`${UPDATE_INSURANCE_SCHEME}`, formData);
      window.location.reload();
    } catch (error) {
    }
  };
  const handleLogoFileChange = (event: any) => {
    const file = event.target.files[0];
    setInsuranceLogo(file);
  };
  const {
    register,
    handleSubmit,
    formState: { errors } } = useForm({ mode: "onChange" });
  return (
    <section>
    { dataInsuranceamc?.map((row:any,index: number) => (
        <Card style={{marginTop:"20px"}} variant="outlined"  >
<Grid container spacing={2}>
   <Grid item xs={12}>
   <Typography  style={{fontSize:"30px", fontWeight:"700"}}>{row.productName}</Typography>
   </Grid>
 <Grid item xs={5}>
 <img  style={{width:"30px"}}  src={row.insLogo} alt="loanLogo" />
 </Grid>
 <Grid item xs={2}>
 <Typography  style={{fontSize:"25px", fontWeight:"500"}}>Insurance Type</Typography>
<Typography  style={{fontSize:"20px", fontWeight:"400",color:"#00d5dd"}}>{row.insuranceType}</Typography>
 </Grid>
 <Grid item xs={2}>
 <Typography  style={{fontSize:"25px", fontWeight:"500"}}>Category</Typography>
<Typography  style={{fontSize:"20px", fontWeight:"400",color:"#00d5dd"}}>{row.category}</Typography>
 </Grid>
 <Grid item xs={2}>
 <Typography  style={{fontSize:"25px", fontWeight:"500"}}>Payment TYpe</Typography>
<Typography  style={{fontSize:"20px", fontWeight:"400",color:"#00d5dd"}}>{row.paymentType}</Typography>
 </Grid>
 <Grid item xs={1}>
<Button variant="contained" onClick={() => handleUpdateClick(row)}>Update</Button>       
  </Grid>
  <Modal
       open={unlistedModel} 
       onClose={() => updateUnlistedShareModel('close')}>
        <Box sx={style}>
        <IconButton style={{ position: 'absolute',fontSize:"18px" ,top: "8px", right: "5px"}} onClick={() => updateUnlistedShareModel('close')}>
      <CloseIcon style={{fontSize:"18px"}} />
       </IconButton>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2"  mb={2}>
            Update Insurance
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} >
            <Grid xs={3}>
            <TextField
              id="outlined-basic"
              label="Loan Type"
              variant="outlined"
              defaultValue={selectedRow?.insuranceType}
              {...register('insuranceType')}
            />
            </Grid>
            <Grid xs={3}>
            <TextField
              id="outlined-basic"
              label="Product"
              variant="outlined"
              defaultValue={selectedRow?.productName}
              {...register('productName')}
            />
            </Grid>
            <Grid xs={3}>
            <TextField
              id="outlined-basic"
              label="Loan Segment"
              variant="outlined"
              defaultValue={selectedRow?.paymentType}
              {...register('paymentType')}
            />
            </Grid>
            <Grid xs={3}>
            <TextField
              id="outlined-basic"
              label="Tenure"
              variant="outlined"
              defaultValue={selectedRow?.category}
              {...register('category')}
            />
            </Grid>
            <Grid xs={3}>
  <Button variant='contained' component='label'>
    Update Logo
    <input
  hidden
  accept='.jpg, .png, .jpeg'
  type="file"
  onChange={handleLogoFileChange}
  style={{ marginBottom: "10px" }}
/>
  </Button>
  <Typography variant='caption' sx={{ marginLeft: '2rem', marginTop: 2 }}>
    {/* {loanLogo === undefined ? "( .png , .jpg , .jpeg)" : loanLogo.name} */}
  </Typography>
</Grid>
           </Grid>
           <Grid style={{textAlign:"center",marginTop:"30px"}}>
           <Button type="submit" variant="contained">
              Submit
            </Button>
           </Grid>
          
          </form>
        </Box>
      </Modal>
</Grid>
</Card>



             ))}
            
            </section>
  )
}

export default withParam(AmcInsuranceScheme)
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper', // Use your preferred background color here
    boxShadow: 24,
    p: 4,
  };