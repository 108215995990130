import React from 'react'
import {
  Container,
  Box,
} from "@mui/material";
import AddProduct from './Products/AIF/AddAIFProduct'

export const ManufractureDashbord = () => {
  return (
    <div>
      <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "10%" }}>
      Manufracture Dashbord
      <AddProduct/>
      </Box>

    </div>
  )
}

 