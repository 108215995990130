import React from 'react'
import { withParam } from '../../utils/Router.Helper'
import { useQuery } from 'react-query'
import { GetQuoteDetailsByID } from '../../api/Api'
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { convertDate } from '../../utils/Helper'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { toast } from 'react-toastify'


const QuoteDetailsById = (props:any) => {
    const QuoteID = props.params.quoteId

    const [edit, setedit] = React.useState(true);
    const { data:QuoteData } = useQuery(["QuoteByID",QuoteID],()=>GetQuoteDetailsByID(QuoteID))

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
      } = useForm({ mode: "onBlur" });

      const onSubmit = (data: any) => {
        const dto:any = {   ...data,
            isinNumber: QuoteData && QuoteData.data.quoteDetails[0].isinNumber,
            quoteDate:new Date(),
            quoteId:QuoteData && QuoteData.data.quoteDetails[0].quoteId,
        }
        axios.put("quoteDetails/updateQuoteDetails",dto).then(()=>toast.success("Quote Update Successfully")).then(()=>window.location.reload()).catch(()=>toast.error("Something went wrong!"))
        console.log(data);
      }
      
  return (
    <Box>
    { QuoteData ? 
        <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant='h5'> { QuoteData && QuoteData.data.quoteDetails[0].isinNumber } </Typography>
        <Grid container spacing={2} sx={{marginTop:2}}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="outlined-basic"
                label="Account Status"
                variant="outlined"
                defaultValue={QuoteData && QuoteData.data.quoteDetails[0].accountStatus}
                InputProps={{ readOnly: edit }}
                // error={errors.city ? true : false}
                // helperText={
                //     errors.city == undefined
                //     ? ""
                //     : errors.city.message + ""
                // }
                {...register("accountStatus")}
                />
            </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="outlined-basic"
                label="Manufacturer Price"
                variant="outlined"
                // disabled={edit}
                defaultValue={QuoteData && QuoteData.data.quoteDetails[0].manufacturerPrice}
                InputProps={{ readOnly: edit }}
                // error={errors.city ? true : false}
                // helperText={
                //     errors.city == undefined
                //     ? ""
                //     : errors.city.message + ""
                // }
                {...register("manufacturerPrice")}
                />
            </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="outlined-basic"
                label="Max Value"
                variant="outlined"
                // disabled={edit}
                defaultValue={QuoteData && QuoteData.data.quoteDetails[0].maxValue}
                InputProps={{ readOnly: edit }}
                // error={errors.city ? true : false}
                // helperText={
                //     errors.city == undefined
                //     ? ""
                //     : errors.city.message + ""
                // }
                {...register("maxValue")}
                />
            </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="outlined-basic"
                label="Min Value"
                variant="outlined"
                // disabled={edit}
                defaultValue={QuoteData && QuoteData.data.quoteDetails[0].minValue}
                InputProps={{ readOnly: edit }}
                // error={errors.city ? true : false}
                // helperText={
                //     errors.city == undefined
                //     ? ""
                //     : errors.city.message + ""
                // }
                {...register("minValue")}
                />
            </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="outlined-basic"
                label="Multiple"
                variant="outlined"
                // disabled={edit}
                defaultValue={QuoteData && QuoteData.data.quoteDetails[0].multiple}
                InputProps={{ readOnly: edit }}
                // error={errors.city ? true : false}
                // helperText={
                //     errors.city == undefined
                //     ? ""
                //     : errors.city.message + ""
                // }
                {...register("multiple")}
                />
            </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="outlined-basic"
                label="salePrice"
                variant="outlined"
                // disabled={edit}
                defaultValue={QuoteData && QuoteData.data.quoteDetails[0].salePrice}
                InputProps={{ readOnly: edit }}
                // error={errors.city ? true : false}
                // helperText={
                //     errors.city == undefined
                //     ? ""
                //     : errors.city.message + ""
                // }
                {...register("salePrice")}
                />
            </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="outlined-basic"
                label="saleYtc"
                variant="outlined"
                // disabled={edit}
                defaultValue={QuoteData && QuoteData.data.quoteDetails[0].saleYtc}
                InputProps={{ readOnly: edit }}
                // error={errors.city ? true : false}
                // helperText={
                //     errors.city == undefined
                //     ? ""
                //     : errors.city.message + ""
                // }
                {...register("saleYtc")}
                />
            </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="outlined-basic"
                label="saleYtm"
                variant="outlined"
                // disabled={edit}
                defaultValue={QuoteData && QuoteData.data.quoteDetails[0].saleYtm}
                InputProps={{ readOnly: edit }}
                // error={errors.city ? true : false}
                // helperText={
                //     errors.city == undefined
                //     ? ""
                //     : errors.city.message + ""
                // }
                {...register("saleYtm")}
                />
            </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="outlined-basic"
                label="ytc"
                variant="outlined"
                // disabled={edit}
                defaultValue={QuoteData && QuoteData.data.quoteDetails[0].ytc}
                InputProps={{ readOnly: edit }}
                // error={errors.city ? true : false}
                // helperText={
                //     errors.city == undefined
                //     ? ""
                //     : errors.city.message + ""
                // }
                {...register("ytc")}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="outlined-basic"
                label="ytm"
                variant="outlined"
                // disabled={edit}
                defaultValue={QuoteData && QuoteData.data.quoteDetails[0].ytm}
                InputProps={{ readOnly: edit }}
                // error={errors.city ? true : false}
                // helperText={
                //     errors.city == undefined
                //     ? ""
                //     : errors.city.message + ""
                // }
                {...register("ytm")}
                />
            </Grid>
            </Grid> 
            <Button variant='contained' type='submit'> Submit </Button>
            <Button variant='outlined' onClick={()=>setedit(!edit)}> Update </Button>
        </form> 
        : <></> }
    </Box>
  )
}

export default withParam(QuoteDetailsById) 