import { Button, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import BookaCall from "../../images/bookacall_revised_image.png";
import "../../css/bookacall.css";
import StartWithUs from "../StartWithUs/StartWithUs";

const BookACall = () => {
  return (
    <section id="bookacall">
      <div style={{ padding: "4em 4em 0em 4em" }}>
        <Grid container className="row">
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            className="col-lg-6  col-md-6 col-sm-6"
          >
            <img src={BookaCall} width={"100%"} height={"100%"} alt=""></img>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            className="col-lg-6  col-md-6 col-sm-6 text-center"
          >
            <div className="" style={{ padding: "4em 3.5em" }}>
              <div className="left-heading">
                <h2>Schedule a call</h2>
              </div>
              <h3 style={{ color: "#000" }}>
                <strong>Get on a call with us</strong>{" "}
              </h3>
              <h4 className="mt-3 mb-5 text-center">
                {" "}
                Talk to Our Freedom Partner! <br /> 30 min free consultation{" "}
                {/* <br /> Get your personalized financial plan today! */}
              </h4>
              {/* <a  as={Link} to={"./Calendy.tsx"} className="main-button " href="Calendy" >Book a free call</a> */}
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div>
                  <Link to="/bookaCallMain">
                    <Button variant="outlined" type="button" className="main-button bkc-button">
                      Book a free call
                    </Button>
                  </Link>
                </div>
                <div>
                  <StartWithUs/>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default BookACall;
