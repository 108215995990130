import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Modal,
    Stack,
    TextField,
    TextFieldProps,
    Typography,
  } from "@mui/material";
  import axios from "axios";
  import React, { useEffect, useState } from "react";
  import { useForm } from "react-hook-form";
  import { toast } from "react-toastify";
  import { bondEnum } from "../../Constants/bondEnum";
  import AddIcon from "@mui/icons-material/Add";
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
  import { DatePicker } from '@mui/x-date-pickers/DatePicker';
  import dayjs, { Dayjs } from 'dayjs';
  import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { withParam } from "../../utils/Router.Helper";
  
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    border: "1px solid gray",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    "& .MuiTextField-root": { m: 1 },
    overflowY: "scroll",
    maxHeight: "80%",
  };
  
  const UpdateBond = (props:any) => {

    console.log("Update Props", props)
    const [callDate, setCallDate] = React.useState<Dayjs | null | any>();
    const [putDate, setPutDate] = React.useState<Dayjs | null | any>();
    const [issuanceDate, setIssuanceDate] = React.useState<Dayjs | null | any>();
    const [maturityDate, setMaturityDate] = React.useState<Dayjs | null | any>();
    const [Bdata, setBdata] = useState<any>([]);

    const [issuerType, setIssuerType] = useState<any>();
    const [callPut, setCallPut] = useState<any>();
    const [governmentGauranteed, setGovernmentGauranteed] = useState<any>();
    const [bondType, setBondType] = useState<any>();
    const [couponBasis, setCouponBasis] = useState<any>();
    const [tier, setTier] = useState<any>();
    const [modeOfIssuance, setModeOfIssuance] = useState<any>();
    const [dayCount, setDayCount] = useState<any>();
    const [seniority, setSeniority] = useState<any>();
    const [psu, setPsu] = useState<any>();
    const [listed, setListed] = useState<any>();
    const [couponFreq, setCouponFreq] = useState<any>();
    const [secured, setSecured] = useState<any>();
    const [taxability, setTaxability] = useState<any>();

    const [bondLogo , setBondLogo] = useState<any>();
    const [ImDoc , setImDoc] = useState<any>();

    const [showCircularProgress, setshowCircularProgress] = useState(false);
    
  
    const resolver = async (values: any) => {
      console.log("values",values)
      return {
      
      values: !values.abbreviation ? {} : values,
      errors:
        !values.bondType
        ? {
            bondType: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.dayCountConvention
        ? {
            dayCountConvention: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.issuerName
        ? {
            issuerName: {
              type: "required",
              message: "This is required.",
            },
          } 
          : !values.issuerType
        ? {
            issuerType: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.couponBasis
        ? {
            couponBasis: {
              type: "required",
              message: "This is required.",
            },
          }
          : values.coupon < 0 
        ? {
            coupon: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.couponFrequency
        ? {
            couponFrequency: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.callPutDate
        ? {
            callPutDate: {
              type: "required",
              message: "This is required.",
            },
          } 
          :callPut === "YES" ?
          !values.callDate 
        ? {
            callDate: {
              type: "required",
              message: "This is required.",
            }
          } : {}
        : !values.issuanceDate
        ? {
            issuanceDate: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.maturityDate
        ? {
            maturityDate: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.faceValue
        ? {
            faceValue: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.securedUnsecured
        ? {
            securedUnsecured: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.governmentGauranteed
        ? {
            governmentGauranteed: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.psuNonPsu
        ? {
            psuNonPsu: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.taxability
        ? {
            taxability: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.modeOfIssuance
        ? {
            modeOfIssuance: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.listedUnlisted
        ? {
            listedUnlisted: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.rtaDetails
        ? {
          rtaDetails: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.debentureTrustee
        ? {
          debentureTrustee: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.interestPaymentDates
        ? {
          interestPaymentDates: {
              type: "required",
              message: "This is required.",
            },
          }
          : !values.abbreviation
        ? {
            abbreviation: {
              type: "required",
              message: "This is required.",
            },
          } : {} 
      };
    };
  
    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      resolver,
    });
  
    console.log("Errors", errors);

    const getApiData = async () => {
        axios.get("bondwork/listOfBondDetails/"+(props.params.isin)).then((res:any) => {
          setBdata(res.data.bondWork[0]);
          setIssuerType(res.data.bondWork[0].issuerType);
          setCallPut(res.data.bondWork[0].callPutDate);
          setGovernmentGauranteed(res.data.bondWork[0].governmentGauranteed);
          setBondType(res.data.bondWork[0].bondType);
          setCouponBasis(res.data.bondWork[0].couponBasis);
          setTier(res.data.bondWork[0].tier);
          setModeOfIssuance(res.data.bondWork[0].modeOfIssuance);
          setDayCount(res.data.bondWork[0].dayCountConvention);
          setSeniority(res.data.bondWork[0].seniorityOfRepayment);
          setPsu(res.data.bondWork[0].psuNonPsu);
          setListed(res.data.bondWork[0].listedUnlisted);
          setCouponFreq(res.data.bondWork[0].couponFrequency);
          setSecured(res.data.bondWork[0].securedUnsecured);
          setTaxability(res.data.bondWork[0].taxability);
          setIssuanceDate(dayjs(res.data.bondWork[0].issuanceDate));
          setMaturityDate(dayjs(res.data.bondWork[0].maturityDate));
          setCallDate(callPut==="YES" ? dayjs(new Date()) :dayjs(res.data.bondWork[0].callDate) );
          setPutDate(callPut==="YES" ? dayjs(new Date()) : dayjs(res.data.bondWork[0].putDate) );
          setValue("bondType", res.data.bondWork[0].bondType );
          setValue("dayCountConvention", res.data.bondWork[0].dayCountConvention );
          setValue("issuerType", res.data.bondWork[0].issuerType );
          setValue("couponBasis", res.data.bondWork[0].couponBasis );
          setValue("couponFrequency", res.data.bondWork[0].couponFrequency );
          setValue("callPutDate", res.data.bondWork[0].callPutDate );
          setValue("callDate", res.data.bondWork[0].callDate );
          setValue("putDate", res.data.bondWork[0].putDate );
          setValue("issuanceDate", res.data.bondWork[0].issuanceDate );
          setValue("maturityDate", res.data.bondWork[0].maturityDate );
          setValue("securedUnsecured", res.data.bondWork[0].securedUnsecured );
          setValue("governmentGauranteed", res.data.bondWork[0].governmentGauranteed );
          setValue("psuNonPsu", res.data.bondWork[0].psuNonPsu );
          setValue("taxability", res.data.bondWork[0].taxability );
          setValue("modeOfIssuance", res.data.bondWork[0].modeOfIssuance );
          setValue("listedUnlisted", res.data.bondWork[0].listedUnlisted );

        })
        .catch((error:any) => {
          console.error("Error",error);
        })
      } 
        useEffect(() => {
          getApiData();
        }, []);
    
        console.log("BondData",Bdata)

        console.log("issuerType", issuerType);
  
    const onSubmit = (data: any) => {
      console.log("Data--", {
        ...data,
          isinNumber:Bdata.isinNumber,
          issuerType:issuerType,
          callPutDate:callPut,
          governmentGauranteed:governmentGauranteed,
          bondType:bondType,
          couponBasis:couponBasis,
          tier:tier,
          modeOfIssuance:modeOfIssuance,
          dayCountConvention:dayCount,
          seniorityOfRepayment:seniority,
          psuNonPsu:psu,
          listedUnlisted:listed,
          couponFrequency:couponFreq,
          securedUnsecured:secured,
          taxability:taxability
      });
      const formData = new FormData();
    formData.append("bondWorkDTO",JSON.stringify({
      ...data,
          isinNumber:Bdata.isinNumber,
          issuerType:issuerType,
          callPutDate:callPut,
          governmentGauranteed:governmentGauranteed,
          bondType:bondType,
          couponBasis:couponBasis,
          tier:tier,
          modeOfIssuance:modeOfIssuance,
          dayCountConvention:dayCount,
          seniorityOfRepayment:seniority,
          psuNonPsu:psu,
          listedUnlisted:listed,
          couponFrequency:couponFreq,
          securedUnsecured:secured,
          taxability:taxability,
    })) 
    formData.append("imPDF", ImDoc)
    formData.append("bondLogo",bondLogo )
      axios
        .put("bondwork/updateBondWork",formData)
        .then((res: any) => toast.success("Bond Updated Successfully"))
        .then(()=> {setshowCircularProgress(false);})
        .catch((err: any) =>
         {
          setshowCircularProgress(false); 
          toast.error(
            err ? err.response.data.status.message.title : "Error"
          )}
        );
    };

    console.log(Bdata)
    console.log("bondEnum",bondEnum.IssuerTypeEnum[0].label)

  
    return (
      <>
  
  { Bdata.isinNumber ? <Container maxWidth='xl'>
        <Box sx={{ marginTop: "5%" }} className="modal-scroll">
          <Typography variant="h6" component="h2" textAlign={"center"} mb={3}>
            Update Bond
          </Typography>
  
          <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} sx={{display: "flex", flexDirection: "row",}}>

            <Grid item md={3}>
            {/* isin */}
            <TextField
                id="outlined-basic"
                label="ISIN"
                defaultValue={Bdata.isinNumber}
                variant="outlined"
                // InputProps={{ readOnly: true }}
                fullWidth
                size='small'
                
                error={errors.isinNumber ? true : false}
                helperText={
                  errors.isinNumber === undefined
                    ? ""
                    : errors.isinNumber.message + ""
                }
                {...register("isinNumber",{disabled: true})}
              />
            </Grid>

            <Grid item md={3}>
               {/* bondType */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.BondTypeEnum.map((option: any) => option)}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:Bdata.bondType}}
                onChange={(options: any, newValue) => {
                  setBondType(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bond Type"
                    size='small'
                    
                    error={errors.bondType ? true : false}
                    helperText={
                      errors.bondType === undefined
                        ? ""
                        : errors.bondType.message + ""
                    }
                    // {...register("bondType")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
                  {/* DayCount */}
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.DayCountEnum.map((option: any) => option)}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:!!Bdata.dayCountConvention ? Bdata.dayCountConvention : ""}}
                onChange={(options: any, newValue) => {
                  setDayCount(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Day Count Convention"
                    size='small'
                    
                    error={errors.dayCountConvention ? true : false}
                    helperText={
                      errors.dayCountConvention === undefined
                        ? ""
                        : errors.dayCountConvention.message + ""
                    }
                    {...register("dayCountConvention")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
                  {/* issuerName */}
                  <TextField
                id="outlined-basic"
                label="Issuer Name"
                defaultValue={Bdata.issuerName}
                variant="outlined"
                fullWidth
                size='small'
                
                error={errors.issuerName ? true : false}
                helperText={
                  errors.issuerName === undefined
                    ? ""
                    : errors.issuerName.message + ""
                }
                {...register("issuerName")}
              />
            </Grid>

            <Grid item md={3}>
            {/* issure Type */}
            <Autocomplete
                id="combo-box-demo"
                options={bondEnum.IssuerTypeEnum.map((option: any) => option)}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:Bdata.issuerType}}
                onChange={(options: any, newValue) => {
                  setIssuerType(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Issuer Type"
                    size='small'
                    
                    error={errors.issuerType ? true : false}
                    helperText={
                      errors.issuerType === undefined
                        ? ""
                        : errors.issuerType.message + ""
                    }
                    // {...register("issuerType")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
            {/* couponBasis */}
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.CouponBasisEnum.map((option: any) => option)}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:Bdata.couponBasis}}
                onChange={(options: any, newValue) => {
                  setCouponBasis(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Coupon Basis"
                    size='small'
                    
                    error={errors.couponBasis ? true : false}
                    helperText={
                      errors.couponBasis === undefined
                        ? ""
                        : errors.couponBasis.message + ""
                    }
                    // {...register("couponBasis")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
                {/* coupon */}
              <TextField
                id="outlined-basic"
                label="Coupon"
                defaultValue={Bdata.coupon}
                variant="outlined"
                fullWidth
                size='small'
                error={errors.coupon ? true : false}
                helperText={
                  errors.coupon === undefined ? "" : errors.coupon.message + ""
                }
                {...register("coupon", { valueAsNumber: true })}
              />
            </Grid>

            <Grid item md={3}>
               {/* couponFreq */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.CouponFrequencyEnum.map(
                  (option: any) => option
                )}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:Bdata.couponFrequency}}
                onChange={(options: any, newValue) => {
                  setCouponFreq(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Coupon Frequency"
                    size='small'
                    
                    error={errors.couponFrequency ? true : false}
                    helperText={
                      errors.couponFrequency === undefined
                        ? ""
                        : errors.couponFrequency.message + ""
                    }
                    {...register("couponFrequency")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
              {/* call/put */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.CallPutDateEnum.map((option: any) => option)}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:Bdata.callPutDate}}
                onChange={(options: any, newValue) => {
                  setCallPut(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Call Put Date"
                    size='small'
                
                    error={errors.callPutDate ? true : false}
                    helperText={
                      errors.callPutDate === undefined
                        ? ""
                        : errors.callPutDate.message + ""
                    }
                    {...register("callPutDate")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
                {/* callDate */}
                {
                  callPut === "YES" ?
                  (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="Call Date"
                    inputFormat="DD/MM/YYYY"
                    value={callDate}
                    onChange={(newValue) => {
                      setCallDate(newValue.toJSON());
                      setValue("callDate",newValue.toJSON())
                    }}
                    renderInput={(params) => 
                    <TextField 
                      size="small" 
                      {...params} 
                      sx={{width:"100%"}}
                      error={errors.callDate ? true : false}
                    helperText={
                    errors.callDate === undefined
                      ? ""
                      : errors.callDate.message + ""
                  }
                  // {...register("callDate")}
                  />}
                  />
                </LocalizationProvider>
                  )
                  :
                  (
                  <TextField
                    id="outlined-basic"
                    label="Call Date"
                    variant="outlined"
                    disabled
                    fullWidth
                    size='small'
                    
                  />
                  )
                }
            </Grid>

            <Grid item md={3}>
                {/* PutDate */}
                {
                  callPut === "YES" ? 
                  (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="Put Date"
                    inputFormat="DD/MM/YYYY"
                    value={Bdata.putDate}
                    disabled = {callPut === "NO" || callPut === undefined}
                    onChange={(newValue) => {
                      setPutDate(newValue.toJSON());
                      setValue("putDate",newValue.toJSON())
                    }}
                    renderInput={(params) => <TextField size="small" {...params} defaultValue={putDate} sx={{width:"100%"}}/>}
                  />
                  </LocalizationProvider>
                  )
                  :
                  (
                  <TextField
                    id="outlined-basic"
                    label="Put Date"
                    variant="outlined"
                    disabled
                    fullWidth
                    size='small'
                    
                  />
                  )
                }
            </Grid>

            <Grid item md={3}>
             {/* issuanceDate */}
             <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="Issuance Date"
                    inputFormat="DD/MM/YYYY"
                    value={issuanceDate}
                    onChange={(newValue) => {
                      setIssuanceDate(newValue.toJSON());
                      setValue("issuanceDate",newValue.toJSON())
                    }}
                    renderInput={(params) => 
                    <TextField 
                      size="small" 
                      {...params} 
                      sx={{width:"100%"}}
                      error={errors.issuanceDate ? true : false}
                    helperText={
                    errors.issuanceDate === undefined
                      ? ""
                      : errors.issuanceDate.message + ""
                  }
                    />}
                  />
                </LocalizationProvider>
            </Grid>

            <Grid item md={3}>
              {/* Maturity Date */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="Maturity Date"
                    inputFormat="DD/MM/YYYY"
                    value={maturityDate}
                    onChange={(newValue) => {
                      setMaturityDate(newValue.toJSON());
                      setValue("maturityDate",newValue.toJSON())
                    }}
                    renderInput={(params) => 
                    <TextField 
                      size="small" 
                      {...params} 
                      sx={{width:"100%"}}
                      error={errors.maturityDate ? true : false}
                  helperText={
                    errors.maturityDate === undefined
                      ? ""
                      : errors.maturityDate.message + ""
                  }
                      />}
                  />
                </LocalizationProvider>
            </Grid>

            <Grid item md={3}>
             {/* faceValue */}
             <TextField
                id="outlined-basic"
                label="Face Value"
                defaultValue={Bdata.faceValue}
                variant="outlined"
                fullWidth
                type="number"
                size='small'
                inputProps={{
                  inputMode:'numeric',
                  pattern : '[0-9]*'
                }}
                
                error={errors.faceValue ? true : false}
                helperText={
                  errors.faceValue === undefined
                    ? ""
                    : errors.faceValue.message + ""
                }
                {...register("faceValue", { valueAsNumber: true })}
              />
            </Grid>

            <Grid item md={3}>
              {/* SeniorityRepay */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.RepaymentEnum.map((option: any) => option)}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:!!Bdata.seniorityOfRepayment ? Bdata.seniorityOfRepayment : ""}}
                onChange={(options: any, newValue) => {
                  setSeniority(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seniority Of Repayment"
                    size='small'
                    
                    error={errors.seniorityOfRepayment ? true : false}
                    helperText={
                      errors.seniorityOfRepayment === undefined
                        ? ""
                        : errors.seniorityOfRepayment.message + ""
                    }
                    // {...register("seniorityOfRepayment")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
                 {/* securedUnsecured */}
                 <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.SecuredUnsecuredEnum.map(
                  (option: any) => option
                )}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:Bdata.securedUnsecured}}
                onChange={(options: any, newValue) => {
                  setSecured(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Secured Unsecured"
                    size='small'
                
                    error={errors.securedUnsecured ? true : false}
                    helperText={
                      errors.securedUnsecured === undefined
                        ? ""
                        : errors.securedUnsecured.message + ""
                    }
                    // {...register("securedUnsecured")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
                     {/* Government */}
                     <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.GaurentedEnum.map((option: any) => option)}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:Bdata.governmentGauranteed}}
                onChange={(options: any, newValue) => {
                  setGovernmentGauranteed(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Government Gauranteed"
                    size='small'
                    
                    error={errors.governmentGauranteed ? true : false}
                    helperText={
                      errors.governmentGauranteed === undefined
                        ? ""
                        : errors.governmentGauranteed.message + ""
                    }
                    // {...register("governmentGauranteed")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
                {/* Tier */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.TierEnum.map((option: any) => option)}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:!!Bdata.tier ? Bdata.tier : ""}}
                onChange={(options: any, newValue) => {
                  setTier(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tier"
                    size='small'
                    
                    error={errors.tier ? true : false}
                    helperText={
                      errors.tier === undefined ? "" : errors.tier.message + ""
                    }
                    // {...register("tier")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
                  {/* Psu/Nompsu */}
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.PsuNonPsuEnum.map((option: any) => option)}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:Bdata.psuNonPsu}}
                onChange={(options: any, newValue) => {
                  setPsu(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="PSU Non-PSU"
                    size='small'
                    
                    error={errors.psuNonPsu ? true : false}
                    helperText={
                      errors.psuNonPsu === undefined
                        ? ""
                        : errors.psuNonPsu.message + ""
                    }
                    // {...register("psuNonPsu")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
               {/* taxability */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.TaxabilityEnum.map((option: any) => option)}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:Bdata.taxability}}
                onChange={(options: any, newValue) => {
                  setTaxability(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Taxability"
                    size='small'
                    
                    error={errors.taxability ? true : false}
                    helperText={
                      errors.taxability === undefined
                        ? ""
                        : errors.taxability.message + ""
                    }
                    // {...register("taxability")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
              {/* recordDays */}
              <TextField
                id="outlined-basic"
                label="Record Days"
                defaultValue={!!Bdata.recordDays ? Bdata.recordDays : ""}
                variant="outlined"
                // defaultValue={"2023-02-22"}
                fullWidth
                size='small'
                
                error={errors.recordDate ? true : false}
                helperText={
                  errors.recordDate === undefined
                    ? ""
                    : errors.recordDate.message + ""
                }
                {...register("recordDays", { valueAsNumber: true })}
              />
            </Grid>

            <Grid item md={3}>
                  {/* modeOfIssuance */}
            {/* modeOfIssuance */}
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.ModeOfIssuanceEnum.map((option: any) => option)}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:Bdata.modeOfIssuance}}
                onChange={(options: any, newValue) => {
                  setModeOfIssuance(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Mode Of Issuance"
                    size='small'
                
                    error={errors.modeOfIssuance ? true : false}
                    helperText={
                      errors.modeOfIssuance === undefined
                        ? ""
                        : errors.modeOfIssuance.message + ""
                    }
                    // {...register("modeOfIssuance")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>  
               {/* Listed/Unlisted */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={bondEnum.ListedUnlistedEnum.map((option: any) => option)}
                getOptionLabel={(options: any) => options.label}
                defaultValue={{label:Bdata.listedUnlisted}}
                onChange={(options: any, newValue) => {
                  setListed(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Listed Unlisted"
                    size='small'
                    
                    error={errors.listedUnlisted ? true : false}
                    helperText={
                      errors.listedUnlisted === undefined
                        ? ""
                        : errors.listedUnlisted.message + ""
                    }
                    // {...register("listedUnlisted")}
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
                {/* stepupStepDown */}
              <TextField
                id="outlined-basic"
                label="Step Up Step Down"
                variant="outlined"
                defaultValue={!!Bdata.stepUpStepDown ? Bdata.stepUpStepDown : "" }
                fullWidth
                size='small'
                
                error={errors.stepupStepDown ? true : false}
                helperText={
                  errors.stepupStepDown === undefined
                    ? ""
                    : errors.stepupStepDown.message + ""
                }
                {...register("stepUpStepDown")}
              />
            </Grid>

            <Grid item md={3}>
               {/* remarks */}
              <TextField
                id="outlined-basic"
                label="Remarks"
                defaultValue={Bdata.remarks}
                variant="outlined"
                fullWidth
                size='small'
                
                error={errors.remarks ? true : false}
                helperText={
                  errors.remarks === undefined ? "" : errors.remarks.message + ""
                }
                {...register("remarks")}
              />
            </Grid>

            <Grid item md={3}>
                {/* RtA */}
              <TextField
                id="outlined-basic"
                label="RTA Details"
                defaultValue={Bdata.rtaDetails}
                variant="outlined"
                fullWidth
                size='small'
                
                error={errors.rtaDetails ? true : false}
                helperText={
                  errors.rtaDetails === undefined
                    ? ""
                    : errors.rtaDetails.message + ""
                }
                {...register("rtaDetails")}
              />
            </Grid>
            
            <Grid item md={3}>
              {/* debentureTrustee */}
              <TextField
                id="outlined-basic"
                label="Debenture Trustee"
                defaultValue={Bdata.debentureTrustee}
                variant="outlined"
                fullWidth
                size='small'
                
                error={errors.debentureTrustee ? true : false}
                helperText={
                  errors.debentureTrustee === undefined
                    ? ""
                    : errors.debentureTrustee.message + ""
                }
                {...register("debentureTrustee")}
              />
            </Grid>

            <Grid item md={3}>
               {/* interestPaymentDates */}
              <TextField
                id="outlined-basic"
                label="Interest Payment Dates"
                variant="outlined"
                defaultValue={Bdata.interestPaymentDates}
                fullWidth
                size='small'
                
                error={errors.interestPaymentDates ? true : false}
                helperText={
                  errors.interestPaymentDates === undefined
                    ? ""
                    : errors.interestPaymentDates.message + ""
                }
                {...register("interestPaymentDates")}
              />
            </Grid>

            <Grid item md={3}>
              {/* rating1 */}
              <TextField
                id="outlined-basic"
                label="Rating 1"
                defaultValue={!!Bdata.rating1 ? Bdata.rating1 : ""}
                variant="outlined"
                fullWidth
                size='small'
                
                error={errors.rating1 ? true : false}
                helperText={
                  errors.rating1 === undefined ? "" : errors.rating1.message + ""
                }
                {...register("rating1")}
              /> 
            </Grid>

            <Grid item md={3}>
               {/* rating2 */}
              <TextField
                id="outlined-basic"
                label="Rating 2"
                defaultValue={!!Bdata.rating2 ? Bdata.rating2 : ""}
                variant="outlined"
                fullWidth
                size='small'
                
                error={errors.rating2 ? true : false}
                helperText={
                  errors.rating2 === undefined ? "" : errors.rating2.message + ""
                }
                {...register("rating2")}
              /> 
            </Grid>

            <Grid item md={3}>
              {/* rating3 */}
              <TextField
                id="outlined-basic"
                label="Rating 3"
                defaultValue={!!Bdata.rating3 ? Bdata.rating3 : ""}
                variant="outlined"
                fullWidth
                size='small'
                
                error={errors.rating3 ? true : false}
                helperText={
                  errors.rating3 === undefined ? "" : errors.rating3.message + ""
                }
                {...register("rating3")}
              />
            </Grid>

            <Grid item md={3}>
               {/* Abbrevation */}
              <TextField
                id="outlined-basic"
                label="Abbrevation"
                defaultValue={Bdata.abbreviation}
                variant="outlined"
                fullWidth
                size='small'
                
                error={errors.abbreviation ? true : false}
                helperText={
                  errors.abbreviation === undefined
                    ? ""
                    : errors.abbreviation.message + ""
                }
                {...register("abbreviation")}
              />
            </Grid>

            <Grid item md={12}>
            <Box marginBottom={3} textAlign={'initial'}>
            <Button variant="contained" component='label' >
             Update Logo
                <input hidden type="file" name='form' onChange={(event:any)=>setBondLogo(event.target.files[0])} style={{ marginBottom: "10px" }} />
            </Button>
            <Typography variant='caption' sx={{marginLeft:1}}>{bondLogo === undefined ? "No file choosen" : bondLogo.name }</Typography>
            </Box>

            <Box textAlign={'initial'}>
            <Button variant='contained' component="label">
              Update IM Document
                <input hidden type="file" name='form' onChange={(event:any)=>setImDoc(event.target.files[0])} style={{ marginBottom: "10px" }} />
            </Button>
            <Typography variant='caption'  sx={{marginLeft:1}}>{ ImDoc === undefined ? "No file choosen" : ImDoc.name }</Typography>
            </Box>
            </Grid>

            <div style={{ textAlign: "center", width: "100%" , marginBottom:"4%" }}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled = {showCircularProgress}
                  sx={{ marginTop: "10px" }}
                >
                  {showCircularProgress === true ? (
                            <CircularProgress
                              color="secondary"
                              size={20}
                              thickness={5}
                            />
                          ) : (
                            ""
                          )}
                  Submit
                </Button>
              </div>
          </Grid>
          </form>
        </Box>
      </Container> :<></> }
      </>
    );
  };
  
  export default withParam(UpdateBond);
  