import { Button, Card, Chip, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import React from 'react'
import DeleteIcon from "@mui/icons-material/Delete";

const PMSProposal = (props:any) => {
  const { PMSDetails } = props;
  const { deleteProposal } = props;
  
  return (
	<>
  <Paper >
    <Card sx={{mb:2}}>
    <Card sx={{ p: 1,backgroundColor:"#f3f3f3" }}>
          <Grid container sx={{ mb: 0 }}>
            <Grid item xs={12} sm={12} md={4} lg={2} textAlign={"left"}>
              <img
                src={PMSDetails?.productLogo}
                alt="PMS logo"
                width={100}
                height={50}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} textAlign={"left"}>
              <Typography variant="subtitle1">
                {PMSDetails?.productName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2} textAlign={"center"}>
              <Typography>
                  {PMSDetails?.pmsSchemeClassification}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={1} textAlign={"center"}>
              <Typography variant="subtitle1">Nav : {PMSDetails?.pmsNav}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2} textAlign={"center"}>
              <Typography variant="subtitle1">
                <Chip
                  label={`Price : ${PMSDetails?.clientPrice}`}
                  color="success"
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={1} textAlign={"right"}>
              <Tooltip title="Delete">
                <IconButton>
                  <DeleteIcon onClick={()=>deleteProposal(PMSDetails.cartId)}/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Card>
    </Card>
  </Paper>
  </>
  )
}

export default PMSProposal