import { Button, Card, CardContent, CardMedia, Grid, Link, Paper, Typography } from '@mui/material'
import React from 'react'

const buttonStyle = {
  height: '60px', // Adjust the height as needed
  width: '75px', // Set the width to the same value as the height

};
{/* <Menu
id="product-menu"
MenuListProps={{
  "aria-labelledby": "product-button",
}}
anchorEl={anchorE2}
open={openProductMenu}
onClose={handleCloseMenu}
TransitionComponent={Fade}
>
<MenuItem onClick={() => {
  sessionStorage.setItem("ProductNumber","0");
  window.location.href = "/products"
  }}>
  Fixed Income
</MenuItem>
<MenuItem onClick={() => {
  sessionStorage.setItem("ProductNumber","1");
  window.location.href = "/products"
  }}>
  Mutual Fund
</MenuItem>
<MenuItem onClick={() => {
  sessionStorage.setItem("ProductNumber","2");
  window.location.href = "/products"
  }}>
  PMS
</MenuItem>
<MenuItem onClick={() => {
  sessionStorage.setItem("ProductNumber","3");
  window.location.href = "/products"
  }}>
  AIF
</MenuItem>
<MenuItem onClick={() => {
  sessionStorage.setItem("ProductNumber","4");
  window.location.href = "/products"
  }}>
  Research Services
</MenuItem>
<MenuItem onClick={() => {
  sessionStorage.setItem("ProductNumber","5");
  window.location.href = "/products"
  }}>
  Unlisted
</MenuItem>
<MenuItem onClick={() => {
    sessionStorage.setItem("ProductNumber","6");
    window.location.href = "/products"
    }}>
  Loan
</MenuItem>
<MenuItem onClick={() => {
  sessionStorage.setItem("ProductNumber","7");
  window.location.href = "/products"
  }}>
  Insurance
</MenuItem>
<MenuItem onClick={() => {
    sessionStorage.setItem("ProductNumber","8");
    window.location.href = "/products"
    }}>
    Non Convertible Debentures
</MenuItem>
<MenuItem onClick={() => {
    sessionStorage.setItem("ProductNumber","9");
    window.location.href = "/products"
    }}>
    InvestKul
</MenuItem>

</Menu> */}



const buttons = [
  'Fixed Income',
  'Mutual Fund',
  'PMS',
  'AIF',
  'Research Service',
  'Unlisted',
  'Loan',
  'Insurance',
  'NCD',
  'InvestKul'
  
];


const ZohoLinks = () => {
  return (
	<>

<Grid container spacing={2}>
        <Grid item xs={4} sm={3} md={4} lg={4}>
          <div style={buttonStyle}>
            <Button variant="contained"  onClick={() => {
        window.location.href = "/ProductFixedIncome"
  }} style={buttonStyle}>Fixed Income</Button>
          </div>
        </Grid>
        <Grid item xs={4} sm={3} md={4} lg={4}>
          <div style={buttonStyle}>
            <Button variant="contained" onClick={() => {
        window.location.href = "/ProductMutualFund"
  }} style={buttonStyle}>Mutual Fund</Button>
          </div>
        </Grid>
        <Grid item xs={4} sm={3} md={4} lg={4}>
          <div style={buttonStyle}>
            <Button variant="contained" onClick={() => {
  window.location.href = "/ProductPMS"
  }} style={buttonStyle}>PMS</Button>
          </div>
        </Grid>
        <Grid item xs={4} sm={3} md={4} lg={4}>
          <div style={buttonStyle}>
            <Button variant="contained" onClick={() => {
  
  window.location.href = "/ProductAIF"
  }} style={buttonStyle}>AIF</Button>
          </div>
        </Grid>
        <Grid item xs={4} sm={3} md={4} lg={4}>
          <div style={buttonStyle}>
            <Button variant="contained" onClick={() => {
  window.location.href = "/PrivateEquity"
  }} style={buttonStyle}>Private Equity</Button>
  {/* Private Equity */}
          </div>
        </Grid>
        <Grid item xs={4} sm={3} md={4} lg={4}>
          <div style={buttonStyle}>
            <Button variant="contained" onClick={() => {
  sessionStorage.setItem("ProductNumber","5");
  window.location.href = "/products"
  }} style={buttonStyle}>Unlisted</Button>
          </div>
        </Grid>
        <Grid item xs={4} sm={3} md={4} lg={4}>
          <div style={buttonStyle}>
            <Button variant="contained" onClick={() => {
  sessionStorage.setItem("ProductNumber","6");
  window.location.href = "/products"
  }} style={buttonStyle}>Loan</Button>
          </div>
        </Grid>
        <Grid item xs={4} sm={3} md={4} lg={4}>
          <div style={buttonStyle}>
     <Button variant="contained" onClick={() => {
  window.location.href = "/ProductInsurance"
  }} style={buttonStyle}>Insurance</Button>
          </div>
        </Grid>
        <Grid item xs={4} sm={3} md={4} lg={4}>
          <div style={buttonStyle}>
            <Button variant="contained" onClick={() => {
  sessionStorage.setItem("ProductNumber","8");
  window.location.href = "/products"
  }} style={buttonStyle}>NCD</Button>
          </div>
        </Grid>
        <Grid item xs={4} sm={3} md={4} lg={4}>
          <div style={buttonStyle}>
            <Button variant="contained" onClick={() => {
  sessionStorage.setItem("ProductNumber","13");
  window.location.href = "/products"
  }} style={buttonStyle}>Research service</Button>
          </div>
        </Grid>
      
    </Grid>
	</>
  )
}

export default ZohoLinks