import { Box, Container, Paper, Tab, Tabs, Typography } from '@mui/material'
import React from 'react'
import AifPayout from '../PayoutProducts/AifPayout';
import EquityPayout from '../PayoutProducts/EquityPayout';
import FixedincomePayout from '../PayoutProducts/FixedincomePayout';
import InsurancePayout from '../PayoutProducts/InsurancePayout';
import MutualFundPayout from '../PayoutProducts/MutualFundPayout';
import PmsPayout from '../PayoutProducts/PmsPayout';
import PrimaryIssuePayout from '../PayoutProducts/PrimaryIssuePayout';
import StartupEcoPayout from '../PayoutProducts/StartupEcoPayout';
import TrainingPayout from '../PayoutProducts/TrainingPayout';
import ListOfProductJson from "../PayoutDashboard/ListOfProductJson.json"
import UnlistedBOPayout from '../BOPayoutProducts/UnlistedBOPayout';
import ResearchServicesPayout from '../PayoutProducts/ResearchServicesPayout';
import Ncd from '../PayoutProducts/Ncd';
import BondPayoutCal from '../PayoutProducts/BondPayoutCal';
import UnlistedPayout from '../PayoutProducts/UnlistedPayout';

interface TabPanelProps {
    children?: React.ReactNode;
    index: Number;
    value: Number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


const BOPayoutTables = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
  
  console.log(ListOfProductJson)
  return (
    !!ListOfProductJson ? (
        <Paper elevation={3} sx={{borderRadius:"10px",}}>
            <Container maxWidth='xl'>
            <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={value} 
              onChange={handleChange} 
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            > 
              <Tab label="Unlisted" {...a11yProps(0)} />
              <Tab label="Bond" {...a11yProps(1)} />
              <Tab label="AIF" {...a11yProps(2)} />
              <Tab label="PMS" {...a11yProps(3)} />
              <Tab label="Research Services" {...a11yProps(4)} />
              <Tab label="NCD" {...a11yProps(5)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <UnlistedPayout/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BondPayoutCal/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AifPayout/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PmsPayout/>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ResearchServicesPayout/>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Ncd/>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <FixedincomePayout/>
          </TabPanel>
          <TabPanel value={value} index={7}>
            <MutualFundPayout/>
          </TabPanel>
          <TabPanel value={value} index={8}>
            <EquityPayout/>
          </TabPanel>
          <TabPanel value={value} index={9}>
            <InsurancePayout/>
          </TabPanel>
          <TabPanel value={value} index={10}>
            <PrimaryIssuePayout/>
          </TabPanel>
          <TabPanel value={value} index={11}>
            <StartupEcoPayout/>
          </TabPanel>
          <TabPanel value={value} index={12}>
            <TrainingPayout/>
          </TabPanel>
        </Box>
            </Container>
        </Paper>
        ) : (
          <Typography> Something went wrong ! </Typography>
        )
  )
}

export default BOPayoutTables