import React, {useState} from 'react'
import { Button, Menu, MenuItem, Stack, TextField, alpha } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'; 
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { visuallyHidden } from '@mui/utils';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { changeDate, convertDate } from '../../utils/Helper';
import { productTypeEnum } from '../../Constants/ProductTypeEnum';
import { useDispatch } from 'react-redux';
import ProductType from '../ProductType';
import { GetTransactionByClient } from '../../api/Api';
import { useQuery } from "react-query";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'orderId',
    numeric: false,
    disablePadding: true,
    label: 'Transaction ID',
  },
  {
    id: 'accountOpenDate',
    numeric: false,
    disablePadding: false,
    label: 'Account Open Date',
  },
  {
    id: 'portfolioActivationDate',
    numeric: false,
    disablePadding: false,
    label: 'Portfolio Activation Date',
  },
  {
    id: 'transactionDate',
    numeric: false,
    disablePadding: false,
    label: 'Transaction Date',
  },
  {
    id: 'schemeName',
    numeric: false,
    disablePadding: false,
    label: 'Scheme Name',
  },
  {
    id: 'clientFullName',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
  },
  {
    id: 'partnerName',
    numeric: false,
    disablePadding: false,
    label: 'Partner Name',
  },
  {
    id: 'partnerCompanyName',
    numeric: false,
    disablePadding: false,
    label: 'Partner Company Name',
  },
  {
    id: 'commitmentAmount',
    numeric: false,
    disablePadding: false,
    label: 'Commitment Amount',
  },
  {
    id: 'buyRate',
    numeric: false,
    disablePadding: false,
    label: 'Investment Amount',
  },
  {
    id: 'totalTransactionValue',
    numeric: false,
    disablePadding: false,
    label: 'Total Transaction Value',
  },
  
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  // const { toDate } = props;
  // const { fromDate } = props;
  const { selectedPayout } = props;
  const { downloadSingleInvoice } = props;
  const { clientId } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function downloadPayoutExcel(clientId){
  axios({
    url: `/trade/clientTransactionExcelDownload?clientId=${clientId}&productTypeCode=4`,
    method: "GET",
    responseType: "blob" // important
}).then((response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  var date1 = new Date();
  link.setAttribute(
      "download",
      "PMS_TransactionStatement_" + ("0" + date1.getDate()).slice(-2) + "_" + ("0" + (date1.getMonth() + 1)).slice(-2) + "_" + date1.getFullYear() + ".xlsx"
  );
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
});
}

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          PMS Transaction
        </Typography>
      )}

<Button variant="contained" onClick={handleButtonClick} sx={{m:2}}>
        Download
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        >
        <MenuItem onClick={()=>downloadPayoutExcel(clientId)}>
          <Button variant="text" color="primary" startIcon={<DownloadForOfflineIcon />}>
            Excel
          </Button>
        </MenuItem>
      </Menu>
    </Toolbar>
  );
}


const ClientPMSTransaction = (props) => {
  const dispatch = useDispatch();
  const clientId=props?.clientId;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('orderId');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([])

  useQuery("AIFTransactionByClient",() => GetTransactionByClient(clientId,productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM)
  .then((res)=>{
    setRows(res.data.tradeList)
  })
  )

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.orderId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage,rows],
  );

  function downloadSingleInvoice(payoutId){
    axios({
      url: `payout/payoutInvoice?payoutIds=${payoutId}`,
      method: "GET",
      responseType: "blob" // important
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    var date1 = new Date();
    link.setAttribute(
        "download",
        "Invoice" + ("0" + date1.getDate()).slice(-2) + "_" + ("0" + (date1.getMonth() + 1)).slice(-2) + "_" + date1.getFullYear() + ".pdf"
    );
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
});
  }

  

  return (
	<>

          <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar 
        numSelected={selected.length} 
        // toDate={toDate}
        // fromDate={fromDate}
        selectedPayout={selected}
        clientId={ clientId }
        downloadSingleInvoice={downloadSingleInvoice}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.orderId);
                const labelId = `enhanced-table-checkbox-${index}`;
                console.log("ghgghfshgdfgdsfhg",labelId)

                return (
                  <TableRow
                    //hover
                    //onClick={(event) => handleClick(event, row.orderId)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                    //sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell >{convertDate(row.accountOpenDate) || "-"}</TableCell>
					          <TableCell align="left">{convertDate(row.portfolioActivationDate) || "-"}</TableCell>
					          <TableCell align="left">{convertDate(row.transactionDate) || "-"}</TableCell>
					          <TableCell >{row.schemeName || "-"}</TableCell>
					          <TableCell >{row.clientFullName || "-"}</TableCell>
					          <TableCell >{row.partnerName || "-"}</TableCell>
					          <TableCell >{row.partnerCompanyName || "-"}</TableCell>
					          <TableCell >{row.commitmentAmount.toLocaleString("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || "-"}</TableCell>
					          <TableCell >{row.buyRate ? row.buyRate.toLocaleString("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 })|| "-" :0}</TableCell>
					          <TableCell >{row.totalTransactionValue ? row.totalTransactionValue.toLocaleString("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 })|| "-" :0}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

    </Box>
  </>
  )
}

export default ClientPMSTransaction