import { Box, Container, Grid, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import EarningCard from './EarningCard'
import PopularCard from './PopularCard'
import TotalGrowthBarChart from './TotalGrowthBarChart'
import TotalIncomeDarkCard from './TotalIncomeDarkCard'
import TotalIncomeLightCard from './TotalIncomeLightCard'
import TotalOrderLineChartCard from './TotalOrderLineChartCard'

const PayoutDashboard = () => {

    const[payoutList, setPayoutList] = useState<any>();
    const[paidUnpaid, setPaidUnpaid] = useState<any>([]);

    useEffect(() => {
      axios.get("payout/getPayoutOfProduct").then((res:any)=>{
        setPayoutList(res.data.payoutList);
      });

      axios.get("payout/getTotalPaidUnpaidPayout").then((res:any)=>{
        setPaidUnpaid(res.data.payoutList);
      })
    }, [])

    console.log("Payout", payoutList)
    console.log("PaidUnpaid", paidUnpaid)
    
  return (
    <Container maxWidth="xl" sx={{marginTop:'7%', backgroundColor:'#eef2f6'}}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <EarningCard payout={payoutList} />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <TotalOrderLineChartCard paid={paidUnpaid}/>
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeDarkCard unpaid={paidUnpaid}/>
                            </Grid>
                            {/* <Grid display={'none'} item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeLightCard/>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid sx={{marginTop:'17px', marginBottom:'5%'}} item xs={12} md={12}>
                        <TotalGrowthBarChart payoutList={payoutList}/>
                    </Grid>
                    {/* <Grid item xs={12} md={4}>
                        <PopularCard/>
                    </Grid> */}
                </Grid> 
            </Grid>
        </Grid>
    </Container>
  )
}

export default PayoutDashboard