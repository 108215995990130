import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import { Button, Chip, Grid, InputBase } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { AIF_AMC_LIST, GET_ALL_AMC_LIST, GET_SCHEME_DATA_FROM_AMC_ID } from '../../utils/API_Names';
import { ManageAmcTableSwitch } from './ManageAmcTableSwitch';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { GetSchemeDataFromAmcId } from '../../api/Api';
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import { ManageSchemeTableSwitch } from './ManageSchemeTableSwitch';
import AddScheme from './AddScheme';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'srNo',
    numeric: false,
    disablePadding: true,
    label: 'Sr No',
  },
  {
    id: 'amcId',
    numeric: false,
    disablePadding: true,
    label: 'AMC Id',
  },
  {
    id: 'amcLogo',
    numeric: false,
    disablePadding: false,
    label: 'Logo',
  },
  {
    id: 'amcCode',
    numeric: false,
    disablePadding: false,
    label: 'Code',
  },
  {
    id: 'amcName',
    numeric: false,
    disablePadding: false,
    label: 'AMC Name',
  },
  {
    id: 'amcSebiNo',
    numeric: false,
    disablePadding: false,
    label: 'SEBI No',
  },
  {
    id: 'productName',
    numeric: false,
    disablePadding: false,
    label: 'Scheme Name',
  },
  {
    id: 'schemeBenchmarkName',
    numeric: false,
    disablePadding: false,
    label: 'Scheme Benchmark Name',
  },
  {
    id: 'schemeCode',
    numeric: false,
    disablePadding: false,
    label: 'Scheme Code',
  },
  {
    id: 'schemeId',
    numeric: false,
    disablePadding: false,
    label: 'Scheme Id',
  },
  {
    id: 'schemeInceptionDate',
    numeric: false,
    disablePadding: false,
    label: 'Scheme Inception Date',
  },
  {
    id: 'productCode',
    numeric: false,
    disablePadding: false,
    label: 'Product Code',
  },
  {
    id: 'isActive',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const { toDate } = props;
  const { fromDate } = props;
  const { selectedPayout } = props;
  const { downloadSingleInvoice } = props;

  function downloadPayoutPDF(){
    axios({
      url: `payout/payoutStatementInPDF?fromDate=${fromDate.split("T")[0]}&productTypeCode=11&toDate=${toDate.split("T")[0]}`,
      method: "GET",
      responseType: "blob" // important
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    var date1 = new Date();
    link.setAttribute(
        "download",
        "TermSheet_" + ("0" + date1.getDate()).slice(-2) + "_" + ("0" + (date1.getMonth() + 1)).slice(-2) + "_" + date1.getFullYear() + ".pdf"
    );
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
});
  }


  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Scheme List
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            {/* <DeleteIcon onClick={()=>downloadSingleInvoice(selectedPayout)}/> */}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            {/* <DownloadForOfflineIcon onClick={downloadPayoutPDF}/> */}
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ManageSchemeSortTable(props) {
  let navigate = useNavigate();
  // console.log("PropsAMC",props)


  const { amcId } = props;
  const [searchTerm, setSearchTerm] = React.useState("");
  const [amcCode, setAMCCode] = React.useState("");
  const [amcName, setAMCName] = React.useState("");
  const [productCode, setProductCode] = React.useState("");
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('amcId');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [refresh, setRefresh] = React.useState(true);

  console.log("selected",productCode)


  // const { data } = useQuery(["schemeDetails",amcId,productType],()=>GetSchemeDataFromAmcId(amcId,productType),{
  //   onSuccess:(data)=>{
  //     console.log("rows on success",data)
      
  // }})

  React.useEffect(()=>{
    axios.get(`${GET_SCHEME_DATA_FROM_AMC_ID}${amcId}`).then(res=>{

      function createData(
        srNo,
        amcId,
        amcLogo,
        amcCode,
        amcName,
        amcSebiNo,
        productCode,
        productName,
        schemeBenchmarkName,
        schemeCode,
        schemeId,
        schemeInceptionDate,
        schemePlanType,
        productTypeCode,
        isActive,
        productMasterCode
        ) {
        return {
        srNo,
        amcId,
        amcLogo,
        amcCode,
        amcName,
        amcSebiNo,
        productCode,
        productName,
        schemeBenchmarkName,
        schemeCode,
        schemeId,
        schemeInceptionDate,
        schemePlanType,
        productTypeCode,
        isActive,
        productMasterCode
        };
    }

      const rowData = res.data.amcDetails[0].listOfFunds.map((scheme,index)=>(
        createData(
                index+1,
                scheme.amcId,
                res.data.amcDetails[0].amcLogo,
                res.data.amcDetails[0].amcCode,
                res.data.amcDetails[0].amcName,
                res.data.amcDetails[0].amcSebiNo,
                res.data.amcDetails[0].productCode,
                scheme.productName,
                scheme.schemeBenchmarkName,
                scheme.schemeCode,
                scheme.schemeId,
                scheme.schemeInceptionDate,
                scheme.schemePlanType,
                scheme.productTypeCode,
                scheme.isActive,
                scheme.productMasterCode,
                )))
                setAMCCode(res.data.amcDetails[0].amcCode);
                setAMCName(res.data.amcDetails[0].amcName);
                setProductCode(res.data.amcDetails[0].productCode);

        setRows(rowData) 
    

    })
	

  },[props,refresh]) 


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.productTypeCode);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() =>
       stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage,rows],
  );
   
  const navigateToProduct = (productType,SchemeCode) => {
    if(productType==="AIF"){
      navigate(`/aifScreener/${SchemeCode}`);
    }
    if(productType==="PMS"){
      navigate(`/pmsScreener/${SchemeCode}`);
    }
    if(productType==="LOAN"){
      navigate(`/pmsScreener/${SchemeCode}`);
    }
// aifScreener/NAMMA1
  } 

  console.log("rows",rows)

  const handleSearch = (event) => {
    console.log("Search Item",event.target.value)
    setSearchTerm(event.target.value);
  };

  const filteredAmcList = visibleRows && visibleRows.filter((item) => {
    return (
      item.productName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container alignItems="center" justifyContent="center" spacing={4}>
              <Grid item xs="auto">
                <img src={props.data.amcLogo} width={150} alt="AMC logo" style={{ marginRight: 4 }} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                {/* <Typography variant="h5">AMC Name</Typography> */}
                <Typography variant="h5" align="center">{props.data.amcName || "-"}</Typography>
              </Grid>
            </Grid>
            <Box sx={{ marginY: 4 }} />  
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Typography variant="h6">Contact Person</Typography>
                  <Typography variant="h6">
                  {props.data.amcContactPerson || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Typography variant="h6">Email ID</Typography>
                  <Typography variant="h6">
                  {props.data.amcEmailId || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Typography variant="h6">SEBI No.</Typography>
                  <Typography variant="h6">
                  {props.data.amcSebiNo || "-"}
                  </Typography>
                </Grid>
            </Grid>
            <br/>

<Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                mb:2
              }}
              elevation={0}
            >
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by Scheme Name"
                onChange={handleSearch}
                inputRef={input => input && input.focus()}
                autoFocus={true}
              />
              <AddScheme amcId={amcId} amcCode={amcCode} productCode={productCode}   amcName={amcName} />
            </Paper>


      { rows && rows.length!==0 ?
      <Paper sx={{ width: '100%', mb: 2 }} elevation={12}>
        <EnhancedTableToolbar 
        numSelected={selected.length} 
        toDate={props.toDate} 
        fromDate={props.fromDate} 
        selectedPayout={selected}
        />

        <TableContainer>
          <Table
            sx={{ minWidth: 1175}}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody 
            sx={{maxHeight:200}}
            >
              {filteredAmcList.map((row, index) => {
                const isItemSelected = isSelected(row.productMasterCode);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.productMasterCode)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.productMasterCode}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.srNo}
                    </TableCell>
                    <TableCell align="left">{row.amcId}</TableCell>
                    <TableCell align="left"><img src={row.amcLogo} alt="Amc Logo" width={30} height={30}/></TableCell>
                    <TableCell align="left">{row.amcCode}</TableCell>
                    <TableCell align="left" onClick={()=>navigateToProduct(row.productCode,row.schemeCode)} sx={{textDecoration:"underline"}}>{row.amcName==="null"?"-":row.amcName}</TableCell>
                    <TableCell align="left">{row.amcSebiNo}</TableCell>
                    <TableCell align="left">{row.productName}</TableCell>
                    <TableCell align="left">{row.schemeBenchmarkName}</TableCell>
                    <TableCell align="left">{row.schemeCode}</TableCell>
                    <TableCell align="left">{row.schemeId}</TableCell>
                    <TableCell align="left">{row.schemeInceptionDate}</TableCell>
                    <TableCell align="left">
                      <Chip color="success" label={row.productCode}/>
                    </TableCell>
                    <TableCell align="left">
                      <ManageSchemeTableSwitch 
                        productType={row.productCode}
                        productMasterCode={row.productMasterCode} 
                        isActive={row.isActive} 
                        setRefresh={setRefresh} 
                        refresh={refresh}
                      />
					          </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25,50,100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper> :<Box>No Data Fount</Box> }
    </Box>
  );
}
