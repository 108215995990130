export const CinRegex = (str) => {
    let regex = /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
    if(regex.test(str)){
        return true
    }
    return false
}

export const GstRegex = (str) => {
    if (str === '') {
        return true;
    }
    let regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if(regex.test(str)){
        return true
    }
    return false
}

export const PANRegex = (str) => {
    let regex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    if(regex.test(str)){
        return true
    }
    return false
}