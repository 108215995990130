import { Button, IconButton, TableCell, TableRow, TextField, Tooltip, styled, tableCellClasses } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {convertDate , convertDateYMD, convertDateDMY} from '../utils/Helper';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.common.black,
      fontWeight: "600",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding:"8px",
    },
  }));

const CallCfRows = (props:any) => {
    const item:any = props.item

    const [totalPayoutUpdate, setTotalPayoutUpdate] = useState<any>(item.totalPayout);
    const [interestPayoutUpdate, setInterestPayoutUpdate] = useState<any>(item.interestPayment);
    const [redemption, setRedemption] = useState<any>(item.redemption);
    const [nonHolidayDate , setNonHolidayDate] = useState<any>(item && item.nonHolidays.slice(0,10));
    const [redemptionDate , setRedemptionDate] = useState<any>(item && item.interestDate.slice(0,10));
    const [ edit, setEdit ] = useState(false)

    const handleInterestChange = (e:any) => {
        const value = parseFloat(e.target.value);
        setInterestPayoutUpdate(value);
      }

    const handleRedemptionChange = (e:any) => {
        const value = parseFloat(e.target.value);
        setRedemption(value);
      }
  
      const handleTotalPayChange = (e:any) => {
        const value = parseFloat(e.target.value);
        setTotalPayoutUpdate(value);
      }

    const updateCashFlow = () => {
        let cashflowData = {
            "cfCallDateId": item.cfCallDateId,
            "cashFlowId": item.cashFlowId,
            "isinNumber": item.isinNumber,
            "interestPayment": interestPayoutUpdate,
            "interestDate": convertDateYMD(redemptionDate),
            "days": item.days,
            "nonHolidays": convertDateYMD(nonHolidayDate),
            "redemption": redemption,
            "totalPayout": totalPayoutUpdate
          }
          console.log("cashflowData", cashflowData);
          axios.put("cashflow/updateCashFlowCallDate",cashflowData).then((res:any)=>res && toast.success("Updated Successfully!"))
    }

    const resolver: any = async (values: any) => {
        return {
            values: !values.cfMaturityDateId ? {} : console.log("values",values),
            errors: item.cfMaturityDateId ==="" && !values.cfMaturityDateId ? {
                cfMaturityDateId: {
                  type: "required",
                  message: "This is required.",
                },
              } 
             :{}
        }
    }

    const { register, handleSubmit, formState : {errors}, watch, setValue } = useForm({
        resolver:resolver
    });

    const onSubmit = (data: any,item:any) => {
        console.log("data--",data)
        console.log("item--",item)
      }
      
      
      console.log("item--",item)
  return (
    <>
    <TableRow>
      <StyledTableCell>{item.isinNumber}</StyledTableCell>
      <StyledTableCell>
        <TextField
        size='small'
          label="Interest Date"
          defaultValue={convertDateDMY(item && item.interestDate.slice(0,10))}
          className={!edit ? "textDisable" : ""}
            onChange={(e:any)=>{
              props.updateCashFlowJson(props.CashFlowData,item.cfCallDateId,convertDateYMD(e.target.value),convertDateYMD(item.issuanceDate));
              setRedemptionDate(e.target.value)
            }}
          variant="outlined"
        />
          </StyledTableCell>
      <StyledTableCell>
      <TextField
      size='small'
          label="Non Holiday"
          defaultValue={convertDateDMY(item && item.nonHolidays.slice(0,10))}
          className={!edit ? "textDisable" : ""}
          onChange={(e:any)=>setNonHolidayDate(e.target.value)}
          variant="outlined"
          />
          </StyledTableCell>
        <StyledTableCell>{item.days}</StyledTableCell>
      <StyledTableCell align={"right"}>
        <TextField
        size='small'
          label="Interest Payout"
          defaultValue={item.interestPayment}
          className={!edit ? "textDisable" : ""}
          onChange={handleInterestChange}
          variant="outlined"
          />
      </StyledTableCell>
      <StyledTableCell align={'center'}>
        <TextField
        size='small'
          label="Redemption"
          defaultValue={item.redemption}
          className={!edit ? "textDisable" : ""}
          onChange={handleRedemptionChange}
          variant="outlined"
        />
        {/* {item.redemption} */}
      </StyledTableCell>
      <StyledTableCell align={"right"}>
        <TextField
        size='small'
          label="Total Payout"
          defaultValue={item.totalPayout}
          className={!edit ? "textDisable" : ""}
          onChange={handleTotalPayChange}
          variant="outlined"
        />
      </StyledTableCell>
      <StyledTableCell align={"right"} sx={{ 
          display: "flex",
          flexDirection: "row",
          p:1
          }}>
       {item.Updatable || edit ?
          <>
          <Tooltip title="Save" placement="top">
           <IconButton aria-label="Save">
             <SaveIcon onClick={updateCashFlow} />
           </IconButton>
          </Tooltip> 
          { !item.Updatable?<Tooltip title="Cancel" placement="top">
           <IconButton aria-label="Cancel">
             <CancelIcon onClick={()=>setEdit(!edit)} />
           </IconButton>
          </Tooltip>:<></> }
          </>
           : 
          <Tooltip title="Edit" placement="top">
           <IconButton aria-label="Edit">
             <EditIcon onClick={()=>setEdit(!edit)} />
           </IconButton>
          </Tooltip>} 
      </StyledTableCell>
    </TableRow>
    </>
  );
}

export default CallCfRows