import { Box , Grid, Avatar, Button, Typography, Paper, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import React from 'react'
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

const TotalOrderLineChartCard = (props:any) => {
    const paidValue = props.paid;
    console.log("Paid props", paidValue);
  return (
    <Paper elevation={3} sx={{
    backgroundColor: "#1e88e5",
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    borderRadius:"10px",
    height:"225px",
    // '&>div': {
    //     position: 'relative',
    //     // zIndex: 5
    // },
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background: theme.palette.primary[800],
        borderRadius: '50%',
        zIndex: 1,
        top: -85,
        right: -95,
        // [theme.breakpoints.down('sm')]: {
        //     top: -105,
        //     right: -140
        // }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        width: 210,
        height: 210,
        // background: theme.palette.primary[800],
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.5,
        // [theme.breakpoints.down('sm')]: {
        //     top: -155,
        //     right: -70
        // }
    }
    }}>
        <Box sx={{ p: 2 }}>
        <List sx={{ py: 0 }}>
            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                    <Avatar
                        variant="rounded"
                        sx={{
                            // ...theme.typography.commonAvatar,
                            // ...theme.typography.largeAvatar,
                            backgroundColor: "#1565c0",
                            color: '#fff'
                        }}
                    >
                        <LocalMallOutlinedIcon fontSize="inherit" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        py: 0,
                        mt: 0.45,
                        mb: 0.45
                    }}
                    primary={
                        paidValue && paidValue.map((item:any)=>(
                        <Typography variant="h4" sx={{ fontSize: '2.125rem', fontWeight: 500, }}>
                            ₹{item.totalPayoutGiven}
                        </Typography>
                        ))
                    }
                    secondary={
                        <Typography variant="subtitle2" sx={{ mt: 0.25 }}>
                            { sessionStorage.getItem("Profile")==="E" ? "Paid Revenue Credit" : "" }
                            { sessionStorage.getItem("Profile")==="D" ? "Paid Payout" : "" }
                            { sessionStorage.getItem("Profile")==="B" ? "Paid Payout" : "" }
                        </Typography>
                    }
                />
            </ListItem>
        </List>
    </Box>
                    {/* <Box sx={{ p: 2.25 }}>
                        <Grid container direction="column">
                            <Grid item>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                backgroundColor: "#1565c0",
                                                mt: 1,
                                            }}
                                        >
                                            <LocalMallOutlinedIcon fontSize="inherit" />
                                        </Avatar>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ mb: 0.75 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                {paidValue && paidValue.map((item:any)=>(
                                                    <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                                                    ₹{item.totalPayoutGiven}
                                                    </Typography>
                                                ))}
                                            </Grid>
                                            <Grid item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '1rem',
                                                        fontWeight: 500,
                                                        textAlign:'left'
                                                    }}
                                                >
                                                    Paid Payout
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box> */}
                </Paper>
  )
}

export default TotalOrderLineChartCard