import { ThemeProvider } from '@emotion/react'
import { Box, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, createTheme, styled, tableCellClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { StyledTableCell } from '../../Constants/TableStyle'
import { getPortfolioOverview } from '../../api/Api'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const defaultTheme = createTheme()

const AnalyzerOverview = () => {
    const [ReportsData, setReportsData] = useState<any>([])
    const [UserinvestedScheme, setUserInvestedScheme] = useState<any>('')
    const [TotalSum, setTotalSum] = useState(0)
    const [investmentsum, setInvestmentSum] = useState(0)
    const [dataweigntage, setDataWeigntage] = useState(0)
    const [sortKey, setSortKey] = useState('percentageOfPortfolio')
    const [sortDirection, setSortDirection] = useState('asc')
    const [FilteredISIN, setFilteredISIN] = useState<any>('')
    const [showRepeated, setShowRepeated] = useState(false)
    const [showCircularProgress, setshowCircularProgress] = useState(false);


    useEffect(() => {
        setshowCircularProgress(true);
        try {
            getPortfolioOverview()
            .then((resp) => {
              const Arr = resp.data.portfolioFundDetailsDTOList
              setReportsData(Arr)
    
              setUserInvestedScheme(Arr.length)
    
              const Total = addSum(Arr)
              setTotalSum(Total)
    
              const totalValue = addInvestValue(Arr)
              setInvestmentSum(totalValue)
    
              const totalWeigntage = calculateWeigntage(Arr)
              setDataWeigntage(totalWeigntage)
            }).then(()=>{
                setshowCircularProgress(false)
              })
            
        } catch (error) {
          console.log('Error', error)
        }
        sortTable('percentageOfPortfolio')
        window.scrollTo(0, 0)
      }, [])

      const addSum = (arr:any) => {
        return arr.reduce((sum:any, item:any) => sum + item.currentMTM, 0)
      }
    
      const addInvestValue = (arr:any) => {
        return arr.reduce((sum:any, item:any) => sum + item.investedAmount, 0)
      }
    
      const calculateWeigntage = (arr:any) => {
        return arr.reduce((sum:any, item:any) => sum + item.percentageOfPortfolio, 0)
      }
    
      const sortTable = (key:any) => {
        const newDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    
        const sortedData = [...ReportsData].sort((a, b) =>
          sortDirection === 'asc' ? b[key] - a[key] : a[key] - b[key]
        )
    
        setReportsData(sortedData)
        setSortKey(key)
        setSortDirection(newDirection)
      }
    
      const StyledTableCell = styled(TableCell)(({ theme :any}) => ({
        [`&.${tableCellClasses.head}`]: {
          padding: '0px 16px',
          textAlign: 'center'
    
        },
        [`&.${tableCellClasses.body}`]: {
          textAlign: 'center'
        }
      }))
      const handleButtonClick = (item:any) => {
        setFilteredISIN(item.isin);
        setShowRepeated(!showRepeated);
      };
  return (
    <div>
        {showCircularProgress == true ? (
                    <CircularProgress
                      color="secondary"
                      size={50}
                      thickness={5}
                    />
                  ) : (
                     <ThemeProvider theme={defaultTheme}>
            <Typography variant='h5' className="mutual-funds-overview-report">Mutual Funds <span>({UserinvestedScheme})</span></Typography>

            <Box className="main-your-performance-table-scroll" sx={{ marginBottom: '2em' }}>
            <Paper elevation={4} className='portfolio-report-main-table'>
                    <TableContainer >
                        <Table sx={{ minWidth: '100%', border: '2px solid #000' }} aria-label='simple table'>
                            <TableHead className="main-tr-table-portfolio-report" sx={{ padding: 0 }}>
                                {/* <TableRow > */}
                                <StyledTableCell className="" onClick={() => sortTable('fundName')}>Scheme Name</StyledTableCell>
                                <StyledTableCell onClick={() => sortTable('morningStar')}> <Typography className="portfolio-sort-mf-td-p-main">Morning Star Rating <SwapVertIcon></SwapVertIcon> </Typography>  </StyledTableCell>
                                <StyledTableCell onClick={() => sortTable('investedAmount')}> <Typography className="portfolio-sort-mf-td-p-main">Investment Value <SwapVertIcon ></SwapVertIcon>  </Typography> </StyledTableCell>
                                <StyledTableCell onClick={() => sortTable('currentMTM')}> <Typography className="portfolio-sort-mf-td-p-main"> Current Value <SwapVertIcon ></SwapVertIcon> </Typography> </StyledTableCell>
                                <StyledTableCell>Absolute Return(%)</StyledTableCell>

                                <StyledTableCell width={250} sx={{minWidth:"250px"}}>
                                    <Typography style={{ marginBottom: '0px',   flexWrap: 'nowrap' }}>Return</Typography>
                                    <Grid container >
                                        <Grid item xs={12} sm={12} md={12} lg={6} >
                                            <Typography style={{ fontSize: '14px', marginBottom: '4px', textAlign: 'center' }}>Scheme</Typography>
                                            <Typography className="portfolio-return-p-data">
                                                <span className="one-yr-return" >1YR</span>
                                                <span className="two-yr-return">3YR</span>
                                                <span className="five-yr-return">5YR</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6} >
                                            <Typography style={{ fontSize: '14px', marginBottom: '4px' }}>Category_Avg</Typography>
                                            <Typography className="portfolio-return-p-data">
                                                <span className="one-yr-return">1YR</span>
                                                <span className="two-yr-return">3YR</span>
                                                <span className="five-yr-return">5YR</span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </StyledTableCell>

                                <StyledTableCell onClick={() => sortTable('percentageOfPortfolio')}> <Typography className="portfolio-sort-mf-td-p-main">Portfolio Weightage <SwapVertIcon></SwapVertIcon> </Typography> </StyledTableCell>

                                {/* </TableRow> */}
                            </TableHead>
                            <TableBody className='main-table-portfolio-mf-tbody'>
                                {ReportsData?.filter((auto:any) => auto.fundName != null).map((item:any) => (
                                    <>

                                        <TableRow className="main-tr-table-portfolio-report-data">
                                            {item.portfolioFundDetailsDTOList.length > 0
                                              ? <StyledTableCell onClick={() => handleButtonClick(item)}>{item.fundName == null ? '0' : item.fundName}
                                                    {showRepeated === true && FilteredISIN === item.isin ? <ArrowDropUpIcon></ArrowDropUpIcon> : <ArrowDropDownIcon></ArrowDropDownIcon>}
                                                </StyledTableCell>
                                              : <>
                                                    <StyledTableCell>{item.fundName == null ? '0' : item.fundName}</StyledTableCell>

                                                </>}
                                            <StyledTableCell>
                                                <Typography style={{ margin: '0px' }}>{item.morningStar === 5 ? <Typography style={{ color: 'gold', fontSize: '20px' }}>★★★★★</Typography> : ''}</Typography>
                                                <Typography style={{ margin: '0px' }}>{item.morningStar === 4 ? <Typography style={{ color: 'gold', fontSize: '20px' }}>★★★★</Typography> : ''}</Typography>
                                                <Typography style={{ margin: '0px' }}>{item.morningStar === 3 ? <Typography style={{ color: 'gold', fontSize: '20px' }}>★★★</Typography> : ''}</Typography>
                                                <Typography style={{ margin: '0px' }}>{item.morningStar === 2 ? <Typography style={{ color: 'gold', fontSize: '20px' }}>★★</Typography> : ''}</Typography>
                                                <Typography style={{ margin: '0px' }}>{item.morningStar === 1 ? <Typography style={{ color: 'gold', fontSize: '25px' }}>★</Typography> : ''}</Typography>
                                                <Typography style={{ margin: '0px' }}>{item.morningStar === '' || item.morningStar == null ? <p style={{ fontSize: '12px' }}>Not rating</p> : ''}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>{item.investedAmount == null ? '-' : item.investedAmount.toLocaleString()}</StyledTableCell>
                                            <StyledTableCell>{item.currentMTM == null ? '-' : item.currentMTM.toLocaleString()}</StyledTableCell>
                                            {/* <StyledTableCell>{item.nav}</StyledTableCell> */}
                                            <StyledTableCell>{item.absolutePAndL == null ? '-' : item.absolutePAndL}</StyledTableCell>
                                            {/* <StyledTableCell>{item.cagr==null?"0":item.cagr}</StyledTableCell> */}
                                            <StyledTableCell>

                                            <Grid container sx={{ display: 'flex' }}>
                                                    <Grid item xs={12} sm={12} md={12} lg={6} >
                                                        <><span className="one-yr-return-data" >{item.portfolioReturnOneYr == null ? '--' : item.portfolioReturnOneYr.toFixed(2)}</span>
                                                            <span className="one-yr-return-data" >{item.portfolioReturnThreeYr == null ? '--' : item.portfolioReturnThreeYr.toFixed(2)}</span>
                                                            <span className="one-yr-return-data" >{item.portfolioReturnFiveYr == null ? '--' : item.portfolioReturnFiveYr.toFixed(2)}</span></>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={6} >
                                                        <><span className="one-yr-return-data" style={{}}>{item.categoryAvgReturnOneYr == null ? '--' : item.categoryAvgReturnOneYr.toFixed(2)}</span>
                                                            <span className="one-yr-return-data" style={{}}>{item.categoryAvgReturnThreeYr == null ? '--' : item.categoryAvgReturnThreeYr.toFixed(2)}</span>
                                                            <span className="one-yr-return-data" style={{}}>{item.categoryAvgReturnFiveYr == null ? '--' : item.categoryAvgReturnFiveYr.toFixed(2)}</span></>
                                                    </Grid>
                                               </Grid>

                                            </StyledTableCell>
                                            <StyledTableCell>{item.percentageOfPortfolio}%</StyledTableCell>
                                        </TableRow>

                                        {showRepeated === true && FilteredISIN === item.isin
                                          ? <>
                                                <TableRow>
                                                    <StyledTableCell colSpan={7} style={{ backgroundColor: '#ebebeb' }}>
                                                        <Table style={{ border: '2px solid black', width: '100%', backgroundColor: '#fff' }}>
                                                            <TableHead className="">
                                                                <TableRow className="main-tr-table-portfolio-report">
                                                                    <th className="">Scheme Name</th>
                                                                    <th > <p className="portfolio-sort-mf-td-p-main"><span>Morning Star Rating</span> </p>  </th>
                                                                    <th > <p className="portfolio-sort-mf-td-p-main"><span>Investment Value</span>  </p> </th>
                                                                    <th > <p className="portfolio-sort-mf-td-p-main"> <span>Current Value</span> </p> </th>
                                                                    {/* <th>NAV(date)</th> */}
                                                                    <th>Absolute Return(%)</th>
                                                                    {/* <th>CAGR %</th> */}
                                                                    <TableHead><p style={{ marginBottom: '0px' }}> Return </p>
                                                                        {/* <Box className="col-12" style={{ display: 'flex', alignItems: 'center' }}> */}
                                                                        <Grid container sx={{ alignItems: 'center',flexWrap: "nowrap" }}>
                                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                                <span style={{ fontSize: '14px' }}> Scheme </span>
                                                                                <p className="portfolio-return-p-data">
                                                                                    <span className="one-yr-return">1YR</span>
                                                                                    <span className="two-yr-return">3YR</span>
                                                                                    <span className="five-yr-return">5YR</span> </p>
                                                                            </Grid>
                                                                            |
                                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                                <span style={{ fontSize: '14px' }}>Category Avg </span>
                                                                                <p className="portfolio-return-p-data">
                                                                                    <span className="one-yr-return">1YR</span>
                                                                                    <span className="two-yr-return">3YR</span>
                                                                                    <span className="five-yr-return">5YR</span> </p>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableHead>
                                                                    <th> <p className="portfolio-sort-mf-td-p-main"><span>Portfolio Weightage</span>  </p> </th>

                                                                </TableRow>
                                                            </TableHead>
                                                            {item.portfolioFundDetailsDTOList.length > 0

                                                              ? <TableBody>
                                                                    {item.portfolioFundDetailsDTOList.map((data:any, index:any) => (
                                                                        <TableRow key={index} className="main-tr-table-portfolio-report-data" >
                                                                            <StyledTableCell>{data.fundName == null ? '0' : data.fundName}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell>
                                                                                <h2 style={{ margin: '0px' }}>{data.morningStar === 5 ? <p style={{ color: 'gold', fontSize: '20px' }}>★★★★★</p> : ''}</h2>
                                                                                <h2 style={{ margin: '0px' }}>{data.morningStar === 4 ? <p style={{ color: 'gold', fontSize: '20px' }}>★★★★</p> : ''}</h2>
                                                                                <h2 style={{ margin: '0px' }}>{data.morningStar === 3 ? <p style={{ color: 'gold', fontSize: '20px' }}>★★★</p> : ''}</h2>
                                                                                <h2 style={{ margin: '0px' }}>{data.morningStar === 2 ? <p style={{ color: 'gold', fontSize: '20px' }}>★★</p> : ''}</h2>
                                                                                <h2 style={{ margin: '0px' }}>{data.morningStar === 1 ? <p style={{ color: 'gold', fontSize: '25px' }}>★</p> : ''}</h2>
                                                                                <h2 style={{ margin: '0px' }}>{data.morningStar === '' || data.morningStar == null ? <p style={{ fontSize: '12px' }}>Not rating</p> : ''}</h2>
                                                                            </StyledTableCell>
                                                                            <StyledTableCell>{data.investedAmount == null ? '-' : data.investedAmount.toLocaleString()}</StyledTableCell>
                                                                            <StyledTableCell>{data.currentMTM == null ? '-' : data.currentMTM.toLocaleString()}</StyledTableCell>
                                                                            <StyledTableCell>{data.absolutePAndL == null ? '-' : data.absolutePAndL}</StyledTableCell>
                                                                            <StyledTableCell>
                                                                                <div className="" style={{ display: 'flex' }}>
                                                                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                                        <><span className="one-yr-return-data" style={{ fontSize: '12px' }}>{data.portfolioReturnOneYr == null ? '-' : data.portfolioReturnOneYr.toFixed(2)}</span>
                                                                                            <span className="one-yr-return-data" style={{ fontSize: '12px' }}>{data.portfolioReturnThreeYr == null ? '-' : data.portfolioReturnThreeYr.toFixed(2)}</span>
                                                                                            <span className="one-yr-return-data" style={{ fontSize: '12px' }}>{data.portfolioReturnFiveYr == null ? '-' : data.portfolioReturnFiveYr.toFixed(2)}</span></>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                                        <><span className="one-yr-return-data" style={{ fontSize: '12px' }}>{data.categoryAvgReturnOneYr == null ? '-' : data.categoryAvgReturnOneYr.toFixed(2)}</span>
                                                                                            <span className="one-yr-return-data" style={{ fontSize: '12px' }}>{data.categoryAvgReturnThreeYr == null ? '-' : data.categoryAvgReturnThreeYr.toFixed(2)}</span>
                                                                                            <span className="one-yr-return-data" style={{ fontSize: '12px' }}>{data.categoryAvgReturnFiveYr == null ? '-' : data.categoryAvgReturnFiveYr.toFixed(2)}</span></>
                                                                                    </Grid>
                                                                                </div>
                                                                            </StyledTableCell>
                                                                            <StyledTableCell>{data.percentageOfPortfolio}%</StyledTableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                              : <></>
                                                            }
                                                        </Table>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </>
                                          : <>
                                            </>
                                        }

                                    </>
                                ))}
                                <TableRow className="main-tr-table-portfolio-report">
                                    <StyledTableCell className="extra-table-added-for-total">Total</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                    <StyledTableCell className="extra-table-added-for-total">{investmentsum.toLocaleString()}</StyledTableCell>
                                    <StyledTableCell className="extra-table-added-for-total">{TotalSum.toLocaleString()}</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                    {/* <StyledTableCell>-</StyledTableCell> */}
                                    <StyledTableCell>-</StyledTableCell>
                                    <StyledTableCell className="extra-table-added-for-total">{dataweigntage.toFixed(2)}%</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>

            {/* </Container> */}
        </ThemeProvider>
                  )}
    </div>
  )
}

export default AnalyzerOverview