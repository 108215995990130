import {
  Container,
  Box,
  Typography,
  IconButton,
  Snackbar,
  Button,
  CircularProgress,
  Modal,
  Fade,
  Backdrop,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { withParam } from "../../utils/Router.Helper";
import { fetchInsuranceData, getInsuranceAmc } from "../../api/Api";
import { useQuery } from "react-query";
import AddProduct from "./AIF/AddAIFProduct";
import FilterInsurance from "../Filters/FilterInsurance";
import { Link } from "react-router-dom";

const Insurance = () => {
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModel] = useState(false);
  const [productInsurance,setproductInsurance] =React.useState<any>([])

  const handleModalOpen = () => {
    setOpenModel(true);
  };
  const handleModalClose = () => {
    setOpenModel(false);
  };
  const handleOpenSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    visibility: "visible !important",
    opacity: "1 !important",
  };

  function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number
  ) {
    return { name, calories, fat, carbs, protein };
  }


  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // const {data, isLoading, error, isError} = useQuery("InsuranceData",fetchInsuranceData);

  // if (isError) {
  //   return (
  //     <Container maxWidth={"xl"}>
  //   <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "10%" }}>
  //     {/* {error.message} */}ERROR
  //     </Box>
  //     </Container>
  //     )
  // }
  useEffect(() => {
    getInsuranceAmc().then((resp)=>setproductInsurance(resp.data)).then(()=>{
        })
  
  }, [])
console.log("isurancedatatat",productInsurance)
  return (
    <>

<Container>
<Grid mt={5}>
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead style={{background:"#a3e5e5"}}>
          <TableRow>
          <TableCell align="center"></TableCell>
            <TableCell align="center">Category</TableCell>
            <TableCell align="center">Product Name</TableCell>
            <TableCell align="center">Insurance Type</TableCell>
            <TableCell align="center">Payment Type</TableCell>
          
         
          </TableRow>
        </TableHead>
        <TableBody>
          { productInsurance?.insurance?.map((row:any,index: number) => (
            <TableRow
            key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                     <TableCell>
                <img  style={{width:"30px"}}  src={row.insLogo} alt="loanLogo" /></TableCell>
              <TableCell align="center">{row.category}</TableCell>
              {/* <TableCell align="center">{row.productName}</TableCell> */}
              <TableCell align="center" sx={{textDecoration: "underline"}}>
                    <Link to={"/AmcInsuranceScheme/"+ row.productMasterCode}  > {row.productName}</Link>
             </TableCell>
              <TableCell align="center">{row.insuranceType}</TableCell>
              <TableCell align="center">{row.paymentType}</TableCell>
          
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
     </Grid>
     </Container>
    {/* {isLoading ? (<CircularProgress
                    size={68}
                    sx={{
                      color: "primary",
                      position: "absolute",
                      top: 300,
                      left: 650,
                      zIndex: 1,
                    }}
                  />):
                  (
      <Container maxWidth={"xl"}>
        <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "1%" }}>
          <AddProduct/>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <FilterInsurance/>
            </Grid>
            <Grid item md={9}>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell align="right">Scheme Name</TableCell>
                  <TableCell align="right">One Yr Return</TableCell>
                  <TableCell align="right">Two Yr Return</TableCell>
                  <TableCell align="right">Three Yr Return</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Delete Product</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              </TableBody>
            </Table>
          </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Container>
                  )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message="Note archived"
        action={action}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <Typography variant="h5">Add Product</Typography>

                <Typography variant="subtitle1">Form</Typography>
                <input type="file" style={{ marginBottom: "10px" }} />
                <Typography variant="subtitle1">Presentation</Typography>
                <input type="file" style={{ marginBottom: "10px" }} />
                <Typography variant="subtitle1">Fact Sheet</Typography>
                <input type="file" style={{ marginBottom: "10px" }} />
                <Typography variant="subtitle1">Fund Manager Video</Typography>
                <input type="file" style={{ marginBottom: "10px" }} />
                <Typography variant="subtitle1">Product Video</Typography>
                <input type="file" style={{ marginBottom: "10px" }} />
                <Typography variant="subtitle1">Knowledge Series</Typography>
                <input type="file" style={{ marginBottom: "10px" }} />
                <Typography variant="subtitle1">Contest</Typography>
                <input type="file" style={{ marginBottom: "10px" }} />
              </div>
            </Box>
          </Box>
        </Fade>
      </Modal> */}

    </>
  );
};

export default withParam(Insurance);
