/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Typography from "@mui/material/Typography";
import { useQuery } from "react-query";
import { withParam } from "../utils/Router.Helper";
import {
  GetClientById,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  GetTransactionByClient,
} from "../api/Api";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Modal,
  Autocomplete,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { mb } from "../Constants/Color";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EditIcon from "@mui/icons-material/Edit";
import { productTypeEnum } from "../Constants/ProductTypeEnum";
import ReactApexChart from "react-apexcharts";
import ClientTransaction from "./ClientTransaction";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { changeDate, isValidUsername } from "../utils/Helper";
import CloseIcon from "@mui/icons-material/Close";

const ClientById = (props: any) => {
  const ClientId = props.params.clientId;

  const [open, setOpen] = React.useState(false);
  const [nomineePancard, setNomineePancard] = useState<any>();
  const [cancelCheque, setCancelCheque] = useState<any>();
  const [profileImage, setProfileImage] = useState<any>();
  const [aadhaarCard, setAadhaarCard] = useState<any>();
  const [clientSignature, setClientSignature] = useState<any>();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValueDate] = React.useState<Dayjs | null | any>(
    dayjs("2022-04-07")
  );
  const registerOptions: any = {
    // companyCode : { required: "Select Distributor Name is required" },
    fullName: {
      required: "Full Name required",
      validate: (value: string) => {
        // const alphanumericRegex =
        //   /^[A-Za-z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
        const alphanumericRegex = /^[a-z0-9_.]+$/;

        if (!alphanumericRegex.test(value)) {
          return "Only alphanumeric are allowed here!";
        }
        return true; // Indicates validation passed
      },
    },
    mobileNo: { required: "Mobile No is required" },
    emailId: { required: "Email Id is required" },
    // aadhaarCardNo: { required: "Aadhar Card No is required" },
    pancard: { required: "Pan Card No is required" },
    addressL1: { required: "Address Line 1 is required" },
    addressL2: { required: "Address Line 2 is required" },
    // addressL3: { required: "Address Line 3 is required" },
    city: { required: "City is required" },
    pincode: { required: "Pin Code is required" },
    country: { required: "Country is required" },
    state: { required: "State is required" },
    clientcode: { required: "Client Code is required" },
    // userrole : { required: "Full Name is required" },
    // companyName : { required: "Company Name is required" },
    bankName: { required: "Bank Name is required" },
    // branchName : { required: "Branch Name is required" },
    accountNo: {
      required: "Account No is required",
      validate: (value: string) => {
        const accountNoRegex = /^[0-9]{8,30}$/;
        if (!accountNoRegex.test(value)) {
          return "Account No. should be between 8 to 30 digits (only numbers)";
        }
        return true;
      },
      // valueAsNumber: true,
    },
    ifscCode: {
      required: "IFSC Code is required",
      validate: (value: string) => {
        const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        if (!ifscCodeRegex.test(value)) {
          return "IFSC Code should be 11 characters long, first four characters should be uppercase alphabets, fifth character should be 0, last 6 characters should be numbers but can also have alphabets";
        }
        return true;
      },
    },
    dpName: { required: "Dp Name is required" },
    // dpId : { required: "Dp Id is required" },
    // rmName : { required: "Rm Name is required" },
    // rmBranch : { required: "Rm Branch is required" },
    // rmBranchCode : { required: "Rm Branch Code is required" },
    // rmEmpCode : { required: "Rm Employee Code is required" },
    // distributorEmployeeCode : { required: "Distributor Employee Code is required" },
    // residentialStatus : { required: "Select User Role" },
    // availabilityDate : { required: "availabilityDate is required" },
  };
  const resolver = async (values: any) => {
    // const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    // const mobValidation = /^(\+\d{1,3}[- ]?)?\d{10}$/;

    return {
      values: !values.city ? {} : values,
      // errors: !values.city ? {} : {},
      errors: !values.fullName
        ? {
            fullName: {
              type: "required",
              message: "This is required",
            },
          }
        : // : !alphanumericRegex.test(values.fullName)
        !isValidUsername(values.fullName)
        ? {
            fullName: {
              type: "required",
              message:
                "Only letters, numbers, special characters(not more than 3) are allowed!",
            },
          }
        : !values.city
        ? {
            city: {
              type: "required",
              message: "This is required",
            },
          }
        : !values.addressLine1
        ? {
            addressLine1: {
              type: "required",
              message: "This is required",
            },
          }
        : !values.emailId
        ? {
            emailId: {
              type: "required",
              message: "This is required",
            },
          }
        : !values.panCardNo
        ? {
            panCardNo: {
              type: "required",
              message: "This is required",
            },
          }
        : !values.mobileNo
        ? {
            mobileNo: {
              type: "required",
              message: "This is required",
            },
          }
        : // : !mobValidation.test(values.mobileNo)
        // ? {
        //     mobileNo: {
        //       type: "required",
        //       message: "Invalid Format!",
        //     },
        //   }
        !values.bankName
        ? {
            bankName: {
              type: "required",
              message: "This is required",
            },
          }
        : !values.branchName
        ? {
            branchName: {
              type: "required",
              message: "This is required",
            },
          }
        : values.accountNo === ""
        ? {
            accountNo: {
              type: "required",
              message: "This is required",
            },
          }
        : !/^[0-9]{8,30}$/.test(values.accountNo)
        ? {
            accountNo: {
              type: "required",
              message:
                "Account No. should be between 8 to 30 digits (only numbers)",
            },
          }
        : values.ifscCode === ""
        ? {
            ifscCode: {
              type: "required",
              message: "This is required",
            },
          }
        : !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(values.ifscCode)
        ? {
            ifscCode: {
              type: "required",
              message:
                "IFSC Code should be 11 characters long, first four characters should be uppercase alphabets, fifth character should be 0, last 6 characters should be numbers but can also have alphabets",
            },
          }
        : {},
    };
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: resolver,
  });

  const onSubmit = (dataa: any) => {
    const formData: any = new FormData();
    formData.append("panCardDocument", nomineePancard);
    formData.append("cancelCheque", cancelCheque);
    formData.append("clientMasterList", clientSignature);
    formData.append("profileImage", profileImage);
    formData.append("aadhaarCardDocument", aadhaarCard);
    formData.append(
      "clientDto",
      JSON.stringify({
        ...dataa,
        clientId: data.data.clients[0].clientId,
        dateOfBirth: changeDate(value),
        // distributorEmployeeCode
      })
    );
    axios
      .put("client/updateClient", formData)
      .then(() => toast.success("Client Updated Successfully"))
      .then(() => {
        setOpen(false);
        refetch();
      })
      .catch((err: any) =>
        toast.error(
          err.response.data ? err.response.data.status.message.title : "Error"
        )
      );
  };

  const { data, refetch } = useQuery(
    "ClientById",
    () => GetClientById(ClientId),
    {
      onSuccess(data) {
        setValueDate(data.data.clients[0]?.dateOfBirth);
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", data && data.data.clients[0].companyMasterCode],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        data && data.data.clients[0].companyTypeCode,
        data && data.data.clients[0].companyMasterCode
      ),
    {
      enabled: !!data,
      staleTime: Infinity,
    }
  );

  const { data: allTransactionData } = useQuery(
    "AllTransactionByClient",
    () => GetTransactionByClient(ClientId, 0),
    {
      staleTime: Infinity,
    }
  );

  const totalNoOfTransaction =
    allTransactionData && allTransactionData.data?.tradeList.length;

  let totalAssets: any = 0;
  const sum =
    allTransactionData &&
    allTransactionData.data?.tradeList.map((item: any) => {
      totalAssets += item.totalTransactionValue;
    });

  const productWiseCount: any = {};
  const tradeDateWiseProduct: any = {};
  const tradesByProductType: any = {};

  allTransactionData &&
    allTransactionData.data?.tradeList?.forEach((trade: any) => {
      const codeToProductName: any = {};
      for (const [name, code] of Object.entries(productTypeEnum)) {
        codeToProductName[code] = name;
      }

      if (!tradesByProductType[codeToProductName[trade?.productTypeCode]]) {
        tradesByProductType[codeToProductName[trade?.productTypeCode]] = [];
      }

      tradesByProductType[codeToProductName[trade?.productTypeCode]].push(
        trade
      );

      const productWiseData: any = codeToProductName[trade?.productTypeCode];
      if (productWiseCount[productWiseData]) {
        productWiseCount[productWiseData]++;
      } else {
        productWiseCount[productWiseData] = 1;
      }

      const tradeDate = trade?.updatedAt?.split("T")[0];
      if (tradeDate) {
        if (tradeDateWiseProduct[tradeDate]) {
          tradeDateWiseProduct[tradeDate]++;
        } else {
          tradeDateWiseProduct[tradeDate] = 1;
        }
      }
    });

  const clientBarData: any = {
    series: Object.entries(productWiseCount).map((i: any) => {
      return ((i[1] / allTransactionData.data?.tradeList.length) * 100).toFixed(
        2
      );
    }),
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "18px",
            },
            value: {
              fontSize: "16px",
            },
            track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: "#f2f2f2",
              strokeWidth: "97%",
              opacity: 1,
              margin: 5,
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            stroke: {
              show: true,
              curve: "smooth",
              lineCap: "butt",
              colors: undefined,
              width: 2,
              dashArray: 0,
            },
            total: {
              show: true,
              label: "Total",
              formatter: function () {
                return parseFloat(totalAssets).toLocaleString("en-IN");
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "bottom",
        formatter: function (seriesName: any, opts: any) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      labels: Object.entries(productWiseCount).map((i) =>
        i[0].replaceAll("_", " ")
      ),
      stroke: {
        lineCap: "round",
      },
    },
  };

  const tradeData: any = {
    series: [
      {
        name: "Transaction",
        colors: ["#2E93fA"],
        data: Object.entries(tradeDateWiseProduct).map((i: any) => {
          return i[1];
        }),
      },
    ],
    options: {
      chart: {
        height: 350,
        // width:800,
        type: "bar",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      grid: {
        show: false, // Hide the grid
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: Object.entries(tradeDateWiseProduct).map((i: any) => {
          return i[0];
        }),
      },
      tooltip: {
        x: {
          format: "dd - MMM - yy",
        },
      },
    },
  };

  const productList: any = Object?.keys(tradesByProductType) || [];

  const productWiseTradeData: any = {
    series: productList.map((productWiseTrade: any, index: any) => {
      const seriesDto: any = {
        name: Object?.keys(tradesByProductType)[index]?.replaceAll("_", " "),
        data: Object.entries(tradesByProductType)
          .map((i: any) => {
            return i[1];
          })
          .map((data: any) => data.map((d: any) => d.totalPrice))[index],
      };

      return seriesDto;
    }),
    options: {
      chart: {
        height: 400,
        // width:800,
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      grid: {
        show: false, // Hide the grid
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: Object.entries(tradeDateWiseProduct).map((i: any) => {
          return i[0];
        }),
      },
      tooltip: {
        x: {
          format: "dd - MMM - yy",
        },
      },
      //     responsive: [
      //       {
      //       breakpoint: 2560,
      //       options: {
      //         chart: {
      //           width:1600,
      //         },
      //       },
      //   },
      //       {
      //       breakpoint: 1441,
      //       options: {
      //         chart: {
      //           width:800,
      //         },
      //       },
      //   },
      //       {
      //       breakpoint: 1536,
      //       options: {
      //         chart: {
      //           width:750,
      //         },
      //       },
      //   },
      //   {
      //     breakpoint: 1025,
      //     options: {
      //       chart: {
      //         width:450,
      //       },
      //     },
      // },
      //   {
      //     breakpoint: 769,
      //     options: {
      //       chart: {
      //         width:270,
      //       },
      //     },
      // },
      //   {
      //     breakpoint: 320,
      //     options: {
      //       chart: {
      //         width:100,
      //       },
      //     },
      // },
      // ]
    },
  };

  return (
    <>
      {data && (
        <Box
          sx={{
            paddingTop: 2,
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
        >
          {data &&
            data.data.clients.map((item: any) => (
              <>
                <Box sx={{ ml: 2 }}>
                  <Box sx={{ mb: 2 }}>
                    <Grid container sx={{ justifyContent: "space-between" }}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                          variant="h4"
                          textAlign={"left"}
                          gutterBottom
                        >
                          Client Dashboard
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Grid container sx={{ justifyContent: "flex-end" }}>
                          <Grid item>
                            <Typography variant="subtitle2" textAlign={"right"}>
                              {" "}
                              {item.fullName}
                            </Typography>
                            <Typography variant="subtitle2" textAlign={"right"}>
                              {item.panCardNo}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <IconButton
                              type="button"
                              sx={{ p: "5px", pl: 1 }}
                              aria-label="Edit"
                              onClick={handleOpen}
                            >
                              <EditIcon fontSize="medium" color={"primary"} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Paper sx={{ p: 1 }}>
                          <Grid container columnSpacing={2}>
                            <Grid
                              item
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <AccountBalanceIcon
                                fontSize="large"
                                color={"info"}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="h4" textAlign={"left"}>
                                {allTransactionData ? totalNoOfTransaction : 0}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                textAlign={"left"}
                              >
                                Transaction
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Paper sx={{ p: 1 }}>
                          <Grid container columnSpacing={2}>
                            <Grid
                              item
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <CurrencyRupeeIcon
                                fontSize="large"
                                color={"warning"}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="h4" textAlign={"left"}>
                                {allTransactionData &&
                                  parseFloat(totalAssets).toLocaleString(
                                    "en-IN"
                                  )}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                textAlign={"left"}
                              >
                                Assets
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="h4" textAlign={"left"} gutterBottom>
                        Transaction
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Paper
                        elevation={4}
                        sx={{
                          borderRadius: "20px",
                          padding: "5px",
                          height: 360,
                        }}
                      >
                        <ReactApexChart
                          options={clientBarData?.options}
                          series={clientBarData?.series}
                          type="radialBar"
                          height={clientBarData?.options?.chart?.height}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={8}>
                      <Paper
                        elevation={4}
                        sx={{
                          borderRadius: "20px",
                          padding: "10px",
                          height: 360,
                        }}
                      >
                        <ReactApexChart
                          options={tradeData?.options}
                          series={tradeData?.series}
                          type="bar"
                          height={tradeData?.options?.chart?.height}
                        />
                      </Paper>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper
                  elevation={4}
                  sx={{ borderRadius: "20px", padding: "10px", height:360 }}
                  >
                    <ReactApexChart options={productWiseTradeData?.options} series={productWiseTradeData?.series} type="area" height={productWiseTradeData?.options?.chart?.height}/>
                  </Paper>
                </Grid> */}
                  </Grid>
                </Box>

                <ClientTransaction
                  transactionData={tradesByProductType}
                  clientId={ClientId}
                />

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <IconButton
                      style={{
                        position: "absolute",
                        fontSize: "18px",
                        top: "8px",
                        right: "5px",
                      }}
                      onClick={() => handleClose()}
                    >
                      <CloseIcon style={{ fontSize: "18px" }} />
                    </IconButton>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      gutterBottom
                    >
                      Edit Client Details
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          {UserProfilesByCompanyMasterCode && (
                            <Autocomplete
                              options={
                                UserProfilesByCompanyMasterCode
                                  ? UserProfilesByCompanyMasterCode.data
                                      .userProfile
                                  : []
                              }
                              getOptionLabel={(option: any) =>
                                option.employeeCode
                              }
                              defaultValue={
                                UserProfilesByCompanyMasterCode.data.userProfile.find(
                                  (option: any) =>
                                    option.employeeCode ===
                                    item.distributorEmployeeCode
                                ) || null
                              }
                              onChange={(event: any, value: any) => {
                                setValue(
                                  "distributorEmployeeCode",
                                  !!value ? value.employeeCode : ""
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Distributor"
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Full Name*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.fullName.trim()}
                            error={errors.fullName ? true : false}
                            helperText={
                              errors.fullName === undefined
                                ? ""
                                : errors.fullName.message + ""
                            }
                            {...register("fullName", registerOptions.fullName)}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="City*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.city}
                            error={errors.city ? true : false}
                            helperText={
                              errors.city === undefined
                                ? ""
                                : errors.city.message + ""
                            }
                            {...register("city", registerOptions.city)}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Address 1*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.addressLine1}
                            error={errors.addressLine1 ? true : false}
                            helperText={
                              errors.addressLine1 === undefined
                                ? ""
                                : errors.addressLine1.message + ""
                            }
                            {...register(
                              "addressLine1",
                              registerOptions.addressL1
                            )}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Address 2"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.addressLine2}
                            {...register(
                              "addressLine2",
                              registerOptions.addressL2
                            )}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Address 3"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.addressLine3}
                            {...register("addressLine3")}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={1} lg={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                              label="Date of Birth"
                              inputFormat="DD/MM/YYYY"
                              value={value}
                              onChange={(newValue: any) => {
                                setValueDate(newValue.toJSON());
                                setValue("dateOfBirth", newValue?.toJSON());
                              }}
                              renderInput={(params: any) => (
                                <TextField {...params} sx={{ width: "100%" }} />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Email Id*"
                            variant="outlined"
                            type="email"
                            fullWidth
                            defaultValue={item.emailId}
                            error={errors.emailId ? true : false}
                            helperText={
                              errors.emailId === undefined
                                ? ""
                                : errors.emailId.message + ""
                            }
                            {...register("emailId", registerOptions.emailId)}
                            sx={{ mb: mb }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="PAN Card No*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.panCardNo}
                            error={errors.panCardNo ? true : false}
                            helperText={
                              errors.panCardNo === undefined
                                ? ""
                                : errors.panCardNo.message + ""
                            }
                            {...register("panCardNo", registerOptions.pancard)}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Aadhaar Card No"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.aadhaarCardNo}
                            {...register(
                              "aadhaarCardNo",
                              registerOptions.aadhaarCardNo
                            )}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Mobile No*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.mobileNo}
                            error={errors.mobileNo ? true : false}
                            helperText={
                              errors.mobileNo === undefined
                                ? ""
                                : errors.mobileNo.message + ""
                            }
                            {...register("mobileNo", registerOptions.mobileNo)}
                            sx={{ mb: mb }}
                          />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Bank Name*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.bankName}
                            error={errors.bankName ? true : false}
                            helperText={
                              errors.bankName === undefined
                                ? ""
                                : errors.bankName.message + ""
                            }
                            {...register("bankName", registerOptions.bankName)}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Branch Name*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.branchName}
                            error={errors.branchName ? true : false}
                            helperText={
                              errors.branchName === undefined
                                ? ""
                                : errors.branchName.message + ""
                            }
                            {...register("branchName")}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Account No*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.accountNo}
                            error={errors.accountNo ? true : false}
                            helperText={
                              errors.accountNo === undefined
                                ? ""
                                : errors.accountNo.message + ""
                            }
                            {...register(
                              "accountNo",
                              registerOptions.accountNo
                            )}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="IFSC Code*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.ifscCode}
                            error={errors.ifscCode ? true : false}
                            helperText={
                              errors.ifscCode === undefined
                                ? ""
                                : errors.ifscCode.message + ""
                            }
                            {...register("ifscCode", registerOptions.ifscCode)}
                            sx={{ mb: mb }}
                          />
                        </Grid> */}

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Client Code"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.clientCode}
                            {...register(
                              "clientCode",
                              registerOptions.clientCode
                            )}
                            sx={{ mb: mb }}
                          />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="DP Id"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.dpId}
                            {...register("dpId")}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="DP Name"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.dpName}
                            {...register("dpName", registerOptions.dpName)}
                            sx={{ mb: mb }}
                          />
                        </Grid> */}

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Country"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.country}
                            {...register("country", registerOptions.country)}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="State"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.state}
                            {...register("state", registerOptions.state)}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Pin Code"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.pinCode}
                            {...register("pinCode", registerOptions.pincode)}
                            sx={{ mb: mb }}
                          />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="RM Name"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.rmName}
                            {...register("rmName")}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="RM Employee Code"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.rmEmpCode}
                            {...register("rmEmpCode")}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="RM Branch"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.rmBranch}
                            {...register("rmBranch")}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="RM Branch Code"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.rmBranchCode}
                            {...register("rmBranchCode")}
                            sx={{ mb: mb }}
                          />
                        </Grid> */}
                      </Grid>

                      {/* BANK DETAILS */}
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        gutterBottom
                      >
                        Edit Bank Details
                      </Typography>
                      <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Bank Name*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.bankName}
                            error={errors.bankName ? true : false}
                            helperText={
                              errors.bankName === undefined
                                ? ""
                                : errors.bankName.message + ""
                            }
                            {...register("bankName", registerOptions.bankName)}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Branch Name*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.branchName}
                            error={errors.branchName ? true : false}
                            helperText={
                              errors.branchName === undefined
                                ? ""
                                : errors.branchName.message + ""
                            }
                            {...register("branchName")}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="Account No*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.accountNo}
                            error={errors.accountNo ? true : false}
                            helperText={
                              errors.accountNo === undefined
                                ? ""
                                : errors.accountNo.message + ""
                            }
                            {...register(
                              "accountNo",
                              registerOptions.accountNo
                            )}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="IFSC Code*"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.ifscCode}
                            error={errors.ifscCode ? true : false}
                            helperText={
                              errors.ifscCode === undefined
                                ? ""
                                : errors.ifscCode.message + ""
                            }
                            {...register("ifscCode", registerOptions.ifscCode)}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                      </Grid>

                      {/* DP DETAILS */}
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        gutterBottom
                      >
                        DP Details
                      </Typography>
                      <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="DP Name"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.dpName}
                            {...register("dpName", registerOptions.dpName)}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="DP Id"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.dpId}
                            {...register("dpId")}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="RM Name"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.rmName}
                            {...register("rmName")}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="RM Employee Code"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.rmEmpCode}
                            {...register("rmEmpCode")}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="RM Branch"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.rmBranch}
                            {...register("rmBranch")}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="RM Branch Code"
                            variant="outlined"
                            fullWidth
                            defaultValue={item.rmBranchCode}
                            {...register("rmBranchCode")}
                            sx={{ mb: mb }}
                          />
                        </Grid>
                      </Grid>

                      <Typography
                        variant="h5"
                        sx={{ marginBottom: 3, fontWeight: "500" }}
                      >
                        Document Upload
                      </Typography>
                      <Stack spacing={3} direction="column" mb={3}>
                        <Box
                          sx={{
                            display: "flex",
                            // justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            // p: 2,
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{ p: 1 }}
                          >
                            {/* <Typography variant="subtitle1"> */} Upload PAN
                            Card: {/* </Typography> */}
                            <input
                              hidden
                              type="file"
                              accept=".pdf"
                              name="nomineePancard"
                              onChange={(event: any) =>
                                setNomineePancard(event.target.files[0])
                              }
                            />
                          </Button>
                          <Typography variant="subtitle1">
                            {!!!nomineePancard
                              ? " No file choosen"
                              : nomineePancard?.name}{" "}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            // justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            // p: 2,
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{ p: 1 }}
                          >
                            {/* <Typography variant="subtitle1"> */}
                            Upload Cancel Cheque:
                            {/* </Typography> */}
                            <input
                              hidden
                              type="file"
                              accept=".pdf"
                              name="cancelCheque"
                              onChange={(event: any) =>
                                setCancelCheque(event.target.files[0])
                              }
                            />
                          </Button>
                          <Typography variant="subtitle1">
                            {!!!cancelCheque
                              ? " No file choosen"
                              : cancelCheque?.name}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            // justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            // p: 2,
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{ p: 1 }}
                          >
                            {/* <Typography variant="subtitle1"> */}
                            Upload Client Master List:
                            {/* </Typography> */}
                            <input
                              hidden
                              type="file"
                              accept=".pdf"
                              name="Client Signature"
                              onChange={(event: any) =>
                                setClientSignature(event.target.files[0])
                              }
                            />
                          </Button>
                          <Typography variant="subtitle1">
                            {!!!clientSignature
                              ? " No file choosen"
                              : clientSignature?.name}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            // justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            // p: 2,
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{ p: 1 }}
                          >
                            {/* <Typography variant="subtitle1"> */}
                            Upload Client Profile Image:
                            {/* </Typography> */}
                            <input
                              hidden
                              type="file"
                              accept=".jpg,.jpeg,.png"
                              name="profileImage"
                              onChange={(event: any) =>
                                setProfileImage(event.target.files[0])
                              }
                            />
                          </Button>
                          <Typography variant="subtitle1">
                            {!!!profileImage
                              ? " No file choosen"
                              : profileImage?.name}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            // justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            // p: 2,
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{ p: 1 }}
                          >
                            {/* <Typography variant="subtitle1"> */}
                            Upload Client Aadhaar Card:
                            {/* </Typography> */}
                            <input
                              hidden
                              type="file"
                              accept=".jpg,.jpeg,.png,.pdf"
                              name="aadhaarCard"
                              onChange={(event: any) =>
                                setAadhaarCard(event.target.files[0])
                              }
                            />
                          </Button>
                          <Typography variant="subtitle1">
                            {!!!aadhaarCard
                              ? " No file choosen"
                              : aadhaarCard?.name}
                          </Typography>
                        </Box>
                      </Stack>
                      {/* </Box> */}
                      <br />
                      <Grid style={{ textAlign: "center" }}>
                        <Button type="submit" variant="contained">
                          Submit
                        </Button>
                      </Grid>
                    </form>
                  </Box>
                </Modal>
              </>
            ))}
        </Box>
      )}
    </>
  );
};
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "80%",
  overflowY: "auto",
};

export default withParam(ClientById);
