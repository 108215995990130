import { Autocomplete, Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import { useQuery } from 'react-query';
import { GetAllClient } from '../api/Api';

const RequestForQuote = () => {

    const { data } = useQuery("clientListApi",()=>GetAllClient());
    console.log("Clients", data)
    
  return (
    <Box sx={{marginTop:'10%'}}>
        <Container>
      <Box textAlign={"center"} mt={3}>
        <Typography variant="h5">
          Please enter the details of the bond you want to sell
        </Typography>
      </Box>

      <Paper elevation={3} sx={{ marginTop: "30px", marginBottom: "30px" }}>
        {/* <Button
          sx={{ marginLeft: "12%", marginTop: "1%" }}
          variant="outlined"
        >
          Add Client
        </Button> */}
        <Grid container>
          <Grid item lg={6} md={6} textAlign="center">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={data && data.data.clients.map((it:any) => (it.firstName))}
            //   getOptionLabel={(options: any) => options.firstName}
              onChange={(event: any, newValue: string | null) => { 
                // setClient(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Client Name"
                  sx={{ marginTop: "40px", width: 300 }}
                  size="small"
                />
              )}
            />

            <TextField
              id="outlined-required"
              label="ISIN"
              sx={{ marginTop: "40px", width: 300 }}
              size="small"
            //   onChange={(event) => (setIsin(event.target.value))} 
            />
            

            <TextField
              required
              id="outlined-required"
              label="Sell Value"
              sx={{ marginTop: "40px", width: 300 }}
              size="small"
              type={'number'}
            //   onChange={(event) => (setSellValue(parseInt(event.target.value)))}
            />

            <TextField
              id="outlined-required"
              label="Indicative Price"
              sx={{ marginTop: "40px", width: 300 }}
              size="small"
              type={'number'}
            //   onChange={(event) => (setIndicativePrice(parseInt(event.target.value)))}
            />

            <TextField
              id="outlined-required"
              label="Indicative Yeild (%)"
              sx={{ marginTop: "40px", width: 300, marginBottom: "40px" }}
              size="small"
              type={'number'}
            //   onChange={(event) => (setIndicativeYeild(parseInt(event.target.value)))}
            />

          </Grid>

          <Grid item lg={4} md={6} textAlign="center">
            <Box textAlign="left" mt={5}>
              <Typography variant="subtitle1">Issuer : - </Typography>
              <Typography variant="subtitle1" mt={2}>
                Coupon : -{" "}
              </Typography>
              <Typography variant="subtitle1" mt={2}>
                Maturity : -{" "}
              </Typography>
            </Box>
            <Button
              href="/sellbondtable"
              variant="outlined"
              sx={{
                padding: "1%",
                width: 300,
                backgroundColor: "#f4f4f4",
                marginTop: "210px",
              }}
              size="small"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
      
    </Container>
    </Box>
  )
}

export default RequestForQuote