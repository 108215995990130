export const MeetingTypeEnum = [
	{Label:"On Call", value:"ON_CALL"},
	{Label:"On Video Call", value:"ON_VIDEO_CALL"},
	{Label:"On Email", value:"EMAIL"},
	{Label:"Office Visit", value:"OFFICE_VISIT"},
	{Label:"Home Visit", value:"HOME_VISIT"},
]

export const statusTypeEnum = [
	{ Label: "Pending", value: "REQUEST_RECEIVED" },
	{ Label: "Meeting Schedule", value: "MEETING_SCHEDULED" },
	{ Label: "Completed", value: "MEETING_DONE" },
]

export const ExistingTypeEnum = [
	{ Label: "Yes", value: "true" },
	{ Label: "No", value: "false" },
]