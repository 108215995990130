import { Box, Button, CircularProgress, Grid, Modal, Typography, Tooltip, IconButton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const ClientModalForOrder = (props: any) => {

	const zoomPluginInstance = zoomPlugin();
	const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const clientDetails = props?.clientDetails;
  const [open, setOpen] = React.useState(false);
  const handleOpenViewClient = () => setOpen(true);
  const handleCloseViewClient = () => setOpen(false);

  const [nomineePancard, setNomineePancard] = useState<any>()
  const [cancelCheque, setCancelCheque] = useState<any>()
  const [profileImage, setProfileImage] = useState<any>()
  const [aadhaarCard, setAadhaarCard] = useState<any>()
  const [clientSignature, setClientSignature] = useState<any>()
  const [clientFile, setClientFile] = useState<any>("");
  const [clientFilePreview, setClientFilePreview] = useState<any>("");
  const [clientFilePreviewName, setClientFilePreviewName] = useState<any>("");
  const [FileName, setFileName] = useState<any>("");
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [clientFileDocId, setClientFileDocId] = useState<any>("");

	  const onSubmit = () => {
      setshowCircularProgress(true);
		const formData:any = new FormData();
		formData.append("panCardDocument",nomineePancard)
		formData.append("cancelCheque",cancelCheque)
		formData.append("clientMasterList",clientSignature)
		formData.append("profileImage",profileImage)
		formData.append("aadhaarCardDocument",aadhaarCard)
		formData.append("clientDto",JSON.stringify(clientDetails))

		axios.put("client/updateClient",formData)
		.then(()=>toast.success("Client Update Successfully"))
		.then(()=>{ 
      setOpen(false);
      setshowCircularProgress(false);
      window.location.reload();
    })
		.catch((err:any) => { 
      toast.error(err?.response?.data?.status?.message?.title?err?.response?.data?.status?.message?.title:err?.response?.data?.title);
      setshowCircularProgress(false)
    })
	  };

  const viewDocument = (fileType:any) => {
  	clientDetails[fileType] ? axios.get("documentupload?documentId="+clientDetails[fileType]).then((res:any)=>{
          setClientFile(res?.data?.documentFilePath)
          setClientFileDocId(clientDetails[fileType]);
          console.log("DOCUMENTNAMEjdwodw",clientDetails[fileType])
        }) : setClientFile("")
        setClientFilePreview("")
  }

  const perViewDocument = (stateAsFile:any) => {
    setClientFilePreviewName(stateAsFile?.name)
    stateAsFile ? setClientFilePreview(URL.createObjectURL(stateAsFile)) : setClientFilePreview("")
    setClientFile("")
  }

  const downloadDocument = (documentId:any) => {
    if(documentId){
      axios.get("documentupload?documentId="+documentId).then((res:any)=>{
        axios({
          url: "documentupload/download?documentId="+documentId,
          method: "GET",
          responseType: "blob"
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link:any = document.createElement("a");
          link.href = url;
          link.setAttribute("download",res.data.documentFileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
      });
      })
    }
  }

  useEffect(()=>{
    return()=>{
      setNomineePancard("")
    }
  },[open])

  return (
    <>
      <Button variant="contained" onClick={handleOpenViewClient} size="small"> View Client </Button>
      <Modal
        open={open}
        onClose={handleCloseViewClient}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-scroll">
          <Typography variant="h6" textAlign={"center"} > {clientDetails.fullName} Details </Typography>
      		 <Grid container rowSpacing={2} columnSpacing={2}>
        		 <Grid item xs={12} sm={12} md={4} lg={4} >
        		 <Grid container rowSpacing={2} columnSpacing={2} sx={{mb:2}} justifyContent={"space-between"}>
        					<Grid item >
        						<Typography > Pan Card : </Typography>
        					</Grid>
        					<Grid item >
        						{ clientDetails.panCardDocument ? <Button variant="contained" onClick={()=>viewDocument("panCardDocument")} sx={{ml:1}}> View </Button>:<Button variant="contained" disabled sx={{ml:1}}>View</Button>}
        						<Button variant='contained' component="label" sx={{ml:1}}>
                        <input hidden type="file" accept='.pdf' name="nomineePancard" onChange={(event:any)=>setNomineePancard(event.target.files[0])}/>
                        { clientDetails.panCardDocument ? 
                            <Typography variant="button">{ !!!nomineePancard ? "Update File" : nomineePancard?.name } </Typography> : 
                            <Typography variant="button">{ !!!nomineePancard ? "Upload new File" : nomineePancard?.name } </Typography> }
                    </Button>
                    <Button variant="contained" onClick={()=>perViewDocument(nomineePancard)} sx={{ml:1}} disabled={!nomineePancard}> Preview </Button>
        					</Grid>
        				</Grid>
        				<Grid container rowSpacing={2} columnSpacing={2} sx={{mb:2}} justifyContent={"space-between"}>
        					<Grid item >
        						<Typography > Cancel Cheque : </Typography>
        					</Grid>
        					<Grid item >
        						{clientDetails.cancelCheque ? <Button variant="contained" onClick={()=>viewDocument("cancelCheque")}sx={{ml:1}} > View </Button>:<Button variant="contained" disabled sx={{ml:1}}>View</Button>}
        						  <Button variant='contained' component="label" sx={{ml:1}}>
                        <input hidden type="file" accept='.pdf' name="cancelCheque" onChange={(event:any)=>setCancelCheque(event.target.files[0])}/>
                        { clientDetails.cancelCheque ?
                        <Typography variant="button">{ !!!cancelCheque ? "Update File" : cancelCheque?.name }</Typography> : 
                        <Typography variant="button">{ !!!cancelCheque ? "Upload new File" : cancelCheque?.name }</Typography> }
                      </Button>
                    <Button variant="contained" onClick={()=>perViewDocument(cancelCheque)} sx={{ml:1}} disabled={!cancelCheque}> Preview </Button>
        					</Grid>
        					
        				</Grid>
        				<Grid container rowSpacing={2} columnSpacing={2} sx={{mb:2}} justifyContent={"space-between"}>
        					<Grid item >
        					<Typography > Client Master List : </Typography>
        					</Grid>
        					<Grid item >
                  { clientDetails.clientMasterList ? <Button variant="contained" onClick={()=>viewDocument("clientMasterList")}sx={{ml:1}} >View</Button>:<Button variant="contained" disabled sx={{ml:1}}>View</Button>}
        						<Button variant='contained' component="label" sx={{ml:1}}>
                                  <input hidden type="file" accept='.pdf' name="Client Signature" onChange={(event:any)=>setClientSignature(event.target.files[0])}/>
                                  { clientDetails.clientMasterList ? 
                                  <Typography variant="button">{ !!!clientSignature ? "Update File" : clientSignature?.name }</Typography> : 
                                  <Typography variant="button">{ !!!clientSignature ? "Upload new File" : clientSignature?.name }</Typography> }
                                </Button>
                                  <Button variant="contained" onClick={()=>perViewDocument(clientSignature)} sx={{ml:1}} disabled={!clientSignature}> Preview </Button>
        					</Grid>
        				</Grid>
        				<Grid container rowSpacing={2} columnSpacing={2} sx={{mb:2}} justifyContent={"space-between"}>
        					<Grid item >
        						<Typography > Profile Image : </Typography>
        					</Grid>
        					<Grid item >
                  { clientDetails.profileImage ? <Button variant="contained" onClick={()=>viewDocument("profileImage")} sx={{ml:1}} > View </Button>:<Button variant="contained" disabled sx={{ml:1}}>View</Button>}
        						<Button variant='contained' component="label" sx={{ml:1}}>
                                  <input hidden type="file" accept='.jpg,.jpeg,.png' name="profileImage" onChange={(event:any)=>setProfileImage(event.target.files[0])}/>
                                  { clientDetails.profileImage ? 
                                  <Typography variant="button">{ !!!profileImage ? "Update File" : profileImage?.name }</Typography> :
                                  <Typography variant="button">{ !!!profileImage ? "Upload new File" : profileImage?.name }</Typography> }
                                </Button>
                                  <Button variant="contained" onClick={()=>perViewDocument(profileImage)} sx={{ml:1}} disabled={!profileImage}> Preview </Button>
        					</Grid>

        				</Grid>
        				<Grid container rowSpacing={2} columnSpacing={2} sx={{mb:2}} justifyContent={"space-between"}>
        					<Grid item >
        						<Typography > Aadhaar Card Document : </Typography>
        					</Grid>
        					<Grid item >
                  { clientDetails.aadhaarCardDocument ? <Button variant="contained" onClick={()=>viewDocument("aadhaarCardDocument")}sx={{ml:1}} > View </Button> : <Button variant="contained" disabled sx={{ml:1}}> View </Button>}
        						<Button variant="contained" component="label" sx={{ml:1}}> 
                                  <input hidden type="file" accept='.jpg,.jpeg,.png,.pdf' name="aadhaarCard" onChange={(event:any)=>setAadhaarCard(event.target.files[0])}/>
                    { clientDetails.aadhaarCardDocument ? 
                                  <Typography variant="button">{ !!!aadhaarCard ? "Update File" : aadhaarCard?.name }</Typography>  : 
                                  <Typography variant="button">{ !!!aadhaarCard ? "Upload new File" : aadhaarCard?.name }</Typography> }
        						</Button>
                                  <Button variant="contained" onClick={()=>perViewDocument(aadhaarCard)} sx={{ml:1}} disabled={!aadhaarCard}> Preview </Button>
        					</Grid>
        				
        				</Grid>
        		 </Grid>
      		 <Grid item xs={12} sm={12} md={8} lg={8} >

      		 { !!clientFile ? 
           clientFile.includes(".pdf") ? 
           <div
          className="rpv-core__viewer"
          style={{
              border: '1px solid rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              height: '450px',
              width: '100%',
          }}
      >
          <div
              style={{
                  alignItems: 'center',
                  backgroundColor: '#eeeeee',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '4px',
              }}
          >
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
              <Tooltip title="Download">
                <IconButton>
                  <CloudDownloadIcon onClick={()=>downloadDocument(clientFileDocId)}/>
                </IconButton>
              </Tooltip>
          </div>
          <div
              style={{
                  flex: 1,
                  overflow: 'hidden',
              }}
          >
               <Viewer fileUrl={clientFile} plugins={[zoomPluginInstance]} /> 
      			</div>
      			</div> : 
            <div
            className="rpv-core__viewer"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '450px',
                width: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '4px',
                }}
            >
            </div>
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            >
                 <img src={clientFile} alt="image"  width={"100%"}/> 
              </div>
              </div>
            : 
      			<></> }


{ !!clientFilePreview ? 
           clientFilePreviewName.includes(".pdf") ? 
           <div
          className="rpv-core__viewer"
          style={{
              border: '1px solid rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              height: '450px',
              width: '100%',
          }}
      >
          <div
              style={{
                  alignItems: 'center',
                  backgroundColor: '#eeeeee',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '4px',
              }}
          >
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
          </div>
          <div
              style={{
                  flex: 1,
                  overflow: 'hidden',
              }}
          >
               <Viewer fileUrl={clientFilePreview} plugins={[zoomPluginInstance]} /> 
      			</div>
      			</div> : 
            <div
            className="rpv-core__viewer"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '450px',
                width: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '4px',
                }}
            >
            </div>
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            >
                 <img src={clientFilePreview} alt="image"  width={"100%"}/> 
              </div>
              </div>
            : 
      			<></> }
      		 </Grid>
      		 </Grid>
		
		<Button onClick={onSubmit} variant="contained"  disabled={showCircularProgress}> 
    {showCircularProgress === true ? (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      thickness={5}
                    />
                  ) : (
                    ""
                  )}{" "}
    Upload Document </Button>

        </Box>
      </Modal>
    </>
  );
};
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "80%",
  overflowY: "auto",
};

export default ClientModalForOrder;
