import React from "react";
import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import ClientUnlistedTransaction from "./ClientTransaction/ClientUnlistedTransaction";
import ClientBondTransaction from "./ClientTransaction/ClientBondTransaction";
import ClientAIFTransaction from "./ClientTransaction/ClientAIFTransaction";
import ClientPMSTransaction from "./ClientTransaction/ClientPMSTransaction";
import { Dayjs } from "dayjs";
import ClientRSTransaction from "./ClientTransaction/ClientRSTransaction";
import ClientNCDTransaction from "./ClientTransaction/ClientNCDTransaction";

interface TabPanelProps {
  children?: React.ReactNode;
  index: Number;
  value: Number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ClientTransaction = (props: any) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [fromDate, setFromDate] = React.useState<Dayjs | null | any>(
    new Date(new Date().setMonth(new Date().getMonth() - 3)).toJSON()
  );
  const [toDate, setToDate] = React.useState<Dayjs | null | any>(
    new Date().toJSON()
  );
  return (
    <>
      <Paper elevation={0} sx={{ borderRadius: "10px", mt: 13 }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab label="Unlisted" {...a11yProps(0)} />
              <Tab label="PMS" {...a11yProps(1)} />
              <Tab label="AIF" {...a11yProps(2)} />
              {sessionStorage.getItem("Profile") !== "D" && (
                <Tab label="Fixed Income" {...a11yProps(3)} />
              )}
              <Tab label="Research Service" {...a11yProps(4)} />
              <Tab label="NCD" {...a11yProps(5)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ClientUnlistedTransaction
              data={props?.transactionData?.UNLISTED}
              toDate={toDate}
              fromDate={fromDate}
              clientId={props?.clientId}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ClientPMSTransaction
              data={props?.transactionData?.PORTFOLIO_MANAGEMENT_SYSTEM}
              clientId={props?.clientId}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ClientAIFTransaction
              data={props?.transactionData?.ALTERNATIVE_INVESTMENT_FUND}
              clientId={props?.clientId}
            />
          </TabPanel>
          {sessionStorage.getItem("Profile") !== "D" ? (
            <>
              <TabPanel value={value} index={3}>
                <ClientBondTransaction
                  data={props?.transactionData?.BOND_WORK}
                  clientId={props?.clientId}
                />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <ClientRSTransaction
                  data={props?.transactionData?.RESEARCH_SERVICES}
                  clientId={props?.clientId}
                />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <ClientNCDTransaction
                  data={props?.transactionData?.NON_CONVERTIBLE_DEBENTURES}
                  clientId={props?.clientId}
                />
              </TabPanel>
            </>
          ) : (
            <>
              <TabPanel value={value} index={3}>
                <ClientRSTransaction
                  data={props?.transactionData?.RESEARCH_SERVICES}
                  clientId={props?.clientId}
                />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <ClientNCDTransaction
                  data={props?.transactionData?.NON_CONVERTIBLE_DEBENTURES}
                  clientId={props?.clientId}
                />
              </TabPanel>
            </>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default ClientTransaction;
