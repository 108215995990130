import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loading = () => {
  return (
	<Box sx={{
		position: "fixed",
		top: "50%",
		left: "50%",
	}}>
		<CircularProgress/>
	</Box>
  )
}

export default Loading