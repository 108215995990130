import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'

const MutualFundPayout = () => {
    const[mutualFundPayout , setmutualFundPayout] = useState<any>();

    useEffect(()=>{
        axios.get("payout?productTypeCode=2").then((res:any)=>{
            setmutualFundPayout(res.data.payoutDTOList);
        })
    },[])

  return (
    <div>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Order Id</TableCell>
            <TableCell align="right">Client Name</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Share per Price</TableCell>
            <TableCell align="right">Total Price</TableCell>
            <TableCell align="right">Payout amount</TableCell>
            <TableCell align="right">Payout Given</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mutualFundPayout && mutualFundPayout.map((item:any) => (
            <TableRow
              key={item.payoutId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item.orderId}
              </TableCell>
              <TableCell align="right">{item.clientName}</TableCell>
              <TableCell align="right">{item.quantity}</TableCell>
              <TableCell align="right">{item.sharePerPrice}</TableCell>
              <TableCell align="right">{item.totalPrice}</TableCell>
              <TableCell align="right">{item.payoutAmount}</TableCell>
              <TableCell align="right">{item.payoutGiven ? <>Yes</> : <>No</>}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}

export default MutualFundPayout