import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { product_listOfAllProductType } from "../../api/Api";
import OrderWindowForAIF from "./OrderWindowForAIF";
import OrderWindowForPMS from "./OrderWindowForPMS";
import { productTypeEnum } from "../../Constants/ProductTypeEnum";
import OrderWindowForUnlisted from "./OrderWindowForUnlisted";
import OrderWindowForMF from "./OrderWindowForMF";
import OrderWindowForResearchServices from "./OrderWindowForResearchServices";
import OrderWindowForNCD from "./OrderWindowForNCD";
import CloseIcon from "@mui/icons-material/Close";
import OrderWindowForLoan from "./OrderWindowForLoan";
import FixedIncomeOrde from "../FixedIncomeOrde";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  "& .MuiTextField-root": { m: 1 },
  overflowY: "auto",
  height: "90%",
};

interface propsType {
  variant?: "text" | "outlined" | "contained";
  sx?: any;
}

const OrderNow = (props: propsType) => {
  const { variant = "outlined" } = props;
  const { sx } = props;

  const [open, setOpen] = React.useState(false);
  const [productType, setProductType] = React.useState<any>("");

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const { data: productData } = useQuery(
    "ProductDataForOrderWindow",
    product_listOfAllProductType,
    {
      staleTime: Infinity,
    }
  );
  // set this API response in the session storage and use it in the OrderWindowForUnlisted.tsx file
  sessionStorage.setItem(
    "listOfAllProducts",
    JSON.stringify(productData?.data?.productType)
  );

  let ConditionWiseComponent: any;
  switch (productType.productTypeCode) {
    case productTypeEnum.MUTUAL_FUND:
      ConditionWiseComponent = <OrderWindowForMF />;
      break;
    case productTypeEnum.FIXED_INCOME:
      ConditionWiseComponent = <FixedIncomeOrde />;
      break;
    case productTypeEnum.PRIMARY_ISSUE:
      ConditionWiseComponent = "";
      break;
    case productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM:
      ConditionWiseComponent = <OrderWindowForPMS />;
      break;
    case productTypeEnum.ALTERNATIVE_INVESTMENT_FUND:
      ConditionWiseComponent = <OrderWindowForAIF />;
      break;
    case productTypeEnum.INSURANCE:
      ConditionWiseComponent = "";
      break;
    case productTypeEnum.REAL_ESTATE:
      ConditionWiseComponent = "";
      break;
    case productTypeEnum.STARTUP_ECOSYSTEM:
      ConditionWiseComponent = "";
      break;
    case productTypeEnum.EQUITY_RESEARCH:
      ConditionWiseComponent = "";
      break;
    case productTypeEnum.TRAINING:
      ConditionWiseComponent = "";
      break;
    case productTypeEnum.UNLISTED:
      ConditionWiseComponent = <OrderWindowForUnlisted />;
      break;
    case productTypeEnum.BOND_WORK:
      ConditionWiseComponent = "";
      break;
    case productTypeEnum.RESEARCH_SERVICES:
      ConditionWiseComponent = <OrderWindowForResearchServices />;
      break;
    case productTypeEnum.NCD:
      ConditionWiseComponent = <OrderWindowForNCD />;
      break;
    case productTypeEnum.LOAN:
      ConditionWiseComponent = <OrderWindowForLoan />;
      break;
    default:
      ConditionWiseComponent = "";
  }
  return (
    <>
      <Button variant={variant} sx={sx} onClick={handleModalOpen}>
        Order Now
      </Button>

      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <IconButton
            style={{
              position: "absolute",
              fontSize: "18px",
              top: "8px",
              right: "5px",
            }}
            onClick={() => handleModalClose()}
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </IconButton>
          <Typography variant="h5" gutterBottom textAlign={"center"}>
            {" "}
            PLACE YOUR {productType.productName} ORDER{" "}
          </Typography>
          {productData && (
            <Autocomplete
              id="combo-box-demo"
              options={productData.data.productType
                .filter(
                  (option: any) =>
                    option.productTypeCode !== 3 &&
                    option.productTypeCode !== 7 &&
                    option.productTypeCode !== 8 &&
                    option.productTypeCode !== 9 &&
                    option.productTypeCode !== 10 &&
                    option.productTypeCode !== 12
                )
                .map((options: any) => options)}
              getOptionLabel={(options: any) => options.productName}
              onChange={(options: any, newValue) => {
                setProductType(newValue || "");
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Product for Order"
                  size={"small"}
                />
              )}
            />
          )}

          {ConditionWiseComponent}
        </Box>
      </Modal>
    </>
  );
};

export default OrderNow;
