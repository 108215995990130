import { Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import StartWithUs from '../StartWithUs/StartWithUs'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const ProductTraining = () => {
  return (
    <section>
        <Container>
        <Grid md={12} className={sessionStorage.getItem("JWT") ? 'your-dynamic-css-class-product' : 'default-class-product'}>
       <Typography style={{ textAlign: "left", fontSize: "30px", fontWeight: "bold",borderBottom: "4px solid black"}}>Training </Typography>
       <Typography style={{fontSize:"18px",fontWeight:"500",textAlign:"justify"}}>
       A Structured Financial market courses designed to accelerate your financial market literacy thereby giving you an edge in your Financial Career. 
      </Typography>
      </Grid>
      <br />



      <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "bold"}}>Let’s take look on the courses which we offer: -</Typography>
      <List>
      <ListItem>
        <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Basic Equity Trading Courses" />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Basic option Trading courses" />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Advance technical Courses" />
      </ListItem>
      <ListItem>
      <ArrowRightIcon></ArrowRightIcon>
        <ListItemText primary="Advance Option Trading Courses" />
      </ListItem>
    </List>
    <br />


{sessionStorage.getItem("JWT")?"":
<Grid mt={2} mb={2}>
<StartWithUs/>
</Grid>
}
        </Container>
    </section>
  )
}

export default ProductTraining