export const ResearchServiceMonthCounts = [
	// { month:"1 Month", value:"1M" },
	// { month:"2 Month", value:"2M" },
	// { month:"3 Month", value:"3M" },
	{ month:"6 Month", value:"6M" },
	{ month:"12 Month", value:"1Y" },
	// { month:"24 Month", value:"2Y" },
]

export const ResearchServiceCategory=[
	{category:"Subroker",value:"Subroker"},
	{category:"Individual",value:"Individual"}
]

export const ResearchServiceSegment=[
	{segment:"Cash",value:"Cash"},
	{segment:"F&O",value:"F&O"},
	{segment:"Cash + F&O",value:"Cash + F&O"},
]