import React from "react";
import { withParam } from "../../../utils/Router.Helper";
import {
	Autocomplete,
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	Divider,
	Grid,
	IconButton,
	Modal,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { useId } from "react";
import { styled } from "@mui/material/styles";
import { useQuery } from "react-query";
import { AifGetById, GetAllClient, GetListOfUserByCompanyMasterCodeAndUserTypeCode, MfGetById, PmsGetById, listOfManufactureNDistributorCompanies } from "../../../api/Api";
import PersonIcon from "@mui/icons-material/Person";
import ReactApexChart from "react-apexcharts";
import Loading from "../../../Constants/Loading";
import ErrorPage from "../../../Constants/ErrorPage";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from '@mui/icons-material/Edit';
import { style } from "../../Clients";
import { useForm } from 'react-hook-form';
import axios from "axios";
import { toast } from "react-toastify";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { changeDate } from "../../../utils/Helper";
import { productTypeEnum } from "../../../Constants/ProductTypeEnum";
import { CREATE_NEW_ORDER } from "../../../utils/API_Names";
import CloseIcon from '@mui/icons-material/Close';

export const TestComponet = (Props: any) => {
	console.log("props of test", Props);
	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
	}));
	return <Item>{Props.children}</Item>;
};

const MFScreener = (props: any) => {
	const schemeCode = props.params.schemeCode;

	const [open, setOpen] = React.useState(false);
	const [investNowModalOpen, setInvestNowModalOpen] = React.useState(false);
	const [companyMasterCode, setCompanyMasterCode] = React.useState();
	const [distributorCode, setDistributorCode] = React.useState("")
	const [orderDate, seOrderDate] = React.useState(new Date())
	const [clientId, setClientId] = React.useState("")
	const [investmentAmount, setInvestmentAmount] = React.useState("")

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const { data, isLoading, isFetching, isError, refetch } = useQuery(["MfGetById", schemeCode], () => MfGetById(schemeCode), {
		refetchOnWindowFocus: false,
		retry: false,
		keepPreviousData: false,
	});

	const { data: clientData } = useQuery(["getAllClients",companyMasterCode,distributorCode], () => GetAllClient(companyMasterCode,distributorCode),{
		enabled:!!companyMasterCode || sessionStorage.getItem("Profile")==="D"
	  })

	const { data: distributorCompanyList } = useQuery("ManufactureNDistributorCompanie", () => listOfManufactureNDistributorCompanies("D"), {
		enabled: sessionStorage.getItem("Profile") === "B",
	});

	const { data: UserProfilesByCompanyMasterCode } = useQuery(["userProfileByCompany", companyMasterCode], () => GetListOfUserByCompanyMasterCodeAndUserTypeCode("D", companyMasterCode), {
		enabled: !!companyMasterCode
	})

	const {
		register,
		handleSubmit,
		control,
		setValue,
		reset,
		getValues,
		watch,
		formState: { errors } } = useForm({ mode: "onChange" });

	const registerOptions = {
		buyRate: {
			required: "Please Enter Valid Amount",
			pattern: {
				value: /^[0-9]+$/,
				message: "Invalid Amount"
			},
			validate: {
				positive: (v: any) => parseInt(v) > data.data.aifDTOList[0]?.schemeMinInvestment || `Please Enter A Amount More then ${data.data.aifDTOList[0]?.schemeMinInvestment}`,
			}
		},
	}

	const chartData: any = data && {
		options: {
			chart: {
				type: "bar",
				height: 450,
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "55%",
					endingShape: "rounded",
				},
			},
			dataLabels: {
				enabled: true,
			},
			stroke: {
				show: true,
				width: 2,
				colors: ["transparent"],
			},
			xaxis: {
				categories: ["1M", "3M", "6M", "1Y", "3Y", "5Y", "Since Inception"],
			},
			yaxis: {
				title: {
					text: "% (Percentage)",
				},
			},
			fill: {
				opacity: 1,
			},
			tooltip: {
				y: {
					formatter: function (val: any) {
						return "$ " + val + " %";
					},
				},
			},
		},
		legend: {
			// position: '',
			width: 400,
			// position: 'top',
		},
		series: [
			{
				name: "Fund",
				data: [
					data.data.mutualFundResponse[0].fundPerformance?.schemeRet1m,
					data.data.mutualFundResponse[0].fundPerformance?.schemeRet3m,
					data.data.mutualFundResponse[0].fundPerformance?.schemeRet6m,
					data.data.mutualFundResponse[0].fundPerformance?.schemeRet1y,
					data.data.mutualFundResponse[0].fundPerformance?.schemeRet3y,
					data.data.mutualFundResponse[0].fundPerformance?.schemeRet5y,
					data.data.mutualFundResponse[0].fundPerformance?.schemeRetInce,
				],
			},
			{
				name: "BenchMark",
				data: [
					data.data.mutualFundResponse[0].fundPerformance?.bmRet1m,
					data.data.mutualFundResponse[0].fundPerformance?.bmRet3m,
					data.data.mutualFundResponse[0].fundPerformance?.bmRet6m,
					data.data.mutualFundResponse[0].fundPerformance?.bmRet1y,
					data.data.mutualFundResponse[0].fundPerformance?.bmRet3y,
					data.data.mutualFundResponse[0].fundPerformance?.bmRet5y,
					data.data.mutualFundResponse[0].fundPerformance?.bmRetInce,
				],
			},
		],
	};

	const donutData: any = data && {
		series: [
			data.data.mutualFundResponse[0].fundComposition?.cash || 10,
			data.data.mutualFundResponse[0].fundComposition?.debt || 10,
			data.data.mutualFundResponse[0].fundComposition?.equity || 10,
		],
		options: {
			// colors: ['#F44336', '#E91E63', '#9C27B0'],
			chart: {
				type: "donut",
			},
			labels: ["Cash", "Debt", "Equity"],
			legend: {
				formatter: function (val: any, opts: any) {
					return val + " - " + opts.w.globals.series[opts.seriesIndex];
				},
				position: "bottom",
			},
			dataLabels: {
				enabled: true,
				// formatter: function(val:any, opts:any) {
				//     return val.toFixed(2) + "% - " + opts.w.globals.series[opts.seriesIndex]
				//   },
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],
		},
	};

	const barData: any = data && {
		series: [
			{
				data:
					!!data.data.mutualFundResponse[0].fundSectors ? data.data.mutualFundResponse[0]?.fundSectors.map((sector: any) => {
						return sector?.sectorWeight;
					}) :
						[]
			},
		],
		chart: {
			type: "bar",
			height: 350,
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: true,
			},
		},
		dataLabels: {
			enabled: true,
		},
		xaxis: {
			categories:
				!!data.data.mutualFundResponse[0].fundSectors ? data.data.mutualFundResponse[0].fundSectors.map((sector: any) => {
					return sector?.sectorName;
				}) :
					[]
		},
	};

	const riskBarData: any = data && {
		series: [
			{
				data: [
					data.data.mutualFundResponse[0].fundRiskRatios?.standardDeviation1Y,
					data.data.mutualFundResponse[0].fundRiskRatios?.sharpeRatio1Y,
					data.data.mutualFundResponse[0].fundRiskRatios?.beta1Y,
					data.data.mutualFundResponse[0].fundRiskRatios?.rsquare1Y,
				],
			},
			{
				data: [
					data.data.mutualFundResponse[0].fundRiskRatios?.standardDeviation3Y,
					data.data.mutualFundResponse[0].fundRiskRatios?.sharpeRatio3Y,
					data.data.mutualFundResponse[0].fundRiskRatios?.beta3Y,
					data.data.mutualFundResponse[0].fundRiskRatios?.rsquare1Y,
				],
			},
		],
		options: {
			chart: {
				type: "bar",
				height: 430,
			},
			plotOptions: {
				bar: {
					horizontal: true,
					dataLabels: {
						position: "top",
					},
				},
			},
			dataLabels: {
				enabled: true,
				offsetX: -3,
				style: {
					fontSize: "12px",
					colors: ["#fff"],
				},
			},
			stroke: {
				show: true,
				width: 1,
				colors: ["#fff"],
			},
			tooltip: {
				shared: true,
				intersect: false,
			},
			xaxis: {
				categories: ["Standard Deviation", "Sharp ratio", "Beta", "R-Square"],
			},
		},
	};

	const onSubmit = (updateData: any) => {
		var UpdateAIfDto = { ...updateData, productMasterCode: data.data.mutualFundResponse[0].productMasterCode }
		axios.put("pms/updatePms", UpdateAIfDto).then((res: any) => toast.success("Update successfully")).catch((err) => toast.error(err?.response?.data?.status?.message?.title ? err?.response?.data?.status?.message?.title : err?.response?.data?.title))
		console.log("Submit data", UpdateAIfDto)
	}

	const changeStatus = () => {
		axios.put("pms/activeInActive?productMasterCode=" + data.data.mutualFundResponse[0].productMasterCode).then((res: any) => { refetch(); toast.success("Success") }).catch((err: any) => toast.error(err?.response?.data?.status?.message?.title ? err?.response?.data?.status?.message?.title : err?.response?.data?.title))
	}

	const onSubmitInvestNow = () => {
		let PmsInvestNowdto = {
			"productMasterCode": data.data.mutualFundResponse[0].productMasterCode,
			"clientId": clientId,
			"version": "1",
			"productTypeCode": productTypeEnum.PORTFOLIO_MANAGEMENT_SYSTEM,
			"orderDate": changeDate(orderDate),
			"distCompanyMasterCode": companyMasterCode,
			"distEmployeeCode": distributorCode,
			"schemeCode": data.data.mutualFundResponse[0].schemeCode,
			"buyRate": investmentAmount
		}

		axios.post(CREATE_NEW_ORDER, PmsInvestNowdto).then((res: any) => res && toast.success(res?.data?.status?.message?.title)).catch((err: any) => {
			toast.error(err?.response?.data?.status?.message?.title ? err?.response?.data?.status?.message?.title : err?.response?.data?.title);
		})
	}

	if (isLoading) {
		return <Loading />
	}

	if (isError) {
		return <ErrorPage width={"100%"} height={"auto"} />
	}
	return (
		<Box sx={{ marginTop: "20px" }}>
			{/* <ErrorPage width={"100%"} height={"auto"}/> */}
			{data &&
				data.data.mutualFundResponse.map((item: any) => (
					<>
						<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
							<Typography variant="h4">
								{item?.schemeName || "-"}
							</Typography>
							<Box>
								<Button onClick={() => setInvestNowModalOpen(true)} variant="contained" sx={{ mr: 1 }}>
									Order Now
								</Button>
								{sessionStorage.getItem("Profile") === "B" ?
									<Button onClick={handleOpen} variant="outlined">
										<EditIcon fontSize="small" /> Edit
									</Button>
									: <></>}
							</Box>
						</Box>

						<Grid container rowGap={4} sx={{
							margin: "0 auto",
							// justifyContent: "flex-end" 
						}} >
							<Grid
								container
								spacing={2}
								sx={{
									justifyContent: "space-evenly",
									margin: "0 auto",
								}}
							>
								<Grid item xs={12} sm={6} md={3} lg={2}>
									<img src={item?.amcInfoDTO?.amcLogo} width={200} alt="AMC logo" />
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={2}>
									<Typography variant="h5">Benchmark</Typography>
									<Typography variant="h6" color="primary">
										{item.schemeBenchmarkName}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={2}>
									<Typography variant="h5">Min Investment</Typography>
									<Typography variant="h6" color="primary">
										{item.schemeMinInvestment?.toLocaleString("en-IN") ? "₹ " + item.schemeMinInvestment?.toLocaleString("en-IN") :
											"-"}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={2}>
									<Typography variant="h5">Inception Date</Typography>
									<Typography variant="h6" color="primary">
										{item.schemeInceptionDate || "-"}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={2}>
									<Typography variant="h5">As On</Typography>
									<Typography variant="h6" color="primary">
										{item.fundPerformance?.date || "-"}
									</Typography>
								</Grid>
							</Grid>

							<Grid
								container
								spacing={2}
								sx={{
									justifyContent: "space-evenly",
									margin: "0 auto",
								}}
							>
								<Grid
									item
									xs={12}
									sm={3}
									md={3}
									lg={3}
									sx={{ justifyContent: "center" }}
								>
									<Button variant="contained" sx={{ width: "80%" }} color="secondary">
										{item.schemeClassification || "-"} | {item.schemePlanType}
									</Button>
								</Grid>
								<Grid item xs={12} sm={9} md={9} lg={9} textAlign={"left"}>
									<Typography variant="subtitle2">
										{item.schemeObjective || "-"}
									</Typography>
								</Grid>
							</Grid>

							<Grid container spacing={2} sx={{ margin: "0 auto" }}>
								<Grid item xs={12} sm={3} md={3} lg={3}>
									<Typography variant="subtitle1">Exit Load / Lock-in</Typography>
									<Typography variant="subtitle2">
										{item.schemeExitLoad || "-"}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={9} md={9} lg={9} textAlign={"left"}>
									<Typography variant="subtitle1">Fees Structure</Typography>
									<Typography variant="subtitle2">
										{item.schemeFeeStructure || "-"}
									</Typography>
								</Grid>
							</Grid>

							<Grid container spacing={2} sx={{ margin: "0 auto" }}>
								<Grid item xs={12} sm={12} md={8} lg={8}>
									<Paper
										elevation={8}
										sx={{ borderRadius: "20px", padding: "10px" }}
									>
										<Grid container sx={{ justifyContent: "space-evenly" }}>
											<Grid item xs={12} sm={2} md={2} lg={2}>
												<Typography variant="h6">NAV</Typography>
												<Typography>{!!item.fundPerformance.nav ? "₹" + item.fundPerformance.nav : "-"}</Typography>
											</Grid>
											<Divider orientation="vertical" flexItem />
											<Grid item xs={12} sm={2} md={2} lg={2}>
												<Typography variant="h6">1 Month Change</Typography>
												<Typography color={item.fundPerformance?.schemeRetInce > 0 ? "Green" : "Red"}>
													{item.fundPerformance?.activeReturns1month > 0 ? <Box sx={{ display: "flex", justifyContent: "center" }}><ArrowDropUpIcon /> {item.fundPerformance?.activeReturns1month}%</Box> : <Box sx={{ display: "flex", justifyContent: "center" }}><ArrowDropDownIcon /> {item.fundPerformance?.activeReturns1month}</Box>}
												</Typography>
											</Grid>
											<Divider orientation="vertical" flexItem />
											<Grid item xs={12} sm={2} md={2} lg={2}>
												<Typography variant="h6">Since Inception</Typography>
												<Typography color={item.fundPerformance?.schemeRetInce > 0 ? "Green" : "Red"}>
													{item.fundPerformance?.schemeRetInce > 0 ? <Box sx={{ display: "flex", justifyContent: "center" }}><ArrowDropUpIcon /> {item.fundPerformance?.schemeRetInce}%</Box> : <Box sx={{ display: "flex", justifyContent: "center" }}><ArrowDropDownIcon /> {item.fundPerformance?.schemeRetInce}</Box>}
												</Typography>
											</Grid>
											<Divider orientation="vertical" flexItem />
											<Grid item xs={12} sm={2} md={2} lg={2}>
												<Typography variant="h6">Fund AUM(Cr.)</Typography>
												<Typography>{!!item.fundPerformance.aum ? "₹" + item.fundPerformance.aum : "-"}</Typography>
											</Grid>
										</Grid>
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4}>
									<Paper
										elevation={8}
										sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
									>
										<Typography
											variant="h4"
											gutterBottom
										>
											Portfolio Characteristic
										</Typography>
										<Grid container sx={{ padding: "0 16px" }}>
											<Grid
												item
												xs={12}
												sm={12}
												md={12}
												lg={12}
												sx={{ display: "flex", justifyContent: "space-between" }}
											>
												<Typography variant="h6">Total Stocks : </Typography>
												<Typography variant="h6">
													{item.portfolioCharacteristics?.totalStocks || "-"}
												</Typography>
											</Grid>
											<Grid
												item
												xs={12}
												sm={12}
												md={12}
												lg={12}
												sx={{ display: "flex", justifyContent: "space-between" }}
											>
												<Typography variant="h6">Avg Market cap : </Typography>
												<Typography variant="h6">
													{item.portfolioCharacteristics?.avgMktCap || "-"}
												</Typography>
											</Grid>
											<Grid
												item
												xs={12}
												sm={12}
												md={12}
												lg={12}
												sx={{ display: "flex", justifyContent: "space-between" }}
											>
												<Typography variant="h6">Dividend Yield : </Typography>
												<Typography variant="h6">
													{item.portfolioCharacteristics?.dividendYield || "-"}
												</Typography>
											</Grid>
											<Grid
												item
												xs={12}
												sm={12}
												md={12}
												lg={12}
												sx={{ display: "flex", justifyContent: "space-between" }}
											>
												<Typography variant="h6">P/E ration: </Typography>
												<Typography variant="h6">
													{item.portfolioCharacteristics?.pe || "-"}
												</Typography>
											</Grid>
											<Grid
												item
												xs={12}
												sm={12}
												md={12}
												lg={12}
												sx={{ display: "flex", justifyContent: "space-between" }}
											>
												<Typography variant="h6">Age:</Typography>
												<Typography variant="h6">
													{item.portfolioCharacteristics?.avg_mkt_cap || "-"}
												</Typography>
											</Grid>
										</Grid>
										<Divider sx={{ mt: 2, mb: 2 }} />
										<Typography variant="h6">Fund Manager</Typography>
										<Grid container sx={{ justifyContent: "center" }}>
											{item?.fundManagers?.map((fundManager: any) => (
												<Grid item xs={12} sm={12} md={4} lg={3}>
													<PersonIcon />
													<Typography>{fundManager?.fundManagerName}</Typography>
												</Grid>
											))}
										</Grid>
									</Paper>
								</Grid>
							</Grid>

							<Grid container spacing={2} sx={{ margin: "0 auto", height: 410 }}>
								<Grid item xs={12} sm={12} md={8} lg={8}>
									<Paper
										elevation={8}
										sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
									>

										<Typography variant="h6">Over View</Typography>
										{!!item.fundPerformance ?
											<ReactApexChart
												options={chartData.options}
												series={chartData.series}
												type="bar"
												height={350}
											/> :
											<img src={"https://neofindesklocal.s3.us-east-2.amazonaws.com/NO_DATA_FOUND.svg"} alt="No data Found" width={350} height={350} />
										}
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4}>
									<Paper
										elevation={8}
										sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
									>
										<Typography variant="h6">Performance</Typography>
										<TableContainer>
											<Table
												sx={{ minWidth: 50, maxHeight: 300 }}
												aria-label="simple table"
												size="small"
											>
												<TableHead>
													<TableRow>
														<TableCell>Returns</TableCell>
														<TableCell align="right">Fund</TableCell>
														<TableCell align="right">Benchmark</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow
														sx={{
															"&:last-child td, &:last-child th": { border: 0 },
														}}
													>
														<TableCell component="th" scope="row">
															1 Month
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.activeReturns1month}
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.bmRet1m}
														</TableCell>
													</TableRow>
													<TableRow
														sx={{
															"&:last-child td, &:last-child th": { border: 0 },
														}}
													>
														<TableCell component="th" scope="row">
															3 Month
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.activeReturns3month}
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.bmRet3m}
														</TableCell>
													</TableRow>
													<TableRow
														sx={{
															"&:last-child td, &:last-child th": { border: 0 },
														}}
													>
														<TableCell component="th" scope="row">
															6 Month
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.activeReturns6month}
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.bmRet6m}
														</TableCell>
													</TableRow>
													<TableRow
														sx={{
															"&:last-child td, &:last-child th": { border: 0 },
														}}
													>
														<TableCell component="th" scope="row">
															1 Year
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.activeReturns1year}
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.bmRet1y}
														</TableCell>
													</TableRow>
													<TableRow
														sx={{
															"&:last-child td, &:last-child th": { border: 0 },
														}}
													>
														<TableCell component="th" scope="row">
															3 Year
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.activeReturns3year}
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.bmRet3y}
														</TableCell>
													</TableRow>
													<TableRow
														sx={{
															"&:last-child td, &:last-child th": { border: 0 },
														}}
													>
														<TableCell component="th" scope="row">
															5 Year
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.activeReturns5year || "-"}
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.bmRet5y || "-"}
														</TableCell>
													</TableRow>
													<TableRow
														sx={{
															"&:last-child td, &:last-child th": { border: 0 },
														}}
													>
														<TableCell component="th" scope="row">
															Since Inception
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.activeReturnsSi || "-"}
														</TableCell>
														<TableCell align="right">
															{item.fundPerformance?.bmRetInce || "-"}
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</Paper>
								</Grid>
							</Grid>

							<Grid container spacing={2} sx={{ margin: "0 auto" }}>
								<Grid item xs={12} sm={12} md={6} lg={4}>
									<Paper
										elevation={8}
										sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
									>
										<Typography variant="h6">Composition ( % )</Typography>
										{!!item.fundComposition ?
											<ReactApexChart
												options={donutData.options}
												series={donutData.series}
												type="donut"
												height={350}
											/>
											: <img src={"https://neofindesklocal.s3.us-east-2.amazonaws.com/NO_DATA_FOUND.svg"} alt="No data Found" width={350} height={350} />}
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={8}>
									<Paper
										elevation={8}
										sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
									>
										<Typography variant="h6">Risk Analysis</Typography>
										{!!data.data.mutualFundResponse[0].fundRiskRatios ?
											<ReactApexChart
												options={riskBarData.options}
												series={riskBarData.series}
												type="bar"
												height={350}
											/> : <>
												<img src={"https://neofindesklocal.s3.us-east-2.amazonaws.com/NO_DATA_FOUND.svg"} alt="No data Found" width={350} height={350} />
											</>
										}
									</Paper>
								</Grid>
							</Grid>

							<Grid container spacing={2} sx={{ margin: "0 auto" }}>
								<Grid item xs={12} sm={12} md={6} lg={8}>
									<Paper
										elevation={8}
										sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
									>
										<Typography variant="h6">Sector weights</Typography>
										{!!data.data.mutualFundResponse[0].fundSectors ?
											<ReactApexChart
												options={barData}
												series={barData.series}
												type="bar"
												height={350}
											/> : <>
												<img src={"https://neofindesklocal.s3.us-east-2.amazonaws.com/NO_DATA_FOUND.svg"} alt="No data Found" width={350} height={350} />
											</>}
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={4}>
									<Paper
										elevation={8}
										sx={{ borderRadius: "20px", padding: "10px", height: 410 }}
									>
										<Typography variant="h6">Holdings as on : {item.fundPerformance?.date || "-"}</Typography>
										{/* <TableCell>{ window.screen.height+ "-" +window.screen.width }</TableCell> */}
										{!!item.fundHolding ?
											<TableContainer>
												<Table
													sx={{ minWidth: 50 }}
													aria-label="simple table"
													size="small"
												>
													<TableHead>
														<TableRow>
															<TableCell>Holdings Company</TableCell>
															<TableCell align="right">Wts%</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{item.fundHolding.map((holdingData: any) => (
															<TableRow
																sx={{
																	"&:last-child td, &:last-child th": { border: 0 },
																	padding: "8px",
																}}
															>
																<TableCell component="th" scope="row">
																	{holdingData?.securityName}
																</TableCell>
																<TableCell align="right">
																	{holdingData.securityWeight}
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
											: <img src={"https://neofindesklocal.s3.us-east-2.amazonaws.com/NO_DATA_FOUND.svg"} alt="No data Found" width={"fit-content"} height={400} />}
									</Paper>

								</Grid>
							</Grid>

							{/* <Grid container spacing={2} sx={{ marginTop: 2 }}>
			<Grid item xs={12} sm={6} md={3} lg={3}>
			  <Card sx={{ minWidth: 220 }}>
				<TestComponet data={"Data Props"}>
				  {" "}
				  Benchmark : {item.schemeBenchmarkName}{" "}
				</TestComponet>
			  </Card>
			</Grid>
			<Grid item xs={12} sm={6} md={3} lg={3}>
			  <Card sx={{ minWidth: 220 }}>
				<TestComponet data={"Data Props"}>
				  {" "}
				  Inception Date : {item.schemeInceptionDate}
				</TestComponet>
			  </Card>
			</Grid>
			<Grid item xs={12} sm={6} md={3} lg={3}>
			  <Card sx={{ minWidth: 220 }}>
				<TestComponet data={"Data Props"}>
				  {" "}
				  Fund Manager : {item.schemeFundManager}
				</TestComponet>
			  </Card>
			</Grid>
			<Grid item xs={12} sm={6} md={3} lg={3}>
			  <Card sx={{ minWidth: 220 }}>
				<TestComponet data={"Data Props"}>
				  {" "}
				  Scheme Plan : {item.schemePlanType}{" "}
				</TestComponet>
			  </Card>
			</Grid>
		  </Grid> */}
						</Grid>


						<Modal
							open={open}
							onClose={handleClose}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box sx={style}>
							<IconButton style={{ position: 'absolute',fontSize:"18px" ,top: "8px", right: "5px"}} onClick={() => handleClose()}>
                            <CloseIcon style={{fontSize:"18px"}} />
                           </IconButton>
								<Typography id="modal-modal-title" variant="h6" component="h2">
									Fees Details
								</Typography>
								<Typography id="modal-modal-description" sx={{ mt: 2 }}>
									<form onSubmit={handleSubmit(onSubmit)}>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={6} md={4} lg={3}>
												<TextField
													id="outlined-basic"
													label="Fixed Fee"
													variant="outlined"
													defaultValue={data && data.data.mutualFundResponse[0].fixedFee}
													{...register("fixedFee", { valueAsNumber: true })}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={4} lg={3}>
												<TextField
													id="outlined-basic"
													label="Hurdle Fee"
													variant="outlined"
													defaultValue={data && data.data.mutualFundResponse[0].hurdleFee}
													{...register("hurdleFee", { valueAsNumber: true })}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={4} lg={3}>
												<TextField
													id="outlined-basic"
													label="Performance Fee"
													variant="outlined"
													defaultValue={data && data.data.mutualFundResponse[0].performanceFee}
													{...register("performanceFee", { valueAsNumber: true })}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={4} lg={3}>
												<TextField
													id="outlined-basic"
													label="Variable Fee"
													variant="outlined"
													defaultValue={data && data.data.mutualFundResponse[0].variableFee}
													{...register("variableFee", { valueAsNumber: true })}
												/>
											</Grid>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12}>
												<Switch
													checked={data && data.data.mutualFundResponse[0].isActive}
													onChange={changeStatus}
													inputProps={{ 'aria-label': 'controlled' }}
												/>
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:"center"}}>
												<Button variant="contained" type="submit"> Submit </Button>
											</Grid>
									</form>

								</Typography>
							</Box>
						</Modal>

						<Modal
							open={investNowModalOpen}
							onClose={() => setInvestNowModalOpen(false)}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box sx={styleMfscriner}>
							<IconButton style={{ position: 'absolute',fontSize:"18px" ,top: "8px", right: "5px"}} onClick={() => setInvestNowModalOpen(false)}>
                            <CloseIcon style={{fontSize:"18px"}} />
                            </IconButton>
								<Typography variant="h6" textAlign={"center"} gutterBottom>Order Details</Typography>
								<Divider sx={{ mb: 1 }} />
								<Typography variant="h5" textAlign={"center"} gutterBottom>{item.schemeName || "-"}</Typography>
								<Grid container textAlign={"center"}>
									<Grid item xs={12} sm={4} md={4} lg={4}>
										<img src={item?.amcInfoDTO?.amcLogo} width={100} alt="AMC logo" />
									</Grid>

									<Grid item xs={12} sm={4} md={4} lg={4}>
										<Typography variant="subtitle1">Min Investment</Typography>
										<Typography variant="subtitle2" gutterBottom>
											{item.schemeMinInvestment?.toLocaleString("en-IN") ? "₹ " + item.schemeMinInvestment?.toLocaleString("en-IN") :
												"-"}
										</Typography>
									</Grid>

									<Grid item xs={12} sm={4} md={4} lg={4}>
										<Typography variant="subtitle1">As On</Typography>
										<Typography variant="subtitle2" >
											{item.fundPerformance?.date || "-"}
										</Typography>
									</Grid>
								</Grid>


								<Grid container spacing={2} textAlign={"center"} sx={{ mb: 2 }}>
									<Grid item xs={12} sm={3} md={3} lg={3}>
										<Typography variant="subtitle1">Exit Load / Lock-in</Typography>
										<Typography variant="subtitle2">
											{item.schemeExitLoad || "-"}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
										<Typography variant="subtitle1">Fees Structure</Typography>
										<Typography variant="subtitle2">
											{item.schemeFeeStructure || "-"}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
										<Typography variant="subtitle1">NAV</Typography>
										<Typography variant="subtitle2">{item.fundPerformance?.nav}</Typography>
									</Grid>

									<Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
										<Typography variant="subtitle1">Fund AUM</Typography>
										<Typography variant="subtitle2">{item.fundPerformance?.aum}</Typography>
									</Grid>
								</Grid>
								<Grid container rowGap={2} columnGap={2}>
									{sessionStorage.getItem("Profile") === "B" ? (
										<Grid item xs={12} sm={3} md={3} lg={3}>
											<Autocomplete
												disablePortal
												id="combo-box-demo"
												options={
													distributorCompanyList &&
													distributorCompanyList.data.companies.map(
														(options: any) => options
													)
												}
												getOptionLabel={(options: any) => options.companyName}
												fullWidth
												onChange={(options: any, newValue) => {
													setCompanyMasterCode(
														newValue ? newValue.companyMasterCode : ""
													);
												}}
												renderInput={(params: any) => (
													<TextField
														{...params}
														label="Select Distributor Company"
														size="small"
													/>
												)}
											/>
										</Grid>
									) : (
										<></>
									)}

									{sessionStorage.getItem("Profile") === "B" ?
										<>
											<Grid item xs={12} sm={3} md={3} lg={2}>
												<Autocomplete
													disablePortal
													id="combo-box-demo"
													options={UserProfilesByCompanyMasterCode ? UserProfilesByCompanyMasterCode.data.userProfile.map((options: any) => options) : []}
													getOptionLabel={(options: any) => options?.userCode+"- "+ options?.firstName +" "+ options?.lastName}
													fullWidth
													onChange={(options: any, newValue) => {
														setDistributorCode(newValue ? newValue.employeeCode : "");
														// sessionStorage.setItem("CompanyData",newValue?.employeeCode)
													}}
													renderInput={(params: any) => (
														<TextField {...params} label="Select Distributor User" size='small' />
													)}
												/>
											</Grid>
										</>
										: <></>}

									{clientData &&
										<Grid item xs={12} sm={3} md={3} lg={2}>
											<Autocomplete
												disablePortal
												id="combo-box-demo"
												options={clientData.data.clients.map((options: any) => options)}
												getOptionLabel={(options: any) => options.fullName + " - " + options.panCardNo}
												fullWidth
												onChange={(options: any, newValue) => {
													setClientId(newValue ? newValue.clientId : "");
												}
												}
												renderInput={(params: any) => (
													<form onSubmit={handleSubmit(onSubmitInvestNow)} id="form1">
														<TextField {...params} label="Select Clients" size='small' />
													</form>
												)}
											/>
										</Grid>
									}

									<Grid item xs={12} sm={3} md={3} lg={2}>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<MobileDatePicker
												label="Order Date"
												inputFormat="DD/MM/YYYY"
												value={orderDate}
												onChange={(newValue: any) => {
													seOrderDate(newValue.toJSON());
												}}
												renderInput={(params) =>
													<TextField
														size="small"
														{...params}
														fullWidth
													/>
												}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item xs={12} sm={3} md={3} lg={2}>
										<form onSubmit={handleSubmit(onSubmitInvestNow)} id="form1">
											<TextField label="Investment Amount"
												size='small'
												fullWidth
												defaultValue={item.schemeMinInvestment}
												error={errors.buyRate ? true : false}
												helperText={!errors.buyRate ? "" : errors.buyRate.message + ""}
												{...register("buyRate", registerOptions.buyRate)} />				</form>
									</Grid>
									<Grid item xs={12} sm={3} md={3} lg={3}>
									</Grid>
								</Grid>
								<Box sx={{ textAlign: "center", mt: 2 }}>
									<form onSubmit={handleSubmit(onSubmitInvestNow)} id="form1">
										<Button type="submit" onClick={() => handleSubmit(onSubmitInvestNow)} variant="contained">Order Now</Button>
									</form>
								</Box>        </Box>
						</Modal>
					</>
				))}
		</Box>
	)
}

export default withParam(MFScreener)

export const styleMfscriner = {
	position: 'absolute' as 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
	  width: '90%',
	  bgcolor: 'background.paper',
	  boxShadow: 24,
	  p: 3,
	  borderRadius:"10px",
	  maxHeight: "100%",
	 
  };