import { Home } from '@mui/icons-material';
import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { Navigate, Route, Router, Routes, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

const Layout = (props) => {
    const navigate = useNavigate();
    const [ LoggedIn, setLoggedIn ] = React.useState(!!sessionStorage.getItem("JWT"))

        // useEffect(() => {
        //     if (!LoggedIn) {
        //     navigate("/");
        //     }
        // }, [LoggedIn,navigate]);
     
    if(!!sessionStorage.getItem("JWT")){
        return (
            <Box sx={{padding: sessionStorage.getItem("JWT") ? "70px 10px 50px 70px" : ""}}>
                {props.children}
            </Box>
        )
    }else
        return ( 
            <></>
        )
    
}

export default Layout