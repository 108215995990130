import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import { Button, Chip, InputBase } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { AIF_AMC_LIST, GET_ALL_AMC_LIST } from '../../utils/API_Names';
import { ManageAmcTableSwitch } from './ManageAmcTableSwitch';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import AddAmc from './AddAmc';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'srNo',
    numeric: false,
    disablePadding: true,
    label: 'Sr No',
  },
  {
    id: 'amcLogo',
    numeric: false,
    disablePadding: false,
    label: '',
  },
  {
    id: 'amcCode',
    numeric: false,
    disablePadding: false,
    label: 'AMC Code',
  },
  {
    id: 'amcName',
    numeric: false,
    disablePadding: false,
    label: 'AMC Name',
  },
  {
    id: 'amcSebiNo',
    numeric: false,
    disablePadding: false,
    label: 'SEBI No',
  },
   {
    id: 'amcContactPerson',
    numeric: false,
    disablePadding: false,
    label: 'Contact Person',
  },
  {
    id: 'amcContactNumber',
    numeric: false,
    disablePadding: false,
    label: 'Contact Number',
  },
  {
    id: 'amcEmailId',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'isActive',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const {valueClick}=props;
console.log("gfhjhgfdfgh",valueClick)

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const { toDate } = props;
  const { fromDate } = props;
  const { selectedPayout } = props;
  const { downloadSingleInvoice } = props;

  function downloadPayoutPDF(){
    axios({
      url: `payout/payoutStatementInPDF?fromDate=${fromDate.split("T")[0]}&productTypeCode=11&toDate=${toDate.split("T")[0]}`,
      method: "GET",
      responseType: "blob" // important
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    var date1 = new Date();
    link.setAttribute(
        "download",
        "TermSheet_" + ("0" + date1.getDate()).slice(-2) + "_" + ("0" + (date1.getMonth() + 1)).slice(-2) + "_" + date1.getFullYear() + ".pdf"
    );
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
});
  }


  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          AMC List
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            {/* <DeleteIcon onClick={()=>downloadSingleInvoice(selectedPayout)}/> */}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            {/* <DownloadForOfflineIcon onClick={downloadPayoutPDF}/> */}
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ManageAmcSortTable(props) {

  console.log("Props",props)
  const {valueClick}=props;
  const [searchTerm, setSearchTerm] = React.useState("");
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('amcId');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [refresh, setRefresh] = React.useState(true);

  console.log("selected",props.valueClick==='PMS'?"true":false)

  React.useEffect(() => {
    if (valueClick) { // Check if valueClick is truthy
      const apiUrl = `amc/getAllAmc?productCode=${valueClick}`;
      axios.get(apiUrl)
        .then((res) => {
          console.log(res.data.amcDetails);
  
          function createData(
            srNo,
            amcId,
            amcLogo,
            amcCode,
            amcName,
            amcSebiNo,
            amcContactPerson,
            amcContactNumber,
            amcEmailId,
            productCode,
            isActive
          ) {
            return {
              srNo,
              amcId,
              amcLogo,
              amcCode,
              amcName,
              amcSebiNo,
              amcContactPerson,
              amcContactNumber,
              amcEmailId,
              productCode,
              isActive
            };
          }
  
          const rowData = res.data.amcDetails.map((item, index) => (
            createData(
              index + 1,
              item.amcId,
              item.amcLogo,
              item.amcCode,
              item.amcName,
              item.amcSebiNo,
              item.amcContactPerson,
              item.amcContactNumber,
              item.amcEmailId,
              item.productCode,
              item.isActive
            )
          ))
          setRows(rowData)
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
    }
  
  }, [props, refresh, valueClick]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.amcId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage,rows],
  );


  console.log("rowwwww",rows)

  const handleSearch = (event) => {
    console.log("Search Item",event.target.value)
    setSearchTerm(event.target.value);
  };

  const filteredAmcList = visibleRows && visibleRows.filter((item) => {
    return (
      item.amcName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  console.log("mylength",valueClick)
  return (
    <Box sx={{ width: '100%' }}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                mb:2
              }}
              elevation={0}
            >
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1,}}
                placeholder="Search by AMC Name"
                onChange={handleSearch}
                inputRef={input => input && input.focus()}
                autoFocus={true}createDat
              />
              <AddAmc/>
            </Paper>

      <Paper sx={{ width: '100%', mb: 2 }} elevation={12}>
        <EnhancedTableToolbar 
        numSelected={selected.length} 
        toDate={props.toDate} 
        fromDate={props.fromDate} 
        selectedPayout={selected}
        />
        <TableContainer component={Paper} elevation={3}>
          <Table sx={{ minWidth: 930}} aria-label="simple table">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody 
            sx={{maxHeight:200}}
            >
              {filteredAmcList?.filter(row => row?.productCode === valueClick)
            .map((row, index) => {
              const isItemSelected = isSelected(row.productMasterCode);
              const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row.amcId)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.amcId}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      {/* <Checkbox color="primary" checked={isItemSelected}  onClick={(event) => handleClick(event, row.amcId)} inputProps={{'aria-labelledby': labelId,}}/> */}
                    </TableCell>
                    <TableCell align="left" component="th" id={labelId}scope="row">{row.srNo}</TableCell>
                    <TableCell align="left"><img src={row.amcLogo} alt="Amc Logo" width={30} height={30}/></TableCell>
                    <TableCell align="left">{row.amcCode}</TableCell>
                    <TableCell align="left" sx={{textDecoration: "underline"}}>
                      {row.amcName === "null"
                        ? "-"
                        : props.valueClick === 'LOAN'
                        ? <Link to={"/AmcLoan/" + row.amcId}>{row.amcName}</Link>
                        : props.valueClick === 'INSURANCE'
                        ? <Link to={"/AmcInsurance/" + row.amcId}>{row.amcName}</Link>
                        : <Link to={{pathname: "/manageAmc/" + row.amcId, state: row}} state={row} >{row.amcName}
                        </Link>}
                    </TableCell>
                    <TableCell align="left">{row.amcSebiNo}</TableCell>
                    <TableCell align="left">{row.amcContactPerson}</TableCell>
                    <TableCell align="left">{row.amcContactNumber}</TableCell>
                    <TableCell align="left">{row.amcEmailId}</TableCell>
                    <TableCell align="left" sx={{display:"flex", p:"17px"}}>
                      <ManageAmcTableSwitch 
                        amcId={row.amcId} 
                        isActive={row.isActive} 
                        setRefresh={setRefresh} 
                        refresh={refresh}
                        />
                    </TableCell>
                    
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25,50,100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

    </Box>
  );
}
