import React, { useEffect } from 'react'
import { Box, Button, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
import { withState } from '../../utils/Router.Helper';

const CompareMFFund = () => {
	const CompareMFFundData = useSelector((state:any)=> state.ChangeCompareMfFund)
	const dispatch = useDispatch();
	const navigate = useNavigate();

  return (
	<Box className="product-compare-mutual-fund-main-box" sx={{mt:2}}>
		<Paper elevation={2} sx={{p:2}}>
			<Typography variant='h4' gutterBottom >Compare Fund </Typography>
			{CompareMFFundData.map((mfSchemeList:any)=>(
				<>
					<Typography variant='subtitle2' gutterBottom sx={{display:"flex", justifyContent:"space-between"}} >
						{mfSchemeList.schemeName} 
						<Tooltip title="Remove">
						  <IconButton>
							<RemoveIcon onClick={()=>dispatch({ type: 'RemoveCompareMfFund',payload: mfSchemeList })}/>
						  </IconButton>
						</Tooltip>
					</Typography>
				</>
			))}
			<Button variant='contained' onClick={()=>navigate("/CompareMFFund",{state:CompareMFFundData})} disabled={CompareMFFundData.length >= 2 ? false : true}> Compare Fund</Button>
		</Paper>
	</Box>
  )
}

export default withState(CompareMFFund)